/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CSS } from '@stitches/react';

import { EventType } from '@elements/option';

import { useClickOutside } from '@hooks/use-click-outside';

import { config } from '@src/themes';

import {
  DropdownButtonContainer,
  DropdownContainer,
  DropdownContainerLabel,
  DropdownLabel,
  DropdownOptionsContainer,
} from './styles';

interface DropdownProps extends PropsWithChildren {
  disabled?: boolean;
  placeholder?: string;
  id: string;
  value: string | number;
  css?: CSS<typeof config>;
  label: string;
  errorMessage?: string;
  onChange: (event: EventType) => void;
}

interface DropdownContextData {
  onSelect: (item: EventType) => void;
  value: string | number;
}

export const DropdownContext = React.createContext<DropdownContextData>(
  {} as DropdownContextData,
);

function Dropdown({
  disabled = false,
  placeholder,
  children,
  onChange,
  id,
  value,
  label,
  errorMessage,
  ...rest
}: DropdownProps) {
  const selectRef = useRef(null);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [isOpened, setOpened] = useState(false);

  useEffect(() => {
    let options: React.ReactNode[] = [];
    if (Array.isArray(children)) {
      children.forEach(item => {
        options = [...options, item];
      });
    } else if (children) {
      options = [children];
    }

    const [option]: any = options.filter(
      ({ props }: any) => props.value === value,
    );

    const labelValue =
      option && !option.props.disabled ? option.props.children : '';
    setSelectedLabel(labelValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const toggleOpen = useCallback(() => {
    if (disabled) return;
    setOpened(!isOpened);
  }, [disabled, isOpened]);

  const onSelect = useCallback(
    (item: EventType) => {
      toggleOpen();
      onChange(item);
    },
    [onChange, toggleOpen],
  );

  function handleClickOutside() {
    setOpened(false);
  }

  useClickOutside(selectRef, handleClickOutside);

  const customContext: DropdownContextData = useMemo(() => {
    return {
      onSelect,
      value,
    };
  }, [value, onSelect]);

  function handleIconOnSelect() {
    if (isOpened) {
      return 'fa-regular fa-chevron-up';
    }
    return 'fa-regular fa-chevron-down';
  }

  return (
    <DropdownContext.Provider value={customContext}>
      <DropdownContext.Consumer>
        {() => (
          <DropdownContainer
            ref={selectRef}
            aria-expanded={isOpened}
            onClick={() => toggleOpen()}
            id={id}
            hasValue={!!selectedLabel}
            disabled={disabled}
            errorMessage={!!errorMessage}
            {...rest}
          >
            {label && <span className="select__label">{label}</span>}
            <DropdownContainerLabel
              hasValue={!!selectedLabel}
              disabled={disabled}
              errorMessage={!!errorMessage}
            >
              <DropdownLabel>{selectedLabel || placeholder}</DropdownLabel>
              <DropdownButtonContainer
                errorMessage={!!errorMessage}
                disabled={disabled}
              >
                <i className={handleIconOnSelect()} />
              </DropdownButtonContainer>
            </DropdownContainerLabel>
            {isOpened && (
              <DropdownOptionsContainer>{children}</DropdownOptionsContainer>
            )}
            {errorMessage && (
              <span className="error__message">{errorMessage}</span>
            )}
          </DropdownContainer>
        )}
      </DropdownContext.Consumer>
    </DropdownContext.Provider>
  );
}

export default Dropdown;
