import { Link } from 'react-router-dom';

import { inputResponsiveMaxWidthStyle } from '@components/input/styles';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const IndividualProductFormContainer = styled('form', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',

  backgroundColor: '$silverLight',
  padding: '2.5rem',
  borderRadius: '10px',
  border: '1px solid $silverPure',
  width: '100%',

  h2: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },

  '@deviceMd': {
    padding: '2.5rem 1.5rem 2rem',
  },

  '.product-in-cart-view__flag': {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    top: '-1px',
    right: '-1px',
    width: '5rem',
    height: '5rem',
    padding: '10px',
    backgroundColor: '$primaryPure',
    color: '$white',
    fontSize: '1.5rem',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%)',
    borderTopRightRadius: '10px',
  },

  '.product-in-cart-view__content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',

    i: {
      color: '$primaryPure',
      fontSize: '2.5rem',
    },

    p: {
      color: '$fontColorPrimary',
      fontSize: '1rem',
      fontWeight: '$semibold',
      textAlign: 'center',
    },

    '> div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.875rem',
      width: '100%',
      padding: '0.5rem 1rem',
      borderTop: '1px solid #9CAEC1',
      borderBottom: '1px solid #9CAEC1',
      marginBottom: '0.5rem',

      span: {
        display: 'flex',
        justifyContent: 'space-between',

        p: {
          color: '$fontColorPrimary',
          fontWeight: '500',
        },
      },
    },

    '&--error': {
      i: {
        color: '$error',
      },
    },
  },

  '.edit-product, .know-more-about-product': {
    color: '$primaryPure',
    fontSize: '1rem',
    fontWeight: '600',
    cursor: 'pointer',
    textAlign: 'center',
  },

  '.product-in-cart-view__product-actions': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: convertPixelToRem(14),

    '.product-actions__buttons-group': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',

      '.edit-button, .remove-button': {
        background: 'transparent',
        width: 'min-content',

        color: '$primaryPure',
        fontWeight: '600',
        textAlign: 'center',

        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },

  img: {
    position: 'absolute',
    margin: '0 auto',
    top: '-42px',
    height: '75px',
    filter: 'drop-shadow(0px 0px 1px #00000060)',
  },

  '> button': {
    width: '100%',
  },

  '> div': inputResponsiveMaxWidthStyle,

  '.disabled-product-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1',
    gap: '1rem',
    textAlign: 'center',

    h3: {
      fontSize: '1.125rem',
      fontWeight: '500',
    },

    i: {
      fontSize: '3rem',
      color: '$primaryPure',
    },

    '&--warning': {
      i: {
        color: '$warning',
      },
    },
  },
  variants: {
    isOnCart: {
      true: {
        borderColor: '$primaryPure',
      },
    },
  },
  defaultVariants: {
    isOnCart: false,
  },
});

export const IndividualProductHeaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',

  '.individual-product-header-container__title-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
  },

  h2: {
    textAlign: 'center',
  },

  variants: {
    hasLightBackground: {
      true: {
        h2: {
          color: '$fontColorPrimary',
        },
      },
    },
  },
});

export const KnownMoreButton = styled(Link, {
  color: '$primaryPure',
  fontSize: '1rem',
  textDecoration: 'none',
  fontWeight: '$semibold',

  '&:hover': {
    textDecoration: 'underline',
  },
});
