/* eslint-disable @typescript-eslint/no-explicit-any */

import SpecificError from '@errors/specific-error';
import SpecificsApiErrors from '@errors/specifics-api-errors';
import ApplicationError from '@errors/types/application-error';
import NotCatalogedError from '@errors/types/not-cataloged-error';

import { sendApplicationErrorToAnalytics } from './analytics-helpers';
import { ApiErrorsMessages } from './enum/api-error-messages';

export default class ErrorUtils {
  public static throwError(code: number, message?: string): ApplicationError {
    const specificError: SpecificError | undefined =
      SpecificsApiErrors.values().find(spError => spError.code === code);

    if (!specificError) {
      return new NotCatalogedError();
    }

    const error: ApplicationError = specificError.getInstance();
    if (message) error.setMessage(message);

    sendApplicationErrorToAnalytics(error.message);

    return error;
  }
}

export function isSpecificError<T extends ApplicationError>(
  error: unknown,
): error is T {
  return error instanceof ApplicationError;
}

export const getMessageError = (error: unknown) => {
  return isSpecificError(error) ? error.message : ApiErrorsMessages.DEFAULT;
};

type ErrorWithMessage = {
  message: string;
};

export function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}
