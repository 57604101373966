import { ProductsId } from '@helpers/enum/products-id';
import { ServiceGroupId } from '@helpers/enum/service-group-id';

export const PRODUCT_RENEGOTIATION_ALLOWED_LIST = [
  ProductsId.HCM_CORPORATIVO,
  ProductsId.VALE_TRANSPORTE,
];

export const SERVICE_RENEGOTIATION_LIST: Record<string, string> = {
  [ServiceGroupId.HCM]:
    'Caso já possua um plano Pontomais contratado, ao contratar novamente, o novo plano ira substituir o seu plano atual e um novo contrato será gerado.',
};
