import { ProductNameText } from '@elements/product-name-text';

type UnservedRegionProductProps = {
  productId: number;
};

function UnservedRegionProduct({ productId }: UnservedRegionProductProps) {
  return (
    <div className="disabled-product-container disabled-product-container--warning">
      <div>
        <i className="fa-solid fa-circle-exclamation" />
      </div>
      <h3>
        Parece que o produto{' '}
        <strong>
          <ProductNameText id={productId} />
        </strong>{' '}
        ainda não chegou em sua região.
      </h3>
    </div>
  );
}

export default UnservedRegionProduct;
