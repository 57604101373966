import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

const CartPageFormFooterContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: `3.5rem 1fr ${convertPixelToRem(132)}`,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.25rem',

  background: '$primaryPure',
  width: '100%',
  borderTop: '2px solid $secondaryPure',
  borderRadius: '0 0 10px 10px',

  color: '$white',

  '> div': {
    padding: `${convertPixelToRem(32)} 0`,

    textAlign: 'center',

    '&:nth-child(2)': {
      textAlign: 'start',
    },

    '&.form-footer__text-information': {
      fontSize: convertPixelToRem(12),
      'p:nth-child(2)': {
        marginTop: '0.25rem',
      },
    },

    '&.form-footer__value': {
      fontSize: convertPixelToRem(14),
    },
  },
});

interface CartPageFormFooterProps {
  totalValue: number;
}

export function CartPageFormFooter({ totalValue }: CartPageFormFooterProps) {
  return (
    <CartPageFormFooterContainer>
      <div className="total-value__icon">
        <i className="fa-solid fa-equals" />
      </div>
      <div className="form-footer__text-information">
        <p>
          <strong>VALOR TOTAL DO PEDIDO</strong>
        </p>
      </div>
      <div className="form-footer__value">
        <strong>{formatValueToCurrency(totalValue)}</strong>
      </div>
    </CartPageFormFooterContainer>
  );
}
