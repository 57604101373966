import { useState } from 'react';

import { ASSETS_ICONS_PATH } from '@config/assets-config';
import { env } from '@config/env';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

import { SvaInput } from './sva-input';

type SvaCartDetailsProps = {
  modalityForSVA: number;
  isCollapsed?: boolean;
};

export function SvaCartDetails({
  modalityForSVA,
  isCollapsed,
}: SvaCartDetailsProps) {
  const { svasForSale, getCurrentRule } = useComboCartPageContext();
  const { comboCartPricingData } = useSelector(({ cart }) => cart);

  const [open, setOpen] = useState(isCollapsed);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const canChooseSva = () => {
    if (!comboCartPricingData) {
      return false;
    }
    return getCurrentRule().quantidadeMaximaSva !== 0;
  };

  if (svasForSale.length === 0) {
    return null;
  }

  return (
    <SVADetails open={open} id="sva-details" disabled={!canChooseSva()}>
      <summary onClick={toggleOpen} role="button">
        <p className="title">Serviços Adicionais</p>

        {!open ? (
          <div>
            {svasForSale.map(({ codigoProduto, descricaoProduto }) => (
              <img
                key={codigoProduto}
                src={`${
                  env.URL_ASSETS + ASSETS_ICONS_PATH
                }/icon-${codigoProduto}.svg`}
                alt={descricaoProduto}
              />
            ))}
          </div>
        ) : (
          <>
            <p className="subtitle">
              Você tem direito a {getCurrentRule().quantidadeMaximaSva} dos
              serviços adicionais abaixo. Selecione os que desejar:
            </p>
            <span className="information">
              <i className="fa-solid fa-exclamation" />
              <p>
                Os serviços adicionais são disponibilizados de acordo com o
                cumprimento dos requisitos da oferta
              </p>
            </span>
          </>
        )}

        <i className={`fa-sharp fa-solid fa-caret-${open ? 'up' : 'down'}`} />
      </summary>

      <div>
        {svasForSale.map(sva => (
          <SvaInput
            key={sva.codigoProduto}
            productData={sva}
            svaModality={modalityForSVA}
          />
        ))}
      </div>
    </SVADetails>
  );
}

const SVADetails = styled('details', {
  '&[open]': {
    border: '1px solid $primaryPure',
  },

  '&[open] > summary': {
    paddingBottom: '1rem',
  },

  backgroundColor: '$white',
  boxShadow: '0px 0px 4px #00000029',
  borderRadius: '8px',
  color: '$primaryPure',
  fontSize: '0.875rem',
  fontWeight: '600',
  position: 'relative',

  cursor: 'pointer',

  summary: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateAreas: `'title arrow'
    'subtitle arrow'
    'information information'`,
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: '1.5',
    textDecoration: 'none',
    padding: '1rem',
    color: '$fontColorPrimary',

    img: {
      maxHeight: '1rem',
      margin: '0.5rem 1rem 0 0',
    },

    '.title': {
      gridArea: 'title',
      color: '$primaryPure',
      fontWeight: 'bold',
    },

    '.subtitle': {
      gridArea: 'subtitle',
    },

    '> i': {
      gridArea: 'arrow',
      color: '$primaryPure',
    },
  },

  '& > summary': {
    listStyle: 'none',
  },

  '& > summary::-webkit-details-marker': {
    display: 'none',
  },

  '.information': {
    display: 'flex',
    gridArea: 'information',
    alignItems: 'center',
    gap: '0.375rem',
    fontSize: '0.75rem',
    color: '#6D839A',

    i: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '$white',
      backgroundColor: '#6D839A',
      height: '1rem',
      width: '1rem',
      borderRadius: '50%',
      padding: '0.5rem',
      fontSize: '0.625rem',
    },
  },

  '@deviceSm': {
    summary: {
      gap: '0.5rem',
      lineHeight: 1.2,
    },

    '.information i': {
      height: '0.875rem',
      width: '0.875rem',
      padding: '0.5rem',
    },

    '.information p': {
      fontSize: '0.625rem',
    },
  },

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
        pointerEvents: 'none',
        opacity: '0.6',
        filter: 'grayscale(0.8)',
      },
    },
  },
});
