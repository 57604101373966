import { ApiRequestAuthData } from '@api/models/request/auth-data';
import { BannersResponse } from '@api/models/response/banners-response';

import getBaseURLApi from './authentication/get-base-url-api';

class BannerService {
  private readonly baseUrl;

  constructor() {
    this.baseUrl = 'banners';
  }

  public async getBannersInformation({
    siglaEmissor,
    siglaCanal,
    siglaSubCanal,
  }: ApiRequestAuthData): Promise<BannersResponse> {
    const url = `/${this.baseUrl}/${siglaCanal}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
    });

    return data;
  }
}

export default new BannerService();
