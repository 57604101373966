import { HTMLAttributes } from 'react';

import { styled } from '@src/themes';

interface ComboServiceFormSectionContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export function ComboServiceFormSectionContainer({
  children,
  ...props
}: ComboServiceFormSectionContainerProps) {
  return (
    <ComboServiceFormSectionContainerStyled {...props}>
      {children}
    </ComboServiceFormSectionContainerStyled>
  );
}

const ComboServiceFormSectionContainerStyled = styled('section', {
  position: 'relative',
});
