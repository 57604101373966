import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class ContractErrorNotCataloged extends ApplicationError {
  constructor() {
    super(
      SpecificsApiErrors.CONTRACT_ERROR_NOT_CATALOGED,
      ApiErrorsMessages.CONTRACT_ERROR_NOT_CATALOGED,
    );
  }
}
