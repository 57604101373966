import { useMemo } from 'react';

import { SectionNameType } from '@extra-types/section-name-type';
import { DefaultTokenVariantsType } from '@extra-types/token-variants-type';
import { createItensGroupByItemsAmount } from '@helpers/section-helpers';

import { useSelector } from './use-selector';
import { useWatchResponsive } from './use-watch-responsive';

interface UseInformationSectionProps {
  name: SectionNameType;
  sectionVariant?: DefaultTokenVariantsType;
  breakingPointToWatch?: number;
  observeMobileResponsive?: boolean;
}

export function useInformationSection({
  name,
  sectionVariant = 'primary',
  breakingPointToWatch = 650,
  observeMobileResponsive,
}: UseInformationSectionProps) {
  const { isMobile } = useWatchResponsive({
    breakingPointToWatch,
    observeMobileResponsive,
  });
  const sections = useSelector(({ landingPage }) => landingPage.sections);

  const sectionInformation = useMemo(() => {
    return sections.find(sectionItem => sectionItem.id === name);
  }, [sections, name]);

  const checkSectionItemsEmpty =
    sectionInformation && sectionInformation.itens.length === 0;

  return {
    sectionInformation,
    sectionVariant,
    isMobile,
    checkSectionItemsEmpty,
    createItensGroupByItemsAmount,
  };
}
