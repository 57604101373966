import { FAQArticleType } from '@extra-types/faq-article-type';
import { FAQCategoryType } from '@extra-types/faq-category-type';

import {
  FAQArticles,
  FAQArticlesListResponse,
  FAQArticlesByCategoryListResponse,
} from '@api/models/response/faq-articles-list-response';
import { FAQCategoriesResponse } from '@api/models/response/faq-categories-response';

export function convertFAQArticlesListResponseToFAQArticleList(
  articleListResponse: FAQArticlesByCategoryListResponse,
): FAQArticleType[] {
  return articleListResponse.conteudoFaq.artigos.map(article => ({
    id: article.id,
    categoryId: article.idCategoria,
    title: article.titulo,
    content: article.conteudo,
  }));
}

export function convertFAQCategoriesResponseToFAQCategoryList(
  articleListResponse: FAQCategoriesResponse,
): FAQCategoryType[] {
  return articleListResponse.categorias.map(category => ({
    id: category.id,
    tag: category.tag,
    color: category.cor,
    displayOrder: category.ordemExibicao,
    iconName: category.nomeIcone,
    title: category.titulo,
  }));
}

export function convertAllFAQArticlesListResponseToFAQArticleList(
  articleListResponse: FAQArticlesListResponse,
): FAQArticles[] {
  return articleListResponse.artigos.map(article => ({
    id: article.id,
    categoryId: article.idCategoria,
    subCategoryId: article.idSubCategoria,
    displayOrder: article.ordemExibicao,
    title: article.titulo,
    content: article.conteudo,
  }));
}

export function sortCategoriesListByDisplayOrder(list: Array<FAQCategoryType>) {
  function compare(a: FAQCategoryType, b: FAQCategoryType) {
    return a.displayOrder - b.displayOrder;
  }

  return list.sort(compare);
}
