import { convertPixelToRem } from '@helpers/style-helpers';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

export function OfferItem() {
  const { selectedOffer } = OfferFacade();

  if (!selectedOffer) {
    return null;
  }

  return (
    <OfferItemContainer>
      <p className="entry-tax-detail__title">
        <span className="title">{selectedOffer.nomeOferta}</span>
        <span>Grátis</span>
      </p>
    </OfferItemContainer>
  );
}

export const OfferItemContainer = styled('div', {
  padding: '1rem 0',
  p: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    span: {
      color: '$primaryPure',
      fontSize: convertPixelToRem(14),
      fontWeight: '$semibold',

      '&.title': {
        color: '$primaryDark',
        fontSize: '0.75rem',
        textTransform: 'uppercase',
      },
    },
  },
  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        span: {
          textDecoration: 'line-through',
        },
      },
    },
  },
});
