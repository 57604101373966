import { createAsyncThunk } from '@reduxjs/toolkit';

import { Emissor } from '@helpers/enum/emissor';

import { RootState } from '@hooks/use-selector';
import {} from '@store/slices/warning-modal-slice';

import { ChannelContactData } from '@api/models/response/search-channel-description';
import channelDescriptionService from '@api/services/channel-description-service';
import emissorService from '@api/services/emissor-service';
import ApplicationError from '@errors/types/application-error';
import NotCatalogedError from '@errors/types/not-cataloged-error';

import { fetchBannerInformationData } from './banner-information-thunk';
import { fetchLandingPageData } from './landing-page-thunk';
import { fetchActiveOffers } from './offer-thunk';
import { fetchProductsData } from './products-thunk';

interface FetchApplicationConfigData {
  channelDescription: ChannelContactData;
  maxBeneficiaryNameLength: number;
  maxBeneficiaryAmount: number;
  minCardValue: number;
  maxCardValue: number;
  restrictSimulation: number;
}

export const fetchApplicationConfig = createAsyncThunk<
  FetchApplicationConfigData,
  void,
  { state: RootState }
>('application/fetchApplicationConfigStatus', async (_, thunkAPI) => {
  const { config } = thunkAPI.getState().application;

  let response = {} as FetchApplicationConfigData;

  try {
    const baseConfigResponse = await emissorService.getBaseConfiguration({
      siglaEmissor: config.emissor.initials,
    });

    response = {
      ...response,
      maxBeneficiaryNameLength:
        baseConfigResponse.configuracaoEmissor.cartaoMaximoCaracteresNome,
      maxBeneficiaryAmount:
        baseConfigResponse.configuracaoEmissor
          .nominalQuantidadeMaximaBeneficiariosPedido,
      minCardValue:
        baseConfigResponse.configuracaoEmissor.nominalValorMinimoCartao,
      maxCardValue:
        baseConfigResponse.configuracaoEmissor.nominalValorMaximoCartao,
      restrictSimulation:
        baseConfigResponse.configuracaoEmissor.restricaoSimulacao,
    };

    const channelDescriptionResponse =
      await channelDescriptionService.getChannelDescriptionData({
        siglaEmissor: config.emissor.initials,
        siglaCanal: config.siglaCanal,
        siglaSubCanal: config.siglaSubCanal,
      });

    response = {
      ...response,
      channelDescription: channelDescriptionResponse.descricaoCanal,
    };
  } catch (error) {
    if (error instanceof ApplicationError)
      return thunkAPI.rejectWithValue(error);

    return thunkAPI.rejectWithValue(new NotCatalogedError());
  }

  thunkAPI.dispatch(fetchProductsData());
  thunkAPI.dispatch(fetchLandingPageData());
  thunkAPI.dispatch(fetchBannerInformationData());
  if (config.emissor.initials === Emissor.VRPAT) {
    thunkAPI.dispatch(fetchActiveOffers());
  }

  return response;
});
