import { HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from '@config/styles-config';
import {
  convertPixelToRem,
  holderStyleWithMobileResponsive,
} from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const PageContainer = styled('main', {
  flex: 1,
  width: '100%',

  variants: {
    headerPadding: {
      true: {
        paddingTop: convertPixelToRem(HEADER_HEIGHT),

        '@deviceSm': {
          paddingTop: convertPixelToRem(HEADER_MOBILE_HEIGHT),
        },
      },
    },
    horizontalHolder: {
      true: holderStyleWithMobileResponsive,
    },
  },
  defaultVariants: {
    headerPadding: true,
    horizontalHolder: false,
  },
});
