import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import formatCurrencyToValue from '@vr/devkit/money/formatCurrencyToValue';
import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';
import debounce from 'lodash.debounce';

import { Input } from '@components/input';
import { WalletFormInputs } from '@compositions/wallet-product-form';
import { ProductNameText } from '@elements/product-name-text';
import { Switch } from '@elements/switch';

import { DIGIT_WAIT_TIME_TO_PRICE } from '@config/application-config';
import { env } from '@config/env';
import {
  FONT_SIZE_INPUT_PRODUCT_PAGE,
  MAX_LENGTH_CURRENCY_VALUE,
} from '@config/input-config';
import { PRODUCT_OFFER_PHRASE } from '@config/products-config';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { getProductFromCart } from '@helpers/cart-helpers';
import { FormErrors } from '@helpers/enum/form-errors';
import { removeSpecialCaracters } from '@helpers/function-helpers';
import { getProductData, getProductType } from '@helpers/product-helpers';
import { getSingularOrPlural } from '@helpers/string-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addProductToCart,
  removeProductFromCart,
} from '@store/slices/cart-slice';
import {
  closeWarningModal,
  handleWarningModalContent,
} from '@store/slices/warning-modal-slice';
import { fetchCartPricing } from '@store/thunks/cart-thunk';

import { OfferFacade } from '@src/facade/offer-facade';

import { ProductItemContainer } from './styles';

interface CartPageWalletFormProductItemProps {
  productId: number;
  contracted?: boolean;
}

export function CartPageWalletFormProductItem({
  productId,
  contracted,
}: CartPageWalletFormProductItemProps) {
  const { selectedOffer } = OfferFacade();
  const inputName = `products.product-${productId}.value` as const;
  const dispatch = useDispatch();

  const { config, isLoading } = useSelector(({ application }) => application);
  const { productCartList, isCartLoading } = useSelector(({ cart }) => cart);
  const isCustomerLoading = useSelector(({ customer }) => customer.isLoading);
  const productList = useSelector(({ products }) => products.productList);
  const [isActive, setActive] = useState(
    productCartList.some(product => product.id === productId),
  );

  const { register, watch, resetField, handleSubmit, trigger, formState } =
    useFormContext<WalletFormInputs>();

  const offerPhrase = PRODUCT_OFFER_PHRASE[productId] || (
    <div>Produto não encontrado</div>
  );

  const productCartData = getProductFromCart({
    productId,
    productCartList,
  });

  const productData = useMemo(
    () => getProductData(productId, productList),
    [productList, productId],
  );

  const walletAmount = watch('amount');
  const productValue = watch(inputName);

  const totalValue = walletAmount * Number(productValue) || 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(async () => {
      const isFormValid = await trigger(['amount', 'products']);
      handleSubmit((form: WalletFormInputs) => {
        if (
          isFormValid &&
          !!form.products[`product-${productId}`].value &&
          (form.amount !== Number(productCartData?.amount) ||
            Number(form.products[`product-${productId}`].value) !==
              Number(productCartData?.value))
        ) {
          dispatch(
            addProductToCart({
              id: productId,
              type: getProductType(productData),
              amount: form.amount,
              value: Number(form.products[`product-${productId}`].value),
              planType: PlanTypeEnum.MENSAL,
            }),
          );
          dispatch(fetchCartPricing({ offerData: selectedOffer }));
        }
      })();
    }, DIGIT_WAIT_TIME_TO_PRICE),
    [handleSubmit, dispatch, productId, productCartData],
  );

  const handleActive = useCallback(() => {
    if (isActive) {
      const removeProduct = () => {
        dispatch(removeProductFromCart({ productId }));
        dispatch(closeWarningModal());
      };
      if (
        productCartList.length === 1 &&
        productCartList.some(cartProduct => cartProduct.id === productId)
      ) {
        dispatch(
          handleWarningModalContent({
            isVisible: true,
            title: 'Remover Produto',
            content:
              'Este é o seu último produto no carrinho, ao remove-lo você será redirecionado para a página inicial. Deseja mesmo continuar?',
            buttonAction: {
              label: 'Sim, remover produto',
              action: removeProduct,
            },
          }),
        );
      } else {
        dispatch(removeProductFromCart({ productId }));
        if (productValue) {
          dispatch(fetchCartPricing({ offerData: selectedOffer }));
        }
        setActive(false);
      }
    } else {
      setActive(true);
    }
  }, [
    isActive,
    productCartList,
    dispatch,
    productId,
    productValue,
    selectedOffer,
  ]);

  if (productData === undefined) {
    return null;
  }

  if (contracted) {
    return (
      <ProductItemContainer disabled className="offer-container">
        <div className="product-item__switch product-item__switch--unselected" />
        <div className="product-item__offer-text">
          <img
            src={env.URL_ASSETS_CHANNEL + productData.logoPath}
            alt={`Modelo do cartão ${productData.name}`}
          />
          <div className="offer-text__product-information">
            <p className="product-information__product-name">
              <ProductNameText id={productId} />
            </p>
            <div className="product-information__text">{offerPhrase}</div>
          </div>
        </div>
      </ProductItemContainer>
    );
  }

  if (!isActive) {
    return (
      <ProductItemContainer className="offer-container">
        <div className="product-item__switch product-item__switch--unselected">
          <Switch
            disabled={isCartLoading || isCustomerLoading}
            isToggled={isActive}
            onToggle={handleActive}
          />
        </div>
        <div className="product-item__offer-text">
          <img
            src={env.URL_ASSETS_CHANNEL + productData.logoPath}
            alt={`Modelo do cartão ${productData.name}`}
          />
          <div className="offer-text__product-information">
            <p className="product-information__product-name">
              <ProductNameText id={productId} />
            </p>
            <div className="product-information__text">{offerPhrase}</div>
          </div>
        </div>
      </ProductItemContainer>
    );
  }

  return (
    <ProductItemContainer>
      <div className="product-item__switch">
        <Switch
          disabled={isCartLoading || isCustomerLoading}
          isToggled={isActive}
          onToggle={handleActive}
        />
      </div>
      <div className="product-item__title">
        <img
          src={env.URL_ASSETS_CHANNEL + productData.logoPath}
          alt={`Modelo do cartão ${productData.name}`}
        />
        <ProductNameText id={productId} />
      </div>
      <div className="product-item__product-value">
        <Input.Default
          id={`cart-page-product-${removeSpecialCaracters(
            productData.name,
          )}-value`}
          maskMoney
          width="100%"
          placeholder="R$ 0,00"
          label="Valor Mensal"
          maxLength={MAX_LENGTH_CURRENCY_VALUE}
          disabled={isCartLoading || isLoading || isCustomerLoading}
          fontSize={FONT_SIZE_INPUT_PRODUCT_PAGE}
          inputMode="numeric"
          errorMessage={
            formState.errors?.products?.[`product-${productId}`]?.value?.message
          }
          {...register(inputName, {
            deps: 'amount',
            shouldUnregister: true,
            onChange: event => {
              if (event.target.value === 'R$ 0,00') {
                resetField(inputName, {
                  defaultValue: event.target.value.replace('R$ 0,00', ''),
                });
              }
              debouncedSave();
            },
            setValueAs: value =>
              value && formatCurrencyToValue(value).toFixed(2),
            required: 'É obrigatório',
            validate: {
              min: value =>
                value !== ''
                  ? Number(value) >= config.minCardValue ||
                    `${FormErrors.MIN_CARD_VALUE} ${formatValueToCurrency(
                      config.minCardValue,
                    )}`
                  : true,
              max: value =>
                value !== ''
                  ? Number(value) <= config.maxCardValue ||
                    `${FormErrors.MAX_CARD_VALUE} ${formatValueToCurrency(
                      config.maxCardValue,
                    )}`
                  : true,
            },
          })}
        />
      </div>
      <div className="product-item__total-value">
        {formatValueToCurrency(totalValue)}
        <div className="total-value__tip">{`${walletAmount} ${getSingularOrPlural(
          walletAmount,
          'cartão',
          'cartões',
        )} x ${formatValueToCurrency(Number(productValue))}`}</div>
      </div>
    </ProductItemContainer>
  );
}
