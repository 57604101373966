import { useEffect, useState } from 'react';

interface UseWatchResponsiveProps {
  breakingPointToWatch?: number;
  observeMobileResponsive?: boolean;
}

export function useWatchResponsive({
  breakingPointToWatch = 650,
  observeMobileResponsive = false,
}: UseWatchResponsiveProps = {}) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function onResize() {
      if (window.innerWidth <= breakingPointToWatch) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    if (observeMobileResponsive) {
      onResize();
      window.addEventListener('resize', onResize);
    }

    return function cleanupListener() {
      if (observeMobileResponsive) {
        window.removeEventListener('resize', onResize);
      }
    };
  }, [breakingPointToWatch, observeMobileResponsive]);

  return {
    isMobile,
  };
}
