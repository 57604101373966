import { HTMLAttributes, useRef } from 'react';

import { AccordionItemType } from '@extra-types/accordion-item-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

interface AccordionItemProps extends HTMLAttributes<HTMLDivElement> {
  data: AccordionItemType;
  changeActiveAction: (id: string) => void;
  isActive?: boolean;
}

export function AccordionItem({
  data,
  isActive,
  changeActiveAction,
  ...props
}: AccordionItemProps) {
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);
  const height = descriptionRef.current
    ? `${descriptionRef.current.scrollHeight + 16}px`
    : '0px';

  return (
    <AccordionItemContainer
      collapse={!isActive}
      css={{
        $$height: height,
      }}
      {...props}
    >
      <div
        role="button"
        className="accordion-item-container__title-container"
        onClick={() => changeActiveAction(data.id)}
        tabIndex={0}
        onKeyDown={event =>
          event.key === 'Enter' && changeActiveAction(data.id)
        }
        aria-expanded={isActive}
      >
        <span>{data.title}</span>
        <i className="fa-solid fa-chevron-down" />
      </div>
      <p ref={descriptionRef} className="accordion-item-container__description">
        {data.description}
      </p>
    </AccordionItemContainer>
  );
}

const AccordionItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  backgroundColor: '$white',
  border: '1px solid $silverPure',
  transition: 'border-color 0.4s',

  color: '$fontColorPrimary',

  '&:hover': {
    borderColor: '$primaryPure',

    '.accordion-item-container__title-container': {
      color: '$primaryPure',
    },
  },

  '.accordion-item-container__title-container': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.5rem',

    padding: '1.25rem 1rem',
    borderRadius: `${convertPixelToRem(10)} ${convertPixelToRem(10)} 0 0`,
    cursor: 'pointer',
    transition: 'color 0.4s ease',

    i: {
      height: convertPixelToRem(16),
      transition: 'transform 0.4s',
    },

    span: {
      fontWeight: '$semibold',
    },
  },

  '.accordion-item-container__description': {
    padding: '0 2rem 1rem 1rem',

    overflow: 'hidden',
    height: '$$height',
    transition:
      'height 0.4s ease-in-out, opacity 0.4s ease-in-out, padding 0.4s ease-in-out',

    fontSize: convertPixelToRem(14),
    lineHeight: 1.5,
  },

  variants: {
    collapse: {
      true: {
        '.accordion-item-container__description': {
          height: 0,
          paddingBottom: '0',
          opacity: 0,
        },
      },
      false: {
        borderColor: '$primaryPure',

        '.accordion-item-container__title-container': {
          color: '$primaryPure',
          i: {
            transform: 'rotate(180deg)',
          },
        },
        '.accordion-item-container__description': {
          opacity: 1,
        },
      },
    },
  },
  defaultVariants: {
    collapse: true,
  },
});
