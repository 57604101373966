import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const DropdownContainerLabel = styled('div', {
  color: '$primaryLight',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'all 0.2s',
  cursor: 'pointer',
  border: '1px solid $inputBorderColor',
  borderRadius: convertPixelToRem(10),
  backgroundColor: '$white',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  padding: `${convertPixelToRem(15)} ${convertPixelToRem(12)}`,
  marginTop: convertPixelToRem(1),

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed !important',
        'span, div': {
          color: '$fontColorLight',
        },
      },
    },
    errorMessage: {
      true: {
        border: '1px solid $error',
      },
    },
    hasValue: {
      true: {},
    },
  },
  compoundVariants: [
    {
      hasValue: true,
      errorMessage: false,
      disabled: false,
      css: {
        borderColor: '$inputSuccessIcon',
        '> div': {
          i: {
            color: '$inputSuccessIcon',
          },
        },
      },
    },
  ],
  defaultVariants: {
    disabled: false,
    errorMessage: false,
    hasValue: false,
  },
});

export const DropdownContainer = styled('div', {
  position: 'relative',
  display: 'block',
  width: '100%',

  '&[aria-expanded=true]': {
    '> div': {
      borderColor: '$inputFocusBorderColor',
    },
  },

  '.select__label': {
    fontSize: '0.8125rem',
    whiteSpace: 'nowrap',
  },

  '.error__message': {
    position: 'absolute',
    bottom: 0,
    color: '$error',
  },

  '@deviceMd': {
    paddingBottom: '1.25rem',
  },

  variants: {
    disabled: {
      true: {},
    },
    errorMessage: {
      true: {},
    },
    hasValue: {
      true: {},
    },
  },
  compoundVariants: [
    {
      hasValue: true,
      errorMessage: false,
      disabled: false,
      css: {
        '.select__label': {
          color: '$inputSuccessIcon',
        },
      },
    },
  ],
  defaultVariants: {
    disabled: false,
    errorMessage: false,
    hasValue: false,
  },
});

export const DropdownLabel = styled('span', {
  color: '$silverDark',
  background: 'transparent',
  outline: 0,
  border: 0,
  fontWeight: 500,
  borderRadius: 0,
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  i: {
    margin: `0 ${convertPixelToRem(10)}`,
  },

  '&:focus': {
    outline: 0,
  },
});

export const DropdownOptionsContainer = styled('ul', {
  margin: 0,
  padding: 0,
  position: 'absolute',
  left: 0,
  listStyle: 'none',
  display: 'block',
  width: '100%',
  maxHeight: convertPixelToRem(200),
  overflow: 'auto',
  border: '2px solid $silverPure',
  borderRadius: `0 0 ${convertPixelToRem(6)} ${convertPixelToRem(6)}`,
  zIndex: 99,
  background: '$white',
  borderTop: 0,
  'li:last-child': {
    borderBottom: 0,
  },
});

export const DropdownButtonContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',

  i: {
    color: '$fontColorPrimary',
  },

  variants: {
    disabled: {
      true: {
        color: '$fontColorLight',

        i: {
          color: '$fontColorLight',
        },
      },
    },
    errorMessage: {
      true: {
        color: '$error',
      },
    },
  },
});
