export const commonColors = {
  white: '#FFFFFF',
  black: '#000000',
  dark: '#343a40',
  light: '#F7F7F7',

  silverLight: '#F4F5F7',
  silverPure: '#EDEDED',
  silverDark: '#465562',

  notificationPure: '#E32525',
  error: '#CD180E',
  warning: '#E36900',
  success: '#43A84D',
  informationLight: '#D7E9F9',
  informationDark: '#3C92E0',

  fontColorLight: '#91A2B1',
  fontColorPrimary: '#4A5969',
  fontColorDark: '#1B2126',
  fontColorSecondary: '$white',

  inputBorderColor: '#D5DCE1',
  inputFocusBorderColor: '#5B6F80',
  inputSuccessIcon: '$success',

  bonusCartBackground: '#E5F1F7',
  walletBackground: '#2D3233',
};
