import InformationRow from '@elements/table-elements/information-row';
import { ProductRow } from '@elements/table-elements/product-row';
import Table from '@elements/table-elements/table';

export function TableSkeleton() {
  return (
    <Table
      head={{
        textLeft: 'Descrição',
      }}
      foot={{
        titleLeft: 'Valor total do pedido',
        titleRight: '',
      }}
    >
      <ProductRow
        productData={{
          id: 0,
          subtotalValue: 0,
        }}
        loading
      />
      <ProductRow
        productData={{
          id: 0,
          subtotalValue: 0,
        }}
        loading
      />
      <ProductRow
        productData={{
          id: 0,
          subtotalValue: 0,
        }}
        loading
      />
      <InformationRow
        variant="gray"
        informationData={{
          title: '',
          subtitle: '',
          value: 0,
        }}
        loading
      />
      <InformationRow
        variant="green"
        informationData={{
          title: '',
          subtitle: '',
          value: 0,
        }}
        loading
      />
      <InformationRow
        variant="green"
        informationData={{
          title: '',
          subtitle: '',
          value: 0,
        }}
        loading
      />
    </Table>
  );
}
