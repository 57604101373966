import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import {
  completeWithLeftZero,
  getSingularOrPlural,
} from '@helpers/string-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

const CartPageFormTotalValueContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: `3.5rem 1fr ${convertPixelToRem(132)}`,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.25rem',

  background: '$silverPure',
  width: '100%',
  borderRadius: '0 0 6px 6px',

  '> div': {
    padding: `${convertPixelToRem(18)} 0`,

    textAlign: 'center',

    '&:nth-child(2)': {
      textAlign: 'start',
    },

    '&.form-footer__text-information': {
      fontSize: convertPixelToRem(12),
      'p:nth-child(2)': {
        marginTop: '0.25rem',
      },
    },

    '&.form-footer__value': {
      fontSize: convertPixelToRem(14),
    },
  },

  variants: {
    isMobilityGroup: {
      true: {
        borderRadius: '0',
      },
    },
  },
});

interface CartPageFormTotalValueProps {
  totalAmountProducts: number;
  totalCardsAmount: number;
  totalValue: number;
  bonusAmount: number;
  isWalletGroup?: boolean;
  isMobilityGroup?: boolean;
}

export function CartPageFormTotalValue({
  totalAmountProducts,
  totalCardsAmount,
  totalValue,
  bonusAmount,
  isWalletGroup = false,
  isMobilityGroup = false,
}: CartPageFormTotalValueProps) {
  return (
    <CartPageFormTotalValueContainer isMobilityGroup={isMobilityGroup}>
      <div className="total-value__icon">
        <i className="fa-solid fa-equals" />
      </div>
      <div className="form-footer__text-information">
        <p>
          <strong>VALOR TOTAL DE BENEFÍCIOS</strong>
        </p>
        <p>
          {completeWithLeftZero(totalAmountProducts)}&nbsp;
          {isWalletGroup
            ? getSingularOrPlural(totalAmountProducts, 'saldo', 'saldos')
            : getSingularOrPlural(totalAmountProducts, 'produto', 'produtos')}
          &nbsp;/&nbsp;
          {completeWithLeftZero(totalCardsAmount)}&nbsp;
          {getSingularOrPlural(totalCardsAmount, 'cartão', 'cartões')}{' '}
          {bonusAmount > 0 && (
            <>
              / {completeWithLeftZero(bonusAmount)}&nbsp;
              {getSingularOrPlural(bonusAmount, 'brinde', 'brindes')}
            </>
          )}
        </p>
      </div>
      <div className="form-footer__value">
        <strong>{formatValueToCurrency(totalValue)}</strong>
      </div>
    </CartPageFormTotalValueContainer>
  );
}
