import { CartModalWalletItem } from '@elements/cart-modal-elements/cart-modal-wallet-item';
import { ProductImage } from '@elements/product-image';

import { PRODUCTS } from '@config/products-config';
import { completeWithLeftZero } from '@helpers/string-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

const CartWalletModalityViewInformationContainer = styled('div', {
  padding: '1rem 0',
  borderBottom: '1px solid $silverLight',

  '.cart-modal-wallet-modality__card-information': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    marginBottom: '1rem',

    p: {
      color: '$$productColor',
      fontWeight: '$semibold',
    },

    img: {
      width: convertPixelToRem(30),
    },
  },

  '.cart-modal-wallet-modality__card-amount': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    span: {
      fontSize: convertPixelToRem(14),

      '&:nth-child(2)': {
        fontSize: '1rem',
      },
    },
  },
});

const CartWalletModalityViewProductListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  padding: '1rem 0',
});

export function CartWalletModalityView() {
  const { productCartList, cartModality } = useSelector(({ cart }) => cart);

  if (!cartModality) {
    return null;
  }

  return (
    <>
      <CartWalletModalityViewInformationContainer
        css={{
          $$productColor: `$colors$${
            PRODUCTS[cartModality.id].colorPrefixToken
          }ColorName`,
        }}
      >
        <div className="cart-modal-wallet-modality__card-information">
          <ProductImage productId={cartModality.id} />
          <p>{cartModality.shelfInformation.title}</p>
        </div>
        <div className="cart-modal-wallet-modality__card-amount">
          <span>Quantidade cartões</span>
          <span>{completeWithLeftZero(productCartList[0].amount)}</span>
        </div>
      </CartWalletModalityViewInformationContainer>
      <CartWalletModalityViewProductListContainer>
        {productCartList.map(product => (
          <CartModalWalletItem
            key={product.id}
            id={product.id}
            value={product.value}
          />
        ))}
      </CartWalletModalityViewProductListContainer>
    </>
  );
}
