import { useContext } from 'react';

import {
  VideoModalContext,
  VideoModalContextData,
} from '../store/context/video-modal-context';

export function useVideoModalContext(): VideoModalContextData {
  const context = useContext(VideoModalContext);

  if (!context) {
    throw new Error(
      'useVideoModalContext must be used within a VideoModalContext.',
    );
  }

  const { handleVideoModal, videoModalState, handleVideoData } = context;

  return { handleVideoModal, videoModalState, handleVideoData };
}
