import { RateArticleType } from '@extra-types/rate-article-type';

export const FAQ_CATEGORY_PRODUCT_ID_EXCEPTION = 0;

export const FAQ_PREVIEW_MAX_ITEMS_TO_SHOW = 3;
export const FAQ_RETRY_MAX_ATTEMPTS = 1;
export const FAQ_RETRY_DELAY_TIME = 3000;

export const RateArticleEnum: Record<RateArticleType, string> = {
  like: 'positivar',
  deslike: 'negativar',
};
