import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

import { useSelector } from './use-selector';

export function useHistoryStack() {
  const { initialPage } = useSelector(({ application }) => application);
  const [stack, setStack] = useState<string[]>([]);
  const { pathname } = useLocation();
  const type = useNavigationType();

  useEffect(() => {
    if (type === 'PUSH') {
      if (pathname === initialPage) {
        setStack([]);
      } else if (stack[stack.length - 1] !== pathname) {
        setStack(prev => [...prev, pathname]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, type]);

  const handlePrevPage = useCallback(() => {
    setStack(prev => prev.slice(0, prev.length - 1));
    return stack.length <= 1 ? initialPage : stack[stack.length - 2];
  }, [initialPage, stack]);

  return { handlePrevPage };
}
