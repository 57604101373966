import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ProductNameText } from '@elements/product-name-text';

import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

interface CartModalWalletItemProps {
  id: number;
  value: number;
}

const CartModalWalletItemContainer = styled('div', {
  '.cart-individual-item-container__product-title': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    marginBottom: '1rem',

    '.product-title__information': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',

      p: {
        fontWeight: '$semibold',
      },

      img: {
        width: convertPixelToRem(30),
      },
    },
  },

  '.cart-individual-item-container__product-information': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    '.product-information__value': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      span: {
        fontSize: convertPixelToRem(14),

        '&:nth-child(2)': {
          fontSize: '1rem',
        },
      },
    },
  },
});

export function CartModalWalletItem({ id, value }: CartModalWalletItemProps) {
  const productsData = useSelector(({ products }) => products.productList);
  const productData = productsData.find(product => product.id === id);

  if (!productData) {
    return null;
  }

  return (
    <CartModalWalletItemContainer>
      <div className="cart-individual-item-container__product-title">
        <div className="product-title__information">
          <p>
            <ProductNameText id={id} />
          </p>
        </div>
      </div>
      <div className="cart-individual-item-container__product-information">
        <div className="product-information__value">
          <span>Valor por cartão</span>
          <span>{formatValueToCurrency(value)}</span>
        </div>
      </div>
    </CartModalWalletItemContainer>
  );
}
