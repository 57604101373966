import ProductWalletDescription from '@components/product-wallet-input/product-wallet-description';

import { ProductType } from '@extra-types/product-type';

import { ProductWalletInputContainer } from './product-combo-wallet-input';

type ProductComboUnservedRegionProps = {
  productData: ProductType;
};

export function ProductComboUnservedRegion({
  productData,
}: ProductComboUnservedRegionProps) {
  return (
    <ProductWalletInputContainer
      css={{
        '@deviceMd': {
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
      }}
    >
      <ProductWalletDescription productData={productData} />
      <div className="product-wallet-description-container__unserved-region">
        <p>Este produto não está disponível em sua região</p>
      </div>
    </ProductWalletInputContainer>
  );
}
