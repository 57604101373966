import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DEFAULT_CHANNEL } from '@config/application-config';
import { ASSETS_LOGOS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import {
  removeListOfQueryStringFromSearch,
  removeSpecificQueryParamFromSearch,
} from '@helpers/query-string-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { clearCart } from '@store/slices/cart-slice';
import { resetOffer } from '@store/slices/offer-slice';
import {
  handleWarningModalContent,
  closeWarningModal,
} from '@store/slices/warning-modal-slice';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

const EmissorLogoContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',

  cursor: 'pointer',
});

const EmissorLogo = styled('img', {
  objectFit: 'contain',
  width: '100%',
  maxHeight: convertPixelToRem(45),
  maxWidth: convertPixelToRem(500),
});

const PartnerLogo = styled('img', {
  maxHeight: convertPixelToRem(45),
  maxWidth: convertPixelToRem(250),

  '@deviceSm': {
    maxWidth: convertPixelToRem(100),
  },
});

function Emissor() {
  const { selectedOffer } = OfferFacade();
  const dispatch = useDispatch();
  const { config, initialPage } = useSelector(({ application }) => application);
  const { search } = useLocation();
  const navigate = useNavigate();

  function removeQueryParamsFromSearch() {
    return removeListOfQueryStringFromSearch({
      queryString: [
        'offerid',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
      ],
      search,
    });
  }

  const handleRedirectPage = () => {
    if (selectedOffer) {
      dispatch(
        handleWarningModalContent({
          isVisible: true,
          title: 'Atenção!',
          content: `Você está saindo do fluxo da oferta ${selectedOffer.nomeOferta}. Deseja mesmo sair?`,
          buttonOrder: 'reverse',
          buttonAction: {
            label: 'Sair da oferta',
            action: () => {
              dispatch(closeWarningModal());
              dispatch(clearCart());
              dispatch(resetOffer());
              navigate({
                pathname: initialPage,
                search: removeQueryParamsFromSearch(),
              });
            },
          },
          closeAction: {
            label: 'Continuar na oferta',
            action: 'close',
          },
        }),
      );
    } else {
      navigate({
        pathname: initialPage,
        search: removeSpecificQueryParamFromSearch({
          queryString: 'offerid',
          search,
        }),
      });
    }
  };

  return (
    <EmissorLogoContainer
      role="button"
      onClick={handleRedirectPage}
      tabIndex={0}
      onKeyDown={event =>
        event.key === 'Enter' && handleRedirectPage && handleRedirectPage()
      }
    >
      <EmissorLogo
        src={`${env.URL_ASSETS}${ASSETS_LOGOS_PATH}/${config.emissor.logoHeaderFileName}`}
        alt={`Logotipo ${config.emissor.name}`}
        title={`Logotipo ${config.emissor.name}`}
      />
    </EmissorLogoContainer>
  );
}

interface ChannelProps {
  fallbackComponent?: JSX.Element;
}

function Channel({ fallbackComponent }: ChannelProps) {
  const channel = useSelector(
    ({ application }) => application.config.siglaCanal,
  );
  const [loadError, setLoadError] = useState(false);

  const handleLoadErrorImage = (): void => {
    setLoadError(true);
  };

  if (loadError || channel === DEFAULT_CHANNEL) {
    return fallbackComponent || null;
  }

  return (
    <PartnerLogo
      className="logo__partner"
      src={`${
        env.URL_ASSETS
      }${ASSETS_LOGOS_PATH}/channel/${channel.toLowerCase()}-logo.png`}
      alt="Logotipo do parceiro"
      onError={handleLoadErrorImage}
    />
  );
}

export const Logo = {
  Emissor,
  Channel,
};
