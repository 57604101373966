import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Helmet from '@elements/helmet';
import { ThemeManagerRoot } from '@elements/theme-manager-root';

import { ChatContextProvider } from '@store/context/chat-context';
import { FAQContextProvider } from '@store/context/faq-context';
import store from '@store/createStore';

import App from './App';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('vr-root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <ThemeManagerRoot>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <FAQContextProvider>
            <ChatContextProvider>
              <Helmet />
              <App />
            </ChatContextProvider>
          </FAQContextProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </ThemeManagerRoot>
  </Provider>,
);
