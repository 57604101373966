import {
  ECOSYSTEM_NOT_FOUND_MESSAGE,
  ecosystemProductsShelfInformation,
} from '@config/ecosystem-products-config';
import { EcosystemProductType } from '@extra-types/ecosystem-product-type';

import { EcosystemProductResponse } from '@api/models/response/ecosystem-product-response';

export const formatEcosystemProductListResponseToEcosystemProductTypeList = (
  ecosystemProductList: EcosystemProductResponse[],
): EcosystemProductType[] => {
  return ecosystemProductList.map(ecosystemProduct => {
    const ecosystemProductShelfInformation =
      ecosystemProductsShelfInformation[ecosystemProduct.codigoProduto];

    return {
      id: ecosystemProduct.codigoProduto,
      keyId: `${ecosystemProduct.codigoProduto}`,
      emphasisText:
        ecosystemProductShelfInformation?.emphasisText ||
        ECOSYSTEM_NOT_FOUND_MESSAGE,
      pageRoute: ecosystemProduct.urlDestino,
      alternativeText:
        ecosystemProductShelfInformation?.alternativeText ||
        ECOSYSTEM_NOT_FOUND_MESSAGE,
      shelfInformation: {
        illustration:
          ecosystemProductShelfInformation?.shelfIllustration ||
          ECOSYSTEM_NOT_FOUND_MESSAGE,
        title: ecosystemProduct.nomeProduto,
        description: ecosystemProduct.descricaoProduto,
      },
      description: ecosystemProduct.descricaoProduto,
      isFeatured: false,
    };
  });
};
