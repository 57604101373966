import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ToastMessage, ToastMessageVariantType } from '@elements/toast-message';

const MARGIN_OF_ANOTHER_ELEMENT_VALUE = 24;
const DEFAULT_DISPOSE_TIME = 4000;
const Z_INDEX_DEFAULT_VALUE = 9999;

type ToastMessageContentData = {
  variant: ToastMessageVariantType;
  message: JSX.Element;
  disposeTime: number;
  positionElementRef?: React.MutableRefObject<HTMLDivElement | null>;
  zIndex?: number;
  element?: string;
};

export interface ToastMessageContextData {
  toastMessageState: boolean;
  toastContent: ToastMessageContentData | undefined;
  toastBottomPosition: number;
  toastZIndexValue: number;
  sendToastMessage: (contentData: ToastMessageContentData) => void;
}

export const ToastMessageContext = createContext<ToastMessageContextData>(
  {} as ToastMessageContextData,
);

interface ToastMessageContextProviderProps {
  children: React.ReactNode;
}

export function ToastMessageContextProvider({
  children,
  ...props
}: ToastMessageContextProviderProps) {
  const [toastMessageState, setToastMessageState] = useState(false);
  const [toastContent, setToastContent] = useState<
    ToastMessageContentData | undefined
  >();
  const timer = useRef<NodeJS.Timeout | null>(null);

  const [toastZIndexValue, setToastZIndexValue] = useState(
    Z_INDEX_DEFAULT_VALUE,
  );
  const [toastBottomPosition, setToastBottomPosition] = useState(
    MARGIN_OF_ANOTHER_ELEMENT_VALUE,
  );

  const sendToastMessage = useCallback(
    (contentData: ToastMessageContentData) => {
      changeToastBottomPosition(
        contentData.positionElementRef?.current?.clientHeight,
      );
      setToastMessageState(true);
      setToastZIndexValue(contentData.zIndex || Z_INDEX_DEFAULT_VALUE);
      setToastContent(contentData);
    },
    [],
  );

  const changeToastBottomPosition = (clientHeight?: number) => {
    setToastBottomPosition(
      (clientHeight || 0) + MARGIN_OF_ANOTHER_ELEMENT_VALUE,
    );
  };

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, [toastContent]);

  useEffect(() => {
    if (toastMessageState) {
      timer.current = setTimeout(() => {
        timer.current = null;
        setToastMessageState(false);
      }, toastContent?.disposeTime || DEFAULT_DISPOSE_TIME);
    }
  }, [toastContent?.disposeTime, toastMessageState, toastContent]);

  const memorizeReturn = useMemo(
    () => ({
      toastMessageState,
      toastContent,
      toastBottomPosition,
      toastZIndexValue,
      sendToastMessage,
    }),
    [
      sendToastMessage,
      toastZIndexValue,
      toastBottomPosition,
      toastContent,
      toastMessageState,
    ],
  );

  return (
    <ToastMessageContext.Provider value={memorizeReturn} {...props}>
      {children}
      <ToastMessage />
    </ToastMessageContext.Provider>
  );
}
