import { Emissor } from '@helpers/enum/emissor';

import { useSelector } from '@hooks/use-selector';

import { HeaderJVCEF } from './JVCEF';
import { HeaderVRPAT } from './VRPAT';

type EmissorHeaderType = {
  [Emissor.VRPAT]: JSX.Element;
  [Emissor.JVCEF]: JSX.Element;
};

const emissorHeader: EmissorHeaderType = {
  [Emissor.VRPAT]: <HeaderVRPAT />,
  [Emissor.JVCEF]: <HeaderJVCEF />,
};

export function Header() {
  const { emissor } = useSelector(({ application }) => application.config);
  return emissorHeader[emissor.initials];
}
