import { ServiceType } from '@extra-types/service-type';
import { ServiceGroupId } from '@helpers/enum/service-group-id';

export const SERVICE_GROUPS: Record<string, ServiceType> = {
  [ServiceGroupId.BENEFIT]: {
    id: ServiceGroupId.BENEFIT,
    title: 'Benefícios',
  },
  [ServiceGroupId.MOBILITY]: {
    id: ServiceGroupId.MOBILITY,
    title: 'Mobilidade',
  },
  [ServiceGroupId.HCM]: {
    id: ServiceGroupId.HCM,
    title: 'Gente',
  },
  [ServiceGroupId.UNKNOWN]: {
    id: ServiceGroupId.UNKNOWN,
    title: 'Desconhecida',
  },
};
