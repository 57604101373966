import AuthenticationError from '@errors/types/authentication-error';
import ChannelNotFoundError from '@errors/types/channel-not-found-error';
import ChannelRestrictError from '@errors/types/channel-restrict-error';
import CompanyHassAllProductsError from '@errors/types/company-has-all-products-error';
import CompanyServedByAnotherChannelError from '@errors/types/company-served-by-another-channel-error';
import CompanyWithFederalRevenueRestrictionError from '@errors/types/company-with-federal-revenue-restriction-error';
import ContractErrorNotCataloged from '@errors/types/contract-error-not-cataloged';
import EmitterDoesNotAcceptBenefitAmountError from '@errors/types/emitter-doesnot-accept-benefit-amount-error';
import EmitterDoesNotServeRegionInformedError from '@errors/types/emitter-doesnot-serve-informed-region-error';
import InfraError from '@errors/types/infra-error';
import InvalidBeneficiaryBirthDateError from '@errors/types/invalid-beneficiary-birthdate-error';
import InvalidBeneficiaryCpfError from '@errors/types/invalid-beneficiary-cpf-error';
import InvalidCnpjError from '@errors/types/invalid-cnpj-error';
import InvalidAddressDeliveryError from '@errors/types/invalid-delivery-address-error';
import InvalidFieldError from '@errors/types/invalid-field-error';
import InvalidProductCodeError from '@errors/types/invalid-product-code-error';
import InvalidPurchaseSimulationIdentificationError from '@errors/types/invalid-purchase-identification-error';
import InvalidSimulationIdentificationError from '@errors/types/invalid-simulation-identification';
import ItWasNotPossibleFindCnpjError from '@errors/types/it-was-not-posssible-find-cnpj-error';
import LeadIsNotAllowHiringError from '@errors/types/lead-isnot-allowed-hiring-error';
import MaximumValueExcessedProductError from '@errors/types/maximum-value-excedded-error';
import MinValueError from '@errors/types/min-value-error';
import ModalityNotFound from '@errors/types/modality-not-found-error';
import NoConditionsForContractError from '@errors/types/no-conditions-for-contract-error';
import NonExistentEmitter from '@errors/types/non-existent-emitter';
import NotCatalogedError from '@errors/types/not-cataloged-error';
import IsNotPossibleProceedWithSimulationError from '@errors/types/not-possible-proceed-with-simulation';
import ProductDoesNotAcceptProvisionalError from '@errors/types/product-doesnot-accept-provisional-error';
import ProductNotComercializedRegionInformedError from '@errors/types/product-not-comercialized-in-region-error';
import QuantityProductsNotAcceptedError from '@errors/types/quantity-products-not-accepted-error';
import ServerIsNotAvailableError from '@errors/types/server-is-not-available-error';

import SpecificError from './specific-error';
import BlockListError from './types/block-list-error';
import CanNotApplyOfferError from './types/can-not-apply-offer-error';
import ContractDownloadError from './types/contract-download-error';
import DowngradeError from './types/downgrade-error';
import InvalidEmailPermissionError from './types/invalid-email-permission-error';
import MaintenanceError from './types/maintenance-error';
import PublicAgencyError from './types/public-agency-error';
import SamePlanError from './types/same-plan-error';

export default abstract class SpecificsApiErrors {
  static values(): SpecificError[] {
    return Object.values(this) as SpecificError[];
  }

  static CHANNEL_NOT_FOUND = {
    code: -1,
    getInstance() {
      return new ChannelNotFoundError();
    },
  } as SpecificError;

  static IS_NOT_POSSIBLE_TO_PROCEED_WITH_THE_SIMULATION = {
    code: -2,
    getInstance() {
      return new IsNotPossibleProceedWithSimulationError();
    },
  } as SpecificError;

  static COMPANY_SERVED_BY_ANOTHER_CHANNEL = {
    code: -3,
    getInstance() {
      return new CompanyServedByAnotherChannelError();
    },
  } as SpecificError;

  static COMPANY_HAS_ALL_PRODUCTS = {
    code: -4,
    getInstance() {
      return new CompanyHassAllProductsError();
    },
  } as SpecificError;

  static EMITTER_DOES_NOT_SERVE_THE_REGION_INFORMED = {
    code: -5,
    getInstance() {
      return new EmitterDoesNotServeRegionInformedError();
    },
  } as SpecificError;

  static SERVER_IS_NOT_AVAILABLE = {
    code: -6,
    getInstance() {
      return new ServerIsNotAvailableError();
    },
  } as SpecificError;

  static QUANTITY_OF_PRODUCTS_NOT_ACCEPTED = {
    code: -7,
    getInstance() {
      return new QuantityProductsNotAcceptedError();
    },
  } as SpecificError;

  static PRODUCT_DOES_NOT_ACCEPT_PROVISIONAL = {
    code: -8,
    getInstance() {
      return new ProductDoesNotAcceptProvisionalError();
    },
  } as SpecificError;

  static EMITTER_DOES_NOT_ACCEPT_THE_BENEFIT_AMOUNT = {
    code: -9,
    getInstance() {
      return new EmitterDoesNotAcceptBenefitAmountError();
    },
  } as SpecificError;

  static NON_EXISTENT_EMITTER = {
    code: -11,
    getInstance() {
      return new NonExistentEmitter();
    },
  } as SpecificError;

  static PRODUCTS_NOT_COMMERCIALIZED_IN_REGION_INFORMED = {
    code: -12,
    getInstance() {
      return new ProductNotComercializedRegionInformedError();
    },
  } as SpecificError;

  static MAXIMUM_VALUE_OF_THE_EXCESSED_PRODUCT = {
    code: -13,
    getInstance() {
      return new MaximumValueExcessedProductError();
    },
  } as SpecificError;

  static INVALID_PRODUCT_CODE = {
    code: -14,
    getInstance() {
      return new InvalidProductCodeError();
    },
  } as SpecificError;

  static COMPANY_WITH_FEDERAL_REVENUE_RESTRICTION = {
    code: -15,
    getInstance() {
      return new CompanyWithFederalRevenueRestrictionError();
    },
  } as SpecificError;

  static INVALID_ADDRES_DELIVERY = {
    code: -16,
    getInstance() {
      return new InvalidAddressDeliveryError();
    },
  } as SpecificError;

  static INVALID_CNPJ = {
    code: -17,
    getInstance() {
      return new InvalidCnpjError();
    },
  } as SpecificError;

  static IT_WAS_NOT_POSSIBLE_TO_FIND_CNPJ = {
    code: -18,
    getInstance() {
      return new ItWasNotPossibleFindCnpjError();
    },
  } as SpecificError;

  static CHANNEL_RESTRICT = {
    code: -19,
    getInstance() {
      return new ChannelRestrictError();
    },
  } as SpecificError;

  static INVALID_PURCHASE_SIMULATION_IDENTIFICATION = {
    code: -20,
    getInstance() {
      return new InvalidPurchaseSimulationIdentificationError();
    },
  } as SpecificError;

  static INVALID_SIMULATION_IDENTIFICATION = {
    code: -21,
    getInstance() {
      return new InvalidSimulationIdentificationError();
    },
  } as SpecificError;

  static INVALID_BENEFICIARY_CPF = {
    code: -22,
    getInstance() {
      return new InvalidBeneficiaryCpfError();
    },
  } as SpecificError;

  static INVALID_BENEFICIARY_BIRTH_DATE = {
    code: -24,
    getInstance() {
      return new InvalidBeneficiaryBirthDateError();
    },
  } as SpecificError;

  static LEAD_IS_NOT_ALLOW_HIRING = {
    code: -25,
    getInstance() {
      return new LeadIsNotAllowHiringError();
    },
  } as SpecificError;

  static MINIMUM_VALUE_NOT_REACHED = {
    code: -37,
    getInstance() {
      return new MinValueError();
    },
  } as SpecificError;

  static MODALITY_NOT_FOUND = {
    code: -38,
    getInstance() {
      return new ModalityNotFound();
    },
  } as SpecificError;

  static NO_CONDITIONS_FOR_CONTRACT = {
    code: -85,
    getInstance() {
      return new NoConditionsForContractError();
    },
  } as SpecificError;

  static CONTRACT_ERROR_NOT_CATALOGED = {
    code: -86,
    getInstance() {
      return new ContractErrorNotCataloged();
    },
  } as SpecificError;

  static CONTRACT_DOWNLOAD_ERROR = {
    code: -800,
    getInstance() {
      return new ContractDownloadError();
    },
  } as SpecificError;

  static PUBLIC_AGENCY_ERROR = {
    code: -88,
    getInstance() {
      return new PublicAgencyError();
    },
  } as SpecificError;

  static CAN_NOT_APPLY_OFFER_ERROR = {
    code: -89,
    getInstance() {
      return new CanNotApplyOfferError();
    },
  } as SpecificError;

  static INFRASTRUCTURE = {
    code: -99,
    getInstance() {
      return new InfraError();
    },
  } as SpecificError;

  static BLOCK_LIST_ERROR = {
    code: -103,
    getInstance() {
      return new BlockListError();
    },
  } as SpecificError;

  static DOWNGRADE_ERROR = {
    code: -111,
    getInstance() {
      return new DowngradeError();
    },
  } as SpecificError;

  static SAME_PLAN_ERROR = {
    code: -113,
    getInstance() {
      return new SamePlanError();
    },
  } as SpecificError;

  static INVALID_EMAIL_PERMISSION = {
    code: -108,
    getInstance() {
      return new InvalidEmailPermissionError();
    },
  } as SpecificError;

  static AUTHENTICATION = {
    code: 401,
    getInstance() {
      return new AuthenticationError();
    },
  } as SpecificError;

  static INVALID_FIELD = {
    code: 422,
    getInstance() {
      return new InvalidFieldError();
    },
  } as SpecificError;

  static MAINTENANCE = {
    code: 503,
    getInstance() {
      return new MaintenanceError();
    },
  } as SpecificError;

  static NOT_CATALOGED = {
    code: -999,
    getInstance() {
      return new NotCatalogedError();
    },
  } as SpecificError;
}
