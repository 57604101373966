import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const RegisterInputsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  width: '100%',

  '.contact__wrapper': {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: '1rem',

    width: '100%',

    '.contact__wrapper--justify-start': {
      justifyContent: 'flex-start',

      '@deviceSm': {
        alignItems: 'center',
      },
    },

    '.contact__wrapper--remove-margin-bottom': {
      '> div': {
        marginBottom: 0,
      },
    },

    '@deviceSm': {
      paddingTop: '0',
    },
  },

  '.contact__wrapper--add-button': {
    display: 'flex',
    justifyContent: 'center',
    '@deviceSm': {
      marginTop: '1rem',
      marginBottom: convertPixelToRem(10),

      '> button.MuiButton-root.btn-xs': {
        width: '100%',
        maxWidth: '15.9375rem',
      },
    },
  },

  '.contact__wrapper--input': {
    '> div + div': {
      marginLeft: convertPixelToRem(40),
    },

    '@deviceMd': {
      flexDirection: 'column',

      '& + div': {
        marginTop: 0,
      },

      '> div + div': {
        marginLeft: 0,
      },

      '.MuiFormControl-root': {
        width: '100%',
      },

      '> div': {
        width: '100% !important',
        margin: `0 0 ${convertPixelToRem(20)}`,
      },
    },
  },

  '.contact__wrapper--remove-button-mobile': {
    display: 'none',

    '@deviceSm': {
      display: 'flex',
      marginTop: `${convertPixelToRem(30)} !important`,

      '> button.MuiButton-root.btn-xs': {
        display: 'block',

        width: '100%',
        maxWidth: '15.9375rem',
      },
    },
  },

  '.contact__wrapper--checkbox': {
    display: 'flex',
    justifyContent: 'center',
    gap: convertPixelToRem(40),
    marginBottom: convertPixelToRem(40),

    '@deviceSm': {
      justifyContent: 'space-between',
    },
  },

  '.register-inputs-container__warning-message': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    marginTop: '0.5rem',

    i: {
      color: '$informationDark',
      fontSize: '1.5rem',
    },
    span: {
      fontSize: '0.75rem',
      fontWeight: '$regular',
    },
  },
});
