import { HCMCarousel } from '@components/hcm-carousel';
import { PageSection } from '@elements/page-section';
import { Title } from '@elements/title';

import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';

import { styled } from '@src/themes';

export function HCMCarouselSection() {
  const carouselItens = [
    {
      path: '/carousel-register.webp',
      alt: 'Imagem mostrando o registro de ponto.',
      title: 'Controle de Ponto',
      description:
        'Ganhe autonomia e segurança,realize a gestão de ponto com umsistema antifraude.',
    },
    {
      path: '/carousel-dashboard.webp',
      alt: 'Imagem mostrando o dashboard de controle de ponto.',
      title: 'Admissão',
      description:
        'Nunca foi tão fácil organizar novas contratações. É online, sem dor de cabeça e papelada.',
    },
    {
      path: '/carousel-vacation-manager.webp',
      alt: 'Imagem mostrando o dashboard de controle de férias dos funcionários.',
      title: 'Férias e Folgas',
      description:
        'Ganhe mais tempo e agilidade no processo de gestão de ausências dos colaboradores.',
    },
    {
      path: '/carousel-work-schedule.webp',
      alt: 'Imagem mostrando o controle de escala de trabalho.',
      title: 'Escala',
      description:
        'Evite erros com a gestão digital das escalas. Facilite a rotina com segurança e visibilidade necessária.',
    },
    {
      path: '/carousel-holerite.webp',
      alt: 'Imagem mostrando o dashboard para controle de emissão de holerites.',
      title: 'Holerite',
      description:
        'Agilize a rotina com a distribuição em massa de holerites e ganhe segurança com o armazenamento digital.',
    },
    {
      path: '/carousel-ged.webp',
      alt: 'Imagem mostrando o painel de gestão eletronica de documentos.',
      title: 'Gestão Eletrônica de Documentos',
      description:
        'Organize todas as informações em um único lugar com segurança e inteligência.',
    },
  ];

  return (
    <PageSection
      id="hcm-carousel"
      css={{
        position: 'relative',
        paddingBottom: '2rem',
        background:
          'linear-gradient(180deg, $white 33%, #F5F5F5 33%, #F5F5F5 33%)',

        '@deviceMd': {
          background: 'linear-gradient(180deg, $white 50%, #F5F5F5 50%)',
        },
      }}
    >
      <Title.Default
        tag="h2"
        css={{
          color: '$black',
          paddingBottom: '2rem',
          justifyContent: 'center',
          fontSize: '1.5rem',
          margin: 0,
        }}
      >
        Tudo para o seu RH em um só lugar? Isso sim é facilidade.
      </Title.Default>
      <HCMCarouselContainer>
        <HCMCarousel>
          {carouselItens.map(item => (
            <div className="carousel-image-holder" key={item.path}>
              <img
                src={env.URL_ASSETS + ASSETS_OTHERS_PATH + item.path}
                alt={item.alt}
              />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </HCMCarousel>
      </HCMCarouselContainer>
    </PageSection>
  );
}

const HCMCarouselContainer = styled('div', {
  display: 'flex',
  gap: '1rem',
  flexWrap: 'wrap',

  '.carousel-image-holder': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    'h3, p': {
      fontWeight: 'bold',
    },

    h3: {
      fontSize: '1.25rem',
      color: '$primaryPure',
    },

    p: {
      fontSize: '0.875rem',
    },

    '@deviceLg': {
      maxHeight: '400px',
    },

    '@deviceMd': {
      maxHeight: '350px',
    },

    '@deviceSm': {
      maxWidth: '320px',
    },
  },
});
