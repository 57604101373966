import { useFormContext } from 'react-hook-form';

import { Input } from '@components/input';
import CardSlider from '@compositions/card-slider';

import { COMPANY_MIN_LENGTH } from '@config/input-config';
import { CompanyType } from '@extra-types/company-type';
import { FormErrors } from '@helpers/enum/form-errors';

import { useSelector } from '@hooks/use-selector';

import { CompanyNameInputContainer } from './styles';

interface CompanyNameInputProps {
  withCardDesign?: boolean;
  isLoading?: boolean;
}

export function CompanyNameInput({
  withCardDesign = true,
  isLoading = false,
}: CompanyNameInputProps) {
  const { config } = useSelector(({ application }) => application);
  const { productCartList, cartModality } = useSelector(({ cart }) => cart);
  const { companyData } = useSelector(({ customer }) => customer);

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<CompanyType & { cnpj: string }>();
  const nomeFantasia = watch('nomeFantasia');

  const getCardList = () => {
    if (cartModality && cartModality.isWalletGroupCard) {
      return [cartModality.id];
    }

    return productCartList.map(product => product.id);
  };

  return (
    <CompanyNameInputContainer>
      {!withCardDesign && (
        <Input.Default
          className="company-cnpj__input"
          label="CNPJ da empresa*"
          type="text"
          width="100%"
          disabled
          customMask="99.999.999/9999-99"
          css={{ width: '100%' }}
          {...register('cnpj', {
            value: companyData.cnpj,
          })}
        />
      )}
      <Input.Default
        className="company-name__input"
        label={`Nome Fantasia (Max. ${config.maxBeneficiaryNameLength} caracteres)`}
        type="text"
        placeholder="Nome Fantasia"
        width="100%"
        disabled={isLoading}
        errorMessage={errors.nomeFantasia?.message}
        css={{ width: '100%' }}
        {...register('nomeFantasia', {
          maxLength: {
            value: config.maxBeneficiaryNameLength,
            message: FormErrors.INVALID_COMPANY_MAXLENGTH,
          },
          minLength: {
            value: COMPANY_MIN_LENGTH,
            message: FormErrors.INVALID_COMPANY_MINLENGTH,
          },
          required: FormErrors.REQUIRED_COMPANY_NAME,
        })}
      />
      {withCardDesign && (
        <CardSlider fantasyName={nomeFantasia} cardList={getCardList()} />
      )}
    </CompanyNameInputContainer>
  );
}
