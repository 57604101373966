import React, { HTMLAttributes, useState } from 'react';

import { keyframes, styled } from '@src/themes';

export const sizes = ['sm', 'md', 'lg'] as const;
export type SizeType = typeof sizes[number];
export const colors = [
  '$primaryPure',
  '$secondaryPure',
  '$white',
  '$black',
] as const;
export type ColorType = typeof colors[number];

export interface LoaderProps extends HTMLAttributes<HTMLDivElement> {
  size?: SizeType;
  color?: ColorType;
}

const scaleAnimation = keyframes({
  '0%, 40%, 100%': {
    webkitTransform: 'scale(.4)',
    mozTransform: 'scale(.4)',
    transform: 'scale(.4)',
  },
  '20%': {
    webkitTransform: 'scale(1)',
    mozTransform: 'scale(1)',
    transform: 'scale(1)',
  },
});

const LoaderContainer = styled('div', {
  display: 'flex',
  position: 'relative',
  width: '100%',
  justifyContent: 'center',
});

const LoaderCircle = styled('div', {
  display: 'flex',
  justifyContent: 'space-around',
  position: 'relative',
  fontSize: '0',
  color: 'white',
});

const Circle = styled('span', {
  background: '$$color',
  border: '1px solid $$color',
  borderRadius: '50%',
  display: 'block',
  webkitAnimationFillMode: 'both',
  animationFillMode: 'both',
  animationName: `${scaleAnimation}`,
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',

  '&:nth-child(1)': {
    animationDuration: '0.75s',
    animationDelay: '0',
  },
  '&:nth-child(2)': {
    animationDuration: '0.75s',
    animationDelay: '0.12s',
  },
  '&:nth-child(3)': {
    animationDuration: '0.75s',
    animationDelay: '0.24s',
  },
});

function Loader({ size = 'md', color = '$white', ...rest }: LoaderProps) {
  const [totalOfDots] = useState(3);

  function loaderSize() {
    const sizesDefinition = { sm: 1, md: 2, lg: 3 };
    return {
      width: `calc((10px * ${sizesDefinition[size]}) * 3)`,
      height: `calc(10px * ${sizesDefinition[size]})`,
    };
  }

  return (
    <LoaderContainer data-testid="loader" {...rest}>
      <LoaderCircle css={loaderSize()}>
        {Array.from(Array(totalOfDots).keys()).map(item => (
          <Circle
            key={item}
            css={{
              ...loaderSize(),
              $$color: `$colors${color}`,
            }}
          />
        ))}
      </LoaderCircle>
    </LoaderContainer>
  );
}

export default Loader;
