import { STITCHES_PREFIX } from '@config/application-config';
import { productsTokenVariantsList } from '@config/styles-config';
import { ProductsTokenVariantsType } from '@extra-types/token-variants-type';

import { styled } from '@src/themes';

import { capitalizeString } from './string-helpers';

const DEFAULT_PIXEL_SIZE = 16;

export function convertPixelToRem(value: number) {
  return `${(value / DEFAULT_PIXEL_SIZE).toFixed(3)}rem`;
}

export const holderStyle = {
  maxWidth: '1232px',
  paddingRight: '2rem',
  paddingLeft: '2rem',
  margin: '0 auto',
};

export const holderStyleWithMobileResponsive = {
  ...holderStyle,

  '@deviceSm': {
    paddingRight: '1rem',
    paddingLeft: '1rem',
  },
};

type VariantsData = 'primary' | 'secondary';

const primitivesVariantList = ['primary', 'secondary'];

type MutationsData = 'pure' | 'dark' | 'medium' | 'light';

export function getStyleTokenByProduct(
  prefixNameToken: string,
  variant: VariantsData,
  mutation: MutationsData,
) {
  if (primitivesVariantList.includes(prefixNameToken.toLowerCase())) {
    return `$${prefixNameToken + capitalizeString(mutation)}`;
  }

  return `$${prefixNameToken}${capitalizeString(variant)}${capitalizeString(
    mutation,
  )}`;
}

type CreateProductsVariantsEmptyObjectData = {
  [key in ProductsTokenVariantsType]: Record<string, never>;
};

export const productsVariantsEmptyObjectList =
  productsTokenVariantsList.reduce<CreateProductsVariantsEmptyObjectData>(
    (variantsEmptyObject, variant) => ({
      ...variantsEmptyObject,
      [variant]: {},
    }),
    {} as CreateProductsVariantsEmptyObjectData,
  );

export const scrollbarStyle = {
  '&::-webkit-scrollbar-track': {
    borderRadius: convertPixelToRem(10),
    backgroundColor: '$silverLight',
  },

  '&::-webkit-scrollbar': {
    width: convertPixelToRem(6),
    maxHeight: convertPixelToRem(32),
    backgroundColor: '$white',
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: convertPixelToRem(10),
    backgroundColor: '$primaryLight',
  },
};

export const ProductPromotionalPhrase = styled('div', {
  color: '$fontColorPrimary',
  fontSize: '0.75rem',
  fontWeight: '$semibold',
});

export const ProductName = styled('span', {
  color: '$$productColor',
  fontWeight: '$bold',
});

export function hexToRGB(hex: string, alpha?: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hj: any;
  }
}

function handleRequestAnimationFrame() {
  const elements = document.querySelectorAll<HTMLStyleElement>('head style');

  if (!elements.length) return '';

  return Array.from(elements)
    .reduce<string[]>((previousValue, element) => {
      if (element.dataset.hotjarWorkaround) return previousValue;

      if (!element.sheet) return previousValue;

      const rulesString = Array.from(element.sheet.cssRules)
        .reduce<string[]>((previousRuleValue, rule) => {
          if (!rule.cssText.includes(STITCHES_PREFIX)) return previousRuleValue;
          return previousRuleValue.concat(rule.cssText);
        }, [])
        .join(' ');

      return previousValue.concat(rulesString);
    }, [])
    .join(' ');
}

export function hotjarWorkaround() {
  if (typeof window === 'undefined') return;

  const syncStyles = document.createElement('style');
  syncStyles.dataset.hotjarWorkaround = 'true';
  syncStyles.type = 'text/css';

  // insert style element into head
  document.head.insertBefore(syncStyles, document.head.children[0]);

  // start styles update interval

  const intervalID = window.setInterval(() => {
    const isHotjarRecording = window?.hj?.settings?.record;
    if (!isHotjarRecording) return;

    window.clearInterval(intervalID);

    let lastStyles: string;

    window.setInterval(() => {
      window.requestAnimationFrame(() => {
        const styles = handleRequestAnimationFrame();
        if (styles === lastStyles) return;

        syncStyles.textContent = styles;
        lastStyles = styles;
      });
    }, 500);
  }, 50);
}
