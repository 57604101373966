import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { Input } from '@components/input';
import { ProductImage } from '@elements/product-image';

import { PRODUCTS } from '@config/products-config';
import { ModalityType } from '@extra-types/modality-type';
import { ProductPricedFullDataType } from '@extra-types/product-priced-full-data-type';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { completeWithLeftZero } from '@helpers/string-helpers';

import { styled } from '@src/themes';

import { OrderRevisionExtensionList } from '../order-revision-extension-list';
import { OrderRevisionServiceTableBonusList } from '../order-revision-service-table-bonus-list';

interface OrderRevisionTableBenefitServiceSingleProps {
  modalityData: ModalityType;
  productPricedFullDataList: ProductPricedFullDataType[];
  isCollapsed: boolean;
}

export function OrderRevisionTableBenefitServiceSingle({
  modalityData,
  productPricedFullDataList,
  isCollapsed,
}: OrderRevisionTableBenefitServiceSingleProps) {
  return (
    <OrderRevisionTableBenefitServiceSingleContainer>
      <ul className="order-revision-single__resume">
        {productPricedFullDataList.map(productPriced => (
          <SingleProductItem
            key={productPriced.id}
            css={{
              $$productColor: `$colors$${
                PRODUCTS[productPriced.id].colorPrefixToken
              }PrimaryPure`,
            }}
            isCollapsed={isCollapsed}
          >
            <div className="single-product-item__information">
              <ProductImage productId={productPriced.id} />
              <span>{productPriced.name}</span>
            </div>
            {modalityData.id === ModalitiesId.MOBILIDADE ? (
              <>
                <div className="single-product-item__amount">
                  <span>Qnt. cartões</span>
                  <Input.Default
                    name={`${modalityData.keyId}-card-amount`}
                    value={completeWithLeftZero(productPriced.cardAmount)}
                    disabled
                  />
                </div>
                <div className="single-product-item__monthly-value">
                  <span>Valor mensal (R$)</span>
                  <Input.Default
                    name={`${modalityData.keyId}-card-value`}
                    value={formatValueToCurrency(
                      productPriced.monthlyCardValue,
                    )}
                    disabled
                  />
                </div>
                <div className="single-product-item__total-value">
                  <span>TOTAL</span>
                  <span className="total-value--mobile-label">
                    VALOR TOTAL DO BENEFÍCIO
                  </span>
                  <span className="total-value__value">
                    {formatValueToCurrency(productPriced.totalCardValue)}/mês
                  </span>
                </div>
              </>
            ) : (
              <div className="single-product-item__amount">
                <span>Média de colaboradores</span>
                <Input.Default
                  name={`${modalityData.keyId}-employeesAmount`}
                  value={completeWithLeftZero(productPriced.cardAmount)}
                  disabled
                />
              </div>
            )}
          </SingleProductItem>
        ))}
      </ul>
      {productPricedFullDataList.map(productPriced => {
        return (
          <div
            key={productPriced.id}
            className="order-revision-single__details"
          >
            <OrderRevisionExtensionList
              isCollapsed={isCollapsed}
              productPricedData={productPriced}
            />
            <OrderRevisionServiceTableBonusList
              isCollapsed={isCollapsed}
              productAddonList={[]}
            />
          </div>
        );
      })}
    </OrderRevisionTableBenefitServiceSingleContainer>
  );
}

const OrderRevisionTableBenefitServiceSingleContainer = styled('div', {
  '.order-revision-single__details': {
    overflow: 'hidden',
    padding: '0 1rem',
  },
});

const SingleProductItem = styled('li', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.5rem',

  width: '100%',
  padding: '1rem',

  '.single-product-item__information': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    maxWidth: '14rem',
    width: '100%',
    marginRight: 'auto',

    img: {
      maxHeight: '3.25rem',
      objectFit: 'contain',
    },

    span: {
      color: '$$productColor',
      fontSize: '0.75rem',
      fontWeight: '$bold',
      whiteSpace: 'nowrap',
    },
  },

  '.single-product-item__addon-award-group': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    opacity: 1,
    marginRight: 'auto',
    transition: 'opacity 0.4s ease',

    img: {
      height: '2.625rem',
      objectFit: 'contain',
    },
  },

  '.single-product-item__amount, .single-product-item__monthly-value': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    '> div': {
      padding: 0,
      input: {
        padding: '0.906rem 0.75rem',
      },
    },
  },

  '.single-product-item__total-value': {
    display: 'flex',
    alignItems: 'center',
    gap: '2.5rem',

    color: '$teriaryPure',
    fontSize: '0.75rem',
    fontWeight: '$bold',

    'span.total-value--mobile-label': {
      display: 'none',
    },

    '.total-value__value': {
      color: '$primaryPure',
      fontSize: '1rem',
    },
  },

  '@deviceLg': {
    alignItems: 'end',
    padding: '1rem 1rem 1.5rem',

    '.single-product-item__amount, .single-product-item__monthly-value, .single-product-item__total-value':
      {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0.25rem',

        'span.total-value__value': {
          display: 'flex',
          alignItems: 'center',

          height: '50px',
        },
      },
  },

  '@deviceMd': {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',

    padding: '1rem',

    '.single-product-item__amount, .single-product-item__monthly-value, .single-product-item__total-value':
      {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',

        width: '100%',
      },

    '.single-product-item__total-value': {
      marginTop: '0.5rem',

      'span:not(.total-value--mobile-label):not(.total-value__value)': {
        display: 'none',
      },

      'span.total-value--mobile-label': {
        display: 'block',
      },

      'span.total-value__value': {
        display: 'block',

        height: 'unset',

        fontSize: '1rem',
      },
    },
  },
  '@deviceXs': {
    '.single-product-item__total-value': {
      '.total-value__value': {
        fontSize: '0.875rem',
      },
    },
  },
  variants: {
    isCollapsed: {
      true: {},
      false: {
        '.single-product-item__addon-award-group': {
          visibility: 'hidden',

          height: 0,
          opacity: 0,
        },
      },
    },
  },
});
