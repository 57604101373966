import { useRef, useState } from 'react';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { PricingEntriesType } from '@extra-types/pricing-types';
import {
  createListOfProductTax,
  valueSelector,
} from '@helpers/product-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

type EntryTaxType = 'product-tax' | 'delivery-tax';

export function EntryTax({
  value,
  type,
  entryData,
}: {
  value: number;
  type: EntryTaxType;
  entryData?: Array<PricingEntriesType[]>;
}) {
  const [isCollapsed, setCollapseState] = useState(true);
  const taxDetailRef = useRef<HTMLDivElement | null>(null);
  const taxDetailHeight = taxDetailRef.current?.scrollHeight || 0;

  const title: { [key in EntryTaxType]: string } = {
    'product-tax': 'Tarifa dos Produtos',
    'delivery-tax': 'Frete',
  };

  if (value > 0 && type === 'product-tax' && entryData) {
    return (
      <EntryTaxDetailInformationContainer
        collapse={isCollapsed}
        css={{
          $$detailHeight: convertPixelToRem(taxDetailHeight),
        }}
      >
        <p className="entry-tax-detail__title">
          <span
            className="uppercase"
            role="button"
            onClick={() => setCollapseState(collapse => !collapse)}
            tabIndex={0}
            onKeyDown={event =>
              event.key === 'Enter' && setCollapseState(collapse => !collapse)
            }
            aria-label="Apresenta os detalhes das taxas relacionadas aos produtos"
          >
            {title[type]}
            <i className="fa-solid fa-chevron-up" />
          </span>
          <span>{formatValueToCurrency(value)}</span>
        </p>
        <div ref={taxDetailRef} className="entry-tax-detail__content">
          <ul
            id="product-taxes"
            className="entry-tax-detail__tax-list"
            aria-hidden={isCollapsed}
          >
            {createListOfProductTax(entryData).map(productTax => (
              <li key={productTax.id}>
                <span>{productTax.label}</span>
                {valueSelector({ value: productTax.totalTaxValue })}
              </li>
            ))}
          </ul>
        </div>
      </EntryTaxDetailInformationContainer>
    );
  }

  return (
    <EntryTaxSimpleInformationContainer>
      <p>
        <span className="uppercase">{title[type]}</span>
        {valueSelector({ value })}
      </p>
    </EntryTaxSimpleInformationContainer>
  );
}

const entryTaxTitleStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  span: {
    fontSize: '0.75rem',
    fontWeight: '$semibold',

    '&.uppercase': {
      textTransform: 'uppercase',
    },

    '&:nth-child(1)': {
      color: '$primaryDark',
      fontSize: convertPixelToRem(12),
    },

    '&:nth-child(2)': {
      color: '$fontColorPrimary',
      fontSize: convertPixelToRem(14),
    },

    '&.offer-value': {
      color: '$primaryPure',
    },
  },
};

export const EntryTaxSimpleInformationContainer = styled('div', {
  padding: '1rem 0',
  p: entryTaxTitleStyle,
});

const EntryTaxDetailInformationContainer = styled('div', {
  padding: '1rem 0',

  '.entry-tax-detail__title': {
    ...entryTaxTitleStyle,

    'span:nth-child(1)': {
      display: 'flex',
      alignItems: 'center',

      cursor: 'pointer',

      i: {
        height: convertPixelToRem(10),
        marginLeft: '0.5rem',
        transition: 'transform 0.4s ease',

        fontSize: '0.75rem',
      },
    },
  },

  '.entry-tax-detail__content': {
    height: '$$detailHeight',
    opacity: 1,
    overflow: 'hidden',
    transition: 'opacity 0.4s ease, height 0.4s ease',
  },

  '.entry-tax-detail__tax-list': {
    background: '$silverPure',
    padding: '0.5rem',
    marginTop: '1rem',
    listStyle: 'none',
    borderRadius: '0.25rem',

    li: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      fontSize: '0.75rem',

      '&:not(:last-child)': {
        marginBottom: '0.25rem',
      },
    },
  },

  variants: {
    collapse: {
      true: {
        '.entry-tax-detail__title': {
          i: {
            transform: 'rotate(180deg)',
          },
        },
        '.entry-tax-detail__content': {
          height: 0,
          opacity: 0,
        },
      },
    },
  },
});
