import { convertPixelToRem, scrollbarStyle } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const OfferDescriptionContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: `1fr ${convertPixelToRem(132)}`,
  gridTemplateAreas: `'title value'`,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.25rem',

  background: '$silverLight',
  width: '100%',
  padding: '1.5rem 0 1.5rem 1.5rem',
  cursor: 'pointer',

  fontSize: convertPixelToRem(14),

  '& + &': {
    borderTop: '1px solid $silverLight',
  },

  '@deviceMd': {
    gridTemplateColumns: `1fr ${convertPixelToRem(132)}`,
  },

  '.bonus-item__title': {
    gridArea: 'title',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '0.5rem',

    color: '$primaryPure',
    fontWeight: '$bold',
    fontSize: convertPixelToRem(14),

    i: {
      height: convertPixelToRem(10),
      transition: 'transform 0.4s ease',

      fontSize: '0.75rem',
    },

    '.subtitle': {
      color: '#6D839A',
      fontWeight: '500',
      textDecoration: 'underline',
    },

    '@deviceMd': {
      marginRight: '0.75rem',
    },

    '@deviceSm': {
      fontSize: '0.75rem',
    },
  },

  '.bonus-item__value': {
    gridArea: 'value',
    display: 'flex',
    justifyContent: 'center',

    p: {
      color: '$primaryPure',
      fontSize: convertPixelToRem(14),
      fontWeight: '$semibold',
    },
  },

  variants: {
    collapse: {
      true: {
        '.bonus-item__title': {
          i: {
            transform: 'rotate(180deg)',
          },
        },
      },
    },
  },
});

export const OfferDetailsContainer = styled('div', {
  ...scrollbarStyle,
  overflow: 'auto',
  paddingRight: '0.5rem',
  paddingBottom: '0.5rem',
  height: '$$height',
  opacity: 1,
  transition: 'height 0.4s ease, opacity 0.4s ease',

  '&.with-padding': {
    padding: '1.5rem',
  },

  '.details-container__holder': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    fontSize: convertPixelToRem(14),

    '@deviceSm': {
      backgroundColor: 'transparent',
    },

    p: {
      lineHeight: 1.5,
    },

    '.bonus-item__preview': {
      fontWeight: '$medium',
    },

    '.bonus-item__rule': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',

      background: '$bonusCartBackground',
      padding: '1rem',
      borderRadius: '6px',
      listStyle: 'none',

      'li::before': {
        display: 'inline-block',
        content: String.raw`\f0da`,

        width: '1rem',

        fontFamily: '$fontAwesome',
        color: '$primaryPure',
        fontWeight: '$bold',
        lineHeight: 1.8,
      },
    },

    '.bonus-item__warning': {
      fontSize: '0.75rem',
    },

    '.table-holder': {
      borderRadius: '8px',
      border: '1px solid #E4E8ED',
      borderBottom: 'unset',
    },

    '.bonus-details-table-header, .timezone-tax-details': {
      display: 'grid',
      gridTemplateColumns: `3fr 0.5fr ${convertPixelToRem(180)}`,
      gridTemplateAreas: `'description base value'`,
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      backgroundColor: '#E4E8ED',
      padding: '1rem',
      borderRadius: '8px 8px 0px 0px',
      fontWeight: '500',

      '@deviceMd': {
        gridTemplateColumns: `2fr 1fr ${convertPixelToRem(120)}`,
      },

      '@deviceSm': {
        gridTemplateColumns: `2fr 1fr ${convertPixelToRem(90)}`,

        fontSize: '0.875rem',
      },

      '@deviceXs': {
        fontSize: '0.75rem',
      },
    },

    '.timezone-tax-details': {
      backgroundColor: '$white',

      borderBottom: '1px solid #E4E8ED',

      '&:last-child': {
        borderRadius: '0 0 8px 8px',
      },

      '.timezone-title': {
        color: '$primaryPure',
      },
    },

    '.description-details-table, .timezone-title-details': {
      gridArea: 'description',
      textAlign: 'start',
    },

    '.base-details-table, .timezone-base-details': {
      gridArea: 'base',
      textAlign: 'end',
    },

    '.value-details-table, .timezone-value-details': {
      gridArea: 'value',
      textAlign: 'end',
    },

    '.timezone-value-details': {
      color: '$primaryPure',
    },
  },

  '@deviceSm': {
    '.details-container__holder': {
      overflow: 'auto',
      maxHeight: '100px',
      scrollSnapType: 'x mandatory',
    },
  },

  variants: {
    collapse: {
      true: {
        height: 0,
        opacity: 0,
      },
    },

    comboOffer: {
      true: {
        '.bonus-details-table-header': {
          backgroundColor: '#F4F5F7 !important',
          padding: '0.5rem !important',
          fontSize: '0.875rem !important',
        },
        '.timezone-tax-details': {
          padding: '0.5rem !important',
          fontWeight: '600 !important',
        },
      },
    },
  },
});
