import { useCallback, useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import { COOKIE_CONSENT_NAME } from '@config/application-config';
import {
  removeApplicationCookies,
  setApplicationCookies,
} from '@helpers/cookies-helpers';
import { CookiesTime } from '@helpers/enum/cookies-time';

import { useSelector } from './use-selector';

export function useCookiesConsent() {
  const config = useSelector(({ application }) => application.config);
  const [isModalOpen, setModalState] = useState(false);
  const [cookiesConsentResponse, setCookiesConsentResponse] = useState<
    boolean | undefined
  >(() => {
    const simuleCookiesConsentData = Cookies.get(COOKIE_CONSENT_NAME);

    return simuleCookiesConsentData
      ? JSON.parse(simuleCookiesConsentData)
      : undefined;
  });

  const removeCookiesAndDisableGA = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as { [key: string]: any })[`ga-disable-${config.emissor.tagGA3}`] =
      true;
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  }, [config.emissor.tagGA3]);

  function handleModalState(state?: boolean) {
    setModalState(oldModalState => state ?? !oldModalState);
  }

  function setConsentCookie(response: boolean) {
    Cookies.set(COOKIE_CONSENT_NAME, response.toString(), {
      expires: response
        ? CookiesTime.inOneHundredDays
        : CookiesTime.inThirtyDays,
    });
  }

  function onAcceptedCookies() {
    setApplicationCookies();
    setConsentCookie(true);
    setCookiesConsentResponse(true);

    setModalState(false);
  }

  function onRefuseCookies() {
    removeCookiesAndDisableGA();
    removeApplicationCookies();
    setConsentCookie(false);
    setCookiesConsentResponse(false);
    setModalState(false);
  }

  useEffect(() => {
    if (!Cookies.get(COOKIE_CONSENT_NAME)) {
      setModalState(true);
    }
  }, []);

  useEffect(() => {
    if (cookiesConsentResponse === false) {
      removeCookiesAndDisableGA();
      removeApplicationCookies();
    }
  }, [cookiesConsentResponse, removeCookiesAndDisableGA]);

  return {
    isModalOpen,
    cookiesConsentResponse,
    handleModalState,
    onAcceptedCookies,
    onRefuseCookies,
  };
}
