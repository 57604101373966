export const defaultTokenVariantList = ['primary', 'secondary'] as const;

export const productsTokenVariantsList = [
  'alimentacao',
  'auto',
  'refeicao',
  'premiacao',
  'boasFestas',
  'vrva',
  'multi',
  'transporte',
  'valeTransporte',
  'compras',
  'homeOffice',
  'nutricao',
  'mobilidade',
  'descontoFarmacia',
  'apoioTrabalhador',
  'seguroAlimentacao',
  'totalpass',
  'tertiary',
] as const;

export const allTokenVariantsList = [
  ...defaultTokenVariantList,
  ...productsTokenVariantsList,
] as const;

export const ICON_VR = 'icon-vr';

export const HEADER_HEIGHT = 86;
export const HEADER_MOBILE_HEIGHT = 72;
