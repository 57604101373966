import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

type OfferWarningMessageVariantType = 'alert' | 'warning';

const offerWarningMessageIconVariant: Record<
  OfferWarningMessageVariantType,
  string
> = {
  alert: 'fa-solid fa-circle-exclamation',
  warning: 'fa-solid fa-circle-exclamation',
};

export function OfferWarningMessage({
  message,
  size = 'lg',
  width = '100%',
  variant = 'warning',
  importantText = false,
}: {
  message: string | undefined;
  size?: 'sm' | 'lg';
  width?: string;
  variant?: OfferWarningMessageVariantType;
  importantText?: boolean;
}) {
  if (!message) {
    return null;
  }

  return (
    <OfferWarningMessageContainer
      size={size}
      css={{ $$width: width }}
      variant={variant}
      data-testid={variant}
    >
      <i className={offerWarningMessageIconVariant[variant]} />
      <p>
        {importantText && <strong>Importante:</strong>} {message}
      </p>
    </OfferWarningMessageContainer>
  );
}

const OfferWarningMessageContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '$$width',
  borderRadius: '10px',
  boxShadow: '0px 0px 8px #00000022',

  color: '$tertiaryPure',
  textAlign: 'start',
  lineHeight: 1.7,
  fontWeight: '$medium',

  '@deviceLg': {
    width: '100%',
  },

  variants: {
    size: {
      lg: {
        gap: '1.25rem',

        padding: '1rem',

        fontSize: convertPixelToRem(14),

        i: {
          fontSize: convertPixelToRem(26),
        },
      },
      sm: {
        gap: '0.75rem',

        padding: '1rem',

        fontSize: convertPixelToRem(12),

        i: {
          fontSize: convertPixelToRem(20),
        },
      },
    },
    variant: {
      warning: {
        background: '#FBDEB2',
        i: {
          color: '#D95100',
        },
      },
      alert: {
        background: '#E5F1F7',
        i: {
          color: '#347392',
        },
      },
    },
  },
});
