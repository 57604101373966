import { SliderCarousel } from '@compositions/slider-carousel';
import { CustomerComment } from '@elements/customer-comment';
import { MobilitySectionTitle } from '@elements/mobility-section-title';
import { PageSection } from '@elements/page-section';

import { COMPANY_COMMENTS_VT } from '@config/product-page-config';

import { styled } from '@src/themes';

export function CommentsSection() {
  return (
    <PageSection dark>
      <SectionHolder>
        <MobilitySectionTitle color="$primaryDark">
          Conheça a opinião de quem já simplificou a gestão com o
          Vale-Transporte da VR
        </MobilitySectionTitle>
        <SliderCarousel>
          {COMPANY_COMMENTS_VT.map(companyData => (
            <CustomerComment
              key={companyData.name}
              customer={{
                imageUrl: companyData.imageUrl,
                name: companyData.name,
                company: companyData.company,
              }}
              comment={companyData.comment}
            />
          ))}
        </SliderCarousel>
      </SectionHolder>
    </PageSection>
  );
}

const SectionHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
  justifyContent: 'space-between',

  paddingTop: '3rem',
  paddingBottom: '3rem',

  '.small-cta-section__action-area': {
    a: {
      width: '332px',
      color: '$black',
    },

    '@deviceLg': {
      width: '100%',

      a: {
        maxWidth: '332px',
        width: '100%',
        margin: '0 auto',
      },
    },
  },

  '@deviceLg': {
    gap: '2rem',
    flexDirection: 'column',
  },
});
