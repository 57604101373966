import { useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Dropdown from '@components/dropdown';
import { HCMProductBox } from '@components/hcm-product-box';
import Modal from '@components/modal';
import { Button } from '@elements/button';
import Option from '@elements/option';
import { PageSection } from '@elements/page-section';
import { Title } from '@elements/title';

import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { HCMOptions, MAX_LIFES_HCM } from '@config/hcm-config';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { FormErrors } from '@helpers/enum/form-errors';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { getProductListByModality } from '@helpers/product-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { addPlanToCart } from '@store/slices/cart-slice';
import { closeModal, handleModalContent } from '@store/slices/modal-slice';
import { fetchPricingWithoutLead } from '@store/thunks/cart-thunk';

import { styled } from '@src/themes';

type HCMPlansProps = {
  modalityId: number;
};

export type HCMPlansType = {
  employeesAmount: number;
  planType: PlanTypeEnum;
};

export function HCMPlansSection({ modalityId }: HCMPlansProps) {
  const dispatch = useDispatch();
  const { productList } = useSelector(({ products }) => products);
  const { isCartLoading, productCartList, cartModality } = useSelector(
    ({ cart }) => cart,
  );

  const modalityProductList = getProductListByModality(modalityId, productList);
  const methods = useForm<HCMPlansType>({
    mode: 'onChange',
    defaultValues: {
      employeesAmount: 10,
      planType: PlanTypeEnum.MENSAL,
    },
  });

  const getPlansByProductList = useCallback(() => {
    return modalityProductList.map(({ id }) => {
      return {
        amount: methods.watch('employeesAmount'),
        id,
        type: 'HCM',
        value: 0,
        planType: methods.watch('planType'),
      };
    });
  }, [methods, modalityProductList]);

  const saveSelectedEmployeesAmount = useCallback(async () => {
    const isFormValid = await methods.trigger(['employeesAmount']);
    methods.handleSubmit(async (form: HCMPlansType) => {
      if (isFormValid && form.employeesAmount) {
        await dispatch(fetchPricingWithoutLead(getPlansByProductList()))
          .unwrap()
          .then(res => {
            if (productCartList.length > 0) {
              dispatch(
                addPlanToCart({
                  ...productCartList[0],
                  planType: form.planType,
                  amount: form.employeesAmount,
                  value:
                    res.precificacao.detalhe.produtos.find(
                      product =>
                        product.codigoProduto === productCartList[0].id,
                    )?.valorProdutoComDesconto || 0,
                }),
              );
            }
          });
      }
    })();
  }, [dispatch, getPlansByProductList, methods, productCartList]);

  const getNumberOfEmployees = () => {
    if (productCartList.length > 0 && cartModality?.id === ModalitiesId.HCM) {
      return HCMOptions[productCartList[0].id];
    }
    return HCMOptions.default;
  };

  useEffect(() => {
    saveSelectedEmployeesAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageSection
        css={{
          background: 'linear-gradient(180deg, #034D2E 50%, #F5F5F5 50%)',
        }}
      >
        <FormProvider {...methods}>
          <HCMPlansSectionContainer>
            <strong>Compare e faça a escolha ideal para você</strong>
            <form className="input-box">
              <p>Número de colaboradores</p>
              <Controller
                control={methods.control}
                name="employeesAmount"
                rules={{ required: FormErrors.REQUIRED_DEFAULT }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    id="employeesAmount"
                    label=""
                    value={value}
                    onChange={e => {
                      if (Number(e.target.value) > MAX_LIFES_HCM) {
                        dispatch(
                          handleModalContent({
                            state: true,
                            title: '',
                          }),
                        );
                      } else {
                        onChange(e.target.value);
                        saveSelectedEmployeesAmount();
                      }
                    }}
                    disabled={isCartLoading}
                  >
                    {getNumberOfEmployees().map(option => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Dropdown>
                )}
              />
            </form>
          </HCMPlansSectionContainer>
        </FormProvider>
      </PageSection>
      <PageSection
        css={{
          paddingBottom: '4rem',
          paddingTop: '1.5rem',
          backgroundColor: '#F5F5F5',
        }}
      >
        <FormProvider {...methods}>
          <div className="plans-holder">
            {modalityProductList?.map(product => (
              <HCMProductBox key={product.id} productData={product} />
            ))}
          </div>
        </FormProvider>
      </PageSection>
      <Modal>
        <div className="alignment">
          <img
            src={`${env.URL_ASSETS + ASSETS_OTHERS_PATH}/shelf-product-hcm.png`}
            alt="Imagem de uma profissional de RH mexendo em seu notebook"
          />
          <Title.Default tag="h2">
            Você tem mais de 100 trabalhadores!
          </Title.Default>
          <p>
            Te encaminharemos para uma página onde poderemos te oferecer um
            atendimento mais personalizado. Vamos juntos encontrar a solução
            ideal para sua empresa?
          </p>
          <br />
          <Button.Anchor
            id="btn-modal-cliente-mais-cem-vidas"
            href="https://pontomais.com.br/loja-vr-plano-custom/"
            onClick={() => dispatch(closeModal())}
          >
            Me leve para a página
          </Button.Anchor>
        </div>
      </Modal>
    </>
  );
}

const HCMPlansSectionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  gap: '1rem',
  paddingBottom: '1rem',

  strong: {
    fontSize: '1.125rem',
    color: '$white',
  },

  '.input-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',

    backgroundColor: '$white',
    padding: '1rem',

    borderRadius: '8px',

    p: {
      fontSize: '0.875rem',
      whiteSpace: 'nowrap',
      fontWeight: '600',
    },

    '#employeesAmount': {
      width: '170px',
    },

    '@deviceMd': {
      '#employeesAmount': {
        paddingBottom: 0,
        width: '120px',
      },
    },

    '@deviceSm': {
      width: '100%',
    },
  },

  '@deviceMd': {
    justifyContent: 'center',

    strong: {
      fontSize: '1rem',
    },
  },

  '@deviceXs': {
    strong: {
      fontSize: '0.875rem',
    },
  },
});
