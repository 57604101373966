import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { Button } from '@elements/button';
import { ServiceNextStep } from '@elements/service-next-step';
import { ComboServiceSummary } from '@pages/offer-page/custom-offer-pages/compositions/combo-service-summary';

import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { isComboFlux } from '@helpers/flux-helpers';
import {
  getModalityData,
  getModalityListByProductList,
} from '@helpers/modality-helpers';
import { removeSpecificQueryParamFromSearch } from '@helpers/query-string-helpers';
import { getServiceList } from '@helpers/service-helpers';
import { getFirstName } from '@helpers/string-helpers';
import {
  convertPixelToRem,
  holderStyleWithMobileResponsive,
} from '@helpers/style-helpers';

import { useContract } from '@hooks/use-contract';
import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { handleCustomerArrivedCheckoutPage } from '@store/slices/customer-slice';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

export function CheckoutPage() {
  const { getDownloadContract, isLoading } = useContract();
  const location = useLocation();
  const dispatch = useDispatch();
  const { selectedOffer } = OfferFacade();
  const username = useSelector(({ customer }) => customer.username);
  const { comboCartPricingData, cartPricingData, productCartList, planType } =
    useSelector(({ cart }) => cart);
  const { productList: apiProductDataList, modalityList } = useSelector(
    ({ products }) => products,
  );
  const initialPage = useSelector(({ application }) => application.initialPage);

  const productPricedDataList = isComboFlux(selectedOffer)
    ? comboCartPricingData
    : cartPricingData;

  useEffect(() => {
    dispatch(handleCustomerArrivedCheckoutPage(true));
  }, [dispatch]);

  if (apiProductDataList.length === 0 || !productPricedDataList) {
    return (
      <Navigate
        to={{
          pathname: initialPage,
          search: removeSpecificQueryParamFromSearch({
            queryString: 'offerid',
            search: location.search,
          }),
        }}
        state={{ from: location.pathname }}
      />
    );
  }

  const apiProductPricedList = apiProductDataList.filter(apiProduct =>
    productPricedDataList.detalhe.produtos.some(
      productPriced => productPriced.codigoProduto === apiProduct.id,
    ),
  );

  const serviceList = getServiceList(
    getModalityListByProductList(apiProductPricedList).map(modalityId =>
      getModalityData(modalityId, modalityList),
    ),
  );

  const totalServiceBenefityOrder = () => {
    if (planType === PlanTypeEnum.ANUAL) {
      return (
        productPricedDataList.detalhe.produtos.find(
          product => product.tipoProduto === ServiceGroupId.HCM,
        )?.valorProdutoComDesconto || 0
      );
    }
    return productPricedDataList.resumo?.valorTotal || 0;
  };

  const extensionsValue = productCartList.flatMap(
    plan => plan.additionalProducts?.map(product => product.value) || [],
  );

  const totalServiceOrder = [
    totalServiceBenefityOrder(),
    ...extensionsValue,
  ].reduce((acc, value) => acc + value, 0);

  return (
    <CheckoutPageContainer>
      <h2>
        <strong>{getFirstName(username)}</strong>, estamos felizes de ter você
        com a gente!
      </h2>
      <div className="checkout-page-container__contract-wrapper">
        <div className="contract-wrapper__download-container">
          <p>Seu contrato está disponível para download aqui:</p>
        </div>
        <Button.Default
          onClick={getDownloadContract}
          loading={isLoading}
          size="small"
        >
          <i className="fas fa-download" />
          Download do contrato
        </Button.Default>
      </div>
      <div className="next-steps-holder">
        <h2>Próximos passos:</h2>
        {serviceList.map(service => (
          <ServiceNextStep key={service.id} serviceData={service} />
        ))}
      </div>
      <div className="checkout-page-container__summary-wrapper">
        <h2>Resumo da compra</h2>
        {serviceList.flatMap(service =>
          service.modalityList.flatMap(modality => (
            <ComboServiceSummary key={service.id} modalityData={modality} />
          )),
        )}
        <div className="summary-wrapper__total-order">
          <p>VALOR TOTAL DO PEDIDO*</p>
          <strong>{formatValueToCurrency(totalServiceOrder)}</strong>
        </div>
        {serviceList.length > 1 && (
          <div className="summary-wrapper__payment-warning">
            <i className="fa-solid fa-circle-exclamation" />
            <span>
              *Os pagamentos serão realizados de forma apartada por serviço.
            </span>
          </div>
        )}
      </div>
      <Button.Anchor
        id="btn-pagamento-pagina-inicial"
        variant="secondary"
        type="button"
        href={`${initialPage}${removeSpecificQueryParamFromSearch({
          queryString: 'offerid',
          search: location.search,
        })}`}
      >
        <i className="fas fa-home" />
        Voltar à página inicial
      </Button.Anchor>
    </CheckoutPageContainer>
  );
}

const CheckoutPageContainer = styled('div', {
  ...holderStyleWithMobileResponsive,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  width: '100%',
  maxWidth: '1044px',

  '> h2': {
    margin: '1.5rem 0',

    fontSize: '1.5rem',
    fontWeight: '$regular',
    lineHeight: 1.3,
    textAlign: 'center',

    '@deviceSm': {
      fontSize: convertPixelToRem(18),
    },
  },

  '> a': {
    margin: '3rem 0',
  },

  '.next-steps-holder': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',

    width: '100%',

    h2: {
      fontSize: '1.5rem',
      fontWeight: '$medium',

      '@deviceSm': {
        fontSize: convertPixelToRem(18),
      },
    },
  },

  '.checkout-page-container__contract-wrapper': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '1rem',

    width: '100%',
    padding: '1.5rem',
    borderTop: '1px solid #E4E8ED',
    borderBottom: '1px solid #E4E8ED',

    button: {
      minWidth: '13.25rem',
    },

    '@deviceMd': {
      flexDirection: 'column',
      alignItems: 'center',

      p: {
        textAlign: 'center',
      },
    },
  },

  '.checkout-page-container__contract-wrapper, .next-steps-holder': {
    marginBottom: '1.5rem',
  },

  '.checkout-page-container__summary-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1rem',

    width: '100%',

    h2: {
      color: '$primaryPure',
      fontSize: '1rem',
      fontWeight: '$bold',
    },
  },

  '.contract-wrapper__download-container': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',

    p: {
      fontSize: convertPixelToRem(18),
      fontWeight: '$semibold',
    },

    '@deviceSm': {
      alignItems: 'center',
      fontSize: convertPixelToRem(18),
    },
  },

  '.download-container__icon-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,

    backgroundColor: '#6D839A',
    borderRadius: '50%',

    height: '2rem',
    width: '2rem',

    i: {
      fontSize: '1rem',
      color: '$white',
    },
  },

  '.summary-wrapper__total-order': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '100%',

    fontSize: '0.75rem',
    fontWeight: '$bold',

    strong: {
      color: '$primaryPure',
      fontSize: '1rem',
    },
  },

  '.summary-wrapper__payment-warning': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',

    i: {
      color: '#FFC600',
      fontSize: '1rem',
    },

    span: {
      fontSize: '0.75rem',
      fontWeight: '$medium',
    },
  },
});
