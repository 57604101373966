import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const CartContainer = styled('aside', {
  display: 'flex',
  flexDirection: 'column',

  position: 'absolute',
  top: convertPixelToRem(80),
  right: '1rem',

  width: '100%',
  maxWidth: convertPixelToRem(400),
  maxHeight: convertPixelToRem(650),
  background: '$white',
  borderRadius: '10px',

  '&.cart-content-enter': {
    opacity: 0,
  },

  '&.cart-content-enter-active': {
    opacity: 1,
    transition: 'opacity 0.4s ease',
  },

  '&.cart-content-exit': {
    opacity: 1,
  },

  '&.cart-content-exit-active': {
    opacity: 0,
    transition: 'opacity 0.4s ease',
  },

  '&:before': {
    position: 'absolute',
    top: '-10px',
    right: convertPixelToRem(20),
    content: '',

    background: '$white',
    height: '25px',
    width: '25px',
    transform: 'rotate(45deg)',
  },

  '@media screen and (min-width: 601px) and (max-height: 740px)': {
    maxHeight: 'calc(100vh - 85px)',
  },

  '@deviceSm': {
    position: 'fixed',
    right: 0,
    top: convertPixelToRem(72),
    left: 0,
    bottom: 0,

    width: 'calc(100% - 10px)',
    maxWidth: 'unset',
    margin: '0 5px 5px',
    minHeight: 'calc(100% - 77px)',
    maxHeight: 'unset',

    '&:before': {
      right: convertPixelToRem(11),
    },
  },
});

export const CartContainerHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: '1rem',
  borderBottom: 'solid 1px $silverLight',

  p: {
    color: '$primaryDark',
    fontWeight: '$bold',
  },

  '> button': {
    background: 'transparent',
    padding: '0.25rem',
    transition: 'color 0.4s ease',

    fontSize: convertPixelToRem(18),
    lineHeight: 0,

    color: '$silverDark',

    '&:hover': {
      color: '$primaryDark',
    },
  },
});
