import { MODALITY_NEW_TEXT } from '@config/modality-config';

import { styled } from '@src/themes';

export function FeaturedShelfFlag({ isEnabled }: { isEnabled: boolean }) {
  if (!isEnabled) {
    return null;
  }

  return (
    <FeaturedShelfFlagContainer>{MODALITY_NEW_TEXT}</FeaturedShelfFlagContainer>
  );
}

const FeaturedShelfFlagContainer = styled('span', {
  position: 'absolute',
  top: '0',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  backgroundColor: '$featuredFlagBackground',
  padding: '0.25rem 1rem',
  borderRadius: '$pill',

  color: '$white',
  fontSize: '0.875rem',
  fontWeight: '$medium',
});
