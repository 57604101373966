import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CartPageBonusItem } from '@components/cart-page-product-list-form-components/cart-page-bonus-item';
import { CartPageFormHeader } from '@components/cart-page-product-list-form-components/cart-page-form-header';
import { CartPageFormTotalValue } from '@components/cart-page-product-list-form-components/cart-page-form-total-value';
import { CartPageWalletFormProductItem } from '@components/cart-page-product-list-form-components/cart-page-wallet-form-product-item';
import { CartPageWalletFormWalletItem } from '@components/cart-page-product-list-form-components/cart-page-wallet-form-wallet-item';
import { WalletFormInputs } from '@compositions/wallet-product-form';

import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { WalletProductFormType } from '@extra-types/product-form-types';
import {
  calcTotalValueCartByModality,
  createPricingBonusList,
} from '@helpers/cart-helpers';
import {
  getCartproductIdList,
  getProductData,
  getProductType,
  removePrefixProduct,
} from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

import { WalletFormContainer, ProductListContainer } from './styles';

export function CartPageWalletProductListForm() {
  const productList = useSelector(({ products }) => products.productList);
  const { productCartList, cartPricingData, cartModality, contractedProducts } =
    useSelector(({ cart }) => cart);
  const { unservedRegionProducts } = useSelector(({ customer }) => customer);

  const cartproductIdList = useMemo(() => {
    return getCartproductIdList(
      productList,
      cartModality?.id,
      productCartList,
      contractedProducts,
      unservedRegionProducts,
    );
  }, [
    productList,
    cartModality?.id,
    productCartList,
    contractedProducts,
    unservedRegionProducts,
  ]);

  const bonusList = useMemo(
    () => (cartPricingData ? createPricingBonusList(cartPricingData) : []),
    [cartPricingData],
  );

  const methods = useForm<WalletFormInputs>({
    mode: 'onChange',
    defaultValues:
      productCartList.length > 0
        ? {
            amount: productCartList[0].amount,
            products: productCartList.reduce((defaultValuesList, product) => {
              return {
                ...defaultValuesList,
                [`product-${product.id}`]: {
                  value: product.value.toFixed(2),
                },
              };
            }, {} as WalletProductFormType),
          }
        : undefined,
  });

  const amount = methods.watch('amount');
  const productsToWatch = methods.watch('products');

  const totalProductCardAmount = amount || 0;

  const totalAmountOfBalances = Object.entries(productsToWatch).length || 0;

  const totalValueCart = calcTotalValueCartByModality({
    cartmodalityId: cartModality?.id,
    productCartList: Object.entries(productsToWatch).map(
      ([productTextId, product]) => {
        const productId = removePrefixProduct(productTextId);
        const productData = getProductData(productId, productList);
        return {
          id: productId,
          type: getProductType(productData),
          amount: amount || 0,
          value: product.value ? Number(product.value) : 0,
          planType: PlanTypeEnum.MENSAL,
        };
      },
    ),
  });

  return (
    <FormProvider {...methods}>
      <WalletFormContainer>
        <CartPageWalletFormWalletItem />
        <ProductListContainer>
          <CartPageFormHeader isWalletGroup />
          {cartproductIdList.map(productId => (
            <CartPageWalletFormProductItem
              key={productId}
              productId={productId}
            />
          ))}
          {contractedProducts.map(
            contractedProduct =>
              contractedProduct.modalityId === cartModality?.id && (
                <CartPageWalletFormProductItem
                  key={contractedProduct.id}
                  productId={contractedProduct.id}
                  contracted
                />
              ),
          )}
          {bonusList.map(bonusItem => (
            <CartPageBonusItem key={bonusItem.id} data={bonusItem} />
          ))}
          <CartPageFormTotalValue
            isWalletGroup
            totalCardsAmount={totalProductCardAmount}
            totalAmountProducts={totalAmountOfBalances}
            totalValue={totalValueCart}
            bonusAmount={bonusList.length}
          />
        </ProductListContainer>
      </WalletFormContainer>
    </FormProvider>
  );
}
