import { BACK_BUTTON_PAGE_BLACK_LIST } from '@config/application-config';

export function verifyBackButtonPageBlackList({
  currentPage,
  initialPage,
}: {
  currentPage: string;
  initialPage: string;
}) {
  if (currentPage === initialPage) {
    return false;
  }

  return !BACK_BUTTON_PAGE_BLACK_LIST.some(page => page === currentPage);
}

export function redirectToAnotherPage(page: string) {
  window.location.replace(page);
}
