import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import type { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import { CSSTransition } from 'react-transition-group';

import clearNotNumber from '@vr/devkit/utils/clearNotNumber';

import { Button } from '@elements/button';
import Checkbox from '@elements/checkbox';
import Loader from '@elements/loader';
import { Title } from '@elements/title';

import { env } from '@config/env';
import { ApiErrorsModalTitle } from '@helpers/enum/api-error-messages';
import { getMessageError } from '@helpers/error-helpers';

import { useContract } from '@hooks/use-contract';
import useDefaultHeader from '@hooks/use-default-header';
import { useDispatch } from '@hooks/use-dispatch';
import { useRoutes } from '@hooks/use-routes';
import { useSelector } from '@hooks/use-selector';
import { DevicesType, useWindowSize } from '@hooks/use-window-size';
import { handleWarningModalContent } from '@store/slices/warning-modal-slice';

import ContractRequest from '@api/models/request/contract-request';
import hiringService from '@api/services/hiring-service';
import ApplicationError from '@errors/types/application-error';

import { ContractModalPreviewContainer } from './styles';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

interface ContractModalProps {
  modalVisibility: boolean;
  handleModalVisibility: () => void;
  contractFileBlob?: Blob;
}

const ANIMATION_TIME = 400;

export function ContractModalPreview({
  modalVisibility,
  contractFileBlob,
  handleModalVisibility,
}: ContractModalProps) {
  pdfjs.GlobalWorkerOptions.workerSrc = `${env.URL_ASSETS}/scripts/loja/pdf.worker.min.js`;
  const { companyData, representativeData, registerData } = useSelector(
    ({ customer }) => customer,
  );
  const nodeRef = useRef(null);
  const dispatch = useDispatch();
  const contract = useContract();
  const { nextStep } = useRoutes();
  const windowSize = useWindowSize();
  const { defaultHeader } = useDefaultHeader();
  const [contractChecked, setContractChecked] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState<number>();
  const [messageError, setMessageError] = useState(false);
  const [PDFLoad, setPDFLoad] = useState(true);
  const [fetchLoad, setFetchLoad] = useState(false);

  const pdfScales: { [key in DevicesType]: number } = {
    xs: 0.57,
    sm: 0.64,
    md: 0.85,
    lg: 96 / 72,
  };

  const changeOverflowToInvisible = () => {
    document.body.style.overflow = 'hidden';
  };

  const changeOverflowToVisible = () => {
    document.body.style.overflow = 'auto';
  };

  function handleContractCheck() {
    setContractChecked(contractCheck => !contractCheck);
    setMessageError(false);
  }

  const handleRegisterCartAndGoToTheNextPage = async () => {
    if (!contractChecked) {
      setMessageError(true);
    } else {
      setFetchLoad(true);
      try {
        const requestContractBody = new ContractRequest({
          nomeFantasia: companyData.nomeFantasia,
          dadosCadastrais: {
            emailCadastro: registerData.emailCadastro,
            nomeCadastro: registerData.nomeCadastro,
            telefoneCadastro: registerData.telefoneCadastro,
            dataNascimento: registerData.dataNascimento,
            cpfContato: registerData.cpfContato,
          },
          contatoExtra: representativeData.extraContact,
          representanteLegal: {
            documento: clearNotNumber(representativeData.document),
            nome: representativeData.name,
            tipo: representativeData.type,
            politicamenteExposta: representativeData.politicallyExposed,
          },
          enderecoLocalEntrega: {
            ...companyData.enderecoEntrega,
            cep: clearNotNumber(companyData.enderecoEntrega.cep),
          },
        });

        await hiringService.createHiring(
          defaultHeader,
          companyData.lead,
          companyData.idCarrinho,
          requestContractBody,
        );

        setMessageError(false);
        nextStep();
      } catch (error) {
        dispatch(
          handleWarningModalContent({
            isVisible: true,
            title: ApiErrorsModalTitle.DEFAULT,
            content: getMessageError(error),
            closeAction: {
              label: 'Fechar',
              action:
                error instanceof ApplicationError ? 'close' : 'reloadPage',
            },
          }),
        );
      }

      setFetchLoad(false);
    }
  };

  const onDocumentLoadSuccess = ({
    numPages: nextNumberOfPages,
  }: PDFDocumentProxy) => {
    setNumberOfPages(nextNumberOfPages);
    setPDFLoad(false);
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={modalVisibility}
      timeout={ANIMATION_TIME}
      classNames="modal"
      unmountOnExit
      onEnter={changeOverflowToInvisible}
      onExit={changeOverflowToVisible}
    >
      <ContractModalPreviewContainer ref={nodeRef} isPDFLoading={PDFLoad}>
        <div className="modal-box modal-box__container">
          <button
            type="button"
            className="close-button"
            onClick={() => handleModalVisibility()}
            disabled={contract.isLoading || fetchLoad}
          >
            <i className="fa-solid fa-xmark" />{' '}
          </button>
          <Title.Root size="sm" barColor="secondary">
            <Title.Default tag="h2">Aqui está seu contrato</Title.Default>
            <Title.Subtitle className="modal-subtitle">
              Após ler o contrato você precisa concordar com os termos e
              condições comerciais para concluir a sua contratação.
            </Title.Subtitle>
          </Title.Root>
          <div className="modal-box modal-box__content">
            <Document
              file={contractFileBlob}
              loading={<Loader className="loader" color="$primaryPure" />}
              onLoad={() => setPDFLoad(true)}
              onLoadStart={() => setPDFLoad(true)}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numberOfPages), (_, index) => (
                <Page
                  scale={pdfScales[windowSize]}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  loading={null}
                />
              ))}
            </Document>
          </div>
          <div className="modal-box modal-box__checkbox-area">
            <Checkbox
              isChecked={contractChecked}
              onCheck={() => handleContractCheck()}
              messageError={messageError}
              disabled={fetchLoad || contract.isLoading}
            >
              Li e concordo com o documento contendo os termos e condições
              comerciais do(s) contrato(s) contidos nele.
            </Checkbox>
          </div>

          <div className="modal-box modal-box__action-area">
            <Button.Default
              id="btn-pedido-modal-contratar-avancar"
              outline
              onClick={() => contract.getDownloadContract()}
              disabled={fetchLoad}
              loading={contract.isLoading}
            >
              <i className="fa-solid fa-file-arrow-down" />
              Fazer download do contrato
            </Button.Default>
            <Button.Default
              id="btn-pedido-modal-contratar-avancar"
              onClick={handleRegisterCartAndGoToTheNextPage}
              loading={fetchLoad}
              disabled={contract.isLoading}
            >
              Concluir
            </Button.Default>
          </div>
        </div>
      </ContractModalPreviewContainer>
    </CSSTransition>
  );
}
