import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { EntryTax } from '@elements/cart-summary-elements/entry-tax';
import { OfferItem } from '@elements/cart-summary-elements/offer-item';
import Details from '@elements/details';

import { AdditionalProductTypeEnum } from '@extra-types/additional-product-type';
import { ModalityType } from '@extra-types/modality-type';
import { PricingType } from '@extra-types/pricing-types';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { OrderCommercialConditionsLabel } from '@helpers/enum/order-commercial-conditions-label';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

import { EntryProductList } from './entry-product-list';

export function SummaryDetails({
  data,
  cartModality,
  isCollapsed,
}: {
  data: PricingType;
  cartModality: ModalityType;
  isCollapsed: boolean;
}) {
  const {
    requiredAdditionalProductList,
    extraAdditionalProductList,
    productCartList,
  } = useSelector(({ cart }) => cart);
  const productsTax = data.resumo.valorTotalTaxas || 0;

  const deliveryFee =
    data.resumo.servicos[cartModality.serviceGroupId].lancamentos.find(entry =>
      entry.nome.includes(OrderCommercialConditionsLabel.DELIVERY_FEE),
    )?.valorTotal || 0;

  const planValue = productCartList.map(({ type, value, amount }) => {
    if (type === ServiceGroupId.BENEFIT) {
      return value * amount + data.resumo.valorTotalTaxas;
    }
    return data.detalhe.produtos
      .map(p => p.valorProdutoComDesconto || p.valorProduto)
      .reduce((prev, curr) => prev + curr);
  });

  const extensionsValue = productCartList.flatMap(
    plan => plan.additionalProducts?.map(product => product.value) || [],
  );

  const totalOrder = [...planValue, ...extensionsValue].reduce(
    (acc, value) => acc + value,
    0,
  );

  if (cartModality.id === ModalitiesId.HCM) {
    return (
      <CartPageSummaryDetailsContainer>
        <CartPageSummaryDetailsEntryListContainer collapsed={isCollapsed}>
          <EntryProductList productList={data.detalhe.produtos} />
          <Details summary="Módulos" variant="secondary">
            <div className="additional-products-content">
              {requiredAdditionalProductList.required.map(
                product =>
                  product.productType === AdditionalProductTypeEnum.MODULO && (
                    <div key={`${product.id}-in-cart`}>
                      <strong>{product.name}</strong>
                    </div>
                  ),
              )}
            </div>
          </Details>
          <Details summary="Extensões" variant="secondary">
            <div className="additional-products-content">
              {[
                ...requiredAdditionalProductList.required,
                ...extraAdditionalProductList,
              ].map(
                product =>
                  product.productType ===
                    AdditionalProductTypeEnum.EXTENSAO && (
                    <div key={`${product.id}-in-cart`}>
                      <strong>{product.name}</strong>
                    </div>
                  ),
              )}
            </div>
          </Details>
        </CartPageSummaryDetailsEntryListContainer>
        <CartPageSummaryDetailsProductTotalValueContainer>
          <p>
            <strong>Total do pedido:</strong>
          </p>
          <p>{formatValueToCurrency(totalOrder)}</p>
        </CartPageSummaryDetailsProductTotalValueContainer>
      </CartPageSummaryDetailsContainer>
    );
  }

  return (
    <CartPageSummaryDetailsContainer>
      <CartPageSummaryDetailsEntryListContainer collapsed={isCollapsed}>
        <EntryProductList productList={data.detalhe.produtos} />
        <EntryTaxContainer>
          <EntryTax
            type="product-tax"
            value={productsTax}
            entryData={data.detalhe.produtos.map(
              product => product.lancamentos,
            )}
          />
          <EntryTax type="delivery-tax" value={deliveryFee} />
        </EntryTaxContainer>
        <OfferItem />
      </CartPageSummaryDetailsEntryListContainer>
      <CartPageSummaryDetailsProductTotalValueContainer>
        <p>
          <strong>Total do pedido:</strong>
        </p>
        <p>{formatValueToCurrency(totalOrder)}</p>
      </CartPageSummaryDetailsProductTotalValueContainer>
    </CartPageSummaryDetailsContainer>
  );
}

const CartPageSummaryDetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const CartPageSummaryDetailsEntryListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  marginBottom: '1rem',
  overflow: 'hidden',
  opacity: 1,
  height: '100%',
  transition: 'opacity 0.4s ease',

  '.additional-products-content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    padding: '0 1rem',
  },

  variants: {
    collapsed: {
      true: {
        '@media only screen and (max-width: 1114px)': {
          height: 0,
          opacity: 0,
          marginBottom: '0',
        },
      },
    },
  },
  defaultVariants: {
    collapsed: false,
  },
});

const EntryTaxContainer = styled('div', {
  '> div:first-child': {
    borderTop: '1px solid $silverLight',
  },

  '> div': {
    borderBottom: '1px solid $silverLight',
  },
});

const CartPageSummaryDetailsProductTotalValueContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  fontSize: convertPixelToRem(14),

  p: {
    color: '$primaryPure',
    fontWeight: '$semibold',

    strong: {
      color: '$fontColorPrimary',
    },
  },
});
