import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ASSETS_LOGOS_PATH } from '@config/assets-config';
import { MODALITIES } from '@config/modality-config';
import {
  PRODUCT_COMMERCIAL_CONDITIONS,
  ProductData,
  PRODUCTS,
} from '@config/products-config';
import { CommercialConditionType } from '@extra-types/commercial-condition-type';
import { ModalityType } from '@extra-types/modality-type';
import {
  PricingDetailProductType,
  PricingEntriesType,
} from '@extra-types/pricing-types';
import { ProductCartType } from '@extra-types/product-cart-type';
import { ProductContractedType } from '@extra-types/product-contracted-type';
import { ProductPricedFullDataType } from '@extra-types/product-priced-full-data-type';
import { ProductType } from '@extra-types/product-type';
import { RemovedProductPricingType } from '@extra-types/removed-product-pricing-type';
import { RemovedProductType } from '@extra-types/removed-product-type';

import { ProductResponse } from '@api/models/response/product-list-response';
import { ProductContractedResponse } from '@api/models/response/search-company-response';

import { Emissor } from './enum/emissor';
import { OfferType } from './enum/offer-type';
import { ProductsId } from './enum/products-id';
import { ServiceGroupId } from './enum/service-group-id';
import { getModalityStaticData } from './modality-helpers';

/**
 * Função de criação de objeto de fallback de produto com informações
 * estáticas.
 * @param {number} id ID de um produto não existente.
 * @returns Um objeto para ser usado de fallback.
 */
function createFallbackProduct(id: number): ProductData {
  return {
    id,
    acceptanceNetworkList: [],
    cardPath: `${ASSETS_LOGOS_PATH}/logo-default.svg`,
    colorPrefixToken: 'refeicao',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-default.svg`,
    name: 'Produto não cadastrado',
    showInMapList: false,
    showAsteriskPatAux: false,
    emissor: 'all',
  };
}

/**
 * Função de busca de produto dentro do objeto PRODUCTS.
 * Caso não seja encontrado um produto, então será executada uma função
 * de fallback para enviar um produto vazio, ou seja, a função nunca
 * retornará undefined.
 * @param id ID para buscar o produto.
 * @returns Um objeto do tipo ProductData.
 */
export function getProductStaticData(id: number): ProductData {
  return PRODUCTS[id] ?? createFallbackProduct(id);
}

export function getProductData(id: number, productList: ProductType[]) {
  return productList.find(product => product.id === id);
}

export function getProductType(productData?: ProductType) {
  return productData?.type || ServiceGroupId.UNKNOWN;
}

/**
 * Função que filtra uma lista de produtos recebida
 * removendo produtos que possuem o mesmo id que modalidades.
 * @param productList Uma lista de produtos.
 * @returns Uma lista de produtos filtrada.
 */
export function filterProductListWithoutProductWithSameIdAsModality<
  T extends ProductType | ProductContractedType,
>(productList: T[]): T[] {
  return productList.filter(
    product =>
      !Object.entries(MODALITIES).some(
        modalityData => modalityData[1].id === product.id,
      ),
  );
}

/**
 * Função que filtra uma lista de produtos recebida
 * removendo produtos que possuem o typeOffer igual ao
 * typeOffer recebido no segundo parâmetro.
 * @param productList Uma lista de produtos.
 * @param typeOffer O tipo que será filtrado.
 * @returns Uma lista de produtos filtrada.
 */
export const filterProductListByOfferType = (
  productList: ProductType[],
  offerType: OfferType,
) => {
  return productList.filter(product => product.offerType === offerType);
};

/**
 * Função que filtra uma lista de produtos recebida
 * que remove produtos que possuem o typeOffer diferente de
 * SIMULE_COMPRE e remove produtos que possuem o mesmo id
 * que qualquer modalidade.
 * @param productList Uma lista de produtos.
 * @returns Uma lista de produtos filtrada.
 */
export const getCleanProductList = (
  productList: ProductType[],
): ProductType[] => {
  const cleanProductList =
    filterProductListWithoutProductWithSameIdAsModality(productList);

  return filterProductListByOfferType(cleanProductList, OfferType.SALE);
};

export function getProductListByModality<T extends ProductType>(
  modalityId: number,
  productList: Array<T>,
): Array<T> {
  return productList.filter(
    product => product.modalityId === modalityId && product.id !== modalityId,
  );
}

export const getProductListGroupedByModality = (
  productList: ProductType[],
  modalityList: ModalityType[],
) => {
  return [...modalityList]
    .filter(
      modality =>
        productList.filter(product => product.modalityId === modality.id)
          .length > 0,
    )
    .filter(modality => modality.canBeOrderedSolo)
    .map(modalityData => {
      return {
        id: modalityData.id,
        title: modalityData.shelfInformation.title,
        pageRoute: modalityData.pageRoute,
        productList: productList.filter(
          product => product.modalityId === modalityData.id,
        ),
      };
    });
};

/**
 * Função que retorna a lista de produtos enviada no primeiro parâmetro sem os produtos
 * da lista de produtos contratados enviados no segundo parâmetro.
 * @param productList Lista de produtos que irá ser filtrada
 * @param contractedProductList Lista de produtos contratados que serão removidos
 * @returns Lista de produtos sem os produtos contratados
 */
export const filterProductListByContractedProductList = (
  productList: ProductType[],
  contractedProductList: ProductContractedType[],
) => {
  return productList.filter(
    registeredProduct =>
      !contractedProductList.some(
        contractedProduct => contractedProduct.id === registeredProduct.id,
      ),
  );
};

export const filterProductListByRemovedProductList = (
  productList: ProductType[],
  removedProductList: RemovedProductType[],
) => {
  return productList.filter(
    registeredProduct =>
      !removedProductList.some(
        removedProduct => removedProduct.productId === registeredProduct.id,
      ),
  );
};

export const filterProductListByUnservedProductList = ({
  productList,
  unservedproductIdList,
}: {
  productList: ProductType[];
  unservedproductIdList: number[];
}) => {
  return productList.filter(registeredProduct => {
    return !unservedproductIdList.some(
      unservedproductId => unservedproductId === registeredProduct.id,
    );
  });
};

export const filterProductListByProductCartList = ({
  productList,
  productCartList,
}: {
  productList: ProductType[];
  productCartList: ProductCartType[];
}) => {
  return productList.filter(registeredProduct => {
    return !productCartList.some(
      productCart => productCart.id === registeredProduct.id,
    );
  });
};

export const filterProductListByProductIdList = ({
  productList,
  productIdList,
}: {
  productList: ProductType[];
  productIdList: ProductsId[];
}): ProductType[] => {
  if (productIdList.length === 0) {
    return productList;
  }

  return productList.filter(product => productIdList.includes(product.id));
};

export const deleteProductsFromProductList = ({
  productList,
  productsToDelete,
}: {
  productList: ProductType[];
  productsToDelete: ProductsId[];
}) => {
  if (productsToDelete.length === 0) {
    return productList;
  }

  return productList.filter(product => !productsToDelete.includes(product.id));
};

export function createListOfProductTax(
  allProductsEntryList: Array<PricingEntriesType[]>,
) {
  const commercialConditionType: CommercialConditionType = 'tax';

  return Object.entries(PRODUCT_COMMERCIAL_CONDITIONS)
    .filter(
      commercialCondition =>
        commercialCondition[1].type === commercialConditionType,
    )
    .map(([commercialConditionId, commercialConditionData]) => {
      const totalTaxValue = allProductsEntryList.reduce(
        (totalValueTax, productEntryList) => {
          const entryData = productEntryList.find(
            entry =>
              entry.identificacaoCondicaoComercial ===
              Number(commercialConditionId),
          );
          if (entryData) {
            return totalValueTax + entryData.valorTotal;
          }
          return totalValueTax;
        },
        0,
      );

      return {
        id: commercialConditionId,
        label: commercialConditionData.label,
        totalTaxValue,
      };
    });
}

export const getAvailableProductList = ({
  productList,
  contractedProductList,
  unservedProductList,
  productCartList,
}: {
  productList: ProductType[];
  contractedProductList: ProductContractedType[];
  unservedProductList: number[];
  productCartList?: ProductCartType[];
}) => {
  const cleanProductsList = getCleanProductList(productList);
  const productListWithoutContractedProducts =
    filterProductListByContractedProductList(
      cleanProductsList,
      contractedProductList,
    );
  const productListWithoutUnservedProducts =
    filterProductListByUnservedProductList({
      productList: productListWithoutContractedProducts,
      unservedproductIdList: unservedProductList,
    });

  if (productCartList !== undefined) {
    return filterProductListByProductCartList({
      productList: productListWithoutUnservedProducts,
      productCartList,
    });
  }

  return productListWithoutUnservedProducts;
};

export function removePrefixProduct(name: string) {
  return Number(name.split('-')[1]);
}

export function convertContractedProductsToProductList(
  contractedProducts: ProductContractedType[],
  productList: ProductType[],
) {
  return productList.filter(product =>
    contractedProducts.some(
      contractedProduct => contractedProduct.id === product.id,
    ),
  );
}

export function getProductsId(productCartList: ProductCartType[]) {
  return productCartList.slice().map(productCart => productCart.id);
}

export function valueSelector({ value }: { value: number }) {
  return value > 0 ? (
    <span>{formatValueToCurrency(value)}</span>
  ) : (
    <span className="offer-value">Grátis</span>
  );
}

export function getProductByPagePath(
  pagePath: string,
  emissor: Emissor,
  productList: ProductType[],
) {
  return productList.find(
    product => product.pagePath === pagePath && product.emissor === emissor,
  );
}

/**
 * Função que formata uma lista de objetos do tipo ProductContractedResponse para uma lista de objetos
 * do tipo ProductContractedType
 * @param {ProductContractedResponse[]} productContractedResponse
 * @returns {ProductContractedType[]}
 */
export function formatProductContractedResponseToProductContracted(
  productContractedResponse: ProductContractedResponse[],
): ProductContractedType[] {
  return productContractedResponse.map(productContracted => ({
    id: productContracted.codigo,
    name: productContracted.nome,
    description: productContracted.descricao,
    modalityId: productContracted.codigoAgrupador,
  }));
}

export function getCartproductIdList(
  productList: ProductType[],
  cartModality: number | undefined,
  productCartList: ProductCartType[],
  contractedProducts: ProductContractedType[],
  unservedRegionProducts: number[],
  offerAvailableProducts?: number[],
) {
  const cartProductList = getProductsId(productCartList);
  const productIdList = productList
    .slice()
    .filter(
      product =>
        !contractedProducts.some(
          contractedProduct => contractedProduct.id === product.id,
        ),
    )
    .filter(
      product =>
        !unservedRegionProducts.some(productId => productId === product.id),
    )
    .filter(
      product =>
        product.modalityId === cartModality &&
        product.id !== cartModality &&
        product.offerType !== OfferType.INTEREST,
    )
    .filter(
      product =>
        !cartProductList.some(cartProduct => cartProduct === product.id),
    )
    .filter(product => {
      if (offerAvailableProducts && offerAvailableProducts.length > 0) {
        return offerAvailableProducts.some(
          offerProduct => offerProduct === product.id,
        );
      }
      return true;
    })
    .map(product => product.id);

  return [...cartProductList, ...productIdList];
}

export function getProductPricedFullData(
  productPriced: PricingDetailProductType,
  apiProduct: ProductType,
): ProductPricedFullDataType {
  return {
    ...apiProduct,
    cardAmount: productPriced.quantidade,
    monthlyCardValue: productPriced.valorMensal,
    totalCardValue: productPriced.valorProduto,
    totalCardValueWithTax: productPriced.valorTotal,
    cardEntries: productPriced.lancamentos.map(entry => ({
      commercialCondition: entry.identificacaoCondicaoComercial,
      name: entry.nome,
      description: entry.descricao,
      type: entry.tipo,
      taxValueType: entry.tipoValorTaxa,
      totalValue: entry.valorTotal,
      unityValue: entry.valorUnitario,
    })),
  };
}

export function getProductPricedFullDataList(
  productPricedList: PricingDetailProductType[],
  apiProductList: ProductType[],
) {
  const productPricedFullDataList: ProductPricedFullDataType[] = [];

  productPricedList.forEach(productPriced => {
    const apiProductData = apiProductList.find(
      apiProduct => apiProduct.id === productPriced.codigoProduto,
    );

    if (apiProductData) {
      productPricedFullDataList.push(
        getProductPricedFullData(productPriced, apiProductData),
      );
    }
  });

  return productPricedFullDataList;
}

export function convertProductResponseListToProductTypeList(
  productResponseList: Array<ProductResponse>,
): Array<ProductType> {
  return productResponseList.map(productResponse => {
    const productStaticData = getProductStaticData(productResponse.codigo);
    const modalityStaticData = getModalityStaticData(
      productResponse.codigoAgrupador,
    );

    return {
      ...productStaticData,
      id: productResponse.codigo,
      name: productResponse.nome,
      type: modalityStaticData.serviceGroupId,
      description: productResponse.descricao,
      modalityId: productResponse.codigoAgrupador,
      offerType: productResponse.modalidadeOferta,
      additionalProductList: productResponse.produtosAdicionais.map(
        additionalProduct => ({
          id: additionalProduct.codigo,
          name: additionalProduct.nome,
          description: additionalProduct.descricao,
          type: additionalProduct.tipo,
          isRequired: additionalProduct.obrigatorio,
          productType: additionalProduct.tipoProdutoAdicional,
        }),
      ),
    } as ProductType;
  });
}

export function convertRemovedProductPricingListToRemovedProductList(
  removedProductPricingList: RemovedProductPricingType[],
): RemovedProductType[] {
  return removedProductPricingList.map(removeProductPricing => ({
    id: removeProductPricing.idMotivo,
    productId: removeProductPricing.codigo,
    description: removeProductPricing.descricao,
  }));
}

export function convertRemovedProductCartListToProductList(
  removedProductCartList: ProductCartType[],
  productList: ProductType[],
) {
  return productList.filter(product =>
    removedProductCartList.find(
      removedProduct => removedProduct.id === product.id,
    ),
  );
}

export function convertProductListToRemovedProductList(
  productList: ProductType[],
): RemovedProductType[] {
  return productList.map(removeProductPricing => ({
    id: -1,
    productId: removeProductPricing.id,
    description: removeProductPricing.name,
  }));
}
