import { HTMLAttributes } from 'react';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  isChecked?: boolean;
  disabled?: boolean;
  onCheck?: () => void;
  children?: React.ReactNode;
  messageError?: boolean;
}

export default function Checkbox({
  isChecked,
  onCheck,
  children,
  disabled = false,
  messageError = false,
  ...rest
}: CheckboxProps) {
  return (
    <CheckboxContainer
      tabIndex={0}
      onKeyDown={event =>
        event.key === 'Enter' && onCheck !== undefined && onCheck()
      }
      disabled={disabled}
      onClick={e => disabled && e.preventDefault()}
      isOnError={messageError}
    >
      <input type="checkbox" checked={isChecked} onChange={onCheck} {...rest} />
      <span className="box">
        {isChecked ? (
          <i className="fa-solid fa-square-check" />
        ) : (
          <i className="fa-light fa-square" />
        )}
      </span>
      <p>{children}</p>
      {messageError && (
        <span className="warning-tip">
          Para avançar você precisa concordar com os termos
        </span>
      )}
    </CheckboxContainer>
  );
}

const CheckboxContainer = styled('label', {
  display: 'inline-flex',
  position: 'relative',
  alignItems: 'center',

  cursor: 'pointer',

  '.warning-tip': {
    position: 'absolute',
    color: '$error',
    top: '1rem',
    left: '-8px',
    padding: '4px 8px',
    fontSize: '0.75rem',
    borderRadius: '4px',
    zIndex: '999',

    '@media screen and (max-width: 1024px)': {
      top: '2rem',
    },

    '@deviceSm': {
      top: '3.5rem',
    },

    '@deviceXs': {
      top: '4.5rem',
    },
  },

  input: {
    opacity: 0,
    width: 0,
    height: 0,

    '&:checked + .box': {
      i: {
        color: '$primaryPure',
      },
      '& + p': {
        color: '$primaryPure',
      },
    },
  },

  '.box': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    background: 'transparent',
    height: convertPixelToRem(18),
    width: convertPixelToRem(18),
    transition: '0.4s ease',

    color: '$fontColorPrimary',
    fontSize: convertPixelToRem(18),

    '&:before': {
      zIndex: '-1',
      display: 'block',
      content: '',
      position: 'absolute',
      left: '-7px',
      bottom: '-6px',

      backgroundColor: '$silverLight',
      height: convertPixelToRem(32),
      width: convertPixelToRem(32),
      borderRadius: '$circular',
      opacity: 0,
      transition: '0.4s ease',
    },
  },

  p: {
    marginLeft: convertPixelToRem(14),
    transition: '0.4s ease',

    color: '$fontColorPrimary',
    fontWeight: '$medium',

    button: {
      background: 'transparent',

      color: '$primaryPure',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },

  '&:hover, &:focus': {
    outline: 0,
    '.box': {
      color: '$primaryPure',

      '&:before': {
        opacity: 0.5,
      },
    },
  },

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
        input: {
          '&:checked + .box': {
            i: {
              color: '#A9B6C1',
            },
            '& + p': {
              color: '#A9B6C1',
              button: {
                color: '#A9B6C1',
              },
            },
          },
        },
        '.box': {
          i: {
            color: '#A9B6C1',
          },
        },
        p: {
          color: '#A9B6C1',

          button: {
            color: '#A9B6C1',
            pointerEvents: 'none',

            '&:hover': {
              textDecoration: 'unset',
            },
          },
        },

        '&:hover, &:focus': {
          '.box': {
            color: '#A9B6C1',

            '&:before': {
              opacity: 0,
            },
          },
        },
      },
    },
    isOnError: {
      true: {
        '.box': {
          color: '$error',
        },
      },
    },
  },
  defaultVariants: {
    disabled: false,
  },
});
