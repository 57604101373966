import { useFormContext } from 'react-hook-form';

import formatCurrencyToValue from '@vr/devkit/money/formatCurrencyToValue';
import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { Input } from '@components/input';
import { Button } from '@elements/button';

import {
  MAX_LENGTH_CURRENCY_VALUE,
  MAX_LENGTH_QUANTITY_CARDS,
} from '@config/input-config';
import { IndividualProductFormType } from '@extra-types/product-form-types';
import { FormErrors } from '@helpers/enum/form-errors';
import { removeSpecialCaracters } from '@helpers/function-helpers';

import { useSelector } from '@hooks/use-selector';

interface IndividualInputsProps {
  productName: string;
  isOnCart: boolean;
}

function IndividualInputs({ productName, isOnCart }: IndividualInputsProps) {
  const config = useSelector(({ application }) => application.config);
  const { watch, register, formState, resetField } =
    useFormContext<IndividualProductFormType>();
  const watchProductValues = watch(['amount', 'value']);

  const isAllFieldsIsFilled =
    !!watchProductValues[0] && !!watchProductValues[1];

  return (
    <>
      <Input.Numeric
        id={`produto-${removeSpecialCaracters(productName)}-quantidade`}
        label="Escolha a quantidade"
        placeholder="0"
        inputMode="numeric"
        maxLength={MAX_LENGTH_QUANTITY_CARDS}
        customMask="99999"
        width="100%"
        errorMessage={formState.errors.amount?.message}
        {...register('amount', {
          valueAsNumber: true,
          min: {
            value: 1,
            message: `${FormErrors.MIN_CARD_AMOUNT} 1`,
          },
          max: {
            value: config.maxBeneficiaryAmount,
            message: `${FormErrors.MAX_CARD_AMOUNT} ${config.maxBeneficiaryAmount}`,
          },
        })}
      />
      <Input.Default
        id={`produto-${removeSpecialCaracters(productName)}-valor`}
        label="Digite o valor"
        type="text"
        placeholder="R$ 0,00"
        maskMoney
        maxLength={MAX_LENGTH_CURRENCY_VALUE}
        width="100%"
        errorMessage={formState.errors.value?.message}
        inputMode="numeric"
        {...register('value', {
          deps: 'amount',
          setValueAs: value => value && formatCurrencyToValue(value).toFixed(2),
          onChange: event => {
            if (event.target.value === 'R$ 0,00') {
              resetField('value', {
                defaultValue: event.target.value.replace('R$ 0,00', ''),
              });
            }
          },
          validate: {
            min: value =>
              value !== ''
                ? Number(value) >= config.minCardValue ||
                  `${FormErrors.MIN_CARD_VALUE} ${formatValueToCurrency(
                    config.minCardValue,
                  )}`
                : true,
            max: value =>
              value !== ''
                ? Number(value) <= config.maxCardValue ||
                  `${FormErrors.MAX_CARD_VALUE} ${formatValueToCurrency(
                    config.maxCardValue,
                  )}`
                : true,
          },
        })}
      />
      <Button.Default
        id={`btn-adicionar-carrinho-${removeSpecialCaracters(productName)}`}
        type="submit"
        disabled={!isAllFieldsIsFilled || !formState.isValid}
      >
        {isOnCart ? 'Atualizar carrinho' : 'Adicionar ao carrinho'}
      </Button.Default>
    </>
  );
}

export default IndividualInputs;
