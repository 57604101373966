import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';

import Scenarios from '@compositions/scenarios';

import { UtmType } from '@extra-types/utm-type';
import { CustomerType } from '@helpers/enum/customer-type';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { closeModal } from '@store/slices/customer-slice';

import CnpjForm from './cnpj-form';
import { CustomerContainer } from './styles';

export type CustomerData = {
  uuid: string;
  empresaRh: {
    cnpjRh: string;
    qtdFuncionarios: number;
    contato: {
      nomeContato: string;
      emailContato: string;
      telefoneContato: string;
    };
    utm?: UtmType;
  };
};

function CustomerModal() {
  const nodeRef = useRef(null);
  const { state, isLoading, customerType } = useSelector(
    ({ customer }) => customer,
  );
  const { isLoading: isOfferLoading } = useSelector(({ offers }) => offers);
  const { isCartLoading } = useSelector(({ cart }) => cart);

  const dispatch = useDispatch();

  const methods = useForm<CustomerData>({
    mode: 'onChange',
    defaultValues: {},
  });

  return (
    <FormProvider {...methods}>
      <CSSTransition
        nodeRef={nodeRef}
        in={state}
        classNames="customer-modal"
        timeout={400}
        unmountOnExit
      >
        <CustomerContainer ref={nodeRef}>
          <div className="modal-wrapper">
            <div>
              {customerType === CustomerType.NEW || !customerType ? (
                <CnpjForm />
              ) : (
                <Scenarios />
              )}
            </div>
            <button
              type="button"
              className="close-button"
              disabled={isLoading || isCartLoading || isOfferLoading}
              onClick={() => dispatch(closeModal())}
            >
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
        </CustomerContainer>
      </CSSTransition>
    </FormProvider>
  );
}

export default CustomerModal;
