import { useLocation } from 'react-router-dom';

import { Step } from '@elements/step';
import { StepLabel } from '@elements/step-label';

import { stepsForStepper } from '@config/stepper-config';
import { StepperVariantType } from '@extra-types/stepper-variant-type';
import { ModalitiesId } from '@helpers/enum/modalities-id';

import { useSelector } from '@hooks/use-selector';

import { StepperContainer } from './styles';

type StepperProps = {
  variant?: StepperVariantType;
};

function Stepper({ variant = 'default' }: StepperProps) {
  const { cartModality } = useSelector(({ cart }) => cart);
  const location = useLocation();

  const stepsVariant = stepsForStepper[variant];
  const stepsToShow = Object.values(stepsVariant).filter(
    step => step.isVisibleInStepBar,
  );
  const currentStep = stepsVariant[location.pathname];

  const steps: { [key: number]: string } = {
    0: '0%',
    1: '50%',
    2: '100%',
    3: '100%',
  };

  if (cartModality && cartModality.id === ModalitiesId.MOBILIDADE) {
    return (
      <StepperContainer as="div">
        <div className="stepper-holder stepper-holder--mobility">
          <i className="fa-solid fa-circle-check" />
          <div>
            <h2>Contrato gerado com sucesso!</h2>
          </div>
        </div>
      </StepperContainer>
    );
  }

  if (location.pathname === '/combo/checkout') {
    return (
      <StepperContainer as="div">
        <div className="stepper-holder stepper-holder--contract">
          <i className="fa-solid fa-circle-check" />
          <h2>Pacote contratado com sucesso!</h2>
        </div>
      </StepperContainer>
    );
  }

  if (location.pathname === '/pedido/checkout') {
    return (
      <StepperContainer as="div">
        <div className="stepper-holder stepper-holder--payment">
          <i className="fa-solid fa-circle-check" />
          <div>
            <p>Pedido finalizado</p>
            <h2>Pedido contratado e realizado com sucesso!</h2>
          </div>
        </div>
      </StepperContainer>
    );
  }

  return (
    <StepperContainer
      css={{ $$location: steps[currentStep.position] }}
      as="div"
    >
      <div className="stepper-holder">
        <div className="steps-line">
          <div className="progression" />
          {stepsToShow.map(stepData => (
            <Step
              key={stepData.position}
              stepIndex={stepData.position}
              currentStepPosition={currentStep.position}
            />
          ))}
        </div>
        <div className="references-line">
          {stepsToShow.map(stepData => (
            <StepLabel
              key={stepData.position}
              stepIndex={stepData.position}
              currentStepPosition={currentStep.position}
              label={stepData.label}
            />
          ))}
        </div>
      </div>
    </StepperContainer>
  );
}

export default Stepper;
