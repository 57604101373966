import { HCMFaqElement } from '@elements/hcm-faq-element';
import { PageSection } from '@elements/page-section';
import { Title } from '@elements/title';

import { HCMFAQJson } from '@config/hcm-config';
import { holderStyle } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export function HCMFaqSection() {
  return (
    <PageSection
      id="hcm-faq"
      css={{ backgroundColor: '$white', padding: '2rem 0' }}
    >
      <SectionHolder>
        <Title.Default tag="h2" css={{ margin: 0 }}>
          Perguntas frequentes
        </Title.Default>
        <div className="faq-categories-holder">
          {HCMFAQJson.map(article => (
            <HCMFaqElement
              key={article.title}
              title={article.title}
              description={article.description}
            />
          ))}
        </div>
      </SectionHolder>
    </PageSection>
  );
}

const SectionHolder = styled('div', {
  ...holderStyle,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2.5rem',

  padding: '1.25rem 0 2.5rem',

  '.faq-categories-holder': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    width: '100%',
    gap: '1rem',

    backgroundColor: '$white',

    button: {
      background: 'transparent',

      padding: '0.5rem 0.75rem',
      borderRadius: '100px',

      fontWeight: 'bold',
      color: '$mobilidadePrimaryPure',

      transition: 'background-color 0.2s ease-in-out',
    },

    '.active': {
      backgroundColor: '$primaryDark',
      color: '$white',
    },

    '@deviceMd': {
      display: 'flex',
      overflow: 'auto',

      button: {
        fontSize: '0.875rem',
        fontWeight: '600',
      },
    },
  },

  '@deviceLg': {
    gap: '2rem',
    padding: '1rem 0 2.5rem',
  },
});
