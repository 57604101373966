import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';

import { styled } from '@src/themes';

type HCMFaqElementProps = {
  title: string;
  description: string;
};

export function HCMFaqElement({ title, description }: HCMFaqElementProps) {
  return (
    <HCMFaqElementContainer>
      <img
        src={`${env.URL_ASSETS + ASSETS_OTHERS_PATH}/lupa-check.webp`}
        alt="icone de lupa"
      />
      <h2>{title}</h2>
      <p>{description}</p>
    </HCMFaqElementContainer>
  );
}

const HCMFaqElementContainer = styled('article', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  padding: '20px 20px 67px 28px',
  backgroundColor: '#F3F7FB',
  borderRadius: '15px 15px 15px 15px',

  img: {
    maxWidth: '35px',
    alignSelf: 'flex-end',
  },

  h2: {
    fontSize: '1.25rem',
    fontWeight: '700',
  },

  p: {
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.5',
  },

  '@deviceMd': {
    minWidth: '350px',
  },

  '@deviceXs': {
    minWidth: '300px',
  },
});
