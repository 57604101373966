import ProductWalletDescription from '@components/product-wallet-input/product-wallet-description';

import { ProductType } from '@extra-types/product-type';

import { ProductWalletInputContainer } from './product-combo-wallet-input';

type ProductComboContractedProps = {
  productData: ProductType;
};

export function ProductComboContracted({
  productData,
}: ProductComboContractedProps) {
  return (
    <ProductWalletInputContainer
      css={{
        cursor: 'not-allowed',
        opacity: '0.6',

        '@deviceMd': {
          alignItems: 'flex-start',
          flexDirection: 'column',
        },
      }}
    >
      <ProductWalletDescription productData={productData} />
      <span className="product-wallet-description-container__contracted-product">
        <i className="fa-solid fa-check-circle" />
        <p>Saldo já contratado!</p>
      </span>
    </ProductWalletInputContainer>
  );
}
