import React, { HTMLAttributes } from 'react';

import { IconVR } from '@elements/icon-vr';

import { ICON_VR } from '@config/styles-config';
import {
  DefaultTokenVariantsType,
  ProductsTokenVariantsType,
} from '@extra-types/token-variants-type';

import { BenefitItemContainer } from './styles';

interface BenefitItemProps extends HTMLAttributes<HTMLDivElement> {
  icon: string;
  title: string;
  children: React.ReactNode;
  variant?: DefaultTokenVariantsType;
  guidance?: 'column' | 'row';
  widthSize?: 'sm' | 'md' | 'lg';
  productVariant?: ProductsTokenVariantsType;
}

export function BenefitItem({
  icon,
  title,
  variant,
  guidance,
  productVariant,
  widthSize,
  children,
  ...rest
}: BenefitItemProps) {
  return (
    <BenefitItemContainer
      variant={variant}
      productVariant={productVariant}
      guidance={guidance}
      widthSize={widthSize}
      {...rest}
    >
      <div className="benefit-item__icon">
        {icon === ICON_VR ? (
          <IconVR variant={variant} />
        ) : (
          <i className={`${icon}`} />
        )}
      </div>
      <div className="benefit-item__text">
        <strong>{title}</strong>
        <p>{children}</p>
      </div>
    </BenefitItemContainer>
  );
}
