/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ModalityType } from '@extra-types/modality-type';
import { ProductType } from '@extra-types/product-type';

import { fetchProductsData } from '@store/thunks/products-thunk';

export type ApplicationType = {
  isLoading: boolean;
  productList: ProductType[];
  modalityList: ModalityType[];
  selectedModality: ModalityType;
};

export const initialState = {
  isLoading: true,
  productList: [],
  modalityList: [],
  selectedModality: {} as ModalityType,
} as ApplicationType;

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    handleSelectModality(state, action: PayloadAction<ModalityType>) {
      state.selectedModality = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductsData.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchProductsData.fulfilled, (state, action) => {
        state.productList = state.productList.concat(
          action.payload.productList,
        );
        state.modalityList = state.modalityList.concat(
          action.payload.modalityList,
        );
        state.isLoading = false;
      });
  },
});

export const { handleSelectModality } = productsSlice.actions;

export default productsSlice.reducer;
