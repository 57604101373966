import { createAsyncThunk } from '@reduxjs/toolkit';

import { CustomerData } from '@compositions/customer-modal';

import { DEFAULT_EMPLOYEES_AMOUNT } from '@config/application-config';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { formatChannelInformationResponseToChannelInformationType } from '@helpers/channel-helpers';
import { CustomerType } from '@helpers/enum/customer-type';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { isComboFlux } from '@helpers/flux-helpers';

import { AppDispatch } from '@hooks/use-dispatch';
import { RootState } from '@hooks/use-selector';
import {
  changeCustomerType,
  handleChannelDescription,
  handleLoadingState,
  proceedToPurchase,
} from '@store/slices/customer-slice';

import { RegisterCartRequest } from '@api/models/request/register-cart-request';
import CreateCartResponseData from '@api/models/response/create-cart-response';
import CreateLeadResponseData from '@api/models/response/create-lead-response';
import { OfferType } from '@api/models/response/offer-response';
import SearchCompanyResponse from '@api/models/response/search-company-response';
import clienteRhService from '@api/services/cliente-rh-service';
import leadsService from '@api/services/leads-service';
import ApplicationError from '@errors/types/application-error';
import BlockListError from '@errors/types/block-list-error';
import CompanyServedByAnotherChannelError from '@errors/types/company-served-by-another-channel-error';
import DowngradeError from '@errors/types/downgrade-error';
import InvalidSimulationIdentificationError from '@errors/types/invalid-simulation-identification';
import NotCatalogedError from '@errors/types/not-cataloged-error';
import SamePlanError from '@errors/types/same-plan-error';

export const fetchCustomerLead = createAsyncThunk<
  CreateLeadResponseData,
  {
    username: string;
    email: string;
    cellphone: string;
  },
  { state: RootState }
>(
  'customer/fetchCustomerLead',
  async ({ username, email, cellphone }, thunkAPI) => {
    const { config } = thunkAPI.getState().application;
    const {
      representativeData: { utm, uuid },
    } = thunkAPI.getState().customer;
    const { cnpj } = thunkAPI.getState().customer.companyData;
    const { productCartList } = thunkAPI.getState().cart;
    const employeesAmount =
      (productCartList.length > 0 &&
        Math.max(...productCartList.map(product => product.amount))) ||
      DEFAULT_EMPLOYEES_AMOUNT;

    const registerRepresentative: CustomerData = {
      uuid,
      empresaRh: {
        cnpjRh: cnpj,
        contato: {
          emailContato: email,
          nomeContato: username,
          telefoneContato: cellphone,
        },
        qtdFuncionarios: employeesAmount,
      },
    };

    if (utm !== null) {
      registerRepresentative.empresaRh.utm = utm;
    }

    try {
      return await leadsService.createLead(
        {
          siglaCanal: config.siglaCanal,
          siglaEmissor: config.emissor.initials,
          siglaSubCanal: config.siglaSubCanal,
        },
        registerRepresentative,
      );
    } catch (error) {
      if (error instanceof ApplicationError) {
        return thunkAPI.rejectWithValue(error);
      }
      return thunkAPI.rejectWithValue(new NotCatalogedError());
    }
  },
);

export const fetchCompanyInformation = createAsyncThunk<
  SearchCompanyResponse,
  void,
  { state: RootState; dispatch: AppDispatch }
>('customer/fetchCompanyInformation', async (_, thunkAPI) => {
  const { config } = thunkAPI.getState().application;

  let searchCompanyDataResponse = {} as SearchCompanyResponse;

  try {
    searchCompanyDataResponse = await clienteRhService.searchCompany(
      thunkAPI.getState().customer.companyData.cnpj,
      {
        siglaCanal: config.siglaCanal,
        siglaEmissor: config.emissor.initials,
        siglaSubCanal: config.siglaSubCanal,
      },
    );
    thunkAPI.dispatch(changeCustomerType(CustomerType.NEW));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error instanceof CompanyServedByAnotherChannelError) {
      const { descricaoCanal } = await clienteRhService.searchCompanyChannel(
        thunkAPI.getState().customer.companyData.cnpj,
        {
          siglaEmissor: config.emissor.initials,
          siglaSubCanal: config.siglaSubCanal,
        },
      );
      thunkAPI.dispatch(
        handleChannelDescription(
          formatChannelInformationResponseToChannelInformationType(
            descricaoCanal,
          ),
        ),
      );
      thunkAPI.dispatch(changeCustomerType(CustomerType.ANOTHER_CHANNEL));
      thunkAPI.dispatch(handleLoadingState(false));
    } else if (error instanceof BlockListError) {
      thunkAPI.dispatch(changeCustomerType(CustomerType.BLOCK_LIST));
      thunkAPI.dispatch(handleLoadingState(false));
    } else if (error instanceof ApplicationError) {
      return thunkAPI.rejectWithValue(error);
    } else {
      return thunkAPI.rejectWithValue(new NotCatalogedError());
    }
  }
  return searchCompanyDataResponse;
});

export const fetchRegisterCart = createAsyncThunk<
  CreateCartResponseData,
  { offerData?: OfferType },
  { state: RootState }
>('customer/fetchRegisterCart', async ({ offerData }, thunkAPI) => {
  const { companyData } = thunkAPI.getState().customer;
  const { config } = thunkAPI.getState().application;
  const {
    productCartList,
    productInterestCartList,
    productComboCartList,
    extraAdditionalProductList,
    requiredAdditionalProductList,
    contractedProducts,
  } = thunkAPI.getState().cart;

  const productCartListToPrice = isComboFlux(offerData)
    ? Object.values(productComboCartList).flat()
    : productCartList;

  const contractedPlanAndExtension = contractedProducts.filter(
    product => product.modalityId === ModalitiesId.HCM,
  );
  const contractedExtensions = contractedPlanAndExtension.filter(
    extension =>
      requiredAdditionalProductList.unrequired.some(
        unrequiredExtension => unrequiredExtension.id === extension.id,
      ) ||
      requiredAdditionalProductList.required.some(
        requiredExtension => requiredExtension.id === extension.id,
      ),
  );

  const extensionIds = [
    ...extraAdditionalProductList,
    ...contractedExtensions,
  ].map(additionalProducts => additionalProducts.id);

  try {
    if (companyData.lead === '') {
      return thunkAPI.rejectWithValue(
        new InvalidSimulationIdentificationError(),
      );
    }

    const registerCartData: RegisterCartRequest = {
      produtosCarrinho: productCartListToPrice.map(productCart => {
        return {
          codigoProduto: productCart.id,
          quantidade: productCart.amount,
          valorTotalBeneficios: productCart.value * productCart.amount,
          tipoProduto: productCart.type ?? '',
          produtosAdicionais: extensionIds,
          tipoPlano:
            (productCart.planType.toUpperCase() as PlanTypeEnum) ||
            PlanTypeEnum.MENSAL.toUpperCase(),
        };
      }),
      produtosInteresse: productInterestCartList.map(interestProduct => ({
        codigoProduto: interestProduct.id,
        nome: interestProduct.name,
      })),
    };

    return await leadsService.createCart(
      {
        siglaCanal: config.siglaCanal,
        siglaEmissor: config.emissor.initials,
        siglaSubCanal: config.siglaSubCanal,
      },
      companyData.lead,
      registerCartData,
      offerData?.codigoOferta,
    );
  } catch (error) {
    if (error instanceof DowngradeError || error instanceof SamePlanError) {
      thunkAPI.dispatch(changeCustomerType(CustomerType.DOWNGRADE));
      thunkAPI.dispatch(proceedToPurchase());
      return thunkAPI.rejectWithValue(error);
    }

    if (error instanceof ApplicationError) {
      return thunkAPI.rejectWithValue(error);
    }
    return thunkAPI.rejectWithValue(new NotCatalogedError());
  }
});
