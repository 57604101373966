import {
  CHANNEL_DEFAULT_INITIALS,
  defaultEmissorChannel,
} from '@config/channel-config';
import { env } from '@config/env';
import { RateArticleEnum } from '@config/faq-config';
import { RateArticleType } from '@extra-types/rate-article-type';

import { ApiRequestAuthData } from '@api/models/request/auth-data';
import {
  FAQArticlesListResponse,
  FAQArticlesByCategoryListResponse,
} from '@api/models/response/faq-articles-list-response';
import { FAQCategoriesResponse } from '@api/models/response/faq-categories-response';
import ResponseEntity from '@api/models/response/response-entity';

import getBaseURLApi from './authentication/get-base-url-api';

class FAQService {
  private readonly baseUrl;

  constructor() {
    this.baseUrl = 'faq';
  }

  public async getFAQCategories({
    siglaEmissor,
    siglaCanal,
    siglaSubCanal,
  }: ApiRequestAuthData): Promise<FAQCategoriesResponse> {
    const url = `/${this.baseUrl}/categorias?origemConsultaVendaOnline=true`;

    const channelInitials =
      siglaCanal.toUpperCase() === CHANNEL_DEFAULT_INITIALS
        ? defaultEmissorChannel[siglaEmissor][env.ID]
        : siglaCanal;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': channelInitials,
        'id-subCanal': siglaSubCanal ?? '',
      },
    });

    return data;
  }

  public async getFAQCategoryArticlesById(
    id: string,
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
  ): Promise<FAQArticlesByCategoryListResponse> {
    const url = `/${this.baseUrl}/categorias/${id}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
    });

    return data;
  }

  public async rateArticle(
    id: string,
    rate: RateArticleType,
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
  ): Promise<ResponseEntity> {
    const url = `/${this.baseUrl}/artigos/${id}/${RateArticleEnum[rate]}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
      method: 'PUT',
    });

    return data;
  }

  public async getAllFAQArticles({
    siglaEmissor,
    siglaCanal,
    siglaSubCanal,
  }: ApiRequestAuthData): Promise<FAQArticlesListResponse> {
    const url = `/${this.baseUrl}/artigos`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
    });

    return data;
  }
}

export default new FAQService();
