import { useMemo } from 'react';

import { PATAuxComparasionSection } from '@compositions/sections/pat-aux-comparasion-section';
import { WalletProductForm } from '@compositions/wallet-product-form';
import { WalletSectionGroup } from '@compositions/wallet-section-group';
import { PageSection } from '@elements/page-section';
import { SectionHolder } from '@elements/section-holder';
import { Title } from '@elements/title';

import { MODALITY_NEW_TEXT } from '@config/modality-config';
import { getModalityData } from '@helpers/modality-helpers';
import { getProductListByModality } from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

interface WalletPageProps {
  modalityId: number;
}

export function WalletProductsPage({ modalityId }: WalletPageProps) {
  const { modalityList } = useSelector(({ products }) => products);
  const modalityData = getModalityData(modalityId, modalityList);

  const { productList } = useSelector(({ products }) => products);

  const saleProductList = useMemo(() => {
    return getProductListByModality(modalityData.id, productList);
  }, [modalityData.id, productList]);

  return (
    <>
      <PageSection variant="tertiary" id={`${modalityData.keyId}-page`} dark>
        <SectionHolder>
          <Title.Root
            barColor={modalityData.colorPrefixToken}
            fontColorVariant="primary"
            multi
          >
            <Title.Default tag="h2">
              {modalityData.shelfInformation.title}
              {modalityData.isFeatured && (
                <Title.Flag>{MODALITY_NEW_TEXT}</Title.Flag>
              )}
            </Title.Default>
            <Title.Subtitle>{modalityData.subtitle}</Title.Subtitle>
          </Title.Root>
          <WalletProductForm modalityData={modalityData} />
        </SectionHolder>
      </PageSection>
      {saleProductList.some(saleProduct => saleProduct.showAsteriskPatAux) && (
        <PATAuxComparasionSection
          variant="tertiary"
          contractIDSection={`${modalityData.keyId}-page`}
        />
      )}
      <WalletSectionGroup type={modalityData.keyId} />
    </>
  );
}
