import { SERVICE_GROUPS } from '@config/service-config';
import { ModalityType } from '@extra-types/modality-type';
import { ServiceType } from '@extra-types/service-type';
import { ServiceWithModalityType } from '@extra-types/service-with-modality-type';

import { ServiceGroupId } from './enum/service-group-id';
import { removeRepeatedItemsFromList } from './modality-helpers';

export function getServiceData(id: string): ServiceType {
  return SERVICE_GROUPS[id] ?? SERVICE_GROUPS[ServiceGroupId.UNKNOWN];
}

export function getServiceList(
  modalityList: ModalityType[],
  fixServiceIdList?: ServiceGroupId[],
): ServiceWithModalityType[] {
  const serviceList = fixServiceIdList ?? [
    ...new Set(modalityList.map(modality => modality.serviceGroupId)),
  ];

  return serviceList.map(serviceGroupId => {
    const serviceData = getServiceData(serviceGroupId);

    return {
      ...serviceData,
      modalityList: removeRepeatedItemsFromList(modalityList).filter(
        modality => modality.serviceGroupId === serviceGroupId,
      ),
    };
  });
}

export function getServiceIdList(modalityList: ModalityType[]): string[] {
  return [...new Set(modalityList.map(modality => modality.serviceGroupId))];
}

export function createComboModalityFormId(modalityId: number | string) {
  return `service-${modalityId}-form`;
}

export function removePrefixAndSuffixFromComboModalityFormID(
  comboModalityFormID: string,
) {
  return comboModalityFormID.split('-')[1];
}
