import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

interface CustomerCommentProps {
  comment: string;
  customer: {
    name: string;
    company: string;
    imageUrl: string;
  };
}

export function CustomerComment({ comment, customer }: CustomerCommentProps) {
  return (
    <CustomerCommentHolder>
      <div className="customer-comment-holder">
        <p className="customer-comment-holder__comment-text">{comment}</p>
        <div className="customer-comment-holder__customer-information">
          <img src={customer.imageUrl} alt={customer.name} />
          <div className="customer-information__text-area">
            <h2>{customer.name}</h2>
            <p>{customer.company}</p>
          </div>
        </div>
      </div>
    </CustomerCommentHolder>
  );
}

const CustomerCommentHolder = styled('div', {
  display: 'flex',
  justifyContent: 'center',

  width: '100%',

  '.customer-comment-holder': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    padding: '0 5rem 2rem',

    maxWidth: '932px',

    '@deviceMd': {
      padding: '0 2rem 2rem',
    },

    '@deviceSm': {
      padding: '0 1rem 1rem',
    },
  },

  '.customer-comment-holder__comment-text': {
    fontSize: convertPixelToRem(14),
    lineHeight: 2,
  },

  '.customer-comment-holder__customer-information': {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',

    img: {
      objectFit: 'contain',
      width: '80px',
    },
  },

  '.customer-information__text-area': {
    fontSize: convertPixelToRem(14),
    lineHeight: 1.5,
  },
});
