import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const ScenariosContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  scrollBehavior: 'smooth',

  '.already-customer-message-box': {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-1rem',
    marginBottom: '1rem',
    gap: '1rem',
    fontSize: convertPixelToRem(14),
    color: '#6D839A',
    textAlign: 'left',
    fontWeight: '500',
  },

  '.additional-informations': {
    fontSize: '0.875rem',
    textAlign: 'left',
    strong: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
  },

  '.information-box': {
    padding: '1.25rem',
    borderRadius: convertPixelToRem(10),
    boxShadow: '0px 0px 8px #00000029',

    '> p': {
      fontSize: convertPixelToRem(14),
      fontWeight: '$medium',
      lineHeight: '1.8',
      textAlign: 'left',

      span: {
        color: '$primaryPure',
        fontWeight: '$semibold',
      },
    },
  },

  '.available-products': {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    flexWrap: 'wrap',

    marginBottom: '1rem',
  },

  '.another-channel-text': {
    fontSize: convertPixelToRem(14),
    fontWeight: '500',
    color: '#6D839A',
    textAlign: 'left',
    wordBreak: 'break-word',
  },

  '.another-channel-warning-text': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',

    fontSize: convertPixelToRem(14),
    fontWeight: '$medium',
    lineHeight: 1.4,
    textAlign: 'center',

    i: {
      fontSize: '1.25rem',
    },
  },

  '.offer-restriction-message': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },

  '.unserved-region-message': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',

    textAlign: 'center',

    '.unserved-region-message__products-group': {
      display: 'flex',
      justifyContent: 'center',
      gap: '1rem',
      flexWrap: 'wrap',

      img: {
        height: convertPixelToRem(46),
      },
    },

    '.unserved-region-message__warning-message': {
      backgroundColor: '$silverLight',
      width: '100%',
      padding: '1rem',
      marginLeft: 'auto',
      borderRadius: '6px',

      fontSize: convertPixelToRem(12),
      fontWeight: '$regular',
      lineHeight: '1.5',
      textAlign: 'center',

      i: {
        marginRight: '0.375rem',

        fontSize: convertPixelToRem(14),
      },
    },
  },

  '.block-list-text': {
    fontSize: '0.75rem',
    fontWeight: 500,
  },

  '.downgrade-text': {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
});

export const ModalityProductGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  width: '100%',
  cursor: 'pointer',

  'p.title': {
    backgroundColor: '$silverLight',
    padding: '0.25rem 0.75rem',
    borderRadius: '6px 6px 0 0',
    transition: '0.4s ease',

    fontSize: '0.75rem',
    fontWeight: '$semibold',
  },

  '.product-list': {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    gap: '1rem',

    backgroundColor: '$silverLight',
    padding: '1rem 0.5rem',
    borderRadius: '0 6px 0 0',
    transition: '0.4s ease',

    img: {
      height: convertPixelToRem(46),
    },
  },

  '.action-label': {
    display: 'flex',
    justifyContent: 'center',

    background: '$primaryLight',
    width: '100%',
    padding: '0.25rem 0.75rem',
    borderRadius: '0 0 6px 6px',
    transition: '0.4s ease',

    color: '$white',
    fontSize: '0.75rem',
    fontWeight: '$medium',
  },

  '&:hover': {
    '.title': {
      backgroundColor: '$silverLight',
    },
    '.product-list': {
      backgroundColor: '$silverLight',
    },
    '.action-label': {
      background: '$primaryDark',
    },
  },
});
