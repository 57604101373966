import { Outlet } from 'react-router-dom';

import Stepper from '@compositions/stepper';

import { StepperVariantType } from '@extra-types/stepper-variant-type';

interface PurchaseLayoutProps {
  variant?: StepperVariantType;
}

export function PurchaseLayout({ variant = 'default' }: PurchaseLayoutProps) {
  return (
    <>
      <Stepper variant={variant} />
      <Outlet />
    </>
  );
}
