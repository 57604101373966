import FileSaver from 'file-saver';

export const downloadPDF = (
  byteArray: string | Uint8Array,
  filename: string,
): void => {
  const blob = new Blob([byteArray], { type: 'application/octet-stream' });
  const url = window.URL || window.webkitURL;
  const fileURL = url.createObjectURL(blob);
  fetch(fileURL)
    .then(fileResponse => fileResponse.blob())
    .then(blobResponse => {
      FileSaver.saveAs(blobResponse, `${filename}.pdf`, { autoBom: false });
    });
};

export const base64ToArrayBuffer = (data: string): Uint8Array => {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};
