import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class CompanyHassAllProductsError extends ApplicationError {
  constructor(message?: string) {
    super(
      SpecificsApiErrors.COMPANY_HAS_ALL_PRODUCTS,
      message || ApiErrorsMessages.DEFAULT,
    );
  }
}
