import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const BonusItemContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: `3.5rem 1fr ${convertPixelToRem(132)}`,
  gridTemplateAreas: `'icon title value'`,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.25rem',

  background: '$bonusCartBackground',
  width: '100%',
  padding: '1.5rem 0',
  cursor: 'pointer',

  fontSize: convertPixelToRem(14),

  '& + &': {
    borderTop: '1px solid $silverLight',
  },

  '@deviceMd': {
    gridTemplateColumns: `3.5rem 1fr ${convertPixelToRem(132)}`,
  },

  '@deviceSm': {
    gridTemplateColumns: `auto 1fr auto`,
    gap: '1rem',
    padding: '1rem',
  },

  '> div': {
    textAlign: 'center',

    '&:nth-child(2)': {
      textAlign: 'start',
    },
  },

  '.offer-item__icon': {
    gridArea: 'icon',

    i: {
      color: '$primaryPure',
      fontSize: '1.5rem',
    },

    '&--unselected': {
      alignSelf: 'unset',
    },
  },

  '.offer-item__title': {
    gridArea: 'title',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    color: '$primaryPure',
    fontWeight: '$bold',
    fontSize: convertPixelToRem(14),

    i: {
      height: convertPixelToRem(10),
      transition: 'transform 0.4s ease',

      fontSize: '0.75rem',
    },

    '@deviceMd': {
      marginRight: '0.75rem',
    },
  },

  '.offer-item__value': {
    gridArea: 'value',
    display: 'flex',
    justifyContent: 'center',

    p: {
      color: '$primaryPure',
      fontSize: convertPixelToRem(14),
      fontWeight: '$semibold',
    },
  },

  variants: {
    collapse: {
      true: {
        '.offer-item__title': {
          i: {
            transform: 'rotate(180deg)',
          },
        },
      },
    },
    disabled: {
      true: {
        opacity: 0.5,
        cursor: 'not-allowed',

        p: {
          textDecoration: 'line-through',
        },
      },
    },
  },
});

export const BonusItemDetailsContainer = styled('div', {
  overflow: 'hidden',
  height: '$$height',
  opacity: 1,
  transition: 'height 0.4s ease, opacity 0.4s ease',

  '.details-container__holder': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    padding: '1rem',

    fontSize: convertPixelToRem(14),

    p: {
      lineHeight: 1.5,
    },

    '.bonus-item__preview': {
      fontWeight: '$medium',
    },

    '.bonus-item__rule': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',

      background: '$bonusCartBackground',
      padding: '1rem',
      borderRadius: '6px',
      listStyle: 'none',

      'li::before': {
        display: 'inline-block',
        content: String.raw`\f0da`,

        width: '1rem',

        fontFamily: '$fontAwesome',
        color: '$primaryPure',
        fontWeight: '$bold',
        lineHeight: 1.8,
      },
    },

    '.bonus-item__warning': {
      fontSize: '0.75rem',
    },
  },

  variants: {
    collapse: {
      true: {
        height: 0,
        opacity: 0,
      },
    },
  },
});
