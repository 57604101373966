import { ComboServiceFormType } from '@extra-types/combo-service-form-type';
import { ModalitiesId } from '@helpers/enum/modalities-id';

import { ComboDefaultServiceForm } from './combo-default-service-form';
import { ComboHCMServiceForm } from './combo-hcm-service-form';
import { ComboMobilityServiceForm } from './combo-mobility-service-form';
import { ComboWalletServiceForm } from './combo-wallet-service-form';

export const ComboProductServicesForm: {
  [key: number]: (props: ComboServiceFormType) => JSX.Element;
} = {
  [ModalitiesId.DEFAULT]: ComboDefaultServiceForm,
  [ModalitiesId.MULTI]: ComboWalletServiceForm,
  [ModalitiesId.MOBILIDADE]: ComboMobilityServiceForm,
  [ModalitiesId.HCM]: ComboHCMServiceForm,
};
