import { ProductCartType } from '@extra-types/product-cart-type';
import { ProductContractedType } from '@extra-types/product-contracted-type';
import { ProductType } from '@extra-types/product-type';
import { CustomerType } from '@helpers/enum/customer-type';
import {
  convertContractedProductsToProductList,
  filterProductListByContractedProductList,
  getCleanProductList,
} from '@helpers/product-helpers';

import { validateIfCustomerCanBeServedByChannel } from './validate-if-customer-can-be-served-by-channel';
import { validateIfCustomerHasContractedAllAvailableProducts } from './validate-if-customer-has-all-products-contracted';
import { validateIfCustomerSelectedOnlyContractedProducts } from './validate-if-customer-selected-only-contracted-products';
import { validateIfIsNewCustomer } from './validate-if-it-is-a-new-customer';

export const customerValidation = ({
  productList,
  productCartList,
  customerType,
  contractedProducts,
}: {
  productList: ProductType[];
  contractedProducts: ProductContractedType[];
  customerType?: CustomerType;
  productCartList: ProductCartType[];
}) => {
  const convertedContractedProducts = convertContractedProductsToProductList(
    contractedProducts,
    productList,
  );

  const cleanProductList = getCleanProductList(productList);
  const productAvailableList = cleanProductList;

  const filteredContractedProducts = getCleanProductList(
    convertedContractedProducts,
  );

  return [
    {
      validation: () =>
        validateIfCustomerHasContractedAllAvailableProducts(
          filterProductListByContractedProductList(
            productAvailableList,
            filteredContractedProducts,
          ),
        ),
      customerType: CustomerType.ALL_PRODUCTS_CONTRACTED,
    },
    {
      validation: () => validateIfCustomerCanBeServedByChannel(customerType),
      customerType: CustomerType.ANOTHER_CHANNEL,
    },

    {
      validation: () => validateIfIsNewCustomer(contractedProducts),
      customerType: CustomerType.NEW,
    },
    {
      validation: () =>
        validateIfCustomerSelectedOnlyContractedProducts(
          filteredContractedProducts,
          productCartList,
        ),
      customerType: CustomerType.CUSTOMER,
    },
    {
      validation: () => true,
      customerType: CustomerType.HAS_AVAILABLE_PRODUCTS,
    },
  ];
};
