/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  fetchActiveOffers,
  fetchOfferDetails,
} from '@store/thunks/offer-thunk';

import {
  OfferDetailResponse,
  OfferResponse,
  OfferType,
} from '@api/models/response/offer-response';

interface OfferState {
  isLoading: boolean;
  activeOffers: OfferResponse;
  offerDetails: OfferDetailResponse;
  selectedOffer: OfferType | undefined;
}

export const initialState = {
  isLoading: false,
  activeOffers: {
    ofertas: [],
  },
  offerDetails: {} as OfferDetailResponse,
  selectedOffer: undefined,
} as OfferState;

const OfferSlice = createSlice({
  name: 'Offer-information',
  initialState,
  reducers: {
    selectOffer: (state, action: PayloadAction<string>) => {
      state.selectedOffer = state.activeOffers.ofertas.find(
        offer => offer.codigoOferta === action.payload,
      );
    },
    resetOffer: state => {
      state.selectedOffer = undefined;
      state.offerDetails = {} as OfferDetailResponse;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchActiveOffers.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchActiveOffers.fulfilled, (state, action) => {
        state.activeOffers = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchActiveOffers.rejected, state => {
        state.isLoading = false;
      })
      .addCase(fetchOfferDetails.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchOfferDetails.fulfilled, (state, action) => {
        state.offerDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchOfferDetails.rejected, state => {
        state.isLoading = false;
      });
  },
});

export const { selectOffer, resetOffer } = OfferSlice.actions;

export default OfferSlice.reducer;
