import React from 'react';

import { Autoplay, Navigation, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { StyledSwiper } from '@elements/styled-swiper';

import { styled } from '@src/themes';

export function SliderCarousel({
  autoPlay = false,
  children,
}: {
  autoPlay?: boolean;
  children: React.ReactNode;
}) {
  const verifyIsSingleItem = true;

  return (
    <CarouselContainer>
      <CarouselSwiper
        modules={[Navigation, Pagination, Autoplay]}
        preventInteractionOnTransition
        grabCursor
        pagination={
          verifyIsSingleItem && {
            clickable: true,
          }
        }
        navigation={verifyIsSingleItem}
        loop={verifyIsSingleItem}
        bulletPadding={verifyIsSingleItem}
        allowTouchMove
        autoplay={
          autoPlay
            ? {
                delay: 5000,
                disableOnInteraction: false,
              }
            : false
        }
        slidesPerView={1}
        breakpoints={{
          851: {
            allowTouchMove: false,
            grabCursor: false,
          },
        }}
      >
        {children && (
          <>
            {React.Children.map(children, child => (
              <SwiperSlide>{child}</SwiperSlide>
            ))}
          </>
        )}
      </CarouselSwiper>
    </CarouselContainer>
  );
}

const CarouselContainer = styled('section', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',

  background: 'transparent',
});

const CarouselSwiper = styled(StyledSwiper, {
  width: '100%',

  '.swiper-button-prev': {
    left: '0',
  },

  '.swiper-button-next': {
    right: '0',
  },

  variants: {
    bulletPadding: {
      true: {
        paddingBottom: '2rem !important',
      },
    },
  },
  defaultVariants: {
    bulletPadding: true,
  },
});
