import ReactGA4 from 'react-ga4';
import { Location } from 'react-router-dom';

import { PRODUCTS } from '@config/products-config';
import { ModalityType } from '@extra-types/modality-type';
import { PricingType } from '@extra-types/pricing-types';
import { ProductCartType } from '@extra-types/product-cart-type';
import { TrackCategories } from '@helpers/enum/track-categories';

import { getEmissor } from './emissor-helpers';

export const initializeAnalytics = () => {
  const emissorStaticInformation = getEmissor();

  if (emissorStaticInformation.tagGA4) {
    ReactGA4.initialize([
      {
        trackingId: emissorStaticInformation.tagGA4,
      },
    ]);
  }
};

export const sendApplicationErrorToAnalytics = (message: string) => {
  // Envio para o Google Analytics 4
  ReactGA4.gtag('event', 'exception', {
    description: message,
    fatal: true,
  });
};

export const sendPageViewToAnalytics = (location: Location) => {
  // Envio para o Google Analytics 4
  ReactGA4.send({
    hitType: 'pageview',
    page: location.pathname + location.search,
  });
};

export const sendEventToAnalytics = (
  category: TrackCategories,
  action: string,
  label: string,
) => {
  // Envio para o Google Analytics 4
  ReactGA4.event({ category, action, label });
};

export const sendEventAddProductToCartToAnalytics = ({
  productIndex,
  productCart,
}: {
  productIndex: number;
  productCart: ProductCartType;
}) => {
  ReactGA4.gtag('event', 'add_to_cart', {
    currency: 'BRL',
    value: productCart.amount * productCart.value,
    items: [
      {
        item_id: productCart.id,
        item_name: PRODUCTS[productCart.id].name,
        index: productIndex,
        price: productCart.value,
        quantity: productCart.amount,
      },
    ],
  });
};

export const sendEventRemoveProductFromCartToAnalytics = ({
  productIndex,
  productToRemove,
}: {
  productIndex: number;
  productToRemove: ProductCartType;
}) => {
  ReactGA4.gtag('event', 'remove_from_cart', {
    currency: 'BRL',
    value: productToRemove.amount * productToRemove.value,
    items: [
      {
        item_id: productToRemove.id,
        item_name: PRODUCTS[productToRemove.id].name,
        index: productIndex,
        price: productToRemove.value,
        quantity: productToRemove.amount,
      },
    ],
  });
};

export const proceedCheckoutAnalytics = ({
  transactionID,
  channel,
  pricingData,
  modalityData,
}: {
  transactionID: string;
  channel: string;
  pricingData: PricingType;
  modalityData: ModalityType;
}) => {
  const shipping = pricingData.resumo.servicos[
    modalityData.serviceGroupId
  ].lancamentos.find(lancamento => lancamento.nome.includes('entrega'));

  const taxWithoutShipping =
    pricingData.resumo.valorTotalTaxas - (shipping ? shipping.valorTotal : 0);

  ReactGA4.gtag('event', 'purchase', {
    transaction_id: transactionID,
    value: pricingData.resumo.valorTotalBeneficios,
    currency: 'BRL',
    tax: taxWithoutShipping,
    shipping: shipping?.valorTotal,
    items: pricingData.detalhe.produtos.map((product, index) => ({
      item_id: product.codigoProduto,
      item_name: PRODUCTS[product.codigoProduto].name,
      affiliation: channel.toUpperCase(),
      index,
      price: product.valorProduto,
      quantity: product.quantidade,
    })),
  });
};
