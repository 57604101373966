import { useContext } from 'react';

import {
  ToastMessageContext,
  ToastMessageContextData,
} from '@store/context/toast-message-context';

export function useToastMessageContext(): ToastMessageContextData {
  const context = useContext(ToastMessageContext);

  if (!context) {
    throw new Error(
      'useToastMessageContext must be used within a ToastMessageContext.',
    );
  }

  return context;
}
