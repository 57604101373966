import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import { ErrorPage } from '@pages/error-page';
import { FAQPage } from '@pages/faq-page';
import { HomePage } from '@pages/home-page';
import { TransportationCompanyPage } from '@pages/mobility-pages/transportation-company-page';
import { TransportationPaymentPage } from '@pages/mobility-pages/transportation-payment-page';
import { TransportationVoucherPage } from '@pages/mobility-pages/transportation-voucher-page';
import { ModalityPage } from '@pages/modality-page';
import { ComboCartPage } from '@pages/offer-page/custom-offer-pages/compositions/combo-cart-page';
import { ComboOrderRevisionPage } from '@pages/offer-page/custom-offer-pages/compositions/combo-order-revision-page';
import { CartPage } from '@pages/order/cart-page';
import { CheckoutPage } from '@pages/order/checkout-page';
import { CompanyDataPage } from '@pages/order/company-data-page';
import { OrderPreviewPage } from '@pages/order/order-preview-page';
import { ProductPage } from '@pages/product-page';

import { ContactModalContextProvider } from '@store/context/contact-modal-context';
import { ContractContextProvider } from '@store/context/contract-context';
import { RoutesContextProvider } from '@store/context/routes-context';
import { ToastMessageContextProvider } from '@store/context/toast-message-context';

import { DefaultLayout } from '@src/layouts/default-layout';
import { PurchaseLayout } from '@src/layouts/purchase-layout';

export default function Routes() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ContactModalContextProvider>
          <ToastMessageContextProvider>
            <ContractContextProvider>
              <RoutesContextProvider>
                <DefaultLayout />
              </RoutesContextProvider>
            </ContractContextProvider>
          </ToastMessageContextProvider>
        </ContactModalContextProvider>
      ),
      children: [
        {
          path: '*',
          element: <ErrorPage />,
        },
        {
          path: 'faq',
          element: <FAQPage />,
        },
        {
          path: 'simule',
          element: (
            <Navigate
              to={{
                pathname: '/',
                search: window.location.search,
              }}
            />
          ),
        },
        {
          path: 'modalidade',
          element: (
            <Navigate
              to={{
                pathname: '/',
                search: window.location.search,
              }}
            />
          ),
        },
        {
          path: '/modalidade/:modalityPath',
          element: <ModalityPage />,
        },
        {
          path: 'produtos/:productName',
          element: <ProductPage />,
        },
        {
          path: 'mobilidade/vale-transporte',
          element: <TransportationVoucherPage />,
        },
        {
          path: 'mobilidade/contrato',
          element: <TransportationCompanyPage />,
        },
        {
          path: 'pedido',
          element: <PurchaseLayout />,
          children: [
            {
              path: 'carrinho',
              element: <CartPage />,
            },
            {
              path: 'dados-empresa',
              element: <CompanyDataPage />,
            },
            {
              path: 'revisar-pedido',
              element: <OrderPreviewPage />,
            },
            {
              path: 'mobilidade/pagamento',
              element: <TransportationPaymentPage />,
            },
            { path: 'checkout', element: <CheckoutPage /> },
          ],
        },
        {
          path: 'combo',
          element: <PurchaseLayout variant="combo" />,
          children: [
            {
              path: 'carrinho',
              element: <ComboCartPage />,
            },
            {
              path: 'empresa',
              element: <CompanyDataPage />,
            },
            {
              path: 'pedido',
              element: <ComboOrderRevisionPage />,
            },
            {
              path: 'checkout',
              element: <CheckoutPage />,
            },
          ],
        },
        {
          index: true,
          element: <HomePage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
