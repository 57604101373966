import { ButtonHTMLAttributes, useMemo } from 'react';

import { CSS } from '@stitches/react';

import { Button } from '@elements/button';

import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { getProductListByModality } from '@helpers/product-helpers';
import { removePrefixAndSuffixFromComboModalityFormID } from '@helpers/service-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useSelector } from '@hooks/use-selector';

import { config, styled } from '@src/themes';

interface ComboSummaryGeneralFormButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  hiddenOnDesktop?: boolean;
  extraDisableCondition?: boolean;
  css?: CSS<typeof config>;
}
export function ComboSummaryGeneralFormButton({
  css,
  hiddenOnDesktop,
  extraDisableCondition,
  ...props
}: ComboSummaryGeneralFormButtonProps) {
  const { activeForm, modalitiesForSale, servicesIdsForSale, productsForSale } =
    useComboCartPageContext();
  const { comboCartPricingData, isCartLoading } = useSelector(
    ({ cart }) => cart,
  );
  const { isLoading: isRegisterCartLoading } = useSelector(
    ({ customer }) => customer,
  );

  const isLastFormSVA = useMemo(() => {
    if (!activeForm) return true;
    return (
      removePrefixAndSuffixFromComboModalityFormID(activeForm) ===
      ServiceGroupId.SVA
    );
  }, [activeForm]);

  const isLastFormOrAllCollapsed = useMemo(() => {
    if (!activeForm) return true;
    const modalityId = removePrefixAndSuffixFromComboModalityFormID(activeForm);

    const formModalityPosition = servicesIdsForSale.findIndex(
      modality => modality === modalityId,
    );
    return formModalityPosition === servicesIdsForSale.length - 1;
  }, [activeForm, servicesIdsForSale]);

  const lastFormDisableCondition = useMemo(() => {
    if (!comboCartPricingData) return true;

    const isThereAtLeastOneProductPricedPerModality = modalitiesForSale.every(
      modality => {
        const modalityProductList = getProductListByModality(
          modality.id,
          productsForSale,
        );

        return modalityProductList.some(modalityProduct =>
          comboCartPricingData.detalhe.produtos.some(
            productPriced => productPriced.codigoProduto === modalityProduct.id,
          ),
        );
      },
    );

    return !isThereAtLeastOneProductPricedPerModality;
  }, [comboCartPricingData, modalitiesForSale, productsForSale]);

  const disableCondition = useMemo(() => {
    if (!activeForm || !comboCartPricingData) return true;

    const modalityId = modalitiesForSale.find(
      modality =>
        modality.serviceGroupId ===
        removePrefixAndSuffixFromComboModalityFormID(activeForm),
    );

    const activeFormProductList = getProductListByModality(
      modalityId?.id ?? 0,
      productsForSale,
    );

    return !comboCartPricingData.detalhe.produtos.some(product =>
      activeFormProductList.some(
        activeFormProduct => activeFormProduct.id === product.codigoProduto,
      ),
    );
  }, [activeForm, comboCartPricingData, modalitiesForSale, productsForSale]);

  const verifyConditions = () => {
    if (isLastFormSVA) {
      return false;
    }
    return (
      extraDisableCondition ||
      (isLastFormOrAllCollapsed ? lastFormDisableCondition : disableCondition)
    );
  };

  return (
    <ComboSummaryGeneralFormButtonElement
      type="submit"
      form={activeForm}
      hiddenCondition={hiddenOnDesktop && !!activeForm}
      disabled={verifyConditions()}
      loading={isCartLoading || isRegisterCartLoading}
      css={css}
      {...props}
    >
      Confirmar e avançar
      <i
        className={`fa-regular fa-chevron-${
          isLastFormOrAllCollapsed ? 'right' : 'down'
        }`}
      />
    </ComboSummaryGeneralFormButtonElement>
  );
}

const ComboSummaryGeneralFormButtonElement = styled(Button.Default, {
  display: 'flex',

  variants: {
    hiddenCondition: {
      true: {
        display: 'none',

        '@deviceLg': {
          display: 'flex',
        },
      },
    },
  },
  defaultVariants: {
    hiddenCondition: false,
  },
});
