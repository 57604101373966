export type DynatraceActions =
  | 'selected_modality'
  | 'selected_ecossystem'
  | 'help_button'
  | 'cart_partial_value'
  | 'cart_products'
  | 'beneficiary_card_amount'
  | 'beneficiary_benefit_total_value'
  | 'beneficiary_amount';

export function useDynatraceTrackment() {
  const dynatraceTrackment = (
    actionName: DynatraceActions,
    propertieValue: string,
  ) => {
    if (window.dtrum) {
      window.dtrum.sendSessionProperties(
        undefined,
        undefined,
        { [`lojavr_${actionName}`]: propertieValue },
        undefined,
      );
    }
  };

  return {
    dynatraceTrackment,
  };
}
