import { ProductsShelf } from '@compositions/products-shelf';
import { PageSection } from '@elements/page-section';
import { SectionHolder } from '@elements/section-holder';
import { Title } from '@elements/title';

import { isCustomChannel } from '@helpers/channel-helpers';

import { useSelector } from '@hooks/use-selector';

export function ProductsShelfSection() {
  const { emissor, siglaCanal } = useSelector(
    ({ application }) => application.config,
  );

  return (
    <PageSection id="escolha-modalidade" dark>
      <SectionHolder>
        <Title.Root barColor="secondary">
          <Title.Default tag="h2">
            {isCustomChannel(siglaCanal)
              ? `A parceria entre o ${
                  isCustomChannel(siglaCanal)?.channelName
                } e ${emissor.shortName} tem tudo que sua empresa precisa!`
              : `A ${emissor.shortName} tem tudo que sua empresa precisa!`}
          </Title.Default>
          <Title.Subtitle
            css={{
              '@deviceSm': {
                fontSize: '1rem',
              },
            }}
          >
            Conheça abaixo nossas soluções:
          </Title.Subtitle>
        </Title.Root>
        <ProductsShelf />
      </SectionHolder>
    </PageSection>
  );
}
