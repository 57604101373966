/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CustomerData } from '@compositions/customer-modal';

import { ChannelInformationType } from '@extra-types/channel-information-type';
import { CompanySliceType } from '@extra-types/company-slice-type';
import { CompanyType } from '@extra-types/company-type';
import { ProductContractedType } from '@extra-types/product-contracted-type';
import { RegisterDataType } from '@extra-types/register-data-type';
import { RepresentativeType } from '@extra-types/representative-type';
import { formatChannelInformationResponseToChannelInformationType } from '@helpers/channel-helpers';
import { CustomerType } from '@helpers/enum/customer-type';
import { getUtmFromQueryString } from '@helpers/query-string-helpers';

import {
  fetchCompanyInformation,
  fetchCustomerLead,
  fetchRegisterCart,
} from '@store/thunks/customer-thunk';

type CustomerModalType = {
  state: boolean;
  isLoading: boolean;
  customerArrivedCheckoutPage: boolean;
  customerType: CustomerType | undefined;
  username: string;
  companyData: CompanySliceType;
  representativeData: RepresentativeType;
  registerData: RegisterDataType;
  contractedProducts: ProductContractedType[];
  unservedRegionProducts: number[];
  channelDescription: ChannelInformationType;
};

export const initialState = {
  state: false,
  isLoading: false,
  customerType: undefined,
  customerArrivedCheckoutPage: false,
  username: '',
  companyData: {
    cnpj: '',
    enderecoEntrega: {
      bairro: '',
      cep: '',
      cidade: '',
      complemento: '',
      estado: '',
      logradouro: '',
      numero: '',
      tipoLogradouro: '',
    },
    enderecoFiscal: {
      bairro: '',
      cep: '',
      cidade: '',
      complemento: '',
      estado: '',
      logradouro: '',
      numero: '',
      tipoLogradouro: '',
    },
    idCarrinho: '',
    lead: '',
    nomeFantasia: '',
    nomeImpressao: '',
  } as CompanySliceType,
  representativeData: {} as RepresentativeType,
  registerData: {} as RegisterDataType,
  contractedProducts: [],
  unservedRegionProducts: [],
  channelDescription: {} as ChannelInformationType,
} as CustomerModalType;

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    handleLeadData: (state, action: PayloadAction<CustomerData>) => {
      state.username = action.payload.empresaRh.contato.nomeContato;
      state.representativeData.utm = getUtmFromQueryString();
      state.representativeData.uuid = action.payload.uuid;
    },
    handleUpdateCompanyData: (state, action: PayloadAction<CompanyType>) => {
      state.companyData = {
        ...state.companyData,
        enderecoEntrega: action.payload.enderecoLocalEntrega,
        nomeFantasia: action.payload.nomeFantasia,
        nomeImpressao: action.payload.nomeFantasia,
      };
      state.registerData = {
        ...state.registerData,
        nomeCadastro: action.payload.dadosCadastrais.nomeCadastro,
        emailCadastro: action.payload.dadosCadastrais.emailCadastro,
        telefoneCadastro: action.payload.dadosCadastrais.telefoneCadastro,
        cpfContato: action.payload.dadosCadastrais.cpfContato,
        dataNascimento: action.payload.dadosCadastrais.dataNascimento,
      };
      state.representativeData = {
        ...state.representativeData,
        name: action.payload.representanteLegal.nome,
        document: action.payload.representanteLegal.documento,
        politicallyExposed:
          action.payload.representanteLegal.politicamenteExposta,
        type: action.payload.representanteLegal.tipo,
        extraContact: action.payload.contatoExtra,
        juridica: action.payload.representanteLegal.juridica,
      };
    },
    setCompanyCnpj: (state, action: PayloadAction<string>) => {
      if (state.companyData.cnpj !== action.payload) {
        state.unservedRegionProducts = [];
      }
      state.companyData.cnpj = action.payload;
    },
    handleSetCustomerEmail: (state, action: PayloadAction<string>) => {
      state.registerData.emailCadastro = action.payload;
    },
    handleContractedProducts: (
      state,
      action: PayloadAction<ProductContractedType[]>,
    ) => {
      state.contractedProducts = action.payload;
    },
    handleChannelDescription: (
      state,
      action: PayloadAction<ChannelInformationType>,
    ) => {
      state.channelDescription = action.payload;
    },
    changeCustomerType: (
      state,
      action: PayloadAction<CustomerType | undefined>,
    ) => {
      state.customerType = action.payload;
    },
    proceedToPurchase: state => {
      state.state = true;
    },
    registerCartID: (state, action: PayloadAction<string>) => {
      state.companyData.idCarrinho = action.payload;
    },
    handleLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    handleAddUnservedRegionProduct: (state, action: PayloadAction<number>) => {
      if (
        !state.unservedRegionProducts.some(
          productId => productId === action.payload,
        )
      ) {
        state.unservedRegionProducts.push(action.payload);
      }
    },
    handleCustomerArrivedCheckoutPage: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.customerArrivedCheckoutPage = action.payload;
    },
    closeModal: state => {
      state.state = false;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(fetchCompanyInformation.pending, state => {
        state.isLoading = true;
        state.customerType = undefined;
      })
      .addCase(fetchCompanyInformation.fulfilled, (state, action) => {
        state.channelDescription =
          formatChannelInformationResponseToChannelInformationType(
            action.payload.descricaoCanal,
          );
        state.isLoading = false;
      })
      .addCase(fetchCompanyInformation.rejected, state => {
        state.isLoading = false;
      })
      .addCase(fetchCustomerLead.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchCustomerLead.fulfilled, (state, action) => {
        state.companyData.nomeFantasia =
          action.payload.dadosEmpresa.nomeFantasia;
        state.companyData.nomeImpressao =
          action.payload.dadosEmpresa.nomeImpressao;
        state.companyData.lead = action.payload.idSimuleCompre;
        state.companyData.enderecoFiscal = action.payload.dadosEmpresa.endereco;
        state.companyData.enderecoEntrega =
          action.payload.dadosEmpresa.endereco;
        state.companyData.idCarrinho = '';
        state.isLoading = false;
      })
      .addCase(fetchCustomerLead.rejected, state => {
        state.isLoading = false;
      })
      .addCase(fetchRegisterCart.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchRegisterCart.fulfilled, (state, payload) => {
        state.companyData.idCarrinho = payload.payload.idCarrinho;
        state.isLoading = false;
      })
      .addCase(fetchRegisterCart.rejected, state => {
        state.isLoading = false;
      });
  },
});

export const {
  closeModal,
  proceedToPurchase,
  changeCustomerType,
  handleLeadData,
  setCompanyCnpj,
  handleContractedProducts,
  handleSetCustomerEmail,
  handleChannelDescription,
  handleAddUnservedRegionProduct,
  handleCustomerArrivedCheckoutPage,
  registerCartID,
  handleUpdateCompanyData,
  handleLoadingState,
} = customerSlice.actions;

export default customerSlice.reducer;
