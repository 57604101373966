import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { serviceNextSteps } from '@config/next-steps-config';
import { ServiceWithModalityType } from '@extra-types/service-with-modality-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

interface ServiceNextStepProps {
  serviceData: ServiceWithModalityType;
}

export function ServiceNextStep({ serviceData }: ServiceNextStepProps) {
  const serviceNextStepData = serviceNextSteps[serviceData.id];

  return (
    <NextStepContainer>
      <div className="service-next-step-container__image-container">
        <img
          src={`${
            env.URL_ASSETS + ASSETS_OTHERS_PATH + serviceNextStepData.image
          }`}
          alt={`Ilustração do próximo passo do serviço ${serviceData.title}`}
        />
      </div>
      <div className="service-next-step-container__content">
        <h3>{serviceData.title}</h3>
        <div className="content__description">
          {serviceNextStepData.description}
        </div>
      </div>
    </NextStepContainer>
  );
}

const NextStepContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  overflow: 'clip',
  gap: '0.25rem',

  backgroundColor: '$primaryDark',
  width: '100%',
  borderRadius: '10px',
  boxShadow: '0px 0px 8px #00000022',

  '.service-next-step-container__image-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,

    width: '108px',

    img: {
      width: 'auto',
      objectFit: 'contain',
    },
  },

  '.service-next-step-container__content': {
    color: '$white',

    padding: '1rem 1rem 1rem 0',

    h3: {
      marginBottom: '0.5rem',

      fontSize: convertPixelToRem(18),
      fontWeight: '$medium',
    },
  },

  '.content__description': {
    fontSize: convertPixelToRem(14),
    fontWeight: '$regular',
    lineHeight: 1.4,
  },

  '@deviceMd': {
    overflow: 'unset',
    marginTop: '3.75rem',
    paddingTop: '1.875rem',

    '.service-next-step-container__image-container': {
      position: 'absolute',
      top: '-3.75rem',
      left: '50%',
      transform: 'translateX(-50%)',

      height: '5.625rem',

      img: {
        maxWidth: 'unset',
      },
    },

    '.service-next-step-container__content': {
      padding: '0 1rem 1rem',
    },
  },
});
