import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

import { ComboServiceFormValue } from './combo-service-form-value';

interface ComboServiceFormCollapseBoxProps
  extends React.HTMLAttributes<HTMLDetailsElement> {
  children: React.ReactNode;
  value: number;
  isFormActive: boolean;
  showRecurringValueLabel: boolean;
}

export function ComboServiceFormCollapseBox({
  children,
  value,
  isFormActive,
  showRecurringValueLabel,
}: ComboServiceFormCollapseBoxProps) {
  return (
    <>
      <ComboServiceFormCollapseBoxDetails open={isFormActive}>
        <summary>
          Detalhes <i className="fa-sharp fa-solid fa-caret-down" />
        </summary>

        <ComboServiceFormCollapseBoxContent>
          <div className="service-products-group__container">{children}</div>
        </ComboServiceFormCollapseBoxContent>
      </ComboServiceFormCollapseBoxDetails>
      <ComboServiceFormCollapseBoxFooter>
        <span className="subtotal-label">Sub Total</span>
        <ComboServiceFormValue
          value={value}
          showRecurringValueLabel={showRecurringValueLabel}
          size="md"
        />
      </ComboServiceFormCollapseBoxFooter>
    </>
  );
}

const ComboServiceFormCollapseBoxDetails = styled('details', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  background: '$silverLight',
  padding: '0.5rem 1rem',

  cursor: 'pointer',

  summary: {
    color: '$primaryPure',
    fontSize: '0.75rem',
    textDecoration: 'underline',
    fontWeight: '$semibold',
    padding: '0.5rem 0',

    i: {
      color: '$primaryPure',
      fontSize: '0.75rem',
    },

    '&:disabled': {
      'span, i': {
        color: '$fontColorLight',
      },
    },
  },

  '& > summary': {
    listStyle: 'none',
  },
  '& > summary::-webkit-details-marker': {
    display: 'none',
  },

  variants: {
    collapse: {
      true: {},
      false: {
        'button.combo-product-form__details-button': {
          i: {
            transform: 'rotate(180deg)',
          },
        },
      },
    },
  },
});

const ComboServiceFormCollapseBoxContent = styled('div', {
  padding: '0 1rem 1rem',

  '.service-products-group__container': {
    backgroundColor: '$white',
    padding: '1rem',
    border: '1px solid #E4E8ED',
    borderRadius: '0.5rem',
  },
});

const ComboServiceFormCollapseBoxFooter = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  background: '#E4E8ED',
  padding: `${convertPixelToRem(18)} 1rem`,

  'span.subtotal-label': {
    fontSize: convertPixelToRem(14),
    fontWeight: '$bold',
  },
});
