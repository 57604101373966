import { useEffect } from 'react';

import { FAQCategoryItem } from '@elements/faq-category-item';

import {
  ApiErrorsMessages,
  ApiErrorsModalTitle,
} from '@helpers/enum/api-error-messages';
import { sortCategoriesListByDisplayOrder } from '@helpers/faq-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useFAQContext } from '@hooks/use-faq-context';
import { handleWarningModalContent } from '@store/slices/warning-modal-slice';

import { styled } from '@src/themes';

import { Skeleton } from './skeletons';

function FAQCategoryListSkeleton() {
  return (
    <FAQCategoryListContainer
      enableGap
      data-testid="faq-category-list-container-skeleton"
    >
      <Skeleton.Line height={68} />
      <Skeleton.Line height={68} />
      <Skeleton.Line height={68} />
    </FAQCategoryListContainer>
  );
}

export function FAQCategoryList() {
  const dispatch = useDispatch();
  const {
    activeCategory,
    handleChangeCategory,
    handleSelectedArticle,
    selectedArticle,
    faqCategories,
  } = useFAQContext();

  useEffect(() => {
    if (activeCategory === undefined && faqCategories.data) {
      handleChangeCategory(faqCategories.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqCategories.data]);

  useEffect(() => {
    if (
      faqCategories.error ||
      (faqCategories.data && faqCategories.data.length === 0)
    ) {
      dispatch(
        handleWarningModalContent({
          isVisible: true,
          title: ApiErrorsModalTitle.DEFAULT,
          content: ApiErrorsMessages.FAQ_CATEGORIES_ERROR,
          closeAction: {
            label: 'Fechar',
            action: 'redirectToInitialPage',
          },
        }),
      );
    }
  }, [dispatch, faqCategories.data, faqCategories.error]);

  if (
    faqCategories.isLoading ||
    faqCategories.isError ||
    !faqCategories.data ||
    (faqCategories.data && faqCategories.data.length === 0)
  ) {
    return <FAQCategoryListSkeleton />;
  }

  return (
    <FAQCategoryListContainer>
      {sortCategoriesListByDisplayOrder(faqCategories.data).map(categoria => (
        <FAQCategoryItem
          key={categoria.id}
          title={categoria.title}
          color={categoria.color}
          iconPath={categoria.iconName}
          isActive={
            categoria.id === (selectedArticle?.categoryId || activeCategory?.id)
          }
          onClick={() => {
            handleChangeCategory(categoria);
            handleSelectedArticle(undefined);
          }}
        />
      ))}
    </FAQCategoryListContainer>
  );
}

const FAQCategoryListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '@deviceLg': {
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
    padding: '0.875rem 0',

    overflowX: 'auto',
  },

  variants: {
    enableGap: {
      true: {
        gap: '4px',
      },
    },
  },
});
