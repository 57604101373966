import { useEffect, useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import WarningModal from '@components/warning-modal';
import CookiesConsentModal from '@compositions/cookies-consent-modal';
import CustomerModal from '@compositions/customer-modal';
import { Footer } from '@compositions/footer';
import { Header } from '@compositions/header';

import { sendPageViewToAnalytics } from '@helpers/analytics-helpers';

import { useVerifyCustomerCheckout } from '@hooks/use-verify-customer-checkout';

export function DefaultLayout() {
  let actionId: number | undefined;
  const location = useLocation();
  useVerifyCustomerCheckout();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);

    if (location.pathname !== '/') {
      sendPageViewToAnalytics(location);
    }
    if (window.dtrum) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      actionId = window.dtrum.enterAction(
        'page change',
        undefined,
        undefined,
        location.pathname,
      );
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (window.dtrum && actionId) {
      window.dtrum.leaveAction(actionId);
    }
  }, [location.pathname, actionId]);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <CustomerModal />
      <WarningModal />
      <CookiesConsentModal />
    </>
  );
}
