import { useMemo } from 'react';

import { maskOtherLocations, maskPhone } from '@helpers/string-helpers';

import { useSelector } from './use-selector';

type ContactType = {
  contactIcon: string;
  contactSubtitle: string;
  contactInformation: string;
};

type ChannelContactType = {
  phoneList: ContactType[];
  emailList: ContactType[];
};

export function useContact() {
  const {
    config: { emissor, siglaCanal, channelDescription },
    isLoading,
  } = useSelector(({ application }) => application);

  const chatData = emissor.chat;

  const isChatEnable =
    chatData.isActive &&
    (chatData.availableChannels === 'all' ||
      chatData.availableChannels.some(channel => channel === siglaCanal));

  const toggleChatModal = () => {
    if (window.toggleChat) {
      window.toggleChat();
    }
  };

  const channelContact = useMemo(() => {
    const contactInformation: ChannelContactType = {
      phoneList: [],
      emailList: [],
    };

    if (channelDescription.telefone) {
      contactInformation.phoneList.push({
        contactIcon: 'fal fa-phone',
        contactSubtitle: 'Clique aqui para fazer a ligação.',
        contactInformation: maskPhone(
          channelDescription.ddd.toString() + channelDescription.telefone,
        ),
      });
    }

    if (channelDescription.telefoneDemaisRegioes) {
      contactInformation.phoneList.push({
        contactIcon: 'fal fa-phone',
        contactSubtitle: 'Clique aqui para fazer a ligação.',
        contactInformation: maskOtherLocations(
          channelDescription.telefoneDemaisRegioes,
        ),
      });
    }

    if (channelDescription.telefoneChat) {
      contactInformation.phoneList.push({
        contactIcon: 'fa-brands fa-whatsapp',
        contactSubtitle: 'Clique aqui para iniciar uma conversa.',
        contactInformation: maskPhone(channelDescription.telefoneChat),
      });
    }

    if (channelDescription.email && emissor.isContactEmailActive) {
      contactInformation.emailList.push({
        contactIcon: 'fal fa-envelope',
        contactSubtitle: 'Clique aqui para enviar um e-mail.',
        contactInformation: channelDescription.email,
      });
    }

    return contactInformation;
  }, [channelDescription, emissor.isContactEmailActive]);

  return {
    channelContact,
    isChatEnable,
    toggleChatModal,
    isContactInformationLoading: isLoading,
  };
}
