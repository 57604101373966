import { isCustomChannel } from '@helpers/channel-helpers';
import { Emissor } from '@helpers/enum/emissor';

import { useSelector } from '@hooks/use-selector';

import { getTheme, globalCss } from '@src/themes';
import { emissorFonts, customChannelFonts } from '@src/themes/fonts';
import { reset } from '@src/themes/reset';

const globalTheme = (channel: string) => {
  if (isCustomChannel(channel)) {
    return globalCss({ ...customChannelFonts, ...reset });
  }
  return globalCss({ ...emissorFonts, ...reset });
};

export function ThemeManagerRoot({ children }: { children?: React.ReactNode }) {
  const { emissor, siglaCanal } = useSelector(
    ({ application }) => application.config,
  );
  globalTheme(siglaCanal)();

  return (
    <div
      className={getTheme(Emissor[emissor.initials], siglaCanal.toUpperCase())}
    >
      {children}
    </div>
  );
}
