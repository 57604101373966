import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CHECKOUT_PATHNAME_LIST } from '@config/application-config';
import { redirectToAnotherPage } from '@helpers/page-helpers';
import { removeSpecificQueryParamFromSearch } from '@helpers/query-string-helpers';

import { useSelector } from './use-selector';

const PATHNAME_BLACKLIST = [...CHECKOUT_PATHNAME_LIST];

export function useVerifyCustomerCheckout() {
  const { pathname, search } = useLocation();
  const { customerArrivedCheckoutPage, initialPage } = useSelector(state => ({
    customerArrivedCheckoutPage: state.customer.customerArrivedCheckoutPage,
    initialPage: state.application.initialPage,
  }));

  useEffect(() => {
    const shouldRedirect =
      !PATHNAME_BLACKLIST.includes(pathname) && customerArrivedCheckoutPage;

    if (shouldRedirect) {
      const updatedSearch = removeSpecificQueryParamFromSearch({
        search,
        queryString: 'offerid',
      });

      redirectToAnotherPage(initialPage + updatedSearch);
    }
  }, [customerArrivedCheckoutPage, initialPage, pathname, search]);
}
