import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ProductImage } from '@elements/product-image';

import { HCMOptions } from '@config/hcm-config';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

const CarthcmItemContainer = styled('div', {
  padding: '1rem 0',

  '& + div': {
    borderTop: '1px solid $silverLight',
  },

  '.cart-hcm-item-container__product-title': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    marginBottom: '1rem',

    '.product-title__information': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',

      p: {
        fontWeight: '$semibold',
      },

      img: {
        width: convertPixelToRem(30),
      },
    },
  },

  '.cart-hcm-item-container__product-information': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    fontWeight: '$medium',

    '.product-information__value, .product-information__amount': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      span: {
        fontSize: convertPixelToRem(14),

        '&:nth-child(2)': {
          fontSize: '1rem',
        },
      },
    },
  },
});

interface CartHCMItemProps {
  id: number;
  amount: number;
  value: number;
  showImage?: boolean;
}

export function CartModalHCMItem({
  id,
  amount,
  value,
  showImage,
}: CartHCMItemProps) {
  const productsData = useSelector(({ products }) => products.productList);
  const productData = productsData.find(product => product.id === id);

  if (!productData) {
    return null;
  }

  return (
    <CarthcmItemContainer>
      <div className="cart-hcm-item-container__product-title">
        <div className="product-title__information">
          {showImage && <ProductImage productId={id} />}
          <p>{productData.name}</p>
        </div>
      </div>
      <div className="cart-hcm-item-container__product-information">
        <div className="product-information__value">
          <span>Valor do plano</span>
          <span>{formatValueToCurrency(value)}</span>
        </div>
        <div className="product-information__amount">
          <span>Média de colaboradores</span>
          <span>
            {HCMOptions[id].find(option => option.value === amount)?.label}
          </span>
        </div>
      </div>
    </CarthcmItemContainer>
  );
}
