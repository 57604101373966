import { EcosystemProductsId } from '@helpers/enum/ecosystem-products-id';

export const ECOSYSTEM_NOT_FOUND_MESSAGE = 'Informação não encontrada';

export const ecosystemProductsShelfInformation: {
  [key: number]: {
    shelfIllustration: string;
    alternativeText: string;
    emphasisText: string;
  };
} = {
  [EcosystemProductsId.ADMISSAO]: {
    shelfIllustration: '/shelf-product-admissao.png',
    alternativeText: 'Logo Admissão',
    emphasisText: 'É tudo online.',
  },
  [EcosystemProductsId.PONTOMAIS]: {
    emphasisText: 'Controle de ponto online.',
    shelfIllustration: '/shelf-product-pontomais.png',
    alternativeText: 'Logo Pontomais',
  },
  [EcosystemProductsId.MOBILIDADE]: {
    emphasisText: 'Para você chegar mais longe.',
    shelfIllustration: '/shelf-product-mobilidade.png',
    alternativeText: 'Logo Audaz',
  },
};
