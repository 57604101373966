import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const CompanyNameInputContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: convertPixelToRem(70),

  width: '100%',

  '> div': {
    flexGrow: 1,

    width: 0,
  },

  '@deviceLg': {
    gap: '2rem',
  },

  '@deviceMd': {
    flexDirection: 'column',
    gap: '1rem',

    '> div': {
      width: '100%',
    },
  },
});
