/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useOnClickOutside } from 'usehooks-ts';

import { VideoDataType } from '@extra-types/video-data-type';

import { useVideoModalContext } from '@hooks/use-video-modal-context';

import { styled } from '@src/themes';

export function VideoModal({ data }: { data: VideoDataType }) {
  const { handleVideoModal, videoModalState } = useVideoModalContext();
  const nodeRef = useRef(null);
  const modalContainerRef = useRef(null);
  useOnClickOutside(modalContainerRef, handleVideoModal);
  const ANIMATION_TIME = 400;

  const changeOverflowToInvisible = () => {
    document.body.style.overflow = 'hidden';
  };

  const changeOverflowToVisible = () => {
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={videoModalState}
      timeout={ANIMATION_TIME}
      classNames="modal"
      unmountOnExit
      onEnter={changeOverflowToInvisible}
      onExit={changeOverflowToVisible}
    >
      <VideoModalContainer data-testid="video-modal" ref={nodeRef}>
        <div className="video-modal__video-container" ref={modalContainerRef}>
          <VideoModalCloseButton type="button" onClick={handleVideoModal}>
            <i className="fa-light fa-xmark" />
          </VideoModalCloseButton>
          <video
            data-testid="video"
            src={data.url}
            autoPlay
            controls
            preload="metadata"
            controlsList="nodownload"
            poster={data.thumbnail}
          />
        </div>
      </VideoModalContainer>
    </CSSTransition>
  );
}

export const VideoModalContainer = styled('div', {
  zIndex: '$9',
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  backgroundColor: 'rgba(0,0,0,0.7)',
  width: '100%',
  height: '100%',
  overscrollBehavior: 'contain',
  backdropFilter: 'blur(4px)',

  '&.modal-enter': {
    opacity: '0',

    video: {
      transform: 'translateY(100%)',
    },
  },

  '&.modal-enter-active': {
    opacity: '1',
    transition: 'opacity 0.4s',

    video: {
      transform: 'translateY(0)',
      transition: 'transform 0.4s',
    },
  },

  '&.modal-exit': {
    opacity: '1',

    video: {
      transform: 'translateY(0)',
    },
  },

  '&.modal-exit-active': {
    opacity: '0',
    transition: 'opacity 0.4s',

    video: {
      transform: 'translateY(100%)',
      transition: 'transform 0.4s',
    },
  },

  '.video-modal__video-container': {
    width: '100%',
    maxWidth: '1280px',
    margin: '1rem',
    objectFit: 'contain',

    video: {
      width: '100%',
    },
  },
});

export const VideoModalCloseButton = styled('button', {
  zIndex: '$9',
  position: 'fixed',
  right: '2rem',
  top: '2rem',

  background: 'transparent',
  opacity: 0.7,
  transition: 'all .4s',

  color: '$white',
  fontSize: '2rem',

  '&:hover': {
    opacity: 1,
  },

  '@deviceSm': {
    top: '1rem',
    right: '1rem',
  },
});
