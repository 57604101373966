import addressData from '@helpers/data/address.json';
import statesData from '@helpers/data/states.json';

interface InitialsAddressData {
  initials: string;
  content: string;
}

const telephoneInitialNumberList = [2, 3, 4, 5, 7];

export const stateInitals: string[] = statesData;

export const addressInitials: InitialsAddressData[] = addressData;

export const addressInitialsDefault = addressInitials[0];

export function getAddressType(value: string): string {
  const addressType: {
    initials: string;
    content: string;
  }[] = addressInitials.filter(
    address =>
      address.initials === value.toUpperCase() ||
      address.content === value.toUpperCase(),
  );

  // eslint-disable-next-line react/jsx-indent
  return addressType[0]
    ? addressType[0].content
    : addressInitialsDefault.content;
}

export function cellphoneValidation(value: string) {
  const valueClear = value.replace(/\D/g, '');

  if (!(valueClear.length >= 10 && valueClear.length <= 11)) return false;

  if (
    valueClear.length === 11 &&
    parseInt(valueClear.substring(2, 3), 10) !== 9
  )
    return false;

  const sameNumberList = Array(10)
    .fill('')
    .map((_, index) => {
      return new Array(8).join(`${index}`);
    });

  if (sameNumberList.some(sameNumber => valueClear.includes(sameNumber))) {
    return false;
  }

  const codigosDDD = [
    11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
    37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63,
    65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88,
    89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  ];

  if (codigosDDD.indexOf(parseInt(valueClear.substring(0, 2), 10)) === -1)
    return false;

  return !(
    valueClear.length === 10 &&
    telephoneInitialNumberList.indexOf(
      parseInt(valueClear.substring(2, 3), 10),
    ) === -1
  );
}
