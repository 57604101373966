import React from 'react';

import { styled } from '@src/themes';

type DetailsProps = {
  summary: string;
  variant?: 'primary' | 'secondary';
  children: React.ReactNode;
};

export default function Details({
  summary,
  variant = 'primary',
  children,
}: DetailsProps) {
  return (
    <DetailsContainer variant={variant}>
      <summary>
        <span>{summary}</span>
        <i className="fas fa-caret-down" />
      </summary>
      {children}
    </DetailsContainer>
  );
}

const DetailsContainer = styled('details', {
  backgroundColor: '$silverLight',
  borderRadius: '0.5rem',
  paddingBottom: '0.5rem',
  marginTop: '0.5rem',
  fontSize: '0.75rem',

  summary: {
    cursor: 'pointer',
    padding: '0.5rem 1rem 0',
  },

  '&[open] summary': {
    marginBottom: '0.5rem',
  },

  '& >summary::marker, & > summary::-webkit-details-marker': {
    display: 'none',
  },

  variants: {
    variant: {
      primary: {
        '& > summary': {
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          color: '$primaryPure',

          fontWeight: 'bold',
          listStyle: 'none',
          transition: 'margin 150ms ease-out',
        },
      },
      secondary: {
        summary: {
          padding: '0.5rem 0.5rem 0 0.5rem',
        },
        '& > summary': {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',

          fontWeight: 'bold',
          listStyle: 'none',
          transition: 'margin 150ms ease-out',

          i: {
            color: '$primaryPure',
          },
        },
      },
    },
  },
});
