import InformationRow from '@elements/table-elements/information-row';
import { SVARow } from '@elements/table-elements/sva-row';

import { SVAProducts, TAX_CARD } from '@config/products-config';
import { TableAdapterType } from '@extra-types/table-types';

type BonusRowProps = {
  tableData: TableAdapterType;
};

export function BonusRow({ tableData }: BonusRowProps) {
  const { totalTax, deliveryFee, totalCards, bonus } = tableData;
  return (
    <>
      {bonus.map(bonusData => {
        const SVAData = SVAProducts[bonusData.id];
        return (
          <SVARow
            key={bonusData.id}
            svaData={{
              title: SVAData.title,
              subtitle: SVAData.subtitle,
              offerText: 'Oferta Exclusiva',
            }}
          >
            <SVAData.Description
              productBonusDataList={bonusData.entryBonusRules}
            />
          </SVARow>
        );
      })}
      <InformationRow
        variant="green"
        informationData={{
          title: 'TOTAL DE TARIFAS DOS PRODUTOS',
          subtitle: '',
          value: tableData.simulation ? totalTax - deliveryFee : totalTax,
          discount: totalCards * TAX_CARD,
        }}
      />
      <InformationRow
        variant="green"
        informationData={{
          title: 'FRETE',
          subtitle: 'Valor promocional para 1° pedido',
          value: deliveryFee,
          discount: totalCards * TAX_CARD,
        }}
      />
    </>
  );
}
