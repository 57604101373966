import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class NoConditionsForContract extends ApplicationError {
  constructor() {
    super(
      SpecificsApiErrors.NO_CONDITIONS_FOR_CONTRACT,
      ApiErrorsMessages.NO_CONDITIONS_FOR_CONTRACT,
    );
  }
}
