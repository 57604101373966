import { SwiperSlide } from 'swiper/react';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const CardSliderContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  background: 'unset',
  width: '100%',
  padding: '0 3.5rem',
  border: 'none',

  '@deviceSm': {
    width: '100%',
    padding: '0',
  },
});

export const CardSlide = styled(SwiperSlide, {
  display: 'flex',
  justifyContent: 'center',

  img: {
    display: 'block',

    width: '100%',
    maxWidth: convertPixelToRem(400),

    '@deviceSm': {
      width: '100%',
      marginLeft: 0,
    },
  },
});

export const SlideButtonStyled = styled('button', {
  zIndex: 20,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  background: '#ffffff 0% 0% no-repeat padding-box',
  width: convertPixelToRem(39),
  height: convertPixelToRem(41),
  border: 'none',
  borderRadius: convertPixelToRem(100),
  boxShadow: '0px 0px 8px #00000022',
  transition: '.4s ease',
  cursor: 'pointer',

  color: '$primaryDark',
  fontWeight: '$bold',

  '&::after': {
    fontSize: '1rem',
    fontFamily: '$fontAwesome',
    lineHeight: 1,
    letterSpacing: 0,
  },

  '&:hover': {
    background: '$primaryDark',

    color: '$white',
  },

  '&.card-slider-button-lock': {
    visibility: 'hidden',
    maxHeight: 0,
  },

  '@deviceSm': {
    display: 'none',
  },

  variants: {
    hidden: {
      true: {
        visibility: 'hidden',
        maxHeight: 0,
      },
    },
    position: {
      right: {
        right: '4px',

        '&::after': {
          content: String.raw`\f105`,
        },
      },
      left: {
        left: '4px',

        '&::after': {
          content: String.raw`\f104`,
        },
      },
    },
  },

  defaultVariants: {
    hidden: false,
  },
});
