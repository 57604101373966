import { HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from '@config/styles-config';

import { styled } from '@src/themes';

export const ComboCartSummaryModalityGroup = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  '@deviceLg': {
    overflowY: 'auto',

    maxHeight: `calc(100vh - ${HEADER_HEIGHT}px - 1rem - 250px)`,
    padding: '2px 2px',
  },

  '@deviceSm': {
    maxHeight: `calc(100vh - ${HEADER_MOBILE_HEIGHT}px - 1rem - 250px)`,
  },

  variants: {
    isCollapsed: {
      true: {
        '@deviceLg': {
          display: 'none',
        },
      },
    },
  },
});

export const ComboCartSummaryModalityContainer = styled('li', {
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-start',

  background: '$white',
  width: '100%',
  borderRadius: '6px',
  padding: '1rem',
  boxShadow: '0px 0px 0.5rem #00000021',

  '@deviceLg': {
    boxShadow: 'none',
    padding: '0 1.25rem 0.5rem',
    marginBottom: '0.5rem',
    borderRadius: 0,
  },
});

export const ComboCartSummaryStaticMessageContainer = styled('div', {
  marginTop: '1.5rem',
  paddingBottom: '1.25rem',
});

export const ComboCartSummaryHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',

  '@deviceLg': {
    gap: '1rem',

    padding: '0.5rem 1rem 1rem',
  },
});
