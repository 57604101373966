export enum AdditionalProductTypeEnum {
  MODULO = 'MODULO',
  EXTENSAO = 'EXTENSAO',
}

type ModuleOrExtensionType = 'MODULO' | 'EXTENSAO';

export type AdditionalProductType = {
  id: number;
  name: string;
  description: string;
  type: string;
  isRequired: boolean;
  productType: ModuleOrExtensionType;
};

export type AdditionalProductsType = {
  required: AdditionalProductType[];
  unrequired: AdditionalProductType[];
};
