import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { EcosystemProductType } from '@extra-types/ecosystem-product-type';
import { ModalityType } from '@extra-types/modality-type';

type ShelfIllustrationProps = {
  productInformation: ModalityType | EcosystemProductType;
};

export function ShelfIllustration({
  productInformation,
}: ShelfIllustrationProps) {
  const imagePath = `${env.URL_ASSETS_CHANNEL}${ASSETS_OTHERS_PATH}${productInformation.shelfInformation.illustration}`;

  return (
    <img
      className="stamp__modality-image"
      src={imagePath}
      alt={`Imagem ilustrativa sobre ${productInformation.shelfInformation.title}`}
    />
  );
}
