import { useNavigate } from 'react-router-dom';

import { Button } from '@elements/button';
import { OfferWarningMessage } from '@pages/offer-page/custom-offer-pages/elements/offer-warning-message';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { clearCart } from '@store/slices/cart-slice';
import { closeModal } from '@store/slices/customer-slice';

import { OfferFacade } from '@src/facade/offer-facade';

import { ScenariosContainer } from './styles';

function OfferRestriction() {
  const { selectedOffer } = OfferFacade();
  const { initialPage } = useSelector(({ application }) => application);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!selectedOffer) {
    return null;
  }

  return (
    <ScenariosContainer>
      <div className="information-box offer-restriction-message">
        <p>Você já é nosso cliente, é bom tê-lo de volta!</p>
        <OfferWarningMessage
          variant="alert"
          message="Que pena... essa oferta não é válida para seu canal de atendimento."
        />
      </div>
      <p>
        Você pode voltar à nossa página inicial e conhecer melhor nossas outras
        soluções.
      </p>
      <Button.Default
        onClick={() => {
          dispatch(closeModal());
          dispatch(clearCart());
          navigate({
            pathname: initialPage,
          });
        }}
      >
        <i className="fa-regular fa-arrow-left" />
        Voltar à página inicial
      </Button.Default>
    </ScenariosContainer>
  );
}

export default OfferRestriction;
