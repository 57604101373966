import { AwardsSection } from '@compositions/sections/awards-section';
import { BenefitsSection } from '@compositions/sections/benefits-section';
import { ContactSection } from '@compositions/sections/contact-section';
import { FaqPreviewSection } from '@compositions/sections/faq-preview-section';
import { WalletBenefitsSection } from '@compositions/sections/wallet-benefits-section';

import { SectionNameType } from '@extra-types/section-name-type';

export const MODAL_GROUP_ID = 'modal-group';

export const SECTIONS_COMPONENTS: {
  [key in SectionNameType]: {
    type: string;
    component: JSX.Element;
  };
} = {
  LOJA_SECTION_BENEFITS: {
    type: 'informative',
    component: (
      <BenefitsSection id="secao-beneficios" name="LOJA_SECTION_BENEFITS" />
    ),
  },
  LOJA_SECTION_AWARDS: {
    type: 'informative',
    component: <AwardsSection id="secao-premios" name="LOJA_SECTION_AWARDS" />,
  },
  LOJA_SECTION_CONTACT: {
    type: 'informative',
    component: (
      <ContactSection id="secao-contato" name="LOJA_SECTION_CONTACT" />
    ),
  },
  LOJA_SECTION_FAQ: {
    type: 'informative',
    component: (
      <FaqPreviewSection
        id="secao-geral-faq"
        name="LOJA_SECTION_FAQ"
        tagFAQ="general"
      />
    ),
  },
  LOJA_VT_SECTION_FAQ_PREVIEW: {
    type: 'vale-transporte-page',
    component: (
      <FaqPreviewSection
        id="secao-vt-faq"
        name="LOJA_VT_SECTION_FAQ_PREVIEW"
        tagFAQ="vt-icon"
      />
    ),
  },
  LOJA_WALLET_SECTION_FAQ: {
    type: 'modalidade-multi',
    component: (
      <FaqPreviewSection
        id="secao-multi-faq"
        name="LOJA_WALLET_SECTION_FAQ"
        variant="tertiary"
        tagFAQ="multi"
      />
    ),
  },
  LOJA_WALLET_SECTION_BENEFITS: {
    type: 'modalidade-multi',
    component: (
      <WalletBenefitsSection
        id="secao-wallet-benefits"
        name="LOJA_WALLET_SECTION_BENEFITS"
      />
    ),
  },
};
