import { MobilitySectionTitle } from '@elements/mobility-section-title';
import { PageSection } from '@elements/page-section';

import { ASSETS_MOBILITY_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { ProductType } from '@extra-types/product-type';
import {
  convertPixelToRem,
  getStyleTokenByProduct,
} from '@helpers/style-helpers';

import { styled } from '@src/themes';

export function BalanceManagementPlatformSection({
  productData,
}: {
  productData: ProductType;
}) {
  return (
    <PageSection dark>
      <SectionHolder>
        <div className="mobility-platform__image-area">
          <img
            src={`${
              env.URL_ASSETS + ASSETS_MOBILITY_PATH
            }/gestao-de-saldo-plataforma.png`}
            alt=""
          />
        </div>
        <div className="mobility-platform__text-content">
          <div className="text-content__platform-information">
            <MobilitySectionTitle
              color={`$colors${getStyleTokenByProduct(
                productData.colorPrefixToken,
                'primary',
                'pure',
              )}`}
              marginBottom="1rem"
            >
              Descomplique agora mesmo a sua gestão de vale-transporte com a
              solução de mobilidade da VR.
            </MobilitySectionTitle>
            <p>
              Ganhe mais tempo para dedicar aos seus trabalhadores, garanta{' '}
              <strong>segurança jurídica</strong> da sua empresa e{' '}
              <strong>economize na compra de vale-transporte.</strong>
            </p>
          </div>
          <div className="text-content__platform-benefits-list">
            <h3>Uma plataforma completa para empresas de todos os portes</h3>
            <ul>
              <li>
                Leitura de saldo robotizada nas operadoras de VT que
                disponibilizam esta informação
              </li>
              <li>
                Visualização de todos os saldos dos cartões nas operadoras de VT
                que disponibilizam esta informação
              </li>
              <li>Tenha o histórico das transações com as operadoras</li>
              <li>Integre com a folha de pagamento dos seus trabalhadores</li>
              <li>Pague apenas um boleto para todas as recargas</li>
              <li>
                Tenha o cálculo e a recuperação de saldo de forma automatizada
              </li>
            </ul>
          </div>
        </div>
      </SectionHolder>
    </PageSection>
  );
}

const SectionHolder = styled('div', {
  display: 'flex',
  gap: convertPixelToRem(90),
  alignItems: 'center',

  paddingTop: '3rem',
  paddingBottom: '3rem',

  '.mobility-platform__image-area': {
    img: {
      height: convertPixelToRem(244),
      objectFit: 'contain',

      '@deviceLg': {
        height: convertPixelToRem(200),
      },

      '@deviceMd': {
        height: 'unset',
        width: '100%',
      },
    },
  },

  '.mobility-platform__text-content': {
    '.text-content__platform-information': {
      marginBottom: '2.5rem',

      p: {
        color: '$black',
        lineHeight: 1.5,
      },
    },

    '.text-content__platform-benefits-list': {
      h3: {
        marginBottom: '1rem',

        color: '$primaryDark',
        fontSize: convertPixelToRem(18),
      },

      ul: {
        listStyle: 'inside',

        li: {
          color: '$black',
          fontSize: '0.75rem',

          '& + li': {
            marginTop: '1rem',
          },
        },
      },
    },
  },

  '@deviceLg': {
    gap: '3rem',
  },

  '@deviceMd': {
    flexDirection: 'column',
  },
});
