import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const SectionHolder = styled('div', {
  padding: '2rem 0',

  hgroup: {
    marginBottom: convertPixelToRem(24),
  },
});
