import { DynatraceActions } from '@hooks/use-dynatrace-trackment';

import { MODALITIES } from './modality-config';

export const queryStringHelpButtonMapping: Record<string, string> = {
  '/': 'home_ajuda',
  '/modalidade/avulso': 'modalidade_avulso_ajuda',
  '/modalidade/multi': 'modalidade_multi_ajuda',
  '/pedido/carrinho': 'carrinho_ajuda',
  '/pedido/dados-empresa': 'dados_empresa_ajuda',
  '/pedido/revisar-pedido': 'revisar_pedido_ajuda',
  '/pedido/checkout': 'checkout_ajuda',
};

type DynatraceMappingType = { key: DynatraceActions; value: string };

const modalitiesMapping = Object.values(MODALITIES).reduce((acc, { keyId }) => {
  return {
    ...acc,
    [keyId]: {
      key: 'selected_modality' as DynatraceActions,
      value: keyId,
    },
  };
}, {} as Record<string, DynatraceMappingType>);

export const productsMapping: Record<string, DynatraceMappingType> = {
  '8000': { key: 'selected_ecossystem', value: 'vr_admissao' },
  '8001': { key: 'selected_ecossystem', value: 'vr_pontomais' },
  'modalidade-not-found': {
    key: 'selected_modality',
    value: 'modalidade_not_found',
  },
  '290': { key: 'selected_modality', value: 'modalidade_avulso_caixa' },
  '270': { key: 'selected_modality', value: 'modalidade_avulso_caixa' },
  ...modalitiesMapping,
};
