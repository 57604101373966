import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { PageTitle } from '@components/page-title';
import { ProductShelf } from '@components/product-shelf';
import { Skeleton } from '@components/skeletons';
import { SliderSwiper } from '@components/slider-swiper';
import Wootric from '@components/wootric';
import { Button } from '@elements/button';
import NextStep from '@elements/next-step';
import { PageContainer } from '@elements/page-container';
import { SimplePageTitle } from '@elements/simple-page-title';
import { Title } from '@elements/title';

import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { customNextSteps } from '@config/next-steps-config';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { getProductStaticData } from '@helpers/product-helpers';
import { getFirstName } from '@helpers/string-helpers';
import { holderStyleWithMobileResponsive } from '@helpers/style-helpers';

import { useContract } from '@hooks/use-contract';
import { useDispatch } from '@hooks/use-dispatch';
import { useProductsShelf } from '@hooks/use-products-shelf';
import { useSelector } from '@hooks/use-selector';
import { clearCart } from '@store/slices/cart-slice';

import { styled } from '@src/themes';

export function TransportationPaymentPage() {
  const { config, initialPage } = useSelector(({ application }) => application);
  const { cartModality, productCartList } = useSelector(({ cart }) => cart);
  const { productsShelfList, isLoading } = useProductsShelf({
    loadEcosystemProducts: true,
    showProductsOnSingleModality: true,
  });
  const contract = useContract();
  const { companyData, representativeData, username } = useSelector(
    ({ customer }) => customer,
  );
  const location = useLocation();
  const dispatch = useDispatch();

  const getOrderedProducts = () => {
    return productCartList.map(productCart => productCart.id);
  };

  const verifyConditionsToUseThisPage = useMemo(
    () => companyData.cnpj !== '' || companyData.lead !== '',
    [companyData.cnpj, companyData.lead],
  );

  if (!verifyConditionsToUseThisPage || cartModality === undefined) {
    return (
      <Navigate
        to={{
          pathname: initialPage,
          search: location.search,
        }}
      />
    );
  }

  return (
    <>
      <PageTitle title="Pagamento" />
      <TransportationPaymentContainer>
        <SimplePageTitle css={{ fontSize: '1.5rem' }}>
          <strong>{getFirstName(username)}</strong>, obrigado por adquirir os
          produtos {config.emissor.name.split(' ')[0]}!
        </SimplePageTitle>
        <div className="cards-holder">
          {getOrderedProducts().map(cardId => {
            const productData = getProductStaticData(cardId);
            return (
              <img
                key={cardId}
                src={`${env.URL_ASSETS_CHANNEL + productData.logoPath}`}
                alt={`Saldo ${productData.name}`}
              />
            );
          })}
        </div>
        <div className="contract-download-information-box">
          <i className="fa-solid fa-file-arrow-down" />
          <p>
            Seu contrato está disponível para download.&nbsp;
            <button
              type="button"
              onClick={() => contract.getDownloadContract()}
            >
              Clique aqui
            </button>
          </p>
        </div>
        <hr />

        <SimplePageTitle css={{ fontSize: '1.5rem' }}>
          Próximo passo:
        </SimplePageTitle>
        <div className="next-steps-holder">
          {customNextSteps[ModalitiesId.MOBILIDADE].map(nextStep => (
            <NextStep.Default
              key={nextStep.id}
              icon={nextStep.icon}
              image={
                nextStep.image &&
                `${env.URL_ASSETS}${ASSETS_OTHERS_PATH}${nextStep.image}`
              }
            >
              {nextStep.content}
            </NextStep.Default>
          ))}
        </div>
        <hr className="separator--margin" />
      </TransportationPaymentContainer>
      <OtherServicesSectionContainer>
        {isLoading ? (
          <Skeleton.SectionContainer className="loading-container" noPadding>
            <Skeleton.Title />
            <Skeleton.GroupContainer guidance="horizontal">
              <Skeleton.Modality />
              <Skeleton.Modality />
              <Skeleton.Modality />
            </Skeleton.GroupContainer>
            <Skeleton.Line />
          </Skeleton.SectionContainer>
        ) : (
          <>
            <Title.Default tag="h3" size="md">
              Conheça nossos outros serviços:
            </Title.Default>
            <SliderSwiper
              itensPerColumn={1}
              showSlider
              breakpoints={{
                768: {
                  spaceBetween: 16,
                  slidesPerView: 2,
                },
                290: {
                  slidesPerView: 1,
                },
              }}
            >
              {productsShelfList
                .filter(product => product.keyId !== 'modalidade-transporte')
                .map(product => (
                  <ProductShelf
                    key={product.keyId}
                    data={product}
                    haveToClearCart
                  />
                ))}
            </SliderSwiper>
            <Button.Anchor
              id="btn-pagamento-pagina-inicial"
              variant="secondary"
              type="button"
              onClick={() => dispatch(clearCart())}
              href={`/${location.search}`}
            >
              <i className="fas fa-home" />
              Voltar à página inicial
            </Button.Anchor>
          </>
        )}
      </OtherServicesSectionContainer>
      {config.emissor.isSurveyActive && (
        <Wootric email={representativeData.email} cnpjRH={companyData.cnpj} />
      )}
    </>
  );
}

export const TransportationPaymentContainer = styled(PageContainer, {
  ...holderStyleWithMobileResponsive,
  flex: 1,

  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',

  paddingTop: '0 !important',
  paddingBottom: '2rem',

  '.next-steps-holder': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2.5rem',

    '@deviceSm': {
      gap: '2rem',
    },
  },

  'div:last-child': {
    img: {
      bottom: '0',
    },
  },

  '.cards-holder': {
    textAlign: 'center',
    maxHeight: '77px',

    img: {
      height: '100%',
    },
  },

  '.contract-download-information-box': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    fontWeight: '500',

    i: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,

      background: '$fontColorPrimary',
      width: '2.75rem',
      height: '2.75rem',
      borderRadius: '$circular',

      color: '$white',
      fontSize: '1.5rem',
    },

    button: {
      backgroundColor: 'transparent',
      color: '$primaryPure',
      textDecoration: 'underline',
      fontWeight: '600',
    },
  },

  '.next-step-content': {
    width: '70%',

    '@deviceLg': {
      width: '60%',
    },

    '@deviceMd': {
      width: '100%',
    },
  },

  hr: {
    background: '$silverLight',
    height: '1px',
    width: '100%',

    '&.separator--margin': {
      margin: '1.5rem 0',
    },
  },
});

const OtherServicesSectionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  position: 'relative',

  backgroundColor: '#E4E8ED',
  width: '100%',

  padding: '1.5rem 1rem',

  '> div': {
    width: '90%',
  },

  '.loading-container': {
    width: '100%',
  },

  '.swiper-horizontal': {
    padding: '1.25rem 0',
  },

  '.swiper-button-next': {
    right: '1.5rem',
  },

  '.swiper-button-prev': {
    left: '1.5rem',
  },

  '.swiper-button-disabled': {
    display: 'none',
  },

  h3: {
    fontWeight: '500',
  },
});
