import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const ContactInputsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  width: '100%',

  '.contact__title': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    marginBottom: '30px',

    fontSize: '1rem',
    fontWeight: '$bold',

    '@deviceSm': {
      marginBottom: convertPixelToRem(40),
    },

    '.title__extra-contact': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  '.contact__remove-button': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    background: 'transparent',
    minHeight: '1.875rem',
    minWidth: '1.875rem',
    padding: 0,
    borderRadius: '50%',
    border: 0,
    boxShadow: 'none',

    color: '$primaryPure',
    fontSize: '1.25rem',

    '&:focus': {
      outline: 0,
    },

    '&:hover': {
      color: '$white',
    },

    '@deviceSm': {
      justifyContent: 'space-between',
    },
  },

  '.contact__list-contacts': {
    marginBottom: 0,
    listStyle: 'none',

    button: {
      height: '2rem',
    },

    '> li': {
      padding: convertPixelToRem(20),
      margin: `0 ${convertPixelToRem(-20)} ${convertPixelToRem(10)}`,
    },

    '> li:last-child': {
      marginBottom: 0,
      paddingBottom: 0,
    },

    'li + li': {
      borderTop: '1px solid $silverPure',
    },

    '@deviceMd': {
      marginTop: 0,
    },

    '@deviceSm': {
      '> li': {
        borderTop: '1px solid $silverPure',
        marginBottom: 0,
      },
    },
  },

  '.contact__wrapper': {
    display: 'flex',
    justifyContent: 'space-between',

    width: '100%',

    '.contact__wrapper--justify-start': {
      justifyContent: 'flex-start',

      '@deviceSm': {
        alignItems: 'center',
      },
    },

    '.contact__wrapper--remove-margin-bottom': {
      '> div': {
        marginBottom: 0,
      },
    },
  },

  '.contact__wrapper--add-button': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',

    '@deviceSm': {
      marginBottom: convertPixelToRem(10),

      '> button.MuiButton-root.btn-xs': {
        width: '100%',
        maxWidth: '15.9375rem',
      },
    },
  },

  '.contact__wrapper--input': {
    '> div + div': {
      marginLeft: convertPixelToRem(40),
    },

    '@deviceMd': {
      flexDirection: 'column',

      '& + div': {
        marginTop: 0,
      },

      '> div + div': {
        marginLeft: 0,
      },

      '.MuiFormControl-root': {
        width: '100%',
      },

      '> div': {
        width: '100% !important',
        margin: `0 0 ${convertPixelToRem(20)}`,
      },
    },
  },

  '.contact__wrapper--remove-button-mobile': {
    display: 'none',

    '@deviceSm': {
      display: 'flex',
      marginTop: `${convertPixelToRem(30)} !important`,

      '> button.MuiButton-root.btn-xs': {
        display: 'block',

        width: '100%',
        maxWidth: '15.9375rem',
      },
    },
  },

  '.contact__wrapper--checkbox': {
    display: 'flex',
    justifyContent: 'center',
    gap: convertPixelToRem(40),
    marginBottom: convertPixelToRem(40),

    '@deviceSm': {
      justifyContent: 'space-between',
    },
  },
});
