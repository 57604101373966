import { env } from '@config/env';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

interface PortalAccessMessageProps {
  message?: string;
}

export function PortalAccessMessage({ message }: PortalAccessMessageProps) {
  const { emissor } = useSelector(({ application }) => application.config);

  return (
    <PortalAccessMessageContainer>
      {message && <p>{message}</p>}
      <p>
        Para gerencia-los acesse o{' '}
        <a
          title={`Link para o ${emissor.portalName}`}
          href={env.URL_PORTAL}
          target="__blank"
        >
          {emissor.portalName}
        </a>
        !
      </p>
    </PortalAccessMessageContainer>
  );
}

const PortalAccessMessageContainer = styled('div', {
  backgroundColor: 'transparent',

  '@deviceMd': {
    width: '100%',
  },

  p: {
    fontSize: '0.75rem',
    fontWeight: '$medium',
    lineHeight: '1.5',
    textAlign: 'center',
  },

  a: {
    color: '$primaryPure',
    fontWeight: '$bold',
    textDecoration: 'underline',
    transition: 'color 0.4s',

    '&:hover': {
      color: '$primaryDark',
    },
  },
});
