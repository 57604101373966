/* istanbul ignore file */

import React from 'react';

import { Grid, Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { StyledSwiper } from '@elements/styled-swiper';

import { DefaultTokenVariantsType } from '@extra-types/token-variants-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

const SliderContainer = styled('div', {
  overflowX: 'hidden',
  width: '100%',

  '> div.swiper': {
    position: 'initial',
  },

  '.swiper-pagination': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,

    height: '2rem',
    width: '100%',
  },

  '.swiper-pagination-bullet': {
    display: 'block',

    height: convertPixelToRem(10),
    width: convertPixelToRem(10),
    border: 'none',
    borderRadius: '6px',
    opacity: '0.3',
    cursor: 'pointer',
    transition: '.4s ease',

    fontSize: '0',

    '&.swiper-pagination-bullet-active': {
      opacity: 1,
    },
  },

  variants: {
    variant: {
      primary: {
        '.swiper-pagination-bullet': {
          backgroundColor: '$fontColorPrimary',

          '&.swiper-pagination-bullet-active': {
            backgroundColor: '$primaryPure',
          },
        },
      },
      secondary: {
        '.swiper-pagination-bullet': {
          backgroundColor: '$white',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

interface SliderProps {
  showSlider: boolean;
  children: React.ReactNode;
  itensPerColumn?: number;
  breakpoints?: {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  };
  variant?: DefaultTokenVariantsType;
  customArrowDistance?: string;
  navigation?: boolean;
  loop?: boolean;
}

export function SliderSwiper({
  children,
  showSlider,
  breakpoints,
  itensPerColumn = 1,
  variant,
  customArrowDistance = '1.5rem',
  navigation = true,
  loop,
}: SliderProps) {
  if (showSlider) {
    return (
      <SliderContainer variant={variant}>
        <StyledSwiper
          modules={[Navigation, Pagination, Grid]}
          navigation={navigation}
          grabCursor
          preventInteractionOnTransition
          allowTouchMove
          spaceBetween={8}
          slidesPerView={1.1}
          loop={loop}
          grid={{
            fill: 'row',
            rows: itensPerColumn,
          }}
          breakpoints={breakpoints}
          css={{
            $$customArrowDistance: customArrowDistance,
          }}
        >
          {children && (
            <>
              {React.Children.map(children, child => (
                <SwiperSlide>{child}</SwiperSlide>
              ))}
            </>
          )}
        </StyledSwiper>
      </SliderContainer>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
