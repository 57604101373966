import { HTMLAttributes } from 'react';

import { ModalityType } from '@extra-types/modality-type';
import { getServiceData } from '@helpers/service-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

interface ComboServiceDisabledFormProps extends HTMLAttributes<HTMLDivElement> {
  modalityData?: ModalityType;
  isSVA?: boolean;
}

export function ComboServiceDisabledForm({
  modalityData,
  isSVA = false,
  ...props
}: ComboServiceDisabledFormProps) {
  if (!isSVA && modalityData) {
    const serviceData = getServiceData(modalityData.serviceGroupId);
    return (
      <ComboServiceDisabledFormStyled {...props}>
        <h2>{serviceData.title}</h2>
      </ComboServiceDisabledFormStyled>
    );
  }

  return (
    <ComboServiceDisabledFormStyled {...props}>
      <h2>Serviços Adicionais</h2>
    </ComboServiceDisabledFormStyled>
  );
}

const ComboServiceDisabledFormStyled = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '$silverLight',
  borderRadius: convertPixelToRem(6),
  overflow: 'hidden',

  padding: '0.5rem 1rem',

  h2: {
    color: '$black',
    fontSize: convertPixelToRem(14),
    lineHeight: 2.5,
  },
});
