import { HTMLAttributes } from 'react';

import { Navigation } from 'swiper';
import { Swiper } from 'swiper/react';

import CardImage from '@elements/card-image';

import { CardSlide, CardSliderContainer, SlideButtonStyled } from './styles';

interface CardSliderProps {
  fantasyName: string;
  cardList: number[];
}

interface SlideButtonProps extends HTMLAttributes<HTMLButtonElement> {
  position: 'right' | 'left';
  isHidden: boolean;
}

function SlideButton({ position, isHidden, ...props }: SlideButtonProps) {
  return (
    <SlideButtonStyled
      type="button"
      hidden={isHidden}
      position={position}
      {...props}
    />
  );
}

function CardSlider({ fantasyName, cardList }: CardSliderProps) {
  return (
    <CardSliderContainer>
      <SlideButton
        className="card-slider-button-prev"
        position="left"
        data-testid="arrow-left"
        isHidden={cardList.length === 1}
      />
      <Swiper
        modules={[Navigation]}
        spaceBetween={10}
        slidesPerView={1}
        allowTouchMove
        loop={cardList.length > 1}
        preventInteractionOnTransition
        navigation={{
          nextEl: '.card-slider-button-next',
          prevEl: '.card-slider-button-prev',
        }}
        breakpoints={{
          651: {
            allowTouchMove: false,
          },
        }}
      >
        {cardList.map(cardID => (
          <CardSlide key={cardID}>
            <CardImage fantasyName={fantasyName} cardId={cardID} />
          </CardSlide>
        ))}
      </Swiper>
      <SlideButton
        className="card-slider-button-next"
        position="right"
        data-testid="arrow-right"
        isHidden={cardList.length === 1}
      />
    </CardSliderContainer>
  );
}

export default CardSlider;
