/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ProductImage } from '@elements/product-image';

import { ProductType } from '@extra-types/product-type';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { handleModalContent } from '@store/slices/modal-slice';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

type SizeVariant = 'small' | 'default';

type ProductInformationProps = {
  productData: ProductType;
  size?: SizeVariant;
  price?: () => Promise<void>;
};

export function ProductInformation({
  productData,
  size = 'default',
  price,
}: ProductInformationProps) {
  const { isCartLoading } = useSelector(({ cart }) => cart);
  const { isLoading } = useSelector(({ customer }) => customer);
  const { selectedOffer } = OfferFacade();
  const dispatch = useDispatch();

  return (
    <ProductInformationContainer
      css={{
        $$productColor: `$colors$${productData.colorPrefixToken}PrimaryPure`,
      }}
      size={size}
      disabled={isCartLoading || isLoading}
      className="product-information"
    >
      <div className="product-information__product-image">
        <ProductImage productId={productData.id} />
      </div>
      <span>
        {productData.name}
        <br />
        {!selectedOffer && price && (
          <p
            onClick={() => {
              dispatch(
                handleModalContent({
                  state: true,
                  title: 'Selecione seu plano',
                }),
              );
              price();
            }}
          >
            alterar plano
          </p>
        )}
      </span>
    </ProductInformationContainer>
  );
}

const ProductInformationContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  '.product-information__product-image': {
    position: 'relative',

    img: {
      maxWidth: '64px',
      objectFit: 'contain',
    },
  },

  span: {
    color: '$$productColor',
    fontSize: '1.5rem',
    fontWeight: '$bold',
    whiteSpace: 'nowrap',

    p: {
      color: '$primaryPure',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },

  '> div': {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },

  '@deviceMd': {
    '.product-information__product-image': {
      img: {
        maxWidth: '56px',
      },
    },
    span: {
      fontSize: '1rem',
    },
  },

  '@deviceSm': {
    '.product-information__product-image': {
      img: {
        maxWidth: '50px',
      },
    },
    span: {
      fontSize: '0.75rem',
    },
  },

  variants: {
    size: {
      small: {
        span: {
          fontSize: '0.75rem',
        },
      },
      default: {
        span: { fontSize: '1.5rem' },
      },
    },
    disabled: {
      true: {
        span: {
          opacity: 0.5,
          pointerEvents: 'none',
          cursor: 'not-allowed',
        },
      },
    },
  },
});
