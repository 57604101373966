import { getEmissor } from '@helpers/emissor-helpers';
import { convertPixelToRem, hexToRGB } from '@helpers/style-helpers';

import { keyframes, styled, themes } from '@src/themes';

export const SVARowContainer = styled('tr', {
  borderTop: '1px solid $silverPure',

  backgroundColor: hexToRGB(
    themes[getEmissor().initials].colors.primaryPure,
    0.08,
  ),

  '.sva-row-container': {
    display: 'flex',
    justifyContent: 'space-between',

    background: '$primaryPure',
    borderRadius: convertPixelToRem(6),
    margin: '10px',
    transition: 'background-color 0.4s ease-in-out',

    '&:hover': {
      background: '$primaryDark',
    },
  },

  '.sva-row-container__left-column': {
    width: '100%',
    padding: '10px 0 10px 10px',
    marginRight: '1rem',

    '.left-column__content-container': {
      display: 'flex',
      alignItems: 'center',

      '.content-container__text-content': {
        width: '100%',

        fontWeight: '500',
        fontSize: convertPixelToRem(14),

        'p + p': {
          marginTop: '0.5rem',
        },

        '.text-content__information-title': {
          fontWeight: '$semibold',
          color: 'white',

          '> i': {
            marginLeft: '0.5rem',

            fontSize: '1rem',
            transition: 'transform 0.4s ease',
          },

          '> div': {
            marginBottom: '0.5rem',
          },
        },

        '.text-content__information-subtitle': {
          color: '$secondaryPure',
        },

        '.text-content__information-title, .text-content__information-subtitle':
          {
            fontSize: '1rem',
          },
      },
    },

    '@deviceSm': {
      padding: '10px 0 10px 10px',

      '.left-column__content-container': {
        '> i': {
          fontSize: '0.75rem',
        },

        '.content-container__text-content': {
          fontSize: '0.75rem',
          lineHeight: '1.5',

          '.text-content__information-title, .text-content__information-subtitle':
            {
              fontSize: '0.75rem',
            },

          '.text-content__information-subtitle': {
            marginTop: '0.25rem',
          },
        },
      },
    },
  },

  '.sva-row-container__right-column': {
    position: 'relative',
    display: 'flex',
    padding: '10px 10px 10px 0',

    '.right-column__content-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',

      '> p': {
        color: 'white',
        fontWeight: '600',
        whiteSpace: 'nowrap',

        '&.content-container__offer-text': {
          fontSize: '1rem',
          textAlign: 'end',
        },

        '@deviceSm': {
          whiteSpace: 'normal',

          '&.content-container__offer-text': {
            fontSize: '0.75rem',
          },
        },
      },
    },
  },

  '.sva-row-description': {
    height: 'auto',
    maxHeight: '0',
    opacity: '0',
    overflow: 'hidden',
    transition: 'max-height 0.6s ease-in-out, opacity 0.4s ease-in-out',

    '.sva-row-description__text-content': {
      paddingTop: '1rem',
      paddingRight: '1.5rem',
      paddingLeft: '1.5rem',
    },

    '.sva-row-description__button-section': {
      display: 'flex',
      justifyContent: 'center',
      padding: '2rem 1.5rem',
    },
  },

  '&[aria-disabled=true]': {
    opacity: '0.2',
    cursor: 'not-allowed',

    '@deviceSm': {
      '.sva-row__right-column': {
        '&::before': {
          content: 'unset',
        },
      },
    },
  },

  variants: {
    collapse: {
      false: {
        '.sva-row-container__left-column': {
          '.left-column__content-container': {
            '.content-container__text-content': {
              '.text-content__information-title': {
                '> i': {
                  transform: 'rotate(-180deg)',
                },
              },
            },
          },
        },

        '.sva-row-description': {
          maxHeight: '1000px',
          opacity: 1,
        },

        background: '$white',
        '.sva-row-container__right-column': {
          '&::before': {
            backgroundColor: '$white',
          },
        },
      },
    },
    cursorPointer: {
      true: {
        '.sva-row-container': {
          cursor: 'pointer',
        },
      },
    },
  },
  defaultVariants: {
    collapse: true,
    cursorPointer: false,
  },
});

const loadingAnimation = keyframes({
  from: {
    opacity: '0.15',
  },
  to: {
    opacity: '0.5',
  },
});

export const LoadingLine = styled('div', {
  position: 'relative',

  height: '1.25rem',
  width: '$$width',
  backgroundColor: '#ddd',
  borderRadius: '10px',
  animation: `${loadingAnimation} 0.8s infinite alternate`,

  '@deviceSm': {
    height: '0.75rem',
  },
});
