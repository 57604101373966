import { nanoid } from '@reduxjs/toolkit';

import { SliderSwiper } from '@components/slider-swiper';
import { BenefitItem } from '@elements/old-benefit-item';
import { PageSection } from '@elements/page-section';
import { Title } from '@elements/title';

import { SectionDefaultPropsType } from '@extra-types/section-props-type';
import { createItensGroupByItemsAmount } from '@helpers/section-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useInformationSection } from '@hooks/use-information-section';

import { styled } from '@src/themes';

export function WalletBenefitsSection({ name }: SectionDefaultPropsType) {
  const {
    sectionInformation,
    isMobile,
    checkSectionItemsEmpty,
    sectionVariant,
  } = useInformationSection({
    name,
    sectionVariant: 'primary',
    observeMobileResponsive: true,
  });

  if (checkSectionItemsEmpty || !sectionInformation) {
    return null;
  }

  return (
    <PageSection
      variant="tertiary"
      dark
      css={{
        padding: '2rem 0',
      }}
    >
      <Title.Root barColor="multi" fontColorVariant="walletFontColor">
        <Title.Default tag="h2">Vantagens do VR Multi</Title.Default>
        <Title.Subtitle>
          Listamos algumas vantagens do VR Multi para você conhecer mais sobre o
          produto.
        </Title.Subtitle>
      </Title.Root>
      <WalletBenefitsSectionContainer>
        <SliderSwiper
          showSlider={isMobile}
          variant={sectionVariant}
          breakpoints={{
            0: {
              spaceBetween: 24,
            },
          }}
        >
          {createItensGroupByItemsAmount(sectionInformation.itens, 2).map(
            groupItemSection => (
              <div
                key={nanoid()}
                className="multi-benefits-section-container__column"
              >
                {groupItemSection.map(itemSection => (
                  <BenefitItem
                    key={itemSection.id}
                    icon={itemSection.nomeIcone}
                    title={itemSection.titulo}
                    guidance="row"
                    variant="primary"
                    widthSize="lg"
                    productVariant="multi"
                  >
                    {itemSection.conteudo}
                  </BenefitItem>
                ))}
              </div>
            ),
          )}
        </SliderSwiper>
      </WalletBenefitsSectionContainer>
    </PageSection>
  );
}

const WalletBenefitsSectionContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: convertPixelToRem(72),
  padding: '1.5rem 0',

  '@deviceMd': {
    gap: '2rem',
    flexDirection: 'column',
  },

  '.multi-benefits-section-container__column': {
    display: 'flex !important',
    flexDirection: 'column',
    flex: '1',
    gap: '2rem',

    outline: 'none',

    '> article:not(:last-child)': {
      minHeight: '130px',
    },

    '@deviceLg': {
      '> article:not(:last-child)': {
        minHeight: '160px',
      },
    },

    '@deviceMd': {
      '> article:not(:last-child)': {
        minHeight: 'unset',
      },
    },

    '@deviceSm': {
      '> article:not(:last-child)': {
        minHeight: '100px',
      },
    },
  },
});
