import { styled } from '@src/themes';

export const CartPageHolder = styled('div', {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'auto 20rem',
  gap: '2rem',
  alignItems: 'flex-start',

  padding: '2rem 0',

  hgroup: {
    marginBottom: '1.5rem',
  },

  '.cart-content-area__main-content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },

  '@media only screen and (max-width: 1114px)': {
    gridTemplateColumns: 'auto',
  },

  '@deviceSm': {
    padding: '2rem 0',
  },
});

export const CartContentArea = styled('section', {});
