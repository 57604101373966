import { recoveryCookie } from '@vr/frontend-devkit/cookies';
import axios, { AxiosInstance as AxiosInstanceOrigin } from 'axios';

import { getGlobalThisVrAuth } from '@helpers/authentication-helpers';

import HttpService from '../http-service';
import { cache, Cache, AxiosInstance } from './cache';
import { AUTH_ERR_TOKEN_INVALID, ConfigProps } from './client';
import {
  servicesControlAccessToken,
  sessionKeys,
} from './service-control-access-token';

export type { AxiosInstance };

export function config(data: ConfigProps): AxiosInstance {
  const { clientId } = data || ({} as ConfigProps);

  const vrAuth = getGlobalThisVrAuth();

  if (vrAuth.isSolved) {
    vrAuth.isSolved = false;
    vrAuth.promise = servicesControlAccessToken
      .generateAccessToken({
        clientId,
      })
      .catch(error => {
        throw error;
      });
  }

  const instance = axios.create({
    baseURL: data?.baseUrl,
    timeout: data?.timeout,
    headers: {
      ...data?.headers,
    },
  });

  instance.interceptors.request.use(
    async req => {
      await vrAuth.promise;

      if (!recoveryCookie(sessionKeys.X_AUTHORIZATION)) {
        await servicesControlAccessToken.refreshAccessToken();
      }

      const accessToken = recoveryCookie(sessionKeys.X_AUTHORIZATION);

      return {
        ...req,
        headers: {
          ...req.headers,
          access_token: accessToken,
          client_id: clientId,
        } as { [key: string]: string },
      };
    },
    error => HttpService.intercept(error),
  );

  instance.interceptors.response.use(
    response => response,
    error => {
      if (typeof error === 'object' && error.response) {
        const newError = {
          ...error,
          status: error.response.status,
        };

        if (error.config['axios-retry']?.retryCount === 1) {
          newError.code = AUTH_ERR_TOKEN_INVALID;
        }

        return HttpService.intercept(newError, instance);
      }

      return HttpService.intercept(error, instance);
    },
  );

  (instance as AxiosInstance).cache = (chave: Cache): AxiosInstanceOrigin => {
    return cache(instance, chave);
  };

  return instance as AxiosInstance;
}
