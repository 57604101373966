/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect } from 'react';

import clearNotNumber from '@vr/devkit/utils/clearNotNumber';

import { FAQContactModalButton } from '@components/faq-contact-modal-button';
import { LoadingViewMessage } from '@elements/loading-view-message';

import { sendEventToAnalytics } from '@helpers/analytics-helpers';
import { TrackCategories } from '@helpers/enum/track-categories';

import { useContact } from '@hooks/use-contact';
import { useContactModalContext } from '@hooks/use-contact-modal-context';
import { useSelector } from '@hooks/use-selector';

import { HelpContainer } from './styles';

function ContactInformationContent() {
  const { handleContactModal } = useContactModalContext();
  const { channelContact, isChatEnable, toggleChatModal } = useContact();
  const isLoading = useSelector(({ application }) => application.isLoading);

  if (isLoading) {
    return <LoadingViewMessage verticalPadding />;
  }

  return (
    <>
      {isChatEnable && (
        <div
          className="content__info"
          onClick={() => {
            toggleChatModal();
            handleContactModal();
          }}
        >
          <i className="fa-light fa-comment" />
          <div className="info__holder">
            <div className="info__title">Chat</div>
            <div className="info__subtitle">Fale via chat conosco</div>
          </div>
        </div>
      )}

      {channelContact.emailList.length > 0 &&
        channelContact.emailList.map(email => (
          <a
            href={`mailto:${email.contactInformation}`}
            className="content__info"
            key={email.contactInformation}
          >
            <i className={email.contactIcon} />
            <div className="info__holder">
              <div className="info__title">{email.contactInformation}</div>
              <div className="info__subtitle">{email.contactSubtitle}</div>
            </div>
          </a>
        ))}
      {channelContact.phoneList.length > 0 &&
        channelContact.phoneList.map(phone => (
          <a
            href={`tel:+55${clearNotNumber(phone.contactInformation)}`}
            className="content__info"
            key={phone.contactInformation}
          >
            <i className={phone.contactIcon} />
            <div className="info__holder">
              <div className="info__title">{phone.contactInformation}</div>
              <div className="info__subtitle">{phone.contactSubtitle}</div>
            </div>
          </a>
        ))}
      <FAQContactModalButton />
    </>
  );
}

function ContactModal() {
  const { handleContactModal, contactModalState } = useContactModalContext();
  const { channelContact } = useContact();

  useEffect(() => {
    if (contactModalState) {
      sendEventToAnalytics(
        TrackCategories.SUPORTE,
        'Abertura da Janela de Contato',
        'Cliente procurou suporte',
      );
    }
  }, [contactModalState]);

  if (channelContact.phoneList.length > 1) {
    return (
      <HelpContainer modalState={contactModalState} id="modal-contato-telefone">
        <div className="help__holder">
          <div className="help__header">
            <button
              id="fechar-atendimento"
              type="button"
              onClick={handleContactModal}
            >
              <i className="fa-solid fa-xmark" />{' '}
            </button>
            <h2>Central de suporte</h2>
          </div>
          <div className="help__content">
            <ContactInformationContent />
          </div>
        </div>
      </HelpContainer>
    );
  }

  return (
    <HelpContainer modalState={contactModalState} id="modal-contato-telefone">
      <div className="help__holder">
        <div className="help__header">
          <button
            id="fechar-atendimento"
            type="button"
            onClick={handleContactModal}
          >
            <i className="fa-solid fa-xmark" />{' '}
          </button>
          <div className="help-title">
            <h2>Central de suporte</h2>
            <p>
              Nossa equipe está à disposição para suporte e tirar suas dúvidas.
            </p>
          </div>
        </div>
        <div className="help__content">
          <ContactInformationContent />
        </div>
      </div>
    </HelpContainer>
  );
}

export default ContactModal;
