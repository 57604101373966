import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const ProductItemContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: `3.5rem 1fr ${convertPixelToRem(
    150,
  )} ${convertPixelToRem(132)}`,
  gridTemplateAreas: `'switch title value total-value'`,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.25rem',

  background: '$white',
  width: '100%',
  padding: '0.5rem 0',

  fontSize: convertPixelToRem(14),

  '& + &': {
    borderTop: '1px solid $silverLight',
  },

  '&.offer-container': {
    gridTemplateAreas: `'switch offer offer offer'`,
    minHeight: convertPixelToRem(106),
  },

  '@deviceMd': {
    gridTemplateColumns: `3rem 1fr ${convertPixelToRem(150)}`,
    gridTemplateAreas: `
      'switch title value'
    `,
    gap: '1rem',

    padding: '1rem',

    '&.offer-container': {
      gridTemplateAreas: `'switch offer offer'`,
    },
  },

  '@media only screen and (max-width: 440px)': {
    gridTemplateColumns: `3rem 1fr`,
    gridTemplateAreas: `
      'switch title'
      'switch value'
    `,

    padding: '1rem',
  },

  '> div': {
    textAlign: 'center',

    '&:nth-child(2)': {
      textAlign: 'start',
    },
  },

  '.product-item__switch': {
    gridArea: 'switch',

    '@media only screen and (max-width: 440px)': {
      alignSelf: 'flex-start',
    },

    '&--unselected': {
      alignSelf: 'unset',
    },
  },

  '.product-item__title': {
    gridArea: 'title',
    display: 'flex',
    fontWeight: '$bold',
    alignItems: 'center',

    img: {
      height: convertPixelToRem(42),
      marginRight: '1rem',

      '@deviceSm': {
        display: 'none',
      },
    },

    '@deviceMd': {
      marginRight: '0.75rem',
    },
  },

  '.product-item__offer-text': {
    gridArea: 'offer',
    display: 'flex',
    alignItems: 'center',

    paddingRight: '0.5rem',

    '@deviceSm': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '0.25rem',
    },

    img: {
      height: convertPixelToRem(42),
      marginRight: '1rem',

      '@deviceSm': {
        display: 'none',
      },
    },

    '.offer-text__product-information': {
      '.product-information__product-name': {
        fontWeight: '$bold',

        '@deviceSm': {
          display: 'block',
        },
      },

      '.product-information__text': {
        marginTop: '0.25rem',
        lineHeight: '1.5',
      },
    },
  },

  '.product-item__product-value': {
    gridArea: 'value',
    display: 'flex',
    justifyContent: 'flex-end',

    label: {
      display: 'none',

      '@deviceMd': {
        display: 'block',
      },
    },

    '@media only screen and (max-width: 440px)': {
      '> div': {
        width: '100%',
      },
    },
  },

  '.product-item__total-value': {
    position: 'relative',
    gridArea: 'total-value',
    color: '$primaryPure',
    fontWeight: '$semibold',

    '.total-value__tip': {
      zIndex: '$1',
      position: 'absolute',
      top: '-1.625rem',
      display: 'none',
      left: '50%',
      transform: 'translate(-50%, 0)',

      background: '$silverDark',
      padding: '0.125rem 0.5rem',
      borderRadius: '$xs',

      color: '$white',
      fontSize: '0.75rem',
      whiteSpace: 'nowrap',

      '&:before': {
        content: '',
        position: 'absolute',
        bottom: '-25%',
        left: '50%',
        transform: 'translate(-50%, 0) rotate(45deg)',

        background: '$silverDark',
        height: '0.5rem',
        width: '0.5rem',
      },
    },

    '&:hover': {
      '.total-value__tip': {
        display: 'block',
      },
    },

    '@deviceMd': {
      display: 'none',

      textAlign: 'end',
    },
  },

  variants: {
    disabled: {
      true: {
        gridTemplateColumns: `3.5rem 1fr ${convertPixelToRem(
          150,
        )} ${convertPixelToRem(132)}`,
        gridTemplateAreas: `'title value total-value'`,

        opacity: 0.5,
        pointerEvents: 'none',
        filter: 'grayscale(0.7)',
      },
    },
  },
});
