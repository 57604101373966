import { CustomerModalCompanyMessage } from '@components/customer-modal-company-message';
import { Button } from '@elements/button';

import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';

import { ScenariosContainer } from './styles';

function BlockList() {
  return (
    <ScenariosContainer>
      <img
        src={`${
          env.URL_ASSETS + ASSETS_OTHERS_PATH
        }/block-list-illustration.svg`}
        alt="Ilustração de um cesto e um carrinho de compras."
      />
      <div className="separator" />
      <CustomerModalCompanyMessage
        blocked
        customMessage="Infelizmente não conseguimos adicionar produtos ao seu contrato atual por aqui."
      />

      <p className="block-list-text">
        Clique no botão abaixo que te encaminharemos para um especialista te
        atender da melhor forma:
      </p>

      <Button.Anchor
        id="btn-modal-cliente-block-list"
        href="https://cloud.relacionamento.vr.com.br/lojavreco?utm_source=site&utm_medium=lojavr&utm_campaign=ofertaseco"
        variant="primary"
        target="_blank"
      >
        Continuar
      </Button.Anchor>
    </ScenariosContainer>
  );
}

export default BlockList;
