import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { CartModalContentView } from '@components/cart-modal-components/cart-modal-content-view';
import { CartModalFooter } from '@components/cart-modal-components/cart-modal-footer';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { toggleCartModalState } from '@store/slices/cart-slice';

import { CartContainer, CartContainerHeader } from './styles';

export function CartModal() {
  const nodeRef = useRef(null);
  const cartData = useSelector(({ cart }) => cart);
  const dispatch = useDispatch();

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={cartData.cartModalState}
      classNames="cart-content"
      timeout={400}
      unmountOnExit
    >
      <CartContainer ref={nodeRef}>
        <CartContainerHeader>
          <p>Meu carrinho</p>
          <button
            type="button"
            onClick={() => dispatch(toggleCartModalState())}
          >
            <i className="fa-solid fa-xmark" />
          </button>
        </CartContainerHeader>
        <CartModalContentView />
        <CartModalFooter />
      </CartContainer>
    </CSSTransition>
  );
}
