import { env } from '@config/env';
import { ProductAddonType } from '@extra-types/product-addon-type';

import { styled } from '@src/themes';

interface ProductProductAddonItemProps {
  data: ProductAddonType;
}

export function ProductAddonItem({ data }: ProductProductAddonItemProps) {
  return (
    <ProductAddonItemContainer>
      <div className="addon-item-container__information-holder">
        <img
          src={env.URL_ASSETS + data.logoPath}
          alt={`Logotipo do adicional ${data.name}`}
        />
        <div className="information-holder__text-group">
          <p className="title">{data.name}</p>
          <p>{data.description}</p>
        </div>
      </div>
      {data.value && data.label && (
        <div className="addon-item-container__value-holder">
          <span>{`${data.value} ${data.label}`}</span>
        </div>
      )}
    </ProductAddonItemContainer>
  );
}

const ProductAddonItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',

  width: '100%',

  '.addon-item-container__information-holder': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    img: {
      flexShrink: 1,

      width: '36px',
      objectFit: 'contain',

      '@deviceSm': {
        width: '46px',
      },
    },

    '.information-holder__text-group': {
      p: {
        fontSize: '0.75rem',
        fontWeight: '$medium',
        lineHeight: 1.4,

        '&.title': {
          fontWeight: '$bold',
          marginBottom: '0.25rem',
        },
      },
    },
  },

  '.addon-item-container__value-holder': {
    background: '$silverPure',
    padding: '0.5rem 0.75rem',
    borderRadius: '0.5rem',

    fontSize: '0.75rem',
    fontWeight: '$semibold',
    whiteSpace: 'nowrap',
    lineHeight: 1.8,

    '@deviceSm': {
      marginLeft: '62px',
    },
  },

  '@deviceSm': {
    flexDirection: 'column',
    alignItems: 'start',
    gap: '0.5rem',
  },
});
