import { CustomerModalCompanyMessage } from '@components/customer-modal-company-message';
import { Button } from '@elements/button';

import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { redirectToAnotherPage } from '@helpers/page-helpers';
import { replaceValueOfSpecificQueryParam } from '@helpers/query-string-helpers';

import { useSelector } from '@hooks/use-selector';

import { ScenariosContainer } from './styles';

function AnotherChannelCustomer() {
  const { channelDescription } = useSelector(({ customer }) => customer);

  const redirectToCorrectChannel = () => {
    return redirectToAnotherPage(
      replaceValueOfSpecificQueryParam({
        key: 'canal',
        value: channelDescription.acronym,
        location: { ...window.location, pathname: '/' },
        removeKey: 'offerid',
      }),
    );
  };

  return (
    <ScenariosContainer>
      <img
        src={`${
          env.URL_ASSETS + ASSETS_OTHERS_PATH
        }/another-channel-illustration.svg`}
        alt="Ilustração de prédios verde e amarelo representando as cores da VR."
      />
      <div className="separator" />
      <CustomerModalCompanyMessage
        blocked
        customMessage="Seu carrinho será esvaziado."
      >
        <p className="another-channel-text">
          Para seguir com a contratação será necessário te direcionar para a
          página que você contratou seus produtos anteriormente.
        </p>
      </CustomerModalCompanyMessage>

      <Button.Default
        id="btn-modal-novo-fluxo"
        type="button"
        variant="primary"
        onClick={redirectToCorrectChannel}
      >
        Me leve para a página
      </Button.Default>
    </ScenariosContainer>
  );
}

export default AnotherChannelCustomer;
