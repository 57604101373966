import { ServiceGroupId } from '@helpers/enum/service-group-id';

import { useSelector } from '@hooks/use-selector';

export const OfferFacade = () => {
  const { selectedOffer, activeOffers, isLoading, offerDetails } = useSelector(
    ({ offers }) => offers,
  );

  const verifyifProductIsInOffer = (productId: number) => {
    return offerDetails.oferta.itemsOferta.some(offer => {
      return offer.codigoProduto === productId;
    });
  };

  const getSvasFromOffer = () => {
    if (selectedOffer) {
      return offerDetails.oferta.itemsOferta.filter(offer => {
        return offer.tipoProduto.includes(ServiceGroupId.SVA);
      });
    }
    return [];
  };

  return {
    selectedOffer,
    activeOffers,
    isLoading,
    offerDetails,
    verifyifProductIsInOffer,
    getSvasFromOffer,
  };
};
