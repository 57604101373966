import { ProductPricing } from '@extra-types/product-pricing';

import { ApiRequestAuthData } from '@api/models/request/auth-data';
import PricingResponse from '@api/models/response/pricing-response';
import SearchChannelDescription from '@api/models/response/search-channel-description';
import SearchCompanyResponse from '@api/models/response/search-company-response';

import getBaseURLApi from './authentication/get-base-url-api';

class ClienteRhService {
  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = 'clientes-rh';
  }

  public async searchCompany(
    cnpjRh: string,
    { siglaCanal, siglaEmissor, siglaSubCanal }: ApiRequestAuthData,
  ): Promise<SearchCompanyResponse> {
    const url = `/${this.baseUrl}/${cnpjRh}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
    });

    return data;
  }

  public async searchCompanyChannel(
    cnpjRh: string,
    { siglaEmissor, siglaSubCanal }: Omit<ApiRequestAuthData, 'siglaCanal'>,
  ): Promise<SearchChannelDescription> {
    const url = `/${this.baseUrl}/${cnpjRh}/canal`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'id-subCanal': siglaSubCanal ?? '',
      },
    });

    return data;
  }

  public async pricing(
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
    idLead: string,
    body: ProductPricing[],
    identificacaoOferta?: string,
  ): Promise<PricingResponse> {
    const url = `/${this.baseUrl}/${idLead}/pedidos/precificacoes`;

    let headers: {
      'sigla-emissor': string;
      'sigla-canal': string;
      'id-subCanal': string;
      'identificacao-oferta'?: string;
    } = {
      'sigla-emissor': siglaEmissor,
      'sigla-canal': siglaCanal,
      'id-subCanal': siglaSubCanal ?? '',
    };

    if (identificacaoOferta) {
      headers = {
        ...headers,
        'identificacao-oferta': identificacaoOferta,
      };
    }

    const { data } = await getBaseURLApi(url, {
      headers,
      data: body,
      method: 'POST',
    });

    return data;
  }

  public async pricingWithoutLead(
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
    body: ProductPricing[],
    identificacaoOferta?: string,
  ): Promise<PricingResponse> {
    const url = `/${this.baseUrl}/pedidos/precificacoes`;

    let headers: {
      'sigla-emissor': string;
      'sigla-canal': string;
      'id-subCanal': string;
      'identificacao-oferta'?: string;
    } = {
      'sigla-emissor': siglaEmissor,
      'sigla-canal': siglaCanal,
      'id-subCanal': siglaSubCanal ?? '',
    };

    if (identificacaoOferta) {
      headers = {
        ...headers,
        'identificacao-oferta': identificacaoOferta,
      };
    }

    const { data } = await getBaseURLApi(url, {
      headers,
      data: body,
      method: 'POST',
    });

    return data;
  }
}
export default new ClienteRhService();
