import axios from 'axios';

import { env } from '@config/env';
import { getSensediaAuth } from '@helpers/authentication-helpers';

export interface AccessToken {
  clientId: string;
  access_token: string;
  refresh_token: string;
  token_type: 'access_token';
  expires_in: number;
}

interface AccessTokenResponse {
  redirect_uri: string;
}

interface GetAccessToken {
  clientId: string;
}

export const serviceAccessToken = {
  async getAccessToken({ clientId }: GetAccessToken) {
    const validationToken = await axios.post<AccessTokenResponse>(
      `${env.URL_API_SENSEDIA}/oauth/grant-code`,
      {
        client_id: clientId,
        redirect_uri: 'http://localhost/',
      },
    );

    const accessCode = validationToken.data.redirect_uri.split('code=')[1];

    const { data } = await axios.post<AccessToken>(
      `${env.URL_API_SENSEDIA}/oauth/access-token`,
      {
        grant_type: 'authorization_code',
        code: accessCode,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${getSensediaAuth().basic}`,
        },
      },
    );

    return data;
  },
};
