import { SECTIONS_COMPONENTS } from '@config/section-config';
import { DisplayOrderType } from '@extra-types/display-order-type';
import { SectionType } from '@extra-types/section-types';

export function createItensGroupByItemsAmount<T>(
  itemList: Array<T>,
  lengthPerGroup: number,
) {
  return itemList.reduce((result, value, index, array) => {
    if (index % lengthPerGroup === 0)
      result.push(array.slice(index, index + lengthPerGroup));
    return result;
  }, [] as Array<Array<T>>);
}

export function createItensGroupByGroupAmount<T>(
  itemList: Array<T>,
  groupLength: number,
) {
  const groupAmount = Math.ceil(itemList.length / groupLength);

  return createItensGroupByItemsAmount(itemList, groupAmount);
}

export function sortListByDisplayOrder<T extends DisplayOrderType>(
  list: Array<T>,
): Array<T> {
  function compare(a: T, b: T) {
    return a.ordemExibicao - b.ordemExibicao;
  }

  return list.sort(compare);
}

export function createVerifiedSectionsBySectionType(
  sections: SectionType[],
  type: string,
) {
  return sections
    .slice()
    .filter(
      section =>
        !!SECTIONS_COMPONENTS[section.id] &&
        SECTIONS_COMPONENTS[section.id].type === type,
    );
}
