import { createAsyncThunk } from '@reduxjs/toolkit';

import { MODALITIES } from '@config/modality-config';
import { ModalityType } from '@extra-types/modality-type';
import { ProductType } from '@extra-types/product-type';
import { StringToReplaceList } from '@helpers/enum/string-to-replace-list';
import {
  filterProductListOfUnknownModalities,
  getModalityListByProductList,
} from '@helpers/modality-helpers';
import { convertProductResponseListToProductTypeList } from '@helpers/product-helpers';
import { replaceString } from '@helpers/string-helpers';

import { RootState } from '@hooks/use-selector';

import productService from '@api/services/product-service';
import ApplicationError from '@errors/types/application-error';
import ModalityNotFound from '@errors/types/modality-not-found-error';
import NotCatalogedError from '@errors/types/not-cataloged-error';

interface FetchProductsDataResponse {
  productList: ProductType[];
  modalityList: ModalityType[];
}

export const fetchProductsData = createAsyncThunk<
  FetchProductsDataResponse,
  void,
  { state: RootState }
>('products/fetchProductsDataStatus', async (_, thunkAPI) => {
  const { config } = thunkAPI.getState().application;
  const modalityList: ModalityType[] = [];
  const productList: ProductType[] = [];

  try {
    await productService
      .getProducts({
        siglaCanal: config.siglaCanal,
        siglaEmissor: config.emissor.initials,
        siglaSubCanal: config.siglaSubCanal,
      })
      .then(response => {
        productList.push(
          ...convertProductResponseListToProductTypeList(
            response.produtosDisponiveis,
          ),
        );
      });

    const modalityIdList = getModalityListByProductList(productList);

    modalityIdList.forEach(modalityId => {
      const modalityData = MODALITIES[modalityId];

      if (modalityData) {
        modalityList.push({
          ...modalityData,
          shelfInformation: {
            ...modalityData.shelfInformation,
            title: replaceString(
              modalityData.shelfInformation.title,
              StringToReplaceList.EMISSOR_NAME,
              config.emissor.shortName,
            ),
          },
        });
      }
    });

    if (modalityList.length === 0) {
      throw new ModalityNotFound();
    }
  } catch (error) {
    if (error instanceof ApplicationError)
      return thunkAPI.rejectWithValue(error);
    return thunkAPI.rejectWithValue(new NotCatalogedError());
  }

  return {
    productList: filterProductListOfUnknownModalities(
      productList,
      modalityList,
    ),
    modalityList,
  };
});
