import { Button } from '@elements/button';

import { sendEventToAnalytics } from '@helpers/analytics-helpers';
import { TrackCategories } from '@helpers/enum/track-categories';

import useDefaultHeader from '@hooks/use-default-header';
import { useDispatch } from '@hooks/use-dispatch';
import { useRoutes } from '@hooks/use-routes';
import { useSelector } from '@hooks/use-selector';
import { fetchRegisterCart } from '@store/thunks/customer-thunk';

import leadsService from '@api/services/leads-service';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

export function SummaryActions() {
  const { selectedOffer } = OfferFacade();
  const { companyData } = useSelector(({ customer }) => customer);
  const { defaultHeader } = useDefaultHeader();
  const { nextStep, prevStep } = useRoutes();
  const dispatch = useDispatch();
  const { cartModality, isCartLoading } = useSelector(({ cart }) => cart);
  const { isLoading } = useSelector(({ customer }) => customer);

  const handleRegisterCartAndGoToTheNextPage = async () => {
    sendEventToAnalytics(
      TrackCategories.MODALIDADE,
      'Escolha de Modalidade',
      cartModality?.shelfInformation.title || 'Não conhecida',
    );

    await dispatch(fetchRegisterCart({ offerData: selectedOffer }))
      .unwrap()
      .then(() => {
        leadsService.createCartProposal(defaultHeader, companyData.lead);
        nextStep();
      });
  };

  if (!cartModality) {
    return null;
  }

  return (
    <SummaryActionsContainer>
      <Button.Default
        id="btn-carrinho-seguir-compra"
        onClick={handleRegisterCartAndGoToTheNextPage}
        loading={isLoading}
        disabled={isCartLoading || !!selectedOffer}
      >
        Seguir para a compra
        <i className="fa-solid fa-chevron-right" />
      </Button.Default>
      <Button.Default
        id="btn-carrinho-continuar-comprando"
        onClick={() => prevStep()}
        outline
        disabled={isCartLoading || isLoading}
      >
        <i className="fa-solid fa-chevron-left" />
        Continuar comprando
      </Button.Default>
    </SummaryActionsContainer>
  );
}

const SummaryActionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  width: '100%',

  'button, a': {
    width: '100%',
  },

  '@media only screen and (max-width: 1114px)': {
    padding: '0 1rem 1.5rem',
    flexDirection: 'row-reverse',
    gap: '1rem',
  },

  '@deviceMd': {
    '#btn-carrinho-continuar-comprando': {
      display: 'none',
    },
  },
});
