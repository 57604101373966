import { ErrorViewMessage } from '@elements/error-view-message';
import { LoadingViewMessage } from '@elements/loading-view-message';
import { ComboServiceSummary } from '@pages/offer-page/custom-offer-pages/compositions/combo-service-summary';
import { ComboSummaryTitle } from '@pages/offer-page/custom-offer-pages/elements/combo-cart-summary-elements/combo-summary-title';
import { ComboSummaryTotalValue } from '@pages/offer-page/custom-offer-pages/elements/combo-cart-summary-elements/combo-summary-total-value';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useSelector } from '@hooks/use-selector';

import { OfferFacade } from '@src/facade/offer-facade';

import {
  ComboCartSummaryModalityContainer,
  ComboCartSummaryHolder,
  ComboCartSummaryStaticMessageContainer,
  ComboCartSummaryModalityGroup,
} from './styles';

interface ComboCartSummaryProps {
  isCollapsed: boolean;
}

export function ComboCartSummary({ isCollapsed }: ComboCartSummaryProps) {
  const { selectedOffer } = OfferFacade();
  const { activeSummary, handleActiveSummary, modalitiesForSale } =
    useComboCartPageContext();
  const { isCartLoading } = useSelector(({ cart }) => cart);
  const { isLoading } = useSelector(({ products }) => products);

  if (isLoading || isCartLoading) {
    return (
      <ComboCartSummaryModalityContainer>
        <ComboCartSummaryStaticMessageContainer>
          <LoadingViewMessage message="Aguarde um momento, estamos precificando a sua compra" />
        </ComboCartSummaryStaticMessageContainer>
      </ComboCartSummaryModalityContainer>
    );
  }

  if (!selectedOffer) {
    return (
      <ComboCartSummaryModalityContainer>
        <ComboCartSummaryStaticMessageContainer>
          <ErrorViewMessage />
        </ComboCartSummaryStaticMessageContainer>
      </ComboCartSummaryModalityContainer>
    );
  }

  return (
    <ComboCartSummaryHolder>
      <ComboSummaryTitle />
      <ComboCartSummaryModalityGroup isCollapsed={isCollapsed}>
        {modalitiesForSale.map(modality => (
          <ComboServiceSummary
            key={modality.id}
            modalityData={modality}
            externalActiveState={activeSummary === `${modality.id}`}
            externalHandleActiveState={() =>
              handleActiveSummary({
                id: `${modality.id}`,
                clearStateOnSameId: true,
              })
            }
          />
        ))}
      </ComboCartSummaryModalityGroup>
      <ComboSummaryTotalValue />
    </ComboCartSummaryHolder>
  );
}
