import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class MaximumValueExcessedProductError extends ApplicationError {
  constructor(message?: string) {
    super(
      SpecificsApiErrors.MAXIMUM_VALUE_OF_THE_EXCESSED_PRODUCT,
      message || ApiErrorsMessages.DEFAULT,
    );
  }
}
