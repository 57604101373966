import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Skeleton } from '@components/skeletons';

import { ModalityType } from '@extra-types/modality-type';
import { WalletProductFormType } from '@extra-types/product-form-types';
import { formatWalletProductListToProductCartList } from '@helpers/cart-helpers';
import { getProductListByModality } from '@helpers/product-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addProductToCart,
  clearCart,
  setCartModality,
} from '@store/slices/cart-slice';
import { closeModal, handleModalContent } from '@store/slices/modal-slice';

import { ModalityInformation } from '../../components/modality-information';
import { SelectedWalletProducts } from './selected-wallet-products';
import { WalletFormContainer, HeaderHolder } from './styles';
import { WalletInputs } from './wallet-inputs';

interface WalletFormtProps {
  modalityData: ModalityType;
}

export type WalletFormInputs = {
  products: WalletProductFormType;
  amount: number;
};

export function WalletProductForm({ modalityData }: WalletFormtProps) {
  const [isOnCart, setIsOnCart] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const dispatch = useDispatch();
  const { cartModality, productCartList } = useSelector(({ cart }) => cart);
  const { productList, isLoading: isProductsLoading } = useSelector(
    ({ products }) => products,
  );
  const isApplicationLoading = useSelector(
    ({ application }) => application.isLoading,
  );

  const productListByModality = useMemo(
    () => getProductListByModality(modalityData.id, productList),
    [modalityData.id, productList],
  );

  const hasModalityProductInCart = useMemo(
    () =>
      productCartList.length > 0 &&
      productCartList.some(productCart =>
        productListByModality.some(
          modalityProduct => modalityProduct.id === productCart.id,
        ),
      ),
    [productCartList, productListByModality],
  );

  const methods = useForm<WalletFormInputs>({
    mode: 'onChange',
    defaultValues:
      cartModality &&
      cartModality.id === modalityData.id &&
      productCartList.length > 0
        ? {
            amount: productCartList[0].amount,
            products: productCartList.reduce((defaultValuesList, product) => {
              return {
                ...defaultValuesList,
                [`product-${product.id}`]: {
                  value: product.value.toFixed(2),
                },
              };
            }, {} as WalletProductFormType),
          }
        : undefined,
  });

  const onSubmit: SubmitHandler<WalletFormInputs> = data => {
    const formattedProductsToCart = formatWalletProductListToProductCartList(
      data.amount,
      data.products,
      productList,
    );

    if (cartModality !== undefined && cartModality.id !== modalityData.id) {
      dispatch(
        handleModalContent({
          title: 'Troca de modalidade de cartão',
          content:
            'O cartão/benefício escolhido não pode ser contratado junto com os benefícios que já estão no seu carrinho. Como você deseja continuar?',
          state: true,
          confirmationButton: () => {
            dispatch(closeModal());
          },
          denyButton: () => {
            dispatch(clearCart());
            dispatch(setCartModality(modalityData));
            formattedProductsToCart.forEach(product => {
              dispatch(addProductToCart(product));
            });
            setIsOnCart(true);
            setEnableEdit(false);
            dispatch(closeModal());
            document.documentElement.scrollTo(0, 0);
          },
        }),
      );
    } else {
      dispatch(setCartModality(modalityData));
      formattedProductsToCart.forEach(product => {
        dispatch(addProductToCart(product));
      });
      setIsOnCart(true);
      setEnableEdit(false);
      document.documentElement.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (hasModalityProductInCart) {
      setIsOnCart(true);
    } else {
      setIsOnCart(false);
      setEnableEdit(false);
    }
  }, [hasModalityProductInCart]);

  if (isApplicationLoading || isProductsLoading) {
    return <Skeleton.BigBox variant="secondary" />;
  }

  if (isOnCart && enableEdit === false) {
    return (
      <FormProvider {...methods}>
        <WalletFormContainer>
          <HeaderHolder>
            <ModalityInformation modalityData={modalityData} responsiveColumn />
            <SelectedWalletProducts.Header />
          </HeaderHolder>
          <SelectedWalletProducts.Content setEnableEdit={setEnableEdit} />
        </WalletFormContainer>
      </FormProvider>
    );
  }

  return (
    <FormProvider {...methods}>
      <WalletFormContainer
        id="wallet-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <HeaderHolder>
          <ModalityInformation modalityData={modalityData} responsiveColumn />
          <WalletInputs.Header modalityData={modalityData} />
        </HeaderHolder>

        <WalletInputs.Content
          modalityData={modalityData}
          enableEdit={enableEdit}
        />
      </WalletFormContainer>
    </FormProvider>
  );
}
