import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { CONTRACT_NAME } from '@config/application-config';
import { ApiErrorsModalTitle } from '@helpers/enum/api-error-messages';
import { getMessageError } from '@helpers/error-helpers';
import { downloadPDF } from '@helpers/file-helpers';

import { useContractArrayBuffer } from '@store/context/contract-context';
import { handleWarningModalContent } from '@store/slices/warning-modal-slice';

import HiringService from '@api/services/hiring-service';
import ApplicationError from '@errors/types/application-error';
import ContractDownloadError from '@errors/types/contract-download-error';

import useDefaultHeader from './use-default-header';
import { useSelector } from './use-selector';

export function useContract() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { contractArrayBuffer, setContractArrayBuffer } =
    useContractArrayBuffer();
  const { defaultHeader } = useDefaultHeader();
  const companyData = useSelector(({ customer }) => customer.companyData);

  const getContractAsBlob = async () => {
    setLoading(true);
    try {
      const response = await HiringService.getContract(
        defaultHeader,
        companyData.lead,
      );

      setContractArrayBuffer(response);
      setLoading(false);

      return new Blob([response as unknown as BlobPart], {
        type: 'application/pdf',
      });
    } catch (error) {
      setLoading(false);
      throw new ContractDownloadError();
    }
  };

  const getDownloadContract = async () => {
    if (contractArrayBuffer) {
      downloadPDF(contractArrayBuffer, CONTRACT_NAME);
    } else {
      setLoading(true);
      try {
        const getContractResponse = await HiringService.getContract(
          defaultHeader,
          companyData.lead,
        );
        downloadPDF(getContractResponse, CONTRACT_NAME);
        setLoading(false);
      } catch (error) {
        dispatch(
          handleWarningModalContent({
            isVisible: true,
            title: ApiErrorsModalTitle.DEFAULT,
            content: getMessageError(error),
            closeAction: {
              label: 'Fechar',
              action:
                error instanceof ApplicationError ? 'close' : 'reloadPage',
            },
          }),
        );
        setLoading(false);
      }
    }
  };

  return {
    isLoading,
    getDownloadContract,
    getContractAsBlob,
  };
}
