import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import getPhone from '@vr/devkit/masks/phoneMask';
import clearNotNumber from '@vr/devkit/utils/clearNotNumber';
import cnpjValidate from '@vr/devkit/validators/cnpjValidate';
import Cookies from 'js-cookie';

import { Input } from '@components/input';
import Scenarios from '@compositions/scenarios';
import Loader from '@elements/loader';

import { COOKIES_CLIENT_ID_LABEL } from '@config/application-config';
import { CNPJ_MAX_LENGTH } from '@config/input-config';
import { FormErrors } from '@helpers/enum/form-errors';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { isComboFlux } from '@helpers/flux-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useRoutes } from '@hooks/use-routes';
import { useSelector } from '@hooks/use-selector';
import {
  closeModal,
  handleLeadData,
  handleSetCustomerEmail,
  setCompanyCnpj,
} from '@store/slices/customer-slice';
import { fetchCartPricing } from '@store/thunks/cart-thunk';
import {
  fetchCompanyInformation,
  fetchCustomerLead,
} from '@store/thunks/customer-thunk';

import { OfferFacade } from '@src/facade/offer-facade';

import { CustomerData } from '.';

export default function CnpjForm() {
  const [pathnameModalOpened, setPathnameModalOpened] = useState<
    string | undefined
  >();
  const dispatch = useDispatch();
  const { selectedOffer } = OfferFacade();
  const navigate = useNavigate();
  const { nextStep } = useRoutes();
  const { pathname } = useLocation();
  const methods = useFormContext<CustomerData>();
  const { isLoading: isProductsLoading } = useSelector(
    ({ products }) => products,
  );
  const { state, isLoading, companyData, customerType } = useSelector(
    ({ customer }) => customer,
  );
  const { isCartLoading, cartModality } = useSelector(({ cart }) => cart);
  const { isLoading: isOfferLoading } = useSelector(({ offers }) => offers);
  const isLoadingInput = () => {
    if (isOfferLoading || isProductsLoading) {
      return true;
    }
    return isLoading && !customerType;
  };

  const onSubmit: SubmitHandler<CustomerData> = useCallback(
    data => {
      dispatch(
        handleLeadData({
          ...data,
          uuid: Cookies.get(COOKIES_CLIENT_ID_LABEL) || '',
        }),
      );

      dispatch(
        fetchCustomerLead({
          username: data.empresaRh.contato.nomeContato,
          email: data.empresaRh.contato.emailContato,
          cellphone: getPhone(data.empresaRh.contato.telefoneContato).pure,
        }),
      )
        .unwrap()
        .then(() => {
          if (isComboFlux(selectedOffer)) {
            dispatch(
              handleSetCustomerEmail(data.empresaRh.contato.emailContato),
            );
            dispatch(closeModal());
            navigate({
              pathname: '/combo/carrinho',
            });
          } else if (cartModality?.id === ModalitiesId.HCM) {
            dispatch(fetchCartPricing({ offerData: selectedOffer }))
              .unwrap()
              .then(response => {
                if (response.precificacao !== undefined) {
                  dispatch(closeModal());
                  nextStep();
                }
              });
          } else {
            dispatch(fetchCartPricing({ offerData: selectedOffer }))
              .unwrap()
              .then(res => {
                if (res.produtosContratados.length !== 0) {
                  dispatch(
                    handleSetCustomerEmail(data.empresaRh.contato.emailContato),
                  );
                } else {
                  dispatch(closeModal());
                  nextStep();
                }
              });
          }
        })
        // eslint-disable-next-line no-console
        .catch(error => console.error(error));
    },
    [dispatch, selectedOffer, cartModality?.id, navigate, nextStep],
  );

  const onChangeCnpjHandler = (cnpjRh: string) => {
    methods.trigger('empresaRh.cnpjRh');
    if (cnpjRh.length === CNPJ_MAX_LENGTH && cnpjValidate(cnpjRh)) {
      if (window.dtrum) {
        window.dtrum.sendSessionProperties(
          undefined,
          undefined,
          { lojavr_cnpj: cnpjRh },
          undefined,
        );

        window.dtrum.identifyUser(cnpjRh);
      }
      dispatch(setCompanyCnpj(clearNotNumber(cnpjRh)));
      dispatch(fetchCompanyInformation());
    }
  };

  useEffect(() => {
    if (!pathnameModalOpened && state) {
      setPathnameModalOpened(pathname);
    }
  }, [pathname, pathnameModalOpened, state]);

  useEffect(() => {
    if (pathnameModalOpened && pathnameModalOpened !== pathname) {
      setPathnameModalOpened(undefined);
      dispatch(closeModal());
    }
  }, [pathnameModalOpened, pathname, dispatch]);

  useEffect(() => {
    if (state && companyData.cnpj) {
      methods.setValue('empresaRh.cnpjRh', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (state) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      setPathnameModalOpened(undefined);
      document.body.style.overflow = 'auto';
    };
  }, [state]);
  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className="content">
      <h2>Criando sua proposta</h2>
      <Input.Default
        label="CNPJ da empresa*"
        width="100%"
        customMask="99.999.999/9999-99"
        placeholder="Digite o nº do CNPJ"
        css={{ width: '100%' }}
        inputMode="numeric"
        {...methods.register('empresaRh.cnpjRh', {
          onChange: event => onChangeCnpjHandler(event.target.value),
          required: FormErrors.REQUIRED_CNPJ,
          validate: {
            value: value => {
              return cnpjValidate(value) || FormErrors.INVALID_CNPJ;
            },
          },
        })}
        disabled={
          isLoading || isCartLoading || isOfferLoading || isProductsLoading
        }
        errorMessage={methods.formState.errors.empresaRh?.cnpjRh?.message}
      />
      {isLoadingInput() && <Loader size="md" color="$primaryPure" />}
      {!!customerType && <Scenarios />}
    </form>
  );
}
