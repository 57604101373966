import {
  EmissorData,
  EmissorStaticInformation,
} from '../config/emissor-config';

export function getEmissor(): EmissorData {
  const { hostname } = window.location;
  let emissor = EmissorStaticInformation.VRPAT;

  if (hostname.includes('caixa') || process.env.REACT_APP_EMISSOR === 'CAIXA') {
    emissor = EmissorStaticInformation.JVCEF;
  }

  return emissor;
}
