export const reset = {
  '*': {
    margin: '0',
    padding: '0',
    border: '0',
    boxSizing: 'border-box',
    // scrollBehavior: 'smooth',

    fontFamily: '$default',
    fontSize: '100%',
  },
  '*[hidden]': {
    display: 'none',
  },
  '*[disabled]': {
    cursor: 'not-allowed',
  },
  'button, a': {
    cursor: 'pointer',
  },
  'ol, ul': {
    listStyle: 'none',
  },
  table: {
    borderSpacing: '0',
  },
};
