import { PropsWithChildren, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { CSS } from '@stitches/react';

import ConfirmationModal from '@components/confirmation-modal';
import { Title } from '@elements/title';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { closeModal } from '@store/slices/modal-slice';

import { config } from '@src/themes';

import { ModalContainer } from './styles';

interface ModalProps extends PropsWithChildren {
  css?: CSS<typeof config>;
  size?: 'sm' | 'md' | 'lg';
  closeWithActions?: () => void;
}

function Modal({ children, css, size = 'sm', closeWithActions }: ModalProps) {
  const nodeRef = useRef(null);
  const { content, title, state } = useSelector(({ modal }) => modal);
  const dispatch = useDispatch();
  const ANIMATION_TIME = 400;

  const changeOverflowToInvisible = () => {
    document.body.style.overflow = 'hidden';
  };

  const changeOverflowToVisible = () => {
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={state}
      timeout={ANIMATION_TIME}
      classNames="modal"
      unmountOnExit
      onEnter={changeOverflowToInvisible}
      onExit={changeOverflowToVisible}
    >
      <ModalContainer ref={nodeRef} css={css} size={size}>
        <div className="modal-box__container">
          <button
            type="button"
            className="close-button"
            onClick={() => {
              if (closeWithActions) {
                closeWithActions();
              }
              dispatch(closeModal());
            }}
          >
            <i className="fa-solid fa-xmark" />
          </button>
          <Title.Default tag="h2">{title}</Title.Default>
          {content && <p>{content}</p>}
          {children}
        </div>
      </ModalContainer>
    </CSSTransition>
  );
}

export const ModalVariants = {
  Confirmation: ConfirmationModal,
};

export default Modal;
