import { useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { DeliverySection } from '@components/delivery-section';
import { PageTitle } from '@components/page-title';
import { ContractModalPreview } from '@compositions/contract-modal-preview';
import { Button } from '@elements/button';
import { PageContainer } from '@elements/page-container';
import { SimplePageTitle } from '@elements/simple-page-title';

import { ApiErrorsModalTitle } from '@helpers/enum/api-error-messages';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { getMessageError } from '@helpers/error-helpers';
import { getFirstName } from '@helpers/string-helpers';

import { useContract } from '@hooks/use-contract';
import { useDispatch } from '@hooks/use-dispatch';
import { useRoutes } from '@hooks/use-routes';
import { useSelector } from '@hooks/use-selector';
import { handleWarningModalContent } from '@store/slices/warning-modal-slice';

import ApplicationError from '@errors/types/application-error';

import { OrderPreviewPageHolder, TableScrollContainer } from './styles';
import { TableSelector } from './table-selector';

export function OrderPreviewPage() {
  const dispatch = useDispatch();
  const [isContractModalVisible, setContractModalVisibility] = useState(false);
  const { cartModality, cartPricingData, productCartList } = useSelector(
    ({ cart }) => cart,
  );
  const username = useSelector(({ customer }) => customer.username);
  const initialPage = useSelector(({ application }) => application.initialPage);
  const [contractBlobData, setContractBlobData] = useState<Blob | undefined>();
  const { isLoading: isContractDownloadLoading, getContractAsBlob } =
    useContract();
  const location = useLocation();
  const { prevStep } = useRoutes();

  const verifyConditionsToUseThisPage = useMemo(
    () => productCartList.length !== 0,
    [productCartList],
  );

  const handleModalVisibility = () => {
    setContractModalVisibility(state => !state);
  };

  const handleContractDownload = () => {
    if (contractBlobData) {
      setContractModalVisibility(true);
    } else {
      getContractAsBlob()
        .then(data => {
          setContractBlobData(data);
          setContractModalVisibility(true);
        })
        .catch(error => {
          dispatch(
            handleWarningModalContent({
              isVisible: true,
              title: ApiErrorsModalTitle.DEFAULT,
              content: getMessageError(error),
              closeAction: {
                label: 'Fechar',
                action:
                  error instanceof ApplicationError ? 'close' : 'reloadPage',
              },
            }),
          );
        });
    }
  };

  if (
    !verifyConditionsToUseThisPage ||
    cartModality === undefined ||
    cartPricingData === undefined
  ) {
    return (
      <Navigate
        to={{
          pathname: initialPage,
          search: location.search,
        }}
      />
    );
  }

  return (
    <>
      <PageTitle title="Resumo do Pedido" />
      <PageContainer horizontalHolder headerPadding={false}>
        <OrderPreviewPageHolder>
          <SimplePageTitle tag="h2">
            Pronto, {getFirstName(username)}! Por favor, confirme os dados
            abaixo para finalizar seu pedido:
          </SimplePageTitle>
          <TableScrollContainer>
            <TableSelector />
          </TableScrollContainer>
          {cartModality.id !== ModalitiesId.HCM && (
            <DeliverySection className="order-preview__delivery-section" />
          )}
          <div className="order-preview-page-holder__action-area">
            <div className="action-area__button-area">
              <Button.Default
                id="btn-revisar-pedido-voltar"
                outline
                onClick={() => {
                  prevStep();
                }}
                disabled={isContractDownloadLoading}
              >
                <i className="fa-regular fa-arrow-left" />
                Voltar
              </Button.Default>
              <Button.Default
                id="btn-revisar-gerar-contrato"
                loading={isContractDownloadLoading}
                onClick={handleContractDownload}
              >
                Gerar contrato
              </Button.Default>
            </div>
          </div>
        </OrderPreviewPageHolder>
      </PageContainer>
      <ContractModalPreview
        handleModalVisibility={handleModalVisibility}
        modalVisibility={isContractModalVisible}
        contractFileBlob={contractBlobData}
      />
    </>
  );
}
