import { getEmissor } from '@helpers/emissor-helpers';
import { Emissor } from '@helpers/enum/emissor';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { ServiceGroupId } from '@helpers/enum/service-group-id';

type NextStepType = {
  id: string;
  icon: string;
  content: JSX.Element;
  image?: string;
};

export const customNextSteps: { [key in ModalitiesId]: Array<NextStepType> } = {
  '900': [
    {
      id: 'first-step',
      icon: 'fa-regular fa-phone-office',
      image: '/mobility-next-step.svg',
      content: (
        <>
          Aguarde que um representante comercial da <strong>VR</strong> entrará
          em contato com você para dar prosseguimento à sua contratação.
        </>
      ),
    },
  ],
  '0': [],
  '207': [],
  '200': [],
  '20000': [],
};

export const emissorNextSteps: { [key in Emissor]: Array<NextStepType> } = {
  VRPAT: [
    {
      id: 'first-step',
      icon: 'fa-light fa-envelope',
      image: '/next-steps-1.png',
      content: (
        <>
          Confira seu <strong>e-mail</strong>, ative o cadastro para acessar o
          Portal VR empresas no <strong>link que enviamos</strong>.
        </>
      ),
    },
    {
      id: 'second-step',
      icon: 'fa-light fa-browser',
      content: (
        <>
          No <strong>Portal VR Empresas</strong>, gerencie seus cartões,
          benefícios, realize e acompanhe seus pedidos, escolha seus locais de
          entrega e consulte seus boletos e comprovantes.
        </>
      ),
    },
  ],
  JVCEF: [
    {
      id: 'first-step',
      icon: 'fa-regular fa-envelope',
      content: (
        <>
          Confira seu <strong>e-mail</strong>, ative o cadastro para acessar o{' '}
          <strong>Portal CAIXA Pré-pagos Empresas</strong> no{' '}
          <strong>link que enviamos</strong>.
        </>
      ),
    },
    {
      id: 'second-step',
      icon: 'fa-light fa-browser',
      content: (
        <>
          No <strong>Portal CAIXA Pré-pagos Empresas</strong>, gerencie seus
          cartões, benefícios, realize e acompanhe seus pedidos, escolha seus
          locais de entrega e consulte seus boletos e comprovantes.
        </>
      ),
    },
  ],
};

export const emissorMarketplaceNextStep: { [key in Emissor]: NextStepType } = {
  VRPAT: {
    id: 'marketplace-step',
    icon: 'fa-regular fa-splotch',
    image: '/next-steps-2.png',
    content: (
      <>
        Ao acessar o <strong>Portal VR Empresas</strong>, cadastre-se para o
        <strong> Portal Marketplace</strong>.
      </>
    ),
  },
  JVCEF: {
    id: 'marketplace-step',
    icon: 'fa-regular fa-splotch',
    content: (
      <>
        Ao acessar o <strong>Portal Caixa Pré-pagos Empresas</strong>,
        cadastre-se para o<strong> Portal Marketplace</strong>.
      </>
    ),
  },
};

export const comboOfferNextSteps: Array<{
  id: string;
  title: string;
  description: string;
  image: string;
}> = [
  {
    id: 'ponto-mais',
    title: 'Pontomais',
    description:
      'Você receberá um e-mail com link para criar sua conta, ativar o contrato e acessar o boleto de pagamento, além do Kit de Boas-Vindas com link para agendar o treinamento de uso do produto.',
    image: '/next-steps-1.png',
  },
  {
    id: 'cartao-multi',
    title: 'Cartão Multi',
    description:
      'Você receberá um e-mail com os dados de acesso ao Portal VR Empresas onde poderá: \n- Cadastrar seus colaboradores \n- Gerar o boleto de pagamento do 1º benefício',
    image: '/next-steps-2.png',
  },
  {
    id: 'vale-transporte',
    title: 'Vale-Transporte',
    description:
      'Aguarde que um representante comercial da VR Mobilidade entrará em contato com você para realizar a ativação do seu produto.',
    image: '/next-steps-1.png',
  },
];

export const serviceNextSteps: Record<
  string,
  {
    id: string;
    description: JSX.Element;
    image: string;
  }
> = {
  [ServiceGroupId.HCM]: {
    id: `next-step-${ServiceGroupId.HCM}`,
    description: (
      <>
        Você <strong>receberá um e-mail</strong> com link para criar sua conta,
        ativar o contrato e acessar o <strong>boleto de pagamento</strong>, além
        do Kit de Boas-Vindas com link para agendar o{' '}
        <strong>treinamento de uso do produto</strong>.
      </>
    ),
    image: '/next-step-hcm.png',
  },
  [ServiceGroupId.BENEFIT]: {
    id: `next-step-${ServiceGroupId.BENEFIT}`,
    description: (
      <>
        <p>
          Você receberá via email um link de acesso ao{' '}
          <strong>Portal {getEmissor().shortName}</strong> onde você irá:
        </p>
        <p>
          <strong>
            - Cadastrar e conferir o endereço de entrega dos cartões
          </strong>
        </p>
        <p>
          <strong>- Cadastrar os colaboraores da sua empresa</strong>
        </p>
        <p>
          <strong>- Realizar o seu primeiro pedido de benefícios</strong>
        </p>
      </>
    ),
    image: '/next-step-beneficios.png',
  },
  [ServiceGroupId.MOBILITY]: {
    id: `next-step-${ServiceGroupId.MOBILITY}`,
    description: (
      <p>
        Aguarde que um{' '}
        <strong>consultor de implantação entrará em contato</strong> com você
        para realizar o seu pedido do vale transporte.
      </p>
    ),
    image: '/next-step-mobilidade.png',
  },
  [ServiceGroupId.UNKNOWN]: {
    id: `next-step-${ServiceGroupId.UNKNOWN}`,
    description: (
      <p>
        Você receberá um e-mail com link para criar sua conta, ativar o contrato
        e acessar o boleto de pagamento, além do Kit de Boas-Vindas com link
        para agendar o treinamento de uso do produto.
      </p>
    ),
    image: '',
  },
};
