import { LiHTMLAttributes } from 'react';

import { PricingEntriesType } from '@extra-types/pricing-types';
import {
  getEntryBaseValueLabel,
  getEntryValueLabel,
} from '@helpers/pricing-helpers';

import { styled } from '@src/themes';

interface ComboEntryTaxProps extends LiHTMLAttributes<HTMLLIElement> {
  entryData: PricingEntriesType;
  variant?: 'default' | 'summary-view';
}

export function ComboEntryTax({
  entryData,
  variant = 'default',
  ...props
}: ComboEntryTaxProps) {
  return (
    <ComboEntryTaxContainer variant={variant} {...props}>
      <div className="combo-entry-tax-container__title-wrapper">
        <p className="title-wrapper__title">{entryData.nome}</p>
        {entryData.descricao && <p>{entryData.descricao}</p>}
      </div>
      <div className="combo-entry-tax-container__entry-values">
        <div className="entry-values__item">
          <span className="item__title">Base</span>
          <span className="item__value">
            {entryData.valorTotal !== 0
              ? getEntryBaseValueLabel(
                  entryData.valorUnitario,
                  entryData.tipoValorTaxa,
                )
              : '-'}
          </span>
        </div>
        <div className="entry-values__item">
          <span className="item__title">Valor Total</span>
          <span className="item__value item__value--green">
            {getEntryValueLabel(entryData.valorTotal)}
          </span>
        </div>
      </div>
    </ComboEntryTaxContainer>
  );
}

const ComboEntryTaxContainer = styled('li', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: '0.5rem',
  alignItems: 'center',

  background: '$white',
  padding: '1rem 0.5rem',

  '.combo-entry-tax-container__title-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    width: '100%',

    p: {
      fontSize: '0.875rem',
      fontWeight: '$semibold',

      '&.title-wrapper__title': {
        color: '$primaryPure',
      },
    },
  },

  '.combo-entry-tax-container__entry-values': {
    display: 'grid',
    justifyContent: 'flex-end',
    gridTemplateColumns: '8.625rem 8.625rem',
  },

  '.entry-values__item': {
    display: 'flex',
    justifyContent: 'flex-end',

    fontSize: '0.875rem',
    fontWeight: '$medium',

    '.item__value': {
      color: '#4A5969',

      '&--green': {
        color: '$primaryPure',
      },
    },
  },

  '.item__title': {
    display: 'none',
  },

  '@deviceMd': {
    '.combo-entry-tax-container__entry-values': {
      gridTemplateColumns: '6rem 6rem',
    },
  },
  '@deviceSm': {
    display: 'flex',
    flexDirection: 'column',

    '.combo-entry-tax-container__title-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',

      marginBottom: '1rem',

      p: {
        fontSize: '0.75rem',
        fontWeight: '$semibold',

        '&.title-wrapper__title': {
          color: '$primaryPure',
        },
      },
    },

    '.combo-entry-tax-container__entry-values': {
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '1.25rem',

      width: '100%',
    },

    '.entry-values__item': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',

      fontSize: '0.75rem',
      fontWeight: '$medium',

      '.item__value': {
        color: '#4A5969',

        '&--green': {
          color: '$primaryPure',
        },
      },
    },

    '.item__title': {
      display: 'block',
    },
  },
  variants: {
    variant: {
      'summary-view': {
        display: 'flex',
        flexDirection: 'column',
        gap: 0,

        background: '$white',
        padding: '1rem 0.5rem',

        '.combo-entry-tax-container__title-wrapper': {
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',

          marginBottom: '1rem',

          p: {
            fontSize: '0.75rem',
            fontWeight: '$semibold',

            '&.title-wrapper__title': {
              color: '$primaryPure',
            },
          },
        },

        '.combo-entry-tax-container__entry-values': {
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '1.25rem',

          width: '100%',
        },

        '.entry-values__item': {
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',

          fontSize: '0.75rem',
          fontWeight: '$medium',

          '.item__value': {
            color: '#4A5969',

            '&--green': {
              color: '$primaryPure',
            },
          },
        },

        '.item__title': {
          display: 'block',
        },
      },
      default: {},
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});
