import Cookies from 'js-cookie';

import { serviceAccessToken } from './service-access-token';

interface GetAccessToken {
  clientId: string;
}

export const sessionKeys = {
  X_AUTHORIZATION: 'vrAuth.xAuthorization',
  CLIENT_ID: 'vrAuth.clientId',
};

export const servicesControlAccessToken = {
  async generateAccessToken(data: GetAccessToken): Promise<string> {
    const accessToken = Cookies.get(sessionKeys.X_AUTHORIZATION);

    if (accessToken) {
      return accessToken;
    }

    const response = await serviceAccessToken.getAccessToken({
      clientId: data.clientId,
    });

    const expirationDate = new Date().setSeconds(response.expires_in);

    Cookies.set(sessionKeys.X_AUTHORIZATION, response.access_token, {
      expires: new Date(expirationDate),
    });
    Cookies.set(sessionKeys.CLIENT_ID, data.clientId);

    return response.access_token;
  },

  async refreshAccessToken() {
    const accessToken = Cookies.get(sessionKeys.X_AUTHORIZATION);
    const clientId = Cookies.get(sessionKeys.CLIENT_ID);

    if (!accessToken && clientId) {
      const response = await serviceAccessToken.getAccessToken({
        clientId,
      });

      const expirationDate = new Date().setSeconds(response.expires_in);

      Cookies.set(sessionKeys.X_AUTHORIZATION, response.access_token, {
        expires: new Date(expirationDate),
      });

      return true;
    }

    throw new Error(
      'Os dados em memória não são suficientes para atualizar o access token!',
    );
  },
};
