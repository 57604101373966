import { styled } from '@src/themes';

export const ModalContainer = styled('div', {
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.7)',
  zIndex: '$7',
  overscrollBehavior: 'contain',
  backdropFilter: 'blur(4px)',

  '@deviceSm': {
    alignItems: 'flex-end',
  },

  '&.modal-enter': {
    opacity: '0',

    '.modal-box__container': {
      transform: 'translateY(100%)',
    },
  },

  '&.modal-enter-active': {
    opacity: '1',
    transition: 'opacity 0.4s',

    '.modal-box__container': {
      transform: 'translateY(0)',
      transition: 'transform 0.4s',
    },
  },

  '&.modal-exit': {
    opacity: '1',

    '.modal-box__container': {
      transform: 'translateY(0)',
    },
  },

  '&.modal-exit-active': {
    opacity: '0',
    transition: 'opacity 0.4s',

    '.modal-box__container': {
      transform: 'translateY(100%)',
      transition: 'transform 0.4s',
    },
  },

  '.modal-box__container': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    backgroundColor: '$white',
    borderRadius: '10px',
    padding: '2rem ',
    maxWidth: '80%',
    zIndex: '$8',

    h2: {
      textAlign: 'center',
      marginRight: 0,
    },

    '> p': {
      fontSize: '1.125rem',
      fontWeight: '500',
      lineHeight: '1.5',
      textAlign: 'center',
    },

    '.content-button-secondary': {
      color: '$primaryPure',
      fontWeight: '600',
      cursor: 'pointer',
    },

    '@deviceXs': {
      gap: '1rem',

      '> p': {
        fontSize: '0.875rem',
      },
    },

    '@deviceSm': {
      width: '100%',
      borderRadius: '10px 10px 0 0',
      padding: '2rem 1.25rem',
      maxWidth: '100%',
      maxHeight: '95vh',

      '.content-button-secondary': {
        fontSize: '0.875rem',
      },
    },
  },

  '.references': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1rem',
    textAlign: 'center',
  },

  '.close-button': {
    position: 'absolute',
    top: '-1rem',
    right: '-1rem',
    backgroundColor: '$white',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    color: '$primaryPure',
    boxShadow: '0px 0px 8px #00000022',
    fontSize: '1.125rem',

    '@deviceSm': {
      top: '-1rem',
      right: '1rem',
    },
  },

  '.alignment': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
    color: '#6D839A',

    h2: {
      textAlign: 'left',
      padding: '1rem 0',
    },

    img: {
      width: '220px',
    },

    a: {
      width: '100%',
    },
  },

  variants: {
    size: {
      sm: {
        '.modal-box__container': {
          width: '445px',
          '@deviceSm': {
            width: '100%',
          },
        },
      },
      md: {
        '.modal-box__container': {
          width: '643px',
          '@deviceSm': {
            width: '100%',
          },
        },
      },
      lg: {
        '.modal-box__container': {
          width: 'auto',
          maxHeight: '70%',

          '@deviceSm': {
            width: '100%',
          },
        },
      },
    },
  },
});
