import { HCMOptions } from '@config/hcm-config';
import { MODALITIES } from '@config/modality-config';
import { PricingDetailProductType } from '@extra-types/pricing-types';
import { ProductType } from '@extra-types/product-type';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import {
  getModalityData,
  getModalityListByProductList,
} from '@helpers/modality-helpers';
import { getProductPricedFullDataList } from '@helpers/product-helpers';
import {
  completeWithLeftZero,
  getSingularOrPlural,
} from '@helpers/string-helpers';

interface ComboServiceSummaryExtraInformationProps {
  serviceId: string;
  productListByService: ProductType[];
  productPricingListByService: PricingDetailProductType[];
}
export function ComboServiceSummaryExtraInformation({
  serviceId,
  productListByService,
  productPricingListByService,
}: ComboServiceSummaryExtraInformationProps) {
  if (serviceId === ServiceGroupId.BENEFIT) {
    const productList = getProductPricedFullDataList(
      productPricingListByService,
      productListByService,
    );
    const modalityList = getModalityListByProductList(productList);
    const cardAmount = modalityList.reduce((totalAmount, modality) => {
      const modalityData = getModalityData(modality, Object.values(MODALITIES));
      const amount = productList
        .filter(product => product.modalityId === modality)
        .reduce((totalProductAmount, product) => {
          if (modalityData.isWalletGroupCard) {
            return product.cardAmount;
          }

          return totalProductAmount + product.cardAmount;
        }, 0);

      return totalAmount + amount;
    }, 0);

    return (
      <span>
        {completeWithLeftZero(cardAmount)}{' '}
        {getSingularOrPlural(cardAmount, 'cartão', 'cartões')}
      </span>
    );
  }

  if (serviceId === ServiceGroupId.MOBILITY) {
    const cardAmount = productPricingListByService[0].quantidade;
    return (
      <span>
        {completeWithLeftZero(cardAmount)}{' '}
        {getSingularOrPlural(cardAmount, 'cartão', 'cartões')}
      </span>
    );
  }

  if (serviceId === ServiceGroupId.HCM) {
    return (
      <>
        {productListByService.map(product => {
          return (
            <>
              <span>{product.name}</span>
              <span>
                {
                  HCMOptions[product.id].find(
                    option =>
                      option.value ===
                      productPricingListByService.find(item => item)
                        ?.quantidade,
                  )?.label
                }{' '}
                trabalhadores
              </span>
            </>
          );
        })}
      </>
    );
  }

  return null;
}
