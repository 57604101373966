import { useState } from 'react';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { OrderRevisionServiceTableContent } from '@components/order-revision-service-table-components/order-revision-service-table-content';
import { OrderRevisionSvaList } from '@components/order-revision-service-table-components/order-revision-sva-list';
import { ComboTaxTable } from '@pages/offer-page/custom-offer-pages/components/combo-summary-components/combo-tax-table';
import { ComboServiceFormRenegotiationMessage } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-renegotiation-message';

import { OrderRevisionServiceType } from '@extra-types/order-revision-service-type';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { isComboFlux } from '@helpers/flux-helpers';
import { scrollToElement } from '@helpers/function-helpers';
import { getPricingResumeData } from '@helpers/pricing-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

interface OrderRevisionTableProps {
  data: OrderRevisionServiceType;
}

export function OrderRevisionServiceTable({ data }: OrderRevisionTableProps) {
  const [isCollapsed, setCollapseState] = useState(true);
  const { selectedOffer } = OfferFacade();
  const { cartPricingData, comboCartPricingData } = useSelector(
    ({ cart }) => cart,
  );
  const tableID = `resumo-pedido-servico-${data.service.id.toLowerCase()}`;

  const productPricedDataList = isComboFlux(selectedOffer)
    ? comboCartPricingData
    : cartPricingData;

  const totalServiceValue =
    data.productPricedList.reduce(
      (totalValue, productPricedItem) =>
        totalValue + productPricedItem.valorTotal,
      0,
    ) + data.pricingResumeData.valorTotalTaxas;

  const handleCollapseState = () => {
    scrollToElement(tableID);
    setCollapseState(state => !state);
  };

  return (
    <OrderRevisionTableContainer isCollapsed={isCollapsed} id={tableID}>
      <ComboServiceFormRenegotiationMessage serviceId={data.service.id} />
      <div className="order-preview-table-container__header">
        <h3>{data.service.title}</h3>
      </div>
      <div className="order-preview-table-container__content">
        <OrderRevisionServiceTableContent
          productPricedList={data.productPricedList}
          isCollapsed={isCollapsed}
        />
      </div>
      <div className="order-preview-table-container__footer">
        <div className="footer__tax-holder">
          {data.pricingResumeData.lancamentos.length === 0 ? (
            <div className="footer__horizontal-rule" />
          ) : (
            <ComboTaxTable
              serviceId={data.service.id}
              pricingResumeData={getPricingResumeData(
                productPricedDataList,
                data.service.id as ServiceGroupId,
              )}
            />
          )}
        </div>

        {data.service.id !== ServiceGroupId.HCM && (
          <OrderRevisionSvaList isCollapsed={isCollapsed} />
        )}
        <div className="footer__total-value-holder">
          <p className="total-value-holder__label">VALOR TOTAL</p>
          <p className="total-value-holder__value">
            {formatValueToCurrency(totalServiceValue)}/mês
          </p>
        </div>
        <button
          className="footer__show-more-button"
          type="button"
          onClick={handleCollapseState}
        >
          Ver {!isCollapsed && 'menos'} detalhes
          <i className="fa-solid fa-caret-down" />
        </button>
      </div>
    </OrderRevisionTableContainer>
  );
}

const OrderRevisionTableContainer = styled('div', {
  overflow: 'clip',
  border: '1px solid #E4E8ED',
  borderRadius: '0.5rem',

  '.order-preview-table-container__header': {
    background: '$silverLight',
    padding: '1.25rem 1rem',
    borderBottom: '1px solid #E4E8ED',

    h3: {
      color: '$black',
      fontSize: '1rem',
    },

    '@deviceSm': {
      padding: '0.75rem 1rem',

      h3: {
        fontSize: convertPixelToRem(14),
      },
    },
  },

  '.order-preview-table-container__footer': {
    display: 'flex',
    flexDirection: 'column',

    '.sva-list': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.5rem 1rem',

      p: {
        fontSize: '0.875rem',
        color: '$primaryPure',
        fontWeight: 'bold',
        paddingBottom: '0.5rem',
      },

      div: {
        display: 'flex',
        gap: '1rem',
      },
    },

    '.footer__tax-holder': {
      width: '100%',
      padding: '0 1rem',

      '> div': {
        width: '100%',
        marginTop: '1.5rem',
      },
    },
  },

  '.footer__horizontal-rule': {
    backgroundColor: '#E4E8ED',
    height: '1px',
    width: 'calc(100% - 32px)',

    transition: 'background-color 0.4s ease',

    '@deviceSm': {
      display: 'none',
    },
  },

  '.footer__total-value-holder': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '2rem',

    width: '100%',
    padding: '1.5rem 1rem',

    '.total-value-holder__label': {
      color: '$tertiaryPure',
      fontSize: '0.75rem',
      fontWeight: '$bold',
    },

    '.total-value-holder__value': {
      color: '$primaryPure',
      fontSize: '1rem',
      fontWeight: '$bold',

      '@deviceXs': {
        fontSize: '0.875rem',
      },
    },

    '@deviceSm': {
      justifyContent: 'space-between',
    },
  },

  '.footer__show-more-button': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',

    background: '$silverLight',
    width: '100%',
    padding: '0.5rem',
    borderTop: '1px solid #E4E8ED',

    color: '$primaryPure',
    fontSize: '0.75rem',
    fontWeight: '$medium',

    i: {
      transition: 'transform 0.4s ease',
    },

    '@deviceSm': {
      padding: '1rem',
    },
  },

  variants: {
    isCollapsed: {
      true: {},
      false: {
        '.footer__show-more-button': {
          i: {
            transform: 'rotate(-180deg)',
          },
        },

        '.footer__horizontal-rule': {
          backgroundColor: '$white',
        },

        '.footer__total-value-holder': {
          justifyContent: 'space-between',
        },
      },
    },
  },
});
