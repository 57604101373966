import { ProductNameText } from '@elements/product-name-text';

import { env } from '@config/env';
import { ProductType } from '@extra-types/product-type';

interface ProductWalletDescriptionProps {
  productData: ProductType;
}

function ProductWalletDescription({
  productData,
}: ProductWalletDescriptionProps) {
  return (
    <div className="product-wallet-input-container__information">
      <img
        src={env.URL_ASSETS_CHANNEL + productData.logoPath}
        alt={`Logotipo ${productData.name}`}
      />
      <div className="information__text">
        <p className="text__product-name">
          <strong>
            <ProductNameText id={productData.id} showAsterisk />
          </strong>
        </p>
        <p>{productData.description}</p>
      </div>
    </div>
  );
}

export default ProductWalletDescription;
