import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { HCMPlansType } from '@compositions/hcm-sections/hcm-plans-section';
import { Button } from '@elements/button';

import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { ProductType } from '@extra-types/product-type';
import { getModalityStaticData } from '@helpers/modality-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addPlanToCart,
  addAdditionalProducts,
  setCartModality,
  resetAdditionalProductCart,
} from '@store/slices/cart-slice';
import {
  changeCustomerType,
  proceedToPurchase,
} from '@store/slices/customer-slice';
import { closeModal } from '@store/slices/modal-slice';
import { fetchPricingWithoutLead } from '@store/thunks/cart-thunk';

import { styled } from '@src/themes';

import { Skeleton, SkeletonSectionContainer } from './skeletons';

type HCMProductBoxProps = {
  productData: ProductType;
};

export function HCMProductBox({ productData }: HCMProductBoxProps) {
  const dispatch = useDispatch();
  const { isCartLoading, cartPricingData, productCartList } = useSelector(
    ({ cart }) => cart,
  );
  const { state } = useSelector(({ modal }) => modal);
  const methods = useFormContext<HCMPlansType>();
  const modality = getModalityStaticData(productData.modalityId);
  const getAmountOfEmployees = useMemo(() => {
    if (productCartList.length > 0) {
      return productCartList[0].amount;
    }
    return methods.watch('employeesAmount');
  }, [methods, productCartList]);
  const sortedAdditionalProducts = productData.additionalProductList
    .slice()
    .sort((first, second) => first.id - second.id)
    .filter(({ isRequired }) => !!isRequired);

  const pricedPlan = useMemo(() => {
    return cartPricingData?.detalhe.produtos.find(
      ({ codigoProduto }) => codigoProduto === productData.id,
    );
  }, [cartPricingData?.detalhe.produtos, productData.id]);

  const getDiscount = () => {
    return (
      (pricedPlan?.valorProdutoComDesconto || 0) -
      (pricedPlan?.valorProduto || 0)
    );
  };

  const isPlanSelected = !!productCartList.find(
    selectedProduct => selectedProduct.id === productData.id,
  );

  const handleSelectPlan = () => {
    dispatch(setCartModality(modality));
    dispatch(
      addPlanToCart({
        amount: getAmountOfEmployees || 10,
        id: productData.id,
        type: productData.type,
        value:
          (methods.watch('planType') === PlanTypeEnum.ANUAL
            ? pricedPlan?.valorProdutoComDesconto
            : pricedPlan?.valorProduto) || 0,
        planType: methods.watch('planType'),
      }),
    );
    dispatch(resetAdditionalProductCart());
    dispatch(
      addAdditionalProducts({
        required: productData.additionalProductList.filter(
          additionalProduct => !!additionalProduct.isRequired,
        ),
        unrequired: productData.additionalProductList.filter(
          additionalProduct => !additionalProduct.isRequired,
        ),
      }),
    );

    if (state) {
      dispatch(fetchPricingWithoutLead());
      dispatch(closeModal());
    } else {
      dispatch(changeCustomerType(undefined));
      dispatch(proceedToPurchase());
    }
  };

  if (isCartLoading) {
    <SkeletonSectionContainer>
      <Skeleton.ProductForm />
      <Skeleton.ProductForm />
      <Skeleton.ProductForm />
      <Skeleton.ProductForm />
    </SkeletonSectionContainer>;
  }

  return (
    <HCMProductBoxContainer
      disabled={isCartLoading || !pricedPlan?.valorProduto}
      selected={isPlanSelected}
    >
      {isPlanSelected && (
        <div className="plan-in-cart-view__flag">
          <i className="fa-solid fa-cart-shopping" />
        </div>
      )}

      <h3>{productData.name}</h3>
      <p>{productData.description}</p>
      {!!pricedPlan && !!getDiscount() && !isCartLoading && (
        <div className="plan-discount">
          <i className="fa-light fa-circle-dollar" />
          Economia de {formatValueToCurrency(getDiscount())}
        </div>
      )}

      <span>
        <p>A partir de</p>
        <strong>
          {isCartLoading
            ? '-'
            : formatValueToCurrency(pricedPlan?.valorTotalComDesconto || 0)}
        </strong>
        /{methods.getValues().planType}
      </span>
      {isPlanSelected ? (
        <div className="plan-selected">
          <i className="fa-solid fa-check" />
          <p>Plano adicionado!</p>
        </div>
      ) : (
        <Button.Default
          onClick={() => handleSelectPlan()}
          loading={isCartLoading}
        >
          Contratar
        </Button.Default>
      )}
      <div className="additional-products-holder">
        {sortedAdditionalProducts.map(additionalProduct => (
          <div key={additionalProduct.id}>
            <i className="fas fa-check" />
            <p>{additionalProduct.name}</p>
          </div>
        ))}
      </div>
    </HCMProductBoxContainer>
  );
}

const HCMProductBoxContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  padding: '1.5rem',
  boxShadow: '0px 4px 16px #1B212626',
  background: '$white',
  borderRadius: '8px',
  minWidth: '260px',
  maxWidth: '260px',

  '.plan-discount': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    color: '$white',
    fontSize: '0.875rem',
    fontWeight: '500',
    backgroundColor: '$featuredFlagBackground',
    whiteSpace: 'nowrap',

    padding: '0.375rem 1.5rem',
  },

  '.additional-products-holder': {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1rem',
    gap: '1rem',

    div: {
      display: 'flex',
      gap: '5px',

      p: {
        color: '$black',
        fontWeight: '600',
      },

      i: {
        color: '$primaryPure',
      },
    },
  },

  h3: {
    color: '$black',
    fontSize: '1.375rem',
    fontWeight: 'bold',
  },

  p: {
    fontSize: '0.875rem',
    color: '$mobilidadePrimaryPure',
    fontWeight: '500',
  },

  '.plan-selected': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    fontWeight: '600',

    i: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '$white',
      backgroundColor: '$primaryPure',
      borderRadius: '50%',
      height: '2rem',
      width: '2rem',
    },
  },

  span: {
    fontSize: '1rem',
    fontWeight: '500',
    color: '$mobilidadePrimaryPure',

    p: {
      fontSize: '0.75rem',
    },

    strong: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
      color: '$black',
    },
  },

  '@deviceSm': {
    width: '100%',
    maxWidth: 'unset',
  },

  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
    },
    selected: {
      true: {
        position: 'relative',
        boxShadow: '0px 4px 16px #1B212626',
        border: '2px solid $primaryPure',

        '.plan-in-cart-view__flag': {
          position: 'absolute',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          top: '-1px',
          right: '-1px',
          width: '4.5rem',
          height: '4.5rem',
          padding: '10px',
          backgroundColor: '$primaryPure',
          color: '$white',
          fontSize: '1.375rem',
          clipPath: 'polygon(0 0, 100% 0, 100% 100%)',
          borderTopRightRadius: '8px',
        },
      },
      false: {},
    },
  },
});
