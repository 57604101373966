import AuthData from '@api/models/request/auth-data';
import BaseConfigurationResponseData from '@api/models/response/base-configuration-response-data';

import getBaseURLApi from './authentication/get-base-url-api';

class EmissorService {
  private readonly baseUrl;

  constructor() {
    this.baseUrl = 'emissores';
  }

  public async getBaseConfiguration({
    siglaEmissor,
  }: Pick<AuthData, 'siglaEmissor'>): Promise<BaseConfigurationResponseData> {
    const url = `/${this.baseUrl}/${siglaEmissor}/configuracoes`;

    const { data } = await getBaseURLApi(url);

    return data;
  }
}

export default new EmissorService();
