import { useLocation } from 'react-router-dom';

import { Cart } from '@compositions/cart';
import { HelpButton } from '@elements/help-button';
import { Logo } from '@elements/logo';
import { PageSectionContainer } from '@elements/page-section';

import { HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from '@config/styles-config';
import { isCustomChannel } from '@helpers/channel-helpers';
import { verifyBackButtonPageBlackList } from '@helpers/page-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useRoutes } from '@hooks/use-routes';
import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

export function HeaderVRPAT() {
  const location = useLocation();
  const { config, initialPage } = useSelector(({ application }) => application);
  const { prevStep } = useRoutes();

  return (
    <HeaderContent as="header" variant="header" dark>
      <div className="page-section-container__holder">
        <div className="holder__left-content">
          {verifyBackButtonPageBlackList({
            currentPage: location.pathname,
            initialPage,
          }) && (
            <MainPageButton
              title="Voltar para a página anterior"
              isCustomChannel={!!isCustomChannel(config.siglaCanal)}
              onClick={() => prevStep()}
            >
              <i className="fa-solid fa-chevron-left" />
            </MainPageButton>
          )}
          <Logo.Emissor />
          <Logo.Channel
            fallbackComponent={<Text>{config.emissor.title}</Text>}
          />
        </div>
        <div className="holder__right-content">
          <HelpButton isCustomChannel={!!isCustomChannel(config.siglaCanal)} />
          <Cart isCustomChannel={!!isCustomChannel(config.siglaCanal)} />
        </div>
      </div>
    </HeaderContent>
  );
}

const HeaderContent = styled(PageSectionContainer, {
  zIndex: '$5',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,

  '.page-section-container__holder': {
    position: 'relative',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '2rem',

    width: '100%',
    height: convertPixelToRem(HEADER_HEIGHT),

    '.holder__left-content': {
      zIndex: '$4',
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',
    },

    '.holder__right-content': {
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',

      '@deviceSm': {
        gap: '0.5rem',
      },
    },

    '@deviceSm': {
      height: convertPixelToRem(HEADER_MOBILE_HEIGHT),

      '.holder__left-content': {
        gap: '0.75rem',
      },
    },
  },
});

const MainPageButton = styled('button', {
  background: 'transparent',

  color: '$white',
  fontSize: '1.5rem',
  transition: 'color 0.4s ease',

  '&:hover': {
    color: '$secondaryPure',
  },

  variants: {
    isCustomChannel: {
      true: {
        color: '$primaryPure',
        '&:hover': {
          color: '$primaryDark',
        },
      },
    },
  },
});

const Text = styled('p', {
  color: '$white',
  fontWeight: '$fontWeights$bold',
  fontSize: '1.25rem',
});
