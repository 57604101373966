import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const MessageContainer = styled('div', {
  zIndex: '$max',
  position: 'fixed',
  left: '0',
  bottom: '1rem',

  background: '$white',
  maxWidth: convertPixelToRem(640),
  margin: '0 1rem',
  padding: '1rem',
  borderRadius: convertPixelToRem(10),
  boxShadow: '0px 0px 8px #00000029',

  '&.cookies-message-enter': {
    opacity: 0,
    transform: 'translateY(100%)',
  },

  '&.cookies-message-enter-active': {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'opacity 0.4s ease, transform 0.4s ease',
  },

  '&.cookies-message-exit': {
    opacity: 1,
    transform: 'translateY(0)',
  },

  '&.cookies-message-exit-active': {
    opacity: 0,
    transform: 'translateY(100%)',
    transition: 'opacity 0.4s ease, transform 0.4s ease',
  },

  h2: {
    marginBottom: '0.5rem',

    color: '$fontColorPrimary',
  },

  p: {
    margin: '0 0 0.5rem',

    color: '$fontColorPrimary',
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '1.315rem',

    a: {
      color: '$primaryPure',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },

    '@deviceSm': {
      fontSize: '0.75rem',
    },
  },

  '.cookies-message__button-section': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
