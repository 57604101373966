/* eslint-disable @typescript-eslint/no-unused-vars */
import clearNotNumber from '@vr/devkit/utils/clearNotNumber';

import { DEFAULT_EMPLOYEES_AMOUNT } from '@config/application-config';
import { CompanyChainType } from '@extra-types/company-chain-type';
import { CompanySliceType } from '@extra-types/company-slice-type';
import { CompanyType } from '@extra-types/company-type';
import { LegalPersonType } from '@extra-types/legal-person-type';
import { ProductCartType } from '@extra-types/product-cart-type';
import { RepresentativeType } from '@extra-types/representative-type';

import { UpdateLeadRequest } from '@api/models/request/update-lead-request';

import { Contact } from './enum/contact';
import { toISOFormat } from './function-helpers';

export const createUpdateLeadRequest = ({
  companyFormData,
  companyData,
  representativeData,
  productCartList,
}: {
  companyFormData: CompanyType;
  companyData: CompanySliceType;
  representativeData: RepresentativeType;
  productCartList: ProductCartType[];
}): UpdateLeadRequest => {
  const { tipo, ...repLegalRest } = companyFormData.representanteLegal;

  const representanteLegal: LegalPersonType[] = [];
  const cadeiaSocietaria: CompanyChainType[] = [];

  if (tipo === Contact.RL) {
    representanteLegal.push({
      nome: repLegalRest.nome,
      documento: clearNotNumber(repLegalRest.documento),
      politicamenteExposta: repLegalRest.politicamenteExposta,
    });
  } else {
    cadeiaSocietaria.push({
      nome: repLegalRest.nome,
      documento: clearNotNumber(repLegalRest.documento),
      politicamenteExposta: repLegalRest.politicamenteExposta,
      juridica: repLegalRest.juridica,
    });
  }

  companyFormData.contatoExtra.forEach(contato => {
    if (contato.tipo === Contact.RL) {
      representanteLegal.push({
        nome: contato.nome,
        documento: clearNotNumber(contato.documento),
        politicamenteExposta: contato.politicamenteExposta,
      });
    } else if (contato.tipo === Contact.CS) {
      cadeiaSocietaria.push({
        nome: contato.nome,
        documento: clearNotNumber(contato.documento),
        politicamenteExposta: contato.politicamenteExposta,
        juridica: contato.juridica,
      });
    }
  });

  return {
    uuid: representativeData.uuid,
    empresaRh: {
      utm: representativeData.utm,
      cnpjRh: companyData.cnpj,
      nomeFantasia: companyData.nomeFantasia,
      contato: {
        nomeContato: companyFormData.dadosCadastrais.nomeCadastro,
        emailContato: companyFormData.dadosCadastrais.emailCadastro,
        telefoneContato: clearNotNumber(
          companyFormData.dadosCadastrais.telefoneCadastro,
        ),
        cpfContato: clearNotNumber(companyFormData.dadosCadastrais.cpfContato),
        dataNascimento: toISOFormat(
          companyFormData.dadosCadastrais.dataNascimento,
        ),
      },
      representanteLegal,
      cadeiaSocietaria,
      enderecoLocalEntrega: {
        ...companyFormData.enderecoLocalEntrega,
        cep: clearNotNumber(companyFormData.enderecoLocalEntrega.cep),
      },
      enderecoFiscal: companyData.enderecoFiscal,
      nomeImpressao: companyFormData.nomeFantasia,
      qtdFuncionarios:
        Math.max(...productCartList.map(product => product.amount)) ||
        DEFAULT_EMPLOYEES_AMOUNT,
    },
  };
};
