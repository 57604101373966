import { Carousel } from '@compositions/carousel';
import { InformativeSectionGroup } from '@compositions/informative-section-group';
import { ProductsShelfSection } from '@compositions/sections/products-shelf-section';
import { PageContainer } from '@elements/page-container';

export function HomePage() {
  return (
    <PageContainer>
      <Carousel />
      <ProductsShelfSection />
      <InformativeSectionGroup type="informative" />
    </PageContainer>
  );
}
