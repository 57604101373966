import { Button } from '@elements/button';
import { ModalityProductGroup } from '@elements/modality-product-group';

import { env } from '@config/env';
import {
  getAvailableProductList,
  getProductStaticData,
  getProductListGroupedByModality,
} from '@helpers/product-helpers';
import { getSingularOrPlural } from '@helpers/string-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { closeModal } from '@store/slices/customer-slice';

import { ScenariosContainer } from './styles';

function UnservedRegion() {
  const { productCartList } = useSelector(({ cart }) => cart);
  const { productList, modalityList } = useSelector(({ products }) => products);
  const { contractedProducts, unservedRegionProducts } = useSelector(
    ({ customer }) => customer,
  );
  const dispatch = useDispatch();

  const availableProductList = getAvailableProductList({
    productList,
    contractedProductList: contractedProducts,
    unservedProductList: unservedRegionProducts,
    productCartList,
  });

  const availableProductsGroupedByModality = getProductListGroupedByModality(
    availableProductList,
    modalityList,
  );

  return (
    <ScenariosContainer>
      <div className="information-box unserved-region-message">
        <p>
          Infelizmente{' '}
          {getSingularOrPlural(unservedRegionProducts.length, 'o', 'os')}{' '}
          {getSingularOrPlural(
            unservedRegionProducts.length,
            'produto',
            'produtos',
          )}{' '}
          {getSingularOrPlural(
            unservedRegionProducts.length,
            'escolhido',
            'escolhidos',
          )}{' '}
          ainda não{' '}
          {getSingularOrPlural(
            unservedRegionProducts.length,
            'chegou',
            'chegaram',
          )}{' '}
          em sua região.
        </p>
        <div className="unserved-region-message__products-group">
          {unservedRegionProducts.map(productId => (
            <img
              key={productId}
              src={`${env.URL_ASSETS_CHANNEL}${
                getProductStaticData(productId).logoPath
              }`}
              alt={`Logotipo ${getProductStaticData(productId).name}`}
            />
          ))}
        </div>
        <p className="unserved-region-message__warning-message">
          {getSingularOrPlural(productCartList.length, 'O', 'Os')}{' '}
          {getSingularOrPlural(productCartList.length, 'produto', 'produtos')}{' '}
          {getSingularOrPlural(productCartList.length, 'será', 'serão')}{' '}
          {getSingularOrPlural(productCartList.length, 'retirado', 'retirados')}{' '}
          de seu carrinho
        </p>
      </div>
      <p>
        Veja abaixo os produtos disponíveis em sua região e escolha a modalidade
        desejada:
      </p>
      {availableProductsGroupedByModality.map(productGroup => (
        <ModalityProductGroup
          key={productGroup.id}
          modalityGroup={productGroup}
          extraActionOnClick={() => dispatch(closeModal())}
        />
      ))}
      <Button.Default onClick={() => dispatch(closeModal())}>
        <i className="fa-regular fa-arrow-left" />
        Voltar
      </Button.Default>
    </ScenariosContainer>
  );
}

export default UnservedRegion;
