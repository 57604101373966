import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { ProductComboIndividualInput } from '@pages/offer-page/custom-offer-pages/components/combo-product-input/product-combo-individual-input';
import { ComboTaxTable } from '@pages/offer-page/custom-offer-pages/components/combo-summary-components/combo-tax-table';
import { ComboServiceFormCollapseBox } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-collapse-box';
import { ComboServiceFormContainer } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-container';
import { ComboServiceFormFooterArea } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-footer-area';
import { ComboServiceFormHeader } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-header';
import { ComboServiceFormSectionContainer } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-section-container';
import { ComboServiceFormServiceInformation } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-service-information';

import { ASSETS_CARDS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { ComboServiceFormType } from '@extra-types/combo-service-form-type';
import { IndividualProductFormType } from '@extra-types/product-form-types';
import { isComboFlux } from '@helpers/flux-helpers';
import { createComboModalityFormId } from '@helpers/service-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useSelector } from '@hooks/use-selector';
import { useToastMessageContext } from '@hooks/use-toast-message-context';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

export type IndividualFormInputs = {
  products: { [key: string]: IndividualProductFormType };
};

export function ComboDefaultServiceForm({
  modalityData,
  isFormActive,
  handleNextForm,
}: ComboServiceFormType) {
  const { selectedOffer } = OfferFacade();
  const { sendToastMessage } = useToastMessageContext();
  const { getProductForSaleListByModality } = useComboCartPageContext();

  const { cartPricingData, comboCartPricingData } = useSelector(
    ({ cart }) => cart,
  );

  const formID = createComboModalityFormId(modalityData.serviceGroupId);
  const availableProducts = getProductForSaleListByModality(modalityData.id);
  const productPricedDataList = isComboFlux(selectedOffer)
    ? comboCartPricingData
    : cartPricingData;

  const methods = useForm<IndividualFormInputs>({
    mode: 'onChange',
  });

  const watchProducts = methods.watch('products');

  function validateIfHasAtLeastOneProductFilled() {
    if (watchProducts !== undefined) {
      return Object.entries(watchProducts).some(
        product => product[1].value !== '',
      );
    }
    return false;
  }

  const calcSubTotal = () => {
    if (watchProducts) {
      return Object.entries(watchProducts).reduce((totalValue, product) => {
        if (product[1]) {
          return (
            totalValue + (product[1].amount || 0) * Number(product[1].value)
          );
        }
        return totalValue;
      }, 0);
    }
    return 0;
  };

  const onSubmit: SubmitHandler<IndividualFormInputs> = async () => {
    const isFormValid = await methods.trigger(['products']);

    if (!isFormValid) {
      sendToastMessage({
        variant: 'error',
        message: <strong>Erro ao adicionar produto ao carrinho!</strong>,
        disposeTime: 4000,
        zIndex: 400,
      });
    } else {
      handleNextForm();
    }
  };

  return (
    <FormProvider {...methods}>
      <ComboServiceFormSectionContainer>
        <ComboServiceFormContainer
          id={formID}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <ComboServiceFormHeader
            serviceGroupId={modalityData.serviceGroupId}
            isCollapsed={!isFormActive}
            value={calcSubTotal()}
            showRecurringValueLabel={false}
          />
          <ComboServiceFormServiceInformation isCollapse={!isFormActive}>
            <ModalityInformationHeader>
              <img
                src={`${env.URL_ASSETS + ASSETS_CARDS_PATH}/card-${
                  modalityData.id
                }.png`}
                alt={modalityData.alternativeText}
              />
              <p>{modalityData.salesTitle}</p>
            </ModalityInformationHeader>
          </ComboServiceFormServiceInformation>
          <ComboServiceFormCollapseBox
            isFormActive={isFormActive}
            value={calcSubTotal()}
            showRecurringValueLabel={false}
          >
            <ContentContainer>
              <p>
                Escolha os saldos e os valores mensal de cada. Caso não queira
                um saldo, basta deixar sem valor.
              </p>
              <ServiceProductsContainer>
                {availableProducts.map(product => (
                  <ProductComboIndividualInput
                    key={product.id}
                    productId={product.id}
                  />
                ))}
              </ServiceProductsContainer>
            </ContentContainer>
            <ComboTaxTable
              serviceId={modalityData.serviceGroupId}
              pricingResumeData={
                productPricedDataList?.resumo.servicos[
                  modalityData.serviceGroupId
                ]
              }
            />
          </ComboServiceFormCollapseBox>
        </ComboServiceFormContainer>
        <ComboServiceFormFooterArea
          isCollapsed={!isFormActive}
          extraDisableCondition={
            !validateIfHasAtLeastOneProductFilled() ||
            !methods.formState.isValid
          }
        />
      </ComboServiceFormSectionContainer>
    </FormProvider>
  );
}

const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',

  '> p': {
    fontSize: convertPixelToRem(14),
  },

  'details[open] > summary': {
    paddingBottom: '1rem',
  },

  details: {
    backgroundColor: '$informationLight',
    border: '1px solid $informationLight',
    borderRadius: '8px',

    summary: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      textDecoration: 'none',
      padding: '1rem',

      '.fa-gift': {
        fontSize: '1rem',
      },
    },

    '& > summary': {
      listStyle: 'none',
    },
    '& > summary::-webkit-details-marker': {
      display: 'none',
    },
  },
});

const ServiceProductsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

const ModalityInformationHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  img: {
    maxHeight: '80px',
  },

  p: {
    fontSize: '1.5rem',
    fontWeight: '$bold',
    whiteSpace: 'nowrap',
  },
});
