import { ProductAddonType } from '@extra-types/product-addon-type';
import { AddonsId } from '@helpers/enum/addons-id';
import { ProductsId } from '@helpers/enum/products-id';

import { ASSETS_PRODUCT_ADDONS_PATH } from './assets-config';

export const additionalProducts: Record<number, ProductAddonType[]> = {
  [ProductsId.HCM_CORPORATIVO]: [
    {
      id: AddonsId.CONTROLE_DE_PONTO,
      saleName: 'Controle de ponto',
      saleDescription:
        'Chega de perder tempo. Economize e realize de gestão da jornada de seus colaboradores de forma 100% digital.',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/controle-ponto-addon-icon.png`,
      name: 'Controle de ponto',
      description:
        'Chega de perder tempo. Economize e realize de gestão da jornada de seus colaboradores de forma 100% digital.',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/controle-ponto-addon-icon.png`,
    },
    {
      id: AddonsId.GESTAO_DE_FERIAS_E_FOLGAS,
      saleName: 'Gestão de férias e folgas',
      saleDescription:
        'Adeus, planilhas! Com o módulo de Férias e Folgas, você garante o cumprimento da legislação trabalhista e centraliza as informações em um só lugar.',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/gestao-ferias-folga-addon-icon.png`,
      name: 'Gestão de férias e folgas',
      description:
        'Adeus, planilhas! Com o módulo de Férias e Folgas, você garante o cumprimento da legislação trabalhista e centraliza as informações em um só lugar.',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/gestao-ferias-folga-addon-icon.png`,
    },
    {
      id: AddonsId.GESTAO_ELETRONICA_DE_DOCUMENTOS,
      saleName: 'Gestão eletrônica de documentos',
      saleDescription:
        'Armazene documentos de forma totalmente digital e promova mais eficiência e economia nos processos, além de garantir a integridade das informações.',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/gestao-eletronica-documentos-addon-icon.png`,
      name: 'Gestão eletrônica de documentos',
      description:
        'Armazene documentos de forma totalmente digital e promova mais eficiência e economia nos processos, além de garantir a integridade das informações.',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/gestao-eletronica-documentos-addon-icon.png`,
      value: 10,
      label: 'GB',
    },
    {
      id: AddonsId.HOLERITE,
      saleName: 'Holerite',
      saleDescription:
        'Disponibilize aos seus colaboradores fácil acesso aos seus demonstrativos de pagamento diretamente pela VR Gente.',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/holerite-addon-icon.png`,
      name: 'Holerite',
      description:
        'Disponibilize aos seus colaboradores fácil acesso aos seus demonstrativos de pagamento diretamente pela VR Gente.',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/holerite-addon-icon.png`,
      value: 50,
      label: 'assinaturas',
    },
    {
      id: AddonsId.GESTAO_DE_ESCALAS,
      saleName: 'Gestão de escalas',
      saleDescription:
        'Solução inteligente, segura e integrada ao controle de ponto para realizar a gestão de escalas de forma muito mais prática.',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/gestao-escalas-addon-icon.png`,
      name: 'Gestão de escalas',
      description:
        'Solução inteligente, segura e integrada ao controle de ponto para realizar a gestão de escalas de forma muito mais prática.',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/gestao-escalas-addon-icon.png`,
    },
    {
      id: AddonsId.ADMISSAO,
      saleName: 'Admissão',
      saleDescription:
        'O melhor jeito de receber e armazenar a documentação admissional no seu RH.',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/admissao-addon-icon.png`,
      name: 'Admissão',
      description:
        'O melhor jeito de receber e armazenar a documentação admissional no seu RH.',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/admissao-addon-icon.png`,
    },
    {
      id: AddonsId.PONTO_POR_RECONHECIMENTO_FACIAL,
      saleName: 'Ponto por reconhecimento facial',
      saleDescription:
        'A tecnologia mais moderna para registro de ponto, que de forma segura e precisa identifica o colaborador através da biometria facial. Ao contratá-la, você ganha o registro de ponto pelo whatsapp.',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/ponto-reconhecimento-facial-addon-icon.png`,
      name: 'Ponto por reconhecimento facial',
      description:
        'A tecnologia mais moderna para registro de ponto, que de forma segura e precisa identifica o colaborador através da biometria facial. Ao contratá-la, você ganha o registro de ponto pelo whatsapp.',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/ponto-reconhecimento-facial-addon-icon.png`,
    },
    {
      id: AddonsId.PONTO_POR_WHATSAPP,
      saleName: 'Ponto por whatsapp',
      saleDescription:
        'Para registrar o ponto basta enviar uma mensagem para o nosso contato no WhatsApp, e pronto! Ao contratá-la, você ganha o registro por Reconhecimento Facial.',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/ponto-whatsapp-addon-icon.png`,
      name: 'Ponto por whatsapp',
      description:
        'Para registrar o ponto basta enviar uma mensagem para o nosso contato no WhatsApp, e pronto! Ao contratá-la, você ganha o registro por Reconhecimento Facial.',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/ponto-whatsapp-addon-icon.png`,
    },
    {
      id: AddonsId.VALIDAMAIS,
      saleName: 'Validamais',
      saleDescription:
        'Com o Validamais você pode consultar antecedentes nas bases de dados públicas, obtendo as informações referentes aos dados Penais e Criminais; Legais e de identidade.',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/validamais-addon-icon.png`,
      name: 'Validamais: Proteção a fraudes e consulta de cadastro',
      description:
        'Com o Validamais você pode consultar antecedentes nas bases de dados públicas, obtendo as informações referentes aos dados Penais e Criminais; Legais e de identidade.',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/validamais-addon-icon.png`,
      value: 2,
      label: 'consultas',
    },
    {
      id: AddonsId.UNIVERSO_DO_TEMPO,
      saleName: 'Universo do tempo',
      saleDescription:
        'Transforme o controle de ponto em um divertido jogo entre os colaboradores, com ações gamificadas que resultam em colaboradores mais engajados, produtivos, focados e determinados. Adicione a extensão e divirta-se!',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/universo-tempo-addon-icon.png`,
      name: 'Universo do tempo',
      description:
        'Transforme o controle de ponto em um divertido jogo entre os colaboradores, com ações gamificadas que resultam em colaboradores mais engajados, produtivos, focados e determinados. Adicione a extensão e divirta-se!',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/universo-tempo-addon-icon.png`,
    },
    {
      id: AddonsId.COMPRE_VR_BENEFICIOS,
      saleName: 'Compre VR',
      saleDescription:
        'Clientes Pontomais que comprarem VR ganham pontos para trocar por soluções de saúde e segurança alimentar para sua empresa. Compre agora!',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/compre-vr-addon-icon.png`,
      name: 'Compre VR',
      description:
        'Clientes Pontomais que comprarem VR ganham pontos para trocar por soluções de saúde e segurança alimentar para sua empresa. Compre agora!',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/compre-vr-addon-icon.png`,
    },
    {
      id: AddonsId.IMPLANTACAO_INCLUSO,
      saleName: 'Implantação gratuita',
      saleDescription: '',
      saleLogoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/implantacao-incluso-addon-icon.png`,
      name: 'Implantação gratuita',
      description: '',
      logoPath: `${ASSETS_PRODUCT_ADDONS_PATH}/implantacao-incluso-addon-icon.png`,
    },
  ],
};

const employeesAmount = [
  {
    value: 10,
    label: '1-10',
  },
  {
    value: 25,
    label: '11-25',
  },
  {
    value: 50,
    label: '26-50',
  },
  {
    value: 100,
    label: '51-100',
  },
  {
    value: 101,
    label: 'Acima de 100',
  },
];

export const HCMOptions: Record<
  number | string,
  {
    value: number;
    label: string;
  }[]
> = {
  [ProductsId.HCM_ESSENCIAL]: employeesAmount.filter(
    amount => amount.value === 10,
  ),
  [ProductsId.HCM_GERENCIAL]: employeesAmount,
  [ProductsId.HCM_PROFISSIONAL]: employeesAmount,
  [ProductsId.HCM_CORPORATIVO]: employeesAmount,
  default: employeesAmount,
};

export const HCMFAQJson = [
  {
    title: 'Como funciona um sistema de ponto digital?',
    description:
      'Nada mais é que um sistema de controle de ponto com funcionamento na nuvem. Nele, é possível fazer o registro de ponto, toda a gestão de horas, espelho de ponto e demais obrigações digitalmente.',
  },
  {
    title: 'Quais são os tipos de ponto eletrônico?',
    description:
      'Existem 6 tipos de ponto digital dentro do sistema Pontomais: registro simples, reconhecimento facial, ponto por WhatsApp, registro offline, ponto por QR Code e Happy, com registros em massa.',
  },
  {
    title: 'O que diz a CLT sobre o ponto digital?',
    description:
      'A Portaria 671 é a lei responsável por permitir o registro do ponto digital. O Pontomais salva as informações de jornada de trabalho e os registros de ponto na nuvem, como previsto e exigido pela lei.',
  },
  {
    title: 'É obrigatório ter ponto eletrônico?',
    description:
      'Conforme a legislação, é obrigatório para empresas com mais de 20 funcionários. Porém, negócios menores também podem utilizar o ponto digital, resguardando os direitos de todos os envolvidos.',
  },
  {
    title: 'Como funciona a folha de ponto digital?',
    description:
      'A folha de ponto digital é uma versão automatizada do documento. Com um sistema de ponto online é possível gerar o arquivo em poucos cliques, com dados em tempo real para a gestão.',
  },
  {
    title: 'Quais são os tipos de escalas de trabalho?',
    description:
      'Existem pelo menos 8 tipos de escalas de trabalho regulamentas pela CLT. O sistema Pontomais tem 4 modelos pré-cadastrados: 6×1, 5×1, 4×2 e 12×36, mas também é possível personalizar uma escala para o seu negócio.',
  },
  {
    title: 'O que é um sistema GED(Gestão Eletronica de Documentos)?',
    description:
      'É a gestão eletrônica de documentos feita em um sistema de organização e armazenamento digital. É a opção mais ágil e segura de guardar as informações de uma empresa, desde os documentos de admissão, até o espelho de ponto e holerite dos funcionários.',
  },
  {
    title:
      'Qual tipo de empresa precisa utilizar o GED(Gestão Eletronica de Documentos)?',
    description:
      'Qualquer tipo de empresa, pequena, média ou grande, pode usar o GED(Gestão Eletronica de Documentos). É possível implementá-lo na empresa toda ou em um setor específico, como é o caso das áreas de Recursos Humanos e Departamento Pessoal.',
  },
  {
    title: 'Pode enviar o holerite online para o trabalhador?',
    description:
      'O Art. 464 da CLT especifica que a empresa pode realizar a entrega do holerite online. O sistema da VR Gente faz a distribuição em massa dos holerites, com assinatura eletrônica do documento no próprio aplicativo. ',
  },
];

export const MIN_LIFES_ESSENTIAL_PLAN = 10;
export const MAX_LIFES_HCM = 100;
