/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { CSS, VariantProps } from '@stitches/react';
import VMasker from 'vanilla-masker';

import { Button } from '@elements/button';
import SearchTips from '@elements/search-tips';

import {
  CELLPHONE_LENGTH,
  MASK_MONEY_CONFIG,
  MAX_NUMBER_INPUT_VALUE,
} from '@config/input-config';
import { mergeRefs } from '@helpers/function-helpers';
import {
  CELLPHONE_MASK,
  NOT_NUMBER_REGEX,
  TELEPHONE_MASK,
} from '@helpers/string-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useFAQContext } from '@hooks/use-faq-context';

import { FAQArticles } from '@api/models/response/faq-articles-list-response';

import { config } from '@src/themes';

import { InputStyled, Label, SearchTipsContainer } from './styles';

interface InputDefaultProps
  extends InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof InputStyled> {
  name: string;
  css?: CSS<typeof config>;
  textAlign?: 'left' | 'center';
  width?: string;
  label?: string;
  errorMessage?: string;
  maskMoney?: boolean;
  maskPhone?: boolean;
  customMask?: string;
  fontSize?: number;
}

interface SearchInputProps
  extends Omit<
    InputDefaultProps,
    'customMask' | 'maskPhone' | 'maskMoney' | 'errorMessage'
  > {
  tips?: Record<string, FAQArticles[]>;
}
const applyMask = (
  maskMoney: boolean,
  maskPhone: boolean,
  customMask: string,
  value: string,
) => {
  if (!value) return value;
  let maskedValue = value;

  if (maskMoney) {
    maskedValue = VMasker.toMoney(value, MASK_MONEY_CONFIG);
  } else if (maskPhone) {
    const clearedValue = value.replace(NOT_NUMBER_REGEX, '');
    const pattern =
      clearedValue.length <= CELLPHONE_LENGTH ? TELEPHONE_MASK : CELLPHONE_MASK;
    maskedValue = VMasker.toPattern(value, pattern);
  } else if (customMask) {
    maskedValue = VMasker.toPattern(value, customMask);
  }

  return maskedValue;
};

const DefaultInput = React.forwardRef<HTMLInputElement, InputDefaultProps>(
  (
    {
      name,
      label,
      css,
      textAlign,
      errorMessage,
      disabled,
      maskMoney = false,
      maskPhone = false,
      width = '7.75rem',
      customMask = '',
      fontSize = 16,
      onChange,
      ...props
    }: InputDefaultProps,
    ref,
  ) => {
    const reference = useRef<HTMLInputElement>(null);

    const handleOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = applyMask(
          maskMoney,
          maskPhone,
          customMask,
          e.target.value,
        );

        if (onChange) {
          onChange(e);
        }
      },
      [onChange, applyMask, customMask],
    );

    useEffect(() => {
      if (typeof reference.current?.value === 'string') {
        const maskedValue = applyMask(
          maskMoney,
          maskPhone,
          customMask,
          reference.current.value,
        );

        if (reference.current.value !== maskedValue) {
          reference.current.value = maskedValue;
          if (onChange) {
            onChange({
              target: reference.current,
            } as ChangeEvent<HTMLInputElement>);
          }
        }
      }
    }, [applyMask, onChange]);

    return (
      <InputStyled
        css={{
          $$width: width,
          $$fontSize: convertPixelToRem(fontSize),
          ...css,
        }}
        hasError={!!errorMessage}
        textAlign={textAlign}
        hasValue={!!reference.current?.value}
        isDisabled={disabled}
      >
        <div className="input-styled__input-holder">
          {label && <Label>{label}</Label>}
          <div className="input-styled__input">
            <fieldset disabled={disabled}>
              <input
                name={name}
                ref={mergeRefs([ref, reference])}
                onChange={handleOnChange}
                {...props}
              />
            </fieldset>
          </div>
          {errorMessage && <span>{errorMessage}</span>}
        </div>
      </InputStyled>
    );
  },
);

const NumericInput = React.forwardRef<HTMLInputElement, InputDefaultProps>(
  (
    {
      name,
      label,
      errorMessage,
      width = '7.75rem',
      disabled,
      onChange,
      textAlign = 'left',
      maskMoney = false,
      maskPhone = false,
      customMask = '',
      fontSize = 16,
      css,
      ...props
    }: InputDefaultProps,
    ref,
  ) => {
    const reference = useRef<HTMLInputElement>(null);

    function toggleButton(actionName: string) {
      if (reference.current) {
        if (actionName === 'stepUp') {
          reference.current.value = `${
            Number(reference.current.value ?? 0) + 1
          }`;
        } else if (reference.current.value !== '0') {
          reference.current.value =
            reference.current.value !== '1'
              ? `${Number(reference.current.value) - 1}`
              : '';
        }
        if (onChange) {
          onChange({
            target: reference.current,
          } as ChangeEvent<HTMLInputElement>);
        }
      }
    }

    const handleOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = applyMask(
          maskMoney,
          maskPhone,
          customMask,
          e.target.value,
        );

        if (onChange) {
          onChange(e);
        }
      },
      [onChange, applyMask, customMask],
    );

    const isMinusButtonDisabled =
      disabled ||
      reference.current?.value === '0' ||
      reference.current?.value === '';

    const isPlusButtonDisabled =
      disabled ||
      !!(
        reference.current &&
        Number(reference.current.value ?? 0) === MAX_NUMBER_INPUT_VALUE
      );

    return (
      <InputStyled
        css={{
          $$width: width,
          $$fontSize: convertPixelToRem(fontSize),
          ...css,
        }}
        hasValue={!!reference.current?.value}
        textAlign={textAlign}
        hasError={!!errorMessage}
        isDisabled={disabled}
      >
        <div className="input-styled__input-holder input-styled__input-holder--numeric">
          <Button.Default
            className="minus-button"
            disabled={isMinusButtonDisabled}
            tabIndex={-1}
            onClick={() => toggleButton('stepDown')}
          >
            <i className="fa-solid fa-minus" />
          </Button.Default>
          <div>
            {label && <Label>{label}</Label>}
            <div className="input-styled__input">
              <fieldset disabled={disabled}>
                <input
                  name={name}
                  ref={mergeRefs([ref, reference])}
                  type="number"
                  min="0"
                  step="1"
                  onChange={handleOnChange}
                  inputMode="numeric"
                  {...props}
                />
              </fieldset>
            </div>
            {errorMessage && <span>{errorMessage}</span>}
          </div>
          <Button.Default
            className="plus-button"
            disabled={isPlusButtonDisabled}
            tabIndex={-1}
            onClick={() => toggleButton('stepUp')}
          >
            <i className="fa-solid fa-plus" />
          </Button.Default>
        </div>
      </InputStyled>
    );
  },
);

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      name,
      css,
      textAlign,
      tips,
      disabled,
      width = '7.75rem',
      fontSize = 16,
      onChange,
      ...props
    }: SearchInputProps,
    ref,
  ) => {
    const reference = useRef<HTMLInputElement>(null);
    const [tipsTabOpen, setTipsTabOpen] = useState(false);
    const { searchTypedValue } = useFAQContext();

    useEffect(() => {
      if (onChange) {
        onChange({
          target: reference.current,
        } as ChangeEvent<HTMLInputElement>);
      }
    }, [onChange]);

    useEffect(() => {
      setTipsTabOpen(!!reference.current?.value);
    });

    const resetInputValue = () => {
      if (reference.current) {
        reference.current.value = '';
        if (onChange) {
          onChange({
            target: reference.current,
          } as ChangeEvent<HTMLInputElement>);
        }
      }
    };

    const isThereAnyTip = () => {
      if (tips) {
        return Object.values(tips).some(tip => tip.length > 1);
      }
      return false;
    };

    return (
      <InputStyled
        css={{
          $$width: width,
          $$fontSize: convertPixelToRem(fontSize),
          ...css,
        }}
        textAlign={textAlign}
        hasValue={!!reference.current?.value}
        isDisabled={disabled}
      >
        <div className="input-styled__input-holder input-styled__input-holder--search">
          <div className="input-styled__input input-styled__input--search">
            <fieldset disabled={disabled}>
              <input
                name={name}
                ref={mergeRefs([ref, reference])}
                onChange={onChange}
                {...props}
              />
            </fieldset>
          </div>
          {reference.current?.value && tips && tipsTabOpen && (
            <SearchTipsContainer>
              <div>
                {isThereAnyTip() && reference.current.value.length >= 3 ? (
                  <button
                    type="button"
                    className="search-all-faq"
                    onClick={() => {
                      if (reference.current?.value)
                        searchTypedValue(reference.current.value);
                      resetInputValue();
                    }}
                  >
                    Pesquisar &quot;<strong>{reference.current.value}</strong>
                    &quot; em todo o FAQ
                  </button>
                ) : (
                  <p>
                    Não foi possível encontrar dúvidas relacionadas a:&nbsp;
                    <strong>{reference.current?.value}</strong>
                  </p>
                )}
                {Object.entries(tips).map(([categoryTitle, tip]) => (
                  <SearchTips
                    key={categoryTitle}
                    categoryTitle={categoryTitle}
                    tip={tip}
                    resetInputValue={resetInputValue}
                  />
                ))}
              </div>
            </SearchTipsContainer>
          )}
        </div>
      </InputStyled>
    );
  },
);

export const Input = {
  Default: DefaultInput,
  Numeric: NumericInput,
  Search: SearchInput,
};
