import { styled } from '@src/themes';

export const CartSummaryContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-start',

  background: '$white',
  width: '100%',
  borderRadius: '6px',
  padding: '1rem 1rem 1.5rem',
  marginBottom: '1.5rem',
  boxShadow: '0px 0px 0.5rem #00000021',

  '@media only screen and (max-width: 1114px)': {
    boxShadow: 'none',
    padding: '0 1.25rem 0.5rem',
    marginBottom: '0.5rem',
    borderRadius: 0,
  },
});

export const CartSummaryStaticMessageContainer = styled('div', {
  marginTop: '1.5rem',
  paddingBottom: '1.25rem',
});
