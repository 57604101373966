import {
  MODALITIES,
  MODALITY_ERROR_MESSAGE,
  MODALITY_ID_NOT_FOUND,
  MODALITY_PREFIX,
} from '@config/modality-config';
import { ModalityType } from '@extra-types/modality-type';
import { ProductType } from '@extra-types/product-type';

import { OrderSteps } from './enum/order-steps';
import { ServiceGroupId } from './enum/service-group-id';

export function createFallbackGroup(): ModalityType {
  return {
    id: MODALITY_ID_NOT_FOUND,
    pageRoute: '/not-found',
    shelfInformation: {
      title: MODALITY_ERROR_MESSAGE,
      illustration: 'not-found',
      description: MODALITY_ERROR_MESSAGE,
    },
    colorPrefixToken: 'primary',
    keyId: 'modalidade-not-found',
    title: MODALITY_ERROR_MESSAGE,
    subtitle: MODALITY_ERROR_MESSAGE,
    description: MODALITY_ERROR_MESSAGE,
    salesTitle: MODALITY_ERROR_MESSAGE,
    salesText: MODALITY_ERROR_MESSAGE,
    isWalletGroupCard: false,
    isFeatured: false,
    alternativeText: MODALITY_ERROR_MESSAGE,
    showLegalFlag: false,
    displayOrder: 0,
    stepOrderLimit: OrderSteps.CART,
    singleModalityProductsShelfView: false,
    serviceGroupId: ServiceGroupId.UNKNOWN,
    canBeOrderedSolo: false,
  };
}

export function getModalityStaticData(id: number) {
  return MODALITIES[id] || createFallbackGroup();
}

export function getModalityData(
  id: number | undefined,
  modalityList: ModalityType[],
): ModalityType {
  return (
    modalityList.find(modality => modality.id === id) ?? createFallbackGroup()
  );
}

export function getModalityListByProductList<T extends ProductType>(
  productList: T[],
): number[] {
  const selectedGroupsList: number[] = [];

  productList.forEach(product => {
    selectedGroupsList.push(product.modalityId);
  });

  return removeRepeatedItemsFromList(selectedGroupsList);
}

export function removeRepeatedItemsFromList<T>(list: Array<T>): Array<T> {
  return list.filter((item, i) => list.indexOf(item) === i);
}

export function filterModalityListBymodalityIdList(
  modalityList: ModalityType[],
  modalityIdList: number[],
) {
  return modalityList.filter(modality => modalityIdList.includes(modality.id));
}

export function getModalityByPathPage(
  modalityList: ModalityType[],
  pathPage: string,
) {
  return Object.values(modalityList).find(
    modality => modality.pageRoute === pathPage,
  );
}

export function createSortedGroupsListByNewFlag(
  groupIdList: number[],
): ModalityType[] {
  const groupWithNewFlag: ModalityType[] = [];
  const groupWithoutNewFlag: ModalityType[] = [];

  groupIdList.forEach(groupId => {
    const groupData = getModalityData(groupId, Object.values(MODALITIES));
    if (groupData.isFeatured) {
      groupWithNewFlag.push(groupData);
    } else {
      groupWithoutNewFlag.push(groupData);
    }
  });

  return [...groupWithNewFlag, ...groupWithoutNewFlag];
}

export function sortModalitiesByDisplayOrder(
  modalityList: ModalityType[],
): ModalityType[] {
  function compare(a: ModalityType, b: ModalityType) {
    if (a.displayOrder < b.displayOrder) return -1;
    if (a.displayOrder > b.displayOrder) return 1;
    return 0;
  }

  return [...modalityList].sort(compare);
}

export function removeModalityPrefix(label: string) {
  return label.split(MODALITY_PREFIX)[1];
}

export function filterProductListOfUnknownModalities(
  productList: ProductType[],
  knowModalities: ModalityType[],
) {
  return productList.filter(product =>
    knowModalities.some(modality => modality.id === product.modalityId),
  );
}
