import { useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import Modal, { ModalVariants } from '@components/modal';
import { PageTitle } from '@components/page-title';
import { Skeleton } from '@components/skeletons';
import { PageContainer } from '@elements/page-container';
import { PageSection } from '@elements/page-section';

import { ModalitiesId } from '@helpers/enum/modalities-id';
import { getModalityByPathPage } from '@helpers/modality-helpers';

import { useAnchorSmooth } from '@hooks/use-anchor-smooth';
import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { handleSelectModality } from '@store/slices/products-slice';

import { HCMProductsPage } from './modality-pages/hcm-products-page';
import { IndividualProductsPage } from './modality-pages/individual-products-page';
import { WalletProductsPage } from './modality-pages/wallet-products-page';

const PATH_PREFIX = '/modalidade/';

export function ModalityPage() {
  const location = useLocation();
  const { modalityPath } = useParams();
  const dispatch = useDispatch();
  const { isLoading: isProductsLoading, modalityList } = useSelector(
    ({ products }) => products,
  );
  const isApplicationLoading = useSelector(
    ({ application }) => application.isLoading,
  );
  useAnchorSmooth({ watchVariable: isApplicationLoading || isProductsLoading });

  const modality = getModalityByPathPage(
    modalityList,
    PATH_PREFIX + modalityPath,
  );

  useEffect(() => {
    if (modality) {
      dispatch(handleSelectModality(modality));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isApplicationLoading || isProductsLoading) {
    return (
      <PageContainer>
        <PageSection>
          <Skeleton.SectionContainer>
            <Skeleton.Title />
            <Skeleton.ProductFormContainer>
              <Skeleton.ProductForm />
              <Skeleton.ProductForm />
              <Skeleton.ProductForm />
              <Skeleton.ProductForm />
            </Skeleton.ProductFormContainer>
            <Skeleton.Title />
            <Skeleton.BigBox />
          </Skeleton.SectionContainer>
        </PageSection>
      </PageContainer>
    );
  }

  if (!modality) {
    return (
      <Navigate
        to={{
          pathname: '/not-found',
          search: location.search,
        }}
        state={{ from: location.pathname }}
      />
    );
  }

  if (modality.id === ModalitiesId.HCM) {
    return (
      <PageContainer>
        <HCMProductsPage modalityId={modality.id} />
      </PageContainer>
    );
  }

  return (
    <>
      <PageTitle title={modality.shelfInformation.title} />
      <PageContainer>
        {modality.isWalletGroupCard ? (
          <WalletProductsPage modalityId={modality.id} />
        ) : (
          <IndividualProductsPage modalityId={modality.id} />
        )}
      </PageContainer>
      <Modal>
        <ModalVariants.Confirmation />
      </Modal>
    </>
  );
}
