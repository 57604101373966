import { Controller, FormProvider, useForm } from 'react-hook-form';

import { Input } from '@components/input';
import { FAQ } from '@compositions/faq';
import { FAQContactList } from '@compositions/faq-contact-list';
import { PageContainer } from '@elements/page-container';
import { PageSection } from '@elements/page-section';

import { env } from '@config/env';

import { useFAQContext } from '@hooks/use-faq-context';

import { styled } from '@src/themes';

export function FAQPage() {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      search: '',
    },
  });
  const { searchTypedValue, handleSearchChange } = useFAQContext();

  return (
    <PageContainer>
      <FormProvider {...methods}>
        <PageSection
          variant="secondary"
          css={{ backgroundColor: '$faqBackgroundColor' }}
        >
          <FAQPageTitleContainer>
            <img
              src={`${env.URL_ASSETS}/images/others/help-image.png`}
              alt="Imagem de um avatar com um ponto de interrogação"
            />
            <div className="addornment" />
            <div className="faq-header-holder">
              <h1>Perguntas Frequentes</h1>
              <h2>
                Abaixo listamos perguntas e respostas sobre a VR e seus
                benefícios para ajudar a tirar sua(s) dúvida(s).
              </h2>

              <Controller
                control={methods.control}
                name="search"
                render={({ field: { value, ref, onChange } }) => (
                  <Input.Search
                    name="search"
                    type="search"
                    placeholder="Digite sua dúvida aqui"
                    tips={handleSearchChange(value)}
                    value={value}
                    ref={ref}
                    width="100%"
                    onKeyDown={event => {
                      if (event.key === 'Enter' && value.length >= 3) {
                        searchTypedValue(value);
                        methods.resetField('search');
                      }
                    }}
                    onChange={e => {
                      handleSearchChange(e.target.value);
                      onChange(e);
                    }}
                  />
                )}
              />
            </div>
          </FAQPageTitleContainer>
        </PageSection>
        <PageSection dark>
          <FAQ />
        </PageSection>
        <PageSection id="faq-contact-section" dark>
          <FAQContactList />
        </PageSection>
      </FormProvider>
    </PageContainer>
  );
}

export const FAQPageTitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  padding: '3rem 0 2rem',
  position: 'relative',
  textAlign: 'center',

  'h1, h2': {
    position: 'relative',
    color: '$white',

    zIndex: 2,
  },

  h1: {
    fontSize: '1.25rem',
    fontWeight: 700,
  },

  h2: {
    fontSize: '0.875rem',
    fontWeight: 500,
    margin: '0.5rem 0',
  },

  '.faq-header-holder': {
    maxWidth: '43%',
    lineHeight: '1.5',
  },

  'img, .addornment': {
    position: 'absolute',
    opacity: 0.5,
    zIndex: 0,
  },

  img: {
    maxWidth: '11.9375rem',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
  },

  '.addornment': {
    right: 0,
    bottom: 0,
    width: '26.1875rem',
    height: '14.1875rem',
    borderRadius: '6.25rem 0rem 6.25rem 0rem',
    backgroundColor: '$primaryLight',
  },

  '@deviceLg': {
    paddingTop: '2.5rem',
  },

  '@deviceMd': {
    paddingTop: '2rem',

    '.faq-header-holder': {
      maxWidth: '65%',
    },

    '.addornment': {
      width: '20rem',
      height: '12.37463rem',
      right: '-2rem',
      borderRadius: '6.25rem 0rem 0rem 0rem',
    },
  },

  '@deviceSm': {
    'img, .addornment': {
      display: 'none',
    },

    '.faq-header-holder': {
      maxWidth: '100%',
      textAlign: 'left',
    },
  },
});
