import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { styled } from '@src/themes';

interface ComboServiceFormValueProps {
  value: number;
  showRecurringValueLabel: boolean;
  size?: 'md' | 'sm';
  isVisible?: boolean;
}

const recurringLabel = '/mês';

export function ComboServiceFormValue({
  value,
  showRecurringValueLabel,
  size = 'md',
  isVisible = true,
}: ComboServiceFormValueProps) {
  if (!isVisible) {
    return null;
  }

  return (
    <ComboServiceFormValueContainer size={size}>{`${formatValueToCurrency(
      value,
    )}${
      showRecurringValueLabel ? recurringLabel : ''
    }`}</ComboServiceFormValueContainer>
  );
}

const ComboServiceFormValueContainer = styled('span', {
  color: '$primaryDark',
  fontWeight: '$bold',

  variants: {
    size: {
      md: {
        fontSize: '1rem',
      },
      sm: {
        fontSize: '0.75rem',
      },
    },
  },
});
