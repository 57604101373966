import { ModalityType } from '@extra-types/modality-type';
import { PricingType } from '@extra-types/pricing-types';
import {
  TableAdapterType,
  TableBenefictsType,
  TaxesDetailsType,
} from '@extra-types/table-types';
import { createPricingBonusList } from '@helpers/cart-helpers';

export const purchaseTableAdapter = (
  data: PricingType,
  cartModalityData: ModalityType,
): TableAdapterType => {
  const { detalhe, resumo } = data;

  const totalTaxEmissaoCartao: number[] = [0];
  const totalTaxSolicCred: number[] = [0];

  const cards = detalhe.produtos?.map(p => p.quantidade);
  const totalCards = cards?.reduce((total, amount) => total + amount);
  const totalProducts = detalhe.produtos.length;
  const totalTax = resumo.valorTotalTaxas;
  const totalBeneficts = data.resumo.valorTotalBeneficios;

  const deliveryFee =
    (resumo.servicos[cartModalityData.serviceGroupId].lancamentos &&
      resumo.servicos[cartModalityData.serviceGroupId].lancamentos.find(value =>
        value.nome.includes('Tarifa de entrega'),
      )?.valorTotal) ||
    0;

  const benefictsInfoArray: TableBenefictsType[] = [];

  detalhe.produtos.forEach(productDetail => {
    benefictsInfoArray.push({
      productCode: productDetail.codigoProduto,
      cardQuantity: productDetail.quantidade,
      monthlyBenefict: productDetail.valorMensal,
      totalBenefict: productDetail.valorProduto,
    });
  });

  const lancamentosArray = detalhe.produtos.map(p => p.lancamentos);

  const taxEmissaoCartao = lancamentosArray.map(values =>
    values.filter(tax => tax.nome === 'Tarifa de emissão de cartões'),
  );

  const taxSolicCred = lancamentosArray.map(values =>
    values.filter(tax => tax.nome === 'Tarifa de crédito'),
  );

  taxEmissaoCartao.forEach(p =>
    p.forEach(tax => totalTaxEmissaoCartao.push(tax.valorTotal)),
  );

  taxSolicCred.forEach(p =>
    p.forEach(tax => totalTaxSolicCred.push(tax.valorTotal)),
  );

  const bonus = createPricingBonusList(data);

  const taxesInfosArray: TaxesDetailsType[] = [
    {
      title: 'Tarifa: Emissão de Cartão Físico*',
      subtitle: '(*cobrada apenas 1x por cartão físico)',
      value: totalTaxEmissaoCartao.reduce((total, amount) => total + amount),
    },
    {
      title: 'Tarifa: Solicitação de Crédito*',
      subtitle: '(*cobrada mensalmente e/ou a cada solicitação)',
      value: totalTaxSolicCred.reduce((total, amount) => total + amount),
    },
  ];

  const totalValue = resumo.valorTotal;

  return {
    beneficts: benefictsInfoArray,
    totalBeneficts,
    bonus,
    totalProducts,
    totalCards,
    deliveryFee,
    totalTax,
    totalValue,
    taxesDetails: taxesInfosArray,
  };
};
