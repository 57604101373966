import { useLocation, useNavigate } from 'react-router-dom';

import { Cart } from '@compositions/cart';
import { HelpButton } from '@elements/help-button';
import { Logo } from '@elements/logo';
import { PageSectionContainer } from '@elements/page-section';

import { HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from '@config/styles-config';
import { verifyBackButtonPageBlackList } from '@helpers/page-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useHistoryStack } from '@hooks/use-history-stack';
import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

export function HeaderJVCEF() {
  const location = useLocation();
  const navigate = useNavigate();
  const { initialPage } = useSelector(({ application }) => application);
  const { handlePrevPage } = useHistoryStack();

  return (
    <HeaderContent as="header" variant="header">
      <div className="page-section-container__holder">
        <div className="holder__left-content">
          {verifyBackButtonPageBlackList({
            currentPage: location.pathname,
            initialPage,
          }) && (
            <MainPageButton
              title="Voltar para a página anterior"
              onClick={() =>
                navigate({
                  pathname: handlePrevPage(),
                  search: location.search,
                })
              }
            >
              <i className="fa-solid fa-chevron-left" />
            </MainPageButton>
          )}
          <Logo.Emissor />
        </div>
        <div className="holder__right-content">
          <HelpButton />
          <Cart />
        </div>
      </div>
    </HeaderContent>
  );
}

const HeaderContent = styled(PageSectionContainer, {
  zIndex: '$5',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,

  '.page-section-container__holder': {
    position: 'relative',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0.5rem',

    width: '100%',
    height: convertPixelToRem(HEADER_HEIGHT),

    '.holder__left-content': {
      zIndex: '$4',
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',
    },

    '.holder__right-content': {
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',

      '@deviceSm': {
        gap: '0.5rem',
      },
    },

    '@deviceSm': {
      height: convertPixelToRem(HEADER_MOBILE_HEIGHT),

      '.holder__left-content': {
        gap: '0.75rem',
      },
    },
  },
});

const MainPageButton = styled('button', {
  background: 'transparent',

  color: '$white',
  fontSize: '1.5rem',
  transition: 'color 0.4s ease',

  '&:hover': {
    color: '$secondaryPure',
  },
});
