/* eslint-disable no-param-reassign */

import {
  AnyAction,
  AsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { KNOWN_ERRORS } from '@config/errors-config';
import { sendEventToAnalytics } from '@helpers/analytics-helpers';
import {
  ApiErrorsMessages,
  ApiErrorsModalTitle,
} from '@helpers/enum/api-error-messages';
import { TrackCategories } from '@helpers/enum/track-categories';
import { getMessageError } from '@helpers/error-helpers';
import { unmountQueryParams } from '@helpers/query-string-helpers';

import SpecificsApiErrors from '@errors/specifics-api-errors';
import ApplicationError from '@errors/types/application-error';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

export type ActionCloseType =
  | 'close'
  | 'reloadPage'
  | 'reloadPageToDefaultChannel'
  | 'redirectToEmissorWebsite'
  | 'redirectToInitialPage'
  | 'redirectToInitialPageAndRemoveSpecificQueryParamFromLocation';

export type WarningModalVariant = 'warning' | 'error' | 'maintenance';

export type WarningModalType = {
  isVisible: boolean;
  variant?: WarningModalVariant;
  title: string;
  content?: string;
  buttonOrder?: 'normal' | 'reverse';
  buttonAction?: {
    label: string;
    action: () => void;
  };
  closeAction?: {
    label: string;
    action: ActionCloseType;
    queryStringToRemove?: string;
  };
};

export const initialState = {
  isVisible: false,
  variant: 'warning',
  title: '',
  content: '',
  buttonOrder: 'normal',
  buttonAction: undefined,
  closeAction: {
    label: 'Fechar',
    action: 'close',
    queryStringToRemove: undefined,
  },
} as WarningModalType;

const isRejectedAction = (action: AnyAction): action is RejectedAction => {
  if (KNOWN_ERRORS.some(knownError => action.payload instanceof knownError)) {
    return false;
  }
  return action.type.includes('fetch') && action.type.endsWith('/rejected');
};

const warningModalSlice = createSlice({
  name: 'warningModal',
  initialState,
  reducers: {
    handleWarningModalContent: (
      state,
      action: PayloadAction<WarningModalType>,
    ) => {
      state.isVisible = action.payload.isVisible;
      state.variant = action.payload.variant || state.variant;
      state.title = action.payload.title;
      state.content = action.payload.content;
      state.buttonOrder = action.payload.buttonOrder || 'normal';
      state.closeAction = action.payload.closeAction || {
        label: 'Fechar',
        action: 'close',
      };
      state.buttonAction = action.payload.buttonAction || undefined;
    },
    closeWarningModal: state => {
      state.isVisible = false;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.buttonAction = undefined;
      let messageModalExtraData: {
        content: string;
        closeAction?: {
          label: string;
          action: ActionCloseType;
        };
      } = {
        content: ApiErrorsMessages.DEFAULT,
      };

      if (action.payload instanceof ApplicationError) {
        let title = ApiErrorsModalTitle.DEFAULT;
        if (action.payload.code === SpecificsApiErrors.CHANNEL_NOT_FOUND.code) {
          const href = unmountQueryParams(window.location.href);

          sendEventToAnalytics(
            TrackCategories.DEFAULT,
            'Canal inválido',
            href.canal,
          );

          messageModalExtraData = {
            content: ApiErrorsMessages.CHANNEL_NOT_FOUND,
            closeAction: {
              label: 'Fechar',
              action: 'reloadPageToDefaultChannel',
            },
          };
        } else {
          messageModalExtraData = {
            ...messageModalExtraData,
            content: getMessageError(action.payload),
          };
        }

        if (action.payload.code === SpecificsApiErrors.MAINTENANCE.code) {
          title = ApiErrorsModalTitle.MAINTENANCE;
          state.variant = 'maintenance';
          messageModalExtraData = {
            ...messageModalExtraData,
            closeAction: {
              label: 'OK',
              action: 'redirectToEmissorWebsite',
            },
          };
        }

        if (action.payload.code === SpecificsApiErrors.INFRASTRUCTURE.code) {
          messageModalExtraData = {
            ...messageModalExtraData,
            closeAction: {
              label: 'Fechar',
              action: 'reloadPage',
            },
          };
        }

        state.title = title;
        state.isVisible = true;
        state.content = messageModalExtraData.content;

        if (messageModalExtraData.closeAction) {
          state.closeAction = {
            label: messageModalExtraData.closeAction.label,
            action: messageModalExtraData.closeAction.action,
          };
        }
      } else {
        state.isVisible = true;
        state.title = ApiErrorsModalTitle.DEFAULT;
        state.content = ApiErrorsMessages.DEFAULT;
      }
    });
  },
});

export const { handleWarningModalContent, closeWarningModal } =
  warningModalSlice.actions;

export default warningModalSlice.reducer;
