import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ProductNameText } from '@elements/product-name-text';

import { HCMOptions } from '@config/hcm-config';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { completeWithLeftZero } from '@helpers/string-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

const EntryProductHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',

  '.entry-holder__product-title': {
    p: {
      fontSize: convertPixelToRem(14),
      fontWeight: '$semibold',
    },
  },

  '.entry-holder__product-information': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',

    '.product-information__value, .product-information__amount': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      fontSize: '0.75rem',
      fontWeight: '$medium',

      'span:nth-child(2)': {
        fontSize: convertPixelToRem(14),
        fontWeight: '$semibold',
      },
    },
  },
});

const EntryContainer = styled('div', {
  padding: '1rem 0',

  borderBottom: '1px solid $silverLight',
});

interface EntryProductProps {
  id: number;
  value: number;
  amount?: number;
}

export function EntryProduct({ id, amount, value }: EntryProductProps) {
  const { productList } = useSelector(({ products }) => products);
  const { productCartList } = useSelector(({ cart }) => cart);
  const productData = productList.find(product => product.id === id);

  if (productData === undefined) {
    return null;
  }

  if (productData.modalityId === ModalitiesId.HCM) {
    return (
      <EntryContainer>
        <EntryProductHolder>
          <div className="entry-holder__product-title">
            <p>
              PONTOMAIS
              <br />
              <ProductNameText id={productCartList[0].id} /> |{' '}
              <span>
                {
                  HCMOptions[id].find(
                    option =>
                      option.value ===
                      productCartList.find(item => item)?.amount,
                  )?.label
                }{' '}
                trabalhadores
              </span>
            </p>
          </div>
        </EntryProductHolder>
      </EntryContainer>
    );
  }

  return (
    <EntryContainer>
      <EntryProductHolder>
        <div className="entry-holder__product-title">
          <p>
            <ProductNameText id={id} />
          </p>
        </div>
        <div className="entry-holder__product-information">
          <p className="product-information__value">
            <span>Valor por cartão:</span>
            <span>{formatValueToCurrency(value)}</span>
          </p>
          {amount && (
            <p className="product-information__amount">
              <span>Quantidade cartões:</span>
              <span>{completeWithLeftZero(amount)}</span>
            </p>
          )}
        </div>
      </EntryProductHolder>
    </EntryContainer>
  );
}
