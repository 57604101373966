import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@elements/button';
import { FeaturedShelfFlag } from '@elements/featured-shelf-flag';
import { ProductImage } from '@elements/product-image';
import { ShelfIllustration } from '@elements/shelf-illustration';

import { productsMapping } from '@config/dynatrace-config';
import { ProductShelfType } from '@extra-types/product-shelf-type';
import { getLinkDestinationType } from '@helpers/function-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useDynatraceTrackment } from '@hooks/use-dynatrace-trackment';
import { clearCart } from '@store/slices/cart-slice';

import { ProductShelfContainer, ProductShelfHolder } from './styles';

type ProductShelfProps = {
  readonly data: ProductShelfType;
  readonly haveToClearCart?: boolean;
};

const KNOWN_MORE_LABEL_TEXT = 'Saiba mais';
const SELECT_LABEL_TEXT = 'Selecionar';

export function ProductShelf({
  data,
  haveToClearCart = false,
}: ProductShelfProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dynatraceTrackment } = useDynatraceTrackment();

  const handleDestination = () => {
    if (getLinkDestinationType(data.pageRoute) === 'externalLink') {
      window.open(data.pageRoute, '_blank')?.focus();
    } else {
      navigate(
        {
          pathname: data.pageRoute,
          search: location.search,
        },
        {
          state: { from: location.pathname },
        },
      );
    }
  };

  return (
    <ProductShelfContainer
      data-testid={`shelf-product-container-${data.keyId}`}
      onClick={() => {
        dynatraceTrackment(
          productsMapping[data.keyId].key,
          productsMapping[data.keyId].value,
        );

        handleDestination();
      }}
    >
      <FeaturedShelfFlag isEnabled={data.isFeatured} />
      <ProductShelfHolder>
        <div className="shelf-product-container__title">
          <span>{data.shelfInformation.title}</span>
        </div>

        <p className="shelf-product-container__description">
          {data.shelfInformation.description}
        </p>
        <Button.Default
          className="shelf-product-container__button"
          id={`btn-selecionar-${data.keyId}`}
          variant="primary"
          onClick={() => {
            if (haveToClearCart) {
              dispatch(clearCart());
            }
          }}
        >
          {data.type === 'product' ? KNOWN_MORE_LABEL_TEXT : SELECT_LABEL_TEXT}
          {getLinkDestinationType(data.pageRoute) === 'externalLink' && (
            <i className="fa-sharp fa-solid fa-arrow-up-right-from-square" />
          )}
        </Button.Default>

        <div className="shelf-product-container__product-image">
          {data.type === 'product' ? (
            <ProductImage productId={Number(data.keyId)} />
          ) : (
            <ShelfIllustration productInformation={data} />
          )}
        </div>
      </ProductShelfHolder>
    </ProductShelfContainer>
  );
}
