import { useCallback, useMemo } from 'react';

import { CartPageBonusItem } from '@components/cart-page-product-list-form-components/cart-page-bonus-item';
import { CartPageFormHeader } from '@components/cart-page-product-list-form-components/cart-page-form-header';
import { CartPageFormTotalValue } from '@components/cart-page-product-list-form-components/cart-page-form-total-value';
import { CartPageIndividualFormProductItem } from '@components/cart-page-product-list-form-components/cart-page-individual-form-product-item';

import {
  calcTotalValueCartByModality,
  createPricingBonusList,
} from '@helpers/cart-helpers';
import { getCartproductIdList } from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

import { ProductListFormContainer } from './styles';

export function CartPageIndividualProductListForm() {
  const { productList } = useSelector(({ products }) => products);
  const { productCartList, cartPricingData, cartModality, contractedProducts } =
    useSelector(({ cart }) => cart);
  const { unservedRegionProducts } = useSelector(({ customer }) => customer);

  const cartproductIdList = useMemo(() => {
    return getCartproductIdList(
      productList,
      cartModality?.id,
      productCartList,
      contractedProducts,
      unservedRegionProducts,
    );
  }, [
    cartModality?.id,
    contractedProducts,
    productCartList,
    productList,
    unservedRegionProducts,
  ]);

  const bonusList = useMemo(
    () => (cartPricingData ? createPricingBonusList(cartPricingData) : []),
    [cartPricingData],
  );

  const totalAmountProductsCart = productCartList.length;
  const totalProductCardAmount = productCartList.reduce(
    (totalValue, product) => totalValue + product.amount,
    0,
  );

  const totalValueCart = calcTotalValueCartByModality({
    cartmodalityId: cartModality?.id,
    productCartList,
  });

  const orderCartProductIdListByContractedProducts = useCallback(() => {
    const cartProductIsListWithoutContractedProduct = cartproductIdList.filter(
      productId => {
        return !contractedProducts.some(
          contractedProduct => contractedProduct.id === productId,
        );
      },
    );
    const contractedProductIdList = contractedProducts.map(({ id }) => id);

    return [
      ...cartProductIsListWithoutContractedProduct,
      ...contractedProductIdList,
    ];
  }, [cartproductIdList, contractedProducts]);

  return (
    <ProductListFormContainer>
      <CartPageFormHeader />
      {orderCartProductIdListByContractedProducts().map(productId => (
        <CartPageIndividualFormProductItem
          key={productId}
          productId={productId}
          contracted={contractedProducts.some(
            contractedProduct => contractedProduct.id === productId,
          )}
        />
      ))}
      {bonusList.map(bonusItem => (
        <CartPageBonusItem key={bonusItem.id} data={bonusItem} />
      ))}
      <CartPageFormTotalValue
        totalAmountProducts={totalAmountProductsCart}
        totalCardsAmount={totalProductCardAmount}
        totalValue={totalValueCart}
        bonusAmount={bonusList.length}
      />
    </ProductListFormContainer>
  );
}
