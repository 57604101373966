import { CartModalHCMItem } from '@elements/cart-modal-elements/cart-modal-hcm-item';

import { useSelector } from '@hooks/use-selector';

export function CartHCMModalityView() {
  const { productCartList } = useSelector(({ cart }) => cart);

  return (
    <>
      {productCartList.map(product => (
        <CartModalHCMItem
          key={product.id}
          id={product.id}
          amount={product.amount}
          value={product.value}
          showImage
        />
      ))}
    </>
  );
}
