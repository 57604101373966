import { getEmissor } from '@helpers/emissor-helpers';
import { convertPixelToRem, hexToRGB } from '@helpers/style-helpers';

import { keyframes, styled, themes } from '@src/themes';

export const ProductRowContainer = styled('tr', {
  td: {
    borderTop: '1px solid #E4E8ED',
  },

  '&:not(:first-child)': {
    '@deviceSm': {
      '.product-row-container__left-column': {
        '&::before': {
          content: '+',
          position: 'absolute',
          right: '1.5rem',
          top: '-8px',

          backgroundColor: 'white',
          padding: '3px',
          borderRadius: '50px',

          color: '$primaryPure',
          fontSize: convertPixelToRem(18),
          fontWeight: '600',
          lineHeight: '0.65',
          transition: 'backgroundColor 0.4s ease-in-out',
        },
      },
    },
  },

  '.product-row-container': {
    display: 'flex',
    justifyContent: 'space-between',
    transition: 'background-color 0.4s ease-in-out',

    '@deviceSm': {
      flexDirection: 'column',
    },
  },

  '.product-row-description': {
    height: 'auto',
    maxHeight: '0',
    opacity: '0',
    overflow: 'hidden',
    transition: 'max-height 0.6s ease-in-out, opacity 0.4s ease-in-out',

    '.product-row-description__text-content': {
      padding: '0 0.5rem',
    },

    '.product-row-description__button-section': {
      display: 'flex',
      justifyContent: 'center',
      padding: '2rem 1.5rem',
    },
  },

  '.product-row-container__left-column': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    width: '100%',
    padding: '1.25rem 0 1.25rem 1.5rem',
    marginRight: '1rem',

    '.left-column__content-container': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',

      width: '100%',

      '> img': {
        width: convertPixelToRem(50),
        marginRight: '1rem',
      },

      '.content-container__text-content': {
        width: '100%',

        fontWeight: '400',
        fontSize: convertPixelToRem(14),

        'p + p': {
          marginTop: '0.25rem',
        },

        '.text-content__product-name-container': {
          display: 'flex',
          alignItems: 'center',

          '.product-name-container__name': {
            display: 'inline-flex',

            fontSize: '1rem',
            fontWeight: '600',
          },

          '> i': {
            height: '1rem',
            marginLeft: '0.5rem',
            transition: 'transform 0.4s ease',

            fontSize: '1rem',
          },

          '> div': {
            marginBottom: '0.5rem',
          },
        },

        '.text-content__information': {
          marginTop: '0.25rem',

          fontWeight: '500',
        },
      },
    },

    '@deviceSm': {
      padding: '1rem 1.25rem 0.75rem',
      marginRight: '0',

      '.left-column__content-container': {
        '> img': {
          height: '2.5rem',
          width: '2.5rem',
          marginRight: '0.75rem',
        },

        '.content-container__text-content': {
          fontSize: '0.75rem',
          lineHeight: '1.5',

          '.text-content__product-name-container': {
            '.product-name-container__name': {
              fontSize: '0.75rem',
            },

            '> i': {
              fontSize: '0.75rem',
              height: '0.75rem',
              marginTop: '0',
            },
          },
        },
      },
    },
  },

  '.product-row-container__right-column': {
    display: 'flex',
    padding: '1.25rem 1.5rem 1.25rem 0',

    '@deviceSm': {
      flexDirection: 'column',

      padding: '0 1.25rem 1rem',

      '.right-column__subtotal-value-container': {
        marginTop: '0.25rem',
      },
    },

    '.right-column__extra-information-container, .right-column__subtotal-value-container':
      {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',

        '@deviceSm': {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },

        '> p': {
          fontSize: '0.75rem',
          fontWeight: '500',
          whiteSpace: 'nowrap',

          '&.amount-value-container__product-amount, &.subtotal-value-container__product-value':
            {
              minWidth: convertPixelToRem(110),
              marginTop: '0.5rem',

              fontSize: convertPixelToRem(15),
              textAlign: 'end',
            },

          '@deviceSm': {
            '&.amount-value-container__product-amount, &.subtotal-value-container__product-value':
              {
                minWidth: convertPixelToRem(74),
                marginTop: '0',
              },

            '&, strong': {
              fontSize: '0.75rem',
            },

            strong: {
              fontWeight: '600',
            },
          },
        },
      },

    '.right-column__extra-information-container': {
      marginRight: '2.5rem',

      '@deviceSm': {
        marginRight: '0',
      },
    },
  },

  '&[aria-disabled=true]': {
    td: {
      position: 'relative',

      '&:before': {
        content: 'Produto retirado da simulação',
        position: 'absolute',
        top: '50%',
        right: '1.5rem',

        background: '$silverDark',
        margin: 'auto 0',
        padding: '0.4rem 0.75rem',
        borderRadius: '0.25rem',
        transform: 'translateY(-50%)',

        color: '$white',
        fontSize: '0.75rem',
        fontWeight: '500',

        '@deviceSm': {
          right: 'unset',
          left: '50%',
          whiteSpace: 'nowrap',
          transform: 'translate(-50%, -50%)',
        },
      },
    },

    'p:not(.subtotal-value-container__label)': {
      textDecoration: 'line-through',
    },

    'td > div.product-row-container': {
      opacity: '0.2',
      cursor: 'not-allowed',

      '@deviceSm': {
        '.product-row-container__left-column': {
          '&::before': {
            content: 'unset',
          },
        },
      },
    },
  },

  variants: {
    collapse: {
      false: {
        '&:not(:first-child)': {
          '@deviceSm': {
            '.product-row-container__left-column': {
              '&::before': {
                backgroundColor: hexToRGB(
                  themes[getEmissor().initials].colors.primaryPure,
                  0.08,
                ),
              },
            },
          },
        },

        '.product-row-container': {
          backgroundColor: hexToRGB(
            themes[getEmissor().initials].colors.primaryPure,
            0.08,
          ),
        },
        '.product-row-description': {
          maxHeight: '1000px',
          opacity: 1,
        },
        '.product-row-container__left-column': {
          '.left-column__content-container': {
            '.content-container__text-content': {
              '.text-content__product-name-container': {
                '> i': {
                  transform: 'rotate(-180deg)',
                },
              },
            },
          },
        },
      },
    },
    cursorPointer: {
      true: {
        '.product-row-container': {
          cursor: 'pointer',
        },
      },
    },
  },
  defaultVariants: {
    collapse: true,
  },
});

const loadingAnimation = keyframes({
  from: {
    opacity: '0.15',
  },
  to: {
    opacity: '0.5',
  },
});

export const LoadingLine = styled('div', {
  position: 'relative',

  height: '1.25rem',
  width: '$$width',
  backgroundColor: '#ddd',
  borderRadius: '10px',
  animation: `${loadingAnimation} 0.8s infinite alternate`,

  '@deviceSm': {
    height: '0.75rem',
  },
});
