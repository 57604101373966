import { inputResponsiveMaxWidthStyle } from '@components/input/styles';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const WalletItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',

  background: '$silverPure',
  width: '100%',
  padding: `0.5rem 1.5rem`,
  borderRadius: convertPixelToRem(6),
  boxShadow: '0px 0px 8px #00000022',

  fontSize: '0.75rem',

  '@deviceMd': {
    padding: '0.5rem 1rem',
  },

  '@media only screen and (max-width: 440px)': {
    flexDirection: 'column',
  },

  '.wallet-item__title': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    color: '$$walletColor',
    fontSize: '1rem',
    fontWeight: '$bold',

    width: '100%',

    img: {
      height: convertPixelToRem(42),
      marginRight: '1rem',
      filter: 'drop-shadow(0px 0px 2px #00000060)',
    },

    '@deviceSm': {
      marginRight: '0.75rem',
    },
  },

  '.wallet-item__wallet-amount': {
    '@media only screen and (max-width: 440px)': {
      width: '100%',
      '> div': inputResponsiveMaxWidthStyle,
    },
  },
});
