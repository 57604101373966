import { useFormContext } from 'react-hook-form';

import cpfValidate from '@vr/devkit/validators/cpfValidate';
import dateValidate from '@vr/devkit/validators/dateValidate';
import emailValidate from '@vr/devkit/validators/emailValidate';

import { Input } from '@components/input';

import {
  CELLPHONE_MAX_LENGTH,
  CELLPHONE_MIN_LENGTH,
  EMAIL_REGEX,
  MAIL_MAX_LENGTH,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from '@config/input-config';
import { CompanyType } from '@extra-types/company-type';
import { ExtraContactType } from '@extra-types/extra-contact-type';
import { RegisterDataType } from '@extra-types/register-data-type';
import { CustomerType } from '@helpers/enum/customer-type';
import { FormErrors } from '@helpers/enum/form-errors';
import { cellphoneValidation } from '@helpers/input-helpers';

import { useSelector } from '@hooks/use-selector';

import { RegisterInputsContainer } from './styles';

export interface ContactData {
  contato: RegisterDataType;
  contatoExtra: ExtraContactType[];
}

export type RegisterInputsProps = {
  isLoading?: boolean;
};

export function RegisterInputs({ isLoading = false }: RegisterInputsProps) {
  const {
    register,
    resetField,
    formState: { errors },
  } = useFormContext<CompanyType>();
  const { customerType } = useSelector(({ customer }) => customer);

  return (
    <RegisterInputsContainer>
      <div className="contact__wrapper contact__wrapper--input">
        <Input.Default
          label="Nome do contato*"
          type="text"
          placeholder="Nome do contato"
          width="100%"
          disabled={isLoading}
          errorMessage={errors.dadosCadastrais?.nomeCadastro?.message}
          css={{ width: '33%' }}
          {...register('dadosCadastrais.nomeCadastro', {
            minLength: {
              value: NAME_MIN_LENGTH,
              message: FormErrors.INVALID_NAME_MINLENGTH,
            },
            maxLength: {
              value: NAME_MAX_LENGTH,
              message: FormErrors.INVALID_NAME_MAXLENGTH,
            },
            required: FormErrors.REQUIRED_NAME,
          })}
        />
        <Input.Default
          label="E-mail*"
          placeholder="E-mail"
          width="100%"
          disabled={
            isLoading ||
            customerType === CustomerType.CUSTOMER ||
            customerType === CustomerType.HAS_AVAILABLE_PRODUCTS
          }
          maxLength={MAIL_MAX_LENGTH}
          errorMessage={errors.dadosCadastrais?.emailCadastro?.message}
          css={{ width: '33%' }}
          {...register('dadosCadastrais.emailCadastro', {
            onChange: event => {
              resetField('dadosCadastrais.emailCadastro', {
                defaultValue: event.target.value.replace(EMAIL_REGEX, ''),
              });
            },
            validate: {
              value: (value: string) =>
                emailValidate(value) || FormErrors.INVALID_MAIL,
            },
            required: FormErrors.REQUIRED_MAIL,
          })}
        />
        <Input.Default
          label="Telefone p/ contato*"
          placeholder="(xx) xxxxx-xxxx"
          width="100%"
          disabled={isLoading}
          maxLength={CELLPHONE_MAX_LENGTH}
          customMask="(99) 99999-9999"
          errorMessage={errors.dadosCadastrais?.telefoneCadastro?.message}
          css={{ width: '33%' }}
          inputMode="numeric"
          {...register('dadosCadastrais.telefoneCadastro', {
            validate: {
              value: value =>
                cellphoneValidation(value) || FormErrors.INVALID_CELLPHONE,
            },
            minLength: {
              value: CELLPHONE_MIN_LENGTH,
              message: FormErrors.INVALID_CELLPHONE,
            },
            maxLength: {
              value: CELLPHONE_MAX_LENGTH,
              message: FormErrors.INVALID_CELLPHONE,
            },
            required: FormErrors.REQUIRED_CELLPHONE,
          })}
        />
      </div>
      <div className="contact__wrapper contact__wrapper--input">
        <Input.Default
          label="CPF*"
          type="text"
          placeholder="000.000.000-00"
          width="100%"
          disabled={isLoading}
          customMask="999.999.999-99"
          errorMessage={errors.dadosCadastrais?.cpfContato?.message}
          css={{ width: '31%' }}
          {...register('dadosCadastrais.cpfContato', {
            required: FormErrors.REQUIRED_CPF,
            validate: {
              value: value => {
                return cpfValidate(value) || FormErrors.INVALID_CPF;
              },
            },
          })}
        />
        <Input.Default
          label="Data de nascimento*"
          placeholder="dd/mm/aaaa"
          width="100%"
          disabled={isLoading}
          customMask="99/99/9999"
          errorMessage={errors.dadosCadastrais?.dataNascimento?.message}
          css={{ width: '31%' }}
          {...register('dadosCadastrais.dataNascimento', {
            validate: {
              value: (value: string) =>
                dateValidate(value) || FormErrors.INVALID_BIRTHDAY,
            },
            required: FormErrors.REQUIRED_BIRTHDAY,
          })}
        />
      </div>
      <div className="register-inputs-container__warning-message">
        <i className="fa-solid fa-circle-exclamation" />
        <span>
          Esses dados serão utilizados para criação da sua conta de acesso aos
          portais de cada serviço contratado
        </span>
      </div>
    </RegisterInputsContainer>
  );
}
