import { EntryBonusRuleType } from '@extra-types/bonus-type';
import {
  formatListToString,
  getSingularOrPlural,
} from '@helpers/string-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

const BonusItemDetailProductContainer = styled('li', {
  fontSize: convertPixelToRem(14),
  lineHeight: '1.4',

  '.detail-product__emphasis': {
    color: '$primaryPure',
  },
});

export function BonusItemDetailProduct({ data }: { data: EntryBonusRuleType }) {
  const productList = useSelector(({ products }) => products.productList);
  const productListName = productList
    .filter(product => data.idList.some(productId => product.id === productId))
    .map(product => product.name);

  return (
    <BonusItemDetailProductContainer>
      Será acumulado de{' '}
      <strong className="detail-product__emphasis">
        {data.unitaryValue}{' '}
        {getSingularOrPlural(data.unitaryValue, 'ponto', 'pontos')}
      </strong>{' '}
      a cada <strong>R$ 100 reais</strong> creditados{' '}
      {getSingularOrPlural(productListName.length, 'no', 'nos')}{' '}
      {getSingularOrPlural(productListName.length, 'cartão', 'cartões')}{' '}
      <strong>{formatListToString(productListName)}</strong>.
    </BonusItemDetailProductContainer>
  );
}
