export type ProductCartType = {
  id: number;
  type: string;
  amount: number;
  value: number;
  additionalProducts?: AdditionalProductsCartType[];
  planType: PlanTypeEnum;
};

export type AdditionalProductsCartType = {
  id: number;
  value: number;
};

export enum PlanTypeEnum {
  MENSAL = 'mensal',
  ANUAL = 'anual',
}
