import { Button } from '@elements/button';
import { PageSection } from '@elements/page-section';

import { ASSETS_LOGOS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useContactModalContext } from '@hooks/use-contact-modal-context';
import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

export function FooterJVCEF() {
  const { handleContactModal } = useContactModalContext();
  const emissor = useSelector(({ application }) => application.config.emissor);
  const FULL_YEAR_COPYRIGHT = new Date().getFullYear().toString();

  return (
    <PageSection variant="footer" tag="footer">
      <FooterContent>
        <div className="footer-container__top-content">
          <img
            src={`${env.URL_ASSETS}${ASSETS_LOGOS_PATH}/logo-footer.png`}
            alt={`Um produto ${emissor.name}`}
          />
          <Button.Default variant="primary" onClick={handleContactModal}>
            <i className="fa-regular fa-headset" />
            Precisa de ajuda ou atendimento?
          </Button.Default>
        </div>
        <div className="footer-container__bottom-content">
          <CopyrightContent>
            © {FULL_YEAR_COPYRIGHT} {emissor.copyright.title} - CNPJ:&nbsp;
            {emissor.copyright.cnpj} - Todos os direitos reservados.&nbsp;
            <a
              title={`Link para ${emissor.privacyPolicy.title.toLowerCase()} da empresa ${
                emissor.name
              }`}
              href={emissor.privacyPolicy.url}
              target="_blank"
              rel="noreferrer"
            >
              {emissor.privacyPolicy.title}
            </a>
          </CopyrightContent>
        </div>
      </FooterContent>
    </PageSection>
  );
}

const FooterContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '3rem',

  width: '100%',
  padding: '2.5rem 0',

  '@deviceMd': {
    flexDirection: 'column-reverse',
    gap: '2rem',
  },

  '@deviceSm': {
    gap: '1.5rem',
  },

  '.footer-container__top-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '2rem',
    width: '100%',

    img: {
      height: convertPixelToRem(60),
      objectFit: 'contain',
    },

    '@deviceMd': {
      flexDirection: 'column-reverse',
      width: '100%',
    },

    '@deviceSm': {
      gap: '1rem',

      img: {
        height: convertPixelToRem(44),
      },

      button: {
        width: '100%',
      },
    },
  },

  '.footer-container__bottom-content': {
    width: '100%',
  },
});

const CopyrightContent = styled('div', {
  color: '$white',
  fontSize: convertPixelToRem(12),
  fontWeight: '$medium',
  lineHeight: 1.8,

  a: {
    transition: 'color 0.4s ease',

    color: '$secondaryPure',
    textAlign: 'center',
    fontWeight: '$medium',
    textDecoration: 'none',

    '&:hover': {
      color: '$white',
    },
  },

  '@deviceMd': {
    textAlign: 'center',
  },
});
