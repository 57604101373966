import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import formatCurrencyToValue from '@vr/devkit/money/formatCurrencyToValue';
import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';
import { debounce } from 'lodash';

import { Input } from '@components/input';
import { WalletFormInputs } from '@compositions/wallet-product-form';

import { DIGIT_WAIT_TIME_TO_PRICE } from '@config/application-config';
import { MAX_LENGTH_CURRENCY_VALUE } from '@config/input-config';
import { PRODUCTS } from '@config/products-config';
import { ModalityType } from '@extra-types/modality-type';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { ProductType } from '@extra-types/product-type';
import { getProductFromComboCart } from '@helpers/cart-helpers';
import { FormErrors } from '@helpers/enum/form-errors';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { removeSpecialCaracters } from '@helpers/function-helpers';
import {
  getProductData,
  getProductType,
  removePrefixProduct,
} from '@helpers/product-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addProductListToComboCart,
  removeProductFromComboCartList,
} from '@store/slices/cart-slice';
import { fetchCartPricing } from '@store/thunks/cart-thunk';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

import ProductWalletDescription from '../../../../../components/product-wallet-input/product-wallet-description';
import { ProductComboContracted } from './product-combo-contracted';
import { ProductComboUnservedRegion } from './product-combo-unserved-region';

interface ProductComboWalletInputProps {
  productData: ProductType;
  modalityData: ModalityType;
  unserved: boolean;
  contracted: boolean;
  idPrefix?: string;
}

export function ProductComboWalletInput({
  productData,
  modalityData,
  unserved,
  contracted,
  idPrefix,
}: ProductComboWalletInputProps) {
  const { selectedOffer, offerDetails } = OfferFacade();
  const { handleActiveSummary } = useComboCartPageContext();
  const productInformation = PRODUCTS[productData.id];
  const inputName = `products.product-${productData.id}.value` as const;
  const prefixWithHifen = `${idPrefix}-`;
  const inputID = `${
    idPrefix ? prefixWithHifen : ''
  }product-${removeSpecialCaracters(productData.name)}-value` as const;
  const dispatch = useDispatch();
  const {
    register,
    resetField,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useFormContext<WalletFormInputs>();
  const { isLoading: isRegisterCartLoading } = useSelector(
    ({ customer }) => customer,
  );
  const { isCartLoading, productComboCartList } = useSelector(
    ({ cart }) => cart,
  );

  const productList = useSelector(({ products }) => products.productList);
  const config = useSelector(({ application }) => application.config);
  const minCardValueValidation =
    offerDetails.oferta.itemsOferta.find(
      item => item.codigoProduto === productData.id,
    )?.valorMinimo ||
    selectedOffer?.valorMinimo ||
    config.minCardValue;
  const maxCardValueValidation =
    offerDetails.oferta.itemsOferta.find(
      item => item.codigoProduto === productData.id,
    )?.valorMaximo ||
    selectedOffer?.valorMaximo ||
    config.maxCardValue;

  const productCartData = getProductFromComboCart({
    modalityId: productData.modalityId,
    productId: productData.id,
    productComboCartList,
  });

  const verifyIfAnyValueHasChanged = (form: WalletFormInputs) => {
    return (
      form.amount !== Number(productCartData?.amount) ||
      Number(form.products[`product-${productData.id}`].value) !==
        Number(productCartData?.value)
    );
  };

  const verifyIfThereIsMoreThanOneProductInCart = () => {
    const filterBenefits = productComboCartList[modalityData.id].filter(
      product => product.type === ServiceGroupId.BENEFIT,
    );

    return filterBenefits.length > 1;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    debounce(async () => {
      const isFormValid = await trigger(['amount', 'products']);
      handleSubmit((form: WalletFormInputs) => {
        if (isFormValid && verifyIfAnyValueHasChanged(form)) {
          if (form.products[`product-${productData.id}`].value === '') {
            if (verifyIfThereIsMoreThanOneProductInCart()) {
              dispatch(
                removeProductFromComboCartList({
                  modalityId: productData.modalityId,
                  productId: productData.id,
                }),
              );
              dispatch(fetchCartPricing({ offerData: selectedOffer }));
              handleActiveSummary({ id: `${modalityData.serviceGroupId}` });
            }
          } else {
            const formProductList = Object.entries(form.products)
              .filter(product => product[1].value !== '')
              .map(([productTextID, product]) => {
                const productId = removePrefixProduct(productTextID);
                const productApplicationData = getProductData(
                  productId,
                  productList,
                );
                return {
                  id: productId,
                  type: getProductType(productApplicationData),
                  amount: form.amount,
                  value: Number(product.value),
                  planType: PlanTypeEnum.MENSAL,
                };
              });
            dispatch(
              addProductListToComboCart({
                modalityId: productData.modalityId,
                productList: formProductList,
              }),
            );
            dispatch(fetchCartPricing({ offerData: selectedOffer }));
            handleActiveSummary({ id: `${modalityData.serviceGroupId}` });
          }
        }
      })();
    }, DIGIT_WAIT_TIME_TO_PRICE),
    [
      handleSubmit,
      dispatch,
      productData.id,
      productCartData,
      productComboCartList,
    ],
  );

  if (!productInformation) return null;

  if (unserved) {
    return <ProductComboUnservedRegion productData={productData} />;
  }

  if (contracted) {
    return <ProductComboContracted productData={productData} />;
  }

  return (
    <ProductWalletInputContainer>
      <ProductWalletDescription productData={productData} />
      <div className="product-wallet-input-container__action">
        <Input.Default
          id={inputID}
          data-testid={inputID}
          maskMoney
          width="150px"
          placeholder="R$ 0,00"
          maxLength={MAX_LENGTH_CURRENCY_VALUE}
          errorMessage={
            errors?.products?.[`product-${productData.id}`]?.value?.message
          }
          inputMode="numeric"
          disabled={isCartLoading || isRegisterCartLoading}
          {...register(inputName, {
            deps: 'amount',
            onChange: event => {
              if (event.target.value === 'R$ 0,00') {
                resetField(inputName, {
                  defaultValue: event.target.value.replace('R$ 0,00', ''),
                });
              }
              debouncedSave();
            },
            setValueAs: value => value && formatCurrencyToValue(value),
            validate: {
              min: value =>
                value !== ''
                  ? Number(value) >= minCardValueValidation ||
                    `${FormErrors.MIN_CARD_VALUE} ${formatValueToCurrency(
                      minCardValueValidation,
                    )}`
                  : true,
              max: value =>
                value !== ''
                  ? Number(value) <= maxCardValueValidation ||
                    `${FormErrors.MAX_CARD_VALUE} ${formatValueToCurrency(
                      maxCardValueValidation,
                    )}`
                  : true,
            },
          })}
        />
      </div>
    </ProductWalletInputContainer>
  );
}

export const ProductWalletInputContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  width: '100%',

  img: {
    maxHeight: convertPixelToRem(40),
  },

  '.product-wallet-description-container__contracted-product': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontWeight: 'bold',
    fontSize: '0.75rem',

    i: {
      color: '$primaryPure',
    },
  },

  '.product-wallet-input-container__information': {
    display: 'flex',
    alignItems: 'start',
    gap: '1rem',
    flex: 1,

    '.information__text': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.125rem',

      color: '$fontColorPrimary',
      fontSize: convertPixelToRem(14),
      lineHeight: '1.5',

      'p.text__product-name': {
        fontSize: convertPixelToRem(16),
      },
    },
  },

  '.product-wallet-input-container__action': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    marginLeft: 'auto',
  },

  '.product-wallet-description-container__already-contracted, .product-wallet-description-container__unserved-region':
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '0.5rem',
      width: '50%',
    },

  '.product-wallet-description-container__unserved-region': {
    p: {
      backgroundColor: '$silverLight',
      padding: '1rem',
      marginLeft: 'auto',
      borderRadius: '6px',

      fontSize: convertPixelToRem(12),
      fontWeight: '$medium',
      lineHeight: '1.5',
      textAlign: 'center',

      '@deviceMd': {
        width: '100%',
      },
    },
  },

  '@deviceMd': {
    '.product-wallet-description-container__already-contracted, .product-wallet-description-container__unserved-region':
      {
        justifyContent: 'center',

        width: '100%',
      },

    '.already-contracted__warning': {
      maxWidth: '100%',
    },
  },

  '@deviceSm': {
    flexDirection: 'column',
    gap: '0',

    '.product-wallet-input-container__information': {
      width: '100%',

      '.information__text': {
        'p.text__product-name': {
          fontSize: convertPixelToRem(14),
        },
      },
    },

    '.product-wallet-input-container__action': {
      width: '100%',

      div: {
        width: '100%',
        input: {
          width: '100%',
        },
      },
    },

    '.product-wallet-description-container__already-contracted, .product-wallet-description-container__unserved-region':
      {
        margin: '1.5rem 0',
      },
  },
});
