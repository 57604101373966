import { PropsWithChildren } from 'react';

import { styled } from '@src/themes';

interface NextStepProps extends PropsWithChildren {
  index?: string;
  icon: string;
  image?: string;
}

function NextStepDefault({ index, icon, children, image }: NextStepProps) {
  return (
    <NextStepContainer withImage={!!image}>
      {index && <span>{index}</span>}
      <div className="icon-holder">
        <i className={icon} />
      </div>
      <p className="next-step-content">{children}</p>
      {image && (
        <img
          className="next-step-image"
          src={image}
          alt="Imagem ilustrativa dos próximos passos"
        />
      )}
    </NextStepContainer>
  );
}

function NextStepCombo({
  children,
  image,
}: Pick<NextStepProps, 'image' | 'children'>) {
  return (
    <NextStepContainer withImage={!!image}>
      {image && (
        <img
          src={image}
          alt="Imagem ilustrativa dos próximos passos"
          width={90}
        />
      )}
      <div className="next-step-content combo">{children}</div>
    </NextStepContainer>
  );
}

export const NextStep = {
  Default: NextStepDefault,
  Combo: NextStepCombo,
};

const NextStepContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  backgroundColor: '$primaryPure',
  width: '80%',
  padding: '1rem',
  borderRadius: '10px',

  color: '$white',

  p: {
    fontWeight: '$medium',
  },

  span: {
    width: '4%',

    color: '$secondaryPure',
    fontSize: '1.75rem',
    fontWeight: 'bold',
  },

  '.icon-holder': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: '$secondaryPure',
    height: '52px',
    width: '52px',
    padding: '0 1rem',
    borderRadius: '100%',

    i: {
      color: '$primaryPure',
      fontSize: '1.5rem',
    },
  },

  '.next-step-image': {
    position: 'absolute',
    right: '0',
  },

  '.combo': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '0.5rem',
    width: '100%',

    '.title, .subtitle': {
      width: '100%',
    },

    '.title': {
      fontSize: '1.125rem',
    },

    '.subtitle': {
      fontSize: '0.875rem',
      fontWeight: '400',
      height: '0',
    },

    '@deviceSm': {
      '.subtitle': {
        height: 'auto',
      },
    },
  },

  '@deviceLg': {
    width: '100%',

    span: {
      width: 'auto',
    },

    p: {
      width: 'min-content',
    },

    img: {
      display: 'none',
    },
  },

  '@deviceSm': {
    justifyContent: 'space-between',

    padding: '2rem 1rem',

    span: {
      width: 'auto',
    },

    p: {
      width: '100%',
    },

    '.icon-holder': {
      position: 'absolute',
      top: '-1.5rem',
      left: '50%',

      transform: 'translate(-50%, 0)',
    },
  },

  variants: {
    withImage: {
      true: {
        p: {
          width: '50%',
        },

        '@deviceLg': {
          p: {
            width: '100%',
          },
        },
      },
      false: {
        p: { width: '100%' },
      },
    },
  },
});

export default NextStep;
