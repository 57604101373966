import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { TableSkeleton } from '@components/table-components/table-skeleton';
import InformationRow from '@elements/table-elements/information-row';
import { ProductRow } from '@elements/table-elements/product-row';
import Table from '@elements/table-elements/table';

import { TableAdapterType } from '@extra-types/table-types';
import { completeWithLeftZero } from '@helpers/string-helpers';

import { BonusRow } from './bonus-row';

interface TableData {
  tableData: TableAdapterType;
  isLoading?: boolean;
}

export function PrecificationTable({ tableData, isLoading }: TableData) {
  if (tableData.totalProducts === 0 || isLoading) {
    return <TableSkeleton />;
  }

  const { totalProducts, totalCards, totalBeneficts, totalValue } = tableData;

  return (
    <Table
      head={{
        textLeft: 'Descrição',
      }}
      foot={{
        titleLeft: 'Valor total do pedido',
        titleRight: formatValueToCurrency(totalValue),
      }}
    >
      {tableData.beneficts?.map(product => (
        <ProductRow
          key={product.productCode}
          productData={{
            id: product.productCode,
            amount: product.cardQuantity,
            subtotalValue: product.totalBenefict,
          }}
        />
      ))}
      <InformationRow
        variant="gray"
        informationData={{
          title: 'VALOR TOTAL DE BENEFÍCIOS',
          subtitle: `
          ${completeWithLeftZero(totalProducts)}
          ${
            totalProducts > 1 ? 'produtos' : 'produto'
          } / ${completeWithLeftZero(totalCards)} cartões`,
          value: totalBeneficts,
        }}
        data-testid="total-benefits"
      />
      <BonusRow tableData={tableData} />
    </Table>
  );
}

export default PrecificationTable;
