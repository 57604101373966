import { useEffect, useState } from 'react';

import throttle from 'lodash/throttle';

export type DevicesType = 'xs' | 'sm' | 'md' | 'lg';

type UseWindowSizeType = {
  excludedSize?: DevicesType[];
};

export function useWindowSize({
  excludedSize = [],
}: UseWindowSizeType = {}): DevicesType {
  const [device, setDevice] = useState<DevicesType>(getPageSizeType());

  function getPageSizeType(): DevicesType {
    if (
      window.innerWidth <= 390 &&
      !excludedSize?.some(size => size === 'xs')
    ) {
      return 'xs';
    }
    if (
      window.innerWidth <= 650 &&
      !excludedSize?.some(size => size === 'sm')
    ) {
      return 'sm';
    }
    if (
      window.innerWidth <= 1024 &&
      !excludedSize?.some(size => size === 'md')
    ) {
      return 'md';
    }
    return 'lg';
  }

  useEffect(() => {
    const track = throttle(() => {
      setDevice(getPageSizeType());
    }, 750);

    window.addEventListener('resize', track);

    return () => window.removeEventListener('resize', track);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return device;
}
