import { styled } from '@src/themes';

const ErrorViewMessageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',

  height: '100%',

  i: {
    fontSize: '2rem',
  },

  p: {
    textAlign: 'center',
    lineHeight: '1.5',
  },
  variants: {
    verticalPadding: {
      true: {
        padding: '1.5rem 0',
      },
    },
  },
  defaultVariants: {
    verticalPadding: false,
  },
});

interface ErrorViewMessageProps {
  message?: string;
  verticalPadding?: boolean;
}

export const defaultMessageErrorViewMessage =
  'Algo inesperado ocorreu, não foi possível carregar o conteúdo';

export function ErrorViewMessage({
  message = defaultMessageErrorViewMessage,
  verticalPadding,
}: ErrorViewMessageProps) {
  return (
    <ErrorViewMessageContainer verticalPadding={verticalPadding}>
      <i className="fa-light fa-cart-circle-exclamation" />
      <p>{message}</p>
    </ErrorViewMessageContainer>
  );
}
