import { ProductReferenceIcon } from '@elements/order-revision-service-table-elements/product-reference-icon';

import { env } from '@config/env';
import { ProductAddonType } from '@extra-types/product-addon-type';

import { styled } from '@src/themes';

export function ResumeAdditionalProductList({
  additionalProductList,
  isCollapsed,
}: {
  additionalProductList?: ProductAddonType[];
  isCollapsed: boolean;
}) {
  if (!additionalProductList) {
    return null;
  }

  return (
    <ResumeAdditionalProductListContainer isCollapsed={isCollapsed}>
      <div className="resume-additional-product-balance-list-container__title">
        <ProductReferenceIcon className="title__product-reference" />
        <span>Módulos do plano</span>
      </div>
      <ul>
        {additionalProductList.map(addon => {
          return (
            <li key={addon.id}>
              <img
                src={env.URL_ASSETS_CHANNEL + addon.logoPath}
                alt={`Logotipo do módulo ${addon.name}`}
              />
            </li>
          );
        })}
      </ul>
    </ResumeAdditionalProductListContainer>
  );
}

const ResumeAdditionalProductListContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  padding: '0 1rem',
  opacity: 1,
  transition: 'opacity 0.4s ease',
  transitionDelay: '0.05s',

  '@deviceSm': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.5rem',

    background: '$silverLight',
    borderRadius: '0.5rem',
    padding: '0.5rem 0.5rem 0',
    marginBottom: 0,
  },

  '.resume-additional-product-balance-list-container__title': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',

    span: {
      fontSize: '0.875rem',
      fontWeight: '$medium',
      lineHeight: 1.3,
    },

    '@deviceSm': {
      padding: '0 0.5rem',

      '.title__product-reference': {
        display: 'none',
      },

      span: {
        fontSize: '0.75rem',
      },
    },
  },

  ul: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    overflowX: 'auto',

    li: {
      display: 'flex',
    },

    img: {
      height: '2.625rem',
      objectFit: 'contain',
    },

    '@deviceSm': {
      gap: '0.625rem',
      width: '100%',

      padding: '0 0.5rem 0.75rem',
    },
  },
  variants: {
    isCollapsed: {
      false: {
        height: 0,
        marginBottom: 0,
        padding: 0,
        opacity: 0,
        visibility: 'hidden',
      },
    },
  },
});
