import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

interface StepLabelProps {
  stepIndex: number;
  currentStepPosition: number;
  label: string;
}

export function StepLabel({
  label,
  stepIndex,
  currentStepPosition,
}: StepLabelProps) {
  return (
    <StepLabelElement active={stepIndex === currentStepPosition}>
      {label}
    </StepLabelElement>
  );
}

const StepLabelElement = styled('span', {
  width: convertPixelToRem(234),

  color: '#9CAEC1',
  fontSize: '1.125rem',
  fontWeight: 'bold',
  textAlign: 'center',

  '@deviceSm': {
    fontSize: convertPixelToRem(15),
  },

  '@deviceXs': {
    width: 'inherit',
  },

  variants: {
    active: {
      true: {
        color: '$primaryPure',
      },
    },
  },
  defaultVariants: {
    active: false,
  },
});
