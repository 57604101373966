import { HTMLAttributes } from 'react';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

import { FAQRateArticle } from './faq-rate-article';

interface FAQAccordionItemProps extends HTMLAttributes<HTMLDivElement> {
  articleId: string;
  title: string;
  content: string;
  color: string;
  changeActiveAction: (id: string) => void;
  isActive?: boolean;
  searchResult?: boolean;
  categoryTitle?: string;
}

function FAQAccordionItem({
  articleId,
  title,
  content,
  isActive,
  color,
  categoryTitle,
  searchResult = false,
  changeActiveAction,
  ...props
}: FAQAccordionItemProps) {
  return (
    <FAQAccordionItemContainer
      className={isActive ? 'active-container' : ''}
      collapse={!isActive}
      css={{
        $$borderColor: color,
        $$categoryColor: color,
      }}
      {...props}
    >
      <div
        role="button"
        className="accordion-item-container__title-container"
        onClick={() => changeActiveAction(articleId)}
        tabIndex={0}
        onKeyDown={event =>
          event.key === 'Enter' && changeActiveAction(articleId)
        }
        aria-expanded={isActive}
      >
        <div>
          <span>{title}</span>
          {searchResult && <p>{categoryTitle}</p>}
        </div>
        <i className="fa-light fa-chevron-down" />
      </div>
      <div className="accordion-item-container__description">
        <p>{content}</p>
        <FAQRateArticle articleId={articleId} />
      </div>
    </FAQAccordionItemContainer>
  );
}

function FAQAccordionSelected({
  articleId,
  title,
  content,
  color,
  ...props
}: Omit<FAQAccordionItemProps, 'changeActiveAction' | 'isActive'>) {
  return (
    <FAQAccordionItemContainer
      className="active-container"
      collapse={false}
      css={{
        $$borderColor: color,
      }}
      {...props}
    >
      <div
        role="button"
        className="accordion-item-container__title-container"
        tabIndex={0}
        aria-expanded
      >
        <span>{title}</span>
      </div>
      <div className="accordion-item-container__description">
        <p>{content}</p>
        <FAQRateArticle articleId={articleId} />
      </div>
    </FAQAccordionItemContainer>
  );
}

export const FAQAccordion = {
  Default: FAQAccordionItem,
  Selected: FAQAccordionSelected,
};

const FAQAccordionItemContainer = styled('article', {
  display: 'flex',
  flexDirection: 'column',

  backgroundColor: 'transparent',
  borderLeft: '5px solid transparent',
  borderBottom: '1px solid transparent',

  color: '$fontColorPrimary',
  fontWeight: '$regular',

  transition: 'border-color 0.4s ease-in-out',

  '&.selected-article': {
    button: {
      background: 'none',
    },
  },

  '.accordion-item-container__title-container': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0.5rem',

    padding: '1.25rem 1.5rem',
    cursor: 'pointer',

    '> div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',

      p: {
        color: '$$categoryColor',
        fontSize: '0.75rem',
        fontWeight: '700',
      },
    },

    span: {
      fontSize: '1rem',
      fontWeight: '$medium',
    },

    i: {
      height: convertPixelToRem(16),
      transition: 'transform 0.4s',
    },

    '@deviceSm': {
      padding: '1.25rem 1rem',
    },
  },

  '.accordion-item-container__description': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',

    height: 'auto',
    padding: '0 2.5rem 1.5rem 1.5rem',
    overflow: 'hidden',
    transition: 'height 0.4s ease, opacity 0.4s ease, padding 0.4s ease',
    willChange: 'height',

    fontSize: convertPixelToRem(14),
    fontWeight: 400,
    lineHeight: 1.75,

    p: {
      textAlign: 'justify',
      wordBreak: 'break-word',
    },

    '@deviceSm': {
      padding: '0 1rem 1rem',
    },
  },

  '@lg': {
    '.accordion-item-container__title-container': {
      span: {
        transition: 'font-weight 0.075s ease-in-out',
      },
    },
    '&:hover': {
      '.accordion-item-container__title-container': {
        span: {
          fontWeight: '$semibold',
        },
      },
    },
  },

  variants: {
    collapse: {
      true: {
        '.accordion-item-container__description': {
          height: 0,
          paddingBottom: '0',
          opacity: 0,
        },
      },
      false: {
        background: '$white',
        borderColor: '$$borderColor',
        borderBottom: '1px solid $$borderColor',

        '.accordion-item-container__title-container': {
          span: {
            fontWeight: '$semibold',
          },

          i: {
            transform: 'rotate(180deg)',
          },
        },

        '.accordion-item-container__description': {
          opacity: 1,
        },
      },
    },
  },
  defaultVariants: {
    collapse: true,
  },
});
