import { combineReducers, configureStore } from '@reduxjs/toolkit';

import applicationSlice from './slices/application-slice';
import bannerInformationSlice from './slices/banner-information-slice';
import cartSlice from './slices/cart-slice';
import customerSlice from './slices/customer-slice';
import landingPageSlice from './slices/landing-page-slice';
import modalSlice from './slices/modal-slice';
import offerSlice from './slices/offer-slice';
import productsSlice from './slices/products-slice';
import warningModalSlice from './slices/warning-modal-slice';

export const reducer = combineReducers({
  application: applicationSlice,
  landingPage: landingPageSlice,
  products: productsSlice,
  cart: cartSlice,
  modal: modalSlice,
  warningModal: warningModalSlice,
  customer: customerSlice,
  bannerInformation: bannerInformationSlice,
  offers: offerSlice,
});

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
