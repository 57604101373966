export enum AddonsId {
  GESTAO_VALE_TRANSPORTE = 9200,
  GESTAO_SALDO = 9201,
  ROTEIRIZACAO_INTELIGENTE = 9202,
  CONTROLE_DE_PONTO = 301,
  ADMISSAO = 302,
  GESTAO_DE_FERIAS_E_FOLGAS = 303,
  GESTAO_ELETRONICA_DE_DOCUMENTOS = 304,
  HOLERITE = 305,
  GESTAO_DE_ESCALAS = 306,
  PONTO_POR_RECONHECIMENTO_FACIAL = 307,
  PONTO_POR_WHATSAPP = 308,
  API_WEBHOOKS_PONTOMAIS = 309,
  VALIDAMAIS = 310,
  INTEGRACAO_ADAPTA = 311,
  INTEGRACAO_CONVENIA = 312,
  FEEDZ = 313,
  COMPRE_VR_BENEFICIOS = 315,
  UNIVERSO_DO_TEMPO = 316,
  IMPLANTACAO_INCLUSO = 323,
}
