import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { toggleCartModalState } from '@store/slices/cart-slice';

import { styled } from '@src/themes';

export function CartModalBackgroundOverflow() {
  const { cartModalState } = useSelector(({ cart }) => cart);

  const dispatch = useDispatch();
  const nodeRef = useRef(null);

  useEffect(() => {
    if (cartModalState) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [cartModalState]);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={cartModalState}
      classNames="cart-overflow"
      timeout={400}
      unmountOnExit
    >
      <CartModalBackgroundOverflowContainer
        data-testid="cart-modal-background-overflow"
        ref={nodeRef}
        onClick={() => dispatch(toggleCartModalState())}
      />
    </CSSTransition>
  );
}

const CartModalBackgroundOverflowContainer = styled('div', {
  position: 'fixed',
  inset: 0,

  backgroundColor: '#00000080',
  backdropFilter: 'blur(6px)',

  '&.cart-overflow-enter': {
    opacity: 0,
  },

  '&.cart-overflow-enter-active': {
    opacity: 1,
    transition: 'opacity 0.4s ease',
  },

  '&.cart-overflow-exit': {
    opacity: 1,
  },

  '&.cart-overflow-exit-active': {
    opacity: 0,
    transition: 'opacity 0.4s ease',
  },

  '@deviceSm': {
    backdropFilter: 'unset',
  },
});
