import React, { useEffect, useState } from 'react';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { Button } from '@elements/button';

import { valueSelector } from '@helpers/product-helpers';

import { LoadingLine, InformationRowContainer } from './styles';

type InformationRowProps = {
  variant: 'gray' | 'green';
  informationData: {
    title: string;
    subtitle: string;
    value: number;
    discount?: number;
  };
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  withoutButton?: boolean;
};

type DescriptionPropsData = {
  role?: React.AriaRole;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  tabIndex?: number;
};

export function InformationRow({
  informationData,
  variant,
  children,
  loading,
  disabled,
  withoutButton = false,
}: InformationRowProps) {
  const [isCollapsed, setCollapse] = useState(true);
  const [descriptionProps, setDescriptionProps] = useState(
    {} as DescriptionPropsData,
  );

  const { title, subtitle, value, discount } = informationData;

  const handleDescriptionCollapse = () => {
    setCollapse(oldState => !oldState);
  };

  useEffect(() => {
    if (children) {
      setDescriptionProps({
        role: 'button',
        onClick: handleDescriptionCollapse,
        onKeyDown: e => {
          if (e.key === 'Enter') {
            handleDescriptionCollapse();
          }
        },
        tabIndex: 0,
      } as DescriptionPropsData);
    } else {
      setDescriptionProps({});
    }
  }, [children]);

  if (loading) {
    return (
      <InformationRowContainer informativeColor={variant}>
        <td colSpan={2}>
          <div className="information-row-container">
            <div className="information-row-container__left-column">
              <div className="left-column__content-container">
                <div className="content-container__text-content">
                  <div className="text-content__information-title">
                    <LoadingLine css={{ $$width: '50%' }} />
                  </div>
                  <div>
                    <LoadingLine css={{ $$width: '30%' }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="information-row__right-column">
              <div className="right-column__content-container">
                <div className="content-container__product-value">
                  <LoadingLine css={{ $$width: '100%' }} />
                </div>
              </div>
            </div>
          </div>
        </td>
      </InformationRowContainer>
    );
  }

  return (
    <InformationRowContainer
      informativeColor={variant}
      collapse={isCollapsed}
      cursorPointer={!!children}
      aria-disabled={disabled}
    >
      <td colSpan={2}>
        <div className="information-row-container">
          <div className="information-row-container__left-column">
            <div
              className="left-column__content-container"
              {...descriptionProps}
            >
              <div className="content-container__text-content">
                <p className="text-content__information-title">
                  {title}
                  {!!children && <i className="fas fa-caret-down" />}
                </p>
                <p className="text-content__information-subtitle">{subtitle}</p>
              </div>
            </div>
          </div>
          <div className="information-row-container__right-column">
            <div className="right-column__subtotal-value-container">
              {discount && value === 0 && (
                <p className="content-container__discount-value">
                  {formatValueToCurrency(discount)}
                </p>
              )}
              <p className="content-container__information-value">
                {valueSelector({ value })}
              </p>
            </div>
          </div>
        </div>
        {!!children && (
          <div className="information-row-description">
            <div className="information-row-description__text-content">
              {children}
            </div>
            {!withoutButton && (
              <div className="information-row-description__button-section">
                <Button.Default
                  size="small"
                  variant="primary"
                  onClick={handleDescriptionCollapse}
                >
                  Fechar
                </Button.Default>
              </div>
            )}
          </div>
        )}
      </td>
    </InformationRowContainer>
  );
}

export default InformationRow;
