import { Button } from '@elements/button';

import { useSelector } from '@hooks/use-selector';

function ConfirmationModal() {
  const { confirmationButton, denyButton } = useSelector(({ modal }) => modal);
  return (
    <div className="references">
      <Button.Default
        onClick={() => {
          if (confirmationButton) {
            confirmationButton();
          }
        }}
      >
        Manter meus produtos
      </Button.Default>
      <p
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button"
        className="content-button-secondary"
        onClick={() => {
          if (denyButton) {
            denyButton();
          }
        }}
        tabIndex={0}
        onKeyDown={event => event.key === 'Enter' && denyButton && denyButton()}
      >
        Esvaziar carrinho e adicionar
      </p>
    </div>
  );
}

export default ConfirmationModal;
