import { Button } from '@elements/button';
import { MobilitySectionTitle } from '@elements/mobility-section-title';
import { PageSection } from '@elements/page-section';

import { ASSETS_MOBILITY_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

import { contractSectionID } from './product-hero-section';

export function BalanceManagementSavingSection() {
  return (
    <PageSection>
      <SectionHolder>
        <div className="balance-management__header">
          <div className="balance-management__header-text">
            <MobilitySectionTitle color="$valeTransportePrimaryPure">
              Economize com a gestão de saldo
            </MobilitySectionTitle>
            <p>
              Com a gestão de saldo ao fazer o pedido online,{' '}
              <strong>
                o sistema calcula a diferença entre o valor pendente e o valor
                do próximo benefício
              </strong>
              , sugerindo o valor de compra que garanta o benefício o
              trabalhador para uso até o final do mês.
            </p>
          </div>
          <div className="balance-management__header-image">
            <img src={`${urlAssets}/enocomize-gestao-de-saldo.png`} alt="" />
          </div>
        </div>

        <div className="balance-management__example">
          <h3>EXEMPLO PRÁTICO</h3>

          <div className="balance-management__example-content">
            <ul>
              <li>
                <div>
                  <i className="fa-thin fa-circle-dollar" />
                </div>
                <p>
                  Pedido inicial <br />
                  <strong>R$ 500,00</strong>
                </p>
              </li>
              <li className="example-content__arrow">
                <i className="fa-sharp fa-light fa-arrow-right" />
              </li>
              <li>
                <div>
                  <i className="fa-thin fa-credit-card-blank" />
                </div>
                <p>
                  Recarga pendente <br />
                  <strong>R$ 400,00</strong>
                </p>
              </li>
              <li className="example-content__arrow">
                <i className="fa-sharp fa-light fa-arrow-right" />
              </li>
              <li>
                <div>
                  <i className="fa-regular fa-money-bill" />
                </div>
                <p>
                  Pedido projeção 7 dias <br />
                  <strong>R$ 56,00</strong>
                </p>
              </li>
              <li className="example-content__arrow">
                <i className="fa-sharp fa-light fa-arrow-right" />
              </li>
              <li>
                <div>
                  <i className="fa-light fa-display" />
                </div>
                <p>
                  Pedido final <br />
                  <strong>R$ 156,00</strong>
                </p>
              </li>
              <li className="example-content__arrow">
                <i className="fa-sharp fa-light fa-arrow-right last" />
              </li>
              <li className="balance-management__example-saving">
                <p>
                  Economia de <br />
                  <span>R$ 344,00</span>
                </p>
              </li>
            </ul>
          </div>
          <div className="balance-management__example-saving-tablet">
            <i className="fa-sharp fa-light fa-arrow-right" />
            <p>
              Economia de <br />
              <span>R$ 344,00</span>
            </p>
          </div>
        </div>

        <div className="balance-management__observation">
          <p>*Sua empresa não paga nada para aderir ao serviço.</p>
        </div>

        <div className="balance-management__action-area">
          <Button.Anchor
            id="mobilidade_sessao-economize-gestao-saldo_cta"
            href={`#${contractSectionID}`}
          >
            Contratar Vale-Transporte
          </Button.Anchor>
        </div>
      </SectionHolder>
    </PageSection>
  );
}

const urlAssets = env.URL_ASSETS + ASSETS_MOBILITY_PATH;

const SectionHolder = styled('div', {
  paddingTop: '3rem',
  paddingBottom: '3rem',

  '@deviceLg': {
    display: 'flex',
    flexWrap: 'wrap',
  },

  '@deviceMd': {
    display: 'inherit',
  },

  '.balance-management__header': {
    '.balance-management__header-text': {
      p: {
        width: '60%',
        color: '$black',
        fontSize: convertPixelToRem(16),
        padding: '10px 0',

        '@deviceLg': {
          width: '100%',
          fontSize: convertPixelToRem(12),
        },

        '@deviceMd': {
          width: '100%',
          fontSize: convertPixelToRem(12),
        },
      },

      '@deviceMd': {
        h2: {
          textAlign: 'unset',
        },
      },
    },

    '.balance-management__header-image': {
      img: {
        width: convertPixelToRem(469),
        height: convertPixelToRem(54),
        margin: '20px 0',

        '@deviceLg': {
          content: `url(${urlAssets}/enocomize-gestao-de-saldo-tablet.png)`,
          width: convertPixelToRem(350),
          height: convertPixelToRem(66),
        },

        '@deviceMd': {
          display: 'none',
        },
      },
    },

    '@deviceLg': {
      width: convertPixelToRem(354),
    },

    '@deviceMd': {
      width: '100%',
    },
  },

  '.balance-management__example': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',

    '@deviceLg': {
      flexDirection: 'row',
      justifyContent: 'center',
      marginLeft: '3rem',
    },

    '@deviceMd': {
      marginLeft: 'unset',
    },

    h3: {
      color: '$valeTransportePrimaryPure',
      fontSize: convertPixelToRem(12),

      '@deviceLg': {
        display: 'none',
      },

      '@deviceMd': {
        display: 'none',
      },
    },

    '.balance-management__example-content': {
      ul: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: convertPixelToRem(28),

        '@deviceLg': {
          flexDirection: 'column',
          gap: convertPixelToRem(10),
        },

        '@deviceMd': {
          flexDirection: 'column',
          paddingTop: convertPixelToRem(20),
          gap: convertPixelToRem(10),
        },

        li: {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: convertPixelToRem(7),

          i: {
            fontSize: convertPixelToRem(16),
            fontWeight: '$fontAwesome',
            color: '$valeTransportePrimaryPure',

            '@deviceLg': {
              fontSize: convertPixelToRem(12),
            },

            '@deviceMd': {
              fontSize: convertPixelToRem(12),
            },
          },

          div: {
            border: '1px solid #74BBD9',
            borderRadius: '$xl',
            padding: '16px 18px',

            '@deviceLg': {
              padding: '15px 19px',
            },
          },

          p: {
            fontSize: convertPixelToRem(14),
            textAlign: 'center',

            '@deviceLg': {
              fontSize: convertPixelToRem(12),
            },

            strong: {
              fontSize: convertPixelToRem(18),

              '@deviceLg': {
                fontSize: convertPixelToRem(12),
              },

              '@deviceMd': {
                fontSize: convertPixelToRem(14),
              },
            },
          },
        },
      },

      '.balance-management__example-saving': {
        p: {
          color: '$valeTransportePrimaryPure',
          fontSize: convertPixelToRem(24),
          fontWeight: '$semibold',
          textAlign: 'unset',

          span: {
            fontSize: convertPixelToRem(26),
            color: '#74BBD9',

            '@deviceMd': {
              fontSize: convertPixelToRem(24),
            },
          },

          '@deviceMd': {
            textAlign: 'center',
          },
        },

        '@deviceLg': {
          display: 'none',
        },

        '@deviceMd': {
          display: 'inherit',
        },
      },

      '.example-content__arrow': {
        i: {
          fontSize: convertPixelToRem(22),
          fontWeight: '$fontAwesome',
          color: '$valeTransportePrimaryPure',
        },

        '.last': {
          color: '#74BBD9',

          '@deviceLg': {
            display: 'none',
          },

          '@deviceMd': {
            display: 'inherit',
          },
        },

        '@deviceLg': {
          transform: 'rotate(90deg)',
        },

        '@deviceMd': {
          display: 'inherit',
          transform: 'rotate(90deg)',
        },
      },
    },

    '.balance-management__example-saving-tablet': {
      display: 'none',

      '@deviceLg': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '70%',
      },

      '@deviceMd': {
        display: 'none',
      },

      i: {
        fontSize: convertPixelToRem(22),
        fontWeight: '$fontAwesome',
        color: '#74BBD9',

        '@deviceMd': {
          transform: 'rotate(90deg)',
        },
      },

      p: {
        fontSize: convertPixelToRem(24),
        textAlign: 'center',
        fontWeight: '$semibold',
        color: '$valeTransportePrimaryPure',

        span: {
          color: '#74BBD9',
        },
      },
    },
  },

  '.balance-management__observation': {
    p: {
      fontSize: convertPixelToRem(10),
      marginTop: '2rem',
      marginBottom: '3rem',

      '@deviceMd': {
        fontSize: convertPixelToRem(12),
        fontWeight: '$semibold',
      },
    },

    '@deviceLg': {
      width: '100%',
      textAlign: 'center',
    },

    '@deviceMd': {
      textAlign: 'unset',
    },
  },

  '.balance-management__action-area': {
    display: 'flex',
    justifyContent: 'center',
    a: {
      width: '332px',
      color: '$black',
    },

    '@deviceLg': {
      width: '100%',
    },
  },
});
