/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiRequestAuthData } from '@api/models/request/auth-data';
import ContractRequest from '@api/models/request/contract-request';
import CreateContractResponseData from '@api/models/response/create-contract-response';

import getBaseURLApi from './authentication/get-base-url-api';

class HiringService {
  private readonly baseUrl;

  constructor() {
    this.baseUrl = 'contratos/leads';
  }

  public async createHiring(
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
    lead: string,
    idCarrinho: string,
    body: ContractRequest,
  ): Promise<CreateContractResponseData> {
    const url = `/${this.baseUrl}/${lead}/carrinhos/${idCarrinho}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
      data: body,
      method: 'POST',
    });

    return data;
  }

  public async getContract(
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
    lead: string,
  ): Promise<any> {
    const url = `/${this.baseUrl}/${lead}/download`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
      responseType: 'arraybuffer',
    });

    return data;
  }
}

export default new HiringService();
