import { createStitches, PropertyValue, ScaleValue } from '@stitches/react';

import { STITCHES_PREFIX } from '@config/application-config';
import { ThemesType } from '@extra-types/theme-types';

import { Emissor } from '../helpers/enum/emissor';
import { commonColors } from './common-colors';
import { commonFonts } from './common-fonts';
import { defaultColors } from './default-colors';
import { SICRE } from './variants/channel/SICRE';
import { JVCEF } from './variants/emissor/JVCEF';
import { VRPAT } from './variants/emissor/VRPAT';

const gridSizes = {
  grid1: '8.333333%',
  grid2: '16.666667%',
  grid3: '25%',
  grid4: '33.333333%',
  grid5: '41.666667%',
  grid6: '50%',
  grid7: '58.333333%',
  grid8: '66.666667%',
  grid9: '75%',
  grid10: '83.333333%',
  grid11: '91.666667%',
  full: '100%',
};

export const themes: ThemesType = {
  VRPAT,
  JVCEF,
  SICRE,
};

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  prefix: STITCHES_PREFIX,
  theme: {
    colors: {
      ...defaultColors,
      ...commonColors,
    },

    fontWeights: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },

    fontSizes: {
      8: '0.5rem',
      10: '0.625rem',
      12: '0.75rem',
      14: '0.875rem',
      16: '1rem',
      18: '1.125rem',
      20: '1.25rem',
      22: '1.375rem',
      24: '1.5rem',
      26: '1.625rem',
      28: '1.75rem',
      30: '1.875rem',
      32: '2rem',
      34: '2.125rem',
      36: '2.25rem',
      38: '2.375rem',
      40: '2.5rem',
      42: '2.625rem',
      44: '2.75rem',
      46: '2.875rem',
      48: '3rem',
      50: '3.125rem',
    },

    space: {
      4: '4px',
      8: '8px',
      12: '12px',
      16: '16px',
      20: '20px',
      24: '24px',
      28: '28px',
      32: '32px',
      36: '36px',
      40: '40px',
      44: '44px',
      48: '48px',
      52: '52px',
      56: '56px',
      60: '60px',
      ...gridSizes,
    },

    sizes: {
      4: '4px',
      8: '8px',
      12: '12px',
      16: '16px',
      20: '20px',
      24: '24px',
      28: '28px',
      32: '32px',
      36: '36px',
      40: '40px',
      44: '44px',
      48: '48px',
      52: '52px',
      56: '56px',
      60: '60px',
      64: '64px',
      68: '68px',
      72: '72px',
      76: '76px',
      80: '80px',
      84: '84px',
      88: '88px',
      92: '92px',
      96: '96px',
      100: '100px',
      104: '104px',
      108: '108px',
      112: '112px',
      116: '116px',
      120: '120px',
      124: '124px',
      128: '128px',
      132: '132px',
      136: '136px',
      140: '140px',
      ...gridSizes,
    },

    radii: {
      default: '0px',
      xs: '4px',
      sm: '8px',
      md: '12px',
      lg: '16px',
      xl: '30px',
      round: '50%',
      circular: '100%',
      pill: '9999px',
    },

    borderWidths: {},

    borderStyles: {},

    zIndices: {
      1: '100',
      2: '200',
      3: '300',
      4: '400',
      5: '500',
      6: '600',
      7: '700',
      8: '800',
      9: '900',
      max: '999',
    },
  },

  media: {
    xs: 'screen and (min-width: 0)',
    sm: 'screen and (min-width: 580px)',
    md: 'screen and (min-width: 768px)',
    lg: 'screen and (min-width: 1024px)',
    xl: 'screen and (min-width: 1280px)',
    xxl: 'screen and (min-width: 1536px)',

    motion: '(prefers-reduced-motion)',
    dark: '(prefers-color-scheme: dark)',
    light: '(prefers-color-scheme: light)',

    deviceXs: 'only screen and (max-width: 400px)',
    deviceSm: 'only screen and (max-width: 600px)',
    deviceMd: 'only screen and (max-width: 768px)',
    deviceLg: 'only screen and (max-width: 992px)',
    deviceXl: 'only screen and (max-width: 1200px)',
  },

  utils: {
    paddingX: (value: PropertyValue<'paddingLeft'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    paddingY: (value: PropertyValue<'paddingTop'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    marginX: (value: PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    marginY: (value: PropertyValue<'marginTop'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    size: (value: PropertyValue<'width'>) => ({
      width: value,
      height: value,
    }),
    gridSize: (value: ScaleValue<'sizes'>) => ({
      flexBasis: value,
      maxWidth: value,
    }),
  },
});

export function getTheme(emissor: Emissor, channel: string) {
  if (themes[channel]) {
    return createTheme(channel, {
      colors: {
        ...commonColors,
        ...themes[channel].colors,
      },
      fonts: {
        ...commonFonts,
        ...themes[channel].fonts,
      },
    });
  }

  return createTheme(emissor, {
    colors: {
      ...commonColors,
      ...themes[emissor].colors,
    },
    fonts: {
      ...commonFonts,
      ...themes[emissor].fonts,
    },
  });
}
