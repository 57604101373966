import { HTMLAttributes } from 'react';

import { HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from '@config/styles-config';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

interface ComboServiceFormContainerProps
  extends HTMLAttributes<HTMLFormElement> {
  children: React.ReactNode;
}

export function ComboServiceFormContainer({
  children,
  ...props
}: ComboServiceFormContainerProps) {
  return (
    <ComboServiceFormContainerStyled {...props}>
      {children}
    </ComboServiceFormContainerStyled>
  );
}

const ComboServiceFormContainerStyled = styled('form', {
  background: '$silverLight',
  borderRadius: convertPixelToRem(6),
  overflow: 'clip',

  scrollMarginTop: `${HEADER_HEIGHT + 10}px`,

  '@deviceSm': {
    scrollMarginTop: `${HEADER_MOBILE_HEIGHT + 10}px`,
  },
});
