import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const CartItemContainer = styled('div', {
  padding: '1rem 0',

  '& + div': {
    borderTop: '1px solid $silverLight',
  },

  '.cart-individual-item-container__product-title': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    marginBottom: '1rem',

    '.product-title__information': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',

      p: {
        fontWeight: '$semibold',
      },

      img: {
        width: convertPixelToRem(30),
      },
    },
  },

  '.cart-individual-item-container__product-information': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    fontWeight: '$medium',

    '.product-information__value, .product-information__amount': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      span: {
        fontSize: convertPixelToRem(14),

        '&:nth-child(2)': {
          fontSize: '1rem',
        },
      },
    },
  },
});
