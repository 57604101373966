import React from 'react';

import { Skeleton } from '@components/skeletons';
import { PageSection } from '@elements/page-section';

import { SECTIONS_COMPONENTS } from '@config/section-config';
import { SectionGroupDefaultPropsType } from '@extra-types/section-props-type';
import { createVerifiedSectionsBySectionType } from '@helpers/section-helpers';

import { useSelector } from '@hooks/use-selector';

export function WalletSectionGroup({ type }: SectionGroupDefaultPropsType) {
  const { sections, isLoading } = useSelector(({ landingPage }) => landingPage);

  const verifiedSections = createVerifiedSectionsBySectionType(sections, type);

  if (isLoading) {
    return (
      <PageSection variant="tertiary" dark>
        <Skeleton.SectionContainer noPadding>
          <Skeleton.GroupContainer guidance="horizontal">
            <Skeleton.BenefitItem variant="secondary" />
            <Skeleton.BenefitItem variant="secondary" />
          </Skeleton.GroupContainer>
          <Skeleton.GroupContainer guidance="horizontal">
            <Skeleton.BenefitItem variant="secondary" />
            <Skeleton.BenefitItem variant="secondary" />
          </Skeleton.GroupContainer>
        </Skeleton.SectionContainer>
        <Skeleton.SectionContainer>
          <Skeleton.Section variant="secondary" />
        </Skeleton.SectionContainer>
      </PageSection>
    );
  }

  return (
    <>
      {verifiedSections.map(section => {
        return React.cloneElement(SECTIONS_COMPONENTS[section.id].component, {
          key: section.id,
        });
      })}
    </>
  );
}
