import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AdditionalProductHcm } from '@components/additional-product-hcm';
import Details from '@elements/details';
import { HCMFormType } from '@pages/offer-page/custom-offer-pages/components/combo-product-services-form/combo-hcm-service-form';

import {
  AdditionalProductType,
  AdditionalProductTypeEnum,
} from '@extra-types/additional-product-type';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { ensureNotUndefined } from '@helpers/function-helpers';
import { getModalityStaticData } from '@helpers/modality-helpers';
import { getProductListByModality } from '@helpers/product-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addExtraAdditionalProductToList,
  removeAdditionalProductFromCart,
  resetAdditionalProductCart,
} from '@store/slices/cart-slice';
import { fetchPricingWithoutLead } from '@store/thunks/cart-thunk';

import { styled } from '@src/themes';

import { CartPageHcmHeader } from './cart-page-hcm-header';
import { CartPageHcmSummary } from './cart-page-hcm-summary';

export function CartPageHCM() {
  const dispatch = useDispatch();
  const { productList } = useSelector(({ products }) => products);
  const { isLoading } = useSelector(({ customer }) => customer);
  const {
    cartModality,
    productCartList,
    isCartLoading,
    extraAdditionalProductList,
    contractedProducts,
  } = useSelector(({ cart }) => cart);
  const methods = useForm<HCMFormType>({
    mode: 'onChange',
    defaultValues: {
      'average-employees': productCartList[0].amount,
      planType: productCartList[0].planType,
    },
  });

  const modalityData = getModalityStaticData(
    cartModality?.id || ModalitiesId.HCM,
  );

  const productsForSale = getProductListByModality(
    modalityData.id,
    productList,
  );

  const productData = useMemo(
    () =>
      ensureNotUndefined(
        productsForSale.find(plan => productCartList[0].id === plan.id),
      ),
    [productCartList, productsForSale],
  );

  const extensionList = useMemo(
    () =>
      productData.additionalProductList
        .filter(
          product => product.productType === AdditionalProductTypeEnum.EXTENSAO,
        )
        .map(extension => {
          if (contractedProducts.some(e => e.id === extension.id)) {
            return {
              ...extension,
              isRequired: true,
            };
          }
          return extension;
        }),
    [contractedProducts, productData.additionalProductList],
  );

  const [extensions, setExtensions] = useState<{
    included: AdditionalProductType[];
    excluded: AdditionalProductType[];
  }>({
    included: extensionList.filter(product => !!product.isRequired),
    excluded: extensionList.filter(product => !product.isRequired),
  });

  function handleExtensions(id: number) {
    const remove = () => {
      setExtensions(prevState => {
        const included = prevState.included.filter(
          product => product.id !== id,
        );
        const excluded = prevState.excluded.concat(
          prevState.included.find(product => product.id === id) ||
            prevState.excluded,
        );

        return { included, excluded };
      });
    };
    const add = () => {
      setExtensions(prevState => {
        const included = prevState.included.concat(
          prevState.excluded.find(product => product.id === id) ||
            prevState.included,
        );

        const excluded = prevState.excluded.filter(
          product => product.id !== id,
        );

        return { included, excluded };
      });

      dispatch(
        addExtraAdditionalProductToList(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          extensionList.find(extension => extension.id === id)!,
        ),
      );
    };

    return { remove, add };
  }

  const handleCartExtensions = useCallback(() => {
    setExtensions(prevState => {
      if (extraAdditionalProductList.length === 0) {
        return {
          included: extensionList.filter(product => !!product.isRequired),
          excluded: extensionList.filter(product => !product.isRequired),
        };
      }

      return prevState;
    });
  }, [extensionList, extraAdditionalProductList]);

  useEffect(() => {
    handleCartExtensions();
  }, [handleCartExtensions]);

  useEffect(() => {
    if (extraAdditionalProductList.length !== 0) {
      extraAdditionalProductList.forEach(extra => {
        dispatch(removeAdditionalProductFromCart(extra.id));
      });
    }
    dispatch(resetAdditionalProductCart());
    dispatch(fetchPricingWithoutLead());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CartPageHCMContainer>
      <FormProvider {...methods}>
        <CartPageHcmSummary modalityData={modalityData}>
          <CartPageHcmHeader
            isFormActive
            modalityData={modalityData}
            plan={productData}
          />
          <Details summary="Módulos">
            <div
              className={`modules-holder ${
                isCartLoading || isLoading ? 'disabled' : ''
              }`}
            >
              {productData.additionalProductList
                .filter(
                  product =>
                    product.productType === AdditionalProductTypeEnum.MODULO,
                )
                .map(module => (
                  <AdditionalProductHcm.Modules
                    key={`module-${module.id}`}
                    id={module.id}
                    name={module.name}
                    description={module.description}
                  />
                ))}
            </div>
          </Details>
          <Details summary="Extensões">
            <div
              className={`extensions-holder ${
                isCartLoading || isLoading ? 'disabled' : ''
              }`}
            >
              {extensions.included.map(module => (
                <AdditionalProductHcm.Extension
                  key={`extension-included-${module.id}`}
                  id={module.id}
                  name={module.name}
                  description={module.description}
                  isIncluded
                  isRequired={module.isRequired}
                  handleExtensions={() => handleExtensions(module.id)}
                />
              ))}
              {extensions.excluded.map(module => (
                <AdditionalProductHcm.Extension
                  key={`extension-excluded-${module.id}`}
                  id={module.id}
                  name={module.name}
                  description={module.description}
                  isIncluded={false}
                  isRequired={module.isRequired}
                  handleExtensions={() => handleExtensions(module.id)}
                />
              ))}
            </div>
          </Details>
        </CartPageHcmSummary>
      </FormProvider>
    </CartPageHCMContainer>
  );
}

export const CartPageHCMContainer = styled('div', {
  '.modules-holder, .extensions-holder': {
    border: '1px solid #E4E8ED',
    borderRadius: '8px 8px',
    overflow: 'hidden',

    margin: '0.625rem 1rem 0 1rem',
  },

  '.disabled': {
    pointerEvents: 'none',
    cursor: 'not-allowed',
    opacity: 0.5,
  },
});
