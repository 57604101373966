/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { MobilityOfferDescriptionType } from '@config/mobility-config';

import { useWindowSize } from '@hooks/use-window-size';

import { styled } from '@src/themes';

interface OfferTableViewProps {
  offerConfig: MobilityOfferDescriptionType[];
  orderValue: number;
}

export default function OfferTableView({
  offerConfig,
  orderValue,
}: OfferTableViewProps) {
  const deviceSize = useWindowSize();

  if (deviceSize === 'sm' || deviceSize === 'xs') {
    return (
      <OfferTableViewContainer>
        <div className="offer-view-table-header">
          <div className="description-details-table">
            <p>Descrição</p>
          </div>
        </div>
        {offerConfig.map(item => (
          <div key={item.id} className="offer-table-view-container">
            <div className="offer-view-title-details">
              <p className="offer-view-title">{item.title}</p>
              <p>{item.description}</p>
            </div>
            <div className="offer-view-base-details">
              <p>Base</p>
              {Number.isNaN(Number(item.base))
                ? item.base
                : `${Number(item.base) * 100}%`}
            </div>
            <div className="offer-view-value-details">
              <p>Valor Total</p>
              {formatValueToCurrency(
                orderValue * Number(item.base) || item.base,
              )}
            </div>
          </div>
        ))}
      </OfferTableViewContainer>
    );
  }

  return (
    <>
      <div className="bonus-details-table-header">
        <div className="description-details-table">
          <p>Descrição</p>
        </div>
        <div className="base-details-table">
          <p>Base</p>
        </div>
        <div className="value-details-table">
          <p>Valor Total</p>
        </div>
      </div>
      {offerConfig.map(item => (
        <div key={item.id} className="timezone-tax-details">
          <div className="timezone-title-details">
            <p className="timezone-title">{item.title}</p>
            <p>{item.description}</p>
          </div>
          <div className="timezone-base-details">
            {Number.isNaN(Number(item.base))
              ? item.base
              : `${Number(item.base) * 100}%`}
          </div>
          <div className="timezone-value-details">
            {formatValueToCurrency(orderValue * Number(item.base) || item.base)}
          </div>
        </div>
      ))}
    </>
  );
}

const OfferTableViewContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '.offer-view-table-header': {
    gridArea: 'title',
    textAlign: 'start',
    width: '100%',
    backgroundColor: '#E4E8ED',
    padding: '1rem',
    borderRadius: '8px 8px 0px 0px',
    fontWeight: '500',
  },

  '.offer-table-view-container': {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr 1fr 1fr`,
    gridTemplateAreas: `
    'title title title title'
    'description description description description'
    'base value value value'
    `,
    gap: '0.875rem',
    paddingBottom: '1rem',
    border: '1px solid #E4E8ED',

    p: {
      color: '$fontColorPrimary',
      fontWeight: '500',
    },

    '.offer-view-title-details': {
      gridArea: 'description',
      textAlign: 'start',
      padding: '0 1rem',

      '.offer-view-title': {
        color: '$primaryPure',
      },
    },

    '.offer-view-base-details': {
      gridArea: 'base',
      textAlign: 'start',
      padding: '0 1rem',
    },

    '.offer-view-value-details': {
      gridArea: 'value',
      textAlign: 'start',
      padding: '0 1rem',
      color: '$primaryPure',
    },

    '@deviceSm': {
      gridTemplateColumns: `2fr 1fr 1fr 1fr`,
    },
  },
});
