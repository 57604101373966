import { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import formatCurrencyToValue from '@vr/devkit/money/formatCurrencyToValue';
import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';
import debounce from 'lodash.debounce';

import { ProductItemContainer } from '@components/cart-page-product-list-form-components/cart-page-individual-form-product-item/styles';
import { Input } from '@components/input';
import { ProductImage } from '@elements/product-image';
import { ProductNameText } from '@elements/product-name-text';
import { Switch } from '@elements/switch';
import { IndividualFormInputs } from '@pages/offer-page/custom-offer-pages/components/combo-product-services-form/combo-default-service-form';

import { DIGIT_WAIT_TIME_TO_PRICE } from '@config/application-config';
import {
  FONT_SIZE_INPUT_PRODUCT_PAGE,
  MAX_LENGTH_CURRENCY_VALUE,
  MAX_LENGTH_QUANTITY_CARDS,
  MIN_CARD_QUANTITY,
} from '@config/input-config';
import { PRODUCT_OFFER_PHRASE } from '@config/products-config';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { getProductFromCart } from '@helpers/cart-helpers';
import { FormErrors } from '@helpers/enum/form-errors';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { removeSpecialCaracters } from '@helpers/function-helpers';
import { getProductData, getProductType } from '@helpers/product-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addProductToComboCartList,
  removeProductFromCart,
} from '@store/slices/cart-slice';
import { fetchCartPricing } from '@store/thunks/cart-thunk';

import { OfferFacade } from '@src/facade/offer-facade';

interface ProductComboIndividualInputProps
  extends HTMLAttributes<HTMLFormElement> {
  productId: number;
}

export function ProductComboIndividualInput({
  productId,
}: ProductComboIndividualInputProps) {
  const { selectedOffer, offerDetails } = OfferFacade();
  const dispatch = useDispatch();
  const { config, isLoading } = useSelector(({ application }) => application);
  const { isLoading: isCustomerLoading, unservedRegionProducts } = useSelector(
    ({ customer }) => customer,
  );
  const { productCartList, isCartLoading } = useSelector(({ cart }) => cart);
  const productList = useSelector(({ products }) => products.productList);
  const [isActive, setActive] = useState(false);
  const { handleActiveSummary } = useComboCartPageContext();

  const offerPhrase = PRODUCT_OFFER_PHRASE[productId] || (
    <div>Produto não encontrado</div>
  );

  const productCartData = getProductFromCart({ productId, productCartList });

  const productData = useMemo(
    () => getProductData(productId, productList),
    [productId, productList],
  );

  const { register, watch, handleSubmit, formState, trigger, resetField } =
    useFormContext<IndividualFormInputs>();

  const minCardAmount =
    offerDetails.oferta.itemsOferta.find(
      item => item.codigoProduto === productId,
    )?.quantidadeMinima ||
    selectedOffer?.quantidadeCartoesMinima ||
    MIN_CARD_QUANTITY;
  const maxCardAmount =
    offerDetails.oferta.itemsOferta.find(
      item => item.codigoProduto === productId,
    )?.quantidadeMaxima ||
    selectedOffer?.quantidadeCartoesMaxima ||
    config.maxBeneficiaryAmount;
  const minCardValue =
    offerDetails.oferta.itemsOferta.find(
      item => item.codigoProduto === productId,
    )?.valorMinimo ||
    selectedOffer?.valorMinimo ||
    config.minCardValue;
  const maxCardValue =
    offerDetails.oferta.itemsOferta.find(
      item => item.codigoProduto === productId,
    )?.valorMaximo ||
    selectedOffer?.valorMaximo ||
    config.maxCardValue;
  const productAmount = watch(`products.${productId}.amount`);
  const productValue = watch(`products.${productId}.value`);

  const totalValue = productAmount * Number(productValue) || 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(async () => {
      const isFormValid = await trigger(['products']);
      handleSubmit((form: IndividualFormInputs) => {
        if (
          isFormValid &&
          !!form.products[productId].amount &&
          !!form.products[productId].value &&
          (form.products[productId].amount !== productCartData?.amount ||
            Number(form.products[productId].value) !== productCartData?.value)
        ) {
          dispatch(
            addProductToComboCartList({
              modalityId: ModalitiesId.DEFAULT,
              product: {
                id: productId,
                type: getProductType(productData),
                amount: form.products[productId].amount,
                value: Number(form.products[productId].value),
                planType: PlanTypeEnum.MENSAL,
              },
            }),
          );
          handleActiveSummary({ id: ModalitiesId.DEFAULT.toString() });
          dispatch(fetchCartPricing({ offerData: selectedOffer }));
        }
      })();
    }, DIGIT_WAIT_TIME_TO_PRICE),
    [handleSubmit, dispatch, productId, productCartData],
  );

  const handleActive = useCallback(() => {
    if (isActive) {
      dispatch(removeProductFromCart({ productId }));
      if (productValue) {
        dispatch(fetchCartPricing({ offerData: selectedOffer }));
      }
      setActive(false);
    } else {
      setActive(true);
    }
  }, [isActive, dispatch, productId, productValue, selectedOffer]);

  if (productData === undefined) {
    return null;
  }

  const isProductUnservedRegion = () => {
    return unservedRegionProducts.some(product => product === productData.id);
  };

  if (isProductUnservedRegion()) {
    return (
      <ProductItemContainer
        as="div"
        css={{
          '@deviceMd': {
            alignItems: 'flex-start',
            flexDirection: 'column',
          },
        }}
      >
        <ProductImage productId={productId} />
        {productData.name}
        <div className="product-individual-description-container__unserved-region">
          <p>Este produto não está disponível em sua região</p>
        </div>
      </ProductItemContainer>
    );
  }

  if (!isActive) {
    return (
      <ProductItemContainer as="div" className="offer-container">
        <div className="product-item__switch product-item__switch--unselected">
          <Switch
            disabled={isCartLoading || isCustomerLoading}
            isToggled={isActive}
            onToggle={handleActive}
          />
        </div>
        <div className="product-item__offer-text">
          <ProductImage productId={productId} />
          <div className="offer-text__product-information">
            <p className="product-information__product-name">
              <ProductNameText id={productId} />
            </p>
            <div className="product-information__text">{offerPhrase}</div>
          </div>
        </div>
      </ProductItemContainer>
    );
  }

  return (
    <ProductItemContainer
      as="div"
      css={{
        $$productColor: `$colors$${productData.colorPrefixToken}ColorName`,
      }}
    >
      <div className="product-item__switch">
        <Switch
          isToggled={isActive}
          onToggle={handleActive}
          disabled={isCartLoading || isCustomerLoading}
        />
      </div>

      <div className="product-item__title">
        <ProductImage productId={productId} />
        {productData.name}
      </div>
      <div className="product-item__product-amount">
        <Input.Default
          id={`product-summary-${removeSpecialCaracters(
            productData.name,
          )}-amount`}
          width="100%"
          customMask="999"
          maxLength={MAX_LENGTH_QUANTITY_CARDS}
          placeholder="0"
          fontSize={FONT_SIZE_INPUT_PRODUCT_PAGE}
          label="Qnt. Cartões"
          disabled={isCartLoading || isLoading || isCustomerLoading}
          errorMessage={
            formState.errors?.products &&
            formState.errors?.products[productId]?.amount?.message
          }
          inputMode="decimal"
          {...register(`products.${productId}.amount` as const, {
            onChange: () => debouncedSave(),
            valueAsNumber: true,
            shouldUnregister: true,
            required: 'É obrigatório',
            min: {
              value: minCardAmount,
              message: `${FormErrors.MIN_CARD_AMOUNT} ${minCardAmount}`,
            },
            max: {
              value: maxCardAmount,
              message: `${FormErrors.MAX_CARD_AMOUNT} ${maxCardAmount}`,
            },
          })}
        />
      </div>
      <div className="product-item__product-value">
        <Input.Default
          id={`product-summary-${removeSpecialCaracters(
            productData.name,
          )}-value`}
          width="100%"
          maskMoney
          placeholder="R$ 0,00"
          maxLength={MAX_LENGTH_CURRENCY_VALUE}
          fontSize={FONT_SIZE_INPUT_PRODUCT_PAGE}
          label="Valor mensal"
          disabled={isCartLoading || isLoading || isCustomerLoading}
          errorMessage={
            formState.errors?.products &&
            formState.errors?.products[productId]?.value?.message
          }
          inputMode="numeric"
          {...register(`products.${productId}.value` as const, {
            deps: 'amount',
            shouldUnregister: true,
            onChange: event => {
              if (event.target.value === 'R$ 0,00') {
                resetField(`products.${productId}.value`, {
                  defaultValue: event.target.value.replace('R$ 0,00', ''),
                });
              }
              debouncedSave();
            },
            setValueAs: value =>
              value && formatCurrencyToValue(value).toFixed(2),
            required: 'É obrigatório',
            validate: {
              min: value => {
                return value !== ''
                  ? Number(value) >= minCardValue ||
                      `${FormErrors.MIN_CARD_VALUE} ${formatValueToCurrency(
                        minCardValue,
                      )}`
                  : true;
              },
              max: value =>
                value !== ''
                  ? Number(value) <= maxCardValue ||
                    `${FormErrors.MAX_CARD_VALUE} ${formatValueToCurrency(
                      maxCardValue,
                    )}`
                  : true,
            },
          })}
        />
      </div>
      <div className="product-item__total-value" data-testid="total-value">
        {formatValueToCurrency(totalValue)}
      </div>
    </ProductItemContainer>
  );
}
