export const ASSETS_BANNERS_PATH = '/images/banners';
export const ASSETS_CARDS_PATH = '/images/cards';
export const ASSETS_PRODUCT_ADDONS_PATH = '/images/logos/addons';
export const ASSETS_OTHERS_PATH = '/images/others';
export const ASSETS_ICONS_PATH = '/images/icons';
export const ASSETS_MOBILITY_PATH = '/images/others/mobilidade';
export const ASSETS_THUMBNAILS_PATH = '/images/thumbnails';
export const ASSETS_AWARDS_PATH = '/images/others/awards';
export const ASSETS_LOGOS_PATH = '/images/logos';
export const ASSETS_FLAGS_PATH = '/images/flags';
export const ASSETS_IMAGES_PAGE_PATH = '/images/pages';
export const ASSETS_ICON_FAQ_PATH = '/images/faq/icons';
export const ASSETS_VIDEOS_PATH = '/videos';
export const ASSETS_ILLUSTRATIONS_PATH = '/images/others/illustrations';
