import { styled } from '@src/themes';

type HCMBenefitsBoxProps = {
  icon: string;
  title: string;
  description: string;
};

export function HCMBenefitsBox({
  icon,
  title,
  description,
}: HCMBenefitsBoxProps) {
  return (
    <HCMBenefitsBoxContainer>
      <i className={icon} />
      <h3>{title}</h3>
      <p>{description}</p>
    </HCMBenefitsBoxContainer>
  );
}

const HCMBenefitsBoxContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  padding: '1rem',
  borderRadius: '8px',
  border: '1px solid $primaryPure',
  backgroundColor: '$white',
  minWidth: '250px',

  i: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '4rem',
    width: '4rem',

    fontSize: '1.5rem',
    backgroundColor: '$primaryPure',
    color: '$white',
    borderRadius: '50%',
  },

  p: {
    maxWidth: '250px',
  },
});
