import { HTMLAttributes } from 'react';

import { styled } from '@src/themes';

interface MobilitySectionTitleProps extends HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  color?: string;
  marginBottom?: string;
}

export function MobilitySectionTitle({
  color = '$black',
  children,
  marginBottom,
  ...props
}: MobilitySectionTitleProps) {
  return (
    <Title
      css={{
        color,
        marginBottom: marginBottom || '',
      }}
      {...props}
    >
      {children}
    </Title>
  );
}

const Title = styled('h2', {
  fontSize: '1.5rem',
  lineHeight: 1.25,

  '@deviceSm': {
    textAlign: 'center',
  },

  variants: {
    dark: {
      true: {
        color: '$fontColorPrimary',
      },
    },
  },
});
