import { useContext } from 'react';

import { ChatContext, ChatContextData } from '@store/context/chat-context';

export function useChatContext(): ChatContextData {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChatContext must be used within a ChatContext.');
  }

  const { setOpenChat } = context;

  return { setOpenChat };
}
