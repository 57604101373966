import { useState } from 'react';

import { ComboEntryTax } from '@pages/offer-page/custom-offer-pages/elements/combo-cart-summary-elements/combo-entry-tax';

import { PricingResumeType } from '@extra-types/pricing-types';
import { removeSpecialCaracters } from '@helpers/function-helpers';
import { valueSelector } from '@helpers/product-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

interface ComboTaxTableProps {
  serviceId: string;
  pricingResumeData?: PricingResumeType;
  variant?: 'default' | 'summary-view';
}

export function ComboTaxTable({
  serviceId,
  pricingResumeData,
  variant,
}: ComboTaxTableProps) {
  const [isCollapsed, setCollapseState] = useState(true);
  const id = `${removeSpecialCaracters(serviceId).toLowerCase()}-taxas`;

  const handleCollapseState = () => {
    setCollapseState(state => !state);
  };

  if (!pricingResumeData) {
    return null;
  }

  if (pricingResumeData.lancamentos.length === 0) {
    return (
      <ComboTaxTableContainer variant={variant} isCollapsed>
        <div
          className="combo-form-table-container__title-wrapper"
          id={id}
          data-testid={id}
        >
          <div className="title-wrapper__title-label">
            <span>Total de tarifas</span>
          </div>
          <div className="title-wrapper__value-label">
            {valueSelector({ value: pricingResumeData.valorTotalTaxas })}
          </div>
        </div>
      </ComboTaxTableContainer>
    );
  }

  return (
    <ComboTaxTableContainer
      variant={variant}
      isCollapsed={isCollapsed}
      cursor="pointer"
    >
      <div
        className="combo-form-table-container__title-wrapper"
        id={id}
        data-testid={id}
        tabIndex={0}
        role="button"
        onKeyDown={event => event.key === 'Enter' && handleCollapseState()}
        onClick={handleCollapseState}
      >
        <div className="title-wrapper__title-label">
          <span>Total de tarifas</span>
          <i className="fa-solid fa-caret-down" />
        </div>
        <div className="title-wrapper__value-label">
          {valueSelector({ value: pricingResumeData.valorTotalTaxas })}
        </div>
      </div>
      <div className="combo-form-table-container__details-wrapper">
        <div className="details-wrapper__title">
          <span>Descrição</span>
          <span>Base</span>
          <span>Valor Total</span>
        </div>
        <ul>
          {pricingResumeData.lancamentos.map(entry => (
            <ComboEntryTax
              key={entry.identificacaoCondicaoComercial}
              entryData={entry}
              variant={variant}
            />
          ))}
        </ul>
      </div>
    </ComboTaxTableContainer>
  );
}

const ComboTaxTableContainer = styled('div', {
  borderRadius: '0.5rem',
  border: '1px solid #F4F5F7',
  marginTop: '0.5rem',
  overflow: 'clip',

  '.combo-form-table-container__title-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    backgroundColor: '$silverLight',
    width: '100%',
    padding: '0.5rem',
  },

  '.title-wrapper__title-label': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',

    fontSize: '0.75rem',
    fontWeight: '$bold',
    textTransform: 'uppercase',

    i: {
      color: '$primaryPure',
      fontSize: convertPixelToRem(15),
      lineHeight: 0,

      transition: 'transform 0.4s ease',
    },
  },

  '.title-wrapper__value-label': {
    color: '$primaryPure',
    fontSize: '1rem',
    fontWeight: '$medium',
    lineHeight: 1.4,

    span: {
      fontSize: '1rem',
    },
  },

  '.details-wrapper__title': {
    display: 'grid',
    gridTemplateColumns: '1fr 8.625rem 8.625rem',

    backgroundColor: '$silverLight',
    width: '100%',
    padding: '0.75rem 0.5rem',

    span: {
      fontSize: '0.875rem',
      fontWeight: '$medium',

      '&:not(:first-child)': {
        textAlign: 'end',
      },
    },

    '@deviceMd': {
      gridTemplateColumns: '1fr 6rem 6rem',
    },

    '@deviceSm': {
      gridTemplateColumns: 'auto',

      padding: '0.5rem',

      span: {
        fontSize: '0.75rem',
      },

      'span:not(:first-child)': {
        display: 'none',
      },
    },
  },

  '.combo-form-table-container__details-wrapper': {
    height: 0,
    opacity: 0,
    transition: 'opacity 0.4s ease',

    ul: {
      'li:nth-child(even)': {
        borderTop: '1px solid #F4F5F7',
      },
    },
  },

  variants: {
    variant: {
      default: {},
      'summary-view': {
        marginTop: 0,
        marginBottom: '1rem',

        '.title-wrapper__title-label': {
          textTransform: 'unset',
        },

        '.title-wrapper__value-label': {
          fontSize: '0.75rem',
          span: {
            fontSize: '0.75rem',
          },
        },

        '.details-wrapper__title': {
          display: 'flex',

          padding: '0.5rem',

          span: {
            fontSize: '0.75rem',
          },

          'span:not(:first-child)': {
            display: 'none',
          },
        },
      },
    },
    isCollapsed: {
      false: {
        '.title-wrapper__title-label': {
          i: {
            transform: 'rotate(180deg)',
          },
        },

        '.combo-form-table-container__details-wrapper': {
          height: 'auto',
          opacity: 1,
        },
      },
    },
    cursor: {
      pointer: {
        '.combo-form-table-container__title-wrapper': {
          cursor: 'pointer',
        },
      },
      default: {},
    },
  },

  defaultVariants: {
    cursor: 'default',
    variant: 'default',
  },
});
