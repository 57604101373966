export const JVCEF = {
  colors: {
    primaryLight: '#0F92FF',
    primaryPure: '#005CA9',
    primaryDark: '#004075',

    secondaryLight: '#FFA829',
    secondaryPure: '#F39200',
    secondaryDark: '#C27300',

    tertiaryLight: '#EFF4F5',
    tertiaryPure: '#8FB5BC',
    tertiaryDark: '#3A4859',

    headerBackgroundColor: '$primaryPure',
    footerBackgroundColor: '$primaryPure',
    sectionBackgroundSecondaryColor: '$primaryPure',
    faqBackgroundColor: '$primaryDark',
    featuredFlagBackground: '$primaryDark',
    disabledPure: '$tertiaryPure',
    disabledFontPure: '$white',

    switchBackgroundInitial: '#A9B6C1',
    switchInnerBackgroundInitial: '#5B6F80',
    switchBackgroundEnd: '$primaryPure',
    switchInnerBackgroundEnd: '#FFFFFF',

    // Buttons ------------------

    buttonPrimaryBackgroundColor: '$secondaryPure',
    buttonPrimaryBorderColor: '$secondaryPure',
    buttonPrimaryTextColor: '$white',
    buttonPrimaryBackgroundColorHover: '$secondaryLight',
    buttonPrimaryBorderColorHover: '$secondaryLight',
    buttonPrimaryTextColorHover: '$white',

    buttonPrimaryOutlineBackgroundColor: 'transparent',
    buttonPrimaryOutlineBorderColor: '$primaryPure',
    buttonPrimaryOutlineTextColor: '$primaryPure',
    buttonPrimaryOutlineBackgroundColorHover: '#F1F9FF',
    buttonPrimaryOutlineBorderColorHover: '$primaryPure',
    buttonPrimaryOutlineTextColorHover: '$primaryPure',

    buttonPrimaryNegativeBackgroundColor: '$white',
    buttonPrimaryNegativeBorderColor: '$white',
    buttonPrimaryNegativeTextColor: '$primaryPure',
    buttonPrimaryNegativeBackgroundColorHover: '$primaryLight',
    buttonPrimaryNegativeBorderColorHover: '$primaryLight',
    buttonPrimaryNegativeTextColorHover: '$white',

    buttonPrimaryDisabledBackgroundColor: '$disabledPure',
    buttonPrimaryDisabledBorderColor: '$disabledPure',
    buttonPrimaryDisabledTextColor: '$disabledFontPure',
    buttonPrimaryDisabledBackgroundColorHover: '$disabledPure',
    buttonPrimaryDisabledBorderColorHover: '$disabledPure',
    buttonPrimaryDisabledTextColorHover: '$disabledFontPure',

    buttonSecondaryBackgroundColor: 'transparent',
    buttonSecondaryBorderColor: '$primaryPure',
    buttonSecondaryTextColor: '$primaryPure',
    buttonSecondaryBackgroundColorHover: '#F1F9FF',
    buttonSecondaryBorderColorHover: '$primaryPure',
    buttonSecondaryTextColorHover: '$primaryPure',

    buttonSecondaryOutlineBackgroundColor: 'transparent',
    buttonSecondaryOutlineBorderColor: '$primaryPure',
    buttonSecondaryOutlineTextColor: '$primaryPure',
    buttonSecondaryOutlineBackgroundColorHover: '#F1F9FF',
    buttonSecondaryOutlineBorderColorHover: '$primaryPure',
    buttonSecondaryOutlineTextColorHover: '$primaryPure',

    buttonSecondaryNegativeBackgroundColor: '$transparent',
    buttonSecondaryNegativeBorderColor: '$white',
    buttonSecondaryNegativeTextColor: '$white',
    buttonSecondaryNegativeBackgroundColorHover: '$primaryLight',
    buttonSecondaryNegativeBorderColorHover: '$primaryLight',
    buttonSecondaryNegativeTextColorHover: '$white',

    buttonSecondaryDisabledBackgroundColor: 'transparent',
    buttonSecondaryDisabledBorderColor: '$disabledPure',
    buttonSecondaryDisabledTextColor: '$disabledPure',
    buttonSecondaryDisabledBackgroundColorHover: 'transparent',
    buttonSecondaryDisabledBorderColorHover: '$disabledPure',
    buttonSecondaryDisabledTextColorHover: '$disabledPure',

    alimentacaoPrimaryLight: '#00B4E6',
    alimentacaoPrimaryPure: '#006480',
    alimentacaoPrimaryDark: '#008CB2',
    alimentacaoSecondaryLight: '#FFBD5C',
    alimentacaoSecondaryPure: '#F39200',
    alimentacaoSecondaryDark: '#C27300',
    alimentacaoColorName: '$alimentacaoPrimaryDark',
    refeicaoPrimaryLight: '#30786D',
    refeicaoPrimaryPure: '#3E9D8E',
    refeicaoPrimaryDark: '#30786D',
    refeicaoSecondaryLight: '#FFBD5C',
    refeicaoSecondaryPure: '#F39200',
    refeicaoSecondaryDark: '#C27300',
    refeicaoColorName: '$refeicaoPrimaryDark',

    multiPrimaryLight: '#0F92FF',
    multiPrimaryPure: '#005CA9',
    multiPrimaryDark: '#004075',

    multiColorName: '$multiPrimaryDark',
  },
  fonts: {
    default: 'Futura, Arial, sans-serif',
  },
};
