import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import {
  FAQ_RETRY_DELAY_TIME,
  FAQ_RETRY_MAX_ATTEMPTS,
} from '@config/faq-config';
import { ProductShelfType } from '@extra-types/product-shelf-type';
import { isDefaultChannel } from '@helpers/channel-helpers';
import { formatEcosystemProductListResponseToEcosystemProductTypeList } from '@helpers/ecosystem-product-helpers';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { OfferType } from '@helpers/enum/offer-type';
import { ProductsId } from '@helpers/enum/products-id';
import {
  filterModalityListBymodalityIdList,
  getModalityListByProductList,
  sortModalitiesByDisplayOrder,
} from '@helpers/modality-helpers';
import {
  deleteProductsFromProductList,
  filterProductListByOfferType,
  filterProductListByProductIdList,
  getProductListByModality,
} from '@helpers/product-helpers';

import ecosystemProductService from '@api/services/ecosystem-product-service';

import useDefaultHeader from './use-default-header';
import { useSelector } from './use-selector';

interface UseProductsShelfProps {
  loadEcosystemProducts?: boolean;
  showProductsOnSingleModality?: boolean;
  productIdList?: ProductsId[];
  productsToDelete?: ProductsId[];
}

export function useProductsShelf({
  loadEcosystemProducts = false,
  showProductsOnSingleModality = false,
  productIdList = [],
  productsToDelete = [],
}: UseProductsShelfProps = {}) {
  const { defaultHeader } = useDefaultHeader();
  const {
    data: ecosystemData,
    isError,
    isLoading: isEcosystemLoading,
  } = useQuery({
    queryKey: [
      loadEcosystemProducts
        ? 'ecosystemProductsData'
        : 'ecosystemProductsDataDisabled',
    ],
    queryFn: () =>
      ecosystemProductService
        .getEcosystemProducts(defaultHeader)
        .then(response =>
          formatEcosystemProductListResponseToEcosystemProductTypeList(
            response.produtosDisponiveis,
          ),
        ),
    refetchOnWindowFocus: false,
    retry: FAQ_RETRY_MAX_ATTEMPTS,
    retryDelay: FAQ_RETRY_DELAY_TIME,
    enabled: loadEcosystemProducts,
  });

  const { siglaCanal } = useSelector(({ application }) => application.config);
  const { modalityList, productList, isLoading } = useSelector(
    ({ products }) => products,
  );

  const productListWithoutDeletedProducts = deleteProductsFromProductList({
    productList,
    productsToDelete,
  });

  const productListFiltered = filterProductListByProductIdList({
    productList: productListWithoutDeletedProducts,
    productIdList,
  });

  const modalityListFiltered = filterModalityListBymodalityIdList(
    modalityList,
    getModalityListByProductList(productListFiltered),
  );

  const productsShelfList: ProductShelfType[] = useMemo(() => {
    const newProductShelfList: ProductShelfType[] = [];
    if (
      showProductsOnSingleModality &&
      modalityListFiltered.length === 1 &&
      modalityListFiltered[0].singleModalityProductsShelfView
    ) {
      filterProductListByOfferType(productListFiltered, OfferType.SALE).forEach(
        product => {
          newProductShelfList.push({
            id: product.id,
            keyId: `${product.id}`,
            shelfInformation: {
              title: product.name,
              description: product.description,
              illustration: '',
            },
            description: product.description,
            alternativeText: product.name,
            isFeatured: false,
            isWalletGroup: false,
            pageRoute: product.pagePath || '',
            type: 'product',
            products: [],
          });
        },
      );
    } else {
      const filteredModalityList = isDefaultChannel(siglaCanal)
        ? modalityListFiltered
        : modalityListFiltered.filter(
            modality => modality.id !== ModalitiesId.MOBILIDADE,
          );

      sortModalitiesByDisplayOrder(filteredModalityList).forEach(modality => {
        newProductShelfList.push({
          id: modality.id,
          keyId: modality.keyId,
          shelfInformation: modality.shelfInformation,
          alternativeText: modality.alternativeText,
          isFeatured: modality.isFeatured,
          pageRoute: modality.pageRoute,
          isWalletGroup: modality.isWalletGroupCard,
          description: modality.description,
          type: 'modality',
          products: getProductListByModality(modality.id, productListFiltered),
        });
      });
    }

    if (!isError && ecosystemData) {
      ecosystemData.forEach(ecosystemProduct =>
        newProductShelfList.push({
          id: ecosystemProduct.id,
          keyId: ecosystemProduct.keyId,
          shelfInformation: ecosystemProduct.shelfInformation,
          alternativeText: ecosystemProduct.alternativeText,
          isFeatured: ecosystemProduct.isFeatured,
          description: ecosystemProduct.description,
          isWalletGroup: false,
          pageRoute: ecosystemProduct.pageRoute,
          type: 'modality',
          products: [],
        }),
      );
    }

    return newProductShelfList;
  }, [
    ecosystemData,
    isError,
    modalityListFiltered,
    productListFiltered,
    showProductsOnSingleModality,
    siglaCanal,
  ]);

  const ecosystemLoading = loadEcosystemProducts ? isEcosystemLoading : false;

  return { productsShelfList, isLoading: isLoading || ecosystemLoading };
}
