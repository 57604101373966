import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { COOKIES_CLIENT_ID_LABEL } from '@config/application-config';

import { CookiesTime } from './enum/cookies-time';

export function setApplicationCookies() {
  const clientId = uuidv4();

  Cookies.set(COOKIES_CLIENT_ID_LABEL, clientId, {
    expires: CookiesTime.inOneHundredDays,
  });
}

export function removeApplicationCookies() {
  Cookies.remove(COOKIES_CLIENT_ID_LABEL);
}
