import { useSelector } from '@hooks/use-selector';

import { DeliverySectionContainer } from './styles';

export function DeliverySection({ className }: { className?: string }) {
  const emissor = useSelector(({ application }) => application.config.emissor);

  return (
    <DeliverySectionContainer className={className}>
      <div className="delivery-session__header">
        <i className="fa-solid fa-truck-fast" />
        <div className="header__title">
          <span>Previsão</span>
          <span>de Entrega</span>
        </div>
      </div>
      <div className="delivery-session__content">
        {emissor.deliveryTimeList.map(delivery => (
          <div key={delivery.id} className="content__delivery-item">
            <p>{delivery.local}</p>
            <strong>{delivery.deadline}*</strong>
          </div>
        ))}
        <div className="content__delivery-item">
          <p>
            *ATENÇÃO: Os prazos de entrega só começam a valer após confirmação
            de pagamento do boleto.
          </p>
        </div>
      </div>
    </DeliverySectionContainer>
  );
}
