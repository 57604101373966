import { StepperVariantType } from '@extra-types/stepper-variant-type';

export const stepsForStepper: Record<
  StepperVariantType,
  Record<
    string,
    {
      position: number;
      label: string;
      isVisibleInStepBar: boolean;
    }
  >
> = {
  default: {
    '/pedido/carrinho': {
      position: 0,
      label: 'Carrinho',
      isVisibleInStepBar: true,
    },
    '/pedido/dados-empresa': {
      position: 1,
      label: 'Dados da Empresa',
      isVisibleInStepBar: true,
    },
    '/pedido/revisar-pedido': {
      position: 2,
      label: 'Revisar Pedido',
      isVisibleInStepBar: true,
    },
    '/pedido/checkout': {
      position: 3,
      label: 'Pedido Concluído',
      isVisibleInStepBar: false,
    },
  },
  combo: {
    '/combo/carrinho': {
      position: 0,
      label: 'Selecione as Soluções',
      isVisibleInStepBar: true,
    },
    '/combo/empresa': {
      position: 1,
      label: 'Dados da Empresa',
      isVisibleInStepBar: true,
    },
    '/combo/pedido': {
      position: 2,
      label: 'Revisar Pedido',
      isVisibleInStepBar: true,
    },
    '/combo/checkout': {
      position: 3,
      label: 'Pedido Concluído',
      isVisibleInStepBar: false,
    },
  },
};
