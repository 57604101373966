import { Swiper } from 'swiper/react';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const StyledSwiper = styled(Swiper, {
  '.swiper-button-prev, .swiper-button-next': {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(calc(-50% - 1rem))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    background: '$primaryDark',
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '$pill',
    transition: '.4s ease',
    cursor: 'pointer',

    color: '$white',

    '&::after': {
      fontSize: '0.75rem',
      fontFamily: '$fontAwesome',
      lineHeight: 1,
      letterSpacing: 0,
    },

    '&:hover': {
      background: '$secondaryLight',

      color: '$primaryDark',
    },

    '@deviceMd': {
      display: 'none',
    },
  },

  '.swiper-button-prev': {
    left: '0',

    '&::after': {
      content: String.raw`\f104`,
    },
  },

  '.swiper-button-next': {
    right: '0',

    '&::after': {
      content: String.raw`\f105`,
    },
  },

  '@media screen and (min-width: 1300px)': {
    '.swiper-button-prev': {
      left: '$$customArrowDistance',
    },
    '.swiper-button-next': {
      right: '$$customArrowDistance',
    },
  },

  '.swiper-pagination': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.875rem',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,

    height: '2rem',
    width: '100%',

    '@deviceMd': {
      gap: '1rem',
    },
  },

  '.swiper-pagination-bullet': {
    display: 'block',

    backgroundColor: '$fontColorPrimary',
    height: convertPixelToRem(10),
    width: convertPixelToRem(10),
    border: 'none',
    borderRadius: '6px',
    opacity: '0.3',
    cursor: 'pointer',
    transition: '.4s ease',

    fontSize: '0',

    '&.swiper-pagination-bullet-active': {
      backgroundColor: '$primaryPure',
      opacity: 1,
    },
  },
});
