import { styled } from '@src/themes';

interface ComboServiceFormServiceInformationProps {
  children: React.ReactNode;
  isCollapse: boolean;
}

export function ComboServiceFormServiceInformation({
  isCollapse,
  children,
}: ComboServiceFormServiceInformationProps) {
  return (
    <ComboServiceFormServiceInformationContainer isCollapse={isCollapse}>
      {children}
    </ComboServiceFormServiceInformationContainer>
  );
}

const ComboServiceFormServiceInformationContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `'product-info product-actions'
  'plan-type null'`,
  gap: '1rem 0',

  '.product-actions': {
    justifySelf: 'flex-end',
  },

  '.products-holder': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
    overflow: 'auto',
    padding: '1rem',

    '@deviceLg': {
      padding: '1rem',
      overflow: 'auto',
    },
  },

  background: '$white',
  padding: '1.25rem 1rem',
  border: '1px solid #E4E8ED',

  '@deviceSm': {
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `'product-info'
    'product-actions'
    'plan-type'`,

    '.product-actions': {
      justifySelf: 'flex-start',
    },

    '> div:first-child': {
      alignSelf: 'start',
    },
  },

  variants: {
    isCollapse: {
      true: {},
      false: {
        boxShadow: '0px 0px 8px #00000022',
      },
    },
  },
});
