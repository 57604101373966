import { SIGLA_CANAL_DEFAULT } from '@config/application-config';
import { customChannels } from '@config/channel-config';
import { ChannelInformationType } from '@extra-types/channel-information-type';

import { ChannelInformationResponse } from '@api/models/response/search-company-response';

export function formatChannelInformationResponseToChannelInformationType(
  channelInformationResponse: ChannelInformationResponse,
): ChannelInformationType {
  return {
    name: channelInformationResponse.nome,
    acronym: channelInformationResponse.sigla,
    email: channelInformationResponse.email,
    ddd: channelInformationResponse.ddd,
    telephone: channelInformationResponse.telefone,
  };
}

export function isCustomChannel(siglaCanal: string) {
  return customChannels.get(siglaCanal.toUpperCase());
}

export function isDefaultChannel(siglaCanal: string) {
  return (
    siglaCanal === SIGLA_CANAL_DEFAULT ||
    siglaCanal === 'WEB' ||
    siglaCanal === 'web'
  );
}
