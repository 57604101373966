import {
  PRODUCT_RENEGOTIATION_ALLOWED_LIST,
  SERVICE_RENEGOTIATION_LIST,
} from '@config/renegotiation-config';

export function getServiceRenegotiationMessage(serviceId: string) {
  const renegotiationData = SERVICE_RENEGOTIATION_LIST[serviceId];
  return renegotiationData ?? undefined;
}

export function verifyProductAllowsRenegotiation(productId: number) {
  return PRODUCT_RENEGOTIATION_ALLOWED_LIST.includes(productId);
}
