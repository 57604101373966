import { DefaultTokenVariantsType } from '@extra-types/token-variants-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export interface AwardItemContainerProps {
  variant: DefaultTokenVariantsType;
}

export const AwardItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexShrink: '0',

  width: convertPixelToRem(142),
  margin: `${convertPixelToRem(16)} ${convertPixelToRem(16)}`,

  p: {
    marginTop: convertPixelToRem(16),

    fontSize: convertPixelToRem(12),
    fontWeight: '$medium',
    textAlign: 'center',
  },

  img: {
    height: convertPixelToRem(100),
  },
  variants: {
    variant: {
      primary: { color: '$fontColorPrimary' },
      secondary: {
        color: '$white',
      },
    },
  },
});

export const AwardItemLinkContainer = styled('a', {
  borderRadius: '10px',
  transition: 'background-color 0.4s',
  textDecoration: 'none',

  variants: {
    variant: {
      primary: {
        '&:hover': { backgroundColor: '$silverLight' },
      },
      secondary: {
        '&:hover': { backgroundColor: '$primaryLight' },
      },
    },
  },
});
