import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ProductCartType } from '@extra-types/product-cart-type';
import { completeWithLeftZero } from '@helpers/string-helpers';

import { styled } from '@src/themes';

export function ProductCartPreview({ data }: { data: ProductCartType }) {
  return (
    <ProductCartPreviewContainer>
      <div className="product-cart-preview-container__information">
        <span>Quantidades</span>
        <span className="information__value">
          {completeWithLeftZero(data.amount)}
        </span>
      </div>
      <div className="product-cart-preview-container__information">
        <span>Valor por cartão</span>
        <span className="information__value">
          {formatValueToCurrency(data.value)}
        </span>
      </div>
    </ProductCartPreviewContainer>
  );
}

const ProductCartPreviewContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',

  width: '100%',
  padding: '0.5rem 1rem',
  borderTop: '1px solid $fontColorLight',
  borderBottom: '1px solid $fontColorLight',

  '.product-cart-preview-container__information': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    span: {
      fontSize: '0.75rem',
      fontWeight: '$medium',

      '&.information__value': {
        fontSize: '1rem',
      },
    },
  },
});
