import { Title } from '@elements/title';

import { ASSETS_IMAGES_PAGE_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { AllTokenVariantsType } from '@extra-types/token-variants-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

type ImagePositionType = 'right' | 'left';
interface AdvantagesItemProps {
  color: AllTokenVariantsType;
  title: string;
  image: {
    url: string;
    alt: string;
    position: ImagePositionType;
  };
  advantagesList: string[];
  hasLightBackground?: boolean;
}

export function AdvantagesItem({
  color,
  title,
  image,
  advantagesList,
  hasLightBackground = false,
}: AdvantagesItemProps) {
  return (
    <AvantagesItemContainer
      className={`advantages-item-container--direction-${image.position}`}
      css={{
        $$bulletColor: `$colors$${color}ColorName`,
        $$productColor: `$colors$${color}ColorName`,
      }}
      hasLightBackground={hasLightBackground}
    >
      <div className="advantages-item-container__image-holder">
        <img
          src={env.URL_ASSETS_CHANNEL + ASSETS_IMAGES_PAGE_PATH + image.url}
          alt={image.alt}
        />
      </div>
      <div className="advantages-item-container__content">
        <Title.Root
          fontColorVariant="primary"
          size="sm"
          barColor={color}
          barColorVariant="secondary"
        >
          <Title.Default tag="h3">{title}</Title.Default>
        </Title.Root>
        <ul>
          {advantagesList.map((advantageItem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{advantageItem}</li>
          ))}
        </ul>
      </div>
    </AvantagesItemContainer>
  );
}

const AvantagesItemContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  background: '$white',
  padding: '0 1rem',
  borderRadius: '10px',
  boxShadow: '0px 0px 8px #00000022',

  '&.advantages-item-container--direction-left': {
    flexDirection: 'row',
  },

  '&.advantages-item-container--direction-right': {
    flexDirection: 'row-reverse',
  },

  h3: {
    color: '$$productColor',
  },

  '.advantages-item-container__content': {
    alignSelf: 'start',

    padding: `${convertPixelToRem(40)} 1rem`,
    maxWidth: convertPixelToRem(800),

    '@deviceMd': {
      padding: '1.25rem 0 0',
    },

    ul: {
      paddingLeft: convertPixelToRem(20),

      li: {
        marginTop: convertPixelToRem(30),

        fontWeight: '$semibold',
        lineHeight: 1.6,

        '&::before': {
          content: '\\2022',
          color: '$$bulletColor',
          fontWeight: 'bold',
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1em',
        },

        '@deviceSm': {
          marginTop: convertPixelToRem(16),
          fontSize: convertPixelToRem(14),
        },
      },
    },
  },

  '.advantages-item-container__image-holder': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxWidth: convertPixelToRem(380),

    img: {
      flexShrink: 0,

      objectFit: 'contain',
      maxWidth: '100%',
    },
  },

  '@deviceLg': {
    '.advantages-item-container__image-holder': {
      maxWidth: convertPixelToRem(260),
    },
  },

  '@deviceMd': {
    flexDirection: 'column !important',

    padding: '1.5rem',
  },

  variants: {
    hasLightBackground: {
      true: {
        h3: {
          color: '$fontColorPrimary',
        },
      },
    },
  },
});
