import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class InvalidProductCodeError extends ApplicationError {
  constructor(message?: string) {
    super(
      SpecificsApiErrors.INVALID_PRODUCT_CODE,
      message || ApiErrorsMessages.DEFAULT,
    );
  }
}
