import { SENSEDIA_AUTH } from '@config/application-config';

import { getEmissor } from './emissor-helpers';

function getGlobal() {
  if (typeof globalThis !== 'undefined') {
    return globalThis;
  }
  if (typeof window !== 'undefined') {
    return window;
  }
  if (typeof global !== 'undefined') {
    return global;
  }

  throw new Error('unable to locate global object');
}

interface GlobalThis {
  vrAuth: {
    isSolved: boolean;
    promise: Promise<string>;
  };
}

export function getGlobalThisVrAuth() {
  const global = getGlobal() as unknown as GlobalThis;

  let { vrAuth } = global;

  if (!vrAuth) {
    vrAuth = {
      isSolved: true,
      promise: Promise.resolve(''),
    };

    global.vrAuth = vrAuth;
  }

  vrAuth.promise.then(() => {
    vrAuth.isSolved = true;
  });

  return vrAuth;
}

export function getSensediaAuth() {
  const { host } = window.location;
  const { initials } = getEmissor();

  if (host.indexOf('com.br') >= 0) {
    return {
      clientId: SENSEDIA_AUTH[initials].client_id_prd,
      basic: SENSEDIA_AUTH[initials].basic_prd,
    };
  }

  return {
    clientId: SENSEDIA_AUTH[initials].client_id_dev,
    basic: SENSEDIA_AUTH[initials].basic_dev,
  };
}
