import { setSession, recoverySession } from '@vr/frontend-devkit/session';
import { AxiosInstance as AxiosInstanceOrigin } from 'axios';

export interface AxiosInstance extends AxiosInstanceOrigin {
  cache(data: Cache): AxiosInstanceOrigin;
}

export interface CacheConfig {
  /**
   * Storage key name
   */
  key: string;

  /**
   * Defines the context group to which this key will belong.
   */
  context?: string;

  /**
   * Expire time in minutes
   * @default 10
   * @example
   * {
   *   key: 'MY_KEY',
   *   expiresMinute: 60 // 1hour
   * };
   */
  expires?: number;

  /**
   * Define the storage type
   *
   * session = SessionStorage;
   * local = LocalStorage
   * @default session
   */
  storage?: 'session' | 'local';
}

interface GenericObject {
  [key: string]: unknown;
}
interface CacheResponse {
  value: unknown;
  expires: number;
}

enum Storage {
  SESSION = 'session',
  LOCAL = 'local',
}

export type Cache = string | CacheConfig;

const DEFAULT = 'default';

function setStorage(
  storage: string,
  key: string,
  context: string,
  value: unknown,
) {
  if (storage === Storage.SESSION) {
    const data = recoverySession(key) ?? {};

    setSession(key, {
      ...data,
      [context]: value,
    });
  }
}

function recoveryStorage<T>(storage: string, key: string, context: string) {
  if (storage === Storage.SESSION) {
    const data = recoverySession(key);

    // eslint-disable-next-line no-prototype-builtins
    if (data?.hasOwnProperty(context)) {
      return (data as GenericObject)[context] as T;
    }
  }

  return null;
}

export function cache(
  instance: AxiosInstanceOrigin,
  data: Cache,
): AxiosInstanceOrigin {
  let context = DEFAULT;
  let key = '';
  let expires = 10;
  let storage: string = Storage.SESSION;

  if (typeof data !== 'string') {
    key = data.key;
    context = data.context ?? context;
    expires = data.expires ?? expires;
    storage = data.storage ?? Storage.SESSION;
  } else {
    key = data;
  }

  const requestId = instance.interceptors.request.use(request => {
    const storageData = recoveryStorage<CacheResponse>(storage, key, context);

    if (storageData && storageData.expires > new Date().getTime()) {
      request.adapter = configAdapter => {
        return Promise.resolve({
          cached: true,
          data: storageData.value,
          status: 200,
          statusText: 'OK',
          headers: request.headers,
          config: configAdapter,
          request,
        });
      };
    }

    // NOTE: O setTimeout está sendo utilizado como garantia de retorno da instância antes da ejeção do interceptor.
    setTimeout(() => instance.interceptors.request.eject(requestId));

    return request;
  });

  const responseId = instance.interceptors.response.use(response => {
    const dateNow = new Date();
    // eslint-disable-next-line no-prototype-builtins
    if (!response.hasOwnProperty('cached')) {
      setStorage(storage, key, context, {
        value: response.data,
        expires: dateNow.setMinutes(dateNow.getMinutes() + expires),
      });
    }

    // NOTE: O setTimeout está sendo utilizado como garantia de retorno da instância antes da ejeção do interceptor.
    setTimeout(() => instance.interceptors.response.eject(responseId));

    return response;
  });

  return instance;
}
