import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { TableSkeleton } from '@components/table-components/table-skeleton';
import InformationRow from '@elements/table-elements/information-row';
import { ProductRow } from '@elements/table-elements/product-row';
import Table from '@elements/table-elements/table';

import { ASSETS_CARDS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { TableAdapterType } from '@extra-types/table-types';
import { getEmissor } from '@helpers/emissor-helpers';
import { Emissor } from '@helpers/enum/emissor';
import {
  completeWithLeftZero,
  getSingularOrPlural,
} from '@helpers/string-helpers';

import { useSelector } from '@hooks/use-selector';

import { BonusRow } from './bonus-row';

interface TableData {
  tableData: TableAdapterType;
  isLoading: boolean;
}

function WalletTable({ tableData, isLoading }: TableData) {
  const { cartModality } = useSelector(({ cart }) => cart);

  if (tableData.totalProducts === 0 || isLoading) {
    return <TableSkeleton />;
  }

  function generateWalletCardsAmountText() {
    const amount = Math.max(
      ...tableData.beneficts.map(benefit => benefit.cardQuantity),
    );

    return `${completeWithLeftZero(amount)} ${getSingularOrPlural(
      amount,
      'cartão',
      'cartões',
    )}`;
  }

  const {
    totalProducts,

    totalBeneficts,

    totalValue,
  } = tableData;

  if (!cartModality) {
    return null;
  }

  return (
    <Table
      color={cartModality.colorPrefixToken}
      head={{
        textLeft: cartModality.shelfInformation.title,
        textRight: generateWalletCardsAmountText(),
        titleRight: `Quantidade de cartões ${cartModality.shelfInformation.title}:`,
        imageLeft: `${env.URL_ASSETS_CHANNEL}${ASSETS_CARDS_PATH}/card-${
          cartModality.id
        }.${getEmissor().initials === Emissor.VRPAT ? 'svg' : 'png'}`,
      }}
      foot={{
        titleLeft: 'Valor total do pedido',
        titleRight: formatValueToCurrency(totalValue),
      }}
    >
      {tableData.beneficts?.map(product => (
        <ProductRow
          key={product.productCode}
          productData={{
            id: product.productCode,
            amount: product.cardQuantity,
            subtotalValue: product.totalBenefict,
          }}
        />
      ))}
      <InformationRow
        informationData={{
          title: 'VALOR TOTAL DE BENEFÍCIOS',
          subtitle: `
        ${generateWalletCardsAmountText()} /
          ${completeWithLeftZero(totalProducts)}
          ${getSingularOrPlural(totalProducts, 'saldo', 'saldos')}`,
          value: totalBeneficts,
        }}
        variant="gray"
        data-testid="total-benefits"
      />
      <BonusRow tableData={tableData} />
    </Table>
  );
}

export default WalletTable;
