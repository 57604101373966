import { useCallback, useEffect } from 'react';

import Checkbox from '@elements/checkbox';

import { svaRestrictions } from '@config/sva-config';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addProductToComboCartList,
  addSvaToCart,
  removeAllSvaFromCart,
  removeProductFromComboCartList,
  removeSvaFromCart,
} from '@store/slices/cart-slice';

import { OfferItemType } from '@api/models/response/offer-response';

import { styled } from '@src/themes';

import ProductSVADescription from './product-sva-description';

interface SvaInputProps {
  productData: OfferItemType;
  svaModality: number;
}

export function SvaInput({ productData, svaModality }: SvaInputProps) {
  const dispatch = useDispatch();
  const { getCurrentRule } = useComboCartPageContext();
  const {
    svaCartList,
    isCartLoading,
    productComboCartList,
    contractedProducts,
  } = useSelector(({ cart }) => cart);

  const isThereAnyBenefitThatAllowSeguroAlimentacaoInCart = useCallback(() => {
    if (svaRestrictions[productData.codigoProduto]) {
      return productComboCartList[svaModality].some(product =>
        svaRestrictions[productData.codigoProduto].includes(product.id),
      );
    }
    return true;
  }, [productComboCartList, productData.codigoProduto, svaModality]);

  const isSvaAlreadyContracted = () => {
    return contractedProducts.some(
      product => product.id === productData.codigoProduto,
    );
  };

  const canChooseSva = () => {
    return (
      svaCartList.length >= getCurrentRule().quantidadeMaximaSva &&
      !svaCartList.find(sva => sva.id === productData.codigoProduto)
    );
  };

  const toggle = () => {
    if (svaCartList.length < getCurrentRule().quantidadeMaximaSva) {
      dispatch(addSvaToCart(productData.codigoProduto));
      dispatch(
        addProductToComboCartList({
          modalityId: svaModality,
          product: {
            amount: 1,
            id: productData.codigoProduto,
            value: 1,
            type: ServiceGroupId.SVA,
            planType: PlanTypeEnum.MENSAL,
          },
        }),
      );
    }

    if (svaCartList.find(sva => sva.id === productData.codigoProduto)) {
      dispatch(removeSvaFromCart(productData.codigoProduto));
      dispatch(
        removeProductFromComboCartList({
          modalityId: svaModality,
          productId: productData.codigoProduto,
        }),
      );
    }
  };

  useEffect(() => {
    if (productData.svaObrigatorio) {
      dispatch(addSvaToCart(productData.codigoProduto));
      dispatch(
        addProductToComboCartList({
          modalityId: svaModality,
          product: {
            amount: 1,
            id: productData.codigoProduto,
            value: 1,
            type: ServiceGroupId.SVA,
            planType: PlanTypeEnum.MENSAL,
          },
        }),
      );
    }

    if (!isThereAnyBenefitThatAllowSeguroAlimentacaoInCart()) {
      toggle();
      dispatch(removeSvaFromCart(productData.codigoProduto));
      dispatch(
        removeProductFromComboCartList({
          modalityId: svaModality,
          productId: productData.codigoProduto,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      getCurrentRule().quantidadeMaximaSva !== 0 &&
      svaCartList.length > getCurrentRule().quantidadeMaximaSva
    ) {
      dispatch(removeAllSvaFromCart());
    }
  }, [dispatch, getCurrentRule, svaCartList.length]);

  return (
    <SvaInputContainer
      disabled={
        canChooseSva() ||
        !isThereAnyBenefitThatAllowSeguroAlimentacaoInCart() ||
        isCartLoading ||
        isSvaAlreadyContracted()
      }
      isChecked={
        !!svaCartList.find(sva => sva.id === productData.codigoProduto)
      }
    >
      <Checkbox
        isChecked={
          !!svaCartList.find(sva => sva.id === productData.codigoProduto) ||
          isSvaAlreadyContracted()
        }
        onChange={() => {
          if (!productData.svaObrigatorio) {
            toggle();
          }
        }}
      />

      <ProductSVADescription productData={productData} />
    </SvaInputContainer>
  );
}

const SvaInputContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$white',
  padding: '0.5rem 1rem',

  position: 'relative',

  width: '100%',

  img: {
    maxHeight: convertPixelToRem(45),
  },

  '&:last-child': {
    borderRadius: '0 0 8px 8px',
  },

  '@deviceSm': {
    gap: '0',
    alignItems: 'flex-start',
  },

  variants: {
    isChecked: {
      true: {
        img: {
          filter:
            'invert(34%) sepia(74%) saturate(2271%) hue-rotate(112deg) brightness(98%) contrast(106%)',
        },
      },
    },
    disabled: {
      true: {
        pointerEvents: 'none',
        filter: 'grayScale(0.4)',
        opacity: '0.4',
      },
    },
  },
});
