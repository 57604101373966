import { FormProvider, useForm } from 'react-hook-form';

import { AdditionalProductHcm } from '@components/additional-product-hcm';
import Details from '@elements/details';
import { HCMFormType } from '@pages/offer-page/custom-offer-pages/components/combo-product-services-form/combo-hcm-service-form';

import { AdditionalProductTypeEnum } from '@extra-types/additional-product-type';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { ensureNotUndefined } from '@helpers/function-helpers';
import { getModalityStaticData } from '@helpers/modality-helpers';
import { getProductListByModality } from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

import { CartPageHcmSummary } from '../cart-page-hcm-summary';
import { CartPageHcmHeaderSimplified } from './cart-page-hcm-header-simplified';

import { CartPageHCMContainer } from '..';

export function CartPageHCMSimplified() {
  const { productList } = useSelector(({ products }) => products);
  const {
    cartModality,
    productCartList,
    requiredAdditionalProductList,
    extraAdditionalProductList,
    isCartLoading,
  } = useSelector(({ cart }) => cart);
  const methods = useForm<HCMFormType>({
    mode: 'onChange',
    defaultValues: {
      'average-employees': productCartList[0].amount,
      planType: productCartList[0].planType,
    },
  });

  const modalityData = getModalityStaticData(
    cartModality?.id || ModalitiesId.HCM,
  );

  const productsForSale = getProductListByModality(
    modalityData.id,
    productList,
  );

  const productData = ensureNotUndefined(
    productsForSale.find(plan => plan.id === productCartList[0].id),
  );

  return (
    <CartPageHCMContainer>
      <FormProvider {...methods}>
        <CartPageHcmSummary modalityData={modalityData}>
          <CartPageHcmHeaderSimplified
            isFormActive={false}
            modalityData={modalityData}
            plan={productData}
          />
          <Details summary="Módulos">
            <div
              className={`modules-holder ${isCartLoading ? 'disabled' : ''}`}
            >
              {productData.additionalProductList
                .filter(
                  product =>
                    product.productType === AdditionalProductTypeEnum.MODULO,
                )
                .map(module => (
                  <AdditionalProductHcm.Modules
                    key={`module-${module.id}`}
                    id={module.id}
                    name={module.name}
                    description={module.description}
                  />
                ))}
            </div>
          </Details>
          <Details summary="Extensões">
            <div
              className={`extensions-holder ${isCartLoading ? 'disabled' : ''}`}
            >
              {requiredAdditionalProductList.required
                .filter(
                  product =>
                    product.productType === AdditionalProductTypeEnum.EXTENSAO,
                )
                .map(module => (
                  <AdditionalProductHcm.Extension
                    key={`extension-included-${module.id}`}
                    id={module.id}
                    name={module.name}
                    description={module.description}
                    isIncluded
                    isRequired
                  />
                ))}
              {extraAdditionalProductList.map(extension => (
                <AdditionalProductHcm.Extension
                  key={`extension-included-${extension.id}`}
                  id={extension.id}
                  name={extension.name}
                  description={extension.description}
                  isIncluded
                  isRequired
                />
              ))}
            </div>
          </Details>
        </CartPageHcmSummary>
      </FormProvider>
    </CartPageHCMContainer>
  );
}
