import { useSelector } from '@hooks/use-selector';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

export function SvaDetailsSimplified() {
  const { offerDetails } = OfferFacade();
  const { svaCartList } = useSelector(({ cart }) => cart);

  const getSvaInformation = (SVAId: number) => {
    return offerDetails.oferta.itemsOferta.find(
      sva => sva.codigoProduto === SVAId,
    );
  };

  if (svaCartList.length === 0) {
    return null;
  }

  return (
    <SvaDetailsSimplifiedContainer>
      <summary>
        <p>Serviços adicionais</p>
        <i className="fa-sharp fa-solid fa-caret-down" />
      </summary>
      <ul>
        {svaCartList.map(sva => (
          <span key={sva.id}>{getSvaInformation(sva.id)?.nome}</span>
        ))}
      </ul>
    </SvaDetailsSimplifiedContainer>
  );
}

const SvaDetailsSimplifiedContainer = styled('details', {
  backgroundColor: '#E4E8ED',
  padding: '0.5rem',
  marginBottom: '1rem',
  fontSize: '0.75rem',
  borderRadius: '0.5rem',

  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    width: '100%',
    cursor: 'pointer',

    i: {
      color: '$primaryPure',
    },
  },

  ul: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    padding: '0.5rem',
    fontWeight: '500',
  },

  '& > summary': {
    listStyle: 'none',
  },

  '& > summary::-webkit-details-marker': {
    display: 'none',
  },
});
