import { ComparinsonTable } from '@components/comparinson-table';
import { Button } from '@elements/button';
import { MobilitySectionTitle } from '@elements/mobility-section-title';
import { PageSection } from '@elements/page-section';

import { styled } from '@src/themes';

import { contractSectionID } from './product-hero-section';

export function BenefitComparinsonTableSection() {
  return (
    <PageSection>
      <SectionHolder>
        <MobilitySectionTitle>
          Vá além da gestão tradicional de VT, conheça os diferenciais da
          solução em mobilidade
        </MobilitySectionTitle>
        <ComparinsonTable
          columnTitles={{
            left: 'Gestão de VT da VR Mobilidade',
            right: 'Gestão Tradicional',
          }}
          itemRowList={[
            {
              id: 'transportation-row-1',
              title: <>Cálculo e recuperação de saldo de forma automatizada</>,
              left: true,
              right: false,
            },
            {
              id: 'transportation-row-2',
              title: <>Integração com ERPs e folha de pagamento</>,
              left: true,
              right: false,
            },
            {
              id: 'transportation-row-3',
              title: <>Informações sobre as operadoras</>,
              left: true,
              right: false,
            },
            {
              id: 'transportation-row-4',
              title: <>Pagamento unificado para todas as operadoras</>,
              left: true,
              right: false,
            },
            {
              id: 'transportation-row-5',
              title: <>Gestão de saldo com múltiplos empregadores</>,
              left: true,
              right: false,
            },
            {
              id: 'transportation-row-6',
              title: <>Gestão de VT por escala de trabalho</>,
              left: true,
              right: false,
            },
            {
              id: 'transportation-row-7',
              title: <>Controle de saldo por centro de custo</>,
              left: true,
              right: false,
            },
          ]}
        />
        <div className="benefit-comparasion-table-section__action-area">
          <Button.Anchor
            id="mobilidade_sessao-comparacao_cta"
            href={`#${contractSectionID}`}
          >
            Contratar Vale-Transporte
          </Button.Anchor>
        </div>
      </SectionHolder>
    </PageSection>
  );
}

const SectionHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2.5rem',

  paddingTop: '3rem',
  paddingBottom: '3rem',

  '.benefit-comparasion-table-section__action-area': {
    alignSelf: 'center',

    a: {
      width: '332px',
      color: '$black',
    },

    '@deviceLg': {
      width: '100%',

      a: {
        maxWidth: '332px',
        width: '100%',
        margin: '0 auto',
      },
    },
  },
});
