import { createAsyncThunk } from '@reduxjs/toolkit';

import { SECTION_NAME_LIST } from '@config/application-config';

import { RootState } from '@hooks/use-selector';

import LandingPageInformationsResponse from '@api/models/response/landing-page-information-response';
import landingPageService from '@api/services/landing-page-service';
import ApplicationError from '@errors/types/application-error';
import NotCatalogedError from '@errors/types/not-cataloged-error';

export const fetchLandingPageData = createAsyncThunk<
  LandingPageInformationsResponse,
  void,
  { state: RootState }
>('landing-page/fetchLandingPageDataStatus', async (_, thunkAPI) => {
  const { config } = thunkAPI.getState().application;
  let response = {} as LandingPageInformationsResponse;

  try {
    response = await landingPageService.getLandingPageInformations({
      siglaCanal: config.siglaCanal,
      siglaEmissor: config.emissor.initials,
      siglaSubCanal: config.siglaSubCanal,
    });

    if (
      !response.secoes.some(section =>
        SECTION_NAME_LIST.some(sectionId => sectionId === section.id),
      )
    ) {
      response = await landingPageService.getLandingPageInformations({
        siglaCanal: 'default',
        siglaEmissor: config.emissor.initials,
        siglaSubCanal: config.siglaSubCanal,
      });
    }
  } catch (error) {
    if (error instanceof ApplicationError)
      return thunkAPI.rejectWithValue(error);

    return thunkAPI.rejectWithValue(new NotCatalogedError());
  }

  return response;
});
