import { Environment } from './enum/environment';

export function getEnvironment(): Environment {
  const { host } = window.location;
  const { REACT_APP_ENV } = process.env;

  if (REACT_APP_ENV) {
    return REACT_APP_ENV as Environment;
  }

  if (host.indexOf('dev') >= 0) {
    return Environment.DEV;
  }
  if (host.indexOf('qa') >= 0) {
    return Environment.QA;
  }
  if (host.indexOf('com.br') >= 0) {
    return Environment.PRD;
  }

  return Environment.LOCAL;
}
