import { styled } from '@src/themes';

export function ComboSummaryTitle() {
  return (
    <ComboSummaryTitleContainer>
      <h2>Resumo da Compra</h2>
    </ComboSummaryTitleContainer>
  );
}

const ComboSummaryTitleContainer = styled('div', {
  color: '$primaryDark',
  fontSize: '1rem',
  overflow: 'hidden',
});
