import {
  CELLPHONE_MAX_LENGTH,
  CELLPHONE_MIN_LENGTH,
  COMPANY_MIN_LENGTH,
  MAIL_MAX_LENGTH,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from '@config/input-config';

interface FormErrorsData {
  [key: string]: string;
}

export const FormErrors: FormErrorsData = {
  REQUIRED_DEFAULT: 'Obrigatório',
  REQUIRED_CPF: 'CPF é obrigatório',
  REQUIRED_CNPJ: 'CNPJ é obrigatório',
  REQUIRED_NAME: 'Nome é obrigatório',
  REQUIRED_CEP: 'CEP é obrigatório',
  REQUIRED_COMPANY_NAME: 'Nome Fantasia é obrigatório',
  REQUIRED_MAIL: 'E-mail é obrigatório',
  REQUIRED_CELLPHONE: 'Telefone é obrigatório',
  REQUIRED_BIRTHDAY: 'Dt. de Nasc. é obrigatória',
  MIN_CARD_AMOUNT: 'Qtd. mínima:',
  MAX_CARD_AMOUNT: 'Qtd. máxima:',
  MIN_CARD_VALUE: 'Valor mínimo:',
  MAX_CARD_VALUE: 'Valor máximo:',
  INVALID_NAME_MINLENGTH: `Mínimo de ${NAME_MIN_LENGTH} caracteres`,
  INVALID_NAME_MAXLENGTH: `Máximo de ${NAME_MAX_LENGTH} caracteres`,
  INVALID_COMPANY_MINLENGTH: `Mínimo de ${COMPANY_MIN_LENGTH} caracteres`,
  INVALID_COMPANY_MAXLENGTH: 'Máximo de 30 caracteres',
  INVALID_CELLPHONE_MINLENGTH: `Mínimo de ${CELLPHONE_MIN_LENGTH} caracteres`,
  INVALID_CELLPHONE_MAXLENGTH: `Máximo de ${CELLPHONE_MAX_LENGTH} caracteres`,
  INVALID_MAIL_MAXLENGTH: `Máximo de ${MAIL_MAX_LENGTH} caracteres`,
  INVALID_NAME: 'Nome inválido',
  INVALID_CPF: 'CPF inválido',
  INVALID_CNPJ: 'CNPJ inválido',
  INVALID_MAIL: 'E-mail inválido',
  INVALID_CELLPHONE: 'Número de telefone inválido',
  INVALID_CEP: 'CEP inválido',
  INVALID_UNIQUE_CPF: 'CPF deve ser único',
  INVALID_UNIQUE_CNPJ: 'CNPJ deve ser único',
  INVALID_BIRTHDAY: 'Dt. de Nasc. inválida',
  FORM_INVALID_FIELDS:
    'Existem campos preenchidos incorretamente. Por favor verifique e corrija para prosseguir.',
  FORM_ONE_PRODUCT_REQUIRED:
    'É necessário preencher corretamente os campos de pelo menos um produto para continuar a simulação.',
} as const;
