import { completeWithLeftZero } from '@helpers/string-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

interface EntryModalityProps {
  amount: number;
}

export function EntryModality({ amount }: EntryModalityProps) {
  const { cartModality } = useSelector(({ cart }) => cart);

  if (!cartModality) {
    return null;
  }

  return (
    <EntryContainer>
      <EntryProductHolder
        css={{
          $$productColor: `$colors$${cartModality.colorPrefixToken}ColorName`,
        }}
      >
        <div className="entry-holder__product-title">
          <p>{cartModality.shelfInformation.title}</p>
        </div>
        <div className="entry-holder__product-information">
          <p className="product-information__amount">
            <span>Quantidade cartões:</span>
            <span>{completeWithLeftZero(amount)}</span>
          </p>
        </div>
      </EntryProductHolder>
    </EntryContainer>
  );
}

const EntryProductHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',

  '.entry-holder__product-title': {
    p: {
      color: '$$productColor',
      fontSize: convertPixelToRem(14),
      fontWeight: '$semibold',
    },
  },

  '.entry-holder__product-information': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',

    '.product-information__value, .product-information__amount': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      fontSize: '0.75rem',
      fontWeight: '$medium',

      'span:nth-child(2)': {
        fontSize: convertPixelToRem(14),
        fontWeight: '$semibold',
      },
    },
  },
});

const EntryContainer = styled('div', {
  padding: '1rem 0',

  borderBottom: '1px solid $silverLight',
});
