import { styled } from '@src/themes';

export const ProductShelfContainer = styled('article', {
  position: 'relative',

  backgroundColor: '$white',
  borderRadius: `0 42px 0 42px`,
  border: '1px solid $white',
  boxShadow: '0px 1px 2px #C6D0E3',

  cursor: 'pointer',
  transition: '.2s ease-in-out',

  '@media only screen and (min-width: 1024px)': {
    '&:hover': {
      transform: 'translateY(-2%)',
      borderColor: '$primaryLight',

      button: {
        backgroundColor: '$buttonPrimaryBackgroundColorHover',
        borderColor: '$buttonPrimaryBorderColorHover',
        color: '$buttonPrimaryTextColorHover',
      },
    },
  },
});

export const ProductShelfHolder = styled('div', {
  display: 'grid',
  gridTemplateAreas: `
    'title product-image'
    'description product-image'
    'button product-image'
  `,
  gridTemplateColumns: 'auto 40%',
  gridTemplateRows: 'auto auto 3.5rem',
  gap: '0.5rem 2.25rem',

  height: '100%',
  width: '100%',
  padding: '2rem',
  overflow: 'hidden',

  '.shelf-product-container__title': {
    gridArea: 'title',
    display: 'flex',
    alignItems: 'center',
    span: {
      color: '$fontColorPrimary',
      fontSize: '1.5rem',
      fontWeight: '$bold',
    },
  },

  '.shelf-product-container__description': {
    gridArea: 'description',
    fontSize: '0.875rem',
    fontWeight: '$semibold',
    lineHeight: 1.5,

    strong: {
      fontSize: '1rem',
    },
  },

  '.shelf-product-container__product-image': {
    display: 'flex',
    justifyContent: 'center',
    gridArea: 'product-image',
    alignSelf: 'center',

    img: {
      width: '100%',
      maxHeight: '156px',

      objectFit: 'contain',
    },
  },

  '.shelf-product-container__button': {
    gridArea: 'button',

    marginTop: '0.5rem',
  },

  '@deviceLg': {
    gridTemplateRows: 'auto auto auto 3.5rem',
    gridTemplateColumns: 'auto 40%',
    gridTemplateAreas: `
    'title title'
    'product-image product-image'
    'description description'
    'button button'
    `,
    gap: '1rem',
  },

  '@deviceMd': {
    '.shelf-product-container__product-image': {
      img: {
        maxHeight: '120px',
      },
    },
  },

  '@media only screen and (min-width: 601px) and (max-width: 720px)': {
    gridTemplateRows: 'auto auto 3.5rem',
    gridTemplateColumns: 'auto 40%',
    gap: '1rem',
    gridTemplateAreas: `
            'title product-image'
            'description product-image '
            'button button'
          `,

    '.shelf-product-container__product-image': {
      img: {
        maxHeight: '140px',
      },
    },
  },

  '@deviceSm': {
    gridTemplateRows: 'auto auto auto 3.5rem',
    gridTemplateColumns: 'auto 50%',
    gap: '1rem',

    padding: '1.5rem',

    '.shelf-product-container__product-image': {
      img: {
        maxHeight: '140px',
      },
    },
  },

  '@media only screen and (max-width: 374px)': {
    gridTemplateRows: 'auto auto auto',
    gridTemplateAreas: `
      'title title'
      'product-image product-image'
      'description description'
      'button button'
    `,
  },
});
