import { createContext, useCallback, useMemo, useState } from 'react';

import { UseQueryResult, useQuery } from '@tanstack/react-query';

import {
  FAQ_RETRY_DELAY_TIME,
  FAQ_RETRY_MAX_ATTEMPTS,
} from '@config/faq-config';
import { FAQCategoryType } from '@extra-types/faq-category-type';
import {
  convertAllFAQArticlesListResponseToFAQArticleList,
  convertFAQCategoriesResponseToFAQCategoryList,
} from '@helpers/faq-helpers';
import { ensureNotUndefined } from '@helpers/function-helpers';

import useDefaultHeader from '@hooks/use-default-header';

import { FAQArticles } from '@api/models/response/faq-articles-list-response';
import faqService from '@api/services/faq-service';

export interface FAQContextData {
  faqCategories: UseQueryResult<FAQCategoryType[], unknown>;
  allFaqArticles: UseQueryResult<FAQArticles[], unknown>;
  activeCategory: FAQCategoryType | undefined;
  selectedArticle: FAQArticles | undefined;
  getArticlesByCategoryId: (id: string) => FAQArticles[];
  handleChangeCategory: (id: FAQCategoryType) => void;
  handleSelectedArticle: (article: FAQArticles | undefined) => void;
  getCategoryById: (id: string) => FAQCategoryType;
  searchTypedValue: (value: string) => void;
  handleSearchChange: (value: string) => Record<string, FAQArticles[]>;
  searchedValue: string;
  notFoundArticle: boolean;
  backToFAQ: () => void;
}

export const FAQContext = createContext<FAQContextData>({} as FAQContextData);

interface FAQContextProviderProps {
  children: React.ReactNode;
}

export function FAQContextProvider({
  children,
  ...props
}: FAQContextProviderProps) {
  const [selectedArticle, setSelectedArticle] = useState<
    FAQArticles | undefined
  >();

  const [activeCategory, setActiveCategory] = useState<
    FAQCategoryType | undefined
  >();
  const [notFoundArticle, setNotFoundArticle] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const { defaultHeader } = useDefaultHeader();

  const faqCategories = useQuery({
    queryKey: ['faqCategoriesData'],
    queryFn: () =>
      faqService
        .getFAQCategories(defaultHeader)
        .then(response =>
          convertFAQCategoriesResponseToFAQCategoryList(response),
        ),
    refetchOnWindowFocus: false,
    retry: FAQ_RETRY_MAX_ATTEMPTS,
    retryDelay: FAQ_RETRY_DELAY_TIME,
  });

  const allFaqArticles = useQuery({
    queryKey: ['allArticlesData'],
    queryFn: () =>
      faqService
        .getAllFAQArticles(defaultHeader)
        .then(response =>
          convertAllFAQArticlesListResponseToFAQArticleList(response),
        ),
    refetchOnWindowFocus: false,
    retry: FAQ_RETRY_MAX_ATTEMPTS,
    retryDelay: FAQ_RETRY_DELAY_TIME,
  });

  function handleSelectedArticle(article: FAQArticles | undefined) {
    setSelectedArticle(article);
  }

  const searchForCategorieTitle = useCallback(
    (id: string) => {
      return ensureNotUndefined(
        faqCategories.data?.find(categorie => categorie.id === id)?.title,
      );
    },
    [faqCategories.data],
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      let separatedCategoriesList = {} as Record<string, FAQArticles[]>;
      if (faqCategories.data) {
        separatedCategoriesList = faqCategories.data.reduce(
          (defaultCategories, category) => {
            return {
              ...defaultCategories,
              [category.title]: [{} as FAQArticles],
            };
          },
          {} as Record<string, FAQArticles[]>,
        );

        if (value.length >= 3 && allFaqArticles.data && faqCategories.data) {
          allFaqArticles.data.forEach(article => {
            if (
              article.title.toLowerCase().includes(value.toLowerCase()) &&
              searchForCategorieTitle(article.categoryId)
            ) {
              const categorieTitle = searchForCategorieTitle(
                article.categoryId,
              );
              separatedCategoriesList[categorieTitle].push(article);
            }
          });
        } else {
          separatedCategoriesList = {} as Record<string, FAQArticles[]>;
        }
      }

      return separatedCategoriesList;
    },
    [allFaqArticles.data, faqCategories.data, searchForCategorieTitle],
  );

  const searchTypedValue = useCallback(
    (value: string) => {
      setSearchedValue(value);
      setNotFoundArticle(
        !Object.values(handleSearchChange(value)).some(tip => tip.length > 1),
      );
    },
    [handleSearchChange],
  );

  const backToFAQ = useCallback(() => {
    setSearchedValue('');
    setNotFoundArticle(false);
  }, []);

  const handleChangeCategory = (category: FAQCategoryType) => {
    setActiveCategory(category);
  };

  const getCategoryById = useCallback(
    (id: string) => {
      return ensureNotUndefined(
        faqCategories.data?.find(category => category.id === id),
      );
    },
    [faqCategories.data],
  );

  const getArticlesByCategoryId = useCallback(
    (id: string) => {
      return ensureNotUndefined(
        allFaqArticles.data?.filter(article => article.categoryId === id),
      );
    },
    [allFaqArticles.data],
  );

  const memorizeReturn = useMemo(
    () => ({
      faqCategories,
      allFaqArticles,
      selectedArticle,
      activeCategory,
      getArticlesByCategoryId,
      handleChangeCategory,
      handleSelectedArticle,
      getCategoryById,
      searchTypedValue,
      searchedValue,
      notFoundArticle,
      backToFAQ,
      handleSearchChange,
    }),
    [
      faqCategories,
      allFaqArticles,
      selectedArticle,
      activeCategory,
      getArticlesByCategoryId,
      getCategoryById,
      searchTypedValue,
      searchedValue,
      notFoundArticle,
      backToFAQ,
      handleSearchChange,
    ],
  );

  return (
    <FAQContext.Provider value={memorizeReturn} {...props}>
      {children}
    </FAQContext.Provider>
  );
}
