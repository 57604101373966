import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  width: '100%',
  height: '100%',

  '.address__input': {
    display: 'flex',
    gap: convertPixelToRem(40),

    '&:last-child': {
      marginTop: convertPixelToRem(20),
    },

    '@deviceMd': {
      flexDirection: 'column',
      gap: '0',

      '& + div': {
        marginTop: 0,
      },

      '> div + div': {
        marginLeft: 0,
      },

      '&:last-child': {
        marginTop: 0,
      },

      '> div': {
        width: '100% !important',
        margin: `0 0 ${convertPixelToRem(20)}`,
      },
    },
  },

  variants: {
    loading: {
      true: {
        '.loader__holder': {
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          top: '50%',
          left: '50%',
          width: '100%',
          height: '100%',
          transform: 'translate(-50%, -50%)',
          backdropFilter: 'blur(2px)',
          zIndex: '$9',
        },
      },
    },
  },
});

export const Loading = styled('div', {
  zIndex: 2,
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.7)',
});
