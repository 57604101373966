import { Emissor } from '@helpers/enum/emissor';
import { Environment } from '@helpers/enum/environment';

export const ALL_CHANNEL_INITIALS = 'ALL';

export type CustomChannelData = {
  hasCardDesign: boolean;
  channelName: string;
};

export const customChannels = new Map<string, CustomChannelData>();

customChannels.set('SICRE', {
  hasCardDesign: true,
  channelName: 'Sicredi',
});

export const CHANNEL_DEFAULT_INITIALS = 'DEFAULT';

export const defaultEmissorChannel: Record<
  Emissor,
  Record<Environment, string>
> = {
  VRPAT: {
    LOCAL: 'WEB',
    DEV: 'WEB',
    MOCK: 'WEB',
    QA: 'WEB',
    PRD: 'WEB',
  },
  JVCEF: {
    LOCAL: 'JVCEF',
    DEV: 'JVCEF',
    MOCK: 'JVCEF',
    QA: 'SCCPP',
    PRD: 'SCCPP',
  },
};
