import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Skeleton } from '@components/skeletons';
import IndividualProductForm from '@compositions/individual-product-form';
import { PATAuxComparasionSection } from '@compositions/sections/pat-aux-comparasion-section';
import { PageSection } from '@elements/page-section';
import { SectionHolder } from '@elements/section-holder';
import { Title } from '@elements/title';

import { PRODUCTS } from '@config/products-config';
import { OfferType } from '@helpers/enum/offer-type';
import { getModalityData } from '@helpers/modality-helpers';
import {
  filterProductListByOfferType,
  getProductListByModality,
} from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

interface IndividualProductsPageProps {
  modalityId: number;
}

function IndividualProductsPageTitle({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  return (
    <Title.Root barColor="secondary">
      <Title.Default tag="h2">{title}</Title.Default>
      <Title.Subtitle>{subtitle}</Title.Subtitle>
    </Title.Root>
  );
}

export function IndividualProductsPage({
  modalityId,
}: IndividualProductsPageProps) {
  const location = useLocation();
  const {
    productList,
    isLoading: isProductsLoading,
    modalityList,
  } = useSelector(({ products }) => products);
  const isApplicationLoading = useSelector(
    ({ application }) => application.isLoading,
  );

  const modalityData = getModalityData(modalityId, modalityList);

  const modalityProductList = getProductListByModality(modalityId, productList);

  const saleProductList = useMemo(() => {
    return filterProductListByOfferType(modalityProductList, OfferType.SALE);
  }, [modalityProductList]);

  if (saleProductList.length === 1) {
    return (
      <Navigate
        to={{
          pathname: PRODUCTS[saleProductList[0].id].pagePath,
          search: location.search,
        }}
        state={{ from: location.pathname }}
      />
    );
  }

  if (isApplicationLoading || isProductsLoading) {
    return (
      <PageSection>
        <Skeleton.SectionContainer>
          <IndividualProductsPageTitle
            title={modalityData.shelfInformation.title}
            subtitle={modalityData.subtitle}
          />
          <Skeleton.BigBox />
          <Skeleton.Title />
          <Skeleton.BigBox />
        </Skeleton.SectionContainer>
      </PageSection>
    );
  }

  return (
    <>
      <PageSection id={`${modalityData.keyId}-page`}>
        <ProductsContainer>
          <IndividualProductsPageTitle
            title={modalityData.shelfInformation.title}
            subtitle={modalityData.subtitle}
          />
          {saleProductList.length > 0 && (
            <div className="indvidual-products-page__content">
              {saleProductList.map(product => (
                <IndividualProductForm
                  key={product.id}
                  productName={product.name}
                  modalityId={product.modalityId}
                  productId={product.id}
                  knowMoreButton
                />
              ))}
            </div>
          )}
        </ProductsContainer>
      </PageSection>
      {saleProductList.some(saleProduct => saleProduct.showAsteriskPatAux) && (
        <PATAuxComparasionSection
          contractIDSection={`${modalityData.keyId}-page`}
        />
      )}
    </>
  );
}

const ProductsContainer = styled(SectionHolder, {
  display: 'flex',
  flexDirection: 'column',
  gap: '4rem',

  transparentBackground: '$primaryPure',

  '.indvidual-products-page__content': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '4rem 1rem',

    '@deviceLg': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },

    '@deviceSm': {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },

  '.interest-products-page__content': {
    width: '100%',
    borderRadius: '10px',
    backgroundColor: '#F8F8F8',
    padding: '32px 16px',
  },
});
