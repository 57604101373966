import { ASSETS_AWARDS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { DefaultTokenVariantsType } from '@extra-types/token-variants-type';

import { AwardItemContainer, AwardItemLinkContainer } from './styles';

interface AwardContainerProps {
  title: string;
  iconName: string;
  variant: DefaultTokenVariantsType;
}

function AwardContainer({ title, iconName, variant }: AwardContainerProps) {
  return (
    <AwardItemContainer variant={variant}>
      <img
        src={`${env.URL_ASSETS}${ASSETS_AWARDS_PATH}/award-${iconName}.webp`}
        alt={`Selo ${title}`}
      />
      <p>{title}</p>
    </AwardItemContainer>
  );
}

interface AwardItemProps extends Omit<AwardContainerProps, 'variant'> {
  href?: string;
  variant?: DefaultTokenVariantsType;
}

function AwardItem({ href, variant = 'primary', ...props }: AwardItemProps) {
  if (href) {
    return (
      <AwardItemLinkContainer
        href={href}
        title={`Página ${props.title}`}
        variant={variant}
        target="__blank"
      >
        <AwardContainer variant={variant} {...props} />
      </AwardItemLinkContainer>
    );
  }

  return <AwardContainer variant={variant} {...props} />;
}

export default AwardItem;
