import { CustomerModalCompanyMessage } from '@components/customer-modal-company-message';
import { Button } from '@elements/button';

import { env } from '@config/env';

import { useSelector } from '@hooks/use-selector';

import { ScenariosContainer } from './styles';

function AllProductsContractedCustomer() {
  const { isLoading } = useSelector(({ customer }) => customer);
  const { config } = useSelector(({ application }) => application);

  const redirectToPortal = () => {
    window.location.replace(env.URL_PORTAL);
  };

  return (
    <ScenariosContainer>
      <CustomerModalCompanyMessage customMessage="Parece que você já possui todos os produtos contratados!" />
      <Button.Default
        type="submit"
        outline
        disabled={isLoading}
        onClick={redirectToPortal}
      >
        Acessar o {config.emissor.portalName}
      </Button.Default>
    </ScenariosContainer>
  );
}

export default AllProductsContractedCustomer;
