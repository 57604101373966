import { styled } from '@src/themes';

export const OptionContainer = styled('li', {
  display: 'flex',
  alignItems: 'center',
  padding: '0.8rem 1.33rem',
  borderBottom: '2px solid $silverPure',
  fontWeight: '500',
  transition: 'all 0.2s ease',
  cursor: 'pointer',

  i: {
    marginRight: '1rem',
  },

  '&:hover': {
    backgroundColor: '$primaryPure',
    color: '$white',
  },

  variants: {
    selected: {
      true: {
        backgroundColor: '$primaryPure',
        color: '$white',
      },
    },
  },
});
