import { useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';
import { debounce } from 'lodash';

import { Input } from '@components/input';
import { ModalityInformation } from '@components/modality-information';
import { ProductComboWalletInput } from '@pages/offer-page/custom-offer-pages/components/combo-product-input/product-combo-wallet-input';
import { ComboTaxTable } from '@pages/offer-page/custom-offer-pages/components/combo-summary-components/combo-tax-table';
import { ComboServiceFormCollapseBox } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-collapse-box';
import { ComboServiceFormContainer } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-container';
import { ComboServiceFormFooterArea } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-footer-area';
import { ComboServiceFormHeader } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-header';
import { ComboServiceFormSectionContainer } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-section-container';
import { ComboServiceFormServiceInformation } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-service-information';

import { DIGIT_WAIT_TIME_TO_PRICE } from '@config/application-config';
import { MIN_CARD_QUANTITY } from '@config/input-config';
import { ComboServiceFormType } from '@extra-types/combo-service-form-type';
import { WalletProductFormType } from '@extra-types/product-form-types';
import {
  compareProductCartLists,
  formatWalletProductListToProductCartList,
} from '@helpers/cart-helpers';
import { FormErrors } from '@helpers/enum/form-errors';
import { isComboFlux } from '@helpers/flux-helpers';
import { getPricingResumeData } from '@helpers/pricing-helpers';
import { removePrefixProduct } from '@helpers/product-helpers';
import { createComboModalityFormId } from '@helpers/service-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { useToastMessageContext } from '@hooks/use-toast-message-context';
import { addProductListToComboCart } from '@store/slices/cart-slice';
import { fetchCartPricing } from '@store/thunks/cart-thunk';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

type WalletFormInputs = {
  products: WalletProductFormType;
  amount: number;
};

export function ComboWalletServiceForm({
  modalityData,
  isFormActive,
  handleNextForm,
}: ComboServiceFormType) {
  const dispatch = useDispatch();
  const { selectedOffer, offerDetails } = OfferFacade();
  const { sendToastMessage } = useToastMessageContext();
  const { getProductForSaleListByModality, handleActiveSummary } =
    useComboCartPageContext();
  const config = useSelector(({ application }) => application.config);
  const productList = useSelector(({ products }) => products.productList);
  const {
    productComboCartList,
    cartPricingData,
    comboCartPricingData,
    contractedProducts,
    isCartLoading,
  } = useSelector(({ cart }) => cart);
  const { isLoading: isRegisterCartLoading, unservedRegionProducts } =
    useSelector(({ customer }) => customer);

  const formID = createComboModalityFormId(modalityData.serviceGroupId);

  const availableProducts = getProductForSaleListByModality(modalityData.id);
  const productPricedDataList = isComboFlux(selectedOffer)
    ? comboCartPricingData
    : cartPricingData;

  const getHighestCardAmountFromOffer = () => {
    const itensAmount = offerDetails.oferta.itemsOferta.map(item => {
      if (availableProducts.some(({ id }) => id === item.codigoProduto)) {
        return {
          quantidadeMinima: item.quantidadeMinima,
          quantidadeMaxima: item.quantidadeMaxima,
        };
      }
      return {
        quantidadeMinima: 0,
        quantidadeMaxima: 0,
      };
    });

    return {
      quantidadeMinima: Math.max(
        ...itensAmount.map(item => item.quantidadeMinima),
      ),
      quantidadeMaxima: Math.max(
        ...itensAmount.map(item => item.quantidadeMaxima),
      ),
    };
  };

  const minCardAmountValidation =
    getHighestCardAmountFromOffer().quantidadeMinima ||
    selectedOffer?.quantidadeCartoesMinima ||
    MIN_CARD_QUANTITY;
  const maxCardAmountValidation =
    getHighestCardAmountFromOffer().quantidadeMaxima ||
    selectedOffer?.quantidadeCartoesMaxima ||
    config.maxBeneficiaryAmount;

  const getFormDefaultValues = () => {
    if (
      productComboCartList[modalityData.id] &&
      productComboCartList[modalityData.id].length > 0
    ) {
      return {
        amount: productComboCartList[modalityData.id][0].amount,
        products: productComboCartList[modalityData.id].reduce(
          (defaultValuesList, product) => {
            return {
              ...defaultValuesList,
              [`product-${product.id}`]: {
                value: formatValueToCurrency(product.value),
              },
            };
          },
          {} as WalletProductFormType,
        ),
      };
    }
    return undefined;
  };

  const methods = useForm<WalletFormInputs>({
    mode: 'onChange',
    defaultValues: getFormDefaultValues(),
  });

  const watchProducts = methods.watch('products');
  const watchAmount = methods.watch('amount');

  function validateIfHasAtLeastOneProductFilled() {
    if (watchProducts !== undefined) {
      return Object.entries(watchProducts).some(product => product[1].value);
    }
    return false;
  }

  const calcSubTotal = useCallback(() => {
    return (
      productPricedDataList?.resumo.servicos[modalityData.serviceGroupId]
        .valorTotal || 0
    );
  }, [modalityData.serviceGroupId, productPricedDataList?.resumo.servicos]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(async () => {
      const isFormValid = await methods.trigger(['amount', 'products']);
      methods.handleSubmit((form: WalletFormInputs) => {
        const modalityId = modalityData.id;
        const productComboList = productComboCartList[modalityId] || [];
        const isValidForm = isFormValid && !!form.amount;
        const hasValidProducts = Object.values(form.products).some(
          product => product.value !== '',
        );
        const formatedFormWalletProductToProductCartList =
          formatWalletProductListToProductCartList(
            form.amount,
            form.products,
            productList,
          );

        const shouldTriggerPricing =
          productComboList.length === 0 ||
          !compareProductCartLists(
            formatedFormWalletProductToProductCartList,
            productComboCartList[modalityData.id],
          );
        if (isValidForm && hasValidProducts && shouldTriggerPricing) {
          dispatch(
            addProductListToComboCart({
              modalityId,
              productList: formatedFormWalletProductToProductCartList,
            }),
          );
          dispatch(fetchCartPricing({ offerData: selectedOffer }));
          handleActiveSummary({ id: `${modalityData.serviceGroupId}` });
        }
      })();
    }, DIGIT_WAIT_TIME_TO_PRICE),
    [productComboCartList],
  );

  const onSubmit: SubmitHandler<WalletFormInputs> = async () => {
    const isFormValid = await methods.trigger(['amount', 'products']);

    if (!isFormValid) {
      sendToastMessage({
        variant: 'error',
        message: <strong>Erro ao adicionar produto ao carrinho!</strong>,
        disposeTime: 4000,
        zIndex: 400,
      });
    }

    if (
      watchAmount >= minCardAmountValidation ||
      Object.values(watchProducts).filter(({ value }) => !!value).length >=
        minCardAmountValidation
    ) {
      handleNextForm();
    } else {
      sendToastMessage({
        variant: 'error',
        message: (
          <p>
            Para aproveitar a oferta você precisa selecionar pelo menos
            <strong> {minCardAmountValidation} cartões </strong>
            ou{' '}
            <strong>
              {selectedOffer?.quantidadeCartoesMinima ||
                minCardAmountValidation}{' '}
              saldos{' '}
            </strong>
            em um mesmo cartão.
          </p>
        ),
        disposeTime: 4000,
        zIndex: 400,
      });
    }
  };

  useEffect(() => {
    if (isFormActive === false) {
      methods.reset(getFormDefaultValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormActive]);

  useEffect(() => {
    const activeProductFormList = methods.getValues().products;

    if (activeProductFormList) {
      const activeFormIDList = Object.keys(activeProductFormList).map(
        removePrefixProduct,
      );

      const formsToUnregister = activeFormIDList.filter(
        activeFormID =>
          !availableProducts.some(product => product.id === activeFormID),
      );

      formsToUnregister.forEach(formToUnregister =>
        methods.unregister(`products.product-${formToUnregister}`),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <ComboServiceFormSectionContainer>
        <ComboServiceFormContainer
          id={formID}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <ComboServiceFormHeader
            serviceGroupId={modalityData.serviceGroupId}
            isCollapsed={!isFormActive}
            value={calcSubTotal()}
            showRecurringValueLabel={false}
          />
          <ComboServiceFormServiceInformation isCollapse={!isFormActive}>
            <ModalityInformation modalityData={modalityData} size="md" />
            <AmountValueContainer>
              <p>Núm. de cartões</p>
              <Input.Numeric
                id={`service-${modalityData.keyId}-amount`}
                customMask="999"
                placeholder="0"
                width="6rem"
                disabled={
                  !isFormActive || isCartLoading || isRegisterCartLoading
                }
                errorMessage={methods.formState.errors.amount?.message}
                {...methods.register('amount', {
                  onChange: () => {
                    debouncedSave();
                  },
                  valueAsNumber: true,
                  required: {
                    value: true,
                    message: FormErrors.REQUIRED_DEFAULT,
                  },
                  max: {
                    value: maxCardAmountValidation,
                    message: `${FormErrors.MAX_CARD_AMOUNT} ${maxCardAmountValidation}`,
                  },
                })}
              />
            </AmountValueContainer>
          </ComboServiceFormServiceInformation>
          <ComboServiceFormCollapseBox
            isFormActive={isFormActive}
            value={calcSubTotal()}
            showRecurringValueLabel={false}
          >
            <ContentContainer>
              <p>
                Escolha os saldos e os valores mensal de cada. Caso não queira
                um saldo, basta deixar sem valor.
              </p>
              <ServiceProductsContainer>
                {availableProducts.map(product => {
                  return (
                    <ProductComboWalletInput
                      key={product.id}
                      idPrefix="combo"
                      modalityData={modalityData}
                      productData={product}
                      unserved={unservedRegionProducts.some(
                        removedProduct => removedProduct === product.id,
                      )}
                      contracted={contractedProducts.some(
                        contractedProduct =>
                          contractedProduct.id === product.id,
                      )}
                    />
                  );
                })}
              </ServiceProductsContainer>
            </ContentContainer>
            <ComboTaxTable
              serviceId={modalityData.serviceGroupId}
              pricingResumeData={getPricingResumeData(
                productPricedDataList,
                modalityData.serviceGroupId,
              )}
            />
          </ComboServiceFormCollapseBox>
        </ComboServiceFormContainer>

        <ComboServiceFormFooterArea
          isCollapsed={!isFormActive}
          extraDisableCondition={
            !validateIfHasAtLeastOneProductFilled() ||
            !methods.formState.isValid
          }
        />
      </ComboServiceFormSectionContainer>
    </FormProvider>
  );
}

const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',

  '> p': {
    fontSize: convertPixelToRem(14),
  },
});

const AmountValueContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.5rem',

  p: {
    fontSize: convertPixelToRem(14),
  },

  '@deviceMd': {
    flexDirection: 'column',
    alignItems: 'start',
    gap: '0',
  },

  '@deviceSm': {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1.5rem',

    p: {
      fontSize: '0.75rem',
    },
  },
});

const ServiceProductsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});
