import IndividualProductForm from '@compositions/individual-product-form';

import { ASSETS_MOBILITY_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { ProductType } from '@extra-types/product-type';
import {
  convertPixelToRem,
  getStyleTokenByProduct,
  holderStyle,
} from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const contractSectionID = 'contratar-vale-transporte';

export function ProductHeroSection({
  productData,
}: {
  productData: ProductType;
}) {
  return (
    <ProductHeroSectionContainer
      id={contractSectionID}
      css={{
        $$productPrimaryColor: `$colors${getStyleTokenByProduct(
          productData.colorPrefixToken,
          'primary',
          'pure',
        )}`,
        $$productSecondaryColor: `$colors${getStyleTokenByProduct(
          productData.colorPrefixToken,
          'secondary',
          'pure',
        )}`,
      }}
    >
      <ProductHeroSectionHolder>
        <div className="product-information-holder__title">
          <ProductTitle>
            <h1>{productData.name}</h1>
            <p>A plataforma que descomplica a gestão de VT.</p>
          </ProductTitle>
          <div className="product-information-holder__cards">
            <ul>
              <li>
                <div>
                  0<span>%</span>
                </div>
                <p>
                  tarifa por local <br />
                  de atendimento
                </p>
              </li>
              <li>
                <div>
                  0<span>%</span>
                </div>
                <p>
                  tarifa de gestão de
                  <br /> saldo automática
                </p>
              </li>
              <li>
                <div>
                  4<span>%</span>
                </div>
                <p>taxa de serviço</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="product-information-holder__image-area">
          <img
            src={`${
              env.URL_ASSETS + ASSETS_MOBILITY_PATH
            }/vale-transporte-hero-bg.svg`}
            alt=""
          />
        </div>
        <div className="product-information-holder__form-area">
          <IndividualProductForm
            modalityId={productData.modalityId}
            productName={productData.name}
            productId={productData.id}
          />
        </div>
      </ProductHeroSectionHolder>
    </ProductHeroSectionContainer>
  );
}

const ProductHeroSectionContainer = styled('section', {
  backgroundColor: '$$productSecondaryColor',
  overflowX: 'hidden',
});

const ProductHeroSectionHolder = styled('div', {
  position: 'relative',
  ...holderStyle,

  padding: `${convertPixelToRem(40)} 0 0`,

  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  gridTemplateColumns: 'auto 380px',
  gridTemplateAreas: `
                      'title-area form-area'
                      'image-area form-area'
                    `,

  '.product-information-holder__title': {
    position: 'relative',
    gridArea: 'title-area',

    paddingLeft: '2rem',

    h1: {
      '@deviceLg': {
        fontSize: '2.25rem',
      },
    },

    '@deviceSm': {
      paddingLeft: '1rem',
    },

    '.product-information-holder__cards': {
      position: 'relative',
      top: convertPixelToRem(20),
      left: convertPixelToRem(32),
      width: '100%',
      maxWidth: convertPixelToRem(580),

      '@deviceLg': {
        top: convertPixelToRem(25),
        left: convertPixelToRem(35),
      },

      '@deviceSm': {
        top: convertPixelToRem(20),
        left: convertPixelToRem(28),
      },

      ul: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: convertPixelToRem(43),

        li: {
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          width: '100%',
          height: convertPixelToRem(46),
          maxWidth: convertPixelToRem(160),

          backgroundColor: '#F9EF0F',
          borderRadius: `0 ${convertPixelToRem(8)}`,

          div: {
            width: convertPixelToRem(64),
            height: convertPixelToRem(46),
            backgroundColor: '$featuredFlagBackground',
            borderRadius: `0 ${convertPixelToRem(8)}`,

            fontSize: '2.5rem',
            fontWeight: '$bold',
            color: '$white',
            textAlign: 'center',

            position: 'absolute',
            top: convertPixelToRem(-10),
            left: '-2rem',

            span: {
              fontSize: '1.5rem',

              '@deviceSm': {
                fontSize: '1.5rem',
              },
            },
          },

          p: {
            fontSize: convertPixelToRem(11),
            color: '$featuredFlagBackground',
            fontWeight: '$semibold',
            paddingLeft: '1rem',

            '@deviceLg': {
              fontSize: convertPixelToRem(10),
            },

            '@deviceSm': {
              fontSize: convertPixelToRem(9),
            },
          },

          '&:nth-child(2)': {
            paddingLeft: '1rem',
          },

          '@deviceLg': {
            width: convertPixelToRem(150),
          },

          '@deviceSm': {
            width: convertPixelToRem(135),
          },
        },

        '@deviceLg': {
          gap: `${convertPixelToRem(20)} ${convertPixelToRem(43)}`,
        },
      },
    },
  },

  '.product-information-holder__form-area': {
    display: 'flex',
    alignItems: 'center',

    position: 'relative',
    gridArea: 'form-area',
    alignSelf: 'end',

    height: '100%',
    padding: '0 2rem 0 0',

    form: {
      zIndex: 1,
    },

    '@deviceMd': {
      justifyContent: 'center',

      width: '100%',
      padding: '2.5rem 2rem 2rem',

      form: {
        maxWidth: '360px',
      },
    },

    '@deviceSm': {
      padding: '2.5rem 1rem 2rem',
    },
  },

  '.product-information-holder__image-area': {
    position: 'relative',
    overflow: 'hidden',
    gridArea: 'image-area',

    width: '100%',
    height: '400px',

    img: {
      position: 'absolute',
      right: '25px',
      bottom: '-27px',
      height: '100%',

      '@deviceLg': {
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },

    '@deviceLg': {
      height: '360px',
    },

    '@deviceMd': {
      position: 'absolute',
      bottom: 0,

      height: '400px',

      '&:after': {
        content: '',
        position: 'absolute',
        height: '100%',
        top: 0,
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',

        backgroundColor: '$$productSecondaryColor',
        width: '400px',
      },
    },

    '@deviceSm': {
      display: 'none',
      '&:after': {
        content: 'unset',
      },
    },
  },

  '@deviceMd': {
    gridTemplateRows: 'unset',
    gridTemplateColumns: 'auto',
    gridTemplateAreas: `
                        'title-area'
                        'form-area'
                      `,
    gap: '2rem',
  },
});

const ProductTitle = styled('div', {
  h1: {
    marginBottom: convertPixelToRem(5),

    color: '$black',
    fontSize: '2.5rem',
    fontWeight: '$bold',
    lineHeight: 1.2,
  },
  p: {
    width: 'fit-content',

    color: '$black',
    fontSize: '1.5rem',
    fontWeight: '$regular',
    lineHeight: 1.5,

    '@deviceLg': {
      fontSize: '1rem',
    },

    '@deviceSm': {
      fontSize: convertPixelToRem(15),
    },
  },
});
