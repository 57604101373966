import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import clearNotNumber from '@vr/devkit/utils/clearNotNumber';

import Dropdown from '@components/dropdown';
import { Input } from '@components/input';
import Loader from '@elements/loader';
import Option from '@elements/option';

import {
  ADDRESS_COMPLEMENT_MAX_LENGTH,
  ADDRESS_MAX_LENGTH,
  CEP_LENGTH,
  CEP_LENGTH_FORMATTED,
  CITY_MAX_LENGTH,
  NEIGHBORHOOD_MAX_LENGTH,
} from '@config/input-config';
import { CompanyType } from '@extra-types/company-type';
import {
  ApiErrorsMessages,
  ApiErrorsModalTitle,
} from '@helpers/enum/api-error-messages';
import { FormErrors } from '@helpers/enum/form-errors';
import {
  addressInitials,
  getAddressType,
  stateInitals,
} from '@helpers/input-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { handleWarningModalContent } from '@store/slices/warning-modal-slice';

import AddressService from '@api/services/address-service';
import InvalidFieldError from '@errors/types/invalid-field-error';

import { Container } from './styles';

export interface AddressData {
  enderecoLocalEntrega: {
    cep: string;
    tipoLogradouro: string;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    estado: string;
  };
}

type AddressInputsProps = {
  isLoading?: boolean;
};

function AddressInputs({ isLoading = false }: AddressInputsProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const {
    control,
    setValue,
    setError,
    register,
    formState: { errors },
  } = useFormContext<CompanyType>();
  const { config } = useSelector(({ application }) => application);

  const handleChangeAddress = async (cep: string) => {
    if (!apiError) {
      const addressService = new AddressService();
      const cepFormatted = clearNotNumber(cep);
      if (cepFormatted.length === CEP_LENGTH_FORMATTED) {
        setLoading(true);
        await addressService
          .getAddress(
            {
              siglaEmissor: config.emissor.initials,
              siglaCanal: config.siglaCanal,
              siglaSubCanal: config.siglaSubCanal,
            },
            cepFormatted,
          )
          .then(response => {
            setApiError(false);
            setValue(
              'enderecoLocalEntrega.tipoLogradouro',
              getAddressType(response.tipoLogradouro),
            );
            setValue('enderecoLocalEntrega.logradouro', response.logradouro);
            setValue('enderecoLocalEntrega.cidade', response.cidade);
            setValue('enderecoLocalEntrega.estado', response.UF);
            setValue('enderecoLocalEntrega.bairro', response.bairro);
            setValue('enderecoLocalEntrega.numero', '');
            setValue('enderecoLocalEntrega.complemento', '');
            setLoading(false);
          })
          .catch(error => {
            setValue('enderecoLocalEntrega.logradouro', '');
            setValue('enderecoLocalEntrega.tipoLogradouro', '');
            setValue('enderecoLocalEntrega.numero', '');
            setValue('enderecoLocalEntrega.complemento', '');
            setValue('enderecoLocalEntrega.bairro', '');
            setValue('enderecoLocalEntrega.cidade', '');
            setValue('enderecoLocalEntrega.estado', '');

            if (error instanceof InvalidFieldError) {
              setError('enderecoLocalEntrega.cep', {
                type: 'manual',
                message: 'CEP não encontrado',
              });
            } else {
              dispatch(
                handleWarningModalContent({
                  isVisible: true,
                  title: ApiErrorsModalTitle.DEFAULT,
                  content: ApiErrorsMessages.CEP_ERROR,
                }),
              );
              setApiError(true);
            }
            setLoading(false);
          });
      }
    }
  };

  return (
    <Container loading={loading}>
      {loading && (
        <div className="loader__holder">
          <Loader color="$primaryPure" size="lg" />
        </div>
      )}
      <div className="address__input">
        <Input.Default
          label="CEP*"
          type="text"
          autoComplete="new-password"
          placeholder="CEP"
          width="100%"
          disabled={isLoading}
          errorMessage={errors.enderecoLocalEntrega?.cep?.message}
          customMask="99999-999"
          css={{ width: '20%' }}
          inputMode="numeric"
          {...register('enderecoLocalEntrega.cep', {
            onBlur(event) {
              handleChangeAddress(event.target.value);
            },
            minLength: {
              value: CEP_LENGTH,
              message: FormErrors.INVALID_CEP,
            },
            maxLength: {
              value: CEP_LENGTH,
              message: FormErrors.INVALID_CEP,
            },
            required: FormErrors.REQUIRED_CEP,
          })}
        />
        <Controller
          control={control}
          name="enderecoLocalEntrega.tipoLogradouro"
          rules={{ required: FormErrors.REQUIRED_DEFAULT }}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              id="de_tipo-logradouro"
              label="Tipo de Logradouro*"
              placeholder="Selecionar"
              onChange={e => {
                onChange(e.target.value);
              }}
              value={value}
              disabled={!apiError}
              errorMessage={
                errors.enderecoLocalEntrega?.tipoLogradouro?.message
              }
              css={{ width: '20%' }}
            >
              {addressInitials?.map(address => (
                <Option key={address.content} value={address.content}>
                  {address.content}
                </Option>
              ))}
            </Dropdown>
          )}
        />
        <Input.Default
          label="Logradouro*"
          type="text"
          placeholder="Logradouro"
          maxLength={ADDRESS_MAX_LENGTH}
          width="100%"
          errorMessage={errors.enderecoLocalEntrega?.logradouro?.message}
          {...register('enderecoLocalEntrega.logradouro', {
            required: FormErrors.REQUIRED_DEFAULT,
          })}
          disabled={!apiError}
          css={{ width: '38%' }}
        />
        <Input.Default
          label="Número*"
          type="number"
          placeholder="Número"
          maxLength={ADDRESS_MAX_LENGTH}
          width="100%"
          disabled={isLoading}
          errorMessage={errors.enderecoLocalEntrega?.numero?.message}
          css={{ width: '16%' }}
          inputMode="numeric"
          {...register('enderecoLocalEntrega.numero', {
            required: FormErrors.REQUIRED_DEFAULT,
          })}
        />
      </div>
      <div className="address__input">
        <Input.Default
          label="Complemento"
          type="text"
          placeholder="Complemento"
          maxLength={ADDRESS_COMPLEMENT_MAX_LENGTH}
          width="100%"
          disabled={isLoading}
          errorMessage={errors.enderecoLocalEntrega?.complemento?.message}
          css={{ width: '20%' }}
          {...register('enderecoLocalEntrega.complemento')}
        />
        <Input.Default
          label="Bairro*"
          type="text"
          placeholder="Bairro"
          maxLength={NEIGHBORHOOD_MAX_LENGTH}
          width="100%"
          errorMessage={errors.enderecoLocalEntrega?.bairro?.message}
          css={{ width: '20%' }}
          {...register('enderecoLocalEntrega.bairro', {
            required: FormErrors.REQUIRED_DEFAULT,
          })}
          disabled={!apiError}
        />
        <Input.Default
          label="Cidade*"
          type="text"
          placeholder="Cidade"
          maxLength={CITY_MAX_LENGTH}
          width="100%"
          errorMessage={errors.enderecoLocalEntrega?.cidade?.message}
          css={{ width: '38%' }}
          {...register('enderecoLocalEntrega.cidade', {
            required: FormErrors.REQUIRED_DEFAULT,
          })}
          disabled={!apiError}
        />
        <Controller
          control={control}
          name="enderecoLocalEntrega.estado"
          rules={{ required: FormErrors.REQUIRED_DEFAULT }}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              id="de_estado"
              label="Estado*"
              placeholder="Selecionar"
              onChange={e => {
                onChange(e.target.value);
              }}
              value={value}
              disabled={!apiError}
              css={{ width: '16%' }}
              errorMessage={errors.enderecoLocalEntrega?.estado?.message}
            >
              {stateInitals?.map(data => (
                <Option key={data} value={data}>
                  {data}
                </Option>
              ))}
            </Dropdown>
          )}
        />
      </div>
    </Container>
  );
}

export default AddressInputs;
