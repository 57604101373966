import { CSS } from '@stitches/react';

import { DefaultTokenVariantsType } from '@extra-types/token-variants-type';
import { holderStyleWithMobileResponsive } from '@helpers/style-helpers';

import { config, styled } from '@src/themes';

export type PageSectionVariantType = DefaultTokenVariantsType | 'tertiary';

interface PageSectionData {
  children: React.ReactNode;
  id?: string;
  tag?: 'header' | 'footer' | 'section' | 'main' | 'div';
  dark?: boolean;
  common?: boolean;
  noPadding?: boolean;
  variant?: PageSectionVariantType | 'header' | 'footer';
  css?: CSS<typeof config>;
  className?: string;
}

export function PageSection({
  tag = 'section',
  dark,
  common = false,
  variant = 'primary',
  id,
  noPadding,
  css,
  className,
  children,
}: PageSectionData) {
  return (
    <PageSectionContainer
      id={id}
      as={tag}
      variant={variant}
      dark={dark}
      common={common}
      css={css}
      noPadding={noPadding}
      className={className}
    >
      <div className="page-section-container__holder">{children}</div>
    </PageSectionContainer>
  );
}

export const PageSectionContainer = styled('section', {
  '.page-section-container__holder': holderStyleWithMobileResponsive,

  variants: {
    variant: {
      primary: {
        background: '$white',
      },
      secondary: {
        background: '$primaryPure',
      },
      tertiary: {
        background: '$silverPure',
      },
      header: {
        background: '$headerBackgroundColor',
      },
      footer: {
        background: '$footerBackgroundColor',
      },
    },
    noPadding: {
      true: {
        '.page-section-container__holder': {
          padding: '0',

          '@deviceSm': {
            padding: '0',
          },
        },
      },
    },
    dark: {
      true: {},
    },
    common: {
      true: {},
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      dark: true,
      css: {
        background: '$silverPure',
      },
    },
    {
      variant: 'secondary',
      dark: true,
      css: {
        background: '$sectionBackgroundSecondaryColor',
      },
    },
    {
      variant: 'tertiary',
      dark: true,
      css: {
        background: '$walletBackground',
        color: '$fontColorPrimary',
      },
    },
    {
      variant: 'tertiary',
      dark: true,
      common: true,
      css: {
        background: '#2D3233',
        color: '$fontColorPrimary',
      },
    },
  ],
  defaultVariants: {
    variant: 'primary',
    dark: false,
  },
});
