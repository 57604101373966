import { Controller, useFormContext } from 'react-hook-form';

import Dropdown from '@components/dropdown';
import { ProductInformation } from '@components/product-information';
import Option from '@elements/option';
import { HCMFormType } from '@pages/offer-page/custom-offer-pages/components/combo-product-services-form/combo-hcm-service-form';
import { ComboServiceFormServiceInformation } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-service-information';

import { HCMOptions } from '@config/hcm-config';
import { ModalityType } from '@extra-types/modality-type';
import { ProductType } from '@extra-types/product-type';
import { FormErrors } from '@helpers/enum/form-errors';

import { AverageEmployeeContainer } from '../cart-page-hcm-header';

type CartPageHcmHeaderProps = {
  isFormActive?: boolean;
  modalityData: ModalityType;
  plan: ProductType;
};

export function CartPageHcmHeaderSimplified({
  isFormActive = false,
  modalityData,
  plan,
}: CartPageHcmHeaderProps) {
  const methods = useFormContext<HCMFormType>();

  const averageEmployeesID = `service-${modalityData.keyId}-average-employees`;

  return (
    <ComboServiceFormServiceInformation isCollapse={!isFormActive}>
      <ProductInformation productData={plan} size="small" />
      <AverageEmployeeContainer className="product-actions">
        <p>Média de trabalhadores</p>
        <Controller
          control={methods.control}
          name="average-employees"
          rules={{ required: FormErrors.REQUIRED_DEFAULT }}
          render={({ field: { value } }) => (
            <Dropdown
              id={averageEmployeesID}
              data-testid={averageEmployeesID}
              value={value}
              placeholder="selecione"
              label=""
              onChange={() => null}
              disabled
              css={{
                width: '132px',
                span: {
                  paddingBottom: 'unset',
                  fontSize: '0.75rem',
                  marginRight: '0.5rem',
                },
              }}
            >
              {HCMOptions[plan.id].map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Dropdown>
          )}
        />
      </AverageEmployeeContainer>
    </ComboServiceFormServiceInformation>
  );
}
