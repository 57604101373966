import { ComparinsonTable } from '@components/comparinson-table';
import { Button } from '@elements/button';
import { MobilitySectionTitle } from '@elements/mobility-section-title';
import { PageSection, PageSectionVariantType } from '@elements/page-section';
import { SectionHolder } from '@elements/section-holder';

import { styled } from '@src/themes';

interface PATAuxComparasionSectionProps {
  contractIDSection: string;
  variant?: PageSectionVariantType;
}

const titleVariantMap: Record<PageSectionVariantType, string> = {
  primary: '$black',
  secondary: 'white',
  tertiary: 'white',
};

export function PATAuxComparasionSection({
  contractIDSection,
  variant = 'primary',
}: PATAuxComparasionSectionProps) {
  return (
    <PageSection variant={variant} dark common>
      <PATAuxComparasionSectionHolder>
        <MobilitySectionTitle color={titleVariantMap[variant]}>
          *Tudo sobre PAT e Auxílio
        </MobilitySectionTitle>
        <ComparinsonTable
          variant={variant}
          columnGap={86}
          columnTitles={{
            left: 'PAT',
            right: 'Auxílio',
          }}
          itemRowList={[
            {
              id: 'pataux-row-1',
              title: (
                <>
                  Todas as empresas devem oferecer, ao trabalhador,{' '}
                  <strong>
                    programa voltado à promoção da saúde e segurança
                    nutricional.
                  </strong>
                </>
              ),
              left: true,
              right: false,
            },
            {
              id: 'pataux-row-2',
              title: (
                <>
                  <strong>Ofertas com Serviços Adicionais.</strong>
                  <br />
                  (Desconto Fermácia, Desconto Academia, Nutrição, Apoio ao
                  Trabalhador e/ou Seguro Alimentação).
                </>
              ),
              left: true,
              right: true,
            },
            {
              id: 'pataux-row-3',
              title: (
                <>
                  <strong>Ofertas com Programa de Recompensas.</strong>
                  <br />
                  (Antigo VR Fidelidade).
                </>
              ),
              left: false,
              right: true,
            },
            {
              id: 'pataux-row-4',
              title: (
                <>
                  <strong>Dedução de até 4% na base de cálculo do IRPJ</strong>{' '}
                  para empresas que declaram pelo lucro real (e não pelo
                  presumido).
                </>
              ),
              left: true,
              right: false,
            },
            {
              id: 'pataux-row-5',
              title: (
                <>
                  Coparticipação,{' '}
                  <strong>
                    pelo trabalhador, de até 20% do valor pago como benefício.
                  </strong>
                </>
              ),
              left: true,
              right: false,
            },
            {
              id: 'pataux-row-6',
              title: (
                <>
                  <strong>
                    Não incidência de encargos trabalhistas e previdenciais
                  </strong>
                  , como FGTS, INSS, etc.
                </>
              ),
              left: true,
              right: true,
            },
            {
              id: 'pataux-row-7',
              title: (
                <>
                  <strong>Permite um cartão com saldo único</strong> para uso em
                  Refeição e Alimentação.
                </>
              ),
              left: false,
              right: true,
            },
          ]}
        />
        <Button.Anchor href={`#${contractIDSection}`}>
          Contratar benefícios
        </Button.Anchor>
      </PATAuxComparasionSectionHolder>
    </PageSection>
  );
}

const PATAuxComparasionSectionHolder = styled(SectionHolder, {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  gap: '2.5rem',

  '> table': {
    maxWidth: '1000px',
  },
});
