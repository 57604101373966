import React from 'react';

import { useFAQContext } from '@hooks/use-faq-context';

import { FAQArticles } from '@api/models/response/faq-articles-list-response';

interface SearchTipsProps {
  categoryTitle: string;
  tip: FAQArticles[];
  resetInputValue: () => void;
}

function SearchTips({ categoryTitle, tip, resetInputValue }: SearchTipsProps) {
  const {
    handleSelectedArticle,
    getCategoryById,
    handleChangeCategory,
    backToFAQ,
  } = useFAQContext();

  return (
    <>
      {tip.length > 1 && (
        <strong className="category-title">{categoryTitle}</strong>
      )}
      {tip.map(
        t =>
          t.id && (
            <button
              type="button"
              className="article-tip"
              key={t.id}
              onClick={() => {
                backToFAQ();
                handleSelectedArticle(t);
                resetInputValue();
                handleChangeCategory(getCategoryById(t.categoryId));
              }}
            >
              {t.title}
            </button>
          ),
      )}
    </>
  );
}

export default SearchTips;
