import { Button } from '@elements/button';
import { PageSection } from '@elements/page-section';

import { footerPageLinkContent } from '@config/emissor-config';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useContactModalContext } from '@hooks/use-contact-modal-context';
import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

export function FooterVRPAT() {
  const { handleContactModal } = useContactModalContext();
  const emissor = useSelector(({ application }) => application.config.emissor);
  const FULL_YEAR_COPYRIGHT = new Date().getFullYear().toString();

  return (
    <PageSection variant="footer" tag="footer" dark>
      <FooterContent>
        <div className="footer-container__left-content">
          {footerPageLinkContent[emissor.initials].map(linkGroup => {
            return (
              <LinkList key={linkGroup.id}>
                <>
                  {linkGroup.title && (
                    <li key={linkGroup.id} className="group__title">
                      {linkGroup.title}
                    </li>
                  )}
                  {linkGroup.linkList.map(linkData => (
                    <li key={linkData.id} className="group__link">
                      <a
                        id={linkData.id}
                        title={`Link para a página ${linkData.label}`}
                        href={linkData.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {linkData.label}
                      </a>
                    </li>
                  ))}
                </>
              </LinkList>
            );
          })}
        </div>
        <div className="footer-container__right-content">
          {emissor.socialMediaList.length > 0 && (
            <SocialGroupLink>
              {emissor.socialMediaList.map(socialMedia => (
                <li key={socialMedia.id}>
                  <a
                    id={socialMedia.id}
                    title={`Link para a página ${socialMedia.name} da empresa ${emissor.name}`}
                    href={socialMedia.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className={socialMedia.icon} />
                  </a>
                </li>
              ))}
            </SocialGroupLink>
          )}
          <CopyrightContent>
            © {FULL_YEAR_COPYRIGHT} {emissor.copyright.title} - CNPJ:&nbsp;
            {emissor.copyright.cnpj} - Todos os direitos reservados.&nbsp;
            <a
              title={`Link para ${emissor.privacyPolicy.title.toLowerCase()} da empresa ${
                emissor.name
              }`}
              href={emissor.privacyPolicy.url}
              target="_blank"
              rel="noreferrer"
            >
              {emissor.privacyPolicy.title}
            </a>
          </CopyrightContent>
          <Button.Default variant="secondary" onClick={handleContactModal}>
            <i className="fa-regular fa-headset" />
            Precisa de ajuda ou atendimento?
          </Button.Default>
        </div>
      </FooterContent>
    </PageSection>
  );
}

export const FooterContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',

  width: '100%',
  padding: '2.5rem 0',

  '@deviceMd': {
    flexDirection: 'column',
    gap: '4rem',
  },

  '.footer-container__left-content': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '4rem',

    '@deviceMd': {
      flexDirection: 'column',
      width: '100%',
    },
  },

  '.footer-container__right-content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',

    width: 'calc(100% / 2.5 - 2rem)',

    '@deviceMd': {
      width: '100%',
    },
  },
});

export const LinkList = styled('u', {
  listStyle: 'none',
  textDecoration: 'none',

  '.group__title': {
    width: 'fit-content',
    borderBottom: 'solid 3px $secondaryPure',
    marginBottom: convertPixelToRem(10),
    textTransform: 'uppercase',

    color: '$white',
    fontSize: convertPixelToRem(16),
    fontWeight: '800',
    lineHeight: '2',
  },

  '.group__link': {
    width: 'fit-content',
    marginTop: convertPixelToRem(20),

    a: {
      position: 'relative',

      color: '$white',
      fontSize: convertPixelToRem(16),
      fontWeight: '500',
      textDecoration: 'none',
      transition: 'color 0.4s ease',

      '&:hover': {
        color: '$secondaryPure',
      },
    },
  },
});

export const SocialGroupLink = styled('ul', {
  display: 'flex',
  justifyContent: 'space-between',

  width: '100%',
  marginBottom: 'convertPixelToRem(42)',
  listStyle: 'none',

  'li > a': {
    color: '$white',
    fontSize: convertPixelToRem(32),
    transition: 'color 0.4s ease',

    '&:hover': {
      color: '$secondaryPure',
    },
  },
});

export const CopyrightContent = styled('div', {
  color: '$white',
  fontSize: convertPixelToRem(12),
  fontWeight: '500',
  lineHeight: '1.8',

  a: {
    transition: 'color 0.4s ease',

    color: '$secondaryPure',
    textAlign: 'center',
    textDecoration: 'none',

    '&:hover': {
      color: '$white',
    },
  },

  '@deviceMd': {
    textAlign: 'center',
  },
});
