import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Button } from '@elements/button';

import { convertPixelToRem } from '@helpers/style-helpers';

import { useCookiesConsent } from '@hooks/use-cookies-consent';
import { useSelector } from '@hooks/use-selector';

import { MessageContainer } from './styles';

function CookiesConsentModal() {
  const messageRef = useRef(null);
  const { isModalOpen, onAcceptedCookies } = useCookiesConsent();
  const config = useSelector(({ application }) => application.config);

  return (
    <CSSTransition
      nodeRef={messageRef}
      in={isModalOpen}
      timeout={400}
      classNames="cookies-message"
      unmountOnExit
    >
      <MessageContainer ref={messageRef}>
        <h2>Consentimento de Cookies</h2>
        <p>
          Nós utilizamos cookies para que você tenha a melhor experiência em
          nosso site, saiba mais sobre em noss
          {config.emissor.privacyPolicy.article}{' '}
          <a
            href={`${config.emissor.privacyPolicy.url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {config.emissor.privacyPolicy.title}
          </a>
          .
        </p>

        <div className="cookies-message__button-section">
          <Button.Default
            id="sc_terms-consent"
            data-testid="terms-consent"
            variant="primary"
            onClick={onAcceptedCookies}
            size="small"
            css={{
              minWidth: convertPixelToRem(120),
            }}
          >
            Entendi e fechar
          </Button.Default>
        </div>
      </MessageContainer>
    </CSSTransition>
  );
}

export default CookiesConsentModal;
