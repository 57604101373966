import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import debounce from 'lodash.debounce';

import { Input } from '@components/input';
import { WalletFormInputs } from '@compositions/wallet-product-form';
import { ProductImage } from '@elements/product-image';

import { DIGIT_WAIT_TIME_TO_PRICE } from '@config/application-config';
import {
  FONT_SIZE_INPUT_PRODUCT_PAGE,
  MAX_LENGTH_QUANTITY_CARDS,
} from '@config/input-config';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { FormErrors } from '@helpers/enum/form-errors';
import { getProductData, getProductType } from '@helpers/product-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { addProductToCart } from '@store/slices/cart-slice';
import { fetchCartPricing } from '@store/thunks/cart-thunk';

import { OfferFacade } from '@src/facade/offer-facade';

import { WalletItemContainer } from './styles';

export function CartPageWalletFormWalletItem() {
  const { selectedOffer } = OfferFacade();
  const dispatch = useDispatch();
  const isCustomerLoading = useSelector(({ customer }) => customer.isLoading);
  const { isCartLoading, cartModality, productCartList } = useSelector(
    ({ cart }) => cart,
  );
  const { config, isLoading } = useSelector(({ application }) => application);
  const productList = useSelector(({ products }) => products.productList);

  const {
    register,
    trigger,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormContext<WalletFormInputs>();

  const amount = watch('amount');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(async () => {
      const isFormValid = await trigger(['amount', 'products']);
      handleSubmit((form: WalletFormInputs) => {
        if (
          isFormValid &&
          productCartList.length > 0 &&
          form.amount !== Number(productCartList[0].amount)
        ) {
          productCartList.forEach(product => {
            const productData = getProductData(product.id, productList);
            dispatch(
              addProductToCart({
                id: product.id,
                type: getProductType(productData),
                amount: form.amount,
                value: product.value,
                planType: PlanTypeEnum.MENSAL,
              }),
            );
          });
          dispatch(fetchCartPricing({ offerData: selectedOffer }));
        }
      })();
    }, DIGIT_WAIT_TIME_TO_PRICE),
    [handleSubmit, dispatch, productCartList],
  );

  useEffect(() => {
    debouncedSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  if (!cartModality || cartModality.id === -1) {
    return null;
  }

  return (
    <WalletItemContainer
      css={{
        $$walletColor: `$colors$${cartModality.colorPrefixToken}ColorName`,
      }}
    >
      <div className="wallet-item__title">
        <ProductImage productId={cartModality.id} />
        {cartModality.shelfInformation.title}
      </div>
      <div className="wallet-item__wallet-amount">
        <Input.Numeric
          id={`cart-page-modality-${cartModality.keyId}-amount`}
          customMask="999"
          placeholder="0"
          width="6rem"
          maxLength={MAX_LENGTH_QUANTITY_CARDS}
          fontSize={FONT_SIZE_INPUT_PRODUCT_PAGE}
          errorMessage={errors?.amount?.message}
          disabled={isCartLoading || isLoading || isCustomerLoading}
          label="Qnt. cartões"
          {...register('amount', {
            valueAsNumber: true,
            shouldUnregister: true,
            required: 'É obrigatório',
            min: {
              value: 1,
              message: `${FormErrors.MIN_CARD_AMOUNT} 1`,
            },
            max: {
              value: config.maxBeneficiaryAmount,
              message: `${FormErrors.MAX_CARD_AMOUNT} ${config.maxBeneficiaryAmount}`,
            },
          })}
        />
      </div>
    </WalletItemContainer>
  );
}
