import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class AuthenticationError extends ApplicationError {
  constructor(message?: string) {
    super(
      SpecificsApiErrors.AUTHENTICATION,
      message || ApiErrorsMessages.AUTHENTICATION,
    );
  }
}
