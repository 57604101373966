import { ComboProductServicesForm } from '@pages/offer-page/custom-offer-pages/components/combo-product-services-form';

import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { createComboModalityFormId } from '@helpers/service-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';

import { styled } from '@src/themes';

import { ComboBonusServiceForm } from '../components/combo-product-services-form/combo-bonus-service-form';

export function ComboProductServicesFormList() {
  const {
    modalitiesForSale,
    activeForm,
    handleActiveForm,
    handleActiveSummary,
    handleNextForm,
  } = useComboCartPageContext();

  return (
    <ComboProductServicesFormListContainer>
      {modalitiesForSale.map(modalityData => {
        const ModalityElement = ComboProductServicesForm[modalityData.id];
        return ModalityElement ? (
          <ModalityElement
            key={modalityData.id}
            modalityData={modalityData}
            isFormActive={
              activeForm ===
              `${createComboModalityFormId(modalityData.serviceGroupId)}`
            }
            handleShowDetails={() => {
              handleActiveForm(modalityData.serviceGroupId);
              handleActiveSummary({ id: modalityData.serviceGroupId });
            }}
            handleNextForm={() => handleNextForm(modalityData.serviceGroupId)}
          />
        ) : null;
      })}
      <ComboBonusServiceForm
        handleNextForm={() => handleNextForm(ServiceGroupId.SVA)}
        handleShowDetails={() => {
          handleActiveForm(ServiceGroupId.SVA);
          handleActiveSummary({ id: ServiceGroupId.SVA });
        }}
        isFormActive={activeForm === `service-${ServiceGroupId.SVA}-form`}
      />
    </ComboProductServicesFormListContainer>
  );
}

const ComboProductServicesFormListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
});
