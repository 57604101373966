import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { Input } from '@components/input';
import { ProductWalletInput } from '@components/product-wallet-input/product-wallet-input';
import { Button } from '@elements/button';

import { ModalityType } from '@extra-types/modality-type';
import { FormErrors } from '@helpers/enum/form-errors';
import { getProductListByModality } from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

import { WalletFormInputs } from '.';
import { ActionsHolder, ProductsHolder, WarningPhrase } from './styles';

type WalletInputsProps = {
  modalityData: ModalityType;
  enableEdit?: boolean;
};

function Header({ modalityData }: WalletInputsProps) {
  const methods = useFormContext<WalletFormInputs>();
  const config = useSelector(({ application }) => application.config);

  return (
    <div className="header-holder__action-holder">
      <p>
        Escolha a quantidade de cartões&nbsp;
        {modalityData.shelfInformation.title}:
      </p>
      <Input.Numeric
        id={`${modalityData.keyId}-amount`}
        customMask="999"
        placeholder="0"
        width="6rem"
        errorMessage={methods.formState.errors.amount?.message}
        {...methods.register('amount', {
          valueAsNumber: true,
          required: {
            value: true,
            message: FormErrors.REQUIRED_DEFAULT,
          },
          min: {
            value: 1,
            message: `${FormErrors.MIN_CARD_AMOUNT} 1`,
          },
          max: {
            value: config.maxBeneficiaryAmount,
            message: `${FormErrors.MAX_CARD_AMOUNT} ${config.maxBeneficiaryAmount}`,
          },
        })}
      />
    </div>
  );
}

function Content({ modalityData, enableEdit }: WalletInputsProps) {
  const { productList } = useSelector(({ products }) => products);
  const methods = useFormContext<WalletFormInputs>();

  const productListByModality = useMemo(
    () => getProductListByModality(modalityData.id, productList),
    [modalityData.id, productList],
  );

  const watchAmount = methods.watch('amount');
  const watchProducts = methods.watch('products');

  function validateIfHasAtLeastOneProductFilled() {
    if (watchProducts !== undefined) {
      return Object.entries(watchProducts).some(product => product[1].value);
    }
    return false;
  }

  const calcSubTotal = () => {
    if (watchProducts) {
      return Object.entries(watchProducts).reduce((totalValue, product) => {
        if (product[1]) {
          return totalValue + (watchAmount || 0) * Number(product[1].value);
        }
        return totalValue;
      }, 0);
    }
    return 0;
  };

  return (
    <>
      <WarningPhrase>
        Escolha os saldos e os valores mensais de cada um. Caso não queira
        contratar algum dos saldos disponíveis abaixo, é só não preencher o
        campo correspondente ao valor do crédito.
      </WarningPhrase>
      <ProductsHolder>
        {productListByModality.map(product => (
          <ProductWalletInput key={product.id} productData={product} />
        ))}
      </ProductsHolder>
      <ActionsHolder>
        <div className="actions-holder__total-value-box">
          <p>Subtotal</p>
          <p>
            <span className="total-value-box__total-value">
              {formatValueToCurrency(calcSubTotal())}
            </span>
          </p>
        </div>

        <Button.Default
          id={`botao-adicionar-carrinho-${modalityData.keyId}`}
          type="submit"
          disabled={
            !validateIfHasAtLeastOneProductFilled() ||
            !methods.formState.isValid
          }
        >
          {enableEdit ? 'Atualizar carrinho' : 'Adicionar ao carrinho'}
        </Button.Default>
      </ActionsHolder>
    </>
  );
}

export const WalletInputs = {
  Content,
  Header,
};
