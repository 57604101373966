import { config, AxiosInstance } from './axios-client';

interface Header {
  [key: string]: string;
}

export type AuthenticatorInstance = AxiosInstance;

/**
 * @interface CreateProps
 *
 * @param {string | undefined} baseUrl
 * @param {string | undefined} header
 * @param {string | undefined} timeout
 */
export interface CreateProps {
  baseUrl?: string;
  headers?: Header;
  timeout?: number;
}

/**
 * @interface ConfigProps
 *
 * @param {string | undefined} baseUrl
 * @param {string | undefined} header
 * @param {string | undefined} timeout
 * @param {string} clientId
 * @param {string} identificadorPortal
 * @param {string} tipoPerfilAcesso
 * @param {string} identificadorSelecao
 * @param {string} identificadorPerfilAcesso
 */
export interface ConfigProps {
  baseUrl?: string;
  headers?: Header;
  timeout?: number;
  clientId: string;
}

type Config = (options: ConfigProps) => AuthenticatorInstance;

export interface HttpClient {
  /**
   * Cria uma instância do Axios personalizada com as configurações
   * de autenticação prevista no modelo:
   * http://documentacao.vr.com.br/Produtos/Autentica%C3%A7%C3%A3o%20Usu%C3%A1rio%20VR/
   *
   * @param {ConfigProps} options
   * @property {string | undefined} baseUrl
   * @property {string | undefined} header
   * @property {string | undefined} timeout
   * @property {string} clientId
   * @property {string} identificadorPortal
   * @property {string} tipoPerfilAcesso
   * @property {string} identificadorSelecao
   * @property {string} identificadorPerfilAcesso
   *
   * @returns uma instância do tipo AuthenticatorInstance
   *
   * @example Se não possui os dados de uma propriedade obrigatória, informe então uma string com 'NAO_SELECIONADO';
   * ```ts
   * import { NAO_SELECIONADO, http } from "@vr/frontend-authenticator";
   *
   * const api = http.config({
   *     baseUrl: '/portal/web-api',
   *     clientId: clientId,
   *     identificadorPortal: idPortal,
   *     identificadorSelecao: idSelecao,
   *     tipoPerfilAcesso: tipoPerfilAcesso,
   *     identificadorPerfilAcesso: idPerfilAcesso,
   * });
   * ```
   */
  config: Config;
}

/**
 * Constante com as funções básicas
 * @property {function} create
 * @property {function} config
 */
export const http: HttpClient = {
  config,
};

/**
 * Código de erro personalizado para Token Inválido
 */
export const AUTH_ERR_TOKEN_INVALID = 'AUTH_ERR_TOKEN_INVALID';

/**
 * Constante para informar em parâmetros obrigatório quando nõa há dado satisfatório;
 */
export const NAO_SELECIONADO = 'NAO_SELECIONADO';
