import { Button } from '@elements/button';
import { PageSection } from '@elements/page-section';
import { SectionHolder } from '@elements/section-holder';

import { SectionDefaultPropsType } from '@extra-types/section-props-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useContactModalContext } from '@hooks/use-contact-modal-context';
import { useInformationSection } from '@hooks/use-information-section';

import { styled } from '@src/themes';

export function ContactSection({ id, name }: SectionDefaultPropsType) {
  const { handleContactModal } = useContactModalContext();
  const { sectionInformation, sectionVariant } = useInformationSection({
    name,
    sectionVariant: 'secondary',
  });

  if (!sectionInformation) {
    return null;
  }

  return (
    <PageSection id={id} dark variant={sectionVariant}>
      <ContactSectionContainer>
        <div className="contact-section__title-area">
          <ContactTitle>{sectionInformation.titulo}</ContactTitle>
          <i className="fa-regular fa-headset" />
        </div>
        <div className="contact-section__content">
          <p>{sectionInformation.subTitulo}</p>
          <Button.Default
            id="btn-atendimento-home"
            variant="secondary"
            onClick={handleContactModal}
          >
            Fale conosco!
          </Button.Default>
        </div>
      </ContactSectionContainer>
    </PageSection>
  );
}

const ContactSectionContainer = styled(SectionHolder, {
  '.contact-section__title-area': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: convertPixelToRem(20),
    marginBottom: '2rem',

    i: {
      color: '$secondaryPure',
      fontSize: convertPixelToRem(40),
    },

    '@deviceSm': {
      alignItems: 'flex-start',
    },
  },

  '.contact-section__content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: convertPixelToRem(24),

    p: {
      color: '$white',
      lineHeight: '1.625',
      fontWeight: '$regular',
      textAlign: 'center',

      '@deviceSm': {
        textAlign: 'start',
      },
    },

    button: {
      width: 'fit-content',

      '@deviceSm': {
        justifyContent: 'center',

        width: '100%',
      },
    },
  },
});

const ContactTitle = styled('h2', {
  color: '$white',
  fontSize: convertPixelToRem(24),
  fontWeight: '$semibold',
  lineHeight: '1.25',
});
