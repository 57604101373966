import { CSS } from '@stitches/react';

import {
  defaultTokenVariantList,
  productsTokenVariantsList,
} from '@config/styles-config';
import {
  DefaultTokenVariantsType,
  ProductsTokenVariantsType,
} from '@extra-types/token-variants-type';
import {
  convertPixelToRem,
  productsVariantsEmptyObjectList,
} from '@helpers/style-helpers';

import { config, styled } from '@src/themes';

type BenefitItemCompoundVariant = {
  variant: DefaultTokenVariantsType;
  productVariant: ProductsTokenVariantsType;
  css: CSS<typeof config>;
};

function createProductCompoundVariant() {
  const productCompoundVariants: BenefitItemCompoundVariant[] = [];

  productsTokenVariantsList.forEach(productTokenVariant => {
    defaultTokenVariantList.forEach(defaultVariant => {
      productCompoundVariants.push({
        variant: defaultVariant,
        productVariant: productTokenVariant,
        css: {
          '.benefit-item__icon': {
            'svg, img, i': {
              fill: `$${productTokenVariant}ColorName`,
              color: `$${productTokenVariant}ColorName`,
            },
          },
          '.benefit-item__text > strong': {
            color: `$${productTokenVariant}ColorName`,
          },
          '.benefit-item__text > p': {
            color: '$multiFontColor',
          },
        },
      });
    });
  });

  return productCompoundVariants;
}

export const BenefitItemContainer = styled('article', {
  display: 'flex',
  gap: '1.25rem',
  width: '100%',

  '@deviceMd': {
    maxWidth: 'unset',
  },

  '.benefit-item__icon': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexShrink: '0',

    height: '3rem',
    width: '3rem',

    i: {
      fontSize: '2.6rem',
    },
  },

  '.benefit-item__text': {
    display: 'flex',
    flexDirection: 'column',

    strong: {
      fontSize: '1rem',
      fontWeight: '500',
    },

    p: {
      marginTop: '0.5rem',

      fontSize: '1rem',
      fontWeight: '500',
      lineHeight: '2',

      '@deviceSm': {
        marginTop: '0.5rem',
        fontSize: convertPixelToRem(12),
      },
    },
  },

  variants: {
    variant: {
      primary: {
        '.benefit-item__icon': {
          'svg, img, i': {
            fill: '$fontColorPrimary',
            color: '$fontColorPrimary',
          },
        },
        '.benefit-item__text': {
          strong: {
            color: '$primaryPure',
          },
          p: {
            color: '$fontColorPrimary',
          },
        },
      },
      secondary: {
        '.benefit-item__icon': {
          'svg, img, i': {
            fill: '$white',
            color: '$white',
          },
        },
        '.benefit-item__text': {
          strong: {
            color: '$secondaryPure',
          },
          p: {
            color: '$white',
          },
        },
      },
    },
    productVariant: productsVariantsEmptyObjectList,
    guidance: {
      column: {
        flexDirection: 'column',
      },
      row: {
        flexDirection: 'row',
      },
    },
    widthSize: {
      sm: {
        maxWidth: '22.5rem',
      },
      md: {
        maxWidth: convertPixelToRem(440),
      },
      lg: {
        maxWidth: '100%',
      },
    },
  },
  compoundVariants: createProductCompoundVariant(),
  defaultVariants: {
    variant: 'primary',
    guidance: 'column',
    productVariant: undefined,
    widthSize: 'sm',
  },
});
