import { convertPixelToRem, scrollbarStyle } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const HelpContainer = styled('div', {
  zIndex: '99999',
  position: 'fixed',
  right: '1.875rem',

  maxHeight: '90vh',
  borderRadius: '1rem 1rem 0 0',
  boxShadow: '0px 0px 48px #00000080',
  opacity: '1',
  transition: 'all 0.4s ease-in-out',

  '@deviceMd': {
    left: '0',
    right: '0',
    width: '100%',
  },

  a: {
    textDecoration: 'none',
  },

  '.help__holder': {
    position: 'relative',
    height: '100%',
  },

  '.help__header': {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#EFEFEF',
    padding: `1.875rem 1.25rem 0 1.25rem`,
    borderRadius: '1rem 1rem 0 0',

    // '.help-title, .help-image': {
    //   width: '50%',
    //   height: '150px',
    // },

    '.help-image': {
      textAlign: 'end',
    },

    img: {
      height: '100%',
    },

    h2: {
      position: 'relative',

      marginBottom: convertPixelToRem(8),

      color: '$primaryDark',
      fontSize: convertPixelToRem(24),
      fontWeight: '800',
      lineHeight: '1',
      textAlign: 'start',
    },

    p: {
      margin: 0,

      fontSize: convertPixelToRem(14),
      textAlign: 'start',
      fontWeight: '600',

      maxWidth: '200px',
    },

    '#fechar-atendimento': {
      zIndex: '4',
      position: 'absolute',
      top: '-1.25rem',
      right: '1.2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      height: convertPixelToRem(40),
      width: convertPixelToRem(40),
      margin: '0',
      background: '$white',
      borderWidth: '0',
      borderRadius: '50%',
      boxShadow: '0 0 4px 2px rgba(50, 50, 50, 0.2)',

      color: '$primaryDark',
      fontSize: '20px',

      cursor: 'pointer',
    },
  },

  '.help__content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    background: '#EFEFEF',
    maxHeight: 'calc(90vh - 180px)',

    padding: `${convertPixelToRem(18)}`,

    overflow: 'auto',

    ...scrollbarStyle,

    '.content__info': {
      display: 'flex',
      alignItems: 'center',

      padding: '1rem 1.25rem',
      background: '$white',
      boxShadow: '0px 0px 8px #00000029',
      borderRadius: '10px',
      cursor: 'pointer',

      i: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        marginRight: '1rem',
        minWidth: convertPixelToRem(50),

        fontSize: convertPixelToRem(24),
        color: '$white',

        backgroundColor: '$primaryPure',
        padding: '12px',
        borderRadius: '50%',
      },

      '.info__holder': {
        display: 'flex',
        flexDirection: 'column',

        color: '$fontColorPrimary',
      },

      '.info__title': {
        wordBreak: 'break-word',
        fontSize: convertPixelToRem(20),
        fontWeight: 'bold',
      },

      '.info__subtitle': {
        fontSize: convertPixelToRem(14),
        fontWeight: '500',
        lineHeight: '1.2',
      },
    },

    '.content__action-area': {
      button: {
        width: '100%',
      },
      i: {
        color: '$secondaryPure',
        fontSize: '1.25rem',
      },
    },
  },

  '@media only screen and (max-height: 440px)': {
    '.help__header > .help-title': {
      height: 'unset',
      width: '100%',

      p: {
        display: 'none',
      },
    },

    '.help-image': {
      display: 'none',
    },
    '.help__content': {
      maxHeight: 'calc(90vh - 70px)',
    },
  },

  '@deviceSm': {
    '.help__content': {
      '.content__info ': {
        i: {
          marginRight: '0.75rem',
        },
        '.info__title': {
          fontSize: convertPixelToRem(16),
        },
      },
    },
  },

  variants: {
    modalState: {
      true: {
        bottom: '0',
      },
      false: {
        bottom: '-100%',
      },
    },
  },
});
