export const FONT_SIZE_INPUT_PRODUCT_PAGE = 14;
export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 24;
export const COMPANY_MIN_LENGTH = 3;
export const CELLPHONE_MIN_LENGTH = 14;
export const CELLPHONE_MAX_LENGTH = 15;
export const MAIL_MAX_LENGTH = 70;
export const MAX_LENGTH_QUANTITY_CARDS = 4;
export const MAX_LENGTH_CURRENCY_VALUE = 11;
export const MIN_CARD_QUANTITY = 1;
export const CNPJ_MAX_LENGTH = 18;
export const CELLPHONE_LENGTH = 10;
export const MAX_NUMBER_INPUT_VALUE = 9999;

export const MASK_MONEY_CONFIG = {
  precision: 2,
  separator: ',',
  delimiter: '',
  unit: 'R$',
};
export const CEP_LENGTH_FORMATTED = 8;
export const CEP_LENGTH = 9;
export const ADDRESS_MAX_LENGTH = 120;
export const ADDRESS_NUMBER_MAX_LENGTH = 8;
export const ADDRESS_COMPLEMENT_MAX_LENGTH = 20;
export const NEIGHBORHOOD_MAX_LENGTH = 50;
export const CITY_MAX_LENGTH = 50;

export const NOT_NUMBER_REGEX = /[^0-9]/g;
export const FILE_ORDER_SPECIFIC_REGEX =
  /^([a-zA-Z, ]{0,3}[^a-zA-Z ]|[0-9,\n ])$/gm;
export const ALLOW_ONLY_ALPHABETICS = /[^a-zA-Z ]+/g;
export const EMAIL_REGEX = /[^\w-_+.@]+/g;
export const ALLOW_COMMA_AND_ALPHABETICS = /[^a-zA-Z0-9,\n ]+/g;
export const MATCH_ONLY_NUMBER = /[0-9]/g;
export const ONE_OR_MORE_OCCURRENCIES_OF_COMMA = /,+/g;
export const ONE_OR_MORE_OCCURRENCIES_OF_SPACE = /\s+/g;
export const FIRST_CHARACTER_ZERO = /^[0]/;
export const CPF_MAX_LENGTH = 11;
export const BIRTHDATE_LENGTH = 8;
export const BIRTHDATE_MIN_LENGTH = 2;
export const FIND_ALPHABETICS = /[a-zA-Z ]+/g;
