import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

const SwitchContainer = styled('label', {
  position: 'relative',
  display: 'inline-block',
  width: convertPixelToRem(40),
  height: '1.5rem',

  background: 'transparent',
  borderRadius: '$circular',

  input: {
    width: 0,
    height: 0,
    opacity: 0,

    '&:checked + .slider:before': {
      backgroundColor: '$switchInnerBackgroundEnd',
      transform: 'translateX(1rem)',
    },

    '&:checked + .slider:after': {
      transform: 'translateX(1rem)',
    },

    '&:checked + .slider': {
      backgroundColor: '$switchBackgroundEnd',
    },
  },

  '.slider': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: 'pointer',

    backgroundColor: '$switchBackgroundInitial',
    borderRadius: '$pill',
    transition: 'all 0.4s ease',

    '&:before': {
      position: 'absolute',
      content: '',
      left: '3px',
      bottom: '3px',

      backgroundColor: '$switchInnerBackgroundInitial',
      height: convertPixelToRem(18),
      width: convertPixelToRem(18),
      borderRadius: '$circular',
      transition: 'all 0.4s ease',
    },

    '&:after': {
      content: '',
      display: 'block',
      position: 'absolute',
      left: '-3px',
      bottom: '-3px',

      backgroundColor: '$silverLight',
      height: convertPixelToRem(32),
      width: convertPixelToRem(32),
      borderRadius: '$circular',
      opacity: 0,
      transition: 'all 0.4s ease',
    },
  },

  '&:hover, &:focus': {
    outline: 0,

    '.slider': {
      '&:after': {
        opacity: 0.5,
      },
    },
  },
  variants: {
    disabled: {
      true: {
        input: {
          '&:checked + .slider:before': {
            backgroundColor: '#A9B6C1',
          },

          '&:checked + .slider': {
            backgroundColor: '#E4E8EC',
          },
        },
        '.slider': {
          backgroundColor: '#E4E8EC',

          '&:before': {
            backgroundColor: '#A9B6C1',
          },
        },
        '&:hover, &:focus': {
          '.slider': {
            '&:after': {
              opacity: 0,
            },
          },
        },
      },
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

interface SwitchProps {
  isToggled?: boolean;
  disabled?: boolean;
  onToggle?: () => void;
}

export function Switch({ isToggled, onToggle, disabled = false }: SwitchProps) {
  return (
    <SwitchContainer
      tabIndex={0}
      data-testid="product-switch"
      onKeyDown={event =>
        event.key === 'Enter' &&
        !disabled &&
        onToggle !== undefined &&
        onToggle()
      }
      disabled={disabled}
      onClick={e => disabled && e.preventDefault()}
    >
      <input type="checkbox" checked={isToggled} onChange={onToggle} />
      <span className="slider" />
    </SwitchContainer>
  );
}
