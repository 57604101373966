import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class ModalityNotFound extends ApplicationError {
  constructor() {
    super(
      SpecificsApiErrors.MODALITY_NOT_FOUND,
      ApiErrorsMessages.MODALITY_NOT_FOUND,
    );
  }
}
