import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

import { ProductImage } from './product-image';

const CardImageHandler = styled('div', {
  position: 'relative',
  width: 'fit-content',
  padding: '0.5rem 0',
  filter: 'drop-shadow(0px 0px 1px #00000060)',

  '.card-image-handler': {
    position: 'absolute',
    fontWeight: '$bold',
    color: '$white',
    textShadow: '0 0 4px #000000',

    '&--card-number': {
      bottom: convertPixelToRem(70),
      left: convertPixelToRem(20),
      fontSize: '1rem',
    },

    '&--collaborator-name': {
      bottom: convertPixelToRem(54),
      left: convertPixelToRem(20),
      fontSize: convertPixelToRem(11),
      textTransform: 'uppercase',
    },

    '&--company-fantasy-name': {
      bottom: convertPixelToRem(37),
      fontSize: convertPixelToRem(11),
      left: convertPixelToRem(20),
    },

    '&--expiration-date': {
      bottom: convertPixelToRem(20),
      fontSize: convertPixelToRem(11),
      left: convertPixelToRem(20),
    },
  },

  '@deviceSm': {
    margin: `0 ${convertPixelToRem(5)}`,
  },
});

interface CardImageProps {
  cardId: number;
  fantasyName: string;
}

function CardImage({ cardId, fantasyName }: CardImageProps) {
  return (
    <CardImageHandler>
      <ProductImage productId={cardId} />
      <div className="card-image-handler card-image-handler--card-number">
        0000 0000 0000 0000
      </div>
      <div className="card-image-handler card-image-handler--collaborator-name">
        Nome do colaborador
      </div>
      <div className="card-image-handler card-image-handler--company-fantasy-name">
        {fantasyName}
      </div>
      <div className="card-image-handler card-image-handler--expiration-date">
        00/00
      </div>
    </CardImageHandler>
  );
}

export default CardImage;
