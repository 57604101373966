import { useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { DeliverySection } from '@components/delivery-section';
import { PageTitle } from '@components/page-title';
import { CartPageFormSelector } from '@compositions/cart-page-form-selector';
import { CartSummaryActionsView } from '@compositions/cart-summary-actions-view';
import { PageContainer } from '@elements/page-container';
import { Title } from '@elements/title';

import { EXCLUDE_REASONS, PRODUCTS } from '@config/products-config';
import { calcTotalValueCartByModality } from '@helpers/cart-helpers';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import {
  formatListToString,
  getFirstName,
  getSingularOrPlural,
} from '@helpers/string-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useDynatraceTrackment } from '@hooks/use-dynatrace-trackment';
import { useSelector } from '@hooks/use-selector';
import { removeProductFromCart } from '@store/slices/cart-slice';
import { handleAddUnservedRegionProduct } from '@store/slices/customer-slice';
import { handleWarningModalContent } from '@store/slices/warning-modal-slice';

import { OfferFacade } from '@src/facade/offer-facade';

import { CartContentArea, CartPageHolder } from './styles';

export function CartPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { initialPage } = useSelector(({ application }) => application);
  const { selectedOffer } = OfferFacade();
  const cartData = useSelector(({ cart }) => cart);
  const { state } = useSelector(({ modal }) => modal);
  const { companyData, username } = useSelector(({ customer }) => customer);
  const { dynatraceTrackment } = useDynatraceTrackment();

  const verifyConditionsToUseThisPage = useMemo(
    () =>
      cartData.cartModality === undefined ||
      cartData.productCartList.length === 0 ||
      companyData.lead === '',
    [cartData.cartModality, cartData.productCartList, companyData.lead],
  );

  useEffect(() => {
    if (!verifyConditionsToUseThisPage) {
      const hasUnnatendedRegionProductsInCart =
        cartData.cartPricingRemovedProductsList.filter(
          ({ id }) =>
            id === EXCLUDE_REASONS.UNSERVED_REGION_PRODUCT_EXCLUDE_REASON,
        );

      if (hasUnnatendedRegionProductsInCart.length > 0) {
        hasUnnatendedRegionProductsInCart.forEach(product => {
          dispatch(handleAddUnservedRegionProduct(product.id));
        });

        hasUnnatendedRegionProductsInCart.forEach(product => {
          dispatch(removeProductFromCart({ productId: product.id }));
        });

        const modalPhrase = `${formatListToString(
          hasUnnatendedRegionProductsInCart.map(
            product => PRODUCTS[product.productId].name,
          ),
        )} ainda não ${getSingularOrPlural(
          hasUnnatendedRegionProductsInCart.length,
          'chegou',
          'chegaram',
        )} em sua região. Devido a indisponibilidade ${getSingularOrPlural(
          hasUnnatendedRegionProductsInCart.length,
          'ele',
          'eles',
        )} ${getSingularOrPlural(
          hasUnnatendedRegionProductsInCart.length,
          'foi',
          'foram',
        )} ${getSingularOrPlural(
          hasUnnatendedRegionProductsInCart.length,
          'retirado',
          'retirados',
        )} de seu carrinho.`;
        dispatch(
          handleWarningModalContent({
            isVisible: true,
            title: 'Indisponibilidade na Região',
            content: modalPhrase,
          }),
        );
      }
    }

    dynatraceTrackment(
      'cart_partial_value',
      formatValueToCurrency(
        calcTotalValueCartByModality({
          cartmodalityId: cartData.cartModality?.id,
          productCartList: cartData.productCartList,
        }),
      ),
    );

    dynatraceTrackment(
      'cart_products',
      JSON.stringify(
        cartData.productCartList.map(product => {
          return {
            [product.id]: {
              quantity: product.amount,
              value: product.value,
            },
          };
        }),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.cartPricingData]);

  if (!cartData.cartModality || verifyConditionsToUseThisPage) {
    if (selectedOffer) {
      return (
        <Navigate
          to={{
            pathname: '/oferta',
            search: location.search,
          }}
          state={{ from: location.pathname }}
        />
      );
    }
    return (
      <Navigate
        to={{
          pathname: initialPage,
          search: location.search,
        }}
        state={{ from: location.pathname }}
      />
    );
  }

  return (
    <>
      <PageTitle title="Carrinho" />
      <PageContainer horizontalHolder headerPadding={false}>
        <CartPageHolder>
          <CartContentArea>
            <Title.Root size="sm" barColor="secondary">
              <Title.Default tag="h2" size="sm">
                Pronto, {getFirstName(username)}! Veja a oferta criada
                exclusivamente para o seu pedido:
              </Title.Default>
            </Title.Root>
            <div className="cart-content-area__main-content">
              <CartPageFormSelector cartModality={cartData.cartModality} />
              {cartData.cartModality.id !== ModalitiesId.HCM && (
                <DeliverySection />
              )}
            </div>
          </CartContentArea>
          {!state && <CartSummaryActionsView />}
        </CartPageHolder>
      </PageContainer>
    </>
  );
}
