import { useEffect } from 'react';

export function useAnchorSmooth({
  watchVariable,
}: {
  watchVariable?: boolean;
} = {}) {
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', e => {
        e.preventDefault();

        const elementToScroll = anchor.getAttribute('href');

        if (elementToScroll) {
          document.querySelector(elementToScroll)?.scrollIntoView({
            behavior: 'smooth',
          });
        }
      });
    });
  }, [watchVariable]);
}
