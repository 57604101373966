import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { SummaryOfferValidation } from '@components/cart-summary-components/summary-offer-validation';
import { ProceedToCartButton } from '@components/proceed-to-cart-button';
import { Button } from '@elements/button';

import { calcTotalValueCartByModality } from '@helpers/cart-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { toggleCartModalState } from '@store/slices/cart-slice';

import { styled } from '@src/themes';

const CartContainerFooter = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1.25rem',

  background: '$silverPure',
  padding: '1rem 1rem 1.25rem',
  marginTop: 'auto',
  borderRadius: '0 0 10px 10px',

  '> a, button': {
    width: '100%',
  },

  '.footer__information': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',

    '.information__total-value': {
      color: '$primaryPure',
      fontWeight: '$semibold',
    },
  },
});

export function CartModalFooter() {
  const { cartModality, productCartList } = useSelector(({ cart }) => cart);
  const { isLoading } = useSelector(({ products }) => products);
  const { initialPage } = useSelector(({ application }) => application);
  const dispatch = useDispatch();
  const location = useLocation();

  const totalValueCart = useMemo(
    () =>
      calcTotalValueCartByModality({
        cartmodalityId: cartModality?.id,
        productCartList,
      }),
    [cartModality, productCartList],
  );

  const verifyIfFooterNeedToBeInvisible = useMemo(
    () =>
      isLoading || cartModality === undefined || productCartList.length === 0,
    [cartModality, productCartList.length, isLoading],
  );

  if (verifyIfFooterNeedToBeInvisible) {
    return null;
  }

  return (
    <CartContainerFooter>
      <div className="footer__information">
        <p>
          <strong>Total parcial:</strong>
        </p>
        <p className="information__total-value">
          {formatValueToCurrency(totalValueCart)}
        </p>
      </div>
      <SummaryOfferValidation />
      <ProceedToCartButton
        id="btn-modal-carrinho-seguir-compra"
        extraFunctionToTriggerOnClick={() => dispatch(toggleCartModalState())}
      />
      <Button.LinkRouter
        id="btn-modal-carrinho-adicionar-editar"
        data-testid="btn-modal-carrinho-adicionar-editar"
        outline
        to={{
          pathname:
            cartModality !== undefined ? cartModality.pageRoute : initialPage,
          search: location.search,
        }}
        state={{ from: location.pathname }}
        onClick={() => dispatch(toggleCartModalState())}
      >
        Adicionar ou editar produtos
      </Button.LinkRouter>
    </CartContainerFooter>
  );
}
