import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class BlockListError extends ApplicationError {
  constructor(message?: string) {
    super(
      SpecificsApiErrors.BLOCK_LIST_ERROR,
      message || ApiErrorsMessages.BLOCK_LIST_ERROR,
    );
  }
}
