import { OrderRevisionServiceTableAddonItem } from '@elements/order-revision-service-table-elements/order-revision-service-table-addon-item';

import { ProductAddonType } from '@extra-types/product-addon-type';

import { styled } from '@src/themes';

import { OrderRevisionExtensionListContainer } from './order-revision-extension-list';

interface OrderRevisionServiceTableBonusListProps {
  productAddonList: ProductAddonType[];
  isCollapsed: boolean;
}

export function OrderRevisionServiceTableBonusList({
  productAddonList,
  isCollapsed,
}: OrderRevisionServiceTableBonusListProps) {
  if (productAddonList.length === 0) {
    return null;
  }

  return (
    <OrderRevisionServiceTableBonusListContainer isCollapsed={isCollapsed}>
      <ul className="order-revision-single__awards">
        <li className="awards__title-line">
          <span>Bônus gratuitos</span>
        </li>
        {productAddonList.map(addon => (
          <OrderRevisionServiceTableAddonItem key={addon.id} data={addon} />
        ))}
      </ul>
    </OrderRevisionServiceTableBonusListContainer>
  );
}

const OrderRevisionServiceTableBonusListContainer = styled(
  OrderRevisionExtensionListContainer,
  {},
);
