import { LANDING_PAGE_TYPE } from '@config/application-config';

import { ApiRequestAuthData } from '@api/models/request/auth-data';
import LandingPageInformationsResponse from '@api/models/response/landing-page-information-response';

import getBaseURLApi from './authentication/get-base-url-api';

class LandingPageService {
  private readonly baseUrl;

  constructor() {
    this.baseUrl = 'landing-page';
  }

  public async getLandingPageInformations({
    siglaEmissor,
    siglaCanal,
    siglaSubCanal,
  }: ApiRequestAuthData): Promise<LandingPageInformationsResponse> {
    const url = `/${this.baseUrl}/${siglaCanal}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
        tipo_lp: LANDING_PAGE_TYPE,
      },
    });

    return data;
  }
}

export default new LandingPageService();
