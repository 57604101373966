import { styled } from '@src/themes';

export const SelectedWalletProductsContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '2rem 0 0 0',
  gap: '2rem',

  '@deviceMd': {
    flexDirection: 'column',
  },

  '.mobile': {
    display: 'none',

    '@deviceMd': {
      display: 'flex',
    },
  },

  '.desktop': {
    display: 'flex',

    '@deviceMd': {
      display: 'none',
    },
  },
});

export const ProductRow = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',

  '.product-name': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    '@deviceMd': {
      img: {
        display: 'none',
      },
    },
  },
});

export const InformationPhrase = styled('p', {
  fontWeight: '600',
});

export const SelectedProductHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
  width: '100%',
  textAlign: 'center',
  padding: '1rem',

  '.subtotal-title': {
    fontWeight: '600',
  },

  '.subtotal-value': {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: '$primaryPure',
  },

  '> div': {
    gap: '1rem',
  },

  '@deviceMd': {
    padding: '0',
  },
});
