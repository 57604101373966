/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ASSETS_PRODUCT_ADDONS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { AdditionalProductType } from '@extra-types/additional-product-type';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addExtraAdditionalProductToCart,
  removeAdditionalProductFromCart,
  removeExtensionFromCart,
} from '@store/slices/cart-slice';

import { styled } from '@src/themes';

type ExtensionType = {
  feature?: string;
  isIncluded: boolean;
  isRequired: boolean;
  handleExtensions?(id: number): {
    remove: () => void;
    add: () => void;
  };
};

function Modules({
  id,
  name,
  description,
}: Pick<AdditionalProductType, 'id' | 'name' | 'description'>) {
  return (
    <AdditionalProductHcmContainer isIncluded>
      <img
        src={`${env.URL_ASSETS + ASSETS_PRODUCT_ADDONS_PATH}/addon-${id}.webp`}
        alt={description}
        className="addon-image"
      />
      <div className="content">
        <h2>{name}</h2>
        <p>{description}</p>
      </div>
    </AdditionalProductHcmContainer>
  );
}

function Extension({
  id,
  name,
  description,
  feature,
  isIncluded,
  isRequired,
  handleExtensions,
}: Pick<AdditionalProductType, 'id' | 'name' | 'description'> & ExtensionType) {
  const dispatch = useDispatch();
  const { cartPricingData } = useSelector(({ cart }) => cart);

  const extensionValue =
    cartPricingData?.detalhe.produtos[0]?.produtosAdicionais?.find(
      prodAdicional => prodAdicional.codigo === id,
    )?.valor;

  return (
    <AdditionalProductHcmContainer isIncluded={isIncluded}>
      {!isIncluded && (
        <i
          className="fa-solid fa-plus add-button"
          onClick={() => {
            if (handleExtensions) {
              handleExtensions(id).add();
              dispatch(
                addExtraAdditionalProductToCart({
                  id,
                  value: extensionValue || 0,
                }),
              );
            }
          }}
        />
      )}
      <img
        src={`${env.URL_ASSETS + ASSETS_PRODUCT_ADDONS_PATH}/addon-${id}.webp`}
        alt={description}
        className="addon-image"
      />
      <div className="content">
        <h2>{name}</h2>
        <p>{description}</p>
      </div>

      <div className="value">
        {extensionValue
          ? `${formatValueToCurrency(extensionValue)}/mês`
          : 'gratuito'}
        {feature}
      </div>

      {isIncluded && !isRequired && (
        <i
          className="fa-regular fa-trash remove-button"
          onClick={() => {
            if (handleExtensions) {
              handleExtensions(id).remove();
              dispatch(removeExtensionFromCart({ productId: id }));
              dispatch(removeAdditionalProductFromCart(id));
            }
          }}
        />
      )}
    </AdditionalProductHcmContainer>
  );
}

const AdditionalProductHcmContainer = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gap: '1rem',
  gridTemplateColumns: 'auto 45px 1fr 115px auto',

  gridTemplateAreas: `'add-button addon-image content value remove-button'`,

  backgroundColor: '$white',
  borderBottom: '1px solid #E4E8ED',
  padding: '1rem 1.25rem',

  '.remove-button': {
    gridArea: 'remove-button',
    justifySelf: 'flex-end',
  },

  '.add-button': {
    gridArea: 'add-button',
  },

  '.addon-image': {
    gridArea: 'addon-image',
  },

  '.value': {
    gridArea: 'value',
    justifySelf: 'flex-end',
    fontSize: '0.75rem',
    fontWeight: '600',
    color: '$primaryPure',
  },

  '.content': {
    gridArea: 'content',
  },

  width: '100%',

  img: {
    maxWidth: '45px',
  },

  i: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '$primaryPure',
    minHeight: '1.5rem',
    minWidth: '1.5rem',
    borderRadius: '50%',
    color: '$white',

    cursor: 'pointer',
  },

  '@deviceSm': {
    gridTemplateColumns: 'auto 45px 1fr auto',
    gridTemplateAreas: `'add-button addon-image content remove-button'
    'add-button addon-image value remove-button'`,
    gap: '0.5rem 1rem',

    '.value': {
      justifySelf: 'flex-start',
    },
  },

  variants: {
    isIncluded: {
      false: {
        backgroundColor: '#E4E8ED',
        borderBottom: '1px solid $white',
      },
      true: {
        gridTemplateColumns: '45px 1fr 115px auto',
        gridTemplateAreas: `'addon-image content value remove-button'`,

        '@deviceSm': {
          gridTemplateColumns: ' 45px 1fr auto',
          gridTemplateAreas: `'addon-image content remove-button'
          'addon-image value remove-button'`,
        },
      },
    },
  },
});

export const AdditionalProductHcm = {
  Modules,
  Extension,
};
