import { StaticTaxType } from '@extra-types/static-tax-type';
import { ProductsId } from '@helpers/enum/products-id';
import { TaxEnum } from '@helpers/enum/tax-enum';

export const VARIABLE_TAX_VALUE = -1;
export const VARIABLE_TAX_LABEL = '- variável -';
export const FREE_LABEL = 'Grátis';

export const STATIC_PRODUCT_TAX: Record<number, StaticTaxType[]> = {
  [ProductsId.VALE_TRANSPORTE]: [
    {
      commercialConditionID: 100,
      name: 'Tarifa por local de atendimento',
      description: 'Tarifa por local de atendimento',
      type: TaxEnum.PERCENTAGE,
      value: 0,
    },
    {
      commercialConditionID: 101,
      name: 'Gestão de Vale-Transporte',
      description:
        'Tarifa de serviço da ferramenta de Gestão de Vale-Transporte',
      type: TaxEnum.PERCENTAGE,
      value: 0.04,
    },
    {
      commercialConditionID: 102,
      name: 'Taxa de Repasse',
      description:
        'Tarifa que pode ser emitida pelas operadoras de cartões de acordo com sua região',
      type: TaxEnum.VARIABLE,
      value: 0,
    },
    {
      commercialConditionID: 103,
      name: 'Gestão de Saldo',
      description: 'Tarifa por gestão de saldo automática',
      type: TaxEnum.PERCENTAGE,
      value: 0,
    },
  ],
};
