import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { Button } from '@elements/button';

import { redirectToAnotherPage } from '@helpers/page-helpers';
import {
  removeSpecificQueryParamFromLocation,
  removeSpecificQueryParamFromSearch,
} from '@helpers/query-string-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { clearCart } from '@store/slices/cart-slice';
import { closeWarningModal } from '@store/slices/warning-modal-slice';

import { ModalContainer, SymbolModalVariant } from './styles';

function WarningModal() {
  const nodeRef = useRef(null);
  const {
    content,
    title,
    isVisible,
    closeAction,
    variant,
    buttonAction,
    buttonOrder,
  } = useSelector(({ warningModal }) => warningModal);
  const { initialPage, config } = useSelector(({ application }) => application);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const ANIMATION_TIME = 400;

  const changeOverflowToInvisible = () => {
    document.body.style.overflow = 'hidden';
  };

  const changeOverflowToVisible = () => {
    document.body.style.overflow = 'auto';
  };

  const handleDisposeMessage = () => {
    if (closeAction?.action === 'reloadPage') {
      return window.location.reload();
    }

    if (closeAction?.action === 'reloadPageToDefaultChannel') {
      return redirectToAnotherPage(
        removeSpecificQueryParamFromLocation({
          key: 'canal',
          location: window.location,
        }),
      );
    }

    if (closeAction?.action === 'redirectToInitialPage') {
      dispatch(clearCart());
      dispatch(closeWarningModal());
      return navigate({
        pathname: initialPage,
        search: location.search,
      });
    }

    if (
      closeAction?.action ===
        'redirectToInitialPageAndRemoveSpecificQueryParamFromLocation' &&
      closeAction?.queryStringToRemove
    ) {
      dispatch(clearCart());
      dispatch(closeWarningModal());
      navigate({
        pathname: initialPage,
        search: removeSpecificQueryParamFromSearch({
          queryString: closeAction.queryStringToRemove,
          search: window.location.search,
        }),
      });
    }

    if (closeAction?.action === 'redirectToEmissorWebsite') {
      return redirectToAnotherPage(config.emissor.website);
    }

    return dispatch(closeWarningModal());
  };

  const getMessageContent = () => {
    if (variant === 'maintenance')
      return (
        <p>{`Estamos trabalhando em melhorias dentro da Loja ${config.emissor.shortName} para te oferecer a melhor experiência. Por favor, volte mais tarde.`}</p>
      );

    if (content) {
      return <p>{content}</p>;
    }

    return null;
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isVisible}
      timeout={ANIMATION_TIME}
      classNames="modal"
      unmountOnExit
      onEnter={changeOverflowToInvisible}
      onExit={changeOverflowToVisible}
    >
      <ModalContainer
        ref={nodeRef}
        css={{
          $$buttonOrder: buttonOrder === 'normal' ? 'column' : 'column-reverse',
        }}
      >
        <div className="modal-box__container">
          <SymbolModalVariant variant={variant} />
          <h2>{title}</h2>
          {getMessageContent()}
          <div className="modal-box__action-group">
            {buttonAction && (
              <Button.Default
                onClick={buttonAction.action}
                outline={buttonOrder === 'reverse'}
              >
                {buttonAction.label}
              </Button.Default>
            )}
            <Button.Default
              onClick={handleDisposeMessage}
              outline={!!buttonAction && buttonOrder === 'normal'}
            >
              {closeAction?.label}
            </Button.Default>
          </div>
        </div>
      </ModalContainer>
    </CSSTransition>
  );
}

export default WarningModal;
