import { CSS } from '@stitches/react';

import { Button } from '@elements/button';

import { DefaultTokenVariantsType } from '@extra-types/token-variants-type';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  changeCustomerType,
  proceedToPurchase,
} from '@store/slices/customer-slice';
import { resetOffer } from '@store/slices/offer-slice';

import { config } from '@src/themes';

export type ProceedToCheckoutButtonOfferToastConfigType = {
  positionElementRef?: React.MutableRefObject<HTMLDivElement | null>;
  zIndex?: number;
};

interface ProceedToCartButtonProps {
  id: string;
  label?: string;
  negative?: boolean;
  variant?: DefaultTokenVariantsType;
  extraFunctionToTriggerOnClick?: () => void;
  css?: CSS<typeof config>;
}

const DEFAULT_LABEL = 'Seguir para o carrinho';

export function ProceedToCartButton({
  id,
  label = DEFAULT_LABEL,
  variant = 'primary',
  negative = false,
  extraFunctionToTriggerOnClick,
  css,
}: ProceedToCartButtonProps) {
  const isProductLoading = useSelector(({ products }) => products.isLoading);
  const isApplicationLoading = useSelector(
    ({ application }) => application.isLoading,
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    if (extraFunctionToTriggerOnClick) {
      extraFunctionToTriggerOnClick();
    }
    dispatch(resetOffer());
    dispatch(changeCustomerType(undefined));
    dispatch(proceedToPurchase());
  };

  if (isProductLoading || isApplicationLoading) {
    return (
      <Button.Default
        id={id}
        data-testid={id}
        variant={variant}
        negative={negative}
        disabled={isProductLoading || isApplicationLoading}
        loading={isProductLoading || isApplicationLoading}
      >
        {label}
      </Button.Default>
    );
  }

  return (
    <Button.Default
      id={id}
      data-testid={id}
      variant={variant}
      negative={negative}
      onClick={handleClick}
      css={css}
    >
      {label}
    </Button.Default>
  );
}
