import { inputResponsiveMaxWidthStyle } from '@components/input/styles';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const WalletFormContainer = styled('form', {
  position: 'relative',
  background: '$silverPure',
  padding: '1.5rem',
  borderRadius: convertPixelToRem(10),
  boxShadow: '0px 0px 8px #00000022',

  '@deviceMd': {
    marginTop: '2rem',
  },

  '@deviceSm': {
    marginTop: '2.25rem',
    padding: '1rem 1rem 2rem',
  },

  '.header-contracted-wallets': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem 0 0 0',

    p: {
      fontSize: '1rem',
      fontWeight: '600',
    },

    '@deviceSm': {
      alignItems: 'center',
    },
  },

  '.products-content': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1rem',
    fontWeight: '500',
    backgroundColor: '$white',
    borderRadius: '8px',
    padding: '1rem',
  },

  '.products-row': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bolder',
  },

  '.edit-button': {
    background: 'transparent',
    width: 'min-content',

    color: '$primaryPure',
    fontWeight: '600',
    textAlign: 'center',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  '.is-on-cart-content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.75rem',

    i: {
      color: '$primaryPure',
      fontSize: '2.5rem',
    },
  },

  '.is-on-cart-flag': {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    top: '0',
    right: '0',
    width: '5rem',
    height: '5rem',
    padding: '10px',
    backgroundColor: '$primaryPure',
    color: '$white',
    fontSize: '1.5rem',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%)',
    borderTopRightRadius: '10px',
  },
});

export const HeaderHolder = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',

  paddingBottom: '1rem',
  borderBottom: '1px solid $silverMedium',

  '.header-holder__action-holder': {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',

    color: '$fontColorPrimary',
    fontWeight: '$semibold',

    p: {
      fontSize: convertPixelToRem(14),
    },
  },

  '@deviceMd': {
    flexDirection: 'column',

    '.header-holder__action-holder': {
      justifyContent: 'space-between',
    },
  },

  '@deviceSm': {
    '.header-holder__action-holder': {
      flexDirection: 'column',
      alignItems: 'flex-start',

      gap: '0.5rem',
      fontSize: convertPixelToRem(12),

      '> div': inputResponsiveMaxWidthStyle,
    },
  },
});

export const WarningPhrase = styled('p', {
  margin: '1rem 0 1.5rem',

  color: '$fontColorPrimary',
  fontSize: convertPixelToRem(14),
  lineHeight: '1.5',

  '@deviceMd': {
    fontSize: '1rem',
    textAlign: 'center',
    fontWeight: '600',
  },
});

export const ProductsHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

export const ActionsHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',

  '.actions-holder__total-value-box': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    background: '$white',
    width: '100%',
    padding: `${convertPixelToRem(18)} 1rem`,
    marginTop: '1.5rem',
    borderRadius: convertPixelToRem(10),

    p: {
      color: '$fontColorPrimary',
      fontWeight: '$bold',
    },

    'p > span': {
      color: '$primaryPure',
    },
  },
});
