import { ProductCartType } from '@extra-types/product-cart-type';
import { ProductContractedType } from '@extra-types/product-contracted-type';

export function validateIfCustomerSelectedOnlyContractedProducts(
  contractedProductListFilteredByModality: ProductContractedType[],
  selectedProductCartList: ProductCartType[],
) {
  return selectedProductCartList.every(selectedProduct =>
    contractedProductListFilteredByModality.some(contractedProduct => {
      return contractedProduct.id === selectedProduct.id;
    }),
  );
}
