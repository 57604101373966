import { ApiRequestAuthData } from '@api/models/request/auth-data';
import EcosystemProductListResponse from '@api/models/response/ecosystem-product-response';

import getBaseURLApi from './authentication/get-base-url-api';

class EcosystemProductService {
  private readonly baseUrl;

  constructor() {
    this.baseUrl = 'produtos-ecossistema';
  }

  public async getEcosystemProducts({
    siglaEmissor,
    siglaCanal,
    siglaSubCanal,
  }: ApiRequestAuthData): Promise<EcosystemProductListResponse> {
    const url = `/${this.baseUrl}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
    });

    return data;
  }
}

export default new EcosystemProductService();
