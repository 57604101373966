import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class ContractDownloadError extends ApplicationError {
  constructor() {
    super(
      SpecificsApiErrors.CONTRACT_DOWNLOAD_ERROR,
      ApiErrorsMessages.CONTRACT_DOWNLOAD_ERROR,
    );
  }
}
