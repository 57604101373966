import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const RegisterSectionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  position: 'relative',
  background: '$white',
  height: 'auto',
  width: '100%',
  padding: convertPixelToRem(20),
  borderRadius: convertPixelToRem(8),
  boxShadow: '0px 0px 8px #00000022',

  '.section__title': {
    display: 'flex',
    alignItems: 'center',

    height: 'auto',
    width: '100%',

    color: '$primaryPure',
    fontSize: '1rem',
    fontWeight: '$semibold',

    '.title__number': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,

      backgroundColor: '$primaryPure',
      height: convertPixelToRem(26),
      width: convertPixelToRem(26),
      marginRight: convertPixelToRem(10),

      color: '$white',
      fontSize: '1rem',
      fontWeight: '$bold',
      borderRadius: '50%',
    },
  },
});

export const RegisterSectionContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  width: '100%',
  padding: '1rem 0',

  '@deviceSm': {
    padding: `${convertPixelToRem(20)} 0 0`,
  },
});
