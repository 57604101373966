import { useState } from 'react';
import { Controller, FieldArrayWithId, useFormContext } from 'react-hook-form';

import cnpjValidate from '@vr/devkit/validators/cnpjValidate';
import cpfValidate from '@vr/devkit/validators/cpfValidate';

import Dropdown from '@components/dropdown';
import { Input } from '@components/input';
import { ExtraContactInputs } from '@compositions/extra-contact-inputs';
import { Button } from '@elements/button';
import Checkbox from '@elements/checkbox';
import Option from '@elements/option';

import { MAX_EXTRA_CONTACTS } from '@config/application-config';
import { NAME_MAX_LENGTH, NAME_MIN_LENGTH } from '@config/input-config';
import { CompanyType } from '@extra-types/company-type';
import { Contact } from '@helpers/enum/contact';
import { FormErrors } from '@helpers/enum/form-errors';
import { validSameValue } from '@helpers/function-helpers';

import { ContactInputsContainer } from './styles';

interface FormTextData {
  nome: {
    text: string;
    placeholder: string;
  };
  document: {
    text: string;
    placeholder: string;
    mask: string;
  };
  politicallyExposed: boolean;
}

interface ContactInputsProps {
  fields: Partial<FieldArrayWithId<CompanyType, 'contatoExtra', 'id'>>[];
  addContact: () => void;
  remove: (index?: number | number[] | undefined) => void;
  getLastContactType: () => string;
  isLoading?: boolean;
}

export function ContactInputs({
  fields,
  addContact,
  remove,
  getLastContactType,
  isLoading = false,
}: ContactInputsProps) {
  const {
    control,
    register,
    getValues,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<CompanyType>();
  const [contactType, setContactType] = useState<Contact>(
    getValues().representanteLegal.tipo || Contact.RL,
  );
  const [politicallyExposed, setPoliticallyExposed] = useState(false);

  const [legalPerson, setLegalPerson] = useState(
    getValues().representanteLegal.juridica || false,
  );
  function handleSetContactType(type: Contact) {
    setValue(`representanteLegal.politicamenteExposta`, false);
    setPoliticallyExposed(false);
    setContactType(type);
  }

  function formHandler(
    whichContactType: Contact,
    isLegalPerson: boolean,
  ): FormTextData {
    const formText: { [key: string]: FormTextData } = {
      representanteLegal: {
        nome: {
          text: 'Nome do Representante*',
          placeholder: 'Nome do representante',
        },
        document: {
          text: 'CPF*',
          placeholder: '000.000.000-00',
          mask: '999.999.999-99',
        },
        politicallyExposed: true,
      },
      pessoaFisica: {
        nome: {
          text: 'Nome do Sócio*',
          placeholder: 'Nome do sócio',
        },
        document: {
          text: 'CPF*',
          placeholder: '000.000.000-00',
          mask: '999.999.999-99',
        },
        politicallyExposed: true,
      },
      pessoaJuridica: {
        nome: {
          text: 'Razão Social*',
          placeholder: 'Razão social',
        },
        document: {
          text: 'CNPJ*',
          placeholder: '00.000.000/0000-00',
          mask: '99.999.999/9999-99',
        },
        politicallyExposed: false,
      },
    };

    if (whichContactType === Contact.RL) {
      return formText.representanteLegal;
    }

    return formText[
      whichContactType === Contact.CS && isLegalPerson
        ? 'pessoaJuridica'
        : 'pessoaFisica'
    ];
  }

  return (
    <ContactInputsContainer>
      {contactType === Contact.CS && (
        <div className="contact__wrapper--checkbox">
          <Controller
            control={control}
            name="representanteLegal.juridica"
            render={({ field: { onChange } }) => (
              <Checkbox
                id="de_contato-extra_pessoa-fisica"
                isChecked={!legalPerson}
                disabled={isLoading}
                onCheck={() => {
                  onChange(false);
                  setLegalPerson(false);
                  setPoliticallyExposed(false);
                  setValue(`representanteLegal.documento`, '');
                }}
              >
                Pessoa Física
              </Checkbox>
            )}
          />
          <Controller
            control={control}
            name="representanteLegal.juridica"
            render={({ field: { onChange } }) => (
              <Checkbox
                id="de_contato-extra_pessoa-juridica"
                isChecked={legalPerson}
                disabled={isLoading}
                onCheck={() => {
                  onChange(true);
                  setLegalPerson(true);
                  setPoliticallyExposed(false);
                  setValue(`representanteLegal.documento`, '');
                }}
              >
                Pessoa Jurídica
              </Checkbox>
            )}
          />
        </div>
      )}
      <div className="contact__wrapper contact__wrapper--input">
        <Controller
          control={control}
          name="representanteLegal.tipo"
          rules={{ required: FormErrors.REQUIRED_DEFAULT }}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              id="de_contato-extra_tipo"
              value={value}
              label="Tipo contato*"
              disabled={isLoading}
              onChange={e => {
                if (Contact.RL === e.target.value) {
                  setLegalPerson(false);
                }
                onChange(e.target.value);
                handleSetContactType(e.target.value as Contact);
                resetField(`representanteLegal.documento`);
              }}
              errorMessage={
                errors.representanteLegal &&
                errors.representanteLegal?.tipo?.message
              }
              css={{ width: '33%' }}
            >
              <Option value={Contact.RL}>Representante Legal</Option>
              <Option value={Contact.CS}>Cadeia Societária</Option>
            </Dropdown>
          )}
        />
        <Input.Default
          id="de_contato-extra_nome"
          label={formHandler(contactType, legalPerson).nome.text}
          type="text"
          placeholder={formHandler(contactType, legalPerson).nome.placeholder}
          width="100%"
          disabled={isLoading}
          errorMessage={
            errors.representanteLegal &&
            errors.representanteLegal?.nome?.message
          }
          css={{ width: '33%' }}
          {...register(`representanteLegal.nome`, {
            minLength: {
              value: NAME_MIN_LENGTH,
              message: FormErrors.INVALID_NAME_MINLENGTH,
            },
            maxLength: {
              value: NAME_MAX_LENGTH,
              message: FormErrors.INVALID_NAME_MAXLENGTH,
            },
            required: FormErrors.REQUIRED_NAME,
          })}
        />

        <Input.Default
          id="de_contato-extra_documento"
          label={formHandler(contactType, legalPerson).document.text}
          type="text"
          placeholder={
            formHandler(contactType, legalPerson).document.placeholder
          }
          width="100%"
          disabled={isLoading}
          customMask={formHandler(contactType, legalPerson).document.mask}
          errorMessage={
            errors.representanteLegal &&
            errors.representanteLegal?.documento?.message
          }
          css={{ width: '33%' }}
          inputMode="numeric"
          {...register(`representanteLegal.documento`, {
            required:
              contactType === Contact.CS && legalPerson
                ? FormErrors.REQUIRED_CNPJ
                : FormErrors.REQUIRED_CPF,
            validate: {
              value: value => {
                if (contactType === Contact.CS && legalPerson) {
                  return cnpjValidate(value) || FormErrors.INVALID_CNPJ;
                }
                return cpfValidate(value) || FormErrors.INVALID_CPF;
              },
              sameValue: value =>
                !validSameValue(value, [
                  getValues().representanteLegal?.documento,
                  ...getValues().contatoExtra.map(contato => contato.documento),
                ]) ||
                (contactType === Contact.CS && legalPerson
                  ? FormErrors.INVALID_UNIQUE_CNPJ
                  : FormErrors.INVALID_UNIQUE_CPF),
            },
          })}
        />
      </div>
      {formHandler(contactType, legalPerson).politicallyExposed && (
        <div className="contact__wrapper contact__wrapper--input contact__wrapper--justify-start contact__wrapper--remove-margin-bottom">
          <Controller
            control={control}
            name="representanteLegal.politicamenteExposta"
            defaultValue={false}
            render={({ field: { onChange } }) => (
              <Checkbox
                id="de_contato-extra_politicamente-exposta"
                isChecked={politicallyExposed}
                disabled={isLoading}
                onCheck={() => {
                  setPoliticallyExposed(!politicallyExposed);
                  onChange(!politicallyExposed);
                }}
              >
                Pessoa politicamente exposta
              </Checkbox>
            )}
          />
        </div>
      )}
      <ul className="contact__list-contacts">
        {fields.map((contact, index) => (
          <ExtraContactInputs
            key={contact.id}
            item={contact}
            index={index}
            remove={remove}
            isLoading={isLoading}
          />
        ))}
        {fields.length < MAX_EXTRA_CONTACTS && (
          <div className="contact__wrapper--add-button">
            <Button.Default
              id="btn-empresa-adicionar-contato"
              variant="primary"
              size="small"
              outline
              onClick={addContact}
              disabled={isLoading}
            >
              <i className="fas fa-user-plus" />
              Incluir {getLastContactType()} Adicional
            </Button.Default>
          </div>
        )}
      </ul>
    </ContactInputsContainer>
  );
}
