import { ServiceInformationType } from '@extra-types/service-information-type';
import { ProductsId } from '@helpers/enum/products-id';

export const SVA_PRODUCTS_ID = [
  ProductsId.APOIO_TRABALHADOR,
  ProductsId.DESCONTO_FARMACIA,
  ProductsId.TOTALPASS,
  ProductsId.SEGURO_ALIMENTACAO,
  ProductsId.NUTRICAO,
];

export const SERVICE_STATIC_INFORMATION: {
  [key: number]: ServiceInformationType;
} = {
  [ProductsId.NUTRICAO]: {
    description:
      'É um serviço que apoia o seu trabalhador em uma rotina alimentar mais saudável. O Nutrição conta com nutricionistas reais que vão avaliar sua alimentação via chat e recomendar a melhor dieta, além de outras funcionalidades. ',
    benefits: [
      {
        id: 'nutricao-nutricionistas',
        icon: 'fa-regular fa-user-doctor-message',
        text: (
          <>
            <strong>Nutricionistas</strong>: chat com profissionais para tirar
            dúvidas sobre a alimentação.
          </>
        ),
      },
      {
        id: 'nutricao-escaner',
        icon: 'fa-regular fa-barcode-scan',
        text: (
          <>
            <strong>Escâner de produtos por código de barras</strong>: indica a
            avaliação nutricional do alimento consultado
          </>
        ),
      },
      {
        id: 'nutricao-dieta',
        icon: 'fa-regular fa-apple-whole',
        text: (
          <>
            <strong>Sugestões de dieta</strong>: cardápio alimentar de acordo
            com os objetivos definidos no app.
          </>
        ),
      },
      {
        id: 'nutricao-refeicoes',
        icon: 'fa-regular fa-pot-food',
        text: (
          <>
            <strong>Registro de refeições</strong>: com avaliações nutricionais
            personalizadas e nutrientes presentes no prato.
          </>
        ),
      },
      {
        id: 'nutricao-bioimpedancia',
        icon: 'fa-regular fa-percent',
        text: (
          <>
            <strong>Bioimpedância</strong>: mediante a contratação do RH.
          </>
        ),
      },
      {
        id: 'nutricao-pat',
        icon: 'fa-regular fa-gavel',
        text: (
          <>
            <strong>
              Aderente ao PAT - Programa de alimentação do trabalhador.
            </strong>
            <br />
            (Obrigatoriedade em fornecer um serviço de saúde e segurança
            alimentar)
          </>
        ),
      },
    ],
  },
  [ProductsId.DESCONTO_FARMACIA]: {
    description:
      'É um servido em parceria com a Vidalink, que oferece descontos em medicamentos e produtos genéricos nas farmácias populares.',
    benefits: [
      {
        id: 'farmacia-economia',
        icon: 'fa-regular fa-pills',
        text: (
          <>
            <strong>Economia</strong>: compra de medicamentos com{' '}
            <strong>valor mínimo de 20% de desconto</strong> sem limite de
            utilização.
          </>
        ),
      },
      {
        id: 'farmacia-rede',
        icon: 'fa-regular fa-location-dot',
        text: (
          <>
            <strong>Rede: mais de 18 mil farmácias credenciadas</strong>,
            incluindo grandes redes: Drogaria Brasil, Pacheco e São Paulo.
          </>
        ),
      },
      {
        id: 'farmacia-digital',
        icon: 'fa-regular fa-mobile',
        text: (
          <>
            <strong>100% digital</strong>: cartão virtual e consulta de
            farmácias no aplicativo.
          </>
        ),
      },
      {
        id: 'farmacia-pat',
        icon: 'fa-regular fa-gavel',
        text: (
          <>
            <strong>
              Aderente ao PAT - Programa de alimentação do trabalhador.
            </strong>
            <br />
            (Obrigatoriedade em fornecer um serviço de saúde e segurança
            alimentar)
          </>
        ),
      },
    ],
  },
  [ProductsId.APOIO_TRABALHADOR]: {
    description:
      'É um serviço da VR que oferece apoio com pronto atendimento, aconselhamento e encaminhamento psicológico gratuito para o trabalhador e seus familiares, com qualquer grau de parentesco.',
    benefits: [
      {
        id: 'apoio-trabalhador-atendimento',
        icon: 'fa-regular fa-user',
        text: (
          <>
            <strong>Atendimento 24h: orientação psicológica</strong> para
            trabalhadores e familiares.
          </>
        ),
      },
      {
        id: 'apoio-trabalhador-ligacao',
        icon: 'fa-regular fa-circle-info',
        text: (
          <>
            <strong>
              Ligação gratuita e sigilosa: em qualquer lugar do país, por meio
              de telefone fixo ou celular.
            </strong>{' '}
            A identificação é feito pelo CPF do titular do serviço.
          </>
        ),
      },
      {
        id: 'apoio-trabalhador-motivacao',
        icon: 'fa-regular fa-heart',
        text: (
          <>
            <strong>Motivação</strong>: mais{' '}
            <strong>bem-estar e produtividade</strong> para seus trabalhadores,
            auxiliando no clima organizacional.
          </>
        ),
      },
      {
        id: 'apoio-trabalhador-pat',
        icon: 'fa-regular fa-gavel',
        text: (
          <>
            <strong>
              Aderente ao PAT - Programa de alimentação do trabalhador.
            </strong>
            <br />
            (Obrigatoriedade em fornecer um serviço de saúde e segurança
            alimentar)
          </>
        ),
      },
    ],
  },
  [ProductsId.SEGURO_ALIMENTACAO]: {
    description:
      'É um seguro exclusivo para o trabalhador que garante a alimentação básica da família em caso de desemprego involuntário, morte acidental ou invalidez total por acidente.',
    benefits: [
      {
        id: 'seguro-alimentacao-atendimento',
        icon: 'fa-regular fa-sun-bright',
        text: (
          <>
            <strong>Atendimento</strong>: 24h por dia, 7 dias por semana.
          </>
        ),
      },
      {
        id: 'seguro-alimentacao-credito',
        icon: 'fa-regular fa-credit-card',
        text: (
          <>
            <strong>Crédito de R$ 400,00</strong>: seguro pago diretamente no{' '}
            <strong>Refeição, Alimentação ou Multi</strong> do trabalhador que
            possua as carteiras de alimentação ou refeição.
          </>
        ),
      },
      {
        id: 'seguro-alimentacao-sem-custo',
        icon: 'fa-regular fa-heart',
        text: (
          <>
            <strong>Sem custo para sua equipe:</strong> não é feita nenhuma
            cobrança para o trabalhador.
          </>
        ),
      },
      {
        id: 'seguro-alimentacao-pat',
        icon: 'fa-regular fa-gavel',
        text: (
          <>
            <strong>
              Aderente ao PAT - Programa de alimentação do trabalhador.
            </strong>
            <br />
            (Obrigatoriedade em fornecer um serviço de saúde e segurança
            alimentar)
          </>
        ),
      },
    ],
  },
  [ProductsId.TOTALPASS]: {
    description:
      'Benefício corporativo de qualidade de vida, que oferece ampla rede credenciada no país, com mais de 5.000 academias disponíveis, incluindo toda rede Smart Fit e Bio Ritmo no Brasil. Agrega também inúmeras unidades oferecendo bem-estar e saúde para todos os gostos!',
    benefits: [
      {
        id: 'totalpass-inclusao-dependentes',
        icon: 'fa-regular fa-user-plus',
        text: (
          <>
            <strong>Inclusão de dependentes</strong>: possibilidade do
            trabalhador <strong>incluir até 3 familiares</strong> com qualquer
            grau de parentesco ou conhecidos.
          </>
        ),
      },
      {
        id: 'totalpass-ampla-rede-credenciada',
        icon: 'fa-regular fa-dumbbell',
        text: (
          <>
            <strong>
              Ampla rede credenciada no país: mais de 8 mil academias
            </strong>
            , incluindo Smart Fit e Bioritmo, entre outros parceiros.
          </>
        ),
      },
      {
        id: 'totalpass-motivacao-equipe',
        icon: 'fa-regular fa-heart',
        text: (
          <>
            <strong>Motivação para a equipe</strong>: melhora da saúde mental,
            sistema imunológico, humor e autoestima.
          </>
        ),
      },
      {
        id: 'totalpass-aulas-modalidades',
        icon: 'fa-regular fa-thumbs-up',
        text: (
          <>
            <strong>Aulas e modalidades</strong>: natação, pilates, lutas,
            dança, yoga, entre outros. São diversas opções para o trabalhador
            aproveitar o benefício como preferir.
          </>
        ),
      },
      {
        id: 'totalpass-saude-mental',
        icon: 'fa-regular fa-sun-bright',
        text: (
          <>
            <strong>Saúde mental</strong>: até 4 sessões no Psicologia Viva e
            acesso ao Zen App, disponível de acordo com o plano contratado.
          </>
        ),
      },
      {
        id: 'totalpass-pat',
        icon: 'fa-regular fa-gavel',
        text: (
          <>
            <strong>
              Aderente ao PAT - Programa de alimentação do trabalhador.
            </strong>
            <br />
            (Obrigatoriedade em fornecer um serviço de saúde e segurança
            alimentar)
          </>
        ),
      },
    ],
  },
};

export const svaRestrictions: Record<number, ProductsId[]> = {
  [ProductsId.SEGURO_ALIMENTACAO]: [
    ProductsId.MULTI_ALIMENTACAO_PAT,
    ProductsId.MULTI_REFEICAO_PAT,
    ProductsId.MULTI_ALIMENTACAO_AUXILIO,
    ProductsId.MULTI_REFEICAO_AUXILIO,
    ProductsId.MULTI_VRVA,
  ],
};
