import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '@hooks/use-selector';

import {
  OfferDetailResponse,
  OfferResponse,
} from '@api/models/response/offer-response';
import offerService from '@api/services/offer-service';
import ApplicationError from '@errors/types/application-error';
import NotCatalogedError from '@errors/types/not-cataloged-error';

export const fetchActiveOffers = createAsyncThunk<
  OfferResponse,
  void,
  { state: RootState }
>('offers/fetchActiveOffers', async (_, thunkAPI) => {
  const { config } = thunkAPI.getState().application;

  try {
    return await offerService.getActiveOffers({
      siglaCanal: config.siglaCanal,
      siglaEmissor: config.emissor.initials,
      siglaSubCanal: config.siglaSubCanal,
    });
  } catch (error) {
    if (error instanceof ApplicationError)
      return thunkAPI.rejectWithValue(error);
    return thunkAPI.rejectWithValue(new NotCatalogedError());
  }
});

export const fetchOfferDetails = createAsyncThunk<
  OfferDetailResponse,
  void,
  { state: RootState }
>('offers/fetchOfferDetails', async (_, thunkAPI) => {
  const { config } = thunkAPI.getState().application;
  const { selectedOffer } = thunkAPI.getState().offers;

  try {
    return await offerService.getOfferDetails(
      {
        siglaCanal: config.siglaCanal,
        siglaEmissor: config.emissor.initials,
        siglaSubCanal: config.siglaSubCanal,
      },
      selectedOffer?.codigoOferta ?? '',
    );
  } catch (error) {
    if (error instanceof ApplicationError)
      return thunkAPI.rejectWithValue(error);
    return thunkAPI.rejectWithValue(new NotCatalogedError());
  }
});
