import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ProductImage } from '@elements/product-image';

import { completeWithLeftZero } from '@helpers/string-helpers';

import { useSelector } from '@hooks/use-selector';

import { CartItemContainer } from './cart-item-container';

interface CartIndividualItemProps {
  id: number;
  amount: number;
  value: number;
  showImage?: boolean;
}

export function CartModalIndividualItem({
  id,
  amount,
  value,
  showImage,
}: CartIndividualItemProps) {
  const productsData = useSelector(({ products }) => products.productList);
  const productData = productsData.find(product => product.id === id);

  if (!productData) {
    return null;
  }

  return (
    <CartItemContainer>
      <div className="cart-individual-item-container__product-title">
        <div className="product-title__information">
          {showImage && <ProductImage productId={id} />}
          <p>{productData.name}</p>
        </div>
      </div>
      <div className="cart-individual-item-container__product-information">
        <div className="product-information__value">
          <span>Valor por cartão</span>
          <span>{formatValueToCurrency(value)}</span>
        </div>
        <div className="product-information__amount">
          <span>Quantidade cartões</span>
          <span>{completeWithLeftZero(amount)}</span>
        </div>
      </div>
    </CartItemContainer>
  );
}
