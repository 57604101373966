/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, nanoid } from '@reduxjs/toolkit';

import { LANDING_PAGE_SECTION_PREFIX } from '@config/application-config';
import { SectionType } from '@extra-types/section-types';

import { fetchLandingPageData } from '@store/thunks/landing-page-thunk';

interface LandingPageState {
  isLoading: boolean;
  sections: SectionType[];
}

export const initialState = {
  isLoading: true,
  sections: [],
} as LandingPageState;

const landingPageSlice = createSlice({
  name: 'landing-page',
  initialState,
  reducers: {
    setLandingPageData: (state, action: PayloadAction<LandingPageState>) => {
      state.sections = action.payload.sections;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLandingPageData.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchLandingPageData.fulfilled, (state, action) => {
        const sectionFilteredByPrefix = action.payload.secoes
          .slice()
          .filter(section => section.id.includes(LANDING_PAGE_SECTION_PREFIX))
          .map(section => {
            const itemListWithId = section.itens.map(item => {
              return {
                ...item,
                id: nanoid(),
              };
            });
            return {
              ...section,
              itens: itemListWithId,
            };
          });

        state.sections = state.sections.concat(sectionFilteredByPrefix);

        state.isLoading = false;
      });
  },
});

export const { setLandingPageData } = landingPageSlice.actions;

export default landingPageSlice.reducer;
