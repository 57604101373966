/* eslint-disable no-param-reassign */
import clearNotNumber from '@vr/devkit/utils/clearNotNumber';

import { AddressType } from '@extra-types/address-type';
import { CompanyChainType } from '@extra-types/company-chain-type';
import { CompanyType } from '@extra-types/company-type';
import { LegalPersonType } from '@extra-types/legal-person-type';
import { ContactType } from '@extra-types/register-data-type';
import { Contact } from '@helpers/enum/contact';
import { toISOFormat } from '@helpers/function-helpers';

export default class ContractRequest {
  private nomeFantasia: string;

  private contato: ContactType;

  private enderecoLocalEntrega: AddressType;

  private representanteLegal: LegalPersonType[];

  private cadeiaSocietaria: CompanyChainType[];

  constructor(company: CompanyType) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tipo, ...repLegalRest } = company.representanteLegal;
    this.cadeiaSocietaria = [];
    this.representanteLegal = [];

    if (tipo === Contact.RL) {
      this.representanteLegal.push({
        nome: repLegalRest.nome,
        documento: clearNotNumber(repLegalRest.documento),
        politicamenteExposta: repLegalRest.politicamenteExposta,
      });
    } else {
      this.cadeiaSocietaria.push({
        nome: repLegalRest.nome,
        documento: clearNotNumber(repLegalRest.documento),
        politicamenteExposta: repLegalRest.politicamenteExposta,
        juridica: repLegalRest.juridica,
      });
    }

    this.nomeFantasia = company.nomeFantasia;
    this.contato = {
      nomeContato: company.dadosCadastrais.nomeCadastro,
      emailContato: company.dadosCadastrais.emailCadastro,
      telefoneContato: clearNotNumber(company.dadosCadastrais.telefoneCadastro),
      cpfContato: clearNotNumber(company.dadosCadastrais.cpfContato),
      dataNascimento: toISOFormat(company.dadosCadastrais.dataNascimento),
    };
    this.enderecoLocalEntrega = company.enderecoLocalEntrega;

    company.contatoExtra.forEach(contato => {
      if (contato.tipo === Contact.RL) {
        this.representanteLegal.push({
          nome: contato.nome,
          documento: clearNotNumber(contato.documento),
          politicamenteExposta: contato.politicamenteExposta,
        });
      } else if (contato.tipo === Contact.CS) {
        this.cadeiaSocietaria.push({
          nome: contato.nome,
          documento: clearNotNumber(contato.documento),
          politicamenteExposta: contato.politicamenteExposta,
          juridica: contato.juridica,
        });
      }
    });
  }
}
