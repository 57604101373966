import { ModalityType } from '@extra-types/modality-type';
import { ModalitiesId } from '@helpers/enum/modalities-id';

import { CartPageHCM } from './cart-page-hcm';
import { CartPageIndividualProductListForm } from './cart-page-individual-product-list-form';
import { CartPageWalletProductListForm } from './cart-page-wallet-product-list-form';

export function CartPageFormSelector({
  cartModality,
}: {
  cartModality: ModalityType;
}) {
  if (cartModality.id === ModalitiesId.HCM) {
    return <CartPageHCM />;
  }

  if (cartModality.isWalletGroupCard) {
    return <CartPageWalletProductListForm />;
  }

  return <CartPageIndividualProductListForm />;
}
