import { useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';

import { PageTitle } from '@components/page-title';
import AddressInputs from '@compositions/address-inputs';
import { CompanyNameInput } from '@compositions/company-name-input';
import { ContactInputs } from '@compositions/contact-inputs';
import { RegisterInputs } from '@compositions/register-inputs';
import { Button } from '@elements/button';
import { RegisterSection } from '@elements/register-section';

import { MODALITIES } from '@config/modality-config';
import { CompanyType } from '@extra-types/company-type';
import { ApiErrorsModalTitle } from '@helpers/enum/api-error-messages';
import { Contact } from '@helpers/enum/contact';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { getMessageError } from '@helpers/error-helpers';
import { isComboFlux } from '@helpers/flux-helpers';
import { getAddressType } from '@helpers/input-helpers';
import { createUpdateLeadRequest } from '@helpers/lead-helpers';
import { formatListToString } from '@helpers/string-helpers';
import {
  convertPixelToRem,
  holderStyleWithMobileResponsive,
} from '@helpers/style-helpers';

import useDefaultHeader from '@hooks/use-default-header';
import { useDispatch } from '@hooks/use-dispatch';
import { useRoutes } from '@hooks/use-routes';
import { useSelector } from '@hooks/use-selector';
import { handleUpdateCompanyData } from '@store/slices/customer-slice';
import { handleWarningModalContent } from '@store/slices/warning-modal-slice';

import leadsService from '@api/services/leads-service';
import ApplicationError from '@errors/types/application-error';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

const modalitiesDeliveryLocationAllowedList = [
  ModalitiesId.DEFAULT,
  ModalitiesId.MULTI,
  ModalitiesId.MOBILIDADE,
];

export function CompanyDataPage() {
  const { selectedOffer } = OfferFacade();
  const [isUpdatingLead, setIsUpdatingLead] = useState(false);
  const location = useLocation();
  const { defaultHeader } = useDefaultHeader();
  const { companyData, representativeData, registerData } = useSelector(
    ({ customer }) => customer,
  );
  const { config, initialPage } = useSelector(({ application }) => application);
  const { productComboCartList, productCartList } = useSelector(
    ({ cart }) => cart,
  );
  const { nextStep, prevStep } = useRoutes();
  const dispatch = useDispatch();

  const modalityLabelForDeliveryLocationTitle = formatListToString(
    Object.entries(productComboCartList)
      .filter(
        productComboList =>
          productComboList[1].length > 0 &&
          modalitiesDeliveryLocationAllowedList.some(
            modalityId => modalityId === Number(productComboList[0]),
          ),
      )
      .map(
        productComboList =>
          MODALITIES[Number(productComboList[0])].shelfInformation.title,
      ),
  );

  const methods = useForm<CompanyType>({
    mode: 'onChange',
    defaultValues: {
      nomeFantasia:
        companyData.nomeFantasia.length <= config.maxBeneficiaryNameLength
          ? companyData.nomeFantasia
          : companyData.nomeFantasia.substring(
              0,
              config.maxBeneficiaryNameLength,
            ),
      enderecoLocalEntrega: {
        cep: companyData.enderecoFiscal.cep
          ? companyData.enderecoFiscal.cep
          : '',
        tipoLogradouro: getAddressType(
          companyData.enderecoFiscal.tipoLogradouro,
        ),
        logradouro: companyData.enderecoFiscal.logradouro,
        numero: companyData.enderecoFiscal.numero
          ? companyData.enderecoFiscal.numero
          : '',
        complemento: companyData.enderecoFiscal.complemento,
        bairro: companyData.enderecoFiscal.bairro,
        cidade: companyData.enderecoFiscal.cidade,
        estado: companyData.enderecoFiscal.estado,
      },
      dadosCadastrais: {
        nomeCadastro: registerData.nomeCadastro || '',
        emailCadastro: registerData.emailCadastro || '',
        telefoneCadastro: registerData.telefoneCadastro || '',
        dataNascimento: registerData.dataNascimento || '',
        cpfContato: registerData.cpfContato || '',
      },
      representanteLegal: {
        tipo: representativeData.type || Contact.RL,
        nome: representativeData.name || '',
        documento: representativeData.document || '',
        politicamenteExposta: false,
        juridica: representativeData.juridica,
      },
      contatoExtra:
        representativeData.extraContact?.map(contact => {
          return {
            tipo: contact.tipo,
            nome: contact.nome,
            documento: contact.documento,
            politicamenteExposta: false,
            juridica: contact.juridica,
          };
        }) || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'contatoExtra',
  });

  const buttonNameObject = {
    representanteLegal: 'Representante',
    cadeiaSocietaria: 'Sócio',
  };

  const watchContactType = methods.watch('representanteLegal.tipo');
  const watchExtraContactType = methods.watch('contatoExtra.0.tipo');

  const contactObjectMapper: Record<string, Contact> = {
    Sócio: Contact.CS,
    Representante: Contact.RL,
  };

  const getLastContactType = () => {
    return buttonNameObject[watchExtraContactType || watchContactType];
  };

  const handleAddContact = () => {
    append({
      tipo: contactObjectMapper[getLastContactType()],
      nome: '',
      documento: '',
      politicamenteExposta: false,
    });
  };

  const onSubmit: SubmitHandler<CompanyType> = async data => {
    setIsUpdatingLead(true);
    const newProductCartList = isComboFlux(selectedOffer)
      ? Object.values(productComboCartList).flat()
      : productCartList;

    try {
      await leadsService.updateLead(
        defaultHeader,
        companyData.lead,
        createUpdateLeadRequest({
          companyData,
          companyFormData: data,
          productCartList: newProductCartList,
          representativeData,
        }),
      );
      setIsUpdatingLead(false);
      dispatch(handleUpdateCompanyData(data));
      nextStep();
    } catch (error) {
      setIsUpdatingLead(false);
      handleWarningModalContent({
        isVisible: true,
        title: ApiErrorsModalTitle.DEFAULT,
        content: getMessageError(error),
        closeAction: {
          label: 'Fechar',
          action: error instanceof ApplicationError ? 'close' : 'reloadPage',
        },
      });
    }
  };

  const verifyConditionsToUseThisPage = useMemo(
    () => companyData.cnpj !== '' || companyData.lead !== '',
    [companyData.cnpj, companyData.lead],
  );

  if (!verifyConditionsToUseThisPage) {
    return (
      <Navigate
        to={{
          pathname: initialPage,
          search: location.search,
        }}
      />
    );
  }

  return (
    <FormProvider {...methods}>
      <PageTitle title="Dados da Empresa" />
      <CompanyDataPageContainer>
        <form
          className="transportation-company-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <RegisterSection
            title="Nome da empresa a ser gravado no(s) cartão(ões):"
            inputNumber={1}
          >
            <CompanyNameInput
              withCardDesign={false}
              isLoading={isUpdatingLead}
            />
          </RegisterSection>
          <RegisterSection
            title="Complete os dados cadastrais:"
            inputNumber={2}
          >
            <RegisterInputs isLoading={isUpdatingLead} />
          </RegisterSection>
          <RegisterSection
            title="Complete os dados de represente legal/cadeia societária:"
            inputNumber={3}
          >
            <ContactInputs
              fields={fields}
              addContact={handleAddContact}
              remove={remove}
              getLastContactType={getLastContactType}
              isLoading={isUpdatingLead}
            />
          </RegisterSection>
          <RegisterSection
            title={`Complete o endereço de entrega${
              modalityLabelForDeliveryLocationTitle !== ''
                ? ` dos cartões ${modalityLabelForDeliveryLocationTitle}:`
                : ':'
            }`}
            inputNumber={3}
          >
            <AddressInputs isLoading={isUpdatingLead} />
          </RegisterSection>
          <div className="action-area__button-area">
            <Button.Default
              id="btn-empresa-voltar"
              outline
              onClick={() => prevStep()}
              disabled={isUpdatingLead}
            >
              <i className="fa-regular fa-arrow-left" />
              Voltar
            </Button.Default>
            <Button.Default
              id="btn-empresa-avancar"
              type="submit"
              loading={isUpdatingLead}
            >
              Continuar
              <i className="fa-regular fa-arrow-right" />
            </Button.Default>
          </div>
        </form>
      </CompanyDataPageContainer>
    </FormProvider>
  );
}

const CompanyDataPageContainer = styled('div', {
  ...holderStyleWithMobileResponsive,
  flex: 1,

  width: '100%',
  paddingTop: '0 !important',
  marginTop: '3rem',

  '> div': {
    margin: '2rem 0',
  },

  '.transportation-company-form': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: convertPixelToRem(20),
  },

  '.action-area__button-area': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.25rem',
    margin: '1.75rem 0px 3rem',

    width: '100%',

    'button, a': {
      width: convertPixelToRem(240),
    },

    '@deviceSm': {
      flexDirection: 'column',
      margin: '1.5rem 0',

      'button, a': {
        width: '100%',
      },
    },
  },
});
