import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const ProductItemContainer = styled('form', {
  display: 'grid',
  gridTemplateColumns: `3.5rem 1fr ${convertPixelToRem(
    160,
  )} ${convertPixelToRem(160)} ${convertPixelToRem(132)}`,
  gridTemplateAreas: `'switch title amount value total-value'`,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.25rem',

  background: '$white',
  width: '100%',
  padding: '0.5rem 0',

  fontSize: convertPixelToRem(14),

  '& + &': {
    borderTop: '1px solid $silverLight',
  },

  '@deviceMd': {
    gridTemplateColumns: `3rem 1fr 1fr`,
    gridTemplateAreas: `
      'switch title total-value'
      'switch amount value'
    `,
    gap: '1rem',

    padding: '1rem',
  },

  '@deviceSm': {
    gridTemplateColumns: `3rem 1fr 1fr 1fr 1fr 1fr 1fr `,
    gridTemplateAreas: `
      'switch title title title total-value total-value total-value'
      'switch amount amount value value value value'
    `,
  },

  '@deviceXs': {
    gridTemplateColumns: `3rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr`,
    gridTemplateAreas: `
      'switch title title title title total-value total-value total-value total-value'
      'switch amount amount amount value value value value value'
    `,
    fontSize: '0.875rem',
  },

  '&.offer-container': {
    gridTemplateAreas: `'switch offer'`,
    gridTemplateColumns: `3.5rem 1fr`,

    minHeight: convertPixelToRem(106),
  },

  '> div': {
    textAlign: 'center',

    '&:nth-child(2)': {
      textAlign: 'start',
    },
  },

  '.product-item__switch': {
    gridArea: 'switch',

    '@deviceMd': {
      alignSelf: 'center',
      paddingTop: '0.25rem',
    },

    '@deviceSm': {
      paddingTop: 0,
    },

    '&--unselected': {
      alignSelf: 'unset',
    },
  },

  '.product-item__title': {
    gridArea: 'title',
    display: 'flex',
    alignItems: 'center',

    fontWeight: '$bold',

    img: {
      width: convertPixelToRem(52),
      marginRight: '1rem',
    },
  },

  '.product-item__offer-text': {
    gridArea: 'offer',
    display: 'flex',

    paddingRight: '0.5rem',

    '@deviceSm': {
      paddingRight: '0',
    },

    img: {
      width: '3.25rem',
      marginRight: '1rem',
    },

    '.offer-text__product-information': {
      '.product-information__product-name': {
        fontWeight: '$bold',

        '@deviceSm': {
          display: 'block',
        },
      },

      '.product-information__text': {
        marginTop: '0.25rem',
        lineHeight: '1.5',
      },
    },
  },

  '.product-item__product-amount': {
    display: 'flex',
    flexDirection: 'row',
    gridArea: 'amount',
    width: '100%',
    justifyContent: 'center',

    span: {
      fontSize: 'calc(16 - 0.15rem)',
    },

    '> div': {
      width: '100%',
      maxWidth: convertPixelToRem(90),

      label: {
        display: 'none',
      },

      '@deviceMd': {
        maxWidth: '100%',

        label: {
          display: 'block',
        },
      },
    },
  },

  '.product-item__product-value': {
    gridArea: 'value',

    label: {
      display: 'none',
    },

    '@deviceMd': {
      label: {
        display: 'block',
      },
    },
  },

  '.product-item__total-value': {
    gridArea: 'total-value',
    color: '$primaryPure',
    fontWeight: '$semibold',

    '@deviceMd': {
      textAlign: 'end',
    },
  },

  variants: {
    isMobility: {
      true: {
        gridTemplateColumns: `3rem 1fr ${convertPixelToRem(
          160,
        )} ${convertPixelToRem(160)} ${convertPixelToRem(132)}`,
        gridTemplateAreas: `'null title amount value total-value'`,

        '@deviceMd': {
          gridTemplateColumns: `1fr 1fr 1fr`,
          gridTemplateAreas: `
            'title title total-value'
            'amount value value'
          `,
        },

        '@deviceSm': {
          gridTemplateColumns: `1fr 1fr 1fr 1fr 1fr 1fr 1fr`,
          gridTemplateAreas: `
            'title title title title total-value total-value total-value'
            'amount amount amount value value value value'
          `,
        },

        '@deviceXs': {
          '.product-item__title': {
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    disabled: {
      true: {
        opacity: 0.5,
        pointerEvents: 'none',
        filter: 'grayscale(0.7)',
      },
    },
  },
});
