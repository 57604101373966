import { LoadingBox } from '@elements/loading-box';
import { SectionHolder } from '@elements/section-holder';

import { DefaultTokenVariantsType } from '@extra-types/token-variants-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const SkeletonSectionContainer = styled(SectionHolder, {
  display: 'flex',
  flexDirection: 'column',

  gap: '1.5rem',

  variants: {
    noPadding: {
      true: {
        padding: 0,
      },
    },
  },
});

const SkeletonGroupContainer = styled('div', {
  display: 'flex',
  gap: '1rem',

  width: '100%',

  variants: {
    guidance: {
      vertical: {
        flexDirection: 'column',
      },
      horizontal: {
        flexDirection: 'row',
        justifyContent: 'flex-start',

        '@deviceSm': {
          flexDirection: 'column',
        },
      },
    },
  },

  defaultVariants: {
    guidance: 'vertical',
  },
});

const SkeletonProductFormContainer = styled('div', {
  display: 'grid',
  gap: '1rem',
  gridTemplateColumns: 'repeat(4, 1fr)',

  '@deviceMd': {
    gap: '0.75rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  '@deviceXs': {
    gap: '0.5rem',
    gridTemplateColumns: '1fr',

    div: {
      maxWidth: '100%',
    },
  },
});

const SkeletonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  width: 'inherit',
});

interface SkeletonProps {
  variant?: DefaultTokenVariantsType;
}

function TitleSkeleton({ variant = 'primary' }: SkeletonProps) {
  return (
    <SkeletonContainer data-testid="loading-title">
      <LoadingBox variant={variant} width={700} />
      <LoadingBox variant={variant} width={450} />
    </SkeletonContainer>
  );
}

function BenefitItemSkeleton({ variant = 'primary' }: SkeletonProps) {
  return (
    <SkeletonContainer>
      <LoadingBox variant={variant} width={300} />
      <LoadingBox variant={variant} height={80} />
    </SkeletonContainer>
  );
}

function SectionSkeleton({ variant = 'primary' }: SkeletonProps) {
  return (
    <SkeletonContainer data-testid="loading-section">
      <LoadingBox variant={variant} width={700} />
      <LoadingBox variant={variant} width={450} />
      <LoadingBox variant={variant} width={2000} height={180} />
    </SkeletonContainer>
  );
}

function ModalitySkeleton({
  variant = 'primary',
  height = 225,
}: SkeletonProps & { height?: number }) {
  return (
    <SkeletonContainer
      css={{
        '> div': {
          borderRadius: '0px 42px',
        },
        '@deviceSm': {
          '> div': {
            height: height || convertPixelToRem(200),
          },
        },
      }}
    >
      <LoadingBox variant={variant} height={height} />
    </SkeletonContainer>
  );
}

function BigBoxSkeleton({ variant = 'primary' }: SkeletonProps) {
  return (
    <SkeletonContainer>
      <LoadingBox variant={variant} width={2000} height={240} />
    </SkeletonContainer>
  );
}

function LineSkeleton({
  variant = 'primary',
  height = 50,
  width = 2000,
}: { height?: number; width?: number } & SkeletonProps) {
  return <LoadingBox variant={variant} width={width} height={height} />;
}

function ProductFormSkeleton({ variant = 'primary' }: SkeletonProps) {
  return (
    <SkeletonContainer>
      <LoadingBox variant={variant} width={320} height={370} />
    </SkeletonContainer>
  );
}

export const Skeleton = {
  SectionContainer: SkeletonSectionContainer,
  GroupContainer: SkeletonGroupContainer,
  BenefitItem: BenefitItemSkeleton,
  BigBox: BigBoxSkeleton,
  Title: TitleSkeleton,
  Section: SectionSkeleton,
  Modality: ModalitySkeleton,
  Line: LineSkeleton,
  ProductFormContainer: SkeletonProductFormContainer,
  ProductForm: ProductFormSkeleton,
};
