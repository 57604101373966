import { useLocation, useNavigate } from 'react-router-dom';

import { Autoplay, Navigation, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { Skeleton, SkeletonSectionContainer } from '@components/skeletons';
import { PageSection } from '@elements/page-section';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { useWindowSize } from '@hooks/use-window-size';
import {
  changeCustomerType,
  proceedToPurchase,
} from '@store/slices/customer-slice';
import { selectOffer } from '@store/slices/offer-slice';
import { fetchOfferDetails } from '@store/thunks/offer-thunk';

import { OfferFacade } from '@src/facade/offer-facade';

import { BannerHolder, CarouselContainer, CarouselSwiper } from './styles';

export function Carousel() {
  const device = useWindowSize({ excludedSize: ['xs'] });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, activeOffers } = OfferFacade();
  const { bannerList } = useSelector(
    ({ bannerInformation }) => bannerInformation,
  );

  const getBanners = () => {
    const filteredBannerList = bannerList.filter(banner =>
      activeOffers.ofertas.some(offer => offer.codigoOferta !== banner.id),
    );

    const transformOfferToBanner = activeOffers.ofertas.map(o => ({
      id: o.codigoOferta,
      name: o.nomeOferta,
      description: o.descricaoOferta,
      img: o.imagem,
      offerId: o.codigoOferta,
      destination: undefined,
    }));

    const bannerToShow = filteredBannerList.map(b => ({
      name: b.id,
      id: b.id,
      description: b.description,
      img: b.imgURL,
      offerId: undefined,
      destination: b.destinationURL,
    }));

    return [...bannerToShow, ...transformOfferToBanner];
  };

  const handleBannerDestination = (offerId?: string, destination?: string) => {
    if (offerId) {
      dispatch(selectOffer(offerId));
      dispatch(changeCustomerType(undefined));
      dispatch(fetchOfferDetails());
      dispatch(proceedToPurchase());
    } else {
      navigate({
        pathname: destination,
        search: location.search,
      });
    }
  };

  if (isLoading && !activeOffers.ofertas) {
    return (
      <PageSection id="banner-section-loading" dark>
        <SkeletonSectionContainer>
          <Skeleton.BigBox />
        </SkeletonSectionContainer>
      </PageSection>
    );
  }

  return (
    <PageSection id="banner-section" dark>
      <CarouselContainer>
        <CarouselSwiper
          modules={[Navigation, Pagination, Autoplay]}
          preventInteractionOnTransition
          grabCursor
          pagination={
            getBanners().length > 1 && {
              clickable: true,
            }
          }
          navigation={getBanners().length > 1}
          loop={getBanners().length > 1}
          bulletPadding={getBanners().length > 1}
          allowTouchMove
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          breakpoints={{
            851: {
              allowTouchMove: false,
              grabCursor: false,
            },
          }}
          css={{
            $$customArrowDistance: '1.5rem',
          }}
        >
          {getBanners().map(banner => (
            <SwiperSlide key={banner.name}>
              <BannerHolder
                css={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleBannerDestination(banner.offerId, banner.destination);
                }}
              >
                <img
                  src={`${banner.img}-${device}.webp`}
                  alt={banner.description}
                />
              </BannerHolder>
            </SwiperSlide>
          ))}
        </CarouselSwiper>
      </CarouselContainer>
    </PageSection>
  );
}
