import { useState } from 'react';
import { Controller, FieldArrayWithId, useFormContext } from 'react-hook-form';

import cnpjValidate from '@vr/devkit/validators/cnpjValidate';
import cpfValidate from '@vr/devkit/validators/cpfValidate';

import Dropdown from '@components/dropdown';
import { Input } from '@components/input';
import { Button } from '@elements/button';
import Checkbox from '@elements/checkbox';
import Option from '@elements/option';

import { NAME_MAX_LENGTH, NAME_MIN_LENGTH } from '@config/input-config';
import { CompanyType } from '@extra-types/company-type';
import { Contact } from '@helpers/enum/contact';
import { FormErrors } from '@helpers/enum/form-errors';
import { validSameValue } from '@helpers/function-helpers';

interface ExtraContactInputsProps {
  index: number;
  item: Partial<FieldArrayWithId<CompanyType, 'contatoExtra', 'id'>>;
  remove: (index?: number | number[] | undefined) => void;
  isLoading?: boolean;
}

interface FormTextData {
  nome: {
    text: string;
    placeholder: string;
  };
  document: {
    text: string;
    placeholder: string;
    mask: string;
  };
  politicallyExposed: boolean;
}

export function ExtraContactInputs({
  index,
  item,
  remove,
  isLoading = false,
}: ExtraContactInputsProps) {
  const [contactType, setContactType] = useState<Contact>(
    item.tipo || Contact.RL,
  );
  const [politicallyExposed, setPoliticallyExposed] = useState(false);

  const {
    control,
    getValues,
    setValue,
    register,
    resetField,
    formState: { errors },
  } = useFormContext<CompanyType>();
  const [legalPerson, setLegalPerson] = useState(
    getValues().contatoExtra[index].juridica || false,
  );
  function handleSetContactType(type: Contact, contactIndex: number) {
    setValue(`contatoExtra.${contactIndex}.politicamenteExposta`, false);
    setPoliticallyExposed(false);
    setContactType(type);
  }

  function handleRemoveContact(whichContactToRemove: number) {
    remove(whichContactToRemove);
  }

  function formHandler(
    whichContactType: Contact,
    isLegalPerson: boolean,
  ): FormTextData {
    const formText: { [key: string]: FormTextData } = {
      representanteLegal: {
        nome: {
          text: 'Nome do Representante*',
          placeholder: 'Nome do representante',
        },
        document: {
          text: 'CPF*',
          placeholder: '000.000.000-00',
          mask: '999.999.999-99',
        },
        politicallyExposed: true,
      },
      pessoaFisica: {
        nome: {
          text: 'Nome do Sócio*',
          placeholder: 'Nome do sócio',
        },
        document: {
          text: 'CPF*',
          placeholder: '000.000.000-00',
          mask: '999.999.999-99',
        },
        politicallyExposed: true,
      },
      pessoaJuridica: {
        nome: {
          text: 'Razão Social*',
          placeholder: 'Razão social',
        },
        document: {
          text: 'CNPJ*',
          placeholder: '00.000.000/0000-00',
          mask: '99.999.999/9999-99',
        },
        politicallyExposed: false,
      },
    };

    if (whichContactType === Contact.RL) {
      return formText.representanteLegal;
    }

    return formText[
      whichContactType === Contact.CS && isLegalPerson
        ? 'pessoaJuridica'
        : 'pessoaFisica'
    ];
  }

  return (
    <li>
      <div className="contact__title">
        <div className="title__extra-contact">
          Dados de Contato Adicional ({index + 1}):
        </div>
        <Button.Default
          id="btn-empresa-remover-contato"
          className="contact__remove-button"
          type="button"
          disabled={isLoading}
          onClick={() => handleRemoveContact(index)}
        >
          <i className="fa-solid fa-xmark" />{' '}
        </Button.Default>
      </div>
      {contactType === Contact.CS && (
        <div className="contact__wrapper--checkbox">
          <Controller
            control={control}
            name={`contatoExtra.${index}.juridica`}
            render={({ field: { onChange } }) => (
              <Checkbox
                id={`de_contato-extra_${index}_pessoa-fisica`}
                isChecked={!legalPerson}
                disabled={isLoading}
                onCheck={() => {
                  onChange(false);
                  setLegalPerson(false);
                  setValue(`contatoExtra.${index}.documento`, '');
                }}
              >
                Pessoa Física
              </Checkbox>
            )}
          />
          <Controller
            control={control}
            name={`contatoExtra.${index}.juridica`}
            render={({ field: { onChange } }) => (
              <Checkbox
                id={`de_contato-extra_${index}_pessoa-juridica`}
                isChecked={legalPerson}
                disabled={isLoading}
                onCheck={() => {
                  onChange(true);
                  setLegalPerson(true);
                  setPoliticallyExposed(false);
                  setValue(`contatoExtra.${index}.documento`, '');
                }}
              >
                Pessoa Jurídica
              </Checkbox>
            )}
          />
        </div>
      )}
      <div className="contact__wrapper contact__wrapper--input">
        <Controller
          control={control}
          name={`contatoExtra.${index}.tipo`}
          rules={{ required: FormErrors.REQUIRED_DEFAULT }}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              id={`de_contato-extra_${index}_tipo`}
              value={value}
              label="Tipo contato*"
              disabled={isLoading}
              onChange={e => {
                if (Contact.RL === e.target.value) {
                  setLegalPerson(false);
                }
                onChange(e.target.value);
                handleSetContactType(e.target.value as Contact, index);
                resetField(`contatoExtra.${index}.documento`);
              }}
              errorMessage={
                errors.contatoExtra && errors.contatoExtra[index]?.tipo?.message
              }
              css={{ width: '33%' }}
            >
              <Option value={Contact.RL}>Representante Legal</Option>
              <Option value={Contact.CS}>Cadeia Societária</Option>
            </Dropdown>
          )}
        />
        <Input.Default
          id={`de_contato-extra_${index}_nome`}
          label={formHandler(contactType, legalPerson).nome.text}
          type="text"
          placeholder={formHandler(contactType, legalPerson).nome.placeholder}
          width="100%"
          disabled={isLoading}
          errorMessage={
            errors.contatoExtra && errors.contatoExtra[index]?.nome?.message
          }
          css={{ width: '33%' }}
          {...register(`contatoExtra.${index}.nome`, {
            minLength: {
              value: NAME_MIN_LENGTH,
              message: FormErrors.INVALID_NAME_MINLENGTH,
            },
            maxLength: {
              value: NAME_MAX_LENGTH,
              message: FormErrors.INVALID_NAME_MAXLENGTH,
            },
            required: FormErrors.REQUIRED_NAME,
          })}
        />

        <Input.Default
          id={`de_contato-extra_${index}_documento`}
          label={formHandler(contactType, legalPerson).document.text}
          type="text"
          placeholder={
            formHandler(contactType, legalPerson).document.placeholder
          }
          width="100%"
          disabled={isLoading}
          customMask={formHandler(contactType, legalPerson).document.mask}
          errorMessage={
            errors.contatoExtra &&
            errors.contatoExtra[index]?.documento?.message
          }
          css={{ width: '33%' }}
          inputMode="numeric"
          {...register(`contatoExtra.${index}.documento`, {
            required:
              contactType === Contact.CS && legalPerson
                ? FormErrors.REQUIRED_CNPJ
                : FormErrors.REQUIRED_CPF,
            validate: {
              value: value => {
                if (contactType === Contact.CS && legalPerson) {
                  return cnpjValidate(value) || FormErrors.INVALID_CNPJ;
                }
                return cpfValidate(value) || FormErrors.INVALID_CPF;
              },
              sameValue: value =>
                !validSameValue(value, [
                  getValues().representanteLegal?.documento,
                  ...getValues().contatoExtra.map(contato => contato.documento),
                ]) ||
                (contactType === Contact.CS && legalPerson
                  ? FormErrors.INVALID_UNIQUE_CNPJ
                  : FormErrors.INVALID_UNIQUE_CPF),
            },
          })}
        />
      </div>
      {formHandler(contactType, legalPerson).politicallyExposed && (
        <div className="contact__wrapper contact__wrapper--input contact__wrapper--justify-start contact__wrapper--remove-margin-bottom">
          <Controller
            control={control}
            name={`contatoExtra.${index}.politicamenteExposta`}
            defaultValue={false}
            render={({ field: { onChange } }) => (
              <Checkbox
                id={`de_contato-extra_${index}_politicamente-exposta`}
                isChecked={politicallyExposed}
                disabled={isLoading}
                onCheck={() => {
                  setPoliticallyExposed(!politicallyExposed);
                  onChange(!politicallyExposed);
                }}
              >
                Pessoa politicamente exposta
              </Checkbox>
            )}
          />
        </div>
      )}
      {index > 0 && (
        <div className="contact__wrapper contact__wrapper--remove-button-mobile">
          <Button.Default
            id={`de_excluir-contato-mobile_${index}`}
            type="button"
            disabled={isLoading}
            onClick={() => handleRemoveContact(index)}
          >
            Excluir Contato
          </Button.Default>
        </div>
      )}
    </li>
  );
}
