import { PortalAccessMessage } from '@elements/portal-access-message';
import { ProductNameText } from '@elements/product-name-text';

type AlreadyContractedProductProps = {
  productId: number;
};

function AlreadyContractedProduct({
  productId,
}: AlreadyContractedProductProps) {
  return (
    <div className="disabled-product-container">
      <div>
        <i className="fa-solid fa-circle-check" />
      </div>
      <h3>
        Parece que você já possui o{' '}
        <strong>
          <ProductNameText id={productId} />
        </strong>{' '}
        contratado.
      </h3>
      <PortalAccessMessage />
    </div>
  );
}

export default AlreadyContractedProduct;
