import { MarketplaceDetails } from '@components/marketplace-datails';
import MarketplaceDescriptionPurchase, {
  MarketplaceDescriptionPurchaseProps,
} from '@components/table-components/marketplace-description';

import { BonusDetailsProps } from '@extra-types/bonus-type';
import { ProductsTokenVariantsType } from '@extra-types/token-variants-type';
import { AcceptanceNetwork } from '@helpers/enum/acceptance-network';
import { Emissor } from '@helpers/enum/emissor';
import { ProductCommercialConditionsId } from '@helpers/enum/product-commercial-conditions';
import { ProductsId } from '@helpers/enum/products-id';
import { ProductName, ProductPromotionalPhrase } from '@helpers/style-helpers';

import {
  ASSETS_CARDS_PATH,
  ASSETS_ICONS_PATH,
  ASSETS_LOGOS_PATH,
} from './assets-config';

export const INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX = '/produtos/';
export const MOBILITY_PRODUCT_PAGE_PATH_PREFIX = '/mobilidade/';
export const TAX_CARD = 4;

export interface ProductData {
  id: number;
  name: string;
  colorPrefixToken: ProductsTokenVariantsType;
  logoPath: string;
  cardPath: string;
  acceptanceNetworkList: AcceptanceNetwork[];
  showInMapList: boolean;
  emissor: Emissor | 'all';
  showAsteriskPatAux: boolean;
  pagePath?: string;
}

export const NOT_FOUND_PRODUCT_STATIC_DATA = {
  id: null,
  name: 'Produto não encontrado',
  colorPrefixToken: 'refeicao',
  logoPath: `${ASSETS_LOGOS_PATH}/logo-vr.png`,
  cardPath: ``,
  acceptanceNetworkList: '',
  showInMapList: false,
  pagePath: null,
};

export const PRODUCTS: { [key: number]: ProductData } = {
  [ProductsId.ALIMENTACAO]: {
    id: ProductsId.ALIMENTACAO,
    name: 'Alimentação',
    colorPrefixToken: 'alimentacao',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.ALIMENTACAO}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.ALIMENTACAO}.svg`,
    acceptanceNetworkList: [AcceptanceNetwork.ALIMENTACAO],
    showInMapList: true,
    showAsteriskPatAux: true,
    emissor: Emissor.VRPAT,
    pagePath: `${INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX}alimentacao`,
  },
  [ProductsId.AUTO]: {
    id: ProductsId.AUTO,
    name: 'Auto',
    colorPrefixToken: 'auto',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.AUTO}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.AUTO}.svg`,
    acceptanceNetworkList: [AcceptanceNetwork.AUTO],
    showInMapList: true,
    showAsteriskPatAux: false,
    emissor: Emissor.VRPAT,
    pagePath: `${INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX}auto`,
  },
  [ProductsId.REFEICAO]: {
    id: ProductsId.REFEICAO,
    name: 'Refeição',
    colorPrefixToken: 'refeicao',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.REFEICAO}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.REFEICAO}.svg`,
    acceptanceNetworkList: [AcceptanceNetwork.REFEICAO],
    showInMapList: true,
    showAsteriskPatAux: true,
    emissor: Emissor.VRPAT,
    pagePath: `${INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX}refeicao`,
  },
  [ProductsId.BOASFESTAS]: {
    id: ProductsId.BOASFESTAS,
    name: 'Boas Festas',
    colorPrefixToken: 'boasFestas',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.BOASFESTAS}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.BOASFESTAS}.svg`,
    acceptanceNetworkList: [AcceptanceNetwork.ALIMENTACAO],
    showInMapList: true,
    showAsteriskPatAux: false,
    emissor: Emissor.VRPAT,
    pagePath: `${INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX}boasfestas`,
  },
  [ProductsId.VRVA]: {
    id: ProductsId.VRVA,
    name: 'Auxílio VR+VA',
    colorPrefixToken: 'vrva',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.VRVA}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.VRVA}.svg`,
    acceptanceNetworkList: [
      AcceptanceNetwork.ALIMENTACAO,
      AcceptanceNetwork.REFEICAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: true,
    emissor: Emissor.VRPAT,
    pagePath: `${INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX}vrva`,
  },
  [ProductsId.VALE_TRANSPORTE]: {
    id: ProductsId.VALE_TRANSPORTE,
    name: 'Vale-Transporte',
    colorPrefixToken: 'valeTransporte',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.VALE_TRANSPORTE}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.VALE_TRANSPORTE}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.TRANSPORTE],
    showInMapList: false,
    showAsteriskPatAux: false,
    emissor: Emissor.VRPAT,
    pagePath: `${MOBILITY_PRODUCT_PAGE_PATH_PREFIX}vale-transporte`,
  },
  [ProductsId.TRANSPORTE]: {
    id: ProductsId.TRANSPORTE,
    name: 'Transporte',
    colorPrefixToken: 'transporte',
    logoPath: `${ASSETS_CARDS_PATH}/logo-${ProductsId.TRANSPORTE}.png`,
    cardPath: `/card-${ProductsId.TRANSPORTE}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.TRANSPORTE],
    showInMapList: false,
    showAsteriskPatAux: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.MULTI]: {
    id: ProductsId.MULTI,
    name: 'Multi',
    colorPrefixToken: 'multi',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.MULTI}.svg`,
    acceptanceNetworkList: [
      AcceptanceNetwork.ALIMENTACAO,
      AcceptanceNetwork.REFEICAO,
      AcceptanceNetwork.AUTO,
      AcceptanceNetwork.COMPRAS,
      AcceptanceNetwork.CULTURA,
      AcceptanceNetwork.TRANSPORTE,
    ],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.MULTI_JVCEF]: {
    id: ProductsId.MULTI_JVCEF,
    name: 'Multi',
    colorPrefixToken: 'multi',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_JVCEF}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.MULTI_JVCEF}.png`,
    acceptanceNetworkList: [
      AcceptanceNetwork.ALIMENTACAO,
      AcceptanceNetwork.REFEICAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.JVCEF,
  },
  [ProductsId.CAIXA_REFEICAO]: {
    id: ProductsId.CAIXA_REFEICAO,
    name: 'CAIXA Refeição',
    colorPrefixToken: 'refeicao',
    logoPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.CAIXA_REFEICAO}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.CAIXA_REFEICAO}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.REFEICAO],
    showInMapList: true,
    showAsteriskPatAux: false,
    emissor: Emissor.JVCEF,
    pagePath: `${INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX}refeicao`,
  },
  [ProductsId.CAIXA_ALIMENTACAO]: {
    id: ProductsId.CAIXA_ALIMENTACAO,
    name: 'CAIXA Alimentação',
    colorPrefixToken: 'alimentacao',
    logoPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.CAIXA_ALIMENTACAO}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.CAIXA_ALIMENTACAO}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.ALIMENTACAO],
    showInMapList: true,
    showAsteriskPatAux: false,
    emissor: Emissor.JVCEF,
    pagePath: `${INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX}alimentacao`,
  },
  [ProductsId.MULTI_REFEICAO_AUXILIO]: {
    id: ProductsId.MULTI_REFEICAO_AUXILIO,
    name: 'Multi Refeição Auxílio',
    colorPrefixToken: 'refeicao',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_REFEICAO_AUXILIO}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_REFEICAO_AUXILIO}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.REFEICAO],
    emissor: Emissor.VRPAT,
    showInMapList: false,
    showAsteriskPatAux: true,
    pagePath: '/modalidade/multisaldo',
  },
  [ProductsId.MULTI_REFEICAO_PAT]: {
    id: ProductsId.MULTI_REFEICAO_PAT,
    name: 'Multi Refeição PAT',
    colorPrefixToken: 'refeicao',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_REFEICAO_PAT}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_REFEICAO_PAT}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.REFEICAO],
    showInMapList: false,
    showAsteriskPatAux: true,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.MULTI_ALIMENTACAO_AUXILIO]: {
    id: ProductsId.MULTI_ALIMENTACAO_AUXILIO,
    name: 'Multi Alimentação Auxílio',
    colorPrefixToken: 'alimentacao',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_ALIMENTACAO_AUXILIO}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_ALIMENTACAO_AUXILIO}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.ALIMENTACAO],
    showInMapList: false,
    showAsteriskPatAux: true,
    emissor: Emissor.VRPAT,
    pagePath: '/modalidade/multisaldo',
  },
  [ProductsId.MULTI_ALIMENTACAO_PAT]: {
    id: ProductsId.MULTI_ALIMENTACAO_PAT,
    name: 'Multi Alimentação PAT',
    colorPrefixToken: 'alimentacao',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_ALIMENTACAO_PAT}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_ALIMENTACAO_PAT}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.ALIMENTACAO],
    showInMapList: false,
    showAsteriskPatAux: true,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.MULTI_VRVA]: {
    id: ProductsId.MULTI_VRVA,
    name: 'Multi VR + VA',
    colorPrefixToken: 'vrva',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_VRVA}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_VRVA}.png`,
    acceptanceNetworkList: [
      AcceptanceNetwork.ALIMENTACAO,
      AcceptanceNetwork.REFEICAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.VRPAT,
    pagePath: '/modalidade/multisaldo',
  },
  [ProductsId.MULTI_PREMIACAO]: {
    id: ProductsId.MULTI_PREMIACAO,
    name: 'Multi Premiação',
    colorPrefixToken: 'premiacao',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_PREMIACAO}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.MULTI_PREMIACAO}.png`,
    acceptanceNetworkList: [
      AcceptanceNetwork.ALIMENTACAO,
      AcceptanceNetwork.REFEICAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: true,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.MULTI_HOME_OFFICE]: {
    id: ProductsId.MULTI_HOME_OFFICE,
    name: 'Multi Home-Office',
    colorPrefixToken: 'homeOffice',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_HOME_OFFICE}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_HOME_OFFICE}.png`,
    acceptanceNetworkList: [
      AcceptanceNetwork.ALIMENTACAO,
      AcceptanceNetwork.REFEICAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.MULTI_MOBILIDADE]: {
    id: ProductsId.MULTI_MOBILIDADE,
    name: 'Multi Mobilidade',
    colorPrefixToken: 'auto',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_MOBILIDADE}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_MOBILIDADE}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.AUTO],
    showInMapList: false,
    showAsteriskPatAux: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.MULTI_BOAS_FESTAS]: {
    id: ProductsId.MULTI_BOAS_FESTAS,
    name: 'Multi Boas Festas',
    colorPrefixToken: 'boasFestas',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_BOAS_FESTAS}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_BOAS_FESTAS}.png`,
    acceptanceNetworkList: [
      AcceptanceNetwork.REFEICAO,
      AcceptanceNetwork.ALIMENTACAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.MULTI_CAIXA_REFEICAO_PAT]: {
    id: ProductsId.MULTI_CAIXA_REFEICAO_PAT,
    name: 'Multi - CAIXA Refeição',
    colorPrefixToken: 'refeicao',
    logoPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.MULTI_CAIXA_REFEICAO_PAT}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_CAIXA_REFEICAO_PAT}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.REFEICAO],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.JVCEF,
  },
  [ProductsId.MULTI_CAIXA_REFEICAO_AUXILIO]: {
    id: ProductsId.MULTI_CAIXA_REFEICAO_AUXILIO,
    name: 'Multi - Auxílio Refeição',
    colorPrefixToken: 'refeicao',
    logoPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.MULTI_CAIXA_REFEICAO_AUXILIO}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_CAIXA_REFEICAO_AUXILIO}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.REFEICAO],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.JVCEF,
  },
  [ProductsId.MULTI_CAIXA_ALIMENTACAO_PAT]: {
    id: ProductsId.MULTI_CAIXA_ALIMENTACAO_PAT,
    name: 'Multi - CAIXA Alimentação',
    colorPrefixToken: 'alimentacao',
    logoPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.MULTI_CAIXA_ALIMENTACAO_PAT}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_CAIXA_ALIMENTACAO_PAT}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.ALIMENTACAO],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.JVCEF,
  },
  [ProductsId.MULTI_CAIXA_ALIMENTACAO_AUXILIO]: {
    id: ProductsId.MULTI_CAIXA_ALIMENTACAO_AUXILIO,
    name: 'Multi - Auxílio Alimentação',
    colorPrefixToken: 'alimentacao',
    logoPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.MULTI_CAIXA_ALIMENTACAO_AUXILIO}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_CAIXA_ALIMENTACAO_AUXILIO}.png`,
    acceptanceNetworkList: [AcceptanceNetwork.ALIMENTACAO],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.JVCEF,
  },
  [ProductsId.MULTI_CAIXA_CA_CR]: {
    id: ProductsId.MULTI_CAIXA_CA_CR,
    name: 'Multi - CA + CR',
    colorPrefixToken: 'vrva',
    logoPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.MULTI_CAIXA_CA_CR}.png`,
    cardPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.MULTI_CAIXA_CA_CR}.png`,
    acceptanceNetworkList: [
      AcceptanceNetwork.ALIMENTACAO,
      AcceptanceNetwork.REFEICAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.JVCEF,
  },
  [ProductsId.AUXILIO_ALIMENTACAO]: {
    id: ProductsId.AUXILIO_ALIMENTACAO,
    name: 'Auxílio Alimentação',
    colorPrefixToken: 'alimentacao',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.ALIMENTACAO}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.ALIMENTACAO}.svg`,
    acceptanceNetworkList: [
      AcceptanceNetwork.REFEICAO,
      AcceptanceNetwork.ALIMENTACAO,
    ],
    showAsteriskPatAux: true,
    showInMapList: false,
    emissor: Emissor.VRPAT,
    pagePath: `${INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX}auxilio-alimentacao`,
  },
  [ProductsId.AUXILIO_REFEICAO]: {
    id: ProductsId.AUXILIO_REFEICAO,
    name: 'Auxílio Refeição',
    colorPrefixToken: 'refeicao',
    logoPath: `${ASSETS_LOGOS_PATH}/logo-${ProductsId.REFEICAO}.png`,
    cardPath: `${ASSETS_CARDS_PATH}/card-${ProductsId.REFEICAO}.svg`,
    acceptanceNetworkList: [
      AcceptanceNetwork.REFEICAO,
      AcceptanceNetwork.ALIMENTACAO,
    ],
    showAsteriskPatAux: true,
    showInMapList: false,
    emissor: Emissor.VRPAT,
    pagePath: `${INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX}auxilio-refeicao`,
  },
  [ProductsId.HCM_CORPORATIVO]: {
    id: ProductsId.HCM_CORPORATIVO,
    name: 'Plano Corporativo',
    colorPrefixToken: 'tertiary',
    logoPath: `${ASSETS_ICONS_PATH}/icon-plano-corporativo.png`,
    cardPath: `${ASSETS_ICONS_PATH}/icon-plano-corporativo.png`,
    acceptanceNetworkList: [
      AcceptanceNetwork.REFEICAO,
      AcceptanceNetwork.ALIMENTACAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.HCM_PROFISSIONAL]: {
    id: ProductsId.HCM_PROFISSIONAL,
    name: 'Plano Profissional',
    colorPrefixToken: 'tertiary',
    logoPath: `${ASSETS_ICONS_PATH}/icon-plano-corporativo.png`,
    cardPath: `${ASSETS_ICONS_PATH}/icon-plano-corporativo.png`,
    acceptanceNetworkList: [
      AcceptanceNetwork.REFEICAO,
      AcceptanceNetwork.ALIMENTACAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.HCM_GERENCIAL]: {
    id: ProductsId.HCM_GERENCIAL,
    name: 'Plano Gerencial',
    colorPrefixToken: 'tertiary',
    logoPath: `${ASSETS_ICONS_PATH}/icon-plano-corporativo.png`,
    cardPath: `${ASSETS_ICONS_PATH}/icon-plano-corporativo.png`,
    acceptanceNetworkList: [
      AcceptanceNetwork.REFEICAO,
      AcceptanceNetwork.ALIMENTACAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.HCM_ESSENCIAL]: {
    id: ProductsId.HCM_ESSENCIAL,
    name: 'Plano Essencial',
    colorPrefixToken: 'tertiary',
    logoPath: `${ASSETS_ICONS_PATH}/icon-plano-corporativo.png`,
    cardPath: `${ASSETS_ICONS_PATH}/icon-plano-corporativo.png`,
    acceptanceNetworkList: [
      AcceptanceNetwork.REFEICAO,
      AcceptanceNetwork.ALIMENTACAO,
    ],
    showAsteriskPatAux: false,
    showInMapList: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.NUTRICAO]: {
    id: ProductsId.NUTRICAO,
    name: 'Nutrição',
    colorPrefixToken: 'nutricao',
    logoPath: `${ASSETS_ICONS_PATH}/nutricao-icon.svg`,
    cardPath: `${ASSETS_ICONS_PATH}/nutricao-icon.svg`,
    acceptanceNetworkList: [],
    showInMapList: false,
    showAsteriskPatAux: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.DESCONTO_FARMACIA]: {
    id: ProductsId.DESCONTO_FARMACIA,
    name: 'Desconto Farmácia',
    colorPrefixToken: 'descontoFarmacia',
    logoPath: `${ASSETS_ICONS_PATH}/desconto-farmacia-icon.svg`,
    cardPath: `${ASSETS_ICONS_PATH}/desconto-farmacia-icon.svg`,
    acceptanceNetworkList: [],
    showInMapList: false,
    showAsteriskPatAux: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.APOIO_TRABALHADOR]: {
    id: ProductsId.APOIO_TRABALHADOR,
    name: 'Apoio ao Trabalhador',
    colorPrefixToken: 'apoioTrabalhador',
    logoPath: `${ASSETS_ICONS_PATH}/apoio-ao-trabalhador-icon.svg`,
    cardPath: `${ASSETS_ICONS_PATH}/apoio-ao-trabalhador-icon.svg`,
    acceptanceNetworkList: [],
    showInMapList: false,
    showAsteriskPatAux: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.SEGURO_ALIMENTACAO]: {
    id: ProductsId.SEGURO_ALIMENTACAO,
    name: 'Seguro Alimentação',
    colorPrefixToken: 'seguroAlimentacao',
    logoPath: `${ASSETS_ICONS_PATH}/seguro-alimentacao-icon.svg`,
    cardPath: `${ASSETS_ICONS_PATH}/seguro-alimentacao-icon.svg`,
    acceptanceNetworkList: [],
    showInMapList: false,
    showAsteriskPatAux: false,
    emissor: Emissor.VRPAT,
  },
  [ProductsId.TOTALPASS]: {
    id: ProductsId.TOTALPASS,
    name: 'Desconto Academia',
    colorPrefixToken: 'totalpass',
    logoPath: `${ASSETS_ICONS_PATH}/totalpass-icon.svg`,
    cardPath: `${ASSETS_ICONS_PATH}/totalpass-icon.svg`,
    acceptanceNetworkList: [],
    showInMapList: false,
    showAsteriskPatAux: false,
    emissor: Emissor.VRPAT,
  },
};

export const PRODUCT_OFFER_PHRASE: { [key: number]: JSX.Element } = {
  [ProductsId.ALIMENTACAO]: (
    <ProductPromotionalPhrase>
      <p>
        Compras em supermercados, padarias, mercearias, açougues e similares
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.AUXILIO_ALIMENTACAO]: (
    <ProductPromotionalPhrase>
      <p>
        Compras em supermercados, padarias, mercearias, açougues e similares
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.AUTO]: (
    <ProductPromotionalPhrase>
      <p>Pagamento de combustível e serviços automotivos no geral</p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.REFEICAO]: (
    <ProductPromotionalPhrase>
      <p>
        Pagamento de refeições em restaurantes, padarias, lanchonetes e
        similares
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.AUXILIO_REFEICAO]: (
    <ProductPromotionalPhrase>
      <p>
        Pagamento de refeições em restaurantes, padarias, lanchonetes e
        similares
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.BOASFESTAS]: (
    <ProductPromotionalPhrase>
      <p>
        Para presentear os colaboradores na época de festas de fim de ano.{' '}
        Aceito nas redes{' '}
        <ProductName css={{ $$productColor: '$colors$refeicaoColorName' }}>
          Vale-Refeição
        </ProductName>
        ,{' '}
        <ProductName css={{ $$productColor: '$colors$alimentacaoColorName' }}>
          Vale-Alimentação
        </ProductName>{' '}
        e aplicativos de delivery.
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.VRVA]: (
    <ProductPromotionalPhrase>
      <p>
        <ProductName css={{ $$productColor: '$colors$refeicaoColorName' }}>
          Vale-Refeição
        </ProductName>{' '}
        e{' '}
        <ProductName css={{ $$productColor: '$colors$alimentacaoColorName' }}>
          Vale-Alimentação
        </ProductName>{' '}
        em um só produto: facilidade de gestão e liberdade de uso para o
        trabalhador
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.MULTI]: (
    <ProductPromotionalPhrase>
      <p>Ativando Multi você terá vários benefícios em um cartão só.</p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.CAIXA_REFEICAO]: (
    <ProductPromotionalPhrase>
      <p>
        Pagamento de refeições em restaurantes, padarias, lanchonetes e
        similares
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.CAIXA_ALIMENTACAO]: (
    <ProductPromotionalPhrase>
      <p>
        Compras em supermercados, padarias, mercearias, açougues e similares
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.MULTI_REFEICAO_AUXILIO]: (
    <ProductPromotionalPhrase>
      <p>
        Para uso em estabelecimentos de refeições prontas como restaurantes,
        padarias, lanchonetes e similares na rede Refeição.
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.MULTI_REFEICAO_PAT]: (
    <ProductPromotionalPhrase>
      <p>
        Para uso em estabelecimentos de refeições prontas como restaurantes,
        padarias, lanchonetes e similares na rede Refeição.
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.MULTI_ALIMENTACAO_AUXILIO]: (
    <ProductPromotionalPhrase>
      <p>
        Para compra de gêneros alimentícios em estabelecimentos como
        supermercados, padarias, mercearias, açougues e similares na rede
        Alimentação.
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.MULTI_ALIMENTACAO_PAT]: (
    <ProductPromotionalPhrase>
      <p>
        Para compra de gêneros alimentícios em estabelecimentos como
        supermercados, padarias, mercearias, açougues e similares na rede
        Alimentação.
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.MULTI_VRVA]: (
    <ProductPromotionalPhrase>
      <p>
        <ProductName css={{ $$productColor: '$colors$refeicaoColorName' }}>
          Vale-Refeição
        </ProductName>{' '}
        e{' '}
        <ProductName css={{ $$productColor: '$colors$alimentacaoColorName' }}>
          Vale-Alimentação
        </ProductName>{' '}
        em um só produto: facilidade de gestão e liberdade de uso para o
        trabalhador
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.MULTI_PREMIACAO]: (
    <ProductPromotionalPhrase>
      <p>
        Para premiação pontual de colaboradores (conforme artigo 457 da CLT) com
        uso em pagamento de boletos de qualquer finalidade, compra online de
        produtos sem restrição, recarga de celular e em toda a rede VR.
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.MULTI_HOME_OFFICE]: (
    <ProductPromotionalPhrase>
      <p>
        Para uso no SuperApp VR e Você para recarga de celular, pagamento de
        contas de água, luz, telefone, internet e gás, e compra online de
        materiais de escritório ou para o home office.
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.MULTI_MOBILIDADE]: (
    <ProductPromotionalPhrase>
      <p>
        Para pagamentos de combustível e serviços automotivos no geral na rede
        Auto.
      </p>
    </ProductPromotionalPhrase>
  ),
  [ProductsId.MULTI_BOAS_FESTAS]: (
    <ProductPromotionalPhrase>
      <p>
        Para presentear os colaboradores na época de festas de fim de ano.{' '}
        Aceito nas redes{' '}
        <ProductName css={{ $$productColor: '$colors$refeicaoColorName' }}>
          Vale-Refeição
        </ProductName>
        ,{' '}
        <ProductName css={{ $$productColor: '$colors$alimentacaoColorName' }}>
          Vale-Alimentação
        </ProductName>{' '}
        e aplicativos de delivery.
      </p>
    </ProductPromotionalPhrase>
  ),
};

export const PRODUCT_COMMERCIAL_CONDITIONS: {
  [key: number]: {
    label: string;
    type: 'tax' | 'offer';
  };
} = {
  [ProductCommercialConditionsId.CARD_ISSUANCE_FEE]: {
    label: 'Tarifa de emissão de cartões',
    type: 'tax',
  },
  [ProductCommercialConditionsId.CREDIT_FEE]: {
    label: 'Tarifa de crédito',
    type: 'tax',
  },
  [ProductCommercialConditionsId.SERVICE_CHARGE]: {
    label: 'Tarifa de serviço',
    type: 'tax',
  },
  [ProductCommercialConditionsId.MARKETPLACE]: {
    label: 'VR Marketplace',
    type: 'offer',
  },
};

export const COMMERCIAL_CONDITIONS_BONUS_DETAILS: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: number]: (data: BonusDetailsProps) => JSX.Element;
} = {
  [ProductCommercialConditionsId.MARKETPLACE]: MarketplaceDetails,
};

type SVAProductsData = {
  [key in string]: {
    title: string;
    subtitle: string;
    Description: React.FC<MarketplaceDescriptionPurchaseProps>;
    minimumValue: number;
    availableProducts: number[];
  };
};

export const SVAProducts: SVAProductsData = {
  [ProductCommercialConditionsId.MARKETPLACE]: {
    title: 'Sua faixa de pontuação no VR Marketplace',
    subtitle: 'Programa de Resgate de Pontos da VR',
    Description: MarketplaceDescriptionPurchase,
    minimumValue: 250.0,
    availableProducts: [27, 31],
  },
};

export const excludedCommercialConditionsCode = [36];

export const EXCLUDE_REASONS = {
  UNSERVED_REGION_PRODUCT_EXCLUDE_REASON: -1,
};
