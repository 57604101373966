import { StyledSwiper } from '@elements/styled-swiper';

import { styled } from '@src/themes';

export const CarouselContainer = styled('section', {
  display: 'flex',
  justifyContent: 'center',

  background: '$silverPure',
});

export const CarouselSwiper = styled(StyledSwiper, {
  display: 'flex',
  justifyContent: 'center',

  '.swiper-button-prev': {
    left: '1.5rem',
  },

  '.swiper-button-next': {
    right: '1.5rem',
  },

  variants: {
    bulletPadding: {
      true: {
        paddingBottom: '2rem !important',
      },
    },
  },
  defaultVariants: {
    bulletPadding: true,
  },
});

export const BannerHolder = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  height: '100%',

  paddingTop: '1rem',

  '&:is(a)': {
    pointer: 'click',
  },

  img: {
    borderRadius: '10px',
    maxWidth: '100%',

    '@deviceMd': {
      maxHeight: '185px',
    },
  },

  '@deviceMd': {
    maxHeight: '185px',
  },
  variants: {
    hideBorder: {
      true: {
        borderWidth: 0,
      },
    },
  },
  defaultVariants: {
    hideBorder: false,
  },
});
