import { ApiRequestAuthData } from '@api/models/request/auth-data';
import ProductListResponse from '@api/models/response/product-list-response';

import getBaseURLApi from './authentication/get-base-url-api';

class ProductService {
  private readonly baseUrl;

  constructor() {
    this.baseUrl = 'produtos';
  }

  public async getProducts({
    siglaEmissor,
    siglaCanal,
    siglaSubCanal,
  }: ApiRequestAuthData): Promise<ProductListResponse> {
    const url = `/${this.baseUrl}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
    });

    return data;
  }
}

export default new ProductService();
