import { BonusDetailsProps } from '@extra-types/bonus-type';

import { BonusItemDetailProduct } from '../elements/bonus-item-detail-product';

export function MarketplaceDetails({
  emissorName,
  productBonusDataList,
}: BonusDetailsProps) {
  return (
    <>
      <p>
        O <strong>VR Marketplace</strong> é o nosso programa de fidelidade no
        qual a empresa ganha pontos <strong>VR Fidelidade</strong> ao realizar
        créditos nos cartões da <strong>{emissorName}</strong> de seus
        colaboradores.
      </p>
      <p>
        Os pontos podem ser utilizados para o pagamento de boletos, produtos e
        serviços de saúde e segurança alimentar.
      </p>
      <p>Veja abaixo como será o acumulo de pontos com o seu pedido:</p>
      {productBonusDataList && (
        <ul className="bonus-item__rule">
          {[...productBonusDataList]
            .sort((a, b) => {
              return b.unitaryValue - a.unitaryValue;
            })
            .map(productBonusData => (
              <BonusItemDetailProduct
                key={productBonusData.unitaryValue}
                data={productBonusData}
              />
            ))}
        </ul>
      )}
      <p className="bonus-item__warning">
        * Os pontos serão computados até 3 dias depois do pagamento e
        faturamento do boleto.
      </p>
    </>
  );
}
