import { HTMLAttributes } from 'react';

import { ASSETS_ICON_FAQ_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { convertPixelToRem } from '@helpers/style-helpers';

import { keyframes, styled } from '@src/themes';

const FAQ_ICON_ASSETS_POSTFIX = '.svg';
interface FAQCategoryProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  color: string;
  iconPath: string;
  isActive: boolean;
}

export function FAQCategoryItem({
  title,
  color,
  iconPath,
  isActive,
  ...props
}: FAQCategoryProps) {
  return (
    <FAQCategoryContainer
      data-testid="faq-category-item"
      isActive={isActive}
      css={{
        $$backgroundColor: color,
        $$backgroundColorWithOpacityDesktop: `${color}85`,
        $$backgroundColorWithOpacityMobile: `${color}1A`,
      }}
      {...props}
    >
      <div className="faq-category-container__holder">
        <FAQCategoryIcon>
          <img
            src={`${env.URL_ASSETS}${ASSETS_ICON_FAQ_PATH}/${iconPath}${FAQ_ICON_ASSETS_POSTFIX}`}
            alt={`Ícone da categoria ${title}`}
          />
        </FAQCategoryIcon>
        <p>{title}</p>
      </div>
    </FAQCategoryContainer>
  );
}

const backgroundAnimation = keyframes({
  from: {
    backgroundPosition: '90%',
  },
  to: {
    backgroundPosition: '0%',
  },
});

const FAQCategoryContainer = styled('div', {
  padding: '0.75rem 1rem',
  overflow: 'hidden',
  borderRadius: '$sm',
  transition: 'background 0.4s',

  p: {
    maxWidth: convertPixelToRem(134),
    color: '$fontColorPrimary',
    fontWeight: '$semibold',
  },

  '.faq-category-container__holder': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    transition: 'all 0.4s',
  },

  '@deviceLg': {
    flexShrink: 0,

    background: '$white',
    padding: '0.5rem 0.75rem',
    border: '1px solid $white',
    borderRadius: '$pill',
    boxShadow: '0px 1px 4px #00000033',
    transition: 'background 0.4s, border-color 0.4s',

    p: {
      maxWidth: 'unset',

      color: '$$backgroundColor',
      fontSize: '0.75rem',
      fontWeight: '$medium',
      whiteSpace: 'nowrap',
      transition: 'color 0.4s',
    },
  },

  '&:hover': {
    backgroundColor: '$$backgroundColorWithOpacityMobile',
    cursor: 'pointer',

    '.faq-category-container__holder': {
      transform: 'translateX(8px)',
    },

    '@deviceLg': {
      borderColor: '$$backgroundColor',
      background: '$$backgroundColorWithOpacityMobile',

      '.faq-category-container__holder': {
        transform: 'unset',
      },
    },
  },

  variants: {
    isActive: {
      true: {
        background:
          'linear-gradient(90deg, $$backgroundColorWithOpacityDesktop 0%, #FFFFFF00 40%)',
        backgroundSize: '150% 100%',
        animation: `${backgroundAnimation} 0.2s linear`,
        '.faq-category-container__holder': {
          transform: 'translateX(8px)',
        },

        '&:hover': {
          backgroundColor: '$transparent',
          cursor: 'initial',
        },

        '@deviceLg': {
          background: '$$backgroundColor',
          borderColor: '$$backgroundColor',

          p: {
            color: '$white',
          },

          '.faq-category-container__holder': {
            transform: 'unset',
          },

          '&:hover': {
            background: '$$backgroundColor',
            cursor: 'initial',
          },
        },
      },
    },
  },
});

const FAQCategoryIcon = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,

  backgroundColor: '$$backgroundColor',
  height: '3rem',
  width: '3rem',
  padding: '0.75rem 0',
  borderRadius: '$sm',

  img: {
    height: '100%',
    maxWidth: convertPixelToRem(36),
    objectFit: 'contain',
  },

  '@deviceLg': {
    display: 'none',
  },
});
