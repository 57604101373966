import { styled } from '@src/themes';

export const ComboCartPageHolder = styled('div', {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'auto 22rem',
  gap: '2rem',
  alignItems: 'flex-start',

  padding: '2rem 0',

  hgroup: {
    marginBottom: '2rem',
  },

  '@deviceLg': {
    gridTemplateColumns: 'auto',
  },
});
