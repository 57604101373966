import { AdvantagesItem } from '@components/advantages-item';
import { Skeleton } from '@components/skeletons';
import { Title } from '@elements/title';

import {
  PRODUCTS_INDIVIDUAL_PAGE_INFORMATION,
  productHasLightBackground,
} from '@config/product-page-config';
import { ProductType } from '@extra-types/product-type';
import { removeSpecialCaracters } from '@helpers/function-helpers';

import { ProductAdvantagesContainer, ProductAdvantagesHolder } from './styles';

function ProductAdvantagesDefaultLoading() {
  return (
    <ProductAdvantagesContainer>
      <ProductAdvantagesHolder>
        <Skeleton.Title />
        <Skeleton.BigBox />
        <Skeleton.BigBox />
      </ProductAdvantagesHolder>
    </ProductAdvantagesContainer>
  );
}

function ProductAdvantagesDefault({
  productData,
}: {
  productData: ProductType;
}) {
  return (
    <ProductAdvantagesContainer
      css={{
        $$productColor: `$colors${productData.colorPrefixToken}ColorName`,
      }}
    >
      <ProductAdvantagesHolder>
        <Title.Root
          fontColorVariant="primary"
          barColor={productData.colorPrefixToken}
          barColorVariant="secondary"
          hasLightBackground={productHasLightBackground(productData.id)}
        >
          <Title.Default tag="h2">
            {productData.name} ainda traz muito mais vantagens
          </Title.Default>
        </Title.Root>
        {PRODUCTS_INDIVIDUAL_PAGE_INFORMATION[
          productData.id
        ].advantagesData.map(advantage => (
          <AdvantagesItem
            key={removeSpecialCaracters(advantage.title)}
            color={productData.colorPrefixToken}
            title={advantage.title}
            image={{
              url: advantage.image.url,
              alt: advantage.image.alt,
              position: advantage.image.position,
            }}
            advantagesList={advantage.list}
            hasLightBackground={productHasLightBackground(productData.id)}
          />
        ))}
      </ProductAdvantagesHolder>
    </ProductAdvantagesContainer>
  );
}

export const ProductAdvantages = {
  Default: ProductAdvantagesDefault,
  Loading: ProductAdvantagesDefaultLoading,
};
