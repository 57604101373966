import { CartPageHCMSimplified } from '@compositions/cart-page-hcm/simplified/cart-page-hcm-simplified';
import PrecificationTable from '@compositions/table-compositions/individual-table';
import WalletTable from '@compositions/table-compositions/wallet-table';

import { purchaseTableAdapter } from '@helpers/adapters/purchase-table-adapter';
import { ModalitiesId } from '@helpers/enum/modalities-id';

import { useSelector } from '@hooks/use-selector';

export function TableSelector() {
  const { cartModality, cartPricingData, isCartLoading } = useSelector(
    ({ cart }) => cart,
  );
  if (!cartPricingData || !cartModality) return null;

  if (cartModality.id === ModalitiesId.HCM) {
    return <CartPageHCMSimplified />;
  }

  if (cartModality.isWalletGroupCard) {
    return (
      <WalletTable
        tableData={purchaseTableAdapter(cartPricingData, cartModality)}
        isLoading={isCartLoading}
      />
    );
  }

  return (
    <PrecificationTable
      tableData={purchaseTableAdapter(cartPricingData, cartModality)}
      isLoading={isCartLoading}
    />
  );
}
