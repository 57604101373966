import { HTMLAttributes } from 'react';

import { queryStringHelpButtonMapping } from '@config/dynatrace-config';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useChatContext } from '@hooks/use-chat-context';
import { useContactModalContext } from '@hooks/use-contact-modal-context';
import { useDynatraceTrackment } from '@hooks/use-dynatrace-trackment';

import { styled } from '@src/themes';

const HelpButtonContainer = styled('button', {
  zIndex: '$4',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',

  background: '$white',
  padding: '0.75rem 1.5rem',
  borderRadius: '$pill',
  cursor: 'pointer',
  transition: 'background 0.4s ease',

  color: '$primaryPure',

  '@deviceSm': {
    justifyContent: 'center',

    background: 'transparent',
    height: '2.75rem',
    width: '2.75rem',
    borderRadius: '$circular',
  },

  i: {
    transition: 'color 0.4s ease',

    fontSize: '1.375rem',

    '@deviceSm': {
      color: '$white',
    },
  },

  span: {
    transition: 'color 0.4s ease',

    fontSize: convertPixelToRem(14),
    fontWeight: '$medium',

    '@deviceSm': {
      display: 'none',
    },
  },

  '&:hover': {
    backgroundColor: '$secondaryPure',

    'i, span': {
      color: '$primaryDark',
    },

    '@deviceSm': {
      backgroundColor: 'transparent',

      i: {
        color: '$secondaryPure',
      },
    },
  },

  variants: {
    isCustomChannel: {
      true: {
        backgroundColor: '$primaryPure',

        'span, i': {
          color: '$headerBackgroundColor',
        },

        '&:hover': {
          backgroundColor: '$primaryDark',

          'span, i': {
            color: '$headerBackgroundColor',
          },
        },
      },
    },
  },
});

type ContactButtonProps = HTMLAttributes<HTMLButtonElement> & {
  isCustomChannel?: boolean;
};

export function HelpButton({
  isCustomChannel = false,
  ...props
}: ContactButtonProps) {
  const { handleContactModal } = useContactModalContext();
  const { setOpenChat } = useChatContext();
  const { dynatraceTrackment } = useDynatraceTrackment();

  return (
    <HelpButtonContainer
      id="btn-atendimento"
      type="button"
      onClick={() => {
        dynatraceTrackment(
          'help_button',
          queryStringHelpButtonMapping[window.location.pathname],
        );
        handleContactModal();
        setOpenChat(true);
      }}
      title="Atendimento"
      isCustomChannel={isCustomChannel}
      {...props}
    >
      <i className="fa-regular fa-comments-question" />
      <span>Ajuda</span>
    </HelpButtonContainer>
  );
}
