import { useMemo } from 'react';

import { styled } from '@src/themes';

interface StepProps {
  stepIndex: number;
  currentStepPosition: number;
}

export function Step({ stepIndex, currentStepPosition }: StepProps) {
  const variant = useMemo(() => {
    if (stepIndex === currentStepPosition) return 'active';
    if (stepIndex < currentStepPosition) return 'complete';
    return 'default';
  }, [currentStepPosition, stepIndex]);

  return <StepElement variant={variant} />;
}

export const StepElement = styled('div', {
  backgroundColor: '$white',
  height: '15px',
  width: '15px',
  borderRadius: '100%',
  border: '3px solid $disabledPure',
  transition: 'all 0.8s ease-in-out',

  '@deviceSm': {
    height: '0.875rem',
    width: '0.875rem',
  },

  variants: {
    variant: {
      default: {},
      active: {
        transform: 'scale(2)',
        transitionDelay: '0.6s',

        backgroundColor: '$secondaryPure',
        border: '4px solid $secondaryPure',
      },
      complete: {
        transitionDelay: '0.6s',

        border: '4px solid $secondaryPure',
        backgroundColor: '$secondaryPure',
      },
    },
  },
});
