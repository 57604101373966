import { createContext, useMemo, useState } from 'react';

import { VideoModal } from '@components/video-modal';

import { VideoDataType } from '@extra-types/video-data-type';

export interface VideoModalContextData {
  videoModalState: boolean;
  handleVideoModal: () => void;
  handleVideoData: (data: VideoDataType) => void;
}

export const VideoModalContext = createContext<VideoModalContextData>(
  {} as VideoModalContextData,
);

interface VideoModalContextProviderProps {
  children: React.ReactNode;
}

export function VideoModalContextProvider({
  children,
  ...props
}: VideoModalContextProviderProps) {
  const [videoModalState, setVideoModalState] = useState(false);
  const [videoData, setVideoData] = useState({} as VideoDataType);

  const handleVideoData = (data: VideoDataType) => {
    setVideoData(data);
  };

  const handleVideoModal = () => {
    setVideoModalState(state => !state);
  };

  const memorizeReturn = useMemo(
    () => ({
      videoModalState,
      handleVideoModal,
      handleVideoData,
    }),
    [videoModalState],
  );

  return (
    <VideoModalContext.Provider value={memorizeReturn} {...props}>
      {children}
      <VideoModal data={videoData} />
    </VideoModalContext.Provider>
  );
}
