import { useFormContext } from 'react-hook-form';

import emailValidate from '@vr/devkit/validators/emailValidate';

import { Input } from '@components/input';
import { CustomerData } from '@compositions/customer-modal';

import {
  ALLOW_ONLY_ALPHABETICS,
  CELLPHONE_MAX_LENGTH,
  CELLPHONE_MIN_LENGTH,
  EMAIL_REGEX,
  MAIL_MAX_LENGTH,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from '@config/input-config';
import { FormErrors } from '@helpers/enum/form-errors';
import { scrollToElement } from '@helpers/function-helpers';
import { cellphoneValidation } from '@helpers/input-helpers';

import { useSelector } from '@hooks/use-selector';

function ScenariosInputs() {
  const isLoading = useSelector(({ customer }) => customer.isLoading);
  const isCartLoading = useSelector(({ cart }) => cart.isCartLoading);
  const methods = useFormContext<CustomerData>();

  return (
    <>
      <Input.Default
        id="representante-nome"
        label="Nome do contato*"
        width="100%"
        css={{ width: '100%' }}
        placeholder="Digite seu nome completo"
        disabled={isLoading || isCartLoading}
        onFocus={() => scrollToElement('representante-nome')}
        {...methods.register('empresaRh.contato.nomeContato', {
          deps: 'empresaRh.cnpjRh',
          onChange: event => {
            methods.resetField('empresaRh.contato.nomeContato', {
              defaultValue: event.target.value.replace(
                ALLOW_ONLY_ALPHABETICS,
                '',
              ),
            });
          },
          minLength: {
            value: NAME_MIN_LENGTH,
            message: FormErrors.INVALID_NAME_MINLENGTH,
          },
          maxLength: {
            value: NAME_MAX_LENGTH,
            message: FormErrors.INVALID_NAME_MAXLENGTH,
          },
          required: FormErrors.REQUIRED_NAME,
        })}
        errorMessage={
          methods.formState.errors.empresaRh?.contato?.nomeContato?.message
        }
      />
      <Input.Default
        id="representante-email"
        label="E-mail*"
        width="100%"
        css={{ width: '100%' }}
        placeholder="Digite seu e-mail"
        disabled={isLoading || isCartLoading}
        {...methods.register('empresaRh.contato.emailContato', {
          deps: 'empresaRh.cnpjRh',
          onChange: event => {
            methods.resetField('empresaRh.contato.emailContato', {
              defaultValue: event.target.value.replace(EMAIL_REGEX, ''),
            });
          },
          validate: {
            value: (value: string) =>
              emailValidate(value) || FormErrors.INVALID_MAIL,
          },
          maxLength: {
            value: MAIL_MAX_LENGTH,
            message: FormErrors.INVALID_MAIL_MAXLENGTH,
          },
          required: FormErrors.REQUIRED_MAIL,
        })}
        errorMessage={
          methods.formState.errors.empresaRh?.contato?.emailContato?.message
        }
      />
      <Input.Default
        id="representante-telefone"
        label="Telefone para contato*"
        width="100%"
        customMask="(99) 99999-9999"
        css={{ width: '100%' }}
        placeholder="(xx) xxxxx-xxxx"
        disabled={isLoading || isCartLoading}
        inputMode="numeric"
        {...methods.register('empresaRh.contato.telefoneContato', {
          deps: 'empresaRh.cnpjRh',
          validate: {
            value: value =>
              cellphoneValidation(value) || FormErrors.INVALID_CELLPHONE,
          },
          minLength: {
            value: CELLPHONE_MIN_LENGTH,
            message: FormErrors.INVALID_CELLPHONE,
          },
          maxLength: {
            value: CELLPHONE_MAX_LENGTH,
            message: FormErrors.INVALID_CELLPHONE,
          },
          required: FormErrors.REQUIRED_CELLPHONE,
        })}
        errorMessage={
          methods.formState.errors.empresaRh?.contato?.telefoneContato?.message
        }
      />
    </>
  );
}

export default ScenariosInputs;
