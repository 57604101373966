/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AdditionalProductsType,
  AdditionalProductType,
} from '@extra-types/additional-product-type';
import { ComboCartType } from '@extra-types/combo-cart-type';
import { ModalityType } from '@extra-types/modality-type';
import { PricingType } from '@extra-types/pricing-types';
import {
  AdditionalProductsCartType,
  PlanTypeEnum,
  ProductCartType,
} from '@extra-types/product-cart-type';
import { ProductContractedType } from '@extra-types/product-contracted-type';
import { ProductInterest } from '@extra-types/product-interest-type';
import { RemovedProductType } from '@extra-types/removed-product-type';
import { SvaCartType } from '@extra-types/sva-cart-type';
import {
  sendEventAddProductToCartToAnalytics,
  sendEventRemoveProductFromCartToAnalytics,
} from '@helpers/analytics-helpers';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { isComboFlux } from '@helpers/flux-helpers';
import { addFixedTaxOnPricingResponse } from '@helpers/pricing-helpers';
import {
  convertRemovedProductPricingListToRemovedProductList,
  formatProductContractedResponseToProductContracted,
} from '@helpers/product-helpers';

import {
  fetchCartPricing,
  fetchPricingWithoutLead,
} from '@store/thunks/cart-thunk';

export type CartType = {
  cartModalState: boolean;
  productCartList: ProductCartType[];
  requiredAdditionalProductList: AdditionalProductsType;
  extraAdditionalProductList: AdditionalProductType[];
  productComboCartList: ComboCartType;
  svaCartList: SvaCartType[];
  productInterestCartList: ProductInterest[];
  cartModality?: ModalityType;
  isCartLoading: boolean;
  cartPricingData?: PricingType;
  extensionPricingData?: PricingType;
  cartPricingRemovedProductsList: RemovedProductType[];
  comboCartPricingData?: PricingType;
  comboCartPricingRemovedProductsList: RemovedProductType[];
  contractedProducts: ProductContractedType[];
  planType: PlanTypeEnum;
};

export const initialState = {
  cartModalState: false,
  productCartList: [],
  requiredAdditionalProductList: { required: [], unrequired: [] },
  extraAdditionalProductList: [],
  productComboCartList: {},
  svaCartList: [],
  productInterestCartList: [],
  cartPricingData: undefined,
  cartPricingRemovedProductsList: [],
  comboCartPricingData: undefined,
  comboCartPricingRemovedProductsList: [],
  cartModality: undefined,
  isCartLoading: false,
  contractedProducts: [],
  planType: PlanTypeEnum.MENSAL,
} as CartType;

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartModalState: (state, action: PayloadAction<boolean>) => {
      state.cartModalState = action.payload;
    },
    toggleCartModalState: state => {
      state.cartModalState = !state.cartModalState;
    },
    toggleCartLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isCartLoading = action.payload;
    },
    setCartModality: (state, action: PayloadAction<ModalityType>) => {
      state.cartModality = action.payload;
    },
    setPlanType: (state, action: PayloadAction<PlanTypeEnum>) => {
      state.planType = action.payload;
    },
    addProductToCart: (state, action: PayloadAction<ProductCartType>) => {
      const productCartIndex = state.productCartList.findIndex(
        product => product.id === action.payload.id,
      );

      if (productCartIndex === -1) {
        state.productCartList.push(action.payload);
      } else {
        state.productCartList[productCartIndex] = action.payload;
      }

      sendEventAddProductToCartToAnalytics({
        productIndex: state.productCartList.findIndex(
          product => product.id === action.payload.id,
        ),
        productCart: action.payload,
      });
    },
    addExtraAdditionalProductToCart: (
      state,
      action: PayloadAction<AdditionalProductsCartType>,
    ) => {
      state.productCartList.forEach(plan => {
        if (plan.additionalProducts) {
          plan.additionalProducts?.push(action.payload);
        } else {
          plan.additionalProducts = [action.payload];
        }
      });
    },
    addPlanToCart: (state, action: PayloadAction<ProductCartType>) => {
      state.productCartList = [action.payload];
    },
    addAdditionalProducts: (
      state,
      action: PayloadAction<AdditionalProductsType>,
    ) => {
      state.requiredAdditionalProductList = action.payload;
    },
    addExtraAdditionalProductToList: (
      state,
      action: PayloadAction<AdditionalProductType>,
    ) => {
      const productCartIndex = state.extraAdditionalProductList.findIndex(
        product => product.id === action.payload.id,
      );

      if (productCartIndex === -1) {
        state.extraAdditionalProductList.push(action.payload);
      } else {
        state.extraAdditionalProductList[productCartIndex] = action.payload;
      }
    },
    removeAdditionalProductFromCart: (state, action: PayloadAction<number>) => {
      state.extraAdditionalProductList =
        state.extraAdditionalProductList.filter(
          product => product.id !== action.payload,
        );

      state.productCartList = state.productCartList.map(product => {
        product.additionalProducts = product.additionalProducts?.filter(
          additionalProduct => additionalProduct.id !== action.payload,
        );

        return product;
      });
    },
    resetAdditionalProductCart: state => {
      state.extraAdditionalProductList = [];
    },
    addProductToComboCartList: (
      state,
      action: PayloadAction<{
        modalityId: number;
        product: ProductCartType;
      }>,
    ) => {
      const productModalityComboCartList =
        state.productComboCartList[action.payload.modalityId];

      if (!productModalityComboCartList) {
        state.productComboCartList = {
          ...state.productComboCartList,
          [action.payload.modalityId]: [action.payload.product],
        };
      } else {
        const productCartIndex = productModalityComboCartList.findIndex(
          product => product.id === action.payload.product.id,
        );

        if (productCartIndex === -1) {
          state.productComboCartList[action.payload.modalityId].push(
            action.payload.product,
          );
        } else {
          state.productComboCartList[action.payload.modalityId][
            productCartIndex
          ] = action.payload.product;
        }
      }

      sendEventAddProductToCartToAnalytics({
        productIndex: state.productCartList.findIndex(
          product => product.id === action.payload.product.id,
        ),
        productCart: action.payload.product,
      });
    },
    addSvaToCart: (state, action: PayloadAction<number>) => {
      const isSvaInList = state.svaCartList.find(
        sva => sva.id === action.payload,
      );
      if (!isSvaInList) {
        state.svaCartList.push({
          id: action.payload,
        });
      }
    },
    removeSvaFromCart: (state, action: PayloadAction<number>) => {
      const svaIndex = state.svaCartList.findIndex(
        sva => sva.id === action.payload,
      );

      if (svaIndex !== -1) {
        const svaToRemove = state.svaCartList[svaIndex];
        state.svaCartList = state.svaCartList.filter(
          sva => sva.id !== svaToRemove.id,
        );
      }
    },
    removeAllSvaFromCart: state => {
      state.svaCartList = [];
    },
    addProductListToComboCart: (
      state,
      action: PayloadAction<{
        modalityId: number;
        productList: ProductCartType[];
      }>,
    ) => {
      const productsWithSVAs = [
        ...action.payload.productList,
        ...state.svaCartList.map(
          sva =>
            ({
              id: sva.id,
              value: 1,
              amount: 1,
              type: ServiceGroupId.SVA,
              planType: PlanTypeEnum.MENSAL,
            } as ProductCartType),
        ),
      ];
      state.productComboCartList = {
        ...state.productComboCartList,
        [action.payload.modalityId]: productsWithSVAs,
      };

      action.payload.productList.forEach((product, index) => {
        sendEventAddProductToCartToAnalytics({
          productIndex: index,
          productCart: product,
        });
      });
    },
    addProductListToComboCartPricingRemovedProductsList: (
      state,
      action: PayloadAction<RemovedProductType[]>,
    ) => {
      state.comboCartPricingRemovedProductsList.push(...action.payload);
    },
    removeProductFromCart: (
      state,
      action: PayloadAction<{ productId: number }>,
    ) => {
      const { productId } = action.payload;
      const productIndexToRemove = state.productCartList.findIndex(
        product => product.id === productId,
      );

      if (productIndexToRemove !== -1) {
        const productToRemove = state.productCartList[productIndexToRemove];
        sendEventRemoveProductFromCartToAnalytics({
          productIndex: productIndexToRemove,
          productToRemove,
        });
        state.productCartList = state.productCartList.filter(
          product => product.id !== productToRemove.id,
        );
      }

      if (state.productCartList.length === 0) {
        state.cartModality = undefined;
      }
    },
    removeExtensionFromCart: (
      state,
      action: PayloadAction<{ productId: number }>,
    ) => {
      const { productId } = action.payload;
      const productIndexToRemove = state.productCartList.findIndex(
        product => product.id === productId,
      );

      if (productIndexToRemove !== -1) {
        const productToRemove = state.productCartList[productIndexToRemove];
        state.productCartList = state.productCartList.filter(
          product => product.id !== productToRemove.id,
        );
      }

      if (state.productCartList.length === 0) {
        state.cartModality = undefined;
      }
    },
    removeProductFromComboCartList: (
      state,
      action: PayloadAction<{
        modalityId: number;
        productId: number;
      }>,
    ) => {
      const { modalityId, productId } = action.payload;
      const productModalityComboCartList =
        state.productComboCartList[modalityId];

      if (productModalityComboCartList) {
        const productIndexToRemove = productModalityComboCartList.findIndex(
          product => product.id === productId,
        );

        if (productIndexToRemove !== -1) {
          const productToRemove =
            productModalityComboCartList[productIndexToRemove];
          sendEventRemoveProductFromCartToAnalytics({
            productIndex: productIndexToRemove,
            productToRemove,
          });

          const newProductComboCartList = productModalityComboCartList.filter(
            product => product.id !== productToRemove.id,
          );

          state.productComboCartList = {
            ...state.productComboCartList,
            [modalityId]: newProductComboCartList,
          };
        }
      }
    },
    removeProductListFromComboCartList: (
      state,
      action: PayloadAction<
        {
          modalityId: number;
          productId: number;
        }[]
      >,
    ) => {
      const productToRemoveList = action.payload;
      const newProductComboCartList = { ...state.productComboCartList };

      productToRemoveList.forEach(({ modalityId, productId }) => {
        const productModalityComboCartList =
          newProductComboCartList[modalityId];

        if (productModalityComboCartList) {
          const indexToRemove = productModalityComboCartList.findIndex(
            product => product.id === productId,
          );

          if (indexToRemove !== -1) {
            sendEventRemoveProductFromCartToAnalytics({
              productIndex: indexToRemove,
              productToRemove:
                newProductComboCartList[modalityId][indexToRemove],
            });
            newProductComboCartList[modalityId] =
              productModalityComboCartList.filter(
                product => product.id !== productId,
              );
          }
        }
      });

      state.productComboCartList = { ...newProductComboCartList };
    },
    updateProductCartList: (
      state,
      action: PayloadAction<ProductCartType[]>,
    ) => {
      state.productCartList = action.payload;
    },
    clearCart: state => {
      state.cartModality = initialState.cartModality;
      state.cartPricingData = initialState.cartPricingData;
      state.cartPricingRemovedProductsList =
        initialState.cartPricingRemovedProductsList;
      state.comboCartPricingData = initialState.comboCartPricingData;
      state.comboCartPricingRemovedProductsList =
        initialState.comboCartPricingRemovedProductsList;
      state.productCartList = initialState.productCartList;
      state.productComboCartList = initialState.productComboCartList;
      state.productInterestCartList = initialState.productInterestCartList;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPricingWithoutLead.pending, state => {
        state.isCartLoading = true;
      })
      .addCase(fetchPricingWithoutLead.fulfilled, (state, action) => {
        if (action.payload.precificacao) {
          const precificationData = addFixedTaxOnPricingResponse(
            action.payload.precificacao,
          );

          state.cartPricingData = { ...precificationData };
          if (action.payload.produtosContratados) {
            state.contractedProducts =
              formatProductContractedResponseToProductContracted(
                action.payload.produtosContratados,
              );
          }
        }
        state.isCartLoading = false;
      })
      .addCase(fetchPricingWithoutLead.rejected, state => {
        state.isCartLoading = false;
      })
      .addCase(fetchCartPricing.pending, state => {
        state.isCartLoading = true;
      })
      .addCase(fetchCartPricing.fulfilled, (state, action) => {
        if (action.payload.precificacao) {
          const precificationData = addFixedTaxOnPricingResponse(
            action.payload.precificacao,
          );

          if (isComboFlux(action.meta.arg.offerData)) {
            state.comboCartPricingData = {
              ...precificationData,
            };
          } else {
            state.cartPricingData = { ...precificationData };
            state.cartPricingRemovedProductsList.push(
              ...convertRemovedProductPricingListToRemovedProductList(
                action.payload.produtosRemovidos,
              ),
            );
          }
        }

        if (action.payload.produtosContratados) {
          state.contractedProducts =
            formatProductContractedResponseToProductContracted(
              action.payload.produtosContratados,
            );
        }
        state.isCartLoading = false;
      })
      .addCase(fetchCartPricing.rejected, state => {
        state.isCartLoading = false;
      });
  },
});

export const {
  setCartModalState,
  toggleCartModalState,
  toggleCartLoadingState,
  setCartModality,
  setPlanType,
  addProductToCart,
  addExtraAdditionalProductToCart,
  addPlanToCart,
  addAdditionalProducts,
  addExtraAdditionalProductToList,
  removeAdditionalProductFromCart,
  resetAdditionalProductCart,
  addProductToComboCartList,
  addSvaToCart,
  removeSvaFromCart,
  removeAllSvaFromCart,
  addProductListToComboCart,
  addProductListToComboCartPricingRemovedProductsList,
  removeExtensionFromCart,
  removeProductFromCart,
  removeProductFromComboCartList,
  removeProductListFromComboCartList,
  updateProductCartList,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
