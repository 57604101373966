import { ReactNode } from 'react';

import { CSS } from '@stitches/react';

import {
  AllTokenVariantsType,
  DefaultTokenVariantsType,
} from '@extra-types/token-variants-type';

import { config } from '@src/themes';

import {
  TitleRootContainer,
  TitleSubtitleContainer,
  TitleDefaultContainer,
  TitleFlagContainer,
} from './styles';

interface TitleRootProps {
  children: ReactNode;
  size?: 'md' | 'sm';
  fontColorVariant?: DefaultTokenVariantsType | 'walletFontColor';
  barColor?: AllTokenVariantsType;
  barColorVariant?: DefaultTokenVariantsType;
  multi?: boolean;
  hasLightBackground?: boolean;
}

function TitleRoot({
  children,
  size,
  fontColorVariant = 'primary',
  barColor = 'primary',
  barColorVariant = 'primary',
  multi = false,
  hasLightBackground = false,
}: TitleRootProps) {
  return (
    <TitleRootContainer
      color={fontColorVariant}
      barColor={barColor}
      barColorVariant={barColorVariant}
      size={size}
      multi={multi}
      hasLightBackground={hasLightBackground}
    >
      {children}
    </TitleRootContainer>
  );
}

interface TitleDefaultProps {
  children: ReactNode;
  tag: 'h1' | 'h2' | 'h3';
  size?: 'lg' | 'md' | 'sm';
  css?: CSS<typeof config>;
}

function TitleDefault({ tag, size, css, children }: TitleDefaultProps) {
  return (
    <TitleDefaultContainer as={tag} size={size} css={css}>
      {children}
    </TitleDefaultContainer>
  );
}

interface TitleSubtitleProps {
  children: ReactNode;
  css?: CSS<typeof config>;
  className?: string;
}

function TitleSubtitle({ css, children, className }: TitleSubtitleProps) {
  return (
    <TitleSubtitleContainer css={css} className={className}>
      {children}
    </TitleSubtitleContainer>
  );
}

interface TitleFlagProps {
  children: ReactNode;
}

function TitleFlag({ children }: TitleFlagProps) {
  return <TitleFlagContainer>{children}</TitleFlagContainer>;
}

export const Title = {
  Root: TitleRoot,
  Default: TitleDefault,
  Flag: TitleFlag,
  Subtitle: TitleSubtitle,
};
