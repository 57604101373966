import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const ModalContainer = styled('div', {
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.7)',
  zIndex: '$9',
  overscrollBehavior: 'contain',
  backdropFilter: 'blur(4px)',

  '@deviceSm': {
    alignItems: 'flex-end',
  },

  '&.modal-enter': {
    opacity: '0',

    '.modal-box__container': {
      transform: 'translateY(100%)',
    },
  },

  '&.modal-enter-active': {
    opacity: '1',
    transition: 'opacity 0.4s',

    '.modal-box__container': {
      transform: 'translateY(0)',
      transition: 'transform 0.4s',
    },
  },

  '&.modal-exit': {
    opacity: '1',

    '.modal-box__container': {
      transform: 'translateY(0)',
    },
  },

  '&.modal-exit-active': {
    opacity: '0',
    transition: 'opacity 0.4s',

    '.modal-box__container': {
      transform: 'translateY(100%)',
      transition: 'transform 0.4s',
    },
  },

  '.modal-box__container': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    gap: '2rem',
    backgroundColor: '$white',
    borderRadius: '10px',
    padding: '3rem 1rem 2rem',
    width: '445px',

    h2: {
      fontSize: '1.625rem',
      fontWeight: '$semibold',
      textAlign: 'center',
    },

    p: {
      fontSize: '1rem',
      textAlign: 'center',
      fontWeight: '$medium',
      lineHeight: 1.6,
      margin: '0 0.75rem',
    },

    '.modal-box__action-group': {
      display: 'flex',
      flexDirection: '$$buttonOrder',
      gap: '0.75rem',
    },

    '@deviceSm': {
      width: '100%',
      maxWidth: '100%',
      maxHeight: '95vh',
      borderRadius: '10px 10px 0 0',

      p: {
        fontSize: convertPixelToRem(14),
      },
    },
  },
});

export const SymbolModalVariant = styled('div', {
  position: 'absolute',
  top: '0',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: convertPixelToRem(64),
  height: convertPixelToRem(64),
  borderRadius: '$circular',

  '&:before': {
    content: '',
    display: 'flex',
    alignItems: 'center',

    color: '$white',
    fontFamily: '$fontAwesome',
    fontSize: '1.5rem',
    fontWeight: '$light',
    lineHeight: 0,
  },

  variants: {
    variant: {
      error: {
        background: '$error',
        '&:before': {
          content: String.raw`\f00d`,
        },
      },
      warning: {
        background: '$warning',
        '&:before': {
          content: String.raw`\f071`,
        },
      },
      maintenance: {
        background: '$warning',
        '&:before': {
          content: String.raw`\f7d9`,
          fontWeight: '$regular',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'warning',
  },
});
