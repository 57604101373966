import { PageContainer } from '@elements/page-container';

import {
  convertPixelToRem,
  holderStyleWithMobileResponsive,
} from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const ComboOrderRevisionPageContainer = styled(PageContainer, {
  ...holderStyleWithMobileResponsive,

  flex: 1,

  width: '100%',
  paddingTop: '0 !important',

  span: {
    fontSize: '0.75rem',
  },

  '.order-revision-page-container__order-revision-service-data-group': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },

  '.order-revision-page-container__total-order-wrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '1.5rem 0 0.5rem',

    fontSize: '0.75rem',
    fontWeight: '$bold',

    strong: {
      color: '$primaryPure',
      fontSize: '1rem',
    },
  },

  '.order-revision-page-container__payment-warning-message': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',

    i: {
      color: '#FFC600',
      fontSize: '1rem',
    },

    span: {
      fontSize: '0.75rem',
      fontWeight: '$medium',
    },
  },

  '.order-revision-page-container__buttons-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.25rem',
    margin: '3rem 0px',

    width: '100%',

    'button, a': {
      width: convertPixelToRem(240),
    },

    '@deviceSm': {
      flexDirection: 'column-reverse',

      'button, a': {
        width: '100%',
      },
    },
  },

  '@deviceSm': {
    padding: '0 1rem',
  },
});
