import React from 'react';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ProceedToCartButton } from '@components/proceed-to-cart-button';
import { ProductNameText } from '@elements/product-name-text';

import { env } from '@config/env';
import { getProductData } from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

import {
  InformationPhrase,
  ProductRow,
  SelectedProductHolder,
  SelectedWalletProductsContent,
} from './styles';

type SelectedWalletProductsProps = {
  setEnableEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

function Content({ setEnableEdit }: SelectedWalletProductsProps) {
  const { productCartList, cartModality } = useSelector(({ cart }) => cart);
  const { contractedProducts } = useSelector(({ customer }) => customer);
  const { productList } = useSelector(({ products }) => products);
  const filteredProductCartList = productCartList.filter(
    product =>
      !contractedProducts.some(
        contractedProduct => contractedProduct.id === product.id,
      ),
  );

  const calcSubTotal = () => {
    const subTotal = [0];
    let amount = 0;
    if (productCartList) {
      productCartList.forEach(product => {
        subTotal.push(product.amount * product.value);
        amount = product.amount;
      });
      return {
        subTotal: subTotal.reduce((prev, current) => prev + current),
        amount,
      };
    }
    return { subTotal: 0, amount: 0 };
  };

  if (cartModality === undefined) {
    return null;
  }

  return (
    <SelectedWalletProductsContent>
      <div className="is-on-cart-content mobile">
        <i className="fa-solid fa-check-circle" />
        <InformationPhrase>Produto adicionado!</InformationPhrase>
      </div>
      <div className="products-content">
        <div className="products-row">
          <p>Quantidade</p>
          <p>{calcSubTotal().amount}</p>
        </div>
        {filteredProductCartList.map(productCart => {
          const productData = getProductData(productCart.id, productList);
          if (!productData) {
            return null;
          }
          return (
            <ProductRow
              key={productCart.id}
              css={{
                '.product-name': {
                  fontWeight: 'bolder',
                },
              }}
            >
              <p className="product-name">
                <img
                  src={env.URL_ASSETS_CHANNEL + productData.logoPath}
                  width={35}
                  alt={`logotipo do produto ${productData.name}`}
                />
                <ProductNameText id={productCart.id} />
              </p>
              <p>{formatValueToCurrency(productCart.value)}</p>
            </ProductRow>
          );
        })}
      </div>

      <SelectedProductHolder>
        <div className="is-on-cart-content desktop">
          <i className="fa-solid fa-check-circle" />
          <InformationPhrase>Produto adicionado!</InformationPhrase>
        </div>

        <div>
          <p className="subtotal-title">Subtotal</p>
          <p>
            <span className="subtotal-value">
              {formatValueToCurrency(calcSubTotal().subTotal)}
            </span>
          </p>
        </div>
        <ProceedToCartButton id={`botao-seguir-compra-${cartModality.keyId}`} />
        <button
          id={`botao-editar-produtos-${cartModality.keyId}`}
          type="button"
          className="edit-button"
          onClick={() => setEnableEdit(true)}
        >
          Editar
        </button>
      </SelectedProductHolder>
    </SelectedWalletProductsContent>
  );
}

function Header() {
  return (
    <div className="is-on-cart-flag">
      <i className="fa-solid fa-cart-shopping" />
    </div>
  );
}

export const SelectedWalletProducts = {
  Content,
  Header,
};
