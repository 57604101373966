/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ModalVariant = 'success' | 'error' | 'alert' | 'neutral';

type ModalType = {
  state: boolean;
  variant?: ModalVariant;
  title: string;
  content?: string;
  confirmationButton?: () => void;
  denyButton?: () => void;
};

export const initialState = {
  state: false,
  variant: 'neutral',
  title: '',
  content: '',
} as ModalType;

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    handleModalContent: (state, action: PayloadAction<ModalType>) => {
      state.state = action.payload.state;
      state.title = action.payload.title;
      state.content = action.payload.content;
      state.confirmationButton = action.payload.confirmationButton;
      state.denyButton = action.payload.denyButton;
    },
    closeModal: state => {
      state.state = false;
    },
  },
});

export const { closeModal, handleModalContent } = modalSlice.actions;

export default modalSlice.reducer;
