import { useEffect, useRef } from 'react';

import { ComboSummaryGeneralFormButton } from '@pages/offer-page/custom-offer-pages/elements/combo-cart-summary-elements/combo-summary-general-form-button';

import { styled } from '@src/themes';

interface ComboServiceFormFooterAreaProps {
  isCollapsed: boolean;
  extraDisableCondition?: boolean;
}

export function ComboServiceFormFooterArea({
  isCollapsed,
  extraDisableCondition,
}: ComboServiceFormFooterAreaProps) {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('stuck', e.intersectionRatio < 1),
      { threshold: [1] },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, isCollapsed]);

  if (isCollapsed) {
    return null;
  }

  return (
    <ComboServiceFormFooterAreaContainer ref={ref}>
      <div
        data-testid="background-element"
        className="combo-service-form-submit-button-container__background"
      >
        <ComboSummaryGeneralFormButton
          extraDisableCondition={extraDisableCondition}
        />
      </div>
    </ComboServiceFormFooterAreaContainer>
  );
}

const ComboServiceFormFooterAreaContainer = styled('div', {
  position: 'sticky',
  zIndex: '$1',
  bottom: '-1px',

  background: 'transparent',
  width: '100%',
  marginTop: '1.25rem',

  '&.stuck': {
    '.combo-service-form-submit-button-container__background': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '1rem',
      alignItems: 'center',
      border: '1px solid #E4E8ED',
      borderRadius: '0.5rem 0.5rem 0 0',
    },
  },

  button: {
    minWidth: '226px',
    whiteSpace: 'nowrap',
  },

  '.combo-service-form-submit-button-container__background': {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 1rem',
    background: 'rgba(255, 255, 255, 0.9)',
    width: '100%',

    p: {
      fontWeight: '600',
    },
  },

  '@deviceLg': {
    display: 'none',
  },
});
