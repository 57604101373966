import { HTMLAttributes } from 'react';

import { styled } from '@stitches/react';

import { ProductShelf } from '@components/product-shelf';
import { Skeleton } from '@components/skeletons';

import { ProductsId } from '@helpers/enum/products-id';

import { useProductsShelf } from '@hooks/use-products-shelf';

export function ProductsShelf({ ...props }: HTMLAttributes<HTMLDivElement>) {
  const { productsShelfList, isLoading } = useProductsShelf({
    loadEcosystemProducts: true,
    showProductsOnSingleModality: true,
    productsToDelete: [ProductsId.HCM_CORPORATIVO],
  });

  if (isLoading) {
    return (
      <Skeleton.SectionContainer noPadding>
        <Skeleton.GroupContainer guidance="horizontal">
          <Skeleton.Modality />
          <Skeleton.Modality />
        </Skeleton.GroupContainer>
        <Skeleton.GroupContainer guidance="horizontal">
          <Skeleton.Modality />
          <Skeleton.Modality />
        </Skeleton.GroupContainer>
      </Skeleton.SectionContainer>
    );
  }

  return (
    <ProductsShelfContainerStyle {...props}>
      {productsShelfList.map(product => (
        <ProductShelf key={product.keyId} data={product} />
      ))}
    </ProductsShelfContainerStyle>
  );
}

const ProductsShelfContainerStyle = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '1.5rem',

  '@media only screen and (max-width: 720px)': {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
});
