import { useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { CartPageBonusItem } from '@components/cart-page-product-list-form-components/cart-page-bonus-item';
import { SvaCartDetails } from '@components/sva-cart-details';
import { ComboServiceDisabledForm } from '@pages/offer-page/custom-offer-pages/components/combo-summary-components/combo-service-disabled-form';
import { ComboServiceFormFooterArea } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-footer-area';

import { ComboServiceFormType } from '@extra-types/combo-service-form-type';
import { createPricingBonusList } from '@helpers/cart-helpers';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { createComboModalityFormId } from '@helpers/service-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useSelector } from '@hooks/use-selector';

type SvaListFormType = {
  svaList: string[];
};

export function ComboBonusServiceForm({
  isFormActive,
  handleNextForm,
}: Omit<ComboServiceFormType, 'modalityData'>) {
  const { modalitiesForSale, isFormEnable } = useComboCartPageContext();
  const { comboCartPricingData } = useSelector(({ cart }) => cart);
  const bonusList = useMemo(
    () =>
      comboCartPricingData ? createPricingBonusList(comboCartPricingData) : [],
    [comboCartPricingData],
  );

  const findBenefitModality = () => {
    const modality = modalitiesForSale.filter(
      ({ id }) => id !== ModalitiesId.HCM,
    );
    return modality[0];
  };

  const methods = useForm<SvaListFormType>({
    mode: 'onChange',
    defaultValues: {
      svaList: [],
    },
  });

  const formID = createComboModalityFormId(ServiceGroupId.SVA);

  const onSubmit: SubmitHandler<SvaListFormType> = async () => {
    const isFormValid = await methods.trigger(['svaList']);
    if (isFormValid) handleNextForm();
  };

  if (!isFormEnable(formID)) {
    return <ComboServiceDisabledForm isSVA />;
  }

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(onSubmit)}>
        <SvaCartDetails
          modalityForSVA={findBenefitModality().id}
          isCollapsed={isFormActive}
        />
        {bonusList.map(bonusItem => (
          <CartPageBonusItem key={bonusItem.id} data={bonusItem} />
        ))}
      </form>
      <ComboServiceFormFooterArea
        isCollapsed={!isFormActive}
        extraDisableCondition={!methods.formState.isValid}
      />
    </FormProvider>
  );
}
