export interface MobilityOfferDescriptionType {
  id: string;
  title: string;
  description: string;
  base: number | string;
  totalValue: number | string;
}

export const MobilityOfferDescription: MobilityOfferDescriptionType[] = [
  {
    id: 'tarifa-local-atendimento',
    title: 'Tarifa por local de atendimento',
    description: 'Tarifa por local de atendimento',
    base: 0,
    totalValue: 0,
  },
  {
    id: 'gestao-vt',
    title: 'Gestão de Vale-Transporte',
    description: 'Tarifa de serviço da ferramenta de Gestão de Vale-Transporte',
    base: 0.04,
    totalValue: 1.04,
  },
  {
    id: 'taxa-repasse',
    title: 'Taxa de Repasse',
    description:
      'Tarifa que pode ser emitida pelas operadoras de cartões de acordo com sua região',
    base: '- variavel -',
    totalValue: '- variavel -',
  },
  {
    id: 'gestao-saldo',
    title: 'Gestão de Saldo',
    description: 'Tarifa por gestão de saldo automática',
    base: 0,
    totalValue: 0,
  },
];
