import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const CartHolder = styled('div', {
  zIndex: '$4',
});

export const CartButton = styled('button', {
  position: 'relative',

  background: 'transparent',
  outline: 'none',
  transition: 'color 0.4s ease',

  color: '$white',

  i: {
    fontSize: '2rem',
  },

  '.cart-button__badge': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '-10px',
    right: 0,

    background: '$error',
    height: convertPixelToRem(18),
    minWidth: convertPixelToRem(18),
    borderRadius: '$circular',

    color: '$white',
    fontSize: convertPixelToRem(10),
    fontWeight: '$medium',
    lineHeight: 1,
  },

  '&:hover': {
    color: '$secondaryPure',
  },

  '@deviceSm': {
    i: {
      fontSize: '1.375rem',
    },

    'span.cart-button__badge': {
      right: '-5px',
    },
  },

  variants: {
    isCustomChannel: {
      true: {
        color: '$primaryPure',

        '&:hover': {
          color: '$primaryDark',
        },
      },
    },
  },
});
