import { useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import OfferTableDescription from '@components/offer-table-description';
import { PageTitle } from '@components/page-title';
import AddressInputs from '@compositions/address-inputs';
import { CartPageMobilityProductListForm } from '@compositions/cart-page-mobility-product-list-form';
import { CompanyNameInput } from '@compositions/company-name-input';
import { ContactInputs } from '@compositions/contact-inputs';
import { ContractModalPreview } from '@compositions/contract-modal-preview';
import { RegisterInputs } from '@compositions/register-inputs';
import { Button } from '@elements/button';
import { FormWarning } from '@elements/form-warning';
import { RegisterSection } from '@elements/register-section';
import { Title } from '@elements/title';

import { MobilityOfferDescription } from '@config/mobility-config';
import { CompanyType } from '@extra-types/company-type';
import { calcTotalValueCartByModality } from '@helpers/cart-helpers';
import { ApiErrorsModalTitle } from '@helpers/enum/api-error-messages';
import { Contact } from '@helpers/enum/contact';
import { getMessageError } from '@helpers/error-helpers';
import { getAddressType } from '@helpers/input-helpers';
import { createUpdateLeadRequest } from '@helpers/lead-helpers';
import { getFirstName } from '@helpers/string-helpers';
import {
  convertPixelToRem,
  holderStyleWithMobileResponsive,
} from '@helpers/style-helpers';

import useDefaultHeader from '@hooks/use-default-header';
import { useDispatch } from '@hooks/use-dispatch';
import { useRoutes } from '@hooks/use-routes';
import { useSelector } from '@hooks/use-selector';
import { handleUpdateCompanyData } from '@store/slices/customer-slice';
import { handleWarningModalContent } from '@store/slices/warning-modal-slice';

import leadsService from '@api/services/leads-service';
import ApplicationError from '@errors/types/application-error';

import { styled } from '@src/themes';

export function TransportationCompanyPage() {
  const FOUR_PERCENT = 0.04;
  const [isUpdatingLead, setIsUpdatingLead] = useState(false);
  const location = useLocation();
  const { companyData, representativeData, username } = useSelector(
    ({ customer }) => customer,
  );
  const { defaultHeader } = useDefaultHeader();
  const { productCartList } = useSelector(({ cart }) => cart);
  const { config, initialPage } = useSelector(({ application }) => application);
  const [modalVisibility, setModalVisibility] = useState(false);
  const dispatch = useDispatch();

  const methods = useForm<CompanyType>({
    mode: 'onChange',
    defaultValues: {
      nomeFantasia:
        companyData.nomeFantasia.length <= config.maxBeneficiaryNameLength
          ? companyData.nomeFantasia
          : companyData.nomeFantasia.substring(
              0,
              config.maxBeneficiaryNameLength,
            ),
      enderecoLocalEntrega: {
        cep: companyData.enderecoFiscal.cep
          ? companyData.enderecoFiscal.cep
          : '',
        tipoLogradouro: getAddressType(
          companyData.enderecoFiscal.tipoLogradouro,
        ),
        logradouro: companyData.enderecoFiscal.logradouro,
        numero: companyData.enderecoFiscal.numero
          ? companyData.enderecoFiscal.numero
          : '',
        complemento: companyData.enderecoFiscal.complemento,
        bairro: companyData.enderecoFiscal.bairro,
        cidade: companyData.enderecoFiscal.cidade,
        estado: companyData.enderecoFiscal.estado,
      },
      dadosCadastrais: {
        nomeCadastro: '',
        emailCadastro: '',
        telefoneCadastro: '',
        dataNascimento: '',
        cpfContato: '',
      },
      representanteLegal: {
        tipo: Contact.RL,
        nome: '',
        documento: '',
        politicamenteExposta: false,
      },
      contatoExtra: [
        {
          tipo: Contact.RL,
          nome: '',
          documento: '',
          politicamenteExposta: false,
        },
      ],
    },
  });
  const { prevStep } = useRoutes();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'contatoExtra',
  });

  const buttonNameObject = {
    representanteLegal: 'Representante',
    cadeiaSocietaria: 'Sócio',
  };

  const watchContactType = methods.watch('representanteLegal.tipo');
  const watchExtraContactType = methods.watch('contatoExtra.0.tipo');

  const contactObjectMapper: Record<string, Contact> = {
    Sócio: Contact.CS,
    Representante: Contact.RL,
  };

  const getLastContactType = () => {
    return buttonNameObject[watchExtraContactType || watchContactType];
  };

  const handleAddContact = () => {
    append({
      tipo: contactObjectMapper[getLastContactType()],
      nome: '',
      documento: '',
      politicamenteExposta: false,
    });
  };

  const totalValueCart = calcTotalValueCartByModality({
    cartmodalityId: 0,
    productCartList,
  });

  const handleModalVisibility = () => {
    setModalVisibility(state => !state);
  };

  const onSubmit: SubmitHandler<CompanyType> = async data => {
    try {
      await leadsService.updateLead(
        defaultHeader,
        companyData.lead,
        createUpdateLeadRequest({
          companyData,
          companyFormData: data,
          productCartList,
          representativeData,
        }),
      );
      setIsUpdatingLead(false);
    } catch (error) {
      setIsUpdatingLead(false);
      handleWarningModalContent({
        isVisible: true,
        title: ApiErrorsModalTitle.DEFAULT,
        content: getMessageError(error),
        closeAction: {
          label: 'Fechar',
          action: error instanceof ApplicationError ? 'close' : 'reloadPage',
        },
      });
    }
    dispatch(handleUpdateCompanyData(data));
    handleModalVisibility();
  };

  useEffect(() => {
    remove(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyConditionsToUseThisPage = useMemo(
    () => companyData.cnpj !== '' || companyData.lead !== '',
    [companyData.cnpj, companyData.lead],
  );

  if (!verifyConditionsToUseThisPage) {
    return (
      <Navigate
        to={{
          pathname: initialPage,
          search: location.search,
        }}
      />
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <PageTitle title="Dados da Empresa" />
        <TransportationCompanyPageContainer>
          <div>
            <Title.Root size="sm" barColor="secondary">
              <Title.Default tag="h3" size="sm">
                {getFirstName(username)}, para começarmos, por favor confirme os
                dados abaixo para a contratação de seu pedido:
              </Title.Default>
            </Title.Root>
          </div>

          <CartPageMobilityProductListForm
            balanceManagerValue={formatValueToCurrency(
              totalValueCart * FOUR_PERCENT,
            )}
          >
            <OfferTableDescription
              title="TOTAL DE TARIFAS DOS PRODUTOS"
              offerConfig={MobilityOfferDescription}
              orderValue={totalValueCart}
            />
          </CartPageMobilityProductListForm>
          <form
            className="transportation-company-form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <RegisterSection
              title="Nome da empresa a ser gravado no(s) cartão(ões):"
              inputNumber={1}
            >
              <CompanyNameInput
                withCardDesign={false}
                isLoading={isUpdatingLead}
              />
            </RegisterSection>
            <RegisterSection
              title="Complete os dados cadastrais:"
              inputNumber={2}
            >
              <RegisterInputs isLoading={isUpdatingLead} />
            </RegisterSection>
            <RegisterSection
              title="Complete os dados de represente legal/cadeia societária:"
              inputNumber={3}
            >
              <ContactInputs
                fields={fields}
                addContact={handleAddContact}
                remove={remove}
                getLastContactType={getLastContactType}
                isLoading={isUpdatingLead}
              />
            </RegisterSection>
            <RegisterSection
              title="Complete o Endereço de Entrega:"
              inputNumber={4}
            >
              <AddressInputs isLoading={isUpdatingLead} />
            </RegisterSection>
            <FormWarning />
            <div className="action-area__button-area">
              <Button.Default
                id="btn-empresa-voltar"
                outline
                disabled={isUpdatingLead}
                onClick={() => prevStep()}
              >
                <i className="fa-regular fa-arrow-left" />
                Voltar
              </Button.Default>
              <Button.Default
                id="btn-empresa-avancar"
                type="submit"
                loading={isUpdatingLead}
              >
                Gerar contrato
              </Button.Default>
            </div>
          </form>
        </TransportationCompanyPageContainer>
      </FormProvider>
      <ContractModalPreview
        handleModalVisibility={() => handleModalVisibility()}
        modalVisibility={modalVisibility}
      />
    </>
  );
}

const TransportationCompanyPageContainer = styled('div', {
  ...holderStyleWithMobileResponsive,
  flex: 1,

  width: '100%',
  paddingTop: '0 !important',
  marginTop: '5.375rem',

  '> div': {
    margin: '2rem 0',
  },

  '.transportation-company-form': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: convertPixelToRem(20),
  },

  '.action-area__button-area': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.25rem',
    margin: '3rem 0px',

    width: '100%',

    'button, a': {
      width: convertPixelToRem(240),
    },

    '@deviceSm': {
      flexDirection: 'column',
      margin: '1.5rem 0',

      'button, a': {
        width: '100%',
      },
    },
  },
});
