import { ReactElement } from 'react';

import { CustomerType } from '@helpers/enum/customer-type';

import { useSelector } from '@hooks/use-selector';

import AllProductsContractedCustomer from './all-products-contracted-customer';
import AnotherChannelCustomer from './another-channel-customer';
import BlockList from './block-list';
import Downgrade from './downgrade';
import HasProductsContracted from './has-products-contracted';
import NewCustomer from './new-customer';
import OfferRestriction from './offer-restriction';
import SelectedOnlyContractedProducts from './selected-only-contracted-products';
import UnservedRegion from './unserved-region';

function Scenarios() {
  const { customerType } = useSelector(({ customer }) => customer);

  const availableScenarios: { [key in CustomerType]: ReactElement } = {
    new: <NewCustomer />,
    hasAvailableProducts: <HasProductsContracted />,
    anotherChannel: <AnotherChannelCustomer />,
    allProductsContracted: <AllProductsContractedCustomer />,
    offerRestriction: <OfferRestriction />,
    customer: <SelectedOnlyContractedProducts />,
    unservedRegion: <UnservedRegion />,
    blockList: <BlockList />,
    downgrade: <Downgrade />,
  };

  if (!customerType) {
    return null;
  }

  return availableScenarios[customerType];
}

export default Scenarios;
