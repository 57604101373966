import { HTMLAttributes } from 'react';

import { ASSETS_FLAGS_PATH } from '@config/assets-config';
import { env } from '@config/env';

interface ModalityLegalFlagProps extends HTMLAttributes<HTMLImageElement> {
  modalityId: string;
}

export function ModalityLegalFlag({
  modalityId,
  ...props
}: ModalityLegalFlagProps) {
  return (
    <img
      src={`${env.URL_ASSETS}${ASSETS_FLAGS_PATH}/flag-legal-${modalityId}.png`}
      alt="Imagem ilustrativa sobre o aviso legal de saldos separados"
      {...props}
    />
  );
}
