import { useRef, useState } from 'react';

import {
  COMMERCIAL_CONDITIONS_BONUS_DETAILS,
  PRODUCT_COMMERCIAL_CONDITIONS,
} from '@config/products-config';
import { BonusType } from '@extra-types/bonus-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { BonusItemContainer, BonusItemDetailsContainer } from './styles';

interface CartPageBonusItemProps {
  data: BonusType;
}

export function CartPageBonusItem({ data }: CartPageBonusItemProps) {
  const emissor = useSelector(({ application }) => application.config.emissor);
  const [isCollapsed, setCollapse] = useState(true);
  const detailsRef = useRef<HTMLDivElement | null>(null);

  const bonusName = PRODUCT_COMMERCIAL_CONDITIONS[data.id].label;
  const detailsHeight = detailsRef.current?.scrollHeight || 0;
  const detailsData = COMMERCIAL_CONDITIONS_BONUS_DETAILS[data.id];

  const handleCollapseState = () => setCollapse(collapse => !collapse);

  return (
    <>
      <BonusItemContainer
        data-testid="bonus-item-container"
        collapse={isCollapsed}
        onClick={handleCollapseState}
      >
        <div className="offer-item__icon">
          <i className="fa-light fa-gift" />
        </div>
        <div className="offer-item__title">
          <p>{bonusName}</p>
          <i className="fa-solid fa-caret-up" />
        </div>
        <div className="offer-item__value">
          <p>Grátis</p>
        </div>
      </BonusItemContainer>
      {detailsData && (
        <BonusItemDetailsContainer
          ref={detailsRef}
          css={{ $$height: convertPixelToRem(detailsHeight) }}
          collapse={isCollapsed}
        >
          <div className="details-container__holder">
            {detailsData({
              emissorName: emissor.name,
              productBonusDataList: data.entryBonusRules,
            })}
          </div>
        </BonusItemDetailsContainer>
      )}
    </>
  );
}
