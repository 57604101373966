/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DEFAULT_INITIAL_PAGE_LINK,
  SIGLA_CANAL_DEFAULT,
} from '@config/application-config';
import { EmissorData } from '@config/emissor-config';
import { getEmissor } from '@helpers/emissor-helpers';
import { unmountQueryParams } from '@helpers/query-string-helpers';

import { fetchApplicationConfig } from '@store/thunks/application-thunk';

import { ChannelContactData } from '@api/models/response/search-channel-description';

export type ApplicationType = {
  isLoading: boolean;
  initialPage: string;
  config: {
    siglaCanal: string;
    siglaSubCanal?: string;
    emissor: EmissorData;
    channelDescription: ChannelContactData;
    maxBeneficiaryNameLength: number;
    maxBeneficiaryAmount: number;
    minCardValue: number;
    maxCardValue: number;
    restrictSimulation: number;
  };
};

export const initialState = {
  isLoading: true,
  initialPage: DEFAULT_INITIAL_PAGE_LINK,
  config: {
    siglaCanal:
      unmountQueryParams(window.location.href).canal || SIGLA_CANAL_DEFAULT,
    siglaSubCanal:
      unmountQueryParams(window.location.href).subcanal || undefined,
    emissor: getEmissor(),
    channelDescription: {} as ChannelContactData,
    maxBeneficiaryNameLength: 0,
    maxBeneficiaryAmount: 0,
    minCardValue: 0,
    maxCardValue: 0,
    restrictSimulation: 0,
  },
} as ApplicationType;

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    handleChangeInitialPage: (
      state,
      action: PayloadAction<{ pageRoute: string }>,
    ) => {
      state.initialPage = action.payload.pageRoute;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchApplicationConfig.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchApplicationConfig.fulfilled, (state, action) => {
        state.config = { ...state.config, ...action.payload };
        state.isLoading = false;
      });
  },
});

export const { handleChangeInitialPage } = applicationSlice.actions;

export default applicationSlice.reducer;
