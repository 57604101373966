import { OrderRevisionServiceTableAddonItem } from '@elements/order-revision-service-table-elements/order-revision-service-table-addon-item';

import { additionalProducts } from '@config/hcm-config';
import { ProductPricedFullDataType } from '@extra-types/product-priced-full-data-type';

import { styled } from '@src/themes';

import { ResumeAdditionalProductList } from './resume-additional-product-list';

interface OrderRevisionExtensionListProps {
  productPricedData: ProductPricedFullDataType;
  isCollapsed: boolean;
}

export function OrderRevisionExtensionList({
  productPricedData,
  isCollapsed,
}: OrderRevisionExtensionListProps) {
  const additionalProductsList = additionalProducts[productPricedData.id];
  if (
    productPricedData.additionalProductList.length === 0 ||
    !additionalProductsList ||
    additionalProductsList.length === 0
  ) {
    return null;
  }

  return (
    <>
      <ResumeAdditionalProductList
        additionalProductList={additionalProducts[productPricedData.id]}
        isCollapsed={isCollapsed}
      />
      <OrderRevisionExtensionListContainer isCollapsed={isCollapsed}>
        <ul className="order-revision-single__awards">
          <li className="awards__title-line">
            <span>Módulos do plano</span>
          </li>
          {additionalProductsList.map(addon => (
            <OrderRevisionServiceTableAddonItem key={addon.id} data={addon} />
          ))}
        </ul>
      </OrderRevisionExtensionListContainer>
    </>
  );
}

export const OrderRevisionExtensionListContainer = styled('div', {
  opacity: 1,

  padding: '0 1rem',
  transition: 'opacity 0.4s ease',
  transitionDelay: '0.05s',
  overflow: 'hidden',

  '.order-revision-single__awards': {
    display: 'flex',
    flexDirection: 'column',
  },

  '.awards__title-line': {
    paddingLeft: '1.875rem',
    paddingBottom: '0.5rem',

    span: {
      fontSize: '0.875rem',
      fontWeight: '$medium',
    },
  },

  '@deviceSm': {
    padding: 0,
    paddingTop: '0.5rem',
    borderTop: '1px solid #E4E8ED',

    '.awards__title-line': {
      paddingLeft: 0,
      paddingBottom: '0.25rem',

      span: {
        fontSize: '0.75rem',
      },
    },
  },
  variants: {
    isCollapsed: {
      true: {
        maxHeight: 0,
        opacity: 0,
      },
    },
  },
});
