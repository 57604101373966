import { useContext } from 'react';

import {
  ContactModalContext,
  ContactModalContextData,
} from '@store/context/contact-modal-context';

export function useContactModalContext(): ContactModalContextData {
  const context = useContext(ContactModalContext);

  if (!context) {
    throw new Error(
      'useLinksContext must be used within a ContactModalContext.',
    );
  }

  const { handleContactModal, contactModalState } = context;

  return { handleContactModal, contactModalState };
}
