import { CustomerModalCompanyMessage } from '@components/customer-modal-company-message';
import { Button } from '@elements/button';

import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';

import { ScenariosContainer } from './styles';

function Downgrade() {
  return (
    <ScenariosContainer>
      <img
        src={`${
          env.URL_ASSETS + ASSETS_OTHERS_PATH
        }/block-list-illustration.svg`}
        alt="Ilustração de um cesto e um carrinho de compras."
      />
      <div className="separator" />
      <CustomerModalCompanyMessage
        blocked
        customMessage="Não é possível prosseguir com este modelo de contratação."
      />

      <p className="downgrade-text">
        Você está tentando alterar seu plano atual para um plano igual ou
        inferior. Para isso, é necessário acessar a seção &quot;
        <strong>Minha Conta</strong>
        &quot; no sistema VR Gente.
      </p>

      <Button.Anchor
        id="btn-modal-cliente-downgrade"
        href="https://app2.pontomais.com.br/minha-conta"
        variant="primary"
        target="_blank"
      >
        Me leve para a página
      </Button.Anchor>
    </ScenariosContainer>
  );
}

export default Downgrade;
