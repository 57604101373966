import { Button } from '@elements/button';

import { useSelector } from '@hooks/use-selector';

import ScenariosInputs from './scenarios-inputs';
import { ScenariosContainer } from './styles';

function NewCustomer() {
  const isLoading = useSelector(({ customer }) => customer.isLoading);
  const isCartLoading = useSelector(({ cart }) => cart.isCartLoading);

  return (
    <ScenariosContainer>
      <div className="information-box">
        <p>
          Seja <span>bem-vindo!</span> Conte um pouco mais de você para ver a
          proposta que simulamos para sua empresa:
        </p>
      </div>
      <ScenariosInputs />
      <Button.Default
        id="btn-modal-ver-resultado"
        type="submit"
        variant="primary"
        loading={isLoading || isCartLoading}
      >
        Ver resultado
      </Button.Default>
    </ScenariosContainer>
  );
}

export default NewCustomer;
