import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const DeliverySectionContainer = styled('div', {
  display: 'flex',

  backgroundColor: '$white',
  width: '100%',
  borderRadius: convertPixelToRem(6),
  border: `${convertPixelToRem(1)} solid $white`,
  boxShadow: '0px 0px 8px #00000022',

  '@deviceSm': {
    flexDirection: 'column',
    height: '100%',
  },

  '.delivery-session__header': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: '$silverPure',
    width: convertPixelToRem(212),
    padding: '1rem',
    margin: convertPixelToRem(6),
    borderTopLeftRadius: convertPixelToRem(6),
    borderBottomLeftRadius: convertPixelToRem(6),

    i: {
      color: '$secondaryPure',
      fontSize: convertPixelToRem(32),
    },

    '.header__title': {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: convertPixelToRem(8),

      span: {
        color: '$fontColorPrimary',
        fontSize: convertPixelToRem(16),
        fontWeight: '$bold',
      },
    },

    '@deviceSm': {
      flexDirection: 'column',

      width: '100%',
      height: convertPixelToRem(100),
      backgroundColor: '$white',
      padding: '0 1rem',
      margin: 0,
      borderTopRightRadius: convertPixelToRem(10),

      i: {
        fontSize: convertPixelToRem(30),
      },

      '.header__title': {
        flexDirection: 'row',
        marginLeft: 0,

        span: {
          marginTop: convertPixelToRem(14),
          marginLeft: convertPixelToRem(4),
        },
      },
    },
  },

  '.delivery-session__content': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: convertPixelToRem(12),

    padding: `1.5rem 1.5rem 1.5rem ${convertPixelToRem(10)}`,

    '.content__delivery-item': {
      p: {
        color: '$fontColorPrimary',
        fontSize: convertPixelToRem(12),
        fontWeight: '$semibold',
        marginBottom: convertPixelToRem(4),
      },

      strong: {
        color: '$fontColorPrimary',
        fontSize: convertPixelToRem(14),
      },
    },

    '@deviceSm': {
      gap: 0,
      padding: 0,

      '.content__delivery-item': {
        padding: `${convertPixelToRem(20)} ${convertPixelToRem(24)}`,
        marginLeft: 0,
        borderTop: '1px solid #E4E8ED',

        p: {
          fontSize: convertPixelToRem(13),
          marginBottom: convertPixelToRem(10),
        },

        span: {
          color: '$fontColorPrimary',
          fontSize: convertPixelToRem(14),
          fontWeight: '$bold',
        },
      },
    },
  },
});
