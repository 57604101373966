import React, { useEffect, useState } from 'react';

import { Button } from '@elements/button';

import { LoadingLine, SVARowContainer } from './styles';

type SVARowProps = {
  svaData: {
    title: string;
    subtitle: string;
    offerText: string;
  };
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
};

type DescriptionPropsData = {
  role?: React.AriaRole;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  tabIndex?: number;
};

export function SVARow({ svaData, children, loading, disabled }: SVARowProps) {
  const [isCollapsed, setCollapse] = useState(true);
  const [descriptionProps, setDescriptionProps] = useState(
    {} as DescriptionPropsData,
  );

  const { title, subtitle, offerText } = svaData;

  const handleDescriptionCollapse = () => {
    setCollapse(oldState => !oldState);
  };

  useEffect(() => {
    if (children) {
      setDescriptionProps({
        role: 'button',
        onClick: handleDescriptionCollapse,
        onKeyDown: e => {
          if (e.key === 'Enter') {
            handleDescriptionCollapse();
          }
        },
        tabIndex: 0,
      } as DescriptionPropsData);
    } else {
      setDescriptionProps({});
    }
  }, [children]);

  if (loading) {
    return (
      <SVARowContainer>
        <td colSpan={2}>
          <div className="sva-row-container">
            <div className="sva-row-container__left-column">
              <div className="left-column__content-container">
                <div className="content-container__text-content">
                  <div className="text-content__information-title">
                    <LoadingLine css={{ $$width: '50%' }} />
                  </div>
                  <div>
                    <LoadingLine css={{ $$width: '30%' }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="sva-row__right-column">
              <div className="right-column__content-container">
                <div className="content-container__product-value">
                  <LoadingLine css={{ $$width: '100%' }} />
                </div>
              </div>
            </div>
          </div>
        </td>
      </SVARowContainer>
    );
  }

  return (
    <SVARowContainer
      collapse={isCollapsed}
      aria-disabled={disabled}
      cursorPointer={!!children}
    >
      <td colSpan={2}>
        <div className="sva-row-container" {...descriptionProps}>
          <div className="sva-row-container__left-column">
            <div className="left-column__content-container">
              <div className="content-container__text-content">
                <p className="text-content__information-title">
                  {title}
                  {!!children && <i className="fas fa-caret-down" />}
                </p>
                <p className="text-content__information-subtitle">{subtitle}</p>
              </div>
            </div>
          </div>
          <div className="sva-row-container__right-column">
            <div className="right-column__content-container">
              <p className="content-container__offer-text">{offerText}</p>
            </div>
          </div>
        </div>
        {!!children && (
          <div className="sva-row-description">
            <div className="sva-row-description__text-content">{children}</div>
            <div className="sva-row-description__button-section">
              <Button.Default
                variant="primary"
                size="small"
                onClick={handleDescriptionCollapse}
              >
                Fechar
              </Button.Default>
            </div>
          </div>
        )}
      </td>
    </SVARowContainer>
  );
}
