import { Skeleton } from '@components/skeletons';
import { FAQContactItem } from '@elements/faq-contact-item';

import { useContact } from '@hooks/use-contact';

import { styled } from '@src/themes';

function FAQContactListContent() {
  const { isChatEnable, channelContact, isContactInformationLoading } =
    useContact();

  if (isContactInformationLoading) {
    return (
      <FAQContactListContainer>
        <Skeleton.Line height={90} />
        <Skeleton.Line height={90} />
        <Skeleton.Line height={90} />
      </FAQContactListContainer>
    );
  }

  return (
    <FAQContactListContainer>
      {isChatEnable && (
        <FAQContactItem
          icon="fal fa-comment"
          title="Chat"
          subtitle="Fale via chat conosco"
        />
      )}
      {channelContact.emailList.map(contact => (
        <FAQContactItem
          key={contact.contactInformation}
          icon={contact.contactIcon}
          title={contact.contactInformation}
          subtitle="Clique aqui para enviar e-mail"
        />
      ))}
      {channelContact.phoneList.map(contact => (
        <FAQContactItem
          key={contact.contactInformation}
          icon={contact.contactIcon}
          title={contact.contactInformation}
          subtitle="Clique aqui para fazer a ligação"
        />
      ))}
    </FAQContactListContainer>
  );
}

export function FAQContactList() {
  return (
    <FAQContactContainer>
      <p>
        Sua dúvida não foi respondida? Entre em contato conosco através de um
        dos canais abaixo que vamos te ajudar.
      </p>
      <FAQContactListContent />
    </FAQContactContainer>
  );
}

const FAQContactContainer = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2rem',

  padding: '3rem 0',

  '> p': {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.5',
    textAlign: 'center',
  },
});

const FAQContactListContainer = styled('ul', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  gap: '1rem',

  width: '100%',

  '@deviceLg': {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  },

  '@media only screen and (max-width: 720px)': {
    gridTemplateColumns: '1fr',
  },
});
