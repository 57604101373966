import { isCustomChannel } from '@helpers/channel-helpers';
import { getEmissor } from '@helpers/emissor-helpers';
import { Emissor } from '@helpers/enum/emissor';
import { Environment } from '@helpers/enum/environment';
import { getQueryParams } from '@helpers/query-string-helpers';

// PORTAL URLs BY EMISSOR AND ENV
const PORTAL_VRPAT_URL_DEV = 'https://dsp.vr.com.br/portal/portal-vr/login/';
const PORTAL_VRPAT_URL_QA =
  'https://portalhmp.vr.com.br/portal/portal-vr/login/';
const PORTAL_VRPAT_URL_PRD = 'https://portal.vr.com.br/portal/portal-vr/login/';
const PORTAL_JVCEF_URL_DEV =
  'https://empresadsp.caixaprepagos.com.br/portal/portal-caixa/login/';
const PORTAL_JVCEF_URL_QA =
  'https://empresahmp.caixaprepagos.com.br/portal/portal-caixa/login/';
const PORTAL_JVCEF_URL_PRD =
  'https://empresa.caixaprepagos.com.br/portal/portal-caixa/login/';

// API URLs BY ENV
const URL_API_MOCK = 'http://localhost:8000';
const URL_API_SELF_HOST = 'http://localhost:8080';
const URL_API_DEV = 'https://api-dsp.vr.com.br/simulecompre/v1';
const URL_API_V2_DEV = 'https://api-dsp.vr.com.br/simulecompre/v2';
const URL_API_QA = 'https://api-hmp.vr.com.br/simulecompre/v1';
const URL_API_V2_QA = 'https://api-hmp.vr.com.br/simulecompre/v2';
const URL_API_PRD = 'https://api.vr.com.br/simulecompre/v1';
const URL_API_V2_PRD = 'https://api.vr.com.br/simulecompre/v2';
const URL_API_SENSEDIA_DEV = 'https://api-dsp.vr.com.br';
const URL_API_SENSEDIA_QA = 'https://api-hmp.vr.com.br';
const URL_API_SENSEDIA_PRD = 'https://api.vr.com.br';

const ENV_PORTAL_URL: {
  [key in Emissor]: {
    [keyEnv in Environment]: string;
  };
} = {
  VRPAT: {
    MOCK: PORTAL_VRPAT_URL_DEV,
    LOCAL: PORTAL_VRPAT_URL_DEV,
    DEV: PORTAL_VRPAT_URL_DEV,
    QA: PORTAL_VRPAT_URL_QA,
    PRD: PORTAL_VRPAT_URL_PRD,
  },
  JVCEF: {
    MOCK: PORTAL_VRPAT_URL_DEV,
    LOCAL: PORTAL_JVCEF_URL_DEV,
    DEV: PORTAL_JVCEF_URL_DEV,
    QA: PORTAL_JVCEF_URL_QA,
    PRD: PORTAL_JVCEF_URL_PRD,
  },
};

const ENV_API_URL: {
  [keyEnv in Environment]: {
    URL_API: string;
    URL_API_V2: string;
    URL_API_SENSEDIA: string;
  };
} = {
  MOCK: {
    URL_API: URL_API_MOCK,
    URL_API_V2: URL_API_MOCK,
    URL_API_SENSEDIA: URL_API_SENSEDIA_DEV,
  },
  LOCAL: {
    URL_API: URL_API_SELF_HOST,
    URL_API_V2: URL_API_SELF_HOST,
    URL_API_SENSEDIA: URL_API_SENSEDIA_DEV,
  },
  DEV: {
    URL_API: URL_API_DEV,
    URL_API_V2: URL_API_V2_DEV,
    URL_API_SENSEDIA: URL_API_SENSEDIA_DEV,
  },
  QA: {
    URL_API: URL_API_QA,
    URL_API_V2: URL_API_V2_QA,
    URL_API_SENSEDIA: URL_API_SENSEDIA_QA,
  },
  PRD: {
    URL_API: URL_API_PRD,
    URL_API_V2: URL_API_V2_PRD,
    URL_API_SENSEDIA: URL_API_SENSEDIA_PRD,
  },
};

const ENV_URL_ASSETS: {
  [keyEnv in Environment]: string;
} = {
  MOCK: 'https://assets-lojavr-dev.vrbeneficios.io/',
  LOCAL: 'https://assets-lojavr-dev.vrbeneficios.io/',
  DEV: 'https://assets-lojavr-dev.vrbeneficios.io/',
  QA: 'https://assets-lojavr-qa.vrbeneficios.io/',
  PRD: 'https://assets-lojavr.vr.com.br/',
};

const generateURLAssetsWithEmissor = (
  emissor: Emissor,
  environment: Environment,
) => {
  return `${ENV_URL_ASSETS[environment] + emissor.toLowerCase()}/assets`;
};

function getURLAssetsChannelComplement(channel: string) {
  return isCustomChannel(channel) ? `/${channel.toLocaleLowerCase()}` : '';
}

function generateLinksByEnvironment() {
  const { host } = window.location;
  const emissor = getEmissor();
  const channel = getQueryParams(window.location.href).canal;
  const { REACT_APP_ENV } = process.env;

  // By default the project will be initialized in the dev environment
  let selectedEnv = Environment.DEV;

  if (host.indexOf('qa') >= 0) {
    selectedEnv = Environment.QA;
  }
  if (host.indexOf('com.br') >= 0) {
    selectedEnv = Environment.PRD;
  }

  if (REACT_APP_ENV) {
    selectedEnv = REACT_APP_ENV as Environment;
  }

  return {
    ...ENV_API_URL[selectedEnv],
    ID: selectedEnv,
    URL_ASSETS: generateURLAssetsWithEmissor(emissor.initials, selectedEnv),
    URL_ASSETS_CHANNEL:
      generateURLAssetsWithEmissor(emissor.initials, selectedEnv) +
      getURLAssetsChannelComplement(channel),
    URL_PORTAL: ENV_PORTAL_URL[emissor.initials][selectedEnv],
  };
}

export const env = { ...generateLinksByEnvironment() };
