import { getServiceData } from '@helpers/service-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

import { ComboServiceFormRenegotiationMessage } from './combo-service-form-renegotiation-message';
import { ComboServiceFormValue } from './combo-service-form-value';

interface ComboServiceFormHeaderProps {
  serviceGroupId: string;
  value: number;
  isCollapsed: boolean;
  showRecurringValueLabel: boolean;
}

export function ComboServiceFormHeader({
  serviceGroupId,
  value,
  isCollapsed,
  showRecurringValueLabel,
}: ComboServiceFormHeaderProps) {
  const serviceData = getServiceData(serviceGroupId);
  return (
    <>
      <ComboServiceFormRenegotiationMessage serviceId={serviceGroupId} />
      <ComboServiceFormHeaderContainer>
        <h2>{serviceData.title}</h2>
        <ComboServiceFormValue
          value={value}
          isVisible={isCollapsed}
          showRecurringValueLabel={showRecurringValueLabel}
          size="sm"
        />
      </ComboServiceFormHeaderContainer>
    </>
  );
}

const ComboServiceFormHeaderContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  padding: '0.5rem 1rem',

  h2: {
    color: '$black',
    fontSize: convertPixelToRem(14),
    lineHeight: 2.5,
  },

  img: {
    flexShrink: 1,

    height: '1.5rem',
    objectFit: 'contain',
  },

  variants: {
    isCollapse: {
      true: {},
      false: {
        boxShadow: 'unset',
      },
    },
  },
});
