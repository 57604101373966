import {
  convertPixelToRem,
  holderStyleWithMobileResponsive,
} from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const ProductAdvantagesContainer = styled('section', {
  backgroundColor: '#F8F8F8',
});

export const ProductAdvantagesHolder = styled('div', {
  ...holderStyleWithMobileResponsive,
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',

  paddingTop: convertPixelToRem(40),
  paddingBottom: convertPixelToRem(52),
});

export const ProductBenefitItem = styled('li', {
  display: 'flex',

  background: '$$productColor',
  width: '100%',
  padding: '1rem 1.25rem',
  borderRadius: `${convertPixelToRem(8)} 0 0 ${convertPixelToRem(8)}`,

  color: '$white',
  fontWeight: '$semibold',

  i: {
    display: 'flex',
    justifyContent: 'center',
    width: convertPixelToRem(20),
    marginRight: convertPixelToRem(10),
  },

  '&:not(:last-child)': {
    marginBottom: '10px',
  },
});
