import { PageContainer } from '@elements/page-container';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const StepperContainer = styled(PageContainer, {
  display: 'flex',
  justifyContent: 'center',

  width: '100%',
  background: 'linear-gradient(180deg, $headerBackgroundColor 80%, $white 0%)',
  paddingTop: '5.375rem',
  flex: 'unset',

  '.stepper-holder': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    backgroundColor: '$white',
    width: 'calc(100% - 4rem)',
    maxWidth: convertPixelToRem(980),
    padding: '2rem 1.125rem 1.125rem',
    margin: '0 2rem',
    borderRadius: '10px',
    boxShadow: '0px 3px 6px #00000029',

    '@deviceSm': {
      margin: '0 1rem',
      width: 'calc(100% - 2rem)',
      padding: '2rem 0.75rem 1.125rem',
    },

    '&--contract': {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '1.5rem',

      padding: '1.25rem 2rem',

      h2: {
        fontSize: convertPixelToRem(26),
        fontWeight: '$semibold',
        '@deviceSm': {
          fontSize: '1rem',
        },
      },
    },

    '&--payment, &--mobility': {
      flexDirection: 'row',
      gap: '2rem',
      padding: '1.25rem 2rem',

      div: {
        p: {
          fontSize: '0.875rem',
          fontWeight: '$medium',
        },

        h2: {
          fontSize: '1.25rem',
          fontWeight: 600,
        },
      },

      '@deviceSm': {
        padding: '1.5rem 1.125rem',
        gap: '1rem',

        div: {
          h2: {
            fontSize: '1.125rem',
          },
        },
      },
    },

    '.steps-line': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      backgroundColor: '$disabledPure',
      width: '77%',
      height: '0',
      borderTop: '3px solid $disabledPure',
      borderBottom: '3px solid $disabledPure',

      '@deviceSm': {
        borderTop: '2px solid $disabledPure',
        borderBottom: '2px solid $disabledPure',
      },
    },

    '.progression': {
      position: 'absolute',

      backgroundColor: '$secondaryPure',
      width: '$$location',
      border: '3px solid $secondaryPure',
      transition: 'all 0.8s ease-in-out',
    },

    '.references-line': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',

      width: '100%',
      paddingTop: '1.5rem',

      textAlign: 'center',
    },
  },

  i: {
    fontSize: convertPixelToRem(60),
    color: '$primaryPure',
  },
});
