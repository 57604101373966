import { CustomerData } from '@compositions/customer-modal';

import { ApiRequestAuthData } from '@api/models/request/auth-data';
import { RegisterCartRequest } from '@api/models/request/register-cart-request';
import { UpdateLeadRequest } from '@api/models/request/update-lead-request';
import CreateCartResponseData from '@api/models/response/create-cart-response';
import CreateLeadResponseData from '@api/models/response/create-lead-response';
import ResponseEntity from '@api/models/response/response-entity';
import { UpdateLeadResponse } from '@api/models/response/update-lead-response';

import getBaseURLApi from './authentication/get-base-url-api';

class LeadsService {
  private readonly baseUrl;

  constructor() {
    this.baseUrl = 'leads';
  }

  public async createLead(
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
    body: CustomerData,
  ): Promise<CreateLeadResponseData> {
    const url = `/${this.baseUrl}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
      data: body,
      method: 'POST',
    });

    return data;
  }

  public async updateLead(
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
    lead: string,
    body: UpdateLeadRequest,
  ): Promise<UpdateLeadResponse> {
    const url = `/${this.baseUrl}/${lead}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
      data: body,
      method: 'PUT',
    });

    return data;
  }

  public async createCart(
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
    lead: string,
    body: RegisterCartRequest,
    identificacaoOferta?: string,
  ): Promise<CreateCartResponseData> {
    const url = `/${this.baseUrl}/${lead}/carrinhos`;

    let headers: {
      'sigla-emissor': string;
      'sigla-canal': string;
      'id-subCanal': string;
      'identificacao-oferta'?: string;
    } = {
      'sigla-emissor': siglaEmissor,
      'sigla-canal': siglaCanal,
      'id-subCanal': siglaSubCanal ?? '',
    };

    if (identificacaoOferta) {
      headers = {
        ...headers,
        'identificacao-oferta': identificacaoOferta,
      };
    }

    const { data } = await getBaseURLApi(url, {
      headers,
      data: body,
      method: 'POST',
    });

    return data;
  }

  public async createCartProposal(
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
    lead: string,
  ): Promise<ResponseEntity> {
    const url = `/${this.baseUrl}/${lead}/carrinhos/proposta`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
      method: 'POST',
    });

    return data;
  }
}

export default new LeadsService();
