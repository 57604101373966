/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback } from 'react';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';

import Dropdown from '@components/dropdown';
import { HCMProductBox } from '@components/hcm-product-box';
import Modal from '@components/modal';
import { ProductInformation } from '@components/product-information';
import Option from '@elements/option';
import { HCMFormType } from '@pages/offer-page/custom-offer-pages/components/combo-product-services-form/combo-hcm-service-form';
import { ComboServiceFormServiceInformation } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-service-information';

import { HCMOptions, MAX_LIFES_HCM } from '@config/hcm-config';
import { ModalityType } from '@extra-types/modality-type';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { ProductType } from '@extra-types/product-type';
import { FormErrors } from '@helpers/enum/form-errors';
import { ProductsId } from '@helpers/enum/products-id';
import { getProductListByModality } from '@helpers/product-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addPlanToCart,
  addProductToComboCartList,
  resetAdditionalProductCart,
} from '@store/slices/cart-slice';
import {
  fetchCartPricing,
  fetchPricingWithoutLead,
} from '@store/thunks/cart-thunk';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

type CartPageHcmHeaderProps = {
  isFormActive?: boolean;
  modalityData: ModalityType;
  plan: ProductType;
};

export function CartPageHcmHeader({
  isFormActive,
  modalityData,
  plan,
}: CartPageHcmHeaderProps) {
  const methods = useFormContext<HCMFormType>();
  const dispatch = useDispatch();
  const { selectedOffer } = OfferFacade();
  const { productList } = useSelector(({ products }) => products);
  const {
    productComboCartList,
    isCartLoading,
    cartPricingData,
    productCartList,
  } = useSelector(({ cart }) => cart);
  const { isLoading: isRegisterCartLoading } = useSelector(
    ({ customer }) => customer,
  );
  const { handleActiveSummary } = useComboCartPageContext();
  const averageEmployeesID = `service-${modalityData.keyId}-employeesAmount`;

  const getPlanValue = useCallback(() => {
    return cartPricingData?.detalhe.produtos.find(
      ({ codigoProduto }) => codigoProduto === plan.id,
    );
  }, [cartPricingData?.detalhe.produtos, plan.id]);

  const modalityProductList = getProductListByModality(
    modalityData.id,
    productList,
  );

  const getPlansByProductList = useCallback(() => {
    return modalityProductList.map(({ id }) => {
      return {
        amount: methods.watch('average-employees'),
        id,
        type: 'HCM',
        value: 0,
        planType: methods.watch('planType'),
      };
    });
  }, [methods, modalityProductList]);

  const saveSelectedEmployeesAmount = useCallback(async () => {
    const isFormValid = await methods.trigger(['average-employees']);
    methods.handleSubmit((form: HCMFormType) => {
      if (isFormValid && form['average-employees']) {
        if (form['average-employees'] > 10) {
          dispatch(
            fetchPricingWithoutLead(
              getPlansByProductList().filter(
                filteredPlan => filteredPlan.id !== ProductsId.HCM_ESSENCIAL,
              ),
            ),
          );
        } else {
          dispatch(fetchPricingWithoutLead(getPlansByProductList()));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSelectedAverageEmployees = useCallback(async () => {
    const isFormValid = await methods.trigger(['average-employees']);
    methods.handleSubmit((form: HCMFormType) => {
      if (selectedOffer) {
        if (isFormValid && form['average-employees']) {
          dispatch(
            addProductToComboCartList({
              modalityId: plan.modalityId,
              product: {
                id: plan.id,
                type: plan.type,
                amount: form['average-employees'],
                value: getPlanValue()?.valorProduto || 0,
                planType: PlanTypeEnum.MENSAL,
              },
            }),
          );
        }
        dispatch(fetchCartPricing({ offerData: selectedOffer }));
        handleActiveSummary({ id: `${modalityData.serviceGroupId}` });
      } else {
        dispatch(resetAdditionalProductCart());
        dispatch(
          addPlanToCart({
            id: plan.id,
            type: plan.type,
            amount: form['average-employees'],
            value:
              (form.planType === PlanTypeEnum.ANUAL
                ? getPlanValue()?.valorProdutoComDesconto
                : getPlanValue()?.valorProduto) || 0,
            planType: form.planType,
            additionalProducts: [],
          }),
        );
        dispatch(fetchPricingWithoutLead());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productComboCartList, productCartList]);

  const keepTheSamePlanInTheCart = useCallback(() => {
    dispatch(
      addPlanToCart({
        id: plan.id,
        type: plan.type,
        amount: methods.getValues()['average-employees'],
        value:
          (methods.watch('planType') === PlanTypeEnum.ANUAL
            ? getPlanValue()?.valorProdutoComDesconto
            : getPlanValue()?.valorProduto) || 0,
        planType: methods.getValues().planType,
      }),
    );
    dispatch(fetchPricingWithoutLead());
  }, [dispatch, getPlanValue, methods, plan.id, plan.type]);

  return (
    <ComboServiceFormServiceInformation isCollapse={!isFormActive}>
      <ProductInformation
        productData={plan}
        size="small"
        price={saveSelectedEmployeesAmount}
      />
      <AverageEmployeeContainer className="product-actions">
        <p>Média de trabalhadores</p>
        <Controller
          control={methods.control}
          name="average-employees"
          rules={{ required: FormErrors.REQUIRED_DEFAULT }}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              id={averageEmployeesID}
              data-testid={averageEmployeesID}
              value={value}
              placeholder="selecione"
              label=""
              disabled={!isFormActive || isCartLoading || isRegisterCartLoading}
              onChange={e => {
                onChange(e.target.value);
                saveSelectedAverageEmployees();
              }}
              css={{
                width: '132px',
                span: {
                  paddingBottom: 'unset',
                  fontSize: '0.75rem',
                  marginRight: '0.5rem',
                },
              }}
            >
              {HCMOptions[plan.id]
                .filter(option => option.value <= MAX_LIFES_HCM)
                .map(o => (
                  <Option key={o.value} value={o.value}>
                    {o.label}
                  </Option>
                ))}
            </Dropdown>
          )}
        />
      </AverageEmployeeContainer>
      <Modal size="lg" closeWithActions={() => keepTheSamePlanInTheCart()}>
        <FormProvider {...methods}>
          <div className="products-holder">
            {modalityProductList?.map(product => (
              <HCMProductBox key={product.id} productData={product} />
            ))}
          </div>
        </FormProvider>
      </Modal>
    </ComboServiceFormServiceInformation>
  );
}

export const AverageEmployeeContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.5rem',

  p: {
    fontSize: convertPixelToRem(12),
    whiteSpace: 'nowrap',
  },

  '@deviceMd': {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1.5rem',

    p: {
      fontSize: '0.75rem',
    },

    '> div': {
      paddingBottom: '0',
    },
  },

  '@deviceXs': {
    flexDirection: 'column',
    alignItems: 'start',
    gap: '0',

    marginTop: '1rem',

    p: {
      fontSize: '0.75rem',
    },

    '> div': {
      paddingTop: '0.5rem',
    },
  },
});
