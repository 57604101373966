import { DefaultTokenVariantsType } from '@extra-types/token-variants-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { keyframes, styled } from '@src/themes';

interface LoadingBoxProps {
  variant?: DefaultTokenVariantsType;
  width?: number;
  height?: number;
}

export function LoadingBox({
  variant = 'primary',
  height = 20,
  width = 1000,
}: LoadingBoxProps) {
  return (
    <LoadingBoxContainer
      variant={variant}
      css={{
        height: convertPixelToRem(height),
        maxWidth: convertPixelToRem(width),
      }}
    />
  );
}

const loadingAnimation = keyframes({
  from: {
    opacity: '0.15',
  },
  to: {
    opacity: '0.25',
  },
});

const LoadingBoxContainer = styled('div', {
  position: 'relative',

  width: '100%',
  backgroundColor: '#ddd',
  borderRadius: '$sm',
  border: 0,

  animationDuration: '0.5s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  animationDirection: 'alternate',

  variants: {
    variant: {
      primary: {
        backgroundColor: '$silverDark',
        animationName: `${loadingAnimation}`,
      },
      secondary: {
        backgroundColor: '$white',
        animationName: `${loadingAnimation}`,
      },
    },
  },
});
