import { useQuery } from '@tanstack/react-query';

import { AccordionList } from '@components/accordion-list';
import { Skeleton } from '@components/skeletons';
import { Button } from '@elements/button';
import { PageSection, PageSectionVariantType } from '@elements/page-section';
import { SectionHolder } from '@elements/section-holder';
import { Title } from '@elements/title';

import {
  FAQ_PREVIEW_MAX_ITEMS_TO_SHOW,
  FAQ_RETRY_DELAY_TIME,
  FAQ_RETRY_MAX_ATTEMPTS,
} from '@config/faq-config';
import { SectionDefaultPropsType } from '@extra-types/section-props-type';
import {
  AllTokenVariantsType,
  DefaultTokenVariantsType,
} from '@extra-types/token-variants-type';
import { createAccordionDataList } from '@helpers/accordion-helpers';
import { convertFAQArticlesListResponseToFAQArticleList } from '@helpers/faq-helpers';

import useDefaultHeader from '@hooks/use-default-header';
import { useInformationSection } from '@hooks/use-information-section';

import faqService from '@api/services/faq-service';

import { styled } from '@src/themes';

type FaqPreviewVariantStylesType = Record<
  PageSectionVariantType,
  {
    section: PageSectionVariantType;
    dark: boolean;
    title: {
      barColor: AllTokenVariantsType;
      fontColor: DefaultTokenVariantsType | 'walletFontColor';
    };
    loading: DefaultTokenVariantsType;
  }
>;

const faqPreviewVariantStyles: FaqPreviewVariantStylesType = {
  primary: {
    section: 'primary',
    dark: false,
    title: {
      barColor: 'secondary',
      fontColor: 'primary',
    },
    loading: 'primary',
  },
  secondary: {
    section: 'primary',
    dark: false,
    title: {
      barColor: 'primary',
      fontColor: 'primary',
    },
    loading: 'secondary',
  },
  tertiary: {
    section: 'tertiary',
    dark: true,
    title: {
      barColor: 'multi',
      fontColor: 'walletFontColor',
    },
    loading: 'secondary',
  },
};

interface FaqPreviewSectionProps extends SectionDefaultPropsType {
  tagFAQ: string;
  title?: string;
  subtitle?: string;
  variant?: PageSectionVariantType;
}

export function FaqPreviewSection({
  id,
  name,
  tagFAQ,
  title,
  subtitle,
  variant = 'primary',
}: FaqPreviewSectionProps) {
  const { sectionInformation } = useInformationSection({
    name,
  });
  const { defaultHeader } = useDefaultHeader();
  const { isLoading, data, isError } = useQuery({
    queryKey: [`faqArticlesData-${tagFAQ}`],
    queryFn: () =>
      faqService.getFAQCategories(defaultHeader).then(response => {
        const categoryID = response.categorias.find(
          category => category.tag === tagFAQ,
        )?.id;

        if (!categoryID) {
          return undefined;
        }

        return faqService
          .getFAQCategoryArticlesById(categoryID, defaultHeader)
          .then(articles =>
            convertFAQArticlesListResponseToFAQArticleList(articles),
          );
      }),
    refetchOnWindowFocus: false,
    retry: FAQ_RETRY_MAX_ATTEMPTS,
    retryDelay: FAQ_RETRY_DELAY_TIME,
  });

  const sectionStyle = faqPreviewVariantStyles[variant];

  if (isLoading) {
    return (
      <PageSection variant={sectionStyle.section} dark={sectionStyle.dark}>
        <Skeleton.SectionContainer>
          <Skeleton.Section variant={sectionStyle.loading} />
        </Skeleton.SectionContainer>
      </PageSection>
    );
  }

  if (isError || !data || data.length === 0 || !sectionInformation) {
    return null;
  }

  return (
    <PageSection variant={sectionStyle.section} dark={sectionStyle.dark}>
      <FaqSectionContainer id={id}>
        <Title.Root
          barColor={sectionStyle.title.barColor}
          fontColorVariant={sectionStyle.title.fontColor}
        >
          <Title.Default tag="h2">
            {title ?? sectionInformation.titulo}
          </Title.Default>
          <Title.Subtitle>
            {subtitle ?? sectionInformation.subTitulo}
          </Title.Subtitle>
        </Title.Root>
        <div className="faq-section-container__faq-holder">
          <AccordionList
            data={createAccordionDataList(
              data.slice(0, FAQ_PREVIEW_MAX_ITEMS_TO_SHOW),
            )}
          />
        </div>
        <p className="faq-section-container__action-area">
          <Button.LinkRouter to="/faq">
            Ver mais Perguntas Frequentes
          </Button.LinkRouter>
        </p>
      </FaqSectionContainer>
    </PageSection>
  );
}

const FaqSectionContainer = styled(SectionHolder, {
  display: 'flex',
  flexDirection: 'column',

  '.faq-section-container__action-area': {
    display: 'flex',
    justifyContent: 'center',

    marginTop: '2rem',

    color: '$white',
    textAlign: 'center',

    '@deviceSm': {
      a: {
        width: '100%',
      },
    },
  },
});
