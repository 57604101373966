import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const CustomerContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0,0,0,0.7)',
  backdropFilter: 'blur(3px)',
  overflow: 'hidden',
  zIndex: '$7',

  '&.customer-modal-enter': {
    opacity: '0',

    '.modal-wrapper': {
      transform: 'translateY(100%)',
    },
  },

  '&.customer-modal-enter-active': {
    opacity: '1',
    transition: 'opacity 0.4s',

    '.modal-wrapper': {
      transform: 'translateY(0)',
      transition: 'transform 0.4s',
    },
  },

  '&.customer-modal-exit': {
    opacity: '1',

    '.modal-wrapper': {
      transform: 'translateY(0)',
    },
  },

  '&.customer-modal-exit-active': {
    opacity: '0',
    transition: 'opacity 0.4s',

    '.modal-wrapper': {
      transform: 'translateY(100%)',
      transition: 'transform 0.4s',
    },
  },

  '.modal-wrapper': {
    position: 'relative',
    display: 'flex',
    maxHeight: '97%',
    backgroundColor: '$white',
    borderRadius: '10px',
    padding: '2rem 0',
    width: '100%',
    maxWidth: convertPixelToRem(381),

    '> div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      overflow: 'auto',
      width: '100%',
      padding: '0.5rem 1.5rem',

      img: {
        alignSelf: 'center',
        maxHeight: '160px',
      },
    },

    h2: {
      fontSize: '2rem',
    },

    '@deviceSm': {
      width: '100%',
      borderRadius: '10px 10px 0 0',
      maxWidth: '100%',

      '>div': {
        width: '100%',
        padding: '0.5rem 1.25rem',
      },
    },
  },

  '.content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    textAlign: 'center',
    alignItems: 'center',

    h2: {
      color: '$fontColorPrimary',
      fontSize: '1.625rem',
      fontWeight: 'bold',
    },
  },

  '@deviceSm': {
    alignItems: 'flex-end',
    width: '100%',
  },

  '.close-button': {
    position: 'absolute',
    top: '-1rem',
    right: '-1rem',
    backgroundColor: '$white',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    color: '$primaryPure',
    boxShadow: '0px 0px 8px #00000022',
    fontSize: '1.125rem',
    zIndex: '555',

    '@deviceSm': {
      top: '-1rem',
      right: '1rem',
    },
  },
});
