import { MobilitySectionTitle } from '@elements/mobility-section-title';
import { PageSection } from '@elements/page-section';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export function EmployeesCompanyBenefitsSection() {
  return (
    <PageSection dark>
      <SectionHolder>
        <MobilitySectionTitle color="$primaryDark">
          Quais as vantagens para minha empresa e para o trabalhador?
        </MobilitySectionTitle>
        <div className="employees-company-benefits-section__content">
          <div className="content__employees-area">
            <h3>Para minha empresa</h3>
            <ul>
              <li>Compra unificada em mais de 1.350 operadoras</li>
              <li>
                Automatize a gestão de VT e tenha mais tempo para dedicar aos
                trabalhadores
              </li>
              <li>
                Mais segurança jurídica ao gerir corretamente a compra de VT
              </li>
              <li>
                Relatórios gerenciais automatizados, gratuitos e instantâneos
              </li>
              <li>Controle de cartões reservas</li>
              <li>Acompanhamento do status dos pedidos no sistema</li>
              <li>
                Gestão da entrega da 1ª ou 2ª via com abrangência nacional
                (conforme a operadora)
              </li>
            </ul>
          </div>
          <div className="content__company-area">
            <h3>Para o trabalhador</h3>
            <ul>
              <li>
                Acesso ao autoatendimento para emissão de segunda via sem
                necessidade do RH
              </li>
              <li>
                Possibilidade de menores descontos, de acordo com a recarga
                feita no mês
              </li>
              <li>Mais transparência no uso de vale-transporte</li>
              <li>
                Acesso às disponibilizações das recargas no SuperApp (para quem
                tem PAT)
              </li>
            </ul>
          </div>
        </div>
      </SectionHolder>
    </PageSection>
  );
}

const SectionHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',

  paddingTop: '3rem',
  paddingBottom: '3rem',

  '.employees-company-benefits-section__content': {
    display: 'flex',
    gap: '6rem',

    '@deviceLg': {
      gap: '3rem',
    },

    '@deviceMd': {
      flexDirection: 'column',
    },
  },

  '.content__employees-area, .content__company-area': {
    h3: {
      color: '$black',
      fontSize: convertPixelToRem(18),
      marginBottom: '1rem',
    },

    ul: {
      listStyle: 'inside',

      li: {
        color: '$black',
        fontSize: '0.75rem',

        '& + li': {
          marginTop: '1rem',
        },
      },
    },
  },
});
