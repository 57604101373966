import { Emissor } from '@helpers/enum/emissor';

export const DEFAULT_INITIAL_PAGE_LINK = '/';
export const DEFAULT_CHANNEL = 'default';
export const LANDING_PAGE_TYPE = 'EMISSOR';
export const LANDING_PAGE_SECTION_PREFIX = 'LOJA';
export const IS_BILLET_ON_SCREEN_ENABLE = false;

export const MAX_EXTRA_CONTACTS = 2;
export const DEFAULT_EMPLOYEES_AMOUNT = 1;

export const SIGLA_CANAL_DEFAULT = 'default';
export const SENSEDIA_AUTH = {
  [Emissor.VRPAT]: {
    client_id_prd: '6f47347f-7950-3b38-afbd-6a773bed1cb3',
    client_id_dev: '1b6834d2-e68e-36c3-80da-c2164bf5e485',
    basic_prd:
      'NmY0NzM0N2YtNzk1MC0zYjM4LWFmYmQtNmE3NzNiZWQxY2IzOjE1MzcyMGJlLTc0YTUtMzQwOC05YTg5LTg3YmVkOTllMjMzMw==',
    basic_dev:
      'MWI2ODM0ZDItZTY4ZS0zNmMzLTgwZGEtYzIxNjRiZjVlNDg1Ojc3MzA3MDAwLTdlYTMtMzIxYy1hOWIzLTc3Nzk5ODM1MWU4OA==',
  },
  [Emissor.JVCEF]: {
    client_id_prd: 'd9d207fd-4a0e-3b65-a084-32d7269adbf0',
    client_id_dev: '5bce741b-7dbd-3a72-a5e2-277c270f679f',
    basic_prd:
      'ZDlkMjA3ZmQtNGEwZS0zYjY1LWEwODQtMzJkNzI2OWFkYmYwOjdmZDBlYzYxLTRmMWItMzVhYS04YzdhLTg5OGRjYTFkNjRhMA==',
    basic_dev:
      'NWJjZTc0MWItN2RiZC0zYTcyLWE1ZTItMjc3YzI3MGY2NzlmOmEyOTJkNzFlLTk3NDUtM2VhNy1iMGZhLWM0YzgzMDdiMWExMA==',
  },
};

export const SECTION_NAME_LIST = [
  'LOJA_SECTION_BENEFITS',
  'LOJA_SECTION_AWARDS',
  'LOJA_SECTION_CONTACT',
  'LOJA_SECTION_FAQ',
  'LOJA_VT_SECTION_FAQ_PREVIEW',
  'LOJA_WALLET_SECTION_FAQ',
  'LOJA_WALLET_SECTION_BENEFITS',
] as const;

export const DIGIT_WAIT_TIME_TO_PRICE = 1000;

// Lista de palavras de rota que o carrinho não deve aparecer
export const FILTER_LOCATION_KEYWORDS_CART = ['pedido', 'combo'];

export const BILLET_NAME = 'boleto';
export const CONTRACT_NAME = 'contrato';

// Nome do cookie que será armazenado o consentimento do uso de cookies pelo usuário
export const COOKIE_CONSENT_NAME = 'vrCookiesConsent';
export const COOKIES_CLIENT_ID_LABEL = 'vrClientID';

export const CHECKOUT_PATHNAME_LIST = ['/pedido/checkout', '/combo/checkout'];

export const BACK_BUTTON_PAGE_BLACK_LIST = [
  ...CHECKOUT_PATHNAME_LIST,
  '/oferta',
];

export const STITCHES_PREFIX = 'lojavr-style';

export const LOADING_DEFAULT_MESSAGE =
  'Aguarde um momento, estamos carregando o conteúdo';

export const QUERY_PARAM_INITIAL_PAGE_BLACK_LIST = ['offerid'];
