/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BannerType } from '@extra-types/banner-type';

import { fetchBannerInformationData } from '@store/thunks/banner-information-thunk';

interface BannerInformationState {
  isLoading: boolean;
  bannerList: BannerType[];
}

export const initialState = {
  isLoading: true,
  bannerList: [],
} as BannerInformationState;

const bannerInformationSlice = createSlice({
  name: 'banner-information',
  initialState,
  reducers: {
    setLandingPageData: (state, action: PayloadAction<BannerType[]>) => {
      state.bannerList = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchBannerInformationData.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchBannerInformationData.fulfilled, (state, action) => {
        const formatedBannerList: BannerType[] = action.payload.map(banner => {
          return {
            id: banner.id,
            bgColor: banner.bgColor,
            borderColor: banner.borderColor,
            description: banner.descricaoBanner,
            destinationURL: banner.urlDestino,
            displayOrder: banner.ordemExibicao,
            imgURL: banner.urlBanner,
          };
        });

        state.bannerList = state.bannerList.concat(formatedBannerList);

        state.isLoading = false;
      });
  },
});

export const { setLandingPageData } = bannerInformationSlice.actions;

export default bannerInformationSlice.reducer;
