import { ProductType } from '@extra-types/product-type';

export function validateIfCustomerHasContractedAllAvailableProducts(
  availableProductListFilteredByModality: ProductType[],
) {
  return (
    availableProductListFilteredByModality.filter(
      product => product.offerType === 'SIMULE_COMPRE',
    ).length === 0
  );
}
