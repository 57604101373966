import { Input } from '@components/input';
import { OrderRevisionServiceTableWalletBalance } from '@components/order-revision-service-table-components/order-revision-service-table-wallet-balance';
import { ProductImage } from '@elements/product-image';

import { ModalityType } from '@extra-types/modality-type';
import { ProductPricedFullDataType } from '@extra-types/product-priced-full-data-type';
import { completeWithLeftZero } from '@helpers/string-helpers';

import { styled } from '@src/themes';

import { OrderRevisionServiceTableBonusList } from '../order-revision-service-table-bonus-list';

interface OrderRevisionTableBenefitServiceWalletProps {
  modalityData: ModalityType;
  productPricedFullDataList: ProductPricedFullDataType[];
  isCollapsed: boolean;
}

export function OrderRevisionTableBenefitServiceWallet({
  modalityData,
  productPricedFullDataList,
  isCollapsed,
}: OrderRevisionTableBenefitServiceWalletProps) {
  return (
    <OrderRevisionTableBenefitServiceWalletContainer
      css={{
        $$modalityColor: `$colors$${modalityData.colorPrefixToken}PrimaryDark`,
      }}
    >
      <div className="order-revision-wallet__resume">
        <div className="resume__wallet-data">
          <ProductImage productId={modalityData.id} />
          <span>{modalityData.title}</span>
        </div>
        <div className="resume__wallet-card-amount">
          <span>Qnt. cartões</span>
          <Input.Default
            name={`${modalityData.keyId}-card-amount`}
            value={completeWithLeftZero(
              productPricedFullDataList[0].cardAmount,
            )}
            disabled
          />
        </div>
      </div>
      <div className="order-revision-wallet__details">
        <OrderRevisionServiceTableWalletBalance
          productPricedList={productPricedFullDataList}
          isCollapsed={isCollapsed}
        />
        <OrderRevisionServiceTableBonusList
          isCollapsed={isCollapsed}
          productAddonList={[]}
        />
      </div>
    </OrderRevisionTableBenefitServiceWalletContainer>
  );
}

const OrderRevisionTableBenefitServiceWalletContainer = styled('div', {
  '.order-revision-wallet__resume': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',

    padding: '1rem',

    '@deviceSm': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '0.5rem',
    },
  },

  '.resume__wallet-data': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    maxWidth: '14rem',
    width: '100%',

    img: {
      maxHeight: '3.25rem',
      objectFit: 'contain',
    },

    span: {
      color: '$$modalityColor',
      fontSize: '0.75rem',
      fontWeight: '$bold',
    },

    '@deviceSm': {
      img: {
        maxHeight: '2.5rem',
      },
    },
  },

  '.resume__wallet-card-amount': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    '> div': {
      padding: 0,
    },

    '@deviceSm': {
      justifyContent: 'space-between',

      width: '100%',
    },
  },

  '.order-revision-wallet__details': {
    overflow: 'hidden',
    padding: '0 1rem',
  },
});
