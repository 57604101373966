import { CustomerModalCompanyMessage } from '@components/customer-modal-company-message';
import { Button } from '@elements/button';

import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';

import { useDispatch } from '@hooks/use-dispatch';
import { useRoutes } from '@hooks/use-routes';
import { useSelector } from '@hooks/use-selector';
import { closeModal } from '@store/slices/customer-slice';
import { fetchRegisterCart } from '@store/thunks/customer-thunk';

import { OfferFacade } from '@src/facade/offer-facade';

function HasProductsContracted() {
  const dispatch = useDispatch();
  const { username, isLoading } = useSelector(({ customer }) => customer);
  const { nextStep } = useRoutes();
  const { selectedOffer } = OfferFacade();

  const handleUserCart = () => {
    dispatch(fetchRegisterCart({ offerData: selectedOffer }))
      .unwrap()
      .then(() => {
        dispatch(closeModal());
        nextStep();
      });
  };

  return (
    <>
      <img
        src={`${env.URL_ASSETS + ASSETS_OTHERS_PATH}/customer-illustration.svg`}
        alt="Ilustração de um homem segurando um copo com uma bebida quente."
      />
      <h2>Olá {username}!</h2>
      <CustomerModalCompanyMessage />
      <Button.Default
        id="btn-modal-continuar-contratacao-cliente-base"
        type="button"
        css={{ width: '100%' }}
        onClick={handleUserCart}
        disabled={isLoading}
        loading={isLoading}
      >
        Continuar
      </Button.Default>
    </>
  );
}

export default HasProductsContracted;
