import { CartIndividualModalityView } from '@components/cart-modal-components/cart-modal-modalities/cart-individual-modality-view';
import { CartWalletModalityView } from '@components/cart-modal-components/cart-modal-modalities/cart-wallet-modality-view';
import { CartModalEmptyContent } from '@elements/cart-modal-elements/cart-modal-empty-content';
import { LoadingViewMessage } from '@elements/loading-view-message';

import { MODALITY_ID_NOT_FOUND } from '@config/modality-config';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { scrollbarStyle } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

import { CartHCMModalityView } from './cart-modal-modalities/cart-hcm-modality-view';

export const CartContentContainer = styled('div', {
  flex: 1,

  padding: '0 1rem',
  overflow: 'auto',

  ...scrollbarStyle,
});

function CartModalSelectModalityView() {
  const { productCartList, cartModality } = useSelector(({ cart }) => cart);
  const isLoading = useSelector(({ products }) => products.isLoading);

  if (isLoading) {
    return <LoadingViewMessage verticalPadding />;
  }

  if (productCartList.length === 0 || cartModality === undefined) {
    return <CartModalEmptyContent />;
  }

  if (cartModality.id === MODALITY_ID_NOT_FOUND) {
    return <CartModalEmptyContent error />;
  }

  if (cartModality.id === ModalitiesId.HCM) {
    return <CartHCMModalityView />;
  }

  if (cartModality.isWalletGroupCard) {
    return <CartWalletModalityView />;
  }

  return <CartIndividualModalityView />;
}

export function CartModalContentView() {
  return (
    <CartContentContainer>
      <CartModalSelectModalityView />
    </CartContentContainer>
  );
}
