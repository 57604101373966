import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { CartModalBackgroundOverflow } from '@components/cart-modal-components/cart-modal-background-overflow';
import { CartModal } from '@compositions/cart-modal';

import { FILTER_LOCATION_KEYWORDS_CART } from '@config/application-config';
import { calcAmountProductsFromCart } from '@helpers/cart-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { toggleCartModalState } from '@store/slices/cart-slice';

import { CartButton, CartHolder } from './styles';

type CartType = {
  isCustomChannel?: boolean;
};

export function Cart({ isCustomChannel = false }: CartType) {
  const { cartModality, productCartList } = useSelector(({ cart }) => cart);
  const dispatch = useDispatch();
  const location = useLocation();

  const cartAmount = calcAmountProductsFromCart({
    cartmodalityId: cartModality?.id,
    productCartList,
  });

  const isNotVisible = useMemo(
    () =>
      FILTER_LOCATION_KEYWORDS_CART.some(word =>
        location.pathname.includes(word),
      ),
    [location.pathname],
  );

  if (isNotVisible) {
    return null;
  }

  return (
    <>
      <CartHolder>
        <CartButton
          id="btn-meu-carrinho"
          type="button"
          title="Meu carrinho"
          onClick={() => dispatch(toggleCartModalState())}
          isCustomChannel={isCustomChannel}
          css={{
            i: {
              fontWeight: cartAmount > 0 ? '$bold' : '$light',
            },
          }}
        >
          <i className="fa-light fa-cart-shopping" />
          {cartAmount > 0 && (
            <span className="cart-button__badge">
              {cartAmount < 100 ? cartAmount : '99+'}
            </span>
          )}
        </CartButton>
        <CartModal />
      </CartHolder>
      <CartModalBackgroundOverflow />
    </>
  );
}
