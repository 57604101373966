import { useContext } from 'react';

import { FAQContext, FAQContextData } from '@store/context/faq-context';

export function useFAQContext(): FAQContextData {
  const context = useContext(FAQContext);

  if (!context) {
    throw new Error('useFAQContext must be used within a FAQContext.');
  }

  const {
    allFaqArticles,
    faqCategories,
    activeCategory,
    selectedArticle,
    getArticlesByCategoryId,
    handleSelectedArticle,
    handleChangeCategory,
    getCategoryById,
    searchTypedValue,
    searchedValue,
    notFoundArticle,
    backToFAQ,
    handleSearchChange,
  } = context;

  return {
    allFaqArticles,
    faqCategories,
    activeCategory,
    getArticlesByCategoryId,
    handleSelectedArticle,
    selectedArticle,
    handleChangeCategory,
    getCategoryById,
    searchTypedValue,
    searchedValue,
    notFoundArticle,
    backToFAQ,
    handleSearchChange,
  };
}
