import { DefaultTokenVariantsType } from '@extra-types/token-variants-type';

import { styled } from '@src/themes';

export const IconVRContainer = styled('svg', {
  width: '10rem',
  height: '10rem',

  variants: {
    variant: {
      primary: {
        fill: '$silverDark',
      },
      secondary: {
        fill: '$white',
      },
    },
  },
});

export interface IconVRProps {
  variant?: DefaultTokenVariantsType;
}

export function IconVR({ variant = 'primary', ...rest }: IconVRProps) {
  return (
    <IconVRContainer
      variant={variant}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      {...rest}
    >
      <path d="M892.57 30.79H30.79v861.77c0 86.52 70.13 156.65 156.62 156.65h861.8v-861.8c0-86.49-70.13-156.62-156.65-156.62Zm-60.5 777.97c-54.83 0-88.03-24.24-108.05-76.43l-74.35-195.56c8.98 2.12 20.04 2.62 30.58 2.62 45.84 0 71.17-15.82 71.17-44.8s-20.56-41.62-62.75-41.62h-48.46L504.19 808.76H312.85c-34.81 0-54.83-14.76-67.49-46.93L125.19 452.97h128.62c34.81 0 55.89 15.28 66.95 47.96L374.01 658h21.08l119.65-328.91h230.88c113.85 0 177.12 53.76 177.12 150.21 0 52.18-25.84 95.43-73.29 121.77 6.86 13.7 15.3 33.74 27.94 67.47 34.26 90.67 54.83 118.61 76.45 140.21H832.07Z" />
    </IconVRContainer>
  );
}
