/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

import { AccordionItem } from '@elements/accordion-item';

import { AccordionItemType } from '@extra-types/accordion-item-type';

import { styled } from '@src/themes';

interface AccordionProps {
  data: AccordionItemType[];
}

export function AccordionList({ data }: AccordionProps) {
  const [activeItemID, setActiveItemID] = useState<string | undefined>();

  const handleChangeActiveItem = (id: string) => {
    if (activeItemID !== id) {
      setActiveItemID(id);
    } else {
      setActiveItemID(undefined);
    }
  };

  return (
    <AccordionContainer>
      {data.map(dataItem => (
        <AccordionItem
          data-testid={dataItem.id}
          key={dataItem.id}
          isActive={dataItem.id === activeItemID}
          changeActiveAction={handleChangeActiveItem}
          data={dataItem}
        />
      ))}
    </AccordionContainer>
  );
}

const AccordionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  width: '100%',

  '@deviceSm': {
    padding: '0 1rem',
  },
});
