import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ContractModalPreview } from '@compositions/contract-modal-preview';
import { OrderRevisionServiceTable } from '@compositions/order-revision-service-table';
import { Button } from '@elements/button';
import { SimplePageTitle } from '@elements/simple-page-title';

import { OrderRevisionServiceType } from '@extra-types/order-revision-service-type';
import { ApiErrorsModalTitle } from '@helpers/enum/api-error-messages';
import { getMessageError } from '@helpers/error-helpers';
import { isComboFlux } from '@helpers/flux-helpers';
import {
  getModalityData,
  getModalityListByProductList,
  sortModalitiesByDisplayOrder,
} from '@helpers/modality-helpers';
import { removeSpecificQueryParamFromSearch } from '@helpers/query-string-helpers';
import { getServiceList } from '@helpers/service-helpers';
import { getFirstName } from '@helpers/string-helpers';

import { useContract } from '@hooks/use-contract';
import { useDispatch } from '@hooks/use-dispatch';
import { useRoutes } from '@hooks/use-routes';
import { useSelector } from '@hooks/use-selector';
import { handleWarningModalContent } from '@store/slices/warning-modal-slice';

import ApplicationError from '@errors/types/application-error';

import { OfferFacade } from '@src/facade/offer-facade';

import { ComboOrderRevisionPageContainer } from './styles';

export function ComboOrderRevisionPage() {
  const { prevStep } = useRoutes();
  const location = useLocation();
  const dispatch = useDispatch();
  const { selectedOffer } = OfferFacade();

  const [contractBlobData, setContractBlobData] = useState<Blob | undefined>();
  const initialPage = useSelector(({ application }) => application.initialPage);
  const [isContractModalVisible, setContractModalVisibility] = useState(false);

  const { isLoading: isContractDownloadLoading, getContractAsBlob } =
    useContract();

  const { comboCartPricingData, cartPricingData } = useSelector(
    ({ cart }) => cart,
  );
  const { productList: apiProductDataList, modalityList } = useSelector(
    ({ products }) => products,
  );
  const { username } = useSelector(({ customer }) => customer);

  const productPricedDataList = isComboFlux(selectedOffer)
    ? comboCartPricingData
    : cartPricingData;

  if (apiProductDataList.length === 0 || !productPricedDataList) {
    return (
      <Navigate
        to={{
          pathname: initialPage,
          search: removeSpecificQueryParamFromSearch({
            queryString: 'offerid',
            search: location.search,
          }),
        }}
        state={{ from: location.pathname }}
      />
    );
  }

  const apiProductPricedList = apiProductDataList.filter(apiProduct =>
    productPricedDataList.detalhe.produtos.some(
      productPriced => productPriced.codigoProduto === apiProduct.id,
    ),
  );

  const serviceList = getServiceList(
    sortModalitiesByDisplayOrder(
      getModalityListByProductList(apiProductPricedList).map(modalityId =>
        getModalityData(modalityId, modalityList),
      ),
    ),
  );

  const orderRevisionDataGroupedByService: OrderRevisionServiceType[] =
    serviceList.map(service => {
      const productListByService = apiProductPricedList.filter(product =>
        service.modalityList.some(
          modality => modality.id === product.modalityId,
        ),
      );

      const productPricingListFilterByService =
        productPricedDataList.detalhe.produtos.filter(product =>
          productListByService.some(
            productModality => productModality.id === product.codigoProduto,
          ),
        );

      const serviceResumeData =
        productPricedDataList?.resumo.servicos[service.id];

      return {
        service: {
          id: service.id,
          title: service.title,
        },
        productPricedList: productPricingListFilterByService,
        pricingResumeData: serviceResumeData,
      };
    });

  const handleModalVisibility = () => {
    setContractModalVisibility(state => !state);
  };

  const handleContractDownload = () => {
    if (contractBlobData) {
      setContractModalVisibility(true);
    } else {
      getContractAsBlob()
        .then(data => {
          setContractBlobData(data);
          setContractModalVisibility(true);
        })
        .catch(error => {
          dispatch(
            handleWarningModalContent({
              isVisible: true,
              title: ApiErrorsModalTitle.DEFAULT,
              content: getMessageError(error),
              closeAction: {
                label: 'Fechar',
                action:
                  error instanceof ApplicationError ? 'close' : 'reloadPage',
              },
            }),
          );
        });
    }
  };

  return (
    <>
      <ComboOrderRevisionPageContainer>
        <SimplePageTitle css={{ textAlign: 'left' }}>
          Pronto, {getFirstName(username)}! Por favor, confirme os dados abaixo
          para gerar seu contrato:
        </SimplePageTitle>
        <div className="order-revision-page-container__order-revision-service-data-group">
          {orderRevisionDataGroupedByService.map(orderServiceData => (
            <OrderRevisionServiceTable
              key={orderServiceData.service.id}
              data={orderServiceData}
            />
          ))}
        </div>

        <div className="order-revision-page-container__total-order-wrapper">
          <p>VALOR TOTAL DO PACOTE*</p>
          <strong>
            {formatValueToCurrency(productPricedDataList.resumo.valorTotal)}/mês
          </strong>
        </div>
        <div className="order-revision-page-container__payment-warning-message">
          <i className="fa-solid fa-circle-exclamation" />
          <span>
            *Os pagamentos serão realizados de forma apartada por serviço.
          </span>
        </div>
        <div className="order-revision-page-container__buttons-wrapper">
          <Button.Default
            id="btn-revisao-pedido-voltar"
            outline
            onClick={() => prevStep()}
            disabled={isContractDownloadLoading}
          >
            <i className="fa-regular fa-arrow-left" />
            Voltar
          </Button.Default>
          <Button.Default
            id="btn-revisao-gerar-contrato"
            loading={isContractDownloadLoading}
            onClick={handleContractDownload}
          >
            Gerar contrato
          </Button.Default>
        </div>
      </ComboOrderRevisionPageContainer>
      <ContractModalPreview
        handleModalVisibility={handleModalVisibility}
        modalVisibility={isContractModalVisible}
        contractFileBlob={contractBlobData}
      />
    </>
  );
}
