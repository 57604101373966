import { useState } from 'react';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ComboTaxTable } from '@pages/offer-page/custom-offer-pages/components/combo-summary-components/combo-tax-table';

import { ModalityType } from '@extra-types/modality-type';
import { PricingDetailProductType } from '@extra-types/pricing-types';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { isComboFlux } from '@helpers/flux-helpers';
import { getPricingResumeData } from '@helpers/pricing-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

import { ComboServiceSummaryExtraInformation } from '../components/combo-summary-components/combo-service-summary-extra-information';
import { ComboSummaryModalityDetails } from '../components/combo-summary-components/combo-summary-modality-details';

export function ComboServiceSummary({
  modalityData,
  externalHandleActiveState,
  externalActiveState,
}: {
  modalityData: ModalityType;
  externalActiveState?: boolean;
  externalHandleActiveState?: () => void;
}) {
  const [isCollapsed, setCollapseState] = useState(true);
  const { selectedOffer } = OfferFacade();
  const { comboCartPricingData, cartPricingData, planType, productCartList } =
    useSelector(({ cart }) => cart);
  const { productList } = useSelector(({ products }) => products);
  const isActive =
    externalActiveState !== undefined ? externalActiveState : !isCollapsed;

  const productPricedDataList = isComboFlux(selectedOffer)
    ? comboCartPricingData
    : cartPricingData;

  const productListByService = productList.filter(
    product => modalityData.id === product.modalityId,
  );
  const productPricingListByService: PricingDetailProductType[] =
    productPricedDataList
      ? productPricedDataList.detalhe.produtos.filter(product =>
          productListByService.some(
            productModality => productModality.id === product.codigoProduto,
          ),
        )
      : [];

  const handleActiveState = () =>
    externalHandleActiveState !== undefined
      ? externalHandleActiveState()
      : setCollapseState(state => !state);

  if (!productPricedDataList || productPricingListByService.length === 0) {
    return (
      <ComboServiceSummaryContainer>
        <div className="combo-cart-page-summary-details-container__header">
          <h3>{modalityData.title}</h3>
        </div>
      </ComboServiceSummaryContainer>
    );
  }

  const totalServiceBenefityOrder = () => {
    const extensionsValue = productCartList.flatMap(
      plan => plan.additionalProducts?.map(product => product.value) || [],
    );

    if (planType === PlanTypeEnum.ANUAL) {
      return [
        ...[
          productPricedDataList.detalhe.produtos.find(
            product => product.tipoProduto === ServiceGroupId.HCM,
          )?.valorProdutoComDesconto || 0,
        ],
        ...extensionsValue,
      ].reduce((acc, value) => acc + value, 0);
    }
    return (
      productPricedDataList.resumo?.servicos[modalityData.serviceGroupId]
        .valorTotalBeneficios || 0
    );
  };

  return (
    <ComboServiceSummaryContainer>
      <div className="combo-cart-page-summary-details-container__header">
        <div className="header__service-information">
          <h3>{modalityData.title}</h3>
          {!isActive && (
            <span className="header__service-total-value">
              {formatValueToCurrency(totalServiceBenefityOrder())}
            </span>
          )}
        </div>
        {!isActive && selectedOffer && (
          <div className="header__service-extra-information">
            <ComboServiceSummaryExtraInformation
              serviceId={modalityData.id.toString()}
              productListByService={productListByService}
              productPricingListByService={productPricingListByService}
            />
          </div>
        )}
      </div>
      <ComboSummaryDetailsEntryListContainer isActive={isActive}>
        <ComboSummaryModalityDetails
          key={modalityData.id}
          modalityData={modalityData}
          productPricingList={productPricingListByService}
        />
        <ComboSummaryDetailsProductTotalValueContainer>
          <p>Subtotal</p>
          <p className="total-value">
            {formatValueToCurrency(totalServiceBenefityOrder())}
          </p>
        </ComboSummaryDetailsProductTotalValueContainer>
        <ComboEntryTaxContainer>
          <ComboTaxTable
            serviceId={modalityData.id.toString()}
            variant="summary-view"
            pricingResumeData={getPricingResumeData(
              productPricedDataList,
              modalityData.serviceGroupId,
            )}
          />
        </ComboEntryTaxContainer>
        <ComboSummaryDetailsProductTotalValueContainer>
          <p>Valor total</p>
          <p className="total-value">
            {formatValueToCurrency(totalServiceBenefityOrder())}
          </p>
        </ComboSummaryDetailsProductTotalValueContainer>
      </ComboSummaryDetailsEntryListContainer>
      <ComboSummaryDetailsShowMore
        type="button"
        isActive={isActive}
        onClick={handleActiveState}
      >
        <i className="fa-solid fa-chevron-up" />
        mostrar {isActive ? 'menos' : 'mais'}
      </ComboSummaryDetailsShowMore>
    </ComboServiceSummaryContainer>
  );
}

const ComboServiceSummaryContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  background: '$white',
  width: '100%',
  borderRadius: '6px',
  boxShadow: '0px 0px 0.5rem #00000021',

  '.combo-cart-page-summary-details-container__header': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    padding: '1rem',

    '.header__service-information, .header__service-extra-information': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      flex: 1,
    },

    '.header__service-extra-information': {
      fontSize: '0.75rem',
      fontWeight: '$medium',
    },

    h3: {
      fontSize: '0.75rem',
      textTransform: 'uppercase',
    },

    'span.header__service-total-value': {
      color: '$primaryPure',
      fontSize: '0.75rem',
      fontWeight: '$bold',
    },
  },
});

const ComboSummaryDetailsEntryListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  padding: '0 1rem',
  overflow: 'hidden',
  opacity: 1,
  height: '100%',
  transition: 'opacity 0.4s ease',

  variants: {
    isActive: {
      false: {
        height: 0,
        opacity: 0,
        marginBottom: '0',
      },
    },
  },
  defaultVariants: {
    isActive: false,
  },
});

const ComboEntryTaxContainer = styled('div', {
  '> div:first-child': {
    borderTop: '1px solid $silverLight',
  },

  '> div': {
    borderBottom: '1px solid $silverLight',
  },
});

const ComboSummaryDetailsProductTotalValueContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  marginBottom: '1rem',

  fontSize: '0.75rem',

  p: {
    color: '$fontColorPrimary',
    fontWeight: '$bold',

    '&.total-value': {
      color: '$primaryPure',
    },
  },
});

const ComboSummaryDetailsShowMore = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',

  background: '$silverLight',
  padding: '0.5rem',
  borderRadius: '0 0 6px 6px',

  color: '$primaryPure',
  fontSize: convertPixelToRem(10),
  fontWeight: '$bold',

  i: {
    transition: 'transform 0.4s ease',
  },
  variants: {
    isActive: {
      false: {
        i: {
          transform: 'rotate(180deg)',
        },
      },
    },
  },
});
