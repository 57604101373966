import { ModalContainer } from '@components/modal/styles';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const ContractModalPreviewContainer = styled(ModalContainer, {
  '.modal-box': {
    '&.modal-box__content': {
      marginTop: '1.5rem',
    },

    '&:not(.modal-box__content)': {
      paddingTop: '1.5rem',
    },

    '@media screen and (orientation: landscape) and (max-height: 450px)': {
      '&.modal-box__content': {
        marginTop: '1rem',
      },

      '&:not(.modal-box__content)': {
        paddingTop: '1rem',
      },
    },
  },

  '.modal-box__container': {
    flex: 1,
    gap: 0,

    width: 'calc(100% - 2rem)',
    maxWidth: convertPixelToRem(1032),
    margin: '0 1rem',
    height: '80%',
    padding: '2rem 0 !important',

    hgroup: {
      margin: '0 2rem',

      '@deviceSm': {
        margin: '0 1rem',
      },
    },

    button: {
      strong: {
        textDecoration: 'underline',
      },
    },

    '@deviceSm': {
      width: '100%',
      maxWidth: '100% !important',
      padding: '2rem 0 1.5rem !important',
      margin: '0',

      '.modal-subtitle': {
        display: 'none',
      },
    },

    '@media screen and (orientation: landscape) and (max-height: 450px)': {
      '.modal-subtitle': {
        display: 'none',
      },
    },
  },

  '.modal-box__content': {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: '#DDDDDD',
    overflowY: 'auto',
    height: '100%',
    padding: '0.5rem 0',

    '.loader': {
      alignSelf: 'center',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      backgroundColor: '$silverLight',
    },

    '&::-webkit-scrollbar': {
      width: '7px',
      backgroundColor: '$white',
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '$primaryPure',
    },

    '@deviceSm': {
      padding: '0.5rem 0',
    },

    '@media screen and (orientation: landscape) and (max-height: 450px)': {
      maxHeight: '150px',
    },
  },

  '.modal-box__text': {
    display: 'flex',
    gap: '3rem',
    margin: '0 2rem',

    '.text__group': {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',

      p: {
        textAlign: 'start',
        fontWeight: '$medium',
        lineHeight: '1.4',
      },
    },
  },

  '.modal-box__checkbox-area': {
    position: 'relative',
    padding: '0 2rem',
    boxShadow: '0px 0px 48px #00000080',

    '@deviceSm': {
      padding: '0 1rem',
    },

    '@media screen and (orientation: landscape) and (max-height: 450px)': {
      fontSize: '0.875rem',
    },
  },

  '.modal-box__action-area': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.25rem',
    backgroundColor: '$white',
    position: 'relative',

    width: '100%',
    padding: '0 2rem',

    'button, a': {
      width: '100%',
      maxWidth: '20rem',
    },

    '@deviceSm': {
      flexDirection: 'column-reverse',
      padding: '0 1rem',
      gap: '0.75rem',

      'button, a': {
        width: '100%',
        maxWidth: '100%',
      },
    },

    '@media screen and (orientation: landscape) and (max-height: 450px)': {
      button: {
        padding: '0.5rem 1rem',
        fontSize: '0.875rem',
      },
    },
  },

  variants: {
    isPDFLoading: {
      true: {
        '.modal-box__content': {
          alignItems: 'center',
          backgroundColor: 'transparent',
        },
        '.modal-box__checkbox-area': {
          boxShadow: 'unset',
        },
      },
    },
  },
});
