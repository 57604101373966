import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class NotCatalogedError extends ApplicationError {
  constructor(message?: string) {
    super(
      SpecificsApiErrors.NOT_CATALOGED,
      message || ApiErrorsMessages.NOT_CATALOGED,
    );
  }
}
