import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import Details from '@elements/details';

import { HCMOptions, additionalProducts } from '@config/hcm-config';
import { ModalityType } from '@extra-types/modality-type';
import { PricingDetailProductType } from '@extra-types/pricing-types';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { completeWithLeftZero } from '@helpers/string-helpers';

import { useSelector } from '@hooks/use-selector';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

import { SvaDetailsSimplified } from './sva-details-simplified';

interface ComboSummaryModalityDetailsProps {
  modalityData: ModalityType;
  productPricingList: PricingDetailProductType[];
}

export function ComboSummaryModalityDetails({
  modalityData,
  productPricingList,
}: ComboSummaryModalityDetailsProps) {
  const { selectedOffer } = OfferFacade();
  const { productList } = useSelector(({ products }) => products);
  const { requiredAdditionalProductList, extraAdditionalProductList } =
    useSelector(({ cart }) => cart);

  const productListByModality = productList.filter(
    product => product.modalityId === modalityData.id,
  );

  const productPricingListByModality = productPricingList
    ? productPricingList
        .filter(product =>
          productListByModality.some(
            productModality => productModality.id === product.codigoProduto,
          ),
        )
        .map(productPricing => ({
          ...productPricing,
          nomeProduto:
            productList.find(
              product => product.id === productPricing.codigoProduto,
            )?.name ?? 'Não encontrado',
        }))
    : [];

  if (modalityData.isWalletGroupCard) {
    return (
      <ComboSummaryModalityDetailsContainer>
        <div className="combo-summary-modality-details__modality-information">
          <div className="modality-information__top-area">
            <span className="top-area__product-name">
              {modalityData.shelfInformation.title}
            </span>
          </div>
          <div className="modality-information__bottom-area">
            <span>Quantidade de cartões</span>
            <span>
              {completeWithLeftZero(productPricingListByModality[0].quantidade)}
            </span>
          </div>
        </div>
        <div className="combo-summary-modality-details__product-list">
          {productPricingListByModality.map(product => (
            <div
              key={product.codigoProduto}
              className="product-item__container"
            >
              <span className="product-name">{product.nomeProduto}</span>
              <div className="product-information__value-area">
                <span>Valor por saldo</span>
                <span>{formatValueToCurrency(product.valorMensal)}</span>
              </div>
            </div>
          ))}
        </div>
        <SvaDetailsSimplified />
      </ComboSummaryModalityDetailsContainer>
    );
  }

  if (modalityData.id === ModalitiesId.HCM) {
    return (
      <ComboSummaryModalityDetailsProductList>
        {productPricingListByModality.map(productPricing => (
          <ComboSummaryModalityDetailsContainer
            key={productPricing.codigoProduto}
          >
            <div className="combo-summary-modality-details__modality-information">
              <div className="modality-information__top-area">
                <span className="top-area__product-name">
                  {productPricing.nomeProduto}
                </span>
              </div>
              <div className="modality-information__bottom-area">
                <strong>
                  {
                    HCMOptions[productPricing.codigoProduto].find(
                      option => option.value === productPricing.quantidade,
                    )?.label
                  }{' '}
                  trabalhadores
                </strong>
              </div>
              <Details summary="Detalhes do Plano" variant="secondary">
                <div className="combo-additional-products-content">
                  {selectedOffer
                    ? additionalProducts[productPricing.codigoProduto].map(
                        product => (
                          <div key={product.id}>
                            <strong>{product.name}</strong>
                            {product.value && (
                              <span>
                                {product.value} {product.label}
                              </span>
                            )}
                          </div>
                        ),
                      )
                    : [
                        ...requiredAdditionalProductList.required,
                        ...extraAdditionalProductList,
                      ].map(additionalProduct => (
                        <div key={additionalProduct.id}>
                          <strong>{additionalProduct.name}</strong>
                        </div>
                      ))}
                </div>
              </Details>
            </div>
          </ComboSummaryModalityDetailsContainer>
        ))}
      </ComboSummaryModalityDetailsProductList>
    );
  }

  return (
    <ComboSummaryModalityDetailsProductList>
      {productPricingListByModality.map(productPricing => (
        <ComboSummaryModalityDetailsContainer
          key={productPricing.codigoProduto}
        >
          <div className="combo-summary-modality-details__modality-information">
            <div className="modality-information__top-area">
              <span className="top-area__product-name">
                {productPricing.nomeProduto}
              </span>
              <span>{formatValueToCurrency(productPricing.valorMensal)}</span>
            </div>
            <div className="modality-information__bottom-area">
              <span>Quantidade de cartões</span>
              <span>{completeWithLeftZero(productPricing.quantidade)}</span>
            </div>
          </div>
        </ComboSummaryModalityDetailsContainer>
      ))}
      <SvaDetailsSimplified />
    </ComboSummaryModalityDetailsProductList>
  );
}

const ComboSummaryModalityDetailsContainer = styled('div', {
  background: 'transparent',
  borderRadius: '0.25rem',

  '.combo-additional-products-content': {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0.5rem 0.5rem 1rem',
    gap: '0.25rem 0',

    div: {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  '.combo-summary-modality-details__modality-information': {
    padding: '0 0 1rem',

    fontSize: '0.75rem',
    lineHeight: 1.4,

    '.modality-information__top-area': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '0.5rem',

      fontWeight: '$medium',

      'span.top-area__product-name': {
        fontWeight: '$bold',
      },
    },

    '.modality-information__bottom-area': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '0.5rem',

      fontWeight: '$medium',
    },
  },

  '.product-item__container': {
    fontSize: '0.75rem',
    lineHeight: 1.4,

    '.product-name': {
      fontWeight: '$bold',
    },

    '.product-information__value-area': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '0.5rem',

      fontWeight: '$medium',
    },
  },

  '.combo-summary-modality-details__product-list': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    padding: '1rem 0',
    borderTop: '1px solid $silverPure',
  },
});

const ComboSummaryModalityDetailsProductList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
});
