import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificError from '@errors/specific-error';
import SpecificsApiErrors from '@errors/specifics-api-errors';

export default class ApplicationError implements Error {
  public code: number;

  public name = 'ApplicationError';

  public message: string;

  public static specificError: SpecificError;

  constructor(specificError?: SpecificError, message?: string) {
    ApplicationError.specificError =
      specificError || SpecificsApiErrors.NOT_CATALOGED;
    this.code = specificError?.code || SpecificsApiErrors.NOT_CATALOGED.code;
    this.message = `${this.code}: ${message || ApiErrorsMessages.DEFAULT}`;
  }

  public setMessage(message: string): void {
    this.message = `${this.code}: ${message}`;
  }
}
