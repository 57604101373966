import { CustomerModalCompanyMessage } from '@components/customer-modal-company-message';
import { Button } from '@elements/button';

import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { closeModal } from '@store/slices/customer-slice';

export default function SelectedOnlyContractedProducts() {
  const { username, isLoading } = useSelector(({ customer }) => customer);
  const dispatch = useDispatch();
  return (
    <>
      <img
        src={`${env.URL_ASSETS + ASSETS_OTHERS_PATH}/customer-illustration.svg`}
        alt="Ilustração de um homem segurando um copo com uma bebida quente."
      />
      <h2>Olá {username}!</h2>
      <CustomerModalCompanyMessage customMessage="Parece que você selecionou somente produtos que você já possui contratado!" />
      <Button.Default
        id="btn-modal-voltar-contratacao-cliente-base"
        onClick={() => {
          dispatch(closeModal());
        }}
        disabled={isLoading}
        loading={isLoading}
        css={{ width: '100%' }}
      >
        Voltar
      </Button.Default>
    </>
  );
}
