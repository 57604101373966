import VMasker from 'vanilla-masker';

export const NOT_NUMBER_REGEX = /[^0-9]/g;
export const CLEARED_CELLPHONE_LENGTH = 11;
export const TELEPHONE_MASK = '(999) 9999-9999';
export const CELLPHONE_MASK = '(999) 99999-9999';
export const OTHER_LOCATIONS_PHONE_MASK = '9999 999 9999';
export const ASTERISK = '*';

export function capitalizeString(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function replaceString(
  phrase: string,
  wordToBeReplace: string,
  word: string,
): string {
  return phrase.replace(wordToBeReplace, word);
}

export function maskPhone(phoneNumber: string): string {
  const completeDDDWithLeftZero = (value: number | string): string => {
    const valueNumber = Number(value);
    return `0${valueNumber}`;
  };

  const clearedValue = phoneNumber.replace(NOT_NUMBER_REGEX, '');
  const pattern =
    completeDDDWithLeftZero(clearedValue).length <= CLEARED_CELLPHONE_LENGTH
      ? TELEPHONE_MASK
      : CELLPHONE_MASK;

  return VMasker.toPattern(completeDDDWithLeftZero(phoneNumber), pattern);
}

export function maskOtherLocations(phoneNumber: string): string {
  const clearedValue = phoneNumber.replace(NOT_NUMBER_REGEX, '');
  const pattern = OTHER_LOCATIONS_PHONE_MASK;

  return VMasker.toPattern(clearedValue, pattern);
}

export function completeWithLeftZero(value: number | string) {
  if (value.toString().length < 2) {
    return `0${value}`;
  }

  return `${value}`;
}

export function getSingularOrPlural(
  amount: number,
  singularWord: string,
  pluralWord: string,
) {
  return amount === 1 ? singularWord : pluralWord;
}

export function getFirstName(fullName: string) {
  const cleanedName = fullName.trimStart();
  return cleanedName.split(' ')[0];
}

export function formatListToString(list: string[]) {
  return [...list]
    .map((item, index) => {
      if (index === 0) return item;

      if (list.length - 1 === index) {
        return ` e ${item}`;
      }

      return `, ${item}`;
    })
    .reduce((text, phrase) => text + phrase, '');
}

export function OnlyNumberValidate(value: string) {
  return /^\d+$/.test(value);
}

export function formatValueToPercentage(value: number) {
  return `${value}%`;
}
