import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { ProductReferenceIcon } from '@elements/order-revision-service-table-elements/product-reference-icon';

import { ASSETS_LOGOS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { ProductPricedFullDataType } from '@extra-types/product-priced-full-data-type';

import { styled } from '@src/themes';

import { ResumeWalletBalanceList } from './resume-wallet-balance-list';

interface OrderRevisionServiceTableWalletBalanceProps {
  productPricedList: ProductPricedFullDataType[];
  isCollapsed: boolean;
}

export function OrderRevisionServiceTableWalletBalance({
  productPricedList,
  isCollapsed,
}: OrderRevisionServiceTableWalletBalanceProps) {
  const totalProductPricedListValue = productPricedList.reduce(
    (totalValue, product) => totalValue + product.totalCardValue,
    0,
  );

  return (
    <>
      <ResumeWalletBalanceList
        productList={productPricedList}
        isCollapsed={isCollapsed}
      />
      <OrderRevisionServiceTableWalletBalanceContainer
        isCollapsed={isCollapsed}
      >
        <ul className="benefits-revision-table-container__balance-list-grid">
          <li className="balance-list-grid__header">
            <div className="grid-item--product-reference-cell" />
            <div className="grid-item grid-item__position--start">
              <span>Saldo</span>
            </div>
            <div className="grid-item grid-item__position--end">
              <span>Valor mensal (R$)</span>
            </div>
            <div className="grid-item grid-item__product-value--total-value grid-item__position--end">
              <span>Valor Total</span>
            </div>
          </li>
          {productPricedList.map(product => (
            <li key={product.id} className="balance-list-grid__balance-item">
              <ProductReferenceIcon className="grid-item--product-reference-cell" />
              <div className="grid-item grid-item__product-information grid-item__position--start">
                <img
                  src={`${env.URL_ASSETS + ASSETS_LOGOS_PATH}/logo-${
                    product.id
                  }.png`}
                  alt={`Logotipo do produto ${product.name}`}
                  height={50}
                />
                <span>{product.name}</span>
              </div>
              <div className="grid-item grid-item__product-value grid-item__position--end">
                <span>{formatValueToCurrency(product.monthlyCardValue)}</span>
              </div>
              <div className="grid-item grid-item__product-value grid-item__product-value--total-value grid-item__position--end">
                <span>{formatValueToCurrency(product.totalCardValue)}</span>
              </div>
            </li>
          ))}
        </ul>
        <div className="benefits-revision-table-container__total-value-area">
          <p>SUBTOTAL</p>
          <p className="total-value-area__value">
            {formatValueToCurrency(totalProductPricedListValue)}/mês
          </p>
        </div>
      </OrderRevisionServiceTableWalletBalanceContainer>
    </>
  );
}

const OrderRevisionServiceTableWalletBalanceContainer = styled('div', {
  opacity: 1,

  transition: 'opacity 0.4s ease',
  transitionDelay: '0.05s',

  '.benefits-revision-table-container__balance-list-grid': {
    display: 'flex',
    flexDirection: 'column',

    '@deviceSm': {
      backgroundColor: '$silverLight',
      padding: '0.5rem 1rem',
      borderRadius: '0.5rem',
    },
  },

  '.balance-list-grid__header, .balance-list-grid__balance-item': {
    display: 'grid',
    gridTemplateColumns: '2.5rem 1fr 14rem 14rem',

    '@deviceMd': {
      gridTemplateColumns: '2.5rem 1fr 1fr 1fr',
    },

    '@deviceSm': {
      gridTemplateColumns: '1fr 1fr',
    },
  },

  '.balance-list-grid__header': {
    paddingBottom: '0.5rem',

    span: {
      fontSize: '0.875rem',
      fontWeight: '$medium',
    },
  },

  '.balance-list-grid__balance-item': {
    padding: '0.25rem 0',

    '& + .balance-list-grid__balance-item': {
      borderTop: '1px solid #E4E8ED',
    },
  },

  '.grid-item': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    '&--product-reference-cell': {
      '@deviceSm': {
        display: 'none',
      },
    },
  },

  '.grid-item__position': {
    '&--start': {
      justifyContent: 'start',
    },

    '&--end': {
      justifyContent: 'end',
    },
  },

  '.grid-item__product-information': {
    span: {
      color: '$fontColorDark',
      fontSize: '0.75rem',
      fontWeight: '$semibold',
    },

    img: {
      height: '2.625rem',
      objectFit: 'contain',
    },
  },

  '.grid-item__product-value': {
    span: {
      color: '$fontColorDark',
      fontSize: '0.875rem',
      fontWeight: '$medium',
    },

    '&--total-value': {
      '@deviceSm': {
        display: 'none',
      },
    },
  },

  '.benefits-revision-table-container__total-value-area': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',

    paddingTop: '1rem',

    p: {
      fontSize: '0.75rem',
      fontWeight: '$bold',

      '&.total-value-area__value': {
        color: '$fontColorDark',
        fontSize: '1rem',

        '@deviceXs': {
          fontSize: '0.875rem',
        },
      },
    },
  },
  variants: {
    isCollapsed: {
      true: {
        maxHeight: 0,
        opacity: 0,
      },
    },
  },
});
