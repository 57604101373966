import React, { useContext } from 'react';

import { DropdownContext } from '@components/dropdown';

import { OptionContainer } from './styles';

export interface EventType extends React.MouseEvent<HTMLLIElement, MouseEvent> {
  target: { value: string | number } & EventTarget;
}

interface OptionProps extends React.HTMLAttributes<HTMLLIElement> {
  disabled?: boolean;
  value: string | number;
}

export default function Option({
  disabled = false,
  value,
  children,
  ...rest
}: OptionProps) {
  const { value: dropdownValue, onSelect } = useContext(DropdownContext);
  const isSelected = !disabled && value === dropdownValue;

  function handleOnClick(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
    if (disabled) return;
    const event: EventType = { ...e, target: { ...e.target, value } };

    onSelect(event);
  }

  return (
    <OptionContainer
      onClick={e => {
        handleOnClick(e);
      }}
      value={value}
      selected={isSelected}
      {...rest}
    >
      {children}
    </OptionContainer>
  );
}
