import { SIGLA_CANAL_DEFAULT } from '@config/application-config';
import { UtmType } from '@extra-types/utm-type';

interface QueryParamData {
  [key: string]: string;
}

export function unmountQueryParams(url: string): QueryParamData {
  const queryParams = {} as QueryParamData;
  const queryString = url.trim().split('?')[1];

  if (!queryString) {
    return queryParams;
  }

  const keyValues = queryString.split('&');
  keyValues.forEach((param: string) => {
    const queryParam = param.split('=');
    const chave = `${queryParam[0]}`;
    // caso a chave exista mas nao tenha valor definido considerar a existencia dela.
    const valor = queryParam[1] ? `${queryParam[1]}` : '';
    queryParams[chave] = valor;
  });

  return queryParams;
}

interface QueryParamResponseData {
  [key: string]: string;
}

export function getQueryParams(url: string): QueryParamResponseData {
  const queryParams = { canal: SIGLA_CANAL_DEFAULT } as QueryParamResponseData;

  const queryString = url.trim().split('?')[1];

  if (!queryString) {
    return queryParams;
  }

  const chaveValores = queryString.split('&');

  const getParam = (param: string) => {
    const queryParam = param.split('=');
    const chave = `${queryParam[0]}`;
    // caso a chave exista mas nao tenha valor definido considerar a existencia dela.
    const valor = queryParam[1] ? `${queryParam[1].split('#')[0]}` : '';

    queryParams[chave] = valor;
  };

  chaveValores.forEach(getParam);

  return queryParams;
}

export function mountQueryParams(queryStringsUmounted: QueryParamData): string {
  const queryStringMounted = Object.keys(queryStringsUmounted)
    .map(queryString => `${queryString}=${queryStringsUmounted[queryString]}`)
    .join('&');

  return `${queryStringMounted.length !== 0 ? '?' : ''}${queryStringMounted}`;
}

interface ReplaceValueOfSpecificQueryParamData {
  key: string;
  value: string;
  location: {
    href: string;
    origin: string;
    pathname: string;
  };
  removeKey?: string;
}

export function replaceValueOfSpecificQueryParam({
  key,
  value,
  location,
  removeKey,
}: ReplaceValueOfSpecificQueryParamData): string {
  const queryParams = unmountQueryParams(location.href);
  queryParams[key] = value;
  if (removeKey) {
    delete queryParams[removeKey];
  }
  return location.origin + location.pathname + mountQueryParams(queryParams);
}

interface RemoveSpecificQueryParamFromLocationData {
  key: string;
  location: {
    href: string;
    origin: string;
    pathname: string;
    search: string;
  };
}

export function removeSpecificQueryParamFromLocation({
  key,
  location,
}: RemoveSpecificQueryParamFromLocationData): string {
  const queryParams = unmountQueryParams(location.href);
  delete queryParams[key];

  return location.origin + location.pathname + mountQueryParams(queryParams);
}

export function removeSpecificQueryParamFromSearch({
  search,
  queryString,
}: {
  search: string;
  queryString: string;
}): string {
  const queryParams = unmountQueryParams(search);
  delete queryParams[queryString];

  return mountQueryParams(queryParams);
}

export function clearQueryString(url: string) {
  return url.trim().split('?')[0];
}

export function getUtmFromQueryString() {
  const queryParams = unmountQueryParams(window.location.href);
  let UTMData: UtmType | null = null;

  if (
    queryParams.utm_source &&
    queryParams.utm_medium &&
    queryParams.utm_campaign
  ) {
    UTMData = {
      utmSource: queryParams.utm_source,
      utmMedium: queryParams.utm_medium,
      utmCampaign: queryParams.utm_campaign,
    };

    if (queryParams.utm_term) {
      UTMData = { ...UTMData, utmTerm: queryParams.utm_term };
    }
    if (queryParams.utm_content) {
      UTMData = { ...UTMData, utmContent: queryParams.utm_content };
    }
  }

  return UTMData;
}

export function removeListOfQueryStringFromSearch({
  search,
  queryString,
}: {
  search: string;
  queryString: string[];
}): string {
  const queryParams = unmountQueryParams(search);
  queryString.forEach(query => {
    delete queryParams[query];
  });

  return mountQueryParams(queryParams);
}
