import { createContext, useEffect, useMemo, useState } from 'react';

import { scriptWebchatUrl } from '@config/chat-config';

import { useSelector } from '@hooks/use-selector';

export interface ChatContextData {
  setOpenChat: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChatContext = createContext<ChatContextData>(
  {} as ChatContextData,
);

interface ChatContextProviderProps {
  children: React.ReactNode;
}

export function ChatContextProvider({
  children,
  ...props
}: ChatContextProviderProps) {
  const { emissor } = useSelector(({ application }) => application.config);
  const [, setOpenChat] = useState(false);

  useEffect(() => {
    const scriptWebChat = document.createElement('script');

    scriptWebChat.src = scriptWebchatUrl;

    document.head.appendChild(scriptWebChat);

    scriptWebChat.onload = () => {
      window.renderBotWidget(emissor.chat.key);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memorizeReturn = useMemo(
    () => ({
      setOpenChat,
    }),
    [setOpenChat],
  );

  return (
    <ChatContext.Provider value={memorizeReturn} {...props}>
      {children}
      <div id="code7-boteria-bot-widget-container-faq" />
    </ChatContext.Provider>
  );
}
