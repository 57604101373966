import { useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import formatCurrencyToValue from '@vr/devkit/money/formatCurrencyToValue';
import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';
import { debounce } from 'lodash';

import { Input } from '@components/input';
import { ProductInformation } from '@components/product-information';
import { ComboServiceDisabledForm } from '@pages/offer-page/custom-offer-pages/components/combo-summary-components/combo-service-disabled-form';
import { ComboTaxTable } from '@pages/offer-page/custom-offer-pages/components/combo-summary-components/combo-tax-table';
import { ComboServiceFormCollapseBox } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-collapse-box';
import { ComboServiceFormContainer } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-container';
import { ComboServiceFormFooterArea } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-footer-area';
import { ComboServiceFormHeader } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-header';
import { ComboServiceFormSectionContainer } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-section-container';
import { ComboServiceFormServiceInformation } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-service-information';

import { DIGIT_WAIT_TIME_TO_PRICE } from '@config/application-config';
import {
  MAX_LENGTH_CURRENCY_VALUE,
  MIN_CARD_QUANTITY,
} from '@config/input-config';
import { ComboServiceFormType } from '@extra-types/combo-service-form-type';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { IndividualProductFormType } from '@extra-types/product-form-types';
import { getProductFromComboCart } from '@helpers/cart-helpers';
import { FormErrors } from '@helpers/enum/form-errors';
import { isComboFlux } from '@helpers/flux-helpers';
import { createComboModalityFormId } from '@helpers/service-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addProductToComboCartList,
  removeProductFromComboCartList,
} from '@store/slices/cart-slice';
import { fetchCartPricing } from '@store/thunks/cart-thunk';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

export function ComboMobilityServiceForm({
  modalityData,
  isFormActive,
  handleNextForm,
}: ComboServiceFormType) {
  const dispatch = useDispatch();
  const { selectedOffer } = OfferFacade();
  const config = useSelector(({ application }) => application.config);
  const { getProductForSaleListByModality, handleActiveSummary, isFormEnable } =
    useComboCartPageContext();
  const {
    productComboCartList,
    isCartLoading,
    comboCartPricingData,
    cartPricingData,
  } = useSelector(({ cart }) => cart);
  const isRegisterCartLoading = useSelector(
    ({ customer }) => customer.isLoading,
  );
  const availableProducts = getProductForSaleListByModality(modalityData.id);
  const productPricedDataList = isComboFlux(selectedOffer)
    ? comboCartPricingData
    : cartPricingData;

  const getFormDefaultValues = () => {
    if (
      productComboCartList[modalityData.id] &&
      productComboCartList[modalityData.id].length > 0
    ) {
      return {
        amount: productComboCartList[modalityData.id][0].amount || undefined,
        value:
          productComboCartList[modalityData.id][0].value.toFixed(2) ||
          undefined,
      };
    }
    return undefined;
  };

  const methods = useForm<IndividualProductFormType>({
    mode: 'onChange',
    defaultValues: getFormDefaultValues(),
  });

  const productData = availableProducts[0];
  const formID = createComboModalityFormId(modalityData.id);
  const amountInputID = `service-${modalityData.keyId}-amount`;
  const valueInputID = `service-${productData.id}-value`;
  const productCartData = getProductFromComboCart({
    modalityId: productData.modalityId,
    productId: productData.id,
    productComboCartList,
  });

  const watchAmount = methods.watch('amount');
  const watchValue = methods.watch('value');

  const calcSubTotal = () => (watchAmount || 0) * Number(watchValue || 0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(async () => {
      const isFormValid = await methods.trigger(['amount', 'value']);
      methods.handleSubmit((form: IndividualProductFormType) => {
        if (
          isFormValid &&
          (form.amount !== Number(productCartData?.amount) ||
            Number(form.value) !== Number(productCartData?.value))
        ) {
          if (form.value === '') {
            if (productComboCartList[modalityData.id].length > 1) {
              dispatch(
                removeProductFromComboCartList({
                  modalityId: productData.modalityId,
                  productId: productData.id,
                }),
              );
              dispatch(fetchCartPricing({ offerData: selectedOffer }));
              handleActiveSummary({ id: `${modalityData.serviceGroupId}` });
            }
          } else {
            dispatch(
              addProductToComboCartList({
                modalityId: productData.modalityId,
                product: {
                  id: productData.id,
                  type: productData.type,
                  amount: form.amount,
                  value: Number(form.value),
                  planType: PlanTypeEnum.MENSAL,
                },
              }),
            );
            dispatch(fetchCartPricing({ offerData: selectedOffer }));
            handleActiveSummary({ id: `${modalityData.serviceGroupId}` });
          }
        }
      })();
    }, DIGIT_WAIT_TIME_TO_PRICE),
    [productComboCartList],
  );

  const onSubmit: SubmitHandler<IndividualProductFormType> = async () => {
    const isFormValid = await methods.trigger(['amount', 'value']);
    if (isFormValid) handleNextForm();
  };

  useEffect(() => {
    if (isFormActive === false) {
      methods.reset(getFormDefaultValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormActive]);

  if (!isFormEnable(formID)) {
    return <ComboServiceDisabledForm modalityData={modalityData} />;
  }

  return (
    <FormProvider {...methods}>
      <ComboServiceFormSectionContainer>
        <ComboServiceFormContainer
          id={formID}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <ComboServiceFormHeader
            serviceGroupId={modalityData.serviceGroupId}
            isCollapsed={!isFormActive}
            value={calcSubTotal()}
            showRecurringValueLabel={false}
          />
          <ComboServiceFormServiceInformation isCollapse={!isFormActive}>
            <ProductInformation productData={availableProducts[0]} />
            <AmountValueContainer>
              <p>Núm. de cartões</p>
              <Input.Numeric
                id={amountInputID}
                data-testid={amountInputID}
                customMask="999"
                placeholder="0"
                width="6rem"
                disabled={
                  !isFormActive || isCartLoading || isRegisterCartLoading
                }
                errorMessage={methods.formState.errors.amount?.message}
                {...methods.register('amount', {
                  onChange: () => {
                    debouncedSave();
                  },
                  valueAsNumber: true,
                  required: {
                    value: true,
                    message: FormErrors.REQUIRED_DEFAULT,
                  },
                  min: {
                    value: MIN_CARD_QUANTITY,
                    message: `${FormErrors.MIN_CARD_AMOUNT} ${MIN_CARD_QUANTITY}`,
                  },
                  max: {
                    value: config.maxBeneficiaryAmount,
                    message: `${FormErrors.MAX_CARD_AMOUNT} ${config.maxBeneficiaryAmount}`,
                  },
                })}
              />
            </AmountValueContainer>
          </ComboServiceFormServiceInformation>
          <ComboServiceFormCollapseBox
            isFormActive={!isFormActive}
            value={calcSubTotal()}
            showRecurringValueLabel={false}
          >
            <ContentContainer>
              <ValueContainer>
                <p>Digite a carga mensal (por optante)</p>
                <Input.Default
                  id={valueInputID}
                  data-testid={valueInputID}
                  maskMoney
                  width="150px"
                  placeholder="R$ 0,00"
                  maxLength={MAX_LENGTH_CURRENCY_VALUE}
                  errorMessage={methods.formState.errors.value?.message}
                  inputMode="numeric"
                  disabled={
                    !isFormActive || isCartLoading || isRegisterCartLoading
                  }
                  {...methods.register('value', {
                    deps: 'amount',
                    onChange: event => {
                      if (event.target.value === 'R$ 0,00') {
                        methods.resetField('value', {
                          defaultValue: event.target.value.replace(
                            'R$ 0,00',
                            '',
                          ),
                        });
                      }
                      debouncedSave();
                    },
                    setValueAs: value =>
                      value && formatCurrencyToValue(value).toFixed(2),
                    required: {
                      value: true,
                      message: FormErrors.REQUIRED_DEFAULT,
                    },
                    validate: {
                      min: value =>
                        value !== ''
                          ? Number(value) >= config.minCardValue ||
                            `${
                              FormErrors.MIN_CARD_VALUE
                            } ${formatValueToCurrency(config.minCardValue)}`
                          : true,
                      max: value =>
                        value !== ''
                          ? Number(value) <= config.maxCardValue ||
                            `${
                              FormErrors.MAX_CARD_VALUE
                            } ${formatValueToCurrency(config.maxCardValue)}`
                          : true,
                    },
                  })}
                />
              </ValueContainer>
              <ComboTaxTable
                serviceId={modalityData.serviceGroupId}
                pricingResumeData={
                  productPricedDataList?.resumo.servicos[
                    modalityData.serviceGroupId
                  ]
                }
              />
            </ContentContainer>
          </ComboServiceFormCollapseBox>
        </ComboServiceFormContainer>
        <ComboServiceFormFooterArea
          isCollapsed={!isFormActive}
          extraDisableCondition={!methods.formState.isValid}
        />
      </ComboServiceFormSectionContainer>
    </FormProvider>
  );
}

const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',

  '> p': {
    fontSize: convertPixelToRem(14),
  },
});

const AmountValueContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.5rem',

  p: {
    fontSize: convertPixelToRem(14),
  },

  '@deviceMd': {
    flexDirection: 'column',
    alignItems: 'start',
    gap: '0',

    '> div': {
      paddingTop: '0.5rem',
    },
  },

  '@deviceSm': {
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1.5rem',

    p: {
      fontSize: '0.75rem',
    },

    '> div': {
      paddingTop: '1.25rem',
    },
  },

  '@deviceXs': {
    flexDirection: 'column',
    alignItems: 'start',
    gap: '0',

    marginTop: '1rem',

    p: {
      fontSize: '0.75rem',
    },

    '> div': {
      paddingTop: '0.5rem',
    },
  },
});

const ValueContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '1.5rem',

  borderBottom: '1px solid $silverPure',

  p: {
    fontSize: '0.875rem',
  },

  '@deviceSm': {
    gap: '0.75rem',

    p: {
      fontSize: '0.75rem',
    },
  },
});
