import { HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from '@config/styles-config';
import { convertPixelToRem, holderStyle } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const ProductBenefitsContainer = styled('section', {
  backgroundColor: '$$productColor',
  overflowX: 'hidden',

  paddingTop: convertPixelToRem(HEADER_HEIGHT),

  '@deviceSm': {
    paddingTop: convertPixelToRem(HEADER_MOBILE_HEIGHT),
  },
});

export const ProductBenefitsHolder = styled('div', {
  ...holderStyle,

  paddingTop: convertPixelToRem(40),
  paddingBottom: convertPixelToRem(52),

  display: 'grid',
  gridTemplateRows: 'minmax(170px, auto) minmax(230px, auto) auto',
  gridTemplateColumns: 'auto 380px',
  gridTemplateAreas: `
                    'title-area form-area'
                    'offer-area form-area'
                    'action-area action-area'
                   `,

  '@deviceMd': {
    gridTemplateRows: 'auto minmax(230px, auto) auto',
    gridTemplateAreas: `
                      'title-area title-area'
                      'offer-area form-area'
                      'action-area action-area'
                      `,
  },

  '@media only screen and (max-width: 780px)': {
    display: 'flex',
    flexDirection: 'column',
    gap: convertPixelToRem(24),
  },

  '@deviceSm': {
    display: 'flex',
    flexDirection: 'column',
    gap: convertPixelToRem(24),

    padding: `${convertPixelToRem(24)} 1rem ${convertPixelToRem(24)}`,
  },

  '.product-information-holder__title': {
    position: 'relative',
    gridArea: 'title-area',

    h1: {
      '@deviceLg': {
        fontSize: '2.25rem',
      },
    },

    p: {
      maxWidth: '65%',
      marginTop: '0.75rem',
      paddingLeft: '2.625rem',

      color: '$white',
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: '$medium',

      '@deviceLg': {
        maxWidth: '100%',
        paddingRight: '1rem',
        marginBottom: '1rem',
      },

      '@deviceMd': {
        marginBottom: '1.5rem',
      },

      '@deviceSm': {
        paddingLeft: '1rem',
      },
    },
  },

  '.product-information-holder__form-area': {
    position: 'relative',
    gridArea: 'form-area',
    alignSelf: 'end',

    height: '100%',
    paddingTop: convertPixelToRem(30),

    form: {
      zIndex: 1,
      height: '100%',
    },

    '@media only screen and (max-width: 780px)': {
      alignSelf: 'center',
      width: '100%',
      maxWidth: '380px',

      form: {
        minHeight: '400px',
      },
    },

    '@deviceSm': {
      alignSelf: 'normal',
      maxWidth: '100%',
    },
  },

  '.product-information-holder__offer-elements': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: '0.5rem',

    zIndex: 1,
    gridArea: 'offer-area',

    paddingBottom: '0.5rem',

    '@deviceMd': {
      marginTop: '2.5rem',
    },

    '@media only screen and (max-width: 780px)': {
      marginTop: '0',
    },
  },

  '.product-information-holder__action-area': {
    gridArea: 'action-area',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    marginTop: '2rem',

    '@deviceSm': {
      marginTop: 0,
    },
  },
  variants: {
    hasLightBackground: {
      true: {
        '.product-information-holder__title > p, p, i': {
          color: '$fontColorPrimary',
        },
      },
    },
  },
});

export const ProductBenefitItem = styled('li', {
  display: 'flex',

  background: '$$productColor',
  width: '100%',
  padding: '1rem 1.25rem',
  borderRadius: `${convertPixelToRem(8)} 0 0 ${convertPixelToRem(8)}`,

  color: '$white',
  fontWeight: '$medium',
  lineHeight: 1.5,

  i: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1rem',
    lineHeight: 1.5,

    width: convertPixelToRem(20),
    marginRight: convertPixelToRem(10),
  },

  '@media only screen and (max-width: 780px)': {
    borderRadius: '0.5rem',

    lineHeight: 1.7,

    i: {
      lineHeight: 1.7,
    },
  },
});

export const ProductBenefitsLoadingHolder = styled('div', {
  ...holderStyle,

  display: 'flex',
  paddingTop: convertPixelToRem(48),
  paddingBottom: convertPixelToRem(48),
  gap: convertPixelToRem(10),

  '@deviceSm': {
    padding: '2.5rem 1rem',
  },

  '.product-information-loading-holder__left-side': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',

    button: {
      maxWidth: convertPixelToRem(227),
    },
  },

  '.left-side__phrase-group': {
    display: 'flex',
    flexDirection: 'column',
    gap: convertPixelToRem(10),
  },

  '.product-information-loading-holder__right-side': {
    width: '100%',
    maxWidth: '20rem',

    '@deviceSm': {
      display: 'none',
    },
  },
});
