import { env } from '@config/env';
import { PRODUCTS } from '@config/products-config';

import { styled } from '@src/themes';

type ProductImageProps = {
  productId: keyof typeof PRODUCTS;
};

export function ProductImage({ productId }: ProductImageProps) {
  const imagePath = `${env.URL_ASSETS_CHANNEL}${
    PRODUCTS[productId] ? PRODUCTS[productId].cardPath : '/'
  }`;

  function getProductName() {
    if (!PRODUCTS[productId]) {
      return 'não encontrado';
    }
    return PRODUCTS[productId].name;
  }

  return <Image src={imagePath} alt={`Cartão ${getProductName()}`} />;
}

const Image = styled('img', {
  objectFit: 'contain',
  filter: 'drop-shadow(0px 0px 4px #00000030)',
});
