import { TableContainer } from './styles';

interface TableProps {
  color?: string;
  head: {
    textLeft: string;
    textRight?: string;
    titleRight?: string;
    imageLeft?: string;
  };
  foot: {
    titleLeft: string;
    titleRight: string;
  };
  children: React.ReactNode;
}

function Table({ color, head, foot, children }: TableProps) {
  return (
    <TableContainer css={{ $$color: color }}>
      <thead>
        <tr>
          <th className="table-view__head-left-content">
            <div>
              {head.imageLeft && (
                <img
                  src={head.imageLeft}
                  alt={`Imagem do cartão ${head.textLeft}`}
                />
              )}
              <strong>{head.textLeft}</strong>
            </div>
          </th>
          <th className="table-view__head-right-content">
            {head.textRight && (
              <div>
                {head.titleRight && <span>{head.titleRight}</span>}
                {head.textRight}
              </div>
            )}
          </th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
      <tfoot>
        <tr>
          <td>
            <span>{foot.titleLeft.toUpperCase()}</span>
          </td>
          <td>
            <i className="fa fa-equals" aria-hidden="true" />
            <span>{foot.titleRight.toUpperCase()}</span>
          </td>
        </tr>
      </tfoot>
    </TableContainer>
  );
}

export default Table;
