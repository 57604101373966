import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const ButtonStyled = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5rem',

  borderRadius: '$pill',
  padding: '1rem 1rem',

  fontSize: '1rem',
  fontWeight: '$semibold',
  textDecoration: 'none',
  lineHeight: 1,
  transition: 'background-color 0.4s, border-color 0.4s, color 0.4s',

  '> div': {
    alignItems: 'center',

    height: '1rem',
  },

  '@deviceMd': {
    fontSize: convertPixelToRem(14),

    '> div': {
      height: convertPixelToRem(14),
    },
  },

  variants: {
    variant: {
      primary: {
        background: '$buttonPrimaryBackgroundColor',
        border: `solid 2px $buttonPrimaryBorderColor`,

        color: '$buttonPrimaryTextColor',

        '&:hover': {
          background: '$buttonPrimaryBackgroundColorHover',
          border: `solid 2px $buttonPrimaryBorderColorHover`,

          color: '$buttonPrimaryTextColorHover',
        },
      },
      secondary: {
        background: '$buttonSecondaryBackgroundColor',
        border: `solid 2px $buttonSecondaryBorderColor`,

        color: '$buttonSecondaryTextColor',

        '&:hover': {
          background: '$buttonSecondaryBackgroundColorHover',
          border: `solid 2px $buttonSecondaryBorderColorHover`,

          color: '$buttonSecondaryTextColorHover',
        },
      },
    },
    negative: {
      true: {},
      false: {},
    },
    outline: {
      true: {},
      false: {},
    },
    isDisabled: {
      true: {},
      false: {},
    },
    size: {
      small: {
        padding: '0.5rem 0.75rem',
        fontSize: convertPixelToRem(14),
      },
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      negative: false,
      outline: true,
      css: {
        background: '$buttonPrimaryOutlineBackgroundColor',
        border: `solid 2px $buttonPrimaryOutlineBorderColor`,

        color: '$buttonPrimaryOutlineTextColor',

        '&:hover': {
          background: '$buttonPrimaryOutlineBackgroundColorHover',
          border: `solid 2px $buttonPrimaryOutlineBorderColorHover`,

          color: '$buttonPrimaryOutlineTextColorHover',
        },
      },
    },
    {
      variant: 'primary',
      negative: true,
      css: {
        background: '$buttonPrimaryNegativeBackgroundColor',
        border: `solid 2px $buttonPrimaryNegativeBorderColor`,

        color: '$buttonPrimaryNegativeTextColor',

        '&:hover': {
          background: '$buttonPrimaryNegativeBackgroundColorHover',
          border: `solid 2px $buttonPrimaryNegativeBorderColorHover`,

          color: '$buttonPrimaryNegativeTextColorHover',
        },
      },
    },
    {
      variant: 'primary',
      isDisabled: true,
      css: {
        background: '$buttonPrimaryDisabledBackgroundColor !important',
        border: 'solid 2px $buttonPrimaryDisabledBorderColor !important',
        cursor: 'not-allowed',

        color: '$buttonPrimaryDisabledTextColor !important',

        '&:hover': {
          background: '$buttonPrimaryDisabledBackgroundColorHover !important',
          border: 'solid 2px $buttonPrimaryDisabledBorderColorHover !important',

          color: '$buttonPrimaryDisabledTextColorHover !important',
        },
      },
    },
    {
      variant: 'secondary',
      negative: false,
      outline: true,
      css: {
        background: '$buttonSecondaryOutlineBackgroundColor',
        border: `solid 2px $buttonSecondaryOutlineBorderColor`,

        color: '$buttonSecondaryOutlineTextColor',

        '&:hover': {
          background: '$buttonSecondaryOutlineBackgroundColorHover',
          border: `solid 2px $buttonSecondaryOutlineBorderColorHover`,

          color: '$buttonSecondaryOutlineTextColorHover',
        },
      },
    },
    {
      variant: 'secondary',
      negative: true,
      css: {
        background: '$buttonSecondaryNegativeBackgroundColor',
        border: `solid 2px $buttonSecondaryNegativeBorderColor`,

        color: '$buttonSecondaryNegativeTextColor',

        '&:hover': {
          background: '$buttonSecondaryNegativeBackgroundColorHover',
          border: `solid 2px $buttonSecondaryNegativeBorderColorHover`,

          color: '$buttonSecondaryNegativeTextColorHover',
        },
      },
    },
    {
      variant: 'secondary',
      isDisabled: true,
      css: {
        background: '$buttonSecondaryDisabledBackgroundColor !important',
        border: 'solid 2px $buttonSecondaryDisabledBorderColor !important',
        cursor: 'not-allowed',

        color: '$buttonSecondaryDisabledTextColor !important',

        '&:hover': {
          background: '$buttonSecondaryDisabledBackgroundColorHover !important',
          border:
            'solid 2px $buttonSecondaryDisabledBorderColorHover !important',

          color: '$buttonSecondaryDisabledTextColorHover !important',
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'primary',
    outline: false,
    negative: false,
    isDisabled: false,
  },
});
