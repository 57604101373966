import { env } from '@config/env';
import { getSensediaAuth } from '@helpers/authentication-helpers';

import { http } from './client';

const getBaseURLApi = http.config({
  baseUrl: env.URL_API_V2,
  clientId: getSensediaAuth().clientId,
});

export default getBaseURLApi;
