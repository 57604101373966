import { ApiRequestAuthData } from '@api/models/request/auth-data';
import SearchChannelDescription from '@api/models/response/search-channel-description';

import getBaseURLApi from './authentication/get-base-url-api';

class ChannelDescriptionService {
  private readonly baseUrl;

  constructor() {
    this.baseUrl = 'canal';
  }

  public async getChannelDescriptionData({
    siglaEmissor,
    siglaCanal,
    siglaSubCanal,
  }: ApiRequestAuthData): Promise<SearchChannelDescription> {
    const url = `/${this.baseUrl}/${siglaCanal}`;

    const { data } = await getBaseURLApi(url, {
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
    });

    return data;
  }
}

export default new ChannelDescriptionService();
