/* eslint-disable @typescript-eslint/no-explicit-any */
/* istanbul ignore file */

import { useEffect } from 'react';

declare global {
  interface Window {
    wootric: any;
    wootricSettings: any;
  }
}

interface WootricData {
  email: string;
  cnpjRH: string;
}

function Wootric({ email, cnpjRH }: WootricData) {
  useEffect(() => {
    const wootricRun = (wootricEmail: string, wootricCnpjRH: string) => {
      window.wootricSettings = {
        account_token: 'NPS-0a6d8f07',
        email: wootricEmail,
        external_id: wootricCnpjRH,
        created_at: Math.floor(new Date().getTime() / 1000),
        scale_color: 'gradient',
      };

      if (window.wootric !== undefined) {
        window.wootric('run');
      }
    };
    if (email && cnpjRH) {
      wootricRun(email, cnpjRH);
    }
  }, [email, cnpjRH]);

  return <div />;
}

export default Wootric;
