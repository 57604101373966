import { CSS } from '@stitches/react';

import { config, styled } from '@src/themes';

export function SimplePageTitle({
  children,
  css,
  tag = 'h1',
}: {
  children: React.ReactNode;
  css?: CSS<typeof config>;
  tag?: 'h1' | 'h2' | 'h3';
}) {
  return (
    <SimpleTitleContainer css={css} as={tag}>
      {children}
    </SimpleTitleContainer>
  );
}

const SimpleTitleContainer = styled('h1', {
  margin: '2.5rem 0 2rem',

  fontSize: '1rem',
  fontWeight: '$semibold',
  lineHeight: 1.5,

  '@deviceSm': {
    margin: '1.5rem 0',
  },
});
