import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '@hooks/use-selector';

import { BannersResponse } from '@api/models/response/banners-response';
import BannerService from '@api/services/banner-service';
import ApplicationError from '@errors/types/application-error';
import NotCatalogedError from '@errors/types/not-cataloged-error';

export const fetchBannerInformationData = createAsyncThunk<
  BannersResponse,
  void,
  { state: RootState }
>(
  'banner-information/fetchBannerInformationDataStatus',
  async (_, thunkAPI) => {
    const { config } = thunkAPI.getState().application;

    try {
      return await BannerService.getBannersInformation({
        siglaCanal: config.siglaCanal,
        siglaEmissor: config.emissor.initials,
        siglaSubCanal: config.siglaSubCanal,
      });
    } catch (error) {
      if (error instanceof ApplicationError)
        return thunkAPI.rejectWithValue(error);
      return thunkAPI.rejectWithValue(new NotCatalogedError());
    }
  },
);
