import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ModalityType } from '@extra-types/modality-type';
import { ProductType } from '@extra-types/product-type';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { ensureNotUndefined, scrollToElement } from '@helpers/function-helpers';
import {
  getModalityData,
  getModalityListByProductList,
  sortModalitiesByDisplayOrder,
} from '@helpers/modality-helpers';
import {
  filterProductListByContractedProductList,
  getProductListByModality,
  getProductPricedFullDataList,
} from '@helpers/product-helpers';
import { createComboModalityFormId } from '@helpers/service-helpers';

import useDefaultHeader from '@hooks/use-default-header';
import { useDispatch } from '@hooks/use-dispatch';
import { useRoutes } from '@hooks/use-routes';
import { useSelector } from '@hooks/use-selector';
import { fetchRegisterCart } from '@store/thunks/customer-thunk';

import {
  OfferItemType,
  OfferRulesType,
} from '@api/models/response/offer-response';
import leadsService from '@api/services/leads-service';

import { OfferFacade } from '@src/facade/offer-facade';

export interface ComboCartPageContextData {
  activeForm?: string;
  activeSummary?: string;
  productsForSale: ProductType[];
  svasForSale: OfferItemType[];
  modalitiesForSale: ModalityType[];
  servicesIdsForSale: ServiceGroupId[];
  getCurrentRule: () => OfferRulesType;
  isFormEnable: (id: string) => boolean;
  handleActiveForm: (id: string) => void;
  handleActiveSummary: ({
    id,
    clearStateOnSameId,
  }: {
    id: string;
    clearStateOnSameId?: boolean;
  }) => void;
  handleNextForm: (id: string) => void;
  getProductForSaleListByModality: (modalityId: number) => ProductType[];
}

export const ComboCartPageContext = createContext<ComboCartPageContextData>(
  {} as ComboCartPageContextData,
);

interface ComboCartPageContextProviderProps {
  children: React.ReactNode;
}

export function ComboCartPageContextProvider({
  children,
  ...props
}: ComboCartPageContextProviderProps) {
  const { nextStep } = useRoutes();
  const dispatch = useDispatch();
  const { defaultHeader } = useDefaultHeader();

  const [activeForm, setActiveForm] = useState<string>();
  const [activeSummary, setActiveSummary] = useState<string>();
  const [enableFormList, setEnableFormList] = useState<string[]>([]);

  const { selectedOffer, offerDetails, getSvasFromOffer } = OfferFacade();
  const { productList, modalityList } = useSelector(({ products }) => products);
  const { contractedProducts, companyData } = useSelector(
    ({ customer }) => customer,
  );
  const { comboCartPricingData } = useSelector(({ cart }) => cart);

  const productsForSale = useMemo(() => {
    const productListWithoutContractedProducts =
      filterProductListByContractedProductList(productList, contractedProducts);

    return productListWithoutContractedProducts.filter(availableProduct => {
      return offerDetails.oferta.itemsOferta.some(
        product => availableProduct.id === product.codigoProduto,
      );
    });
  }, [contractedProducts, offerDetails.oferta.itemsOferta, productList]);

  const getCurrentRule = useCallback(() => {
    if (comboCartPricingData) {
      return ensureNotUndefined(
        offerDetails.oferta.regras.find(
          rule =>
            comboCartPricingData.resumo.valorTotal > rule.faturamentoInicial &&
            (comboCartPricingData.resumo.valorTotal <= rule.faturamentoFinal ||
              rule.faturamentoFinal === null),
        ),
      );
    }

    return offerDetails.oferta.regras[0];
  }, [comboCartPricingData, offerDetails.oferta.regras]);

  const svasForSale = useMemo(() => {
    return offerDetails.oferta.itemsOferta.filter(
      product => product.tipoProduto === 'Externo (SVA)',
    );
  }, [offerDetails.oferta.itemsOferta]);

  const modalitiesForSale = useMemo(
    () =>
      sortModalitiesByDisplayOrder(
        [...new Set(productsForSale.map(product => product.modalityId))].map(
          modalityId => getModalityData(modalityId, modalityList),
        ),
      ),
    [modalityList, productsForSale],
  );

  const servicesIdsForSale = useMemo(() => {
    const servicesIds = [
      ...new Set(modalitiesForSale.map(modality => modality.serviceGroupId)),
    ];
    if (getSvasFromOffer().length > 0) {
      servicesIds.push(ServiceGroupId.SVA);
    }

    return servicesIds;
  }, [getSvasFromOffer, modalitiesForSale]);

  const isFormEnable = useCallback(
    (id: string) => enableFormList.includes(id),
    [enableFormList],
  );

  const handleActiveForm = useCallback(
    (id: string) => {
      const formID = createComboModalityFormId(id);
      const newActiveFormState = activeForm === formID ? undefined : formID;
      setActiveForm(newActiveFormState);

      if (newActiveFormState) {
        scrollToElement(newActiveFormState, {
          behavior: 'auto',
          delayTime: 0,
        });
      }
    },
    [activeForm],
  );

  const handleNextForm = useCallback(
    (id: string) => {
      dispatch(
        fetchRegisterCart({
          offerData: selectedOffer,
        }),
      )
        .unwrap()
        .then(() => {
          const activeFormIndex = servicesIdsForSale.findIndex(
            serviceId => serviceId === id,
          );

          const nextFormIndex = activeFormIndex + 1;
          if (nextFormIndex <= servicesIdsForSale.length - 1) {
            const formID = `${createComboModalityFormId(
              servicesIdsForSale[nextFormIndex],
            )}`;
            setActiveForm(formID);

            if (!enableFormList.includes(formID)) {
              setEnableFormList(oldList => [...oldList, formID]);
            }

            scrollToElement(formID);
          } else {
            leadsService.createCartProposal(defaultHeader, companyData.lead);
            nextStep();
          }
        });
    },
    [
      dispatch,
      selectedOffer,
      servicesIdsForSale,
      enableFormList,
      defaultHeader,
      companyData.lead,
      nextStep,
    ],
  );

  const handleActiveSummary = useCallback(
    ({
      id,
      clearStateOnSameId,
    }: {
      id: string;
      clearStateOnSameId?: boolean;
    }) => {
      setActiveSummary(state =>
        clearStateOnSameId && state === id ? undefined : id,
      );
    },
    [],
  );

  const getProductForSaleListByModality = useCallback(
    (modalityId: number) => {
      return getProductListByModality(modalityId, productsForSale);
    },
    [productsForSale],
  );

  useEffect(() => {
    getCurrentRule();
  }, [comboCartPricingData, getCurrentRule]);

  useEffect(() => {
    if (modalitiesForSale.length > 0) {
      const formID = `${createComboModalityFormId(servicesIdsForSale[0])}`;
      setEnableFormList([formID]);
      setActiveForm(formID);
      setActiveSummary(`${modalitiesForSale[0].serviceGroupId}`);
    }
    if (comboCartPricingData) {
      const productFullDataList = getProductPricedFullDataList(
        comboCartPricingData.detalhe.produtos,
        productList,
      );
      const modalityIdList = getModalityListByProductList(
        productFullDataList,
      ).filter(modalityId =>
        modalitiesForSale.some(modality => modality.id === modalityId),
      );
      setEnableFormList(
        modalityIdList.map(
          modalityId => `${createComboModalityFormId(modalityId)}`,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalitiesForSale]);

  const memorizeReturn = useMemo(
    () => ({
      activeForm,
      activeSummary,
      productsForSale,
      svasForSale,
      modalitiesForSale,
      servicesIdsForSale,
      getCurrentRule,
      isFormEnable,
      handleActiveForm,
      handleActiveSummary,
      handleNextForm,
      getProductForSaleListByModality,
    }),
    [
      activeForm,
      activeSummary,
      productsForSale,
      svasForSale,
      modalitiesForSale,
      servicesIdsForSale,
      getCurrentRule,
      isFormEnable,
      handleActiveForm,
      handleActiveSummary,
      handleNextForm,
      getProductForSaleListByModality,
    ],
  );

  return (
    <ComboCartPageContext.Provider value={memorizeReturn} {...props}>
      {children}
    </ComboCartPageContext.Provider>
  );
}
