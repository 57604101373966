import { Emissor } from '@helpers/enum/emissor';

import { useSelector } from '@hooks/use-selector';

import { FooterJVCEF } from './JVCEF';
import { FooterVRPAT } from './VRPAT';

type EmissorFooterType = {
  [Emissor.VRPAT]: JSX.Element;
  [Emissor.JVCEF]: JSX.Element;
};

const emissorFooter: EmissorFooterType = {
  [Emissor.VRPAT]: <FooterVRPAT />,
  [Emissor.JVCEF]: <FooterJVCEF />,
};

export function Footer() {
  const { emissor } = useSelector(({ application }) => application.config);

  return emissorFooter[emissor.initials];
}
