import { getEmissor } from '@helpers/emissor-helpers';
import { Emissor } from '@helpers/enum/emissor';
import { getQueryParams } from '@helpers/query-string-helpers';

const fontsMapper: { [key in Emissor]: string } = {
  VRPAT:
    'https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap',
  JVCEF:
    'https://assets-lojavr-dev.vrbeneficios.io/jvcef/assets/css/fonts.min.css',
};

export const channelFontsMapper: { [key: string]: string } = {
  SICRE:
    'https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;500;600;700&family=Montserrat:wght@100;400;600&display=swap',
};

export const emissorFonts = {
  '@import': fontsMapper[getEmissor().initials],
};

export const customChannelFonts = {
  '@import':
    channelFontsMapper[
      getQueryParams(window.location.href).canal.toUpperCase()
    ],
};
