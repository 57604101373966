import { useFormContext } from 'react-hook-form';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { HCMPlansType } from '@compositions/hcm-sections/hcm-plans-section';

import { ModalityType } from '@extra-types/modality-type';
import { PlanTypeEnum } from '@extra-types/product-cart-type';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

type CartPageHcmSummaryProps = {
  children: React.ReactNode;
  modalityData: ModalityType;
};

export function CartPageHcmSummary({
  children,
  modalityData,
}: CartPageHcmSummaryProps) {
  const { watch } = useFormContext<HCMPlansType>();
  const { cartPricingData, isCartLoading, productCartList } = useSelector(
    ({ cart }) => cart,
  );

  const planValue =
    cartPricingData?.detalhe.produtos.map(p => p.valorProdutoComDesconto)[0] ||
    cartPricingData?.resumo.servicos.HCM.valorTotalBeneficios ||
    0;

  const extensionsValue = productCartList.flatMap(
    plan => plan.additionalProducts?.map(product => product.value) || [],
  );

  const totalOrder = [planValue, ...extensionsValue].reduce(
    (acc, value) => acc + value,
    0,
  );

  function hasDiscount() {
    return watch('planType') === PlanTypeEnum.ANUAL;
  }

  return (
    <CartPageHcmSummaryContainer>
      <div className="summary-header">
        <h2>{modalityData.salesTitle}</h2>
      </div>
      {children}
      <div className="summary-footer">
        <span>
          <i className="fa-solid fa-equals" />
          <h2>VALOR TOTAL</h2>
        </span>
        <h3>
          {!isCartLoading &&
            `${
              hasDiscount()
                ? `${formatValueToCurrency(totalOrder || 0)}/ano`
                : `${formatValueToCurrency(totalOrder || 0)}/mês`
            }`}
        </h3>
      </div>
    </CartPageHcmSummaryContainer>
  );
}

const CartPageHcmSummaryContainer = styled('div', {
  padding: '10px 0 1rem 0',
  backgroundColor: '#F4F5F7',
  borderRadius: '6px 6px 0px 0px',

  '.summary-header, .summary-footer': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },

  '.summary-footer': {
    paddingTop: '1.5rem',
    paddingBottom: '0.5rem',
    color: '#6D839A',

    '> span': {
      display: 'flex',
      gap: '3.5rem',
      alignItems: 'center',
    },

    'h2, h3': {
      fontSize: '0.75rem',
    },
  },

  '.summary-header': {
    paddingBottom: '0.75rem',

    '> span': {
      display: 'flex',
      gap: '0.5rem',

      p: {
        fontSize: '0.75rem',
        textDecoration: 'line-through',
        color: '$fontColorLight',
      },
    },

    'h2, h3, p': {
      fontWeight: 'bold',
    },

    h3: {
      fontSize: '0.75rem',
      color: '$primaryPure',
    },

    h2: {
      fontSize: '0.875rem',
      color: '$black',
    },
  },
});
