import { ProductReferenceIcon } from '@elements/order-revision-service-table-elements/product-reference-icon';

import { ASSETS_ICONS_PATH } from '@config/assets-config';
import { env } from '@config/env';

import { useSelector } from '@hooks/use-selector';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

import { ResumeSVAProductList } from './resume-sva-product-list';

interface OrderRevisionSvaListProps {
  isCollapsed: boolean;
}

export function OrderRevisionSvaList({
  isCollapsed,
}: OrderRevisionSvaListProps) {
  const { offerDetails } = OfferFacade();
  const { svaCartList } = useSelector(({ cart }) => cart);

  const getSvaInformation = (SVAId: number) => {
    return offerDetails.oferta.itemsOferta.find(
      sva => sva.codigoProduto === SVAId,
    );
  };

  if (svaCartList.length === 0) {
    return null;
  }

  return (
    <>
      <ResumeSVAProductList isCollapsed={isCollapsed} />
      <OrderRevisionSvaListContainer isCollapsed={isCollapsed}>
        <ul className="benefits-revision-table-container__balance-list-grid">
          <li className="balance-list-grid__header">
            <div className="grid-item grid-item__position--start">
              <span>Serviços Adicionais</span>
            </div>
          </li>
          {svaCartList.map(product => (
            <li key={product.id} className="balance-list-grid__balance-item">
              <ProductReferenceIcon className="grid-item--product-reference-cell" />
              <div className="grid-item grid-item__product-information grid-item__position--start">
                <img
                  src={`${env.URL_ASSETS + ASSETS_ICONS_PATH}/icon-${
                    product.id
                  }.svg`}
                  alt={`Logotipo do SVA ${product.id}`}
                />
                <div>
                  <h2>{getSvaInformation(product.id)?.nome}</h2>
                  <h3>{getSvaInformation(product.id)?.descricaoProduto}</h3>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </OrderRevisionSvaListContainer>
    </>
  );
}

const OrderRevisionSvaListContainer = styled('div', {
  opacity: 1,

  transition: 'opacity 0.4s ease',
  transitionDelay: '0.05s',

  '.benefits-revision-table-container__balance-list-grid': {
    display: 'flex',
    flexDirection: 'column',

    '@deviceSm': {
      backgroundColor: '$silverLight',
      margin: '0.5rem 1rem',
      padding: '0.5rem 0',
      borderRadius: '0.5rem',
    },
  },

  '.benefits-revision-table-container__total-value-area': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',

    paddingTop: '1rem',

    p: {
      fontSize: '0.75rem',
      fontWeight: '$bold',

      '&.total-value-area__value': {
        color: '$fontColorDark',
        fontSize: '1rem',

        '@deviceXs': {
          fontSize: '0.875rem',
        },
      },
    },
  },

  '.balance-list-grid__header, .balance-list-grid__balance-item': {
    display: 'grid',
    gridTemplateColumns: '2.5rem 1fr',

    '@deviceMd': {
      gridTemplateColumns: '2.5rem 1fr',
    },

    '@deviceSm': {
      gridTemplateColumns: '1fr',
    },
  },

  '.balance-list-grid__header': {
    padding: '0.5rem 0',

    span: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      color: '$primaryPure',
    },
  },

  '.balance-list-grid__balance-item': {
    padding: '0.25rem 0',
    width: '100%',

    '& + .balance-list-grid__balance-item': {
      borderTop: '1px solid #E4E8ED',
    },
  },

  '.grid-item': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    '&--product-reference-cell': {
      '@deviceSm': {
        display: 'none',
      },
    },
  },

  '.grid-item__position': {
    '&--start': {
      justifyContent: 'start',
      padding: '0 1rem',

      span: {
        whiteSpace: 'nowrap',
      },
    },
  },

  '.grid-item__product-information': {
    div: {
      color: '$fontColorDark',
      fontSize: '0.75rem',

      h2: {
        fontWeight: '$bold',
      },

      h3: {
        color: '#6D839A',
        fontWeight: '500',
      },
    },

    img: {
      width: '1.5rem',
      objectFit: 'contain',
    },
  },

  variants: {
    isCollapsed: {
      true: {
        maxHeight: 0,
        opacity: 0,

        '.grid-item': {
          display: 'none',
        },

        ul: {
          display: 'none !important',
        },
      },
    },
  },
});
