import { convertPixelToRem, scrollbarStyle } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const inputResponsiveMaxWidthStyle = {
  width: '100%',

  '.input-styled__input-holder': {
    width: '100%',

    div: {
      width: '100%',
    },
  },

  input: {
    width: '100%',
  },
};

export const InputStyled = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.75rem',

  padding: '1.25rem 0',

  '.input-styled__input-holder': {
    position: 'relative',
    width: '$$width',

    '&--numeric': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      width: 'unset',

      '> div': {
        position: 'relative',
        width: '$$width',
      },
    },

    '&--search': {
      '&::before': {
        content: String.raw`\f002`,
        position: 'absolute',
        fontFamily: '$fontAwesome',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '0.5rem',
        zIndex: 2,
      },
    },
  },

  '.input-styled__input': {
    position: 'relative',

    input: {
      backgroundColor: '$white',
      padding: `${convertPixelToRem(14.5)} ${convertPixelToRem(
        40,
      )} ${convertPixelToRem(14.5)} 0.75rem`,
      border: '1px solid $inputBorderColor',
      outline: 'none',
      width: '$$width',
      borderRadius: '$sm',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',

      color: '$fontColorPrimary',
      fontSize: '1rem',
      fontWeight: '$medium',
      lineHeight: '1',
      transition: 'border-color 0.4s ease',

      '&:focus': {
        borderColor: '$inputFocusBorderColor',
      },

      '&::placeholder': {
        color: '$fontColorLight',
      },

      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },

      '&[type=search]': {
        paddingLeft: '2rem',
        paddingRight: '1rem',
      },
    },

    '&::before': {
      position: 'absolute',
      top: '50%',
      right: '1rem',

      height: '1.5rem',
      transform: 'translateY(-50%)',

      fontFamily: '$fontAwesome',
      fontWeight: '300',
      fontSize: '1.5rem',
    },
  },

  span: {
    position: 'absolute',
    top: convertPixelToRem(54),
    left: '0.5rem',

    color: '$error',
    fontSize: '0.75rem',
    fontWeight: '$regular',
    whiteSpace: 'nowrap',
  },

  button: {
    flexShrink: '0',

    width: convertPixelToRem(48),
    height: convertPixelToRem(48),
    padding: 0,
    outline: 'none',

    '@deviceSm': {
      fontSize: '1.25rem',
    },
  },

  variants: {
    hasValue: {
      true: {},
    },
    hasError: {
      true: {
        '.input-styled__input': {
          input: {
            borderColor: '$error',
          },
          '&::before': {
            content: String.raw`\f00d`,

            color: '$error',
          },
        },
        label: { color: '$error' },
      },
    },
    textAlign: {
      left: {
        input: {
          textAlign: 'left',
        },
      },
      center: {
        input: {
          textAlign: 'center',
        },
      },
    },
    isDisabled: {
      true: {
        '.input-styled__input': {
          input: {
            backgroundColor: '#F1F1F1',
            color: '$fontColorLight',
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      hasValue: true,
      hasError: false,
      isDisabled: false,
      css: {
        label: {
          color: '$inputSuccessIcon',
        },
        '.input-styled__input': {
          '&:not(.input-styled__input--search)': {
            input: {
              borderColor: '$inputSuccessIcon',
            },

            '&::before': {
              content: String.raw`\f00c`,

              color: '$inputSuccessIcon',
            },
          },
        },
      },
    },
  ],
  defaultVariants: {
    hasValue: false,
    textAlign: 'left',
    hasError: false,
    isDisabled: false,
  },
});

export const Label = styled('label', {
  position: 'absolute',
  top: '-1.25rem',
  left: '0',

  color: '$fontColorPrimary',
  fontSize: '0.8125rem',
  fontWeight: '$medium',
  whiteSpace: 'nowrap',

  onError: {
    true: {
      color: '$error',
    },
  },
});

export const SearchTipsContainer = styled('div', {
  zIndex: 2,
  position: 'absolute',
  top: '3.25rem',
  left: '0',

  backgroundColor: '$white',
  width: '100%',
  padding: '0.5rem 0.5rem 0.5rem 1rem',
  borderRadius: '$sm',

  fontSize: 'calc($$fontSize - 0.05rem)',
  lineHeight: 1.5,
  textAlign: 'left',
  fontWeight: '$regular',

  '> div': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.35rem',

    overflowY: 'auto',

    maxHeight: '350px',

    ...scrollbarStyle,
  },

  '.article-tip, .search-all-faq': {
    textDecoration: 'underline',
    fontSize: '0.875rem',
    width: 'unset',
    height: 'unset',
    background: 'transparent',
    textAlign: 'left',

    '&:hover': {
      color: '$primaryPure',
    },
  },

  '.category-title': {
    marginTop: '0.25rem',
    fontSize: '0.75rem',
  },

  '@deviceMd': {
    whiteSpace: 'unset',
  },
});

export const TrashButton = styled('button', {
  flexShrink: 0,

  backgroundColor: 'transparent',
  height: '49px',
  width: '49px',
  borderRadius: '$pill',

  color: '$fontColorPrimary',

  transition: 'background-color 0.4s ease, color 0.4s',

  '&:hover': {
    color: '$white',
    backgroundColor: '$silverMedium',
  },
});
