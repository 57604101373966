import { Helmet as ReactHelmet } from 'react-helmet-async';

import { PageTitle } from '@components/page-title';

import { ASSETS_OTHERS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { GTM_QA } from '@config/gtm-config';
import { Environment } from '@helpers/enum/environment';
import { getEnvironment } from '@helpers/environment-helpers';

import { useSelector } from '@hooks/use-selector';

function Helmet() {
  const { emissor } = useSelector(({ application }) => application.config);

  return (
    <>
      <ReactHelmet>
        {/* Facebook Open Graph */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Loja ${emissor.name}`} />
        <meta property="og:description" content={emissor.description} />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:url" content={emissor.storeWebsite} />
        <meta property="og:site_name" content={`Loja ${emissor.name}`} />
        {getEnvironment() !== Environment.PRD && (
          <meta name="robots" content="noindex" />
        )}
        <meta
          property="og:image"
          content={`${env.URL_ASSETS}${ASSETS_OTHERS_PATH}/share-default.png`}
        />
        <meta property="og:image:width" content="100" />
        <meta property="og:image:height" content="100" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Loja ${emissor.name}`} />
        <meta name="twitter:description" content={emissor.description} />
        <meta name="twitter:site" content={emissor.twitterWebsite} />
        <meta name="twitter:url" content={emissor.storeWebsite} />
        <meta
          name="twitter:image"
          content={`${env.URL_ASSETS}${ASSETS_OTHERS_PATH}/share-default.png`}
        />

        <meta name="name" content={`Loja ${emissor.name}`} />
        <meta
          name="author"
          content={`${emissor.name} - ${emissor.storeWebsite}`}
        />
        <meta name="email" content={emissor.email} />
        <meta name="description" content={emissor.description} />
        <meta
          name="image"
          content={`${env.URL_ASSETS}${ASSETS_OTHERS_PATH}/share-default.png`}
        />
        <meta
          name="copyright"
          content={`Copyright © ${new Date().getFullYear} ${emissor.name}`}
        />

        <title>{`Loja ${emissor.name}`}</title>

        <link
          rel="shortcut icon"
          type="image/x-icon"
          href={`${env.URL_ASSETS}${ASSETS_OTHERS_PATH}/favicon.ico`}
        />

        {/* Dynatrace */}
        {getEnvironment() === Environment.PRD && (
          <script
            type="text/javascript"
            src={`${env.URL_ASSETS}/scripts/loja/dynatrace.js`}
          />
        )}
        {/* Google Tag Manager QA */}
        {getEnvironment() === Environment.QA && (
          <script type="text/javascript">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
                  {'gtm.start': new Date().getTime(),event:'gtm.js'}
                  );var f=d.getElementsByTagName(s)[0],

                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=

                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);

                  })(window,document,'script','dataLayer','${GTM_QA}');`}
          </script>
        )}

        {/* Google Tag Manager List */}
        {getEnvironment() === Environment.PRD &&
          emissor.tagGTMList.map(tagGTM => (
            <script key={tagGTM} type="text/javascript">
              {`
                (function (w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                  var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                  j.async = true;
                  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                  f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', '${tagGTM}');
              `}
            </script>
          ))}
      </ReactHelmet>
      <PageTitle />
    </>
  );
}

export default Helmet;
