import { useContext } from 'react';

import {
  RoutesContext,
  RoutesContextData,
} from '@store/context/routes-context';

export function useRoutes(): RoutesContextData {
  const context = useContext(RoutesContext);

  if (!context) {
    throw new Error('useRoutes must be used within a RoutesContext.');
  }

  const { handleNavigation, nextStep, prevStep } = context;
  return { handleNavigation, nextStep, prevStep };
}
