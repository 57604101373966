import { ProductsId } from '@helpers/enum/products-id';
import { unmountQueryParams } from '@helpers/query-string-helpers';

export const productsWithLightBackground = new Map<string, number[]>();

productsWithLightBackground.set('SICRE', [ProductsId.AUTO]);

export function productHasLightBackground(productId: number) {
  return productsWithLightBackground
    .get(unmountQueryParams(window.location.href).canal)
    ?.includes(productId);
}

interface ProductBenefitsItem {
  id: number;
  icon: string;
  title: string;
  description: string;
}

interface ProductIndividualPageInformationData {
  benefitsData: {
    title: string;
    list: ProductBenefitsItem[];
  };
  advantagesData: {
    title: string;
    list: string[];
    image: {
      url: string;
      alt: string;
      position: 'right' | 'left';
    };
  }[];
}

export const PRODUCTS_INDIVIDUAL_PAGE_INFORMATION: {
  [key: number]: ProductIndividualPageInformationData;
} = {
  [ProductsId.ALIMENTACAO]: {
    benefitsData: {
      title:
        'Benefício para pagamento de alimentações em mercados, padarias, mercearias e aplicativos de entrega de mercado.',
      list: [
        {
          id: 1,
          icon: 'fa-solid fa-rabbit-running',
          title: 'Agilidade',
          description: 'com crédito rápido no cartão, em 24 horas.',
        },
        {
          id: 2,
          icon: 'fa-solid fa-badge-percent',
          title: 'Condições comerciais',
          description: 'que se encaixam na sua empresa.',
        },
        {
          id: 3,
          icon: 'fa-solid fa-display',
          title: 'Praticidade',
          description:
            'para pagamentos, gestão, relatórios e mais no Portal VR.',
        },
        {
          id: 4,
          icon: 'fa-solid fa-mobile-screen',
          title: 'Aplicativo',
          description: 'exclusivo para seus colaboradores.',
        },
      ],
    },
    advantagesData: [
      {
        title: 'Para sua empresa',
        list: [
          'Segurança que atende aos acordos Sindicais e adequada ao PAT - Programa de Alimentação do Trabalhador (Lei 6.321/76)',
          'Condições comerciais que se encaixam na sua empresa.',
          'Praticidade para pagamentos, relatórios e muito mais no portal VR.',
          'Flexibilidade na escolha de datas e valores creditados no cartão.',
          'Facilidade com pedidos 100% online.',
        ],
        image: {
          url: `/image-${ProductsId.ALIMENTACAO}-1.png`,
          alt: 'Mulher',
          position: 'left',
        },
      },
      {
        title: 'Para seu funcionário',
        list: [
          'Liberdade para escolher refeições e estabelecimentos.',
          'Exclusividade com App para consultar saldo, extrato, promoções, envio de notificações e muito mais.',
          'Comodidade com o cartão aceito nas principais maquininhas do mercado.',
          'Cuidado que atende a todas as restrições alimentares. ',
          'Vantagens com saldo cumulativo no cartão.',
        ],
        image: {
          url: `/image-${ProductsId.ALIMENTACAO}-2.png`,
          alt: 'Mulher',
          position: 'right',
        },
      },
    ],
  },
  [ProductsId.AUXILIO_ALIMENTACAO]: {
    benefitsData: {
      title:
        'Benefício para pagamento de alimentações em mercados, padarias, mercearias e aplicativos de entrega de mercado.',
      list: [
        {
          id: 1,
          icon: 'fa-solid fa-rabbit-running',
          title: 'Agilidade',
          description: 'com crédito rápido no cartão, em 24 horas.',
        },
        {
          id: 2,
          icon: 'fa-solid fa-badge-percent',
          title: 'Condições comerciais',
          description: 'que se encaixam na sua empresa.',
        },
        {
          id: 3,
          icon: 'fa-solid fa-display',
          title: 'Praticidade',
          description:
            'para pagamentos, gestão, relatórios e mais no Portal VR.',
        },
        {
          id: 4,
          icon: 'fa-solid fa-mobile-screen',
          title: 'Aplicativo',
          description: 'exclusivo para seus colaboradores.',
        },
      ],
    },
    advantagesData: [
      {
        title: 'Para sua empresa',
        list: [
          'Condições comerciais que se encaixam na sua empresa.',
          'Praticidade para pagamentos, relatórios e muito mais no portal VR.',
          'Flexibilidade na escolha de datas e valores creditados no cartão.',
          'Facilidade com pedidos 100% online.',
        ],
        image: {
          url: `/image-${ProductsId.ALIMENTACAO}-1.png`,
          alt: 'Mulher',
          position: 'left',
        },
      },
      {
        title: 'Para seu funcionário',
        list: [
          'Liberdade para escolher refeições e estabelecimentos.',
          'Exclusividade com App para consultar saldo, extrato, promoções, envio de notificações e muito mais.',
          'Comodidade com o cartão aceito nas principais maquininhas do mercado.',
          'Cuidado que atende a todas as restrições alimentares. ',
          'Vantagens com saldo cumulativo no cartão.',
        ],
        image: {
          url: `/image-${ProductsId.ALIMENTACAO}-2.png`,
          alt: 'Mulher',
          position: 'right',
        },
      },
    ],
  },
  [ProductsId.AUTO]: {
    benefitsData: {
      title:
        'Uma solução que dá agilidade e mais tempo para sua empresa chegar ainda mais longe.',
      list: [
        {
          id: 1,
          icon: 'fa-solid fa-rabbit-running',
          title: 'Agilidade',
          description: 'com crédito rápido no cartão, em 24 horas.',
        },
        {
          id: 2,
          icon: 'fa-solid fa-badge-percent',
          title: 'Condições comerciais',
          description: 'que se encaixam na sua empresa.',
        },
        {
          id: 3,
          icon: 'fa-solid fa-display',
          title: 'Praticidade',
          description:
            'para pagamentos, gestão, relatórios e mais no Portal VR.',
        },
        {
          id: 4,
          icon: 'fa-solid fa-mobile-screen',
          title: 'Aplicativo',
          description: 'exclusivo para seus colaboradores.',
        },
      ],
    },
    advantagesData: [
      {
        title: 'Para sua empresa',
        list: [
          'Simplifica gerenciamento.',
          'A empresa determina a data e o valor a ser creditado no cartão.',
          'Reduz custos operacionais como pedidos de reembolso e antecipação de despesas.',
        ],
        image: {
          url: `/image-${ProductsId.AUTO}-1.png`,
          alt: 'Mulher',
          position: 'left',
        },
      },
      {
        title: 'Para seu funcionário',
        list: [
          'Rede de posto e estabelecimentos de serviços automotivos credenciados em todo o país.',
          'Descontos em serviços para a manutenção automotiva em parceiros credenciados.',
          'Autoserviço por meio de aplicativos: consulta de saldo, extrato, entre outras funções.',
        ],
        image: {
          url: `/image-${ProductsId.AUTO}-2.png`,
          alt: 'Mulher',
          position: 'right',
        },
      },
    ],
  },
  [ProductsId.REFEICAO]: {
    benefitsData: {
      title:
        'Benefício para pagamento de refeições em restaurantes, padarias, lanchonetes e aplicativos de entrega de refeições.',
      list: [
        {
          id: 1,
          icon: 'fa-solid fa-rabbit-running',
          title: 'Agilidade',
          description: 'com crédito rápido no cartão, em 24 horas.',
        },
        {
          id: 2,
          icon: 'fa-solid fa-badge-percent',
          title: 'Condições comerciais',
          description: 'que se encaixam na sua empresa.',
        },
        {
          id: 3,
          icon: 'fa-solid fa-display',
          title: 'Praticidade',
          description:
            'para pagamentos, gestão, relatórios e mais no Portal VR.',
        },
        {
          id: 4,
          icon: 'fa-solid fa-mobile-screen',
          title: 'Aplicativo',
          description: 'exclusivo para seus colaboradores.',
        },
      ],
    },
    advantagesData: [
      {
        title: 'Para sua empresa',
        list: [
          'Segurança que atende aos acordos Sindicais e adequada ao PAT - Programa de Alimentação do Trabalhador (Lei 6.321/76)',
          'Condições comerciais que se encaixam na sua empresa.',
          'Praticidade para pagamentos, relatórios e muito mais no portal VR.',
          'Flexibilidade na escolha de datas e valores creditados no cartão.',
          'Facilidade com pedidos 100% online.',
        ],
        image: {
          url: `/image-${ProductsId.REFEICAO}-1.png`,
          alt: 'Mulher',
          position: 'left',
        },
      },
      {
        title: 'Para seu funcionário',
        list: [
          'Liberdade para escolher refeições e estabelecimentos.',
          'Exclusividade com App para consultar saldo, extrato, promoções, envio de notificações e muito mais.',
          'Comodidade com o cartão aceito nas principais maquininhas do mercado.',
          'Cuidado que atende a todas as restrições alimentares. ',
          'Vantagens com saldo cumulativo no cartão.',
        ],
        image: {
          url: `/image-${ProductsId.REFEICAO}-2.png`,
          alt: 'Mulher',
          position: 'right',
        },
      },
    ],
  },
  [ProductsId.AUXILIO_REFEICAO]: {
    benefitsData: {
      title:
        'Benefício para pagamento de refeições em restaurantes, padarias, lanchonetes e aplicativos de entrega de refeições.',
      list: [
        {
          id: 1,
          icon: 'fa-solid fa-rabbit-running',
          title: 'Agilidade',
          description: 'com crédito rápido no cartão, em 24 horas.',
        },
        {
          id: 2,
          icon: 'fa-solid fa-badge-percent',
          title: 'Condições comerciais',
          description: 'que se encaixam na sua empresa.',
        },
        {
          id: 3,
          icon: 'fa-solid fa-display',
          title: 'Praticidade',
          description:
            'para pagamentos, gestão, relatórios e mais no Portal VR.',
        },
        {
          id: 4,
          icon: 'fa-solid fa-mobile-screen',
          title: 'Aplicativo',
          description: 'exclusivo para seus colaboradores.',
        },
      ],
    },
    advantagesData: [
      {
        title: 'Para sua empresa',
        list: [
          'Condições comerciais que se encaixam na sua empresa.',
          'Praticidade para pagamentos, relatórios e muito mais no portal VR.',
          'Flexibilidade na escolha de datas e valores creditados no cartão.',
          'Facilidade com pedidos 100% online.',
        ],
        image: {
          url: `/image-${ProductsId.REFEICAO}-1.png`,
          alt: 'Mulher',
          position: 'left',
        },
      },
      {
        title: 'Para seu funcionário',
        list: [
          'Liberdade para escolher refeições e estabelecimentos.',
          'Exclusividade com App para consultar saldo, extrato, promoções, envio de notificações e muito mais.',
          'Comodidade com o cartão aceito nas principais maquininhas do mercado.',
          'Cuidado que atende a todas as restrições alimentares. ',
          'Vantagens com saldo cumulativo no cartão.',
        ],
        image: {
          url: `/image-${ProductsId.REFEICAO}-2.png`,
          alt: 'Mulher',
          position: 'right',
        },
      },
    ],
  },
  [ProductsId.BOASFESTAS]: {
    benefitsData: {
      title:
        'Fazer um fim de ano ainda mais especial para o trabalhador da sua empresa é da nossa conta.',
      list: [
        {
          id: 1,
          icon: 'fa-solid fa-rabbit-running',
          title: 'Agilidade',
          description: 'com crédito rápido no cartão, em 24 horas.',
        },
        {
          id: 2,
          icon: 'fa-solid fa-badge-percent',
          title: 'Condições comerciais',
          description: 'que se encaixam na sua empresa.',
        },
        {
          id: 3,
          icon: 'fa-solid fa-display',
          title: 'Praticidade',
          description:
            'para pagamentos, gestão, relatórios e mais no Portal VR.',
        },
        {
          id: 4,
          icon: 'fa-solid fa-mobile-screen',
          title: 'Aplicativo',
          description: 'exclusivo para seus colaboradores.',
        },
      ],
    },
    advantagesData: [
      {
        title: 'Para sua empresa',
        list: [
          'Redução de custos de armazenagem, logística, entrega e transporte de cestas.',
          'Definição de data e valor a ser creditado no cartão, com crédito disponível em até 24h.',
          'Pedido 100% online pelo Portal VR com gestão de pagamentos, relatórios, entre outros.',
          'Mais motivação na equipe e retenção de talentos.',
        ],
        image: {
          url: `/image-${ProductsId.BOASFESTAS}-1.png`,
          alt: 'Mulher',
          position: 'left',
        },
      },
      {
        title: 'Para seu funcionário',
        list: [
          'Evita o incômodo do transporte de uma cesta física.',
          'Liberdade de escolha em estabelecimentos: aceito na rede Alimentação e Refeição.',
          'Aceito nas principais maquininhas de cartão.',
          'Facilidade com o app VR e Você para consulta do saldo, extrato, rede credenciada e muito mais.',
        ],
        image: {
          url: `/image-${ProductsId.BOASFESTAS}-2.png`,
          alt: 'Mulher',
          position: 'right',
        },
      },
    ],
  },
  [ProductsId.VRVA]: {
    benefitsData: {
      title:
        'Oferecer ainda mais liberdade de escolha para os trabalhadores da sua empresa é da nossa conta.',
      list: [
        {
          id: 1,
          icon: 'fa-solid fa-rabbit-running',
          title: 'Agilidade',
          description: 'com crédito rápido no cartão, em 24 horas.',
        },
        {
          id: 2,
          icon: 'fa-solid fa-badge-percent',
          title: 'Condições comerciais',
          description: 'que se encaixam na sua empresa.',
        },
        {
          id: 3,
          icon: 'fa-solid fa-display',
          title: 'Praticidade',
          description:
            'para pagamentos, gestão, relatórios e mais no Portal VR.',
        },
        {
          id: 4,
          icon: 'fa-solid fa-mobile-screen',
          title: 'Aplicativo',
          description: 'exclusivo para seus colaboradores.',
        },
      ],
    },
    advantagesData: [
      {
        title: 'Para sua empresa',
        list: [
          'Inovação: Cartão aceito nas redes Refeição e Alimentação, com saldo único.',
          'Facilidade: Pedidos 100% online com escolha de datas e valores creditados no cartão.',
          'Satisfação: ajuda sua empresa na retenção de talentos, oferecendo mais liberdade de escolha ao trabalhador.',
        ],
        image: {
          url: `/image-${ProductsId.VRVA}-1.png`,
          alt: 'Mulher',
          position: 'left',
        },
      },
      {
        title: 'Para seu funcionário',
        list: [
          'Praticidade: um cartão de saldo único com duas formas de uso, aceito nas redes Refeição e Alimentação.',
          'Inovação: Mais de 550 mil credenciamentos em estabelecimentos comerciais (restaurantes, padarias, lanchonetes, supermercados, atacados, hortifrútis, aplicativos de delivery, entre outros).',
          'Facilidade: App VR e VOCÊ exclusivo para consultas de saldo, extrato, rede credenciada e muito mais.',
        ],
        image: {
          url: `/image-${ProductsId.VRVA}-2.png`,
          alt: 'Mulher',
          position: 'right',
        },
      },
    ],
  },
  [ProductsId.VALE_TRANSPORTE]: {
    benefitsData: {
      title:
        'Benefício para pagamento de alimentações em mercados, padarias, mercearias e aplicativos de entrega de mercado.',
      list: [
        {
          id: 1,
          icon: 'fa-solid fa-rabbit-running',
          title: 'Agilidade',
          description: 'com crédito rápido no cartão, em 24 horas.',
        },
        {
          id: 2,
          icon: 'fa-solid fa-badge-percent',
          title: 'Condições comerciais',
          description: 'que se encaixam na sua empresa.',
        },
        {
          id: 3,
          icon: 'fa-solid fa-display',
          title: 'Praticidade',
          description:
            'para pagamentos, gestão, relatórios e mais no Portal VR.',
        },
        {
          id: 4,
          icon: 'fa-solid fa-mobile-screen',
          title: 'Aplicativo',
          description: 'exclusivo para seus colaboradores.',
        },
      ],
    },
    advantagesData: [
      {
        title: 'Para sua empresa',
        list: [
          'Segurança que atende aos acordos Sindicais e adequada ao PAT - Programa de Alimentação do Trabalhador (Lei 6.321/76)',
          'Condições comerciais que se encaixam na sua empresa.',
          'Praticidade para pagamentos, relatórios e muito mais no portal VR.',
          'Flexibilidade na escolha de datas e valores creditados no cartão.',
          'Facilidade com pedidos 100% online.',
        ],
        image: {
          url: `/image-${ProductsId.ALIMENTACAO}-1.png`,
          alt: 'Mulher',
          position: 'left',
        },
      },
      {
        title: 'Para seu funcionário',
        list: [
          'Liberdade para escolher refeições e estabelecimentos.',
          'Exclusividade com App para consultar saldo, extrato, promoções, envio de notificações e muito mais.',
          'Comodidade com o cartão aceito nas principais maquininhas do mercado.',
          'Cuidado que atende a todas as restrições alimentares. ',
          'Vantagens com saldo cumulativo no cartão.',
        ],
        image: {
          url: `/image-${ProductsId.ALIMENTACAO}-2.png`,
          alt: 'Mulher',
          position: 'right',
        },
      },
    ],
  },
  [ProductsId.CAIXA_ALIMENTACAO]: {
    benefitsData: {
      title:
        'Benefício para pagamento de alimentações em mercados, padarias, mercearias e aplicativos de entrega de mercado.',
      list: [
        {
          id: 1,
          icon: 'fa-solid fa-rabbit-running',
          title: 'Agilidade',
          description: 'com crédito rápido no cartão, em 24 horas.',
        },
        {
          id: 2,
          icon: 'fa-solid fa-badge-percent',
          title: 'Condições comerciais',
          description: 'que se encaixam na sua empresa.',
        },
        {
          id: 3,
          icon: 'fa-solid fa-display',
          title: 'Praticidade',
          description:
            'para pagamentos, gestão, relatórios e mais no Portal Caixa Pré-Pagos.',
        },
        {
          id: 4,
          icon: 'fa-solid fa-mobile-screen',
          title: 'Aplicativo',
          description: 'exclusivo para seus colaboradores.',
        },
      ],
    },
    advantagesData: [
      {
        title: 'Para sua empresa',
        list: [
          'Segurança que atende aos acordos Sindicais e adequada ao PAT - Programa de Alimentação do Trabalhador (Lei 6.321/76)',
          'Condições comerciais que se encaixam na sua empresa.',
          'Praticidade para pagamentos, relatórios e muito mais no Portal Caixa Pré-Pagos.',
          'Flexibilidade na escolha de datas e valores creditados no cartão.',
          'Facilidade com pedidos 100% online.',
        ],
        image: {
          url: `/image-${ProductsId.CAIXA_ALIMENTACAO}-1.png`,
          alt: 'Mulher',
          position: 'left',
        },
      },
      {
        title: 'Para seu funcionário',
        list: [
          'Liberdade para escolher refeições e estabelecimentos.',
          'Exclusividade com App para consultar saldo, extrato, promoções, envio de notificações e muito mais.',
          'Comodidade com o cartão aceito nas principais maquininhas do mercado.',
          'Cuidado que atende a todas as restrições alimentares. ',
          'Vantagens com saldo cumulativo no cartão.',
        ],
        image: {
          url: `/image-${ProductsId.CAIXA_ALIMENTACAO}-2.png`,
          alt: 'Mulher',
          position: 'right',
        },
      },
    ],
  },
  [ProductsId.CAIXA_REFEICAO]: {
    benefitsData: {
      title:
        'Benefício para pagamento de refeições em restaurantes, padarias, lanchonetes e aplicativos de entrega de refeições.',
      list: [
        {
          id: 1,
          icon: 'fa-solid fa-rabbit-running',
          title: 'Agilidade',
          description: 'com crédito rápido no cartão, em 24 horas.',
        },
        {
          id: 2,
          icon: 'fa-solid fa-badge-percent',
          title: 'Condições comerciais',
          description: 'que se encaixam na sua empresa.',
        },
        {
          id: 3,
          icon: 'fa-solid fa-display',
          title: 'Praticidade',
          description:
            'para pagamentos, gestão, relatórios e mais no Portal Caixa Pré-Pagos.',
        },
        {
          id: 4,
          icon: 'fa-solid fa-mobile-screen',
          title: 'Aplicativo',
          description: 'exclusivo para seus colaboradores.',
        },
      ],
    },
    advantagesData: [
      {
        title: 'Para sua empresa',
        list: [
          'Segurança que atende aos acordos Sindicais e adequada ao PAT - Programa de Alimentação do Trabalhador (Lei 6.321/76)',
          'Condições comerciais que se encaixam na sua empresa.',
          'Praticidade para pagamentos, relatórios e muito mais no Portal Caixa Pré-Pagos.',
          'Flexibilidade na escolha de datas e valores creditados no cartão.',
          'Facilidade com pedidos 100% online.',
        ],
        image: {
          url: `/image-${ProductsId.CAIXA_REFEICAO}-1.png`,
          alt: 'Mulher',
          position: 'left',
        },
      },
      {
        title: 'Para seu funcionário',
        list: [
          'Liberdade para escolher refeições e estabelecimentos.',
          'Exclusividade com App para consultar saldo, extrato, promoções, envio de notificações e muito mais.',
          'Comodidade com o cartão aceito nas principais maquininhas do mercado.',
          'Cuidado que atende a todas as restrições alimentares. ',
          'Vantagens com saldo cumulativo no cartão.',
        ],
        image: {
          url: `/image-${ProductsId.CAIXA_REFEICAO}-2.png`,
          alt: 'Mulher',
          position: 'right',
        },
      },
    ],
  },
};

export const COMPANY_COMMENTS_VT: {
  imageUrl: string;
  name: string;
  company: string;
  comment: string;
}[] = [
  {
    imageUrl:
      'https://vrmobilidade.com.br/wp-content/uploads/2023/05/atletico_logo_novo.webp',
    name: 'Cecília Nascimento',
    company: 'Analista de RH - Clube Atlético Mineiro',
    comment:
      'Utilizar o Vale-Transporte da VR Mobilidade é uma economia financeira e de tempo. Financeira em relação à economia gerada na administração dos saldos. E de tempo, em relação à facilidade na gestão de vale-transporte. Ele tem tantos recursos que tudo que você quer, você consegue. É uma facilidade imensa, uma rapidez para fazer o pedido.',
  },
  {
    imageUrl:
      'https://vrmobilidade.com.br/wp-content/uploads/2023/05/datametrica_logo_novo.webp',
    name: 'Raphael Pinheiro',
    company: 'Gerente - DP, Datamétrica',
    comment:
      'Houve um ganho em qualidade na gestão de benefícios com o Vale-Transporte da VR. Encontrei uma equipe muito parceira, disposta a me ouvir e a entender a minha necessidade. Quando ultrapassamos o marco de R$ 1 milhão economizados, a equipe da VR Mobilidade esteve aqui para comemorar conosco.',
  },
  {
    imageUrl:
      'https://vrmobilidade.com.br/wp-content/uploads/2023/05/mrveco_logo_novo_transparente.webp',
    name: 'Fabrízio',
    company: 'Diretor de CSC - MRV',
    comment:
      'A implementação foi realizada de forma gradativa e neste processo, pudemos contar com a expertise dos especialistas da VR Mobilidade, que a todo momento nos auxiliaram para que o processo de compra de vales-transportes obtivesse sucesso. Estamos satisfeitos tanto com a economia gerada quanto com a confiabilidade, exatidão de dados, recuperação e consulta fácil de saldo.',
  },
];
