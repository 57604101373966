import { LOADING_DEFAULT_MESSAGE } from '@config/application-config';

import { styled, keyframes } from '@src/themes';

const spinAnimation = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const LoadingViewMessageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',

  height: '100%',

  i: {
    animation: `${spinAnimation} 1s linear infinite`,

    fontSize: '2rem',
  },

  p: {
    textAlign: 'center',
    lineHeight: '1.5',
  },
  variants: {
    guidance: {
      vertical: {},
      horizontal: {
        flexDirection: 'row',
      },
    },
    verticalPadding: {
      true: {
        padding: '1.5rem 0',
      },
    },
  },
  defaultVariants: {
    verticalPadding: false,
    guidance: 'vertical',
  },
});

interface LoadingViewMessageProps {
  message?: string;
  guidance?: 'horizontal' | 'vertical';
  verticalPadding?: boolean;
}

export function LoadingViewMessage({
  message = LOADING_DEFAULT_MESSAGE,
  guidance,
  verticalPadding,
}: LoadingViewMessageProps) {
  return (
    <LoadingViewMessageContainer
      verticalPadding={verticalPadding}
      guidance={guidance}
    >
      <i className="fa-duotone fa-spinner-third" />
      <p>{message}</p>
    </LoadingViewMessageContainer>
  );
}
