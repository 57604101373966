import { useMemo } from 'react';

import formatCurrencyToValue from '@vr/devkit/money/formatCurrencyToValue';

import { CartPageFormFooter } from '@components/cart-page-product-list-form-components/cart-page-form-footer';
import { CartPageFormHeader } from '@components/cart-page-product-list-form-components/cart-page-form-header';
import { CartPageIndividualFormProductItem } from '@components/cart-page-product-list-form-components/cart-page-individual-form-product-item';

import { calcTotalValueCartByModality } from '@helpers/cart-helpers';
import { getCartproductIdList } from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

import { ProductListFormContainer } from './styles';

interface CartPageMobilityProductListFormProps {
  children?: React.ReactNode;
  balanceManagerValue: string;
}

export function CartPageMobilityProductListForm({
  children,
  balanceManagerValue,
}: CartPageMobilityProductListFormProps) {
  const { productList } = useSelector(({ products }) => products);
  const { productCartList, cartModality } = useSelector(({ cart }) => cart);
  const { contractedProducts, unservedRegionProducts } = useSelector(
    ({ customer }) => customer,
  );

  const cartproductIdList = useMemo(() => {
    return getCartproductIdList(
      productList,
      cartModality?.id,
      productCartList,
      contractedProducts,
      unservedRegionProducts,
    );
  }, [
    cartModality,
    contractedProducts,
    productCartList,
    productList,
    unservedRegionProducts,
  ]);

  const totalValueCart = calcTotalValueCartByModality({
    cartmodalityId: cartModality?.id,
    productCartList,
  });

  return (
    <ProductListFormContainer>
      <CartPageFormHeader />
      {cartproductIdList.map(productId => (
        <CartPageIndividualFormProductItem
          key={productId}
          productId={productId}
          isMobility
        />
      ))}
      {children}
      <CartPageFormFooter
        totalValue={totalValueCart + formatCurrencyToValue(balanceManagerValue)}
      />
    </ProductListFormContainer>
  );
}
