import React, { HTMLAttributes } from 'react';

import {
  DefaultTokenVariantsType,
  ProductsTokenVariantsType,
} from '@extra-types/token-variants-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

interface BenefitItemProps extends HTMLAttributes<HTMLDivElement> {
  icon: string;
  title: string;
  description: string;
  variant?: DefaultTokenVariantsType;
  productVariant?: ProductsTokenVariantsType;
  dark?: boolean;
}

export function BenefitItem({
  icon,
  title,
  variant = 'primary',
  description,
  dark = true,
  ...rest
}: BenefitItemProps) {
  return (
    <BenefitItemContainer variant={variant} dark={dark} {...rest}>
      <div className="benefit-item__icon">
        <i className={`${icon}`} />
      </div>
      <div className="benefit-item__text">
        <strong>{title}</strong>
        <p>{description}</p>
      </div>
    </BenefitItemContainer>
  );
}

const BenefitItemContainer = styled('article', {
  display: 'flex',
  alignItems: 'center',
  gap: convertPixelToRem(28),
  border: 'solid 2px $white',
  boxShadow: '0px 0px 8px #00000029',

  width: '100%',
  minHeight: '120px',
  padding: '1rem',
  transition: '0.4s ease',

  '.benefit-item__icon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0',

    i: {
      fontSize: '1.5rem',
    },
  },

  '.benefit-item__text': {
    display: 'flex',
    flexDirection: 'column',

    strong: {
      fontSize: '1rem',
      fontWeight: '$semibold',
    },

    p: {
      fontSize: convertPixelToRem(14),
      fontWeight: '$medium',
      lineHeight: '1.3',
    },
  },

  variants: {
    dark: {
      false: {
        '.benefit-item__icon': {
          background: '$primaryDark',
        },
      },
    },
    variant: {
      primary: {
        padding: '1.5rem 1rem',
        borderColor: '$primaryLight',
        borderRadius: '$md',

        '.benefit-item__icon': {
          background: '$primaryLight',
          height: convertPixelToRem(52),
          width: convertPixelToRem(52),
          borderRadius: '$circular',

          'svg, img, i': {
            fill: '$white',
            color: '$white',
          },
        },
        '.benefit-item__text': {
          strong: {
            color: '$secondaryPure',
          },
          p: {
            marginTop: '0.5rem',
            color: '$white',
          },
        },
      },
      secondary: {
        gap: '0.625rem',

        background: '$white',
        borderColor: '$primaryPure',
        borderRadius: '0 1.25rem 0 1.25rem',

        '.benefit-item__icon': {
          'svg, img, i': {
            color: '$fontColorDark',
            fill: '$fontColorDark',
            fontSize: convertPixelToRem(38),
          },
        },

        '.benefit-item__text': {
          strong: {
            color: '$fontColorDark',
          },
          p: {
            color: '$fontColorPrimary',
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});
