import { EntryModality } from '@elements/cart-summary-elements/entry-modality';
import { EntryProduct } from '@elements/cart-summary-elements/entry-product';

import { PricingDetailProductType } from '@extra-types/pricing-types';
import { convertPixelToRem, scrollbarStyle } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

const EntryModalityContainer = styled('div', {});

export function EntryProductList({
  productList,
}: {
  productList: PricingDetailProductType[];
}) {
  const { cartModality } = useSelector(({ cart }) => cart);

  if (!cartModality) {
    return null;
  }

  if (cartModality.isWalletGroupCard) {
    return (
      <EntryProductListContainer>
        <EntryModalityContainer>
          <EntryModality amount={productList[0].quantidade} />
        </EntryModalityContainer>
        <div className="product-list__container">
          {productList.map(product => (
            <EntryProduct
              key={product.codigoProduto}
              id={product.codigoProduto}
              value={product.valorMensal}
            />
          ))}
        </div>
      </EntryProductListContainer>
    );
  }

  return (
    <EntryProductListContainer>
      <div className="product-list__container">
        {productList.map(product => (
          <EntryProduct
            key={product.codigoProduto}
            id={product.codigoProduto}
            value={product.valorMensal}
            amount={product.quantidade}
          />
        ))}
      </div>
    </EntryProductListContainer>
  );
}

const EntryProductListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',

  '.product-list__container': {
    overflow: 'auto',
    flex: 1,
    ...scrollbarStyle,

    '> div:last-child': {
      borderBottom: 0,
    },

    '@media only screen and (max-width: 1114px)': {
      padding: '0 0.375rem',
      maxHeight: convertPixelToRem(300),
    },

    '@deviceXs': {
      maxHeight: convertPixelToRem(208),
    },
  },
});
