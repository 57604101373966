import { useContext } from 'react';

import {
  ComboCartPageContext,
  ComboCartPageContextData,
} from '@store/context/combo-cart-page-context';

export function useComboCartPageContext(): ComboCartPageContextData {
  const context = useContext(ComboCartPageContext);

  if (!context) {
    throw new Error(
      'useComboCartPageContext must be used within a ComboCartPageContext.',
    );
  }

  return context;
}
