import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

const CartPageFormHeaderIndividualContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: `3.5rem 1fr ${convertPixelToRem(
    132,
  )} ${convertPixelToRem(160)} ${convertPixelToRem(132)}`,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.25rem',

  background: '$silverPure',
  width: '100%',
  borderRadius: '6px 6px 0 0',

  '> div': {
    padding: `${convertPixelToRem(18)} 0`,

    fontWeight: '$medium',
    textAlign: 'center',

    '&:nth-child(2)': {
      textAlign: 'start',
    },
  },

  '@deviceMd': {
    display: 'none',
  },
});

const CartPageFormHeaderWalletContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: `3.5rem 1fr ${convertPixelToRem(
    150,
  )} ${convertPixelToRem(132)}`,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.25rem',

  background: '$silverPure',
  width: '100%',
  borderRadius: '6px 6px 0 0',

  '> div': {
    padding: `${convertPixelToRem(18)} 0`,

    textAlign: 'center',

    '&:nth-child(2)': {
      textAlign: 'start',
    },
  },

  '@deviceMd': {
    display: 'none',
  },
});

export function CartPageFormHeader({
  isWalletGroup = false,
}: {
  isWalletGroup?: boolean;
}) {
  if (isWalletGroup) {
    return (
      <CartPageFormHeaderWalletContainer>
        <div />
        <div>Saldo</div>
        <div>Valor mensal (R$)</div>
        <div>Valor Total</div>
      </CartPageFormHeaderWalletContainer>
    );
  }

  return (
    <CartPageFormHeaderIndividualContainer>
      <div />
      <div>Produto</div>
      <div>Qnt. cartões</div>
      <div>Valor mensal (R$)</div>
      <div>Valor Total</div>
    </CartPageFormHeaderIndividualContainer>
  );
}
