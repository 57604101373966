/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { MobilityOfferDescriptionType } from '@config/mobility-config';
import { convertPixelToRem } from '@helpers/style-helpers';

import { DevicesType, useWindowSize } from '@hooks/use-window-size';

import OfferTableView from './offer-table-view';
import { OfferDetailsContainer, OfferDescriptionContainer } from './styles';

interface OfferTableDescriptionProps {
  title?: string;
  subtitle?: string;
  comboOffer?: boolean;
  offerConfig: MobilityOfferDescriptionType[];
  orderValue: number;
}

export default function OfferTableDescription({
  title,
  subtitle,
  comboOffer = false,
  offerConfig,
  orderValue,
}: OfferTableDescriptionProps) {
  const [isCollapsed, setCollapse] = useState(false);
  const deviceSize = useWindowSize();
  const detailsRef = useRef<HTMLDivElement | null>(null);

  const handleCollapseState = () => setCollapse(collapse => !collapse);

  const handleBonusHeight: { [key in DevicesType]: number } = {
    xs: 535,
    sm: 596,
    md: 107 * offerConfig.length,
    lg: 101 * offerConfig.length,
  };

  const getTotalTaxes = () => {
    const tax = offerConfig.filter(
      item => item.base !== 0 && typeof item.base === 'number',
    );

    const totalTaxes = tax.reduce((acc, item) => {
      const value = Number(item.base);
      return acc + value;
    }, 0);

    return orderValue * totalTaxes;
  };

  return (
    <>
      {title && (
        <OfferDescriptionContainer
          data-testid="bonus-item-container"
          collapse={isCollapsed}
          onClick={handleCollapseState}
        >
          <div className="bonus-item__title">
            <p>
              {title} <i className="fa-solid fa-caret-up" />
            </p>
            {subtitle && <p className="subtitle">{subtitle}</p>}
          </div>
          <div className="bonus-item__value">
            <p>{formatValueToCurrency(getTotalTaxes())}</p>
          </div>
        </OfferDescriptionContainer>
      )}

      <OfferDetailsContainer
        ref={detailsRef}
        css={{ $$height: convertPixelToRem(handleBonusHeight[deviceSize]) }}
        collapse={isCollapsed}
        data-testid="bonus-details-container"
        className={title && 'with-padding'}
        comboOffer={comboOffer}
      >
        <div className="details-container__holder">
          <div className="table-holder">
            <OfferTableView offerConfig={offerConfig} orderValue={orderValue} />
          </div>
        </div>
      </OfferDetailsContainer>
    </>
  );
}
