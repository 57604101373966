import { SliderSwiper } from '@components/slider-swiper';
import { BenefitItem } from '@elements/benefit-item';
import { PageSection } from '@elements/page-section';
import { SectionHolder } from '@elements/section-holder';
import { Title } from '@elements/title';

import { SectionDefaultPropsType } from '@extra-types/section-props-type';
import { isCustomChannel } from '@helpers/channel-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useInformationSection } from '@hooks/use-information-section';
import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

const RESPONSIVE_BREAKPOINT = 1024;

export function BenefitsSection({ id, name }: SectionDefaultPropsType) {
  const { config } = useSelector(({ application }) => application);
  const {
    sectionInformation,
    checkSectionItemsEmpty,
    sectionVariant,
    isMobile,
  } = useInformationSection({
    name,
    sectionVariant: 'secondary',
    breakingPointToWatch: RESPONSIVE_BREAKPOINT,
    observeMobileResponsive: true,
  });

  const isDark = !isCustomChannel(config.siglaCanal);

  if (checkSectionItemsEmpty || !sectionInformation) {
    return null;
  }

  return (
    <PageSection id={id} variant={sectionVariant} dark>
      <BanefictSectionContent>
        <Title.Root fontColorVariant={sectionVariant} barColor={sectionVariant}>
          <Title.Default tag="h2">
            Porque oferecer {config.emissor.shortName} para seus colaboradores
          </Title.Default>
          <Title.Subtitle>
            Soluções sob medida para ter mais tempo e uma vida melhor.
          </Title.Subtitle>
        </Title.Root>
        <div className="benefits__items">
          <SliderSwiper
            itensPerColumn={3}
            showSlider={isMobile}
            variant={sectionVariant}
            navigation={false}
            breakpoints={{
              651: {
                slidesPerView: 2.1,
                spaceBetween: 16,
              },
            }}
          >
            {sectionInformation.itens.map(itemSection => (
              <BenefitItem
                key={itemSection.id}
                icon={itemSection.nomeIcone}
                title={itemSection.titulo}
                description={itemSection.conteudo}
                dark={isDark}
              />
            ))}
          </SliderSwiper>
        </div>
      </BanefictSectionContent>
    </PageSection>
  );
}

const BanefictSectionContent = styled(SectionHolder, {
  '.benefits__items': {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '1rem',

    '@media screen and (max-width: 1024px)': {
      display: 'flex',
      flexDirection: 'column',

      article: {
        minHeight: convertPixelToRem(148),
      },
    },

    '@deviceXs': {
      article: {
        minHeight: convertPixelToRem(148),
      },
    },

    '@media only screen and (max-width: 720px)': {
      article: {
        minHeight: convertPixelToRem(170),
      },
    },

    '@media only screen and (max-width: 361px)': {
      article: {
        minHeight: convertPixelToRem(160),
      },
    },
  },
});
