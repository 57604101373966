import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { CartPageHcmHeader } from '@compositions/cart-page-hcm/cart-page-hcm-header';
import { ProductAddonItem } from '@elements/product-addon-item';
import { ComboServiceDisabledForm } from '@pages/offer-page/custom-offer-pages/components/combo-summary-components/combo-service-disabled-form';
import { ComboServiceFormCollapseBox } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-collapse-box';
import { ComboServiceFormContainer } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-container';
import { ComboServiceFormFooterArea } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-footer-area';
import { ComboServiceFormHeader } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-header';
import { ComboServiceFormSectionContainer } from '@pages/offer-page/custom-offer-pages/elements/combo-service-form-elements/combo-service-form-section-container';

import { additionalProducts } from '@config/hcm-config';
import { ComboServiceFormType } from '@extra-types/combo-service-form-type';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { createComboModalityFormId } from '@helpers/service-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useComboCartPageContext } from '@hooks/use-combo-cart-page-context';
import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

export interface HCMFormType {
  'average-employees': number;
  planType: PlanTypeEnum;
}

export function ComboHCMServiceForm({
  modalityData,
  isFormActive,
  handleNextForm,
}: ComboServiceFormType) {
  const { getProductForSaleListByModality, isFormEnable } =
    useComboCartPageContext();
  const { productComboCartList, comboCartPricingData } = useSelector(
    ({ cart }) => cart,
  );

  const methods = useForm<HCMFormType>({
    mode: 'onChange',
    defaultValues: {
      'average-employees': productComboCartList[modalityData.id]?.[0]?.amount,
    },
  });

  const availableProducts = getProductForSaleListByModality(modalityData.id);
  const productData = availableProducts[0];

  const formID = createComboModalityFormId(modalityData.serviceGroupId);
  const totalValue = comboCartPricingData?.resumo.servicos.HCM?.valorTotal || 0;

  const onSubmit: SubmitHandler<HCMFormType> = async () => {
    const isFormValid = await methods.trigger(['average-employees']);
    if (isFormValid) handleNextForm();
  };

  if (!isFormEnable(formID)) {
    return <ComboServiceDisabledForm modalityData={modalityData} />;
  }

  return (
    <FormProvider {...methods}>
      <ComboServiceFormSectionContainer>
        <ComboServiceFormContainer
          id={formID}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <ComboServiceFormHeader
            serviceGroupId={modalityData.serviceGroupId}
            isCollapsed={!isFormActive}
            value={totalValue}
            showRecurringValueLabel={false}
          />
          <CartPageHcmHeader
            isFormActive={isFormActive}
            modalityData={modalityData}
            plan={productData}
          />
          <ComboServiceFormCollapseBox
            isFormActive={isFormActive}
            value={totalValue}
            showRecurringValueLabel={false}
          >
            <ContentContainer>
              <AditionalProductsContainer>
                {additionalProducts[productData.id].map(additionalProduct => (
                  <ProductAddonItem
                    key={additionalProduct.id}
                    data={additionalProduct}
                  />
                ))}
              </AditionalProductsContainer>
            </ContentContainer>
          </ComboServiceFormCollapseBox>
        </ComboServiceFormContainer>
        <ComboServiceFormFooterArea
          isCollapsed={!isFormActive}
          extraDisableCondition={!methods.formState.isValid}
        />
      </ComboServiceFormSectionContainer>
    </FormProvider>
  );
}

const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',

  '> p': {
    fontSize: convertPixelToRem(14),
  },
});

const AditionalProductsContainer = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',

  '> p': {
    fontSize: convertPixelToRem(14),
  },
});
