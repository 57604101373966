import React from 'react';

import { ProceedToCartButton } from '@components/proceed-to-cart-button';

import { removeSpecialCaracters } from '@helpers/function-helpers';
import { getProductData } from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

import { ProductCartPreview } from './product-cart-preview';

interface ProductInCartViewProps {
  productId: number;
  handleRemoveProduct: () => void;
  setEnableEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

function ProductInCartView({
  productId,
  setEnableEdit,
  handleRemoveProduct,
}: ProductInCartViewProps) {
  const { productCartList } = useSelector(({ cart }) => cart);
  const { productList } = useSelector(({ products }) => products);

  const productData = getProductData(productId, productList);
  const productCartData = productCartList.find(
    productCart => productCart.id === productId,
  );

  if (productCartData === undefined || productData === undefined) {
    return (
      <>
        <div className="product-in-cart-view__flag">
          <i className="fa-solid fa-cart-shopping" />
        </div>
        <div className="product-in-cart-view__content">
          <i className="fa-solid fa-circle-xmark" />
          <h2>Erro ao buscar no carrinho!</h2>
          <div>
            <p>
              Não foi possível buscar o produto no carrinho, recarregue a página
              e tente novamente.
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="product-in-cart-view__flag">
        <i className="fa-solid fa-cart-shopping" />
      </div>
      <div className="product-in-cart-view__content">
        <i className="fa-solid fa-check-circle" />
        <p>Produto adicionado!</p>
        <ProductCartPreview data={productCartData} />
      </div>
      <div className="product-in-cart-view__product-actions">
        <ProceedToCartButton
          id={`btn-seguir-carrinho-${removeSpecialCaracters(productData.name)}`}
        />
        <div className="product-actions__buttons-group">
          <button
            id={`btn-editar-${removeSpecialCaracters(productData.name)}`}
            type="button"
            className="edit-button"
            onClick={() => setEnableEdit(true)}
          >
            Editar
          </button>
          <button
            id={`btn-excluir-${removeSpecialCaracters(productData.name)}`}
            type="button"
            className="remove-button"
            onClick={handleRemoveProduct}
          >
            Excluir
          </button>
        </div>
      </div>
    </>
  );
}

export default ProductInCartView;
