import { AccordionItemType } from '@extra-types/accordion-item-type';
import { FAQArticleType } from '@extra-types/faq-article-type';

/**
 * Função que formata uma lista de objetos do tipo SectionItemType para uma lista de objetos
 * do tipo AccordionItemType
 * @param {SectionItemType[]} itemList
 * @returns {AccordionItemType[]}
 */
export function createAccordionDataList(
  itemList: FAQArticleType[],
): AccordionItemType[] {
  return itemList.map(item => ({
    id: item.id,
    title: item.title,
    description: item.content,
  }));
}
