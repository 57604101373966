import { useEffect, useState } from 'react';

import { Button } from '@elements/button';
import { FAQAccordion } from '@elements/faq-accordion-item';

import { ensureNotUndefined } from '@helpers/function-helpers';

import { useFAQContext } from '@hooks/use-faq-context';

import { FAQArticles } from '@api/models/response/faq-articles-list-response';

import { styled } from '@src/themes';

import { Skeleton } from './skeletons';

const INITIAL_NUMBER_OF_ARTICLE_DISPLAYED = 7;
const LIMIT_OF_ARTICLES_TO_BE_ADDED = 4;

interface SearchedFAQArticles extends FAQArticles {
  categoryTitle: string;
}
interface FAQArticleListProps {
  articlesFounded?: Record<string, FAQArticles[]>;
}

export function FAQArticleList({ articlesFounded }: FAQArticleListProps) {
  const [activeArticle, setActiveArticle] = useState<string | undefined>();
  const [numberOfArticlesShown, setNumberOfArticlesShown] = useState(
    INITIAL_NUMBER_OF_ARTICLE_DISPLAYED,
  );
  const {
    activeCategory,
    allFaqArticles,
    selectedArticle,
    faqCategories,
    getArticlesByCategoryId,
  } = useFAQContext();

  const handleActiveArticle = (id: string) => {
    if (activeArticle === id) {
      setActiveArticle(undefined);
    } else {
      setActiveArticle(id);
    }
  };

  const getCategoryById = (id: string) => {
    return ensureNotUndefined(
      faqCategories.data?.find(category => category.id === id),
    );
  };

  const handleShowMoreArticles = (listLength: number) => {
    setNumberOfArticlesShown(value => {
      const incrementNumber = listLength - value;

      return incrementNumber < LIMIT_OF_ARTICLES_TO_BE_ADDED
        ? value + incrementNumber
        : value + LIMIT_OF_ARTICLES_TO_BE_ADDED;
    });
  };

  function buildArticlesFoundedArray(): SearchedFAQArticles[] {
    const filteredArticles: SearchedFAQArticles[] = [];
    if (articlesFounded) {
      Object.entries(articlesFounded).forEach(([categoryTitle, articles]) => {
        articles.forEach(article => {
          if (article.id) {
            filteredArticles.push({
              ...article,
              categoryTitle,
            } as SearchedFAQArticles);
          }
        });
      });
    }
    return filteredArticles;
  }

  const resetActiveArticle = () => {
    setActiveArticle(undefined);
    setNumberOfArticlesShown(INITIAL_NUMBER_OF_ARTICLE_DISPLAYED);
  };

  useEffect(() => {
    resetActiveArticle();
  }, [activeCategory]);

  if (!activeCategory || allFaqArticles.isLoading) {
    return (
      <FAQArticleListContainer>
        <Skeleton.Line height={32} />
        <FAQArticleListSkeletonContainer data-testid="faq-article-loading-skeleton">
          <Skeleton.Line height={60} />
          <Skeleton.Line height={60} />
          <Skeleton.Line height={60} />
          <Skeleton.Line height={60} />
          <Skeleton.Line height={60} />
        </FAQArticleListSkeletonContainer>
      </FAQArticleListContainer>
    );
  }

  if (
    !allFaqArticles.data ||
    allFaqArticles.data.length === 0 ||
    allFaqArticles.isError
  ) {
    return (
      <FAQArticleListContainer
        css={{
          $$titleColor: activeCategory.color,
        }}
      >
        <h2>{activeCategory.title}</h2>
        <p className="faq-article-list-container">
          Lamentamos, mas não foi possível carregar o conteúdo desta categoria
          no momento.
          <br />
          Por favor, tente novamente mais tarde.
        </p>
      </FAQArticleListContainer>
    );
  }

  if (articlesFounded && Object.entries(articlesFounded).length > 0) {
    return (
      <FAQArticleHolder>
        <FAQArticleListContainer>
          <FAQAccordionItemListContainer>
            {buildArticlesFoundedArray()
              .slice(0, numberOfArticlesShown)
              .map(article => {
                if (article.id) {
                  return (
                    <FAQAccordion.Default
                      key={article.id}
                      articleId={article.id}
                      title={article.title}
                      content={article.content}
                      isActive={article.id === activeArticle}
                      color={getCategoryById(article.categoryId).color}
                      categoryTitle={article.categoryTitle}
                      searchResult
                      changeActiveAction={handleActiveArticle}
                    />
                  );
                }
                return null;
              })}
            {buildArticlesFoundedArray().length > numberOfArticlesShown && (
              <div className="faq-accordion-list-container__action-area">
                <Button.Default
                  data-testid="load-more-articles"
                  size="small"
                  negative
                  onClick={() =>
                    handleShowMoreArticles(buildArticlesFoundedArray().length)
                  }
                >
                  Carregar mais
                  <i className="fa-solid fa-chevron-down" />
                </Button.Default>
              </div>
            )}
          </FAQAccordionItemListContainer>
        </FAQArticleListContainer>
      </FAQArticleHolder>
    );
  }

  return (
    <FAQArticleHolder>
      {selectedArticle && (
        <FAQArticleListContainer
          css={{
            $$titleColor: activeCategory.color,
          }}
        >
          <h2>{activeCategory.title}</h2>
          <FAQAccordion.Selected
            className="selected-article"
            key={selectedArticle.id}
            articleId={selectedArticle.id}
            title={selectedArticle.title}
            content={selectedArticle.content}
            color={getCategoryById(selectedArticle.categoryId).color}
          />
        </FAQArticleListContainer>
      )}
      <FAQArticleListContainer
        css={{
          $$titleColor: activeCategory.color,
        }}
      >
        {selectedArticle ? (
          <p>Outras dúvidas que também podem ser do seu interesse</p>
        ) : (
          <h2>{activeCategory.title}</h2>
        )}
        <FAQAccordionItemListContainer>
          {getArticlesByCategoryId(activeCategory.id)
            .slice(0, numberOfArticlesShown)
            .map(article => (
              <FAQAccordion.Default
                key={article.id}
                articleId={article.id}
                title={article.title}
                content={article.content}
                isActive={article.id === activeArticle}
                color={activeCategory.color}
                changeActiveAction={handleActiveArticle}
              />
            ))}
          {allFaqArticles.data.length > numberOfArticlesShown && (
            <div className="faq-accordion-list-container__action-area">
              <Button.Default
                data-testid="load-more-articles"
                size="small"
                negative
                onClick={() =>
                  handleShowMoreArticles(allFaqArticles.data.length)
                }
              >
                Carregar mais
                <i className="fa-solid fa-chevron-down" />
              </Button.Default>
            </div>
          )}
        </FAQAccordionItemListContainer>
      </FAQArticleListContainer>
    </FAQArticleHolder>
  );
}

const FAQArticleHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
});
const FAQArticleListSkeletonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
});

export const FAQArticleListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',

  p: {
    fontWeight: 500,
  },

  h2: {
    color: '$$titleColor',
    fontSize: '1.25rem',
  },

  '.faq-article-list-container': {
    lineHeight: 1.4,
  },
});

export const FAQAccordionItemListContainer = styled('div', {
  background: '$silverLight',
  padding: '0.75rem 0',
  borderRadius: '$lg',
  boxShadow: '0px 2px 8px #00000029',

  'article:first-child:not(.active-container), article + article:not(:last-child):not(.active-container)':
    {
      borderBottom: '1px solid $fontColorPrimary',
    },

  'article:last-child:not(.active-container)': {
    borderBottomWidth: '0 !important',
  },

  '.faq-accordion-list-container__action-area': {
    display: 'flex',
    justifyContent: 'center',
    padding: '1.5rem 0 1rem',
  },

  button: {
    background: 'transparent',
    borderColor: 'transparent',
  },
});
