import { MobilitySectionTitle } from '@elements/mobility-section-title';
import { PageSection } from '@elements/page-section';

import { ASSETS_MOBILITY_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export function SmartRoutingSection() {
  return (
    <PageSection dark>
      <SectionHolder>
        <div className="smart-routing__content">
          <div className="smart-routing__content-text">
            <MobilitySectionTitle>
              Roteirização inteligente para o trabalhador
            </MobilitySectionTitle>
            <p>No caminho certo para fazer mais com menos</p>
          </div>
          <div className="smart-routing__content-cards">
            <ul>
              <li>
                <i className="fa-light fa-location-dot" />
                <p>
                  Sistema inteligente com análise de trajetos, mapas e trânsito
                  em tempo real.
                </p>
              </li>
              <li>
                <i className="fa-solid fa-chart-line-up" />
                <p>
                  Comparativos por custo, tempo, quantidade de modais e
                  deslocamento.
                </p>
              </li>
              <li>
                <i className="fa-light fa-user" />
                <p>Análise em massa ou individual, conforme a necessidade.</p>
              </li>
              <li>
                <i className="fa-light fa-file" />
                <p>
                  Relatório de vale-transporte com opções disponíveis para cada
                  rota.
                </p>
              </li>
              <li>
                <i className="fa-light fa-star" />
                <p>
                  Recomendação estratégica de alocação do trabalhador para uma
                  unidade da empresa mais próxima.
                </p>
              </li>
              <li>
                <i className="fa-light fa-laptop" />
                <p>Definição da melhor rota com até seis opções por CPF.</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="smart-routing__image_area">
          <img src={`${urlAssets}/roteirizacao-inteligente.png`} alt="" />
        </div>
      </SectionHolder>
    </PageSection>
  );
}

const urlAssets = env.URL_ASSETS + ASSETS_MOBILITY_PATH;

const SectionHolder = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '3rem',
  paddingBottom: '3rem',

  '@deviceMd': {
    paddingTop: '2rem',
    flexDirection: 'column-reverse',
  },

  '.smart-routing__content': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '.smart-routing__content-text': {
      p: {
        marginTop: convertPixelToRem(5),
        color: '$black',
        fontSize: convertPixelToRem(16),

        '@deviceLg': {
          fontSize: convertPixelToRem(12),
        },

        '@deviceMd': {
          fontSize: convertPixelToRem(12),
        },
      },

      '@deviceMd': {
        h2: {
          textAlign: 'unset',
        },
      },
    },
    '.smart-routing__content-cards': {
      ul: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: convertPixelToRem(9),
        maxWidth: convertPixelToRem(587),
        marginTop: '1rem',

        '@deviceMd': {
          maxWidth: 'unset',
        },

        li: {
          display: 'flex',
          flexDirection: 'column',
          gap: convertPixelToRem(8),
          width: convertPixelToRem(189),
          height: convertPixelToRem(123),
          backgroundColor: '$white',
          alignItems: 'center',
          textAlign: 'center',
          borderRadius: `0 12px 0 12px`,

          '@deviceMd': {
            width: '100%',
            justifyContent: 'center',
          },

          i: {
            fontSize: convertPixelToRem(20),
            fontWeight: '$fontAwesome',
            color: '$valeTransportePrimaryPure',
            marginTop: '0.75rem',
          },

          p: {
            fontSize: convertPixelToRem(12),
            padding: `0 18px`,
          },
        },
      },
    },
  },
  '.smart-routing__image_area': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    img: {
      width: convertPixelToRem(499),
      height: convertPixelToRem(413),
      objectFit: 'contain',

      '@deviceLg': {
        content: `url(${urlAssets}/roteirizacao-inteligente-tablet.png)`,
        width: convertPixelToRem(350),
        height: convertPixelToRem(382),
      },

      '@deviceMd': {
        content: `url(${urlAssets}/roteirizacao-inteligente-mobile.png)`,
        width: convertPixelToRem(347),
        height: convertPixelToRem(256),
        marginBottom: convertPixelToRem(20),
      },
    },
  },
});
