import { useFormContext } from 'react-hook-form';

import { FAQArticleList } from '@components/faq-article-list';
import { FAQCategoryList } from '@components/faq-category-list';
import { Button } from '@elements/button';

import { useFAQContext } from '@hooks/use-faq-context';

import { styled } from '@src/themes';

export function FAQ() {
  const methods = useFormContext();
  const { notFoundArticle, backToFAQ, handleSearchChange, searchedValue } =
    useFAQContext();
  const articlesFounded = handleSearchChange(searchedValue);

  if (notFoundArticle) {
    return (
      <NotFoundArticleContainer>
        <div className="searched-tip-box">
          <p>
            Não foi possível encontrar dúvidas relacionadas a:&nbsp;
            <strong>”{searchedValue}”</strong>
          </p>
          <Button.Default
            size="small"
            onClick={() => {
              methods.resetField('search');
              backToFAQ();
            }}
          >
            Voltar ao inicio
          </Button.Default>
        </div>
      </NotFoundArticleContainer>
    );
  }

  if (Object.entries(articlesFounded).length > 0) {
    return (
      <SearchResultFAQContainer>
        <button className="back-button" type="button" onClick={backToFAQ}>
          <i className="fas fa-chevron-left" />
          Voltar ao início
        </button>
        <p>
          Lista de busca relacionado a: <strong>”{searchedValue}”</strong>
        </p>
        <FAQArticleList articlesFounded={articlesFounded} />
      </SearchResultFAQContainer>
    );
  }

  return (
    <FAQContainer>
      <FAQCategoryList />
      <FAQArticleList />
    </FAQContainer>
  );
}

const NotFoundArticleContainer = styled('div', {
  display: 'flex',

  paddingTop: '1.25rem',

  '.searched-tip-box': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
    padding: '1.25rem 1rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

    width: '100%',

    borderRadius: '0.875rem',
    background: '$white',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.16)',

    p: {
      fontSize: '1.25rem',
      fontWeight: '400',
    },
  },
});

const FAQContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '280px 1fr',
  gap: '3.25rem',
  paddingTop: '1.25rem',

  '@deviceLg': {
    gridTemplateColumns: '1fr',
    gap: '1.125rem',
  },
});

const SearchResultFAQContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '.back-button': {
    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
    color: '$fontColorPrimary',
    borderBottom: '1px solid $fontColorPrimary',
    paddingTop: '1rem',
    fontWeight: 500,

    '&:hover': {
      color: '$primaryPure',
      borderBottom: '1px solid $primaryPure',
    },

    i: {
      marginRight: '0.5rem',
      fontWeight: 400,
    },
  },

  '> p': {
    padding: '1rem 0',
  },
});
