import { PropsWithChildren } from 'react';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

type CustomerModalCompanyMessageProps = {
  customMessage?: string;
  blocked?: boolean;
} & PropsWithChildren;

export function CustomerModalCompanyMessage({
  customMessage = 'Disponibilizaremos para contratação apenas produtos/serviços ainda não adquiridos.',
  blocked = false,
  children,
}: CustomerModalCompanyMessageProps) {
  return (
    <CustomerModalCompanyMessageContainer blocked={blocked}>
      <p>Você já é nosso cliente, é bom tê-lo de volta!</p>
      {children}
      <div className="contracted-products__box">
        <i className="fa-solid fa-circle-exclamation" />
        {customMessage}
      </div>
    </CustomerModalCompanyMessageContainer>
  );
}

const CustomerModalCompanyMessageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
  textAlign: 'center',

  '> p': {
    fontWeight: '$medium',
    fontSize: convertPixelToRem(14),
    lineHeight: 1.5,
    textAlign: 'center',
  },

  '.contracted-products__box': {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    gap: '0.75rem',
    fontSize: '0.875rem',

    background: '$informationLight',
    width: '100%',
    padding: '0.75rem 1rem',
    borderRadius: '0.5rem',
    boxShadow: '2px 4px 16px #00000014',

    i: {
      fontSize: '2rem',
      color: '$informationDark',
    },
  },

  variants: {
    blocked: {
      true: {
        '> p': {
          fontSize: convertPixelToRem(26),
          fontWeight: 'bold',
          textAlign: 'left',
          lineHeight: 1.2,
        },
      },
    },
  },
});
