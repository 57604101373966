import { SummaryDetails } from '@components/cart-summary-components/summary-details';
import { SummaryTitle } from '@elements/cart-summary-elements/summary-title';
import { ErrorViewMessage } from '@elements/error-view-message';
import { LoadingViewMessage } from '@elements/loading-view-message';

import { useSelector } from '@hooks/use-selector';

import {
  CartSummaryContainer,
  CartSummaryStaticMessageContainer,
} from './styles';

export function CartSummary({ isCollapsed }: { isCollapsed: boolean }) {
  const cartData = useSelector(({ cart }) => cart);

  if (cartData.isCartLoading) {
    return (
      <CartSummaryContainer>
        <CartSummaryStaticMessageContainer>
          <LoadingViewMessage message="Aguarde um momento, estamos precificando a sua compra" />
        </CartSummaryStaticMessageContainer>
      </CartSummaryContainer>
    );
  }

  if (
    cartData.cartPricingData === undefined ||
    cartData.cartModality === undefined
  ) {
    return (
      <CartSummaryContainer>
        <CartSummaryStaticMessageContainer>
          <ErrorViewMessage />
        </CartSummaryStaticMessageContainer>
      </CartSummaryContainer>
    );
  }

  return (
    <CartSummaryContainer>
      <SummaryTitle hidden={isCollapsed} />
      <SummaryDetails
        data={cartData.cartPricingData}
        cartModality={cartData.cartModality}
        isCollapsed={isCollapsed}
      />
    </CartSummaryContainer>
  );
}
