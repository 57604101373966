import { useState } from 'react';

import { ComboCartSummary } from '@pages/offer-page/custom-offer-pages/compositions/combo-cart-summary';

import { HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from '@config/styles-config';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

export function ComboCartSummarySection() {
  const [isCollapsed, setCollapseState] = useState(true);
  const { isCartLoading } = useSelector(({ cart }) => cart);

  const handleCollapseState = (state?: boolean) => {
    return state === undefined
      ? setCollapseState(oldState => !oldState)
      : setCollapseState(state);
  };

  return (
    <CartSummaryViewContainer>
      <CollapseButton
        isCollapsed={isCollapsed}
        type="button"
        onClick={() => handleCollapseState()}
        disabled={isCartLoading}
      >
        <i className="fa-solid fa-circle-arrow-up" />
        {isCollapsed ? 'mostrar' : 'menos'} detalhes
      </CollapseButton>
      <ComboCartSummary isCollapsed={isCollapsed} />
    </CartSummaryViewContainer>
  );
}

const CartSummaryViewContainer = styled('aside', {
  zIndex: '$2',
  position: 'sticky',
  top: `calc(${convertPixelToRem(HEADER_HEIGHT)} + 1rem)`,

  display: 'flex',
  flexDirection: 'column',

  transition: 'transform 0.4s ease',

  '@deviceLg': {
    position: 'fixed',
    top: 'unset',
    bottom: 0,
    left: 0,
    right: 0,

    background: '$white',
    maxHeight: `calc(100vh - ${HEADER_HEIGHT}px - 1rem)`,
    borderRadius: `${convertPixelToRem(8)} ${convertPixelToRem(8)} 0 0`,
    boxShadow: '0px 0px 8px #00000080',
  },

  '@deviceSm': {
    maxHeight: `calc(100vh - ${HEADER_MOBILE_HEIGHT}px - 1rem)`,
  },
});

const CollapseButton = styled('button', {
  display: 'none',
  pointerEvents: 'none',

  background: 'transparent',
  padding: 0,
  width: 0,

  '&:disabled': {
    color: '$fontColorLight',
  },

  '@deviceLg': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    pointerEvents: 'all',

    background: '$silverLight',
    width: '100%',
    padding: '0.5rem',
    border: '1px solid #E4E8ED',
    borderRadius: '0.375rem 0.375rem 0 0',

    color: '$primaryPure',
    fontWeight: '$semibold',
    fontSize: '0.75rem',

    i: {
      transition: 'transform 0.2s ease',

      fontSize: '15px',
    },
  },
  variants: {
    isCollapsed: {
      false: {
        i: {
          transform: 'rotate(180deg)',
        },
      },
    },
  },
});
