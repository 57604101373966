import { Button } from '@elements/button';
import { PageContainer } from '@elements/page-container';
import { PageSection } from '@elements/page-section';
import { Title } from '@elements/title';

import { styled } from '@src/themes';

export function ErrorPage() {
  return (
    <PageContainer>
      <PageSection
        variant="secondary"
        dark
        css={{
          height: '100%',
          '> div': {
            display: 'flex',
            alignItems: 'center',

            height: '100%',
          },
        }}
      >
        <ErrorContent>
          <ErrorCode>404...</ErrorCode>
          <Title.Root barColor="secondary" fontColorVariant="secondary">
            <Title.Default tag="h1">
              Desculpe, não conseguimos encontrar esta página
            </Title.Default>
            <Title.Subtitle>
              Que tal acessar a página inicial para encontrar o que você
              procura?
            </Title.Subtitle>
          </Title.Root>
          <Button.LinkRouter to="/" variant="secondary">
            Ir para página inicial
          </Button.LinkRouter>
        </ErrorContent>
      </PageSection>
    </PageContainer>
  );
}

const ErrorContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '2rem',

  padding: '2rem 0',

  a: {
    marginLeft: '2rem',
  },

  '@deviceSm': {
    a: {
      margin: 0,
      width: '100%',
    },
  },
});

const ErrorCode = styled('div', {
  color: '$primaryDark',
  fontSize: '15rem',
  fontWeight: 800,
  textShadow: `-2px -2px 0 $colors$secondaryPure,
    2px -2px 0 $colors$secondaryPure,
    -2px 2px 0 $colors$secondaryPure,
    2px 2px 0 $colors$secondaryPure`,

  '@deviceMd': {
    fontSize: '30vw',
  },
});
