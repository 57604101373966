import { Link, useLocation } from 'react-router-dom';

import { convertPixelToRem } from '@helpers/style-helpers';

import { useContactModalContext } from '@hooks/use-contact-modal-context';
import { useDispatch } from '@hooks/use-dispatch';
import { useFAQContext } from '@hooks/use-faq-context';
import { setCartModalState } from '@store/slices/cart-slice';

import { styled } from '@src/themes';

export function FAQContactModalButton() {
  const location = useLocation();
  const { handleContactModal } = useContactModalContext();
  const dispatch = useDispatch();
  const { faqCategories } = useFAQContext();

  if (
    !faqCategories.data ||
    (faqCategories.data && faqCategories.data.length === 0)
  ) {
    return null;
  }

  return (
    <FAQButton
      to={{
        pathname: '/faq',
        search: location.search,
      }}
      state={{ from: location.pathname }}
      onClick={() => {
        handleContactModal();
        dispatch(setCartModalState(false));
      }}
    >
      <i className="fa-regular fa-circle-question" />
      <div className="info__holder">
        <div className="info__title">FAQs</div>
        <div className="info__subtitle">Veja aqui as perguntas frequentes</div>
      </div>
    </FAQButton>
  );
}

const FAQButton = styled(Link, {
  display: 'flex',
  alignItems: 'center',

  padding: '1rem 1.25rem',
  background: '$white',
  boxShadow: '0px 0px 8px #00000029',
  borderRadius: '10px',
  cursor: 'pointer',

  i: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    marginRight: '1rem',
    minWidth: convertPixelToRem(50),

    fontSize: convertPixelToRem(24),
    color: '$white',

    backgroundColor: '$primaryPure',
    padding: '12px',
    borderRadius: '50%',
  },

  '.info__holder': {
    display: 'flex',
    flexDirection: 'column',

    color: '$fontColorPrimary',
  },

  '.info__title': {
    wordBreak: 'break-word',
    fontSize: convertPixelToRem(20),
    fontWeight: 'bold',
  },

  '.info__subtitle': {
    fontSize: convertPixelToRem(14),
    fontWeight: '500',
    lineHeight: '1.2',
  },

  '@deviceSm': {
    i: {
      marginRight: '0.75rem',
    },

    '.info__title': {
      fontSize: convertPixelToRem(16),
    },
  },
});
