import { ASSETS_ICONS_PATH } from '@config/assets-config';
import { env } from '@config/env';
import { convertPixelToRem } from '@helpers/style-helpers';

import { OfferItemType } from '@api/models/response/offer-response';

import { styled } from '@src/themes';

interface ProductSVADescriptionProps {
  productData: OfferItemType;
}

function ProductSVADescription({ productData }: ProductSVADescriptionProps) {
  return (
    <ProductSvaDescriptionContainer>
      <img
        src={`${env.URL_ASSETS_CHANNEL + ASSETS_ICONS_PATH}/icon-${
          productData.codigoProduto
        }.svg`}
        alt={`Logotipo ${productData.nome}`}
      />

      <p className="text__product-name">
        <strong>{productData.nome}</strong>
      </p>
      <p className="description">{productData.descricaoProduto}</p>
    </ProductSvaDescriptionContainer>
  );
}

export default ProductSVADescription;

const ProductSvaDescriptionContainer = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'auto auto',
  gridTemplateAreas: `'image title'
  'image description'`,
  columnGap: '1rem',
  color: '#6D839A',
  fontSize: convertPixelToRem(14),
  lineHeight: '1.2',
  fontWeight: '500',

  p: {
    color: '$fontColorPrimary',
  },

  img: {
    gridArea: 'image',
  },

  '.description': {
    gridArea: 'description',
    color: '#6D839A',
  },

  '.text__product-name': {
    gridArea: 'title',
    color: '$fontColorPrimary',
    fontSize: convertPixelToRem(16),
  },

  '@deviceSm': {
    display: 'grid',
    gap: '0.5rem',
    width: '100%',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateAreas: `'image title'
    'description description'`,
    fontSize: convertPixelToRem(12),
    alignItems: 'flex-start',

    '.text__product-name': {
      gridArea: 'title',
      fontSize: convertPixelToRem(14),
    },

    '.description': {
      gridArea: 'description',
      color: '#6D839A',
    },
  },
});
