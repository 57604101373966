import { getEmissor } from '@helpers/emissor-helpers';
import { convertPixelToRem, hexToRGB } from '@helpers/style-helpers';

import { keyframes, styled, themes } from '@src/themes';

export const InformationRowContainer = styled('tr', {
  borderTop: '1px solid $silverPure',

  '.information-row-container': {
    display: 'flex',
    justifyContent: 'space-between',
  },

  '.information-row-container__left-column': {
    width: '100%',
    padding: '1.5rem 0 1.5rem 1.5rem',
    marginRight: '1rem',

    '.left-column__content-container': {
      display: 'flex',
      alignItems: 'center',

      '.content-container__text-content': {
        width: '100%',

        fontWeight: '500',
        fontSize: convertPixelToRem(14),

        'p + p': {
          marginTop: '0.5rem',
        },

        '.text-content__information-title': {
          fontWeight: '700',

          '> i': {
            marginLeft: '0.5rem',

            fontSize: '1rem',
            transition: 'transform 0.4s ease',
          },

          '> div': {
            marginBottom: '0.5rem',
          },
        },

        '.text-content__information-title, .text-content__information-subtitle':
          {
            fontSize: convertPixelToRem(14),
          },
      },
    },

    '@deviceSm': {
      padding: '1rem 0 1rem 1.25rem',

      '.left-column__content-container': {
        '> i': {
          fontSize: '0.75rem',
        },

        '.content-container__text-content': {
          fontSize: '0.75rem',
          lineHeight: '1.5',

          '.text-content__information-title, .text-content__information-subtitle':
            {
              fontSize: '0.75rem',
            },

          '.text-content__information-subtitle': {
            marginTop: '0.25rem',
          },
        },
      },
    },
  },

  '.information-row-container__right-column': {
    position: 'relative',
    display: 'flex',
    padding: '1.5rem 1.5rem 1.5rem 0',

    '@deviceSm': {
      padding: '1.5rem 1.25rem 1.5rem 0',
    },

    '.right-column__subtotal-value-container': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',

      '> p': {
        fontWeight: '700',
        whiteSpace: 'nowrap',

        '&.content-container__discount-value': {
          marginRight: '0.75rem',

          textDecoration: 'line-through',
          fontSize: '0.75rem',
          fontWeight: '600',
        },

        '&.content-container__information-value': {
          fontSize: '1rem',
          textAlign: 'end',
        },

        '@deviceSm': {
          '&.content-container__information-value': {
            fontSize: '0.75rem',
          },
        },
      },
    },
  },

  '.information-row-description': {
    height: 'auto',
    maxHeight: '0',
    opacity: '0',
    overflow: 'hidden',
    transition: 'max-height 0.6s ease-in-out, opacity 0.4s ease-in-out',

    '.information-row-description__text-content': {
      paddingRight: '1.5rem',
      paddingLeft: '1.5rem',

      '@deviceMd': {
        padding: '0.5rem 1.5rem',
      },

      '@deviceSm': {
        padding: '0.5rem 1rem',
      },
    },

    '.information-row-description__button-section': {
      display: 'flex',
      justifyContent: 'center',
      padding: '2rem 1.5rem',
    },
  },

  '&[aria-disabled=true]': {
    opacity: '0.2',
    cursor: 'not-allowed',

    '@deviceSm': {
      '.information-row__right-column': {
        '&::before': {
          content: 'unset',
        },
      },
    },
  },
  variants: {
    informativeColor: {
      gray: {
        background: '$silverPure',

        '@deviceSm': {
          '.information-row-container__right-column': {
            '&::before': {
              background: '$silverLight',
            },
          },
        },

        '.information-row-container__left-column': {
          '.left-column__content-container': {
            '.content-container__text-content': {
              '.text-content__information-title': {
                color: '$fontColorPrimary',
              },

              '.text-content__information-subtitle': {
                color: '$fontColorPrimary',
              },
            },
          },
        },

        '.information-row-container__right-column': {
          '.right-column__subtotal-value-container': {
            '> p': {
              '&.content-container__information-value': {
                color: '$fontColorPrimary',
              },
            },
          },
        },
      },
      green: {
        backgroundColor: hexToRGB(
          themes[getEmissor().initials].colors.primaryPure,
          0.08,
        ),

        '@deviceSm': {
          '.information-row-container__right-column': {
            '&::before': {
              background: '$primaryLight',
            },
          },
        },

        '.information-row-container__left-column': {
          '.left-column__content-container': {
            '.content-container__text-content': {
              '.text-content__information-title': {
                color: '$primaryPure',
              },

              '.text-content__information-subtitle': {
                color: '$fontColorPrimary',
              },
            },
          },
        },

        '.information-row-container__right-column': {
          '.right-column__subtotal-value-container': {
            '> p': {
              '&.content-container__information-value': {
                color: '$primaryPure',
              },
            },
          },
        },
      },
    },
    collapse: {
      false: {
        backgroundColor: '$white',

        '.text-content__information-title': {
          '> i': {
            transform: 'rotate(-180deg)',
          },
        },
        '.information-row-description': {
          maxHeight: '1000px',
          opacity: 1,
        },
        '.information-row-container__right-column': {
          backgroundColor: '$white',
        },
      },
    },
    cursorPointer: {
      true: {
        '.information-row-container': {
          cursor: 'pointer',
        },
      },
    },
  },
  defaultVariants: {
    collapse: true,
    cursorPointer: false,
  },
});

const loadingAnimation = keyframes({
  from: {
    opacity: '0.15',
  },
  to: {
    opacity: '0.5',
  },
});

export const LoadingLine = styled('div', {
  position: 'relative',

  height: '1.25rem',
  width: '$$width',
  backgroundColor: '#ddd',
  borderRadius: '10px',
  animation: `${loadingAnimation} 0.8s infinite alternate`,

  '@deviceSm': {
    height: '0.75rem',
  },
});
