import { useFormContext } from 'react-hook-form';

import formatCurrencyToValue from '@vr/devkit/money/formatCurrencyToValue';
import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { Input } from '@components/input';
import { TrashButton } from '@components/input/styles';
import { WalletFormInputs } from '@compositions/wallet-product-form';
import { PortalAccessMessage } from '@elements/portal-access-message';

import { MAX_LENGTH_CURRENCY_VALUE } from '@config/input-config';
import { PRODUCTS } from '@config/products-config';
import { ProductType } from '@extra-types/product-type';
import { FormErrors } from '@helpers/enum/form-errors';
import { removeSpecialCaracters } from '@helpers/function-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import { removeProductFromCart } from '@store/slices/cart-slice';

import { styled } from '@src/themes';

import ProductWalletDescription from './product-wallet-description';

interface ProductWalletInputProps {
  productData: ProductType;
  enableRemoveProductButton?: boolean;
  idPrefix?: string;
  minCardValue?: number;
  maxCardValue?: number;
}

export function ProductWalletInput({
  productData,
  enableRemoveProductButton = true,
  idPrefix,
  minCardValue,
  maxCardValue,
}: ProductWalletInputProps) {
  const productInformation = PRODUCTS[productData.id];
  const inputName = `products.product-${productData.id}.value` as const;
  const prefixWithHifen = `${idPrefix}-`;
  const inputID = `${
    idPrefix ? prefixWithHifen : ''
  }product-${removeSpecialCaracters(productData.name)}-value` as const;
  const dispatch = useDispatch();
  const {
    register,
    resetField,
    formState: { errors },
  } = useFormContext<WalletFormInputs>();
  const { contractedProducts, unservedRegionProducts } = useSelector(
    ({ customer }) => customer,
  );
  const isCartLoading = useSelector(({ cart }) => cart.isCartLoading);
  const config = useSelector(({ application }) => application.config);
  const minCardValueValidation = minCardValue || config.minCardValue;
  const maxCardValueValidation = maxCardValue || config.maxCardValue;

  const isProductContracted = () => {
    return contractedProducts.some(
      contractedProduct => contractedProduct.id === productData.id,
    );
  };

  const isProductUnservedRegion = () => {
    return unservedRegionProducts.some(
      contractedProduct => contractedProduct === productData.id,
    );
  };

  const handleRemoveProduct = () => {
    resetField(inputName, {
      defaultValue: '',
    });
    dispatch(removeProductFromCart({ productId: productData.id }));
  };

  if (!productInformation) return null;

  if (isProductUnservedRegion()) {
    return (
      <ProductWalletInputContainer
        css={{
          '@deviceMd': {
            alignItems: 'flex-start',
            flexDirection: 'column',
          },
        }}
      >
        <ProductWalletDescription productData={productData} />
        <div className="product-wallet-description-container__unserved-region">
          <p>Este produto não está disponível em sua região</p>
        </div>
      </ProductWalletInputContainer>
    );
  }

  if (isProductContracted()) {
    return (
      <ProductWalletInputContainer
        css={{
          '@deviceMd': {
            alignItems: 'flex-start',
            flexDirection: 'column',
          },
        }}
      >
        <ProductWalletDescription productData={productData} />
        <div className="product-wallet-description-container__already-contracted">
          <PortalAccessMessage message="Sua empresa já possui esse saldo contratado!" />
        </div>
      </ProductWalletInputContainer>
    );
  }

  return (
    <ProductWalletInputContainer>
      <ProductWalletDescription productData={productData} />
      <div className="product-wallet-input-container__action">
        <Input.Default
          id={inputID}
          data-testid={inputID}
          maskMoney
          width="150px"
          placeholder="R$ 0,00"
          maxLength={MAX_LENGTH_CURRENCY_VALUE}
          errorMessage={
            errors?.products?.[`product-${productData.id}`]?.value?.message
          }
          inputMode="numeric"
          disabled={isCartLoading}
          {...register(inputName, {
            deps: 'amount',
            onChange: event => {
              if (event.target.value === 'R$ 0,00') {
                resetField(inputName, {
                  defaultValue: event.target.value.replace('R$ 0,00', ''),
                });
              }
            },
            setValueAs: value =>
              value && formatCurrencyToValue(value).toFixed(2),
            validate: {
              min: value =>
                value !== ''
                  ? Number(value) >= minCardValueValidation ||
                    `${FormErrors.MIN_CARD_VALUE} ${formatValueToCurrency(
                      minCardValueValidation,
                    )}`
                  : true,
              max: value =>
                value !== ''
                  ? Number(value) <= maxCardValueValidation ||
                    `${FormErrors.MAX_CARD_VALUE} ${formatValueToCurrency(
                      maxCardValueValidation,
                    )}`
                  : true,
            },
          })}
        />
        {enableRemoveProductButton && (
          <TrashButton
            id={`product-${removeSpecialCaracters(productData.name)}-clear`}
            data-testid={`product-${removeSpecialCaracters(
              productData.name,
            )}-clear`}
            type="button"
            onClick={handleRemoveProduct}
          >
            <i className="fa-light fa-trash" />
          </TrashButton>
        )}
      </div>
    </ProductWalletInputContainer>
  );
}

const ProductWalletInputContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  width: '100%',

  img: {
    maxHeight: convertPixelToRem(40),
  },

  '.product-wallet-input-container__information': {
    display: 'flex',
    alignItems: 'start',
    gap: '1rem',
    flex: 1,

    '.information__text': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.125rem',

      color: '$fontColorPrimary',
      fontSize: convertPixelToRem(14),
      lineHeight: '1.5',

      'p.text__product-name': {
        fontSize: convertPixelToRem(16),
      },
    },
  },

  '.product-wallet-input-container__action': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    marginLeft: 'auto',
  },

  '.product-wallet-description-container__already-contracted, .product-wallet-description-container__unserved-region':
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '0.5rem',
      width: '50%',
    },

  '.product-wallet-description-container__unserved-region': {
    p: {
      backgroundColor: '$silverLight',
      padding: '1rem',
      marginLeft: 'auto',
      borderRadius: '6px',

      fontSize: convertPixelToRem(12),
      fontWeight: '$medium',
      lineHeight: '1.5',
      textAlign: 'center',

      '@deviceMd': {
        width: '100%',
      },
    },
  },

  '@deviceMd': {
    '.product-wallet-description-container__already-contracted, .product-wallet-description-container__unserved-region':
      {
        justifyContent: 'center',

        width: '100%',
      },

    '.already-contracted__warning': {
      maxWidth: '100%',
    },
  },

  '@deviceSm': {
    flexDirection: 'column',
    gap: '0',

    '.product-wallet-input-container__information': {
      width: '100%',

      '.information__text': {
        'p.text__product-name': {
          fontSize: convertPixelToRem(14),
        },
      },
    },

    '.product-wallet-input-container__action': {
      width: '100%',

      div: {
        width: '100%',
        input: {
          width: '100%',
        },
      },
    },

    '.product-wallet-description-container__already-contracted, .product-wallet-description-container__unserved-region':
      {
        margin: '1.5rem 0',
      },
  },
});
