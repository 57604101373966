import React from 'react';

import { Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { StyledSwiper } from '@elements/styled-swiper';

import { styled } from '@src/themes';

export function HCMCarousel({ children }: { children: React.ReactNode }) {
  const verifyIsSingleItem = true;

  return (
    <HCMCarouselContainer>
      <CarouselSwiper
        modules={[Navigation]}
        preventInteractionOnTransition
        grabCursor
        navigation={verifyIsSingleItem}
        spaceBetween={0}
        loop={verifyIsSingleItem}
        allowTouchMove
        slidesPerView={2}
        centeredSlides
        breakpoints={{
          768: {
            spaceBetween: 20,
            slidesPerView: 2,
          },
          480: {
            spaceBetween: 60,
          },
          0: {
            slidesPerView: 1,
          },
        }}
      >
        {children && (
          <>
            {React.Children.map(children, child => (
              <SwiperSlide>{child}</SwiperSlide>
            ))}
          </>
        )}
      </CarouselSwiper>
    </HCMCarouselContainer>
  );
}

const HCMCarouselContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  maxHeight: '450px',

  background: 'transparent',

  img: {
    height: '100%',
    width: '100%',
  },
});

const CarouselSwiper = styled(StyledSwiper, {
  '.swiper-slide': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    textAlign: 'center',
    transition: 'all 0.4s ease-in-out',

    '@deviceMd': {
      transition: 'none',
    },
  },

  '.swiper-slide-next, .swiper-slide-prev': {
    opacity: 0.5,
    transition: 'all 0.4s ease-in-out',
    transform: 'scale(0.7) translateZ(0) !important',

    '@deviceSm': {
      opacity: 0,
      transform: 'none !important',
      transition: 'none',
    },
  },

  '.swiper-button-prev': {
    left: '20%',
  },

  '.swiper-button-next': {
    right: '20%',
  },

  '@deviceMd': {
    '.swiper-button-next, .swiper-button-prev': {
      display: 'flex',
    },
  },

  '@deviceSm': {
    '.swiper-button-next': {
      right: '0',
    },

    '.swiper-button-prev': {
      left: '0',
    },
  },
});
