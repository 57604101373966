import { Helmet as ReactHelmet } from 'react-helmet-async';

import { useSelector } from '@hooks/use-selector';

interface PageTitleProps {
  title?: string;
}

export function PageTitle({ title }: PageTitleProps) {
  const emissor = useSelector(({ application }) => application.config.emissor);

  const titleComplement = title ? ` - ${title}` : '';

  return (
    <ReactHelmet>
      <title data-testid="page-title">{`Loja ${emissor.name}${titleComplement}`}</title>
    </ReactHelmet>
  );
}
