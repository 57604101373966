import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

import { ComboSummaryGeneralFormButton } from './combo-summary-general-form-button';

export function ComboSummaryTotalValue() {
  const comboCartPricingData = useSelector(
    ({ cart }) => cart.comboCartPricingData,
  );

  return (
    <ComboSummaryTotalValueContainer>
      <div className="combo-summary-total-value-container__value-area">
        <span className="value-area__value-label">Valor total do pedido*</span>
        <span>
          {formatValueToCurrency(comboCartPricingData?.resumo.valorTotal ?? 0)}
          /mês
        </span>
      </div>
      <ComboSummaryGeneralFormButton hiddenOnDesktop />
      <div className="combo-summary-total-value-container__warning-area">
        <i className="fa-solid fa-circle-exclamation" />
        <span>
          *Os pagamentos serão realizados de forma apartada por serviço.
        </span>
      </div>
    </ComboSummaryTotalValueContainer>
  );
}

const ComboSummaryTotalValueContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '@deviceLg': {
    gap: '1rem',
  },

  '.combo-summary-total-value-container__value-area': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    padding: '1rem 0',

    span: {
      fontSize: '0.75rem',
      fontWeight: '$bold',
      color: '$primaryPure',

      '&.value-area__value-label': {
        color: '$fontColorPrimary',
        textTransform: 'uppercase',
      },
    },

    '@deviceLg': {
      padding: '0',
    },
  },

  '.combo-summary-total-value-container__warning-area': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',

    span: {
      fontSize: '0.75rem',
      lineHeight: 1,
      fontWeight: '$medium',
    },

    i: {
      color: '$secondaryLight',
      fontSize: '1rem',
    },
  },
});
