import { SliderSwiper } from '@components/slider-swiper';
import AwardItem from '@elements/award-item';
import { PageSection } from '@elements/page-section';
import { SectionHolder } from '@elements/section-holder';
import { Title } from '@elements/title';

import { SectionDefaultPropsType } from '@extra-types/section-props-type';
import { StringToReplaceList } from '@helpers/enum/string-to-replace-list';
import { replaceString } from '@helpers/string-helpers';

import { useInformationSection } from '@hooks/use-information-section';
import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

export function AwardsSection({ id, name }: SectionDefaultPropsType) {
  const config = useSelector(({ application }) => application.config);
  const { sectionInformation, checkSectionItemsEmpty, sectionVariant } =
    useInformationSection({
      name,
      sectionVariant: 'primary',
      observeMobileResponsive: true,
    });

  if (checkSectionItemsEmpty || !sectionInformation) {
    return null;
  }

  return (
    <PageSection id={id} variant={sectionVariant}>
      <AwardsHolder>
        <Title.Root fontColorVariant={sectionVariant} barColor="secondary">
          <Title.Default tag="h2">
            {replaceString(
              sectionInformation.titulo,
              StringToReplaceList.EMISSOR_NAME,
              config.emissor.name,
            )}
          </Title.Default>
          <Title.Subtitle>{sectionInformation.subTitulo}</Title.Subtitle>
        </Title.Root>
        <div className="awards__group">
          <SliderSwiper
            itensPerColumn={1}
            showSlider
            loop
            variant={sectionVariant}
            customArrowDistance="-60px"
            breakpoints={{
              1232: {
                slidesPerView: 7,
              },
              1024: {
                slidesPerView: 6,
              },
              850: {
                slidesPerView: 5,
              },
              768: {
                slidesPerView: 4.5,
              },
              400: {
                spaceBetween: 16,
                slidesPerView: 2.5,
              },
              0: {
                spaceBetween: 10,
                slidesPerView: 1.75,
              },
            }}
          >
            {sectionInformation.itens.map(itemSection => (
              <div key={itemSection.id} className="group__item">
                <AwardItem
                  title={itemSection.titulo}
                  href={itemSection.conteudo}
                  iconName={itemSection.nomeIcone}
                  variant={sectionVariant}
                />
              </div>
            ))}
          </SliderSwiper>
        </div>
      </AwardsHolder>
    </PageSection>
  );
}

const AwardsHolder = styled(SectionHolder, {
  '.awards__group': {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    position: 'relative',
  },

  '.group__item': {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
