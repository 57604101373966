export const VRPAT = {
  colors: {
    primaryLight: '#30B700',
    primaryPure: '#00AA13',
    primaryDark: '#008C15',

    secondaryLight: '#FFC600',
    secondaryPure: '#FFB600',
    secondaryDark: '#B47E00',

    tertiaryPure: '#4A5969',
    tertiaryLight: '#9CAEC1',
    tertiaryDark: '#2E3E4E',

    rateColor: '#688499',
    likeColor: '#00BE28',
    deslikeColor: '#D13B02',

    disabledPure: '#D5DCE1',
    disabledFontPure: '#465562',

    headerBackgroundColor: '$primaryDark',
    footerBackgroundColor: '$primaryDark',
    sectionBackgroundSecondaryColor: '$primaryDark',
    switchBackgroundInitial: '#A9B6C1',
    switchInnerBackgroundInitial: '#5B6F80',
    switchBackgroundEnd: '$inputSuccessIcon',
    switchInnerBackgroundEnd: '#FFFFFF',

    faqBackgroundColor: '#054C2D',
    walletFontColor: '#FFFFFF',

    // Buttons - Primary Tokens
    buttonPrimaryBackgroundColor: '$primaryPure',
    buttonPrimaryBorderColor: '$primaryPure',
    buttonPrimaryTextColor: '$white',
    buttonPrimaryBackgroundColorHover: '$primaryDark',
    buttonPrimaryBorderColorHover: '$primaryDark',
    buttonPrimaryTextColorHover: '$white',

    buttonPrimaryOutlineBackgroundColor: 'transparent',
    buttonPrimaryOutlineBorderColor: '$primaryPure',
    buttonPrimaryOutlineTextColor: '$primaryPure',
    buttonPrimaryOutlineBackgroundColorHover: '$primaryDark',
    buttonPrimaryOutlineBorderColorHover: '$primaryDark',
    buttonPrimaryOutlineTextColorHover: '$white',

    buttonPrimaryNegativeBackgroundColor: '$white',
    buttonPrimaryNegativeBorderColor: '$white',
    buttonPrimaryNegativeTextColor: '$primaryPure',
    buttonPrimaryNegativeBackgroundColorHover: '$primaryDark',
    buttonPrimaryNegativeBorderColorHover: '$primaryDark',
    buttonPrimaryNegativeTextColorHover: '$white',

    buttonPrimaryDisabledBackgroundColor: '$disabledPure',
    buttonPrimaryDisabledBorderColor: '$disabledPure',
    buttonPrimaryDisabledTextColor: '$disabledFontPure',
    buttonPrimaryDisabledBackgroundColorHover: '$disabledPure',
    buttonPrimaryDisabledBorderColorHover: '$disabledPure',
    buttonPrimaryDisabledTextColorHover: '$disabledFontPure',

    // Buttons - Secondary Tokens
    buttonSecondaryBackgroundColor: '$secondaryPure',
    buttonSecondaryBorderColor: '$secondaryPure',
    buttonSecondaryTextColor: '$primaryDark',
    buttonSecondaryBackgroundColorHover: '$secondaryDark',
    buttonSecondaryBorderColorHover: '$secondaryDark',
    buttonSecondaryTextColorHover: '$primaryDark',

    buttonSecondaryOutlineBackgroundColor: 'transparent',
    buttonSecondaryOutlineBorderColor: '$secondaryPure',
    buttonSecondaryOutlineTextColor: '$secondaryPure',
    buttonSecondaryOutlineBackgroundColorHover: '$secondaryDark',
    buttonSecondaryOutlineBorderColorHover: '$secondaryDark',
    buttonSecondaryOutlineTextColorHover: '$primaryDark',

    buttonSecondaryNegativeBackgroundColor: '$white',
    buttonSecondaryNegativeBorderColor: '$white',
    buttonSecondaryNegativeTextColor: '$secondaryPure',
    buttonSecondaryNegativeBackgroundColorHover: '$secondaryDark',
    buttonSecondaryNegativeBorderColorHover: '$secondaryDark',
    buttonSecondaryNegativeTextColorHover: '$white',

    buttonSecondaryDisabledBackgroundColor: '$disabledPure',
    buttonSecondaryDisabledBorderColor: '$disabledPure',
    buttonSecondaryDisabledTextColor: '$disabledFontPure',
    buttonSecondaryDisabledBackgroundColorHover: '$disabledPure',
    buttonSecondaryDisabledBorderColorHover: '$disabledPure',
    buttonSecondaryDisabledTextColorHover: '$disabledFontPure',
    featuredFlagBackground: '#034D2E',

    // Produtos
    alimentacaoPrimaryLight: '#FF99DB',
    alimentacaoPrimaryPure: '#E20093',
    alimentacaoPrimaryDark: '#800053',
    alimentacaoSecondaryLight: '#d5e864',
    alimentacaoSecondaryPure: '#C0DF16',
    alimentacaoSecondaryDark: '#94c90f',
    alimentacaoColorName: '$alimentacaoPrimaryPure',
    refeicaoPrimaryLight: '#FFC999',
    refeicaoPrimaryPure: '#FF6900',
    refeicaoPrimaryDark: '#B24700',
    refeicaoSecondaryLight: '#d5e864',
    refeicaoSecondaryPure: '#C0DF16',
    refeicaoSecondaryDark: '#94c90f',
    refeicaoColorName: '$refeicaoPrimaryPure',
    transportePrimaryLight: '#FF99A9',
    transportePrimaryPure: '#FF3654',
    transportePrimaryDark: '#9E003A',
    transporteSecondaryLight: '#99d6ea',
    transporteSecondaryPure: '#62b6e5',
    transporteSecondaryDark: '#0E3371',
    transporteColorName: '$transportePrimaryPure',
    valeTransportePrimaryLight: '#FF99A9',
    valeTransportePrimaryPure: '#FF3654',
    valeTransportePrimaryDark: '#9E003A',
    valeTransporteSecondaryLight: '#A9F8A0',
    valeTransporteSecondaryPure: '#02D72F',
    valeTransporteSecondaryDark: '#016517',
    valeTransporteColorName: '$transportePrimaryPure',
    autoPrimaryLight: '#C1CCD7',
    autoPrimaryPure: '#78868D',
    autoPrimaryDark: '#384047',
    autoSecondaryLight: '#d5e864',
    autoSecondaryPure: '#C0DF16',
    autoSecondaryDark: '#94c90f',
    autoColorName: '$autoPrimaryPure',
    culturaPrimaryLight: '#99F2FF',
    culturaPrimaryPure: '#2E70E5',
    culturaPrimaryDark: '#0E3371',
    culturaSecondaryLight: '#FFF999',
    culturaSecondaryPure: '#FFF110',
    culturaSecondaryDark: '#D6A100',
    culturaColorName: '$culturaPrimaryPure',
    homeOfficePrimaryLight: '#FFE899',
    homeOfficePrimaryPure: '#FFC600',
    homeOfficePrimaryDark: '#AD4E00',
    homeOfficeSecondaryLight: '#A9F8A0',
    homeOfficeSecondaryPure: '#02D72F',
    homeOfficeSecondaryDark: '#016517',
    homeOfficeColorName: '$homeOfficePrimaryPure',
    vrvaPrimaryLight: '#99FFEB',
    vrvaPrimaryPure: '#83C1C3',
    vrvaPrimaryDark: '#299B9B',
    vrvaSecondaryLight: '#d5e864',
    vrvaSecondaryPure: '#C0DF16',
    vrvaSecondaryDark: '#78aa01',
    vrvaColorName: '$vrvaPrimaryPure',
    cestaPrimaryLight: '#FF99EE',
    cestaPrimaryPure: '#CA00A8',
    cestaPrimaryDark: '#550066',
    cestaSecondaryLight: '#A6F89D',
    cestaSecondaryPure: '#02D72F',
    cestaSecondaryDark: '#016517',
    cestaColorName: '$cestaPrimaryPure',
    boasFestasPrimaryLight: '#F5D4A3',
    boasFestasPrimaryPure: '#CB9D3E',
    boasFestasPrimaryDark: '#A37E30',
    boasFestasSecondaryLight: '#d5e864',
    boasFestasSecondaryPure: '#C0DF16',
    boasFestasSecondaryDark: '#78aa01',
    boasFestasColorName: '$boasFestasPrimaryPure',
    comprasPrimaryLight: '#CB99FF',
    comprasPrimaryPure: '#AA15D4',
    comprasPrimaryDark: '#510F8A',
    comprasSecondaryLight: '#FFF999',
    comprasSecondaryPure: '#FFF110',
    comprasSecondaryDark: '#D6A100',
    comprasColorName: '$comprasPrimaryPure',
    premiacaoPrimaryLight: '#CB99FF',
    premiacaoPrimaryPure: '#AA15D4',
    premiacaoPrimaryDark: '#510F8A',
    premiacaoSecondaryLight: '#FFF999',
    premiacaoSecondaryPure: '#FFF110',
    premiacaoSecondaryDark: '#D6A100',
    premiacaoColorName: '$premiacaoPrimaryPure',
    mobilidadePrimaryLight: '#C1CCD7',
    mobilidadePrimaryPure: '#A0AAB4',
    mobilidadePrimaryDark: '#384047',
    mobilidadeSecondaryLight: '#A9F8A0',
    mobilidadeSecondaryPure: '#02D72F',
    mobilidadeSecondaryDark: '#016517',
    mobilidadeColorName: '$mobilidadePrimaryPure',
    multiPrimaryLight: '#C7FE9A',
    multiPrimaryPure: '#02D72F',
    multiPrimaryDark: '#1BAC02',
    multiSecondaryLight: '#EFFF99',
    multiSecondaryPure: '#DEFF33',
    multiSecondaryDark: '#90AA09',
    multiColorName: '$multiPrimaryDark',
    multibeneficiosPrimaryLight: '#D0D0C8',
    multibeneficiosPrimaryPure: '#96968F',
    multibeneficiosPrimaryDark: '#54544F',
    multibeneficiosSecondaryLight: '#FFF999',
    multibeneficiosSecondaryPure: '#FFF110',
    multibeneficiosSecondaryDark: '#D6A100',
    multiBackground: '#2D3233',
    multiFontColor: '#ffffff',
    multibeneficiosColorName: '$multibeneficiosPrimaryPure',
    marketplacePrimaryLight: '#FFE1CA',
    marketplacePrimaryPure: '#FF6EAA',
    marketplacePrimaryDark: '#B2006B',
    marketplaceSecondaryPure: '#FFF110',
    marketplaceSecondaryLight: '#FFF999',
    marketplaceSecondaryDark: '#D6A100',
    marketplaceColorName: '$marketplacePrimaryPure',
  },
  fonts: {
    default: 'Montserrat, sans-serif',
  },
};
