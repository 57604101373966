import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class CanNotApplyOfferError extends ApplicationError {
  constructor(message?: string) {
    super(
      SpecificsApiErrors.CAN_NOT_APPLY_OFFER_ERROR,
      message || ApiErrorsMessages.CAN_NOT_APPLY_OFFER_ERROR,
    );
  }
}
