import { HTMLAttributes, useMemo } from 'react';

import clearNotNumber from '@vr/devkit/utils/clearNotNumber';
import emailValidate from '@vr/devkit/validators/emailValidate';

import { OnlyNumberValidate } from '@helpers/string-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useContact } from '@hooks/use-contact';

import { styled } from '@src/themes';

function ContactAction({
  action,
  children,
}: {
  children: React.ReactNode;
  action: string | (() => void) | undefined;
}) {
  if (typeof action === 'string') {
    return (
      <ContactActionContainer enablePointer enableHover>
        <ContactActionContent as="a" href={action}>
          {children}
        </ContactActionContent>
      </ContactActionContainer>
    );
  }

  if (typeof action === 'function') {
    return (
      <ContactActionContainer enablePointer enableHover>
        <ContactActionContent as="button" onClick={action}>
          {children}
        </ContactActionContent>
      </ContactActionContainer>
    );
  }

  return (
    <ContactActionContainer as="li">
      <ContactActionContent>{children}</ContactActionContent>
    </ContactActionContainer>
  );
}

interface ContactItemProps extends HTMLAttributes<HTMLDivElement> {
  icon: string;
  title: string;
  subtitle: string;
}

export function FAQContactItem({
  icon,
  title,
  subtitle,
  ...props
}: ContactItemProps) {
  const { toggleChatModal } = useContact();

  const action = useMemo(() => {
    const contact = title.toLowerCase();
    if (emailValidate(contact)) {
      return `mailto:${contact}`;
    }

    if (OnlyNumberValidate(clearNotNumber(contact))) {
      return `tel:+55${contact}`;
    }

    if (contact === 'chat') {
      return toggleChatModal;
    }

    return undefined;
  }, [title, toggleChatModal]);

  return (
    <ContactAction action={action} {...props}>
      <div className="contact-item-container__icon">
        <i className={icon} />
      </div>
      <div className="contact-item-container__text-content">
        <p className="text-content__title">{title}</p>
        <p>{subtitle}</p>
      </div>
    </ContactAction>
  );
}

const ContactActionContainer = styled('li', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.5rem',
  flex: 1,

  backgroundColor: '$white',
  border: '1px solid $white',
  borderRadius: '10px',
  boxShadow: '0px 0px 8px #00000029',

  color: '$fontColorPrimary',
  textDecoration: 'none',

  variants: {
    enablePointer: {
      true: {
        cursor: 'pointer',
      },
    },
    enableHover: {
      true: {
        transition: 'border-color 0.4s, transform 0.4s',

        '&:hover': {
          borderColor: '$primaryPure',
          transform: 'translateY(-10px)',
        },
      },
    },
  },
});

const ContactActionContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1.5rem',

  backgroundColor: 'transparent',
  width: '100%',
  padding: '1.25rem 1.5rem',

  color: '$fontColorPrimary',
  textDecoration: 'none',

  '@deviceLg': {
    gap: '1rem',
  },

  '.contact-item-container__icon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,

    background: '$primaryPure',
    height: '3rem',
    width: '3rem',
    borderRadius: '$pill',

    color: '$white',
    fontSize: '1.5rem',
  },

  '.contact-item-container__text-content': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',

    p: {
      fontSize: convertPixelToRem(14),
      fontWeight: '$medium',
      textAlign: 'start',
    },

    '.text-content__title': {
      fontSize: convertPixelToRem(18),
      fontWeight: '$bold',
      wordBreak: 'break-word',
    },
  },
});
