import { getServiceRenegotiationMessage } from '@helpers/renegotiation-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export function ComboServiceFormRenegotiationMessage({
  serviceId,
}: {
  serviceId: string;
}) {
  const renegotiationMessage = getServiceRenegotiationMessage(serviceId);

  if (!renegotiationMessage) {
    return null;
  }

  return (
    <ComboServiceFormRenegotiationMessageContainer>
      <i className="fas fa-exclamation-circle" />
      <span>{renegotiationMessage}</span>
    </ComboServiceFormRenegotiationMessageContainer>
  );
}

const ComboServiceFormRenegotiationMessageContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  backgroundColor: '$informationLight',
  padding: '1rem',

  i: {
    color: '$informationDark',
    fontSize: '1.5rem',
  },

  '> span': {
    color: '$tertiaryPure',
    fontSize: convertPixelToRem(14),
    fontWeight: '$medium',
    lineHeight: 1.7,
  },

  '@deviceSm': {
    '> span': {
      fontSize: '0.75rem',
    },
  },
});
