import React, { useEffect, useState } from 'react';

import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import { Button } from '@elements/button';
import { ProductNameText } from '@elements/product-name-text';

import { getProductData } from '@helpers/product-helpers';
import {
  completeWithLeftZero,
  getSingularOrPlural,
} from '@helpers/string-helpers';

import { useSelector } from '@hooks/use-selector';

import { LoadingLine, ProductRowContainer } from './styles';

type ProductRowProps = {
  productData: {
    id: number;
    subtotalValue: number;
    amount?: number;
  };
  showImage?: boolean;
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
};

type DescriptionPropsData = {
  role?: React.AriaRole;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  tabIndex?: number;
};

export function ProductRow({
  productData,
  children,
  loading,
  disabled,
}: ProductRowProps) {
  const productList = useSelector(({ products }) => products.productList);
  const { cartModality } = useSelector(({ cart }) => cart);
  const walletView = cartModality ? cartModality.isWalletGroupCard : false;
  const productApplicationData = getProductData(productData.id, productList);

  const [isCollapsed, setCollapse] = useState(true);
  const [descriptionProps, setDescriptionProps] = useState(
    {} as DescriptionPropsData,
  );

  const { amount, subtotalValue } = productData;

  const handleDescriptionCollapse = () => {
    setCollapse(oldState => !oldState);
  };

  useEffect(() => {
    if (children) {
      setDescriptionProps({
        role: 'button',
        onClick: handleDescriptionCollapse,
        onKeyDown: e => {
          if (e.key === 'Enter') {
            handleDescriptionCollapse();
          }
        },
        tabIndex: 0,
      } as DescriptionPropsData);
    } else {
      setDescriptionProps({});
    }
  }, [children]);

  if (loading) {
    return (
      <ProductRowContainer>
        <td colSpan={2}>
          <div className="product-row-container">
            <div className="product-row-container__left-column">
              <div className="left-column__content-container">
                <div className="content-container__text-content">
                  <div className="text-content__product-name-container">
                    <LoadingLine css={{ $$width: '50%' }} />
                  </div>
                  <div>
                    <LoadingLine css={{ $$width: '30%' }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="product-row-container__right-column">
              <div className="right-column__content-container">
                <div className="content-container__product-value">
                  <LoadingLine css={{ $$width: '100%' }} />
                </div>
              </div>
            </div>
          </div>
        </td>
      </ProductRowContainer>
    );
  }

  if (productApplicationData === undefined) {
    return null;
  }

  return (
    <ProductRowContainer
      cursorPointer={!!children}
      aria-disabled={disabled}
      collapse={isCollapsed}
    >
      <td colSpan={2}>
        <div className="product-row-container">
          <div className="product-row-container__left-column">
            <div
              className="left-column__content-container"
              {...descriptionProps}
            >
              <div className="content-container__text-content">
                <div className="text-content__product-name-container">
                  <p className="product-name-container__name">
                    <ProductNameText id={productData.id} />
                  </p>
                  {!!children && <i className="fas fa-caret-down" />}
                </div>
                {(!!children && (
                  <p className="text-content__information">
                    {isCollapsed
                      ? 'Clique para ver os colaboradores'
                      : 'Clique para fechar o detalhe'}
                  </p>
                )) ||
                  (amount && !walletView && (
                    <p className="text-content__information">
                      {completeWithLeftZero(amount)}{' '}
                      {getSingularOrPlural(amount, 'cartão', 'cartões')}
                    </p>
                  ))}
              </div>
            </div>
          </div>
          <div className="product-row-container__right-column">
            {amount && !walletView && children && (
              <div className="right-column__extra-information-container">
                <p>Qtd. cartões</p>
                <p className="amount-value-container__product-amount">
                  <strong>{completeWithLeftZero(amount)}</strong>
                </p>
              </div>
            )}
            <div className="right-column__subtotal-value-container">
              <p className="subtotal-value-container__label">Subtotal</p>
              <p className="subtotal-value-container__product-value">
                <strong>{formatValueToCurrency(subtotalValue)}</strong>
              </p>
            </div>
          </div>
        </div>
        {!!children && (
          <div className="product-row-description">
            <div className="product-row-description__text-content">
              {children}
            </div>
            <div className="product-row-description__button-section">
              <Button.Default
                size="small"
                variant="primary"
                onClick={handleDescriptionCollapse}
              >
                Fechar
              </Button.Default>
            </div>
          </div>
        )}
      </td>
    </ProductRowContainer>
  );
}
