import { MODALITIES } from '@config/modality-config';
import { PRODUCT_COMMERCIAL_CONDITIONS } from '@config/products-config';
import { BonusType } from '@extra-types/bonus-type';
import { ComboCartType } from '@extra-types/combo-cart-type';
import { PricingType } from '@extra-types/pricing-types';
import { PlanTypeEnum, ProductCartType } from '@extra-types/product-cart-type';
import { WalletProductFormType } from '@extra-types/product-form-types';
import { ProductPricing } from '@extra-types/product-pricing';
import { ProductType } from '@extra-types/product-type';

import { ModalitiesId } from './enum/modalities-id';
import { ProductCommercialConditionsId } from './enum/product-commercial-conditions';
import { ServiceGroupId } from './enum/service-group-id';
import { getProductData, removePrefixProduct } from './product-helpers';

export function getProductFromCart({
  productId,
  productCartList,
}: {
  productId: number;
  productCartList: ProductCartType[];
}) {
  return productCartList.find(cartProduct => cartProduct.id === productId);
}

export function getProductFromComboCart({
  modalityId,
  productId,
  productComboCartList,
}: {
  modalityId: number;
  productId: number;
  productComboCartList: ComboCartType;
}) {
  const productComboList = productComboCartList[modalityId];

  if (!productComboList) {
    return undefined;
  }

  return productComboList.find(cartProduct => cartProduct.id === productId);
}

export function calcAmountProductsFromCart({
  cartmodalityId,
  productCartList,
}: {
  cartmodalityId: number | undefined;
  productCartList: ProductCartType[];
}) {
  if (
    cartmodalityId === undefined ||
    MODALITIES[cartmodalityId] === undefined
  ) {
    return 0;
  }

  if (MODALITIES[cartmodalityId].isWalletGroupCard) {
    return 1;
  }

  return productCartList.length;
}

export function calcTotalValueCartByModality({
  cartmodalityId,
  productCartList,
}: {
  cartmodalityId: number | undefined;
  productCartList: ProductCartType[];
}) {
  let cartTotalValue = 0;

  if (
    cartmodalityId === undefined ||
    MODALITIES[cartmodalityId] === undefined
  ) {
    return cartTotalValue;
  }

  if (cartmodalityId === ModalitiesId.HCM) {
    const hcmProduct = productCartList.find(product => product.type === 'HCM');
    if (hcmProduct) {
      return hcmProduct.value;
    }
  }

  if (MODALITIES[cartmodalityId].isWalletGroupCard) {
    const amount = Math.max(
      ...productCartList.map(productCart => productCart.amount),
    );
    cartTotalValue =
      productCartList.reduce(
        (totalValue, product) => totalValue + product.value,
        cartTotalValue,
      ) * amount;
  } else {
    cartTotalValue = productCartList.reduce(
      (totalValue, product) => totalValue + product.value * product.amount,
      cartTotalValue,
    );
  }

  return cartTotalValue;
}

export function formatWalletProductListToProductCartList(
  amount: number,
  walletProductList: WalletProductFormType,
  productList: ProductType[],
): ProductCartType[] {
  const productCartList: ProductCartType[] = [];

  Object.entries(walletProductList).forEach(([key, { value }]) => {
    const productId = removePrefixProduct(key);
    const productData = getProductData(productId, productList);

    if (productData && value) {
      productCartList.push({
        id: productId,
        type: productData.type,
        amount,
        value: Number(value),
        planType: PlanTypeEnum.MENSAL,
      });
    }
  });

  return productCartList;
}

export function convertProductCartListToProductPricingList(
  productCartList: ProductCartType[],
  extensionsId: number[],
): ProductPricing[] {
  const productCartWithoutSVA = productCartList.filter(
    productCart => productCart.type !== ServiceGroupId.SVA,
  );

  return productCartWithoutSVA.map(productCart => {
    return {
      codigoProduto: productCart.id,
      quantidade: productCart.amount,
      valorTotalBeneficios: Number(
        (productCart.value * productCart.amount).toFixed(2),
      ),
      tipoProduto: productCart.type,
      tipoPlano:
        productCart.planType.toUpperCase() || PlanTypeEnum.MENSAL.toUpperCase(),
      produtosAdicionais: extensionsId,
    };
  });
}

export function createPricingBonusList(pricingData: PricingType): BonusType[] {
  const collectedBonusList: BonusType[] = [];

  pricingData.detalhe.produtos.forEach(product => {
    product.lancamentos.forEach(entry => {
      if (
        entry.identificacaoCondicaoComercial !==
        ProductCommercialConditionsId.DISCOUNT
      ) {
        const isBonusOffer =
          PRODUCT_COMMERCIAL_CONDITIONS[entry.identificacaoCondicaoComercial]
            .type === 'offer';

        const bonusIndex = collectedBonusList.findIndex(
          b => b.id === entry.identificacaoCondicaoComercial,
        );

        if (isBonusOffer && bonusIndex < 0) {
          collectedBonusList.push({
            id: entry.identificacaoCondicaoComercial,
            entryBonusRules: [
              {
                idList: [product.codigoProduto],
                unitaryValue: entry.valorUnitario,
              },
            ],
          });
        } else if (isBonusOffer && bonusIndex >= 0) {
          const bonusData = { ...collectedBonusList[bonusIndex] };

          const bonusRuleIndex = bonusData.entryBonusRules.findIndex(
            bonusRule => bonusRule.unitaryValue === entry.valorUnitario,
          );

          const entryBonusRules = [...bonusData.entryBonusRules];

          if (bonusRuleIndex < 0) {
            entryBonusRules.push({
              idList: [product.codigoProduto],
              unitaryValue: entry.valorUnitario,
            });
          } else {
            entryBonusRules[bonusRuleIndex] = {
              idList: [
                product.codigoProduto,
                ...entryBonusRules[bonusRuleIndex].idList,
              ],
              unitaryValue: entryBonusRules[bonusRuleIndex].unitaryValue,
            };
          }

          collectedBonusList[bonusIndex] = {
            ...bonusData,
            entryBonusRules,
          };
        }
      }
    });
  });

  return collectedBonusList;
}

export function compareProductCartLists(
  firstProductCartList: ProductCartType[],
  secondProductCartList: ProductCartType[],
): boolean {
  return firstProductCartList.every(firstProductCart => {
    const matchingProduct = secondProductCartList.find(
      secondProductCart => firstProductCart.id === secondProductCart.id,
    );

    return (
      matchingProduct &&
      matchingProduct.amount === firstProductCart.amount &&
      matchingProduct.value === firstProductCart.value
    );
  });
}
