import { HCMBenefitsBox } from '@elements/hcm-benefits-box';
import { PageSection } from '@elements/page-section';
import { Title } from '@elements/title';

import { styled } from '@src/themes';

export function HCMBenefitsSection() {
  return (
    <PageSection
      id="hcm-benefits"
      css={{
        padding: '4rem 0',
      }}
    >
      <Title.Default
        tag="h2"
        css={{
          color: '$black',
          paddingBottom: '2rem',
          justifyContent: 'center',
          fontSize: '1.5rem',
          margin: 0,
        }}
      >
        Benefícios de centralizar sua gestão com a VR Gente
      </Title.Default>
      <HCMBenefitsContainer>
        <HCMBenefitsBox
          icon="fa-light fa-heart"
          title="Gestão completa"
          description="Todas as soluções essenciais para automatizar a rotina de trabalho."
        />
        <HCMBenefitsBox
          icon="fa-light fa-trophy"
          title="Líder de mercado"
          description="O maior especialista em controle de ponto e gestão de jornada do Brasil."
        />
        <HCMBenefitsBox
          icon="fa-light fa-desktop"
          title="Dados estratégicos"
          description="Informações em tempo real para analisar, acompanhar e comprovar resultados."
        />
        <HCMBenefitsBox
          icon="fa-light fa-lock"
          title="Segurança jurídica"
          description="Plataforma 100% adequada à CLT e LGPD para você fazer a gestão com confiança."
        />
      </HCMBenefitsContainer>
    </PageSection>
  );
}

const HCMBenefitsContainer = styled('div', {
  display: 'flex',
  backgroundColor: '$white',
  gap: '1rem',
  overflow: 'auto',

  '@deviceMd': {
    padding: '1rem 0',
  },
});
