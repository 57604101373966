import { HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from '@config/styles-config';

import { globalCss } from '@src/themes';

export const globalStyles = globalCss({
  '*': {
    'scroll-margin-top': `${HEADER_HEIGHT}px`,

    '@deviceSm': {
      'scroll-margin-top': `${HEADER_MOBILE_HEIGHT}px`,
    },
  },

  'html, body, #vr-root, #vr-root > div': {
    height: '100%',
  },

  body: {
    color: '$fontColorPrimary',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',

    '#bot-widget-container-faq': {
      visibility: 'hidden',
      display: 'none',
    },

    '#vr-root': {
      '> div': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },

  // Wootric
  '#wootric-modal': {
    '*': {
      fontFamily: 'Montserrat !important',
    },

    ul: {
      margin: '0 1rem !important',
    },

    '.wootric-visible': {
      width: '32px',
      height: '32px',
      display: 'inline-block!important',
      textAlign: 'center',
      padding: '0!important',
      lineHeight: '28px',
    },

    '#close-icon': {
      stroke: '$primaryPure ',
    },

    '#wootric-thanks': {
      p: {
        color: '$primaryPure !important',
        textShadow: 'none !important',
        fontWeight: '500 !important',
      },
    },

    '.wootric-feedback-wrapper': {
      'input, textarea': {
        background: '#fff !important',
        fontSize: '1rem !important',
        color: '#000 !important',
      },
    },

    '.wootric-beacon-parent': {
      background: '$secondaryPure !important',
      border: '0 !important',
      '#wootric-question': {
        p: {
          color: '$primaryPure !important',
          textShadow: 'none !important',
          fontWeight: '500 !important',
        },
      },
    },

    '#wootric-submit': {
      background: '$primaryPure !important',
      fontWeight: '600 !important',
      color: '#fff !important',
    },

    label: {
      color: '$primaryPure !important',
      textShadow: 'none !important',
      fontWeight: '600 !important',
    },

    '.wootric-powered-by': {
      color: '$primaryPure !important',
      textShadow: 'none !important',
    },

    '.wootric-powered-by a.wootric-link, #wootric-modal .wootric-feedback-disclaimer a.wootric-disclaimer-link':
      {
        color: '$primaryPure !important',
        fontWeight: '600 !important',
      },

    '#wootric-close': {
      color: '$primaryPure !important',
      fontWeight: '600 !important',
      border: '0 !important',
      background: '$white !important',
      margin: '0 10px !important',

      boxShadow: '0 0 4px 2px rgb(50 50 50 / 20%)',

      '&:focus, &:hover': {
        width: '120px !important',
        background: '$white !important',
      },

      '#wootric-dismiss': {
        marginRight: '8px',
      },

      '#wootric-x': {
        margin: '0 10px !important',
      },
    },

    '&.wootric-survey-type-ces .wootric-beacon-parent': {
      minWidth: '100% !important',
    },
  },

  '#code7-boteria-bot-widget-chat-message': {
    borderRadius: '10px 10px 10px 0',
  },

  '#code7-boteria-bot-widget-chat-message-user': {
    borderRadius: '10px 10px 0 10px',
  },

  '#code7-boteria-bot-widget-chat-faq': {
    right: '1.875rem !important',
    bottom: '2rem !important',
    minWidth: '400px !important',
    minHeight: '75dvh !important',
  },

  '#code7-boteria-bot-widget-chat-header': {
    minHeight: '72px !important',
    backgroundColor: '$primaryPure !important',
  },

  '@media only screen and (max-width: 1024px)': {
    '#code7-boteria-bot-widget-chat-faq': {
      minHeight: '500px !important',
    },
  },

  '@media only screen and (max-width: 750px)': {
    '#code7-boteria-bot-widget-chat-faq': {
      right: '0 !important',
      bottom: '0 !important',
      top: 'unset !important',
      left: 'unset !important',
      width: '400px !important',
    },
  },

  '@media only screen and (max-width: 450px), only screen and (max-height: 500px)':
    {
      '#code7-boteria-bot-widget-chat-faq': {
        height: '100% !important',
        width: '100% !important',
        minWidth: 'unset !important',
        minHeight: 'unset !important',
        top: '0 !important',
        right: '0 !important',
        left: '0 !important',
        bottom: '0 !important',
      },

      '.code7-boteria-bot-widget-chat-wrap': {
        height: 'calc(100% - 90px) !important',
      },

      '#code7-boteria-bot-widget-chat-header, #code7-boteria-bot-widget-footer':
        {
          borderRadius: '0 !important',
        },
    },

  '@media only screen and (orientation: landscape)': {
    'div#code7-boteria-bot-widget-chat-faq': {
      minHeight: 'unset !important',
      bottom: '0 !important',
    },

    '.code7-boteria-bot-widget-chat-wrap': {
      height: 'calc(100% - 90px) !important',
    },
  },

  '@media only screen and (orientation: portrait)': {
    '#wootric-modal.wootric-mobile-modal-zoomed.wootric-bottom div.wootric-beacon-parent':
      {
        border: '0 !important',
      },
  },

  '@media screen and (-webkit-min-device-pixel-ratio:0)': {
    'select, textarea, input': {
      fontSize: '1rem !important',
    },
  },
});
