import { useLocation } from 'react-router-dom';

import { ProductImage } from '@elements/product-image';
import { ProductNameText } from '@elements/product-name-text';

import { productHasLightBackground } from '@config/product-page-config';
import { removeSpecialCaracters } from '@helpers/function-helpers';
import { getProductStaticData } from '@helpers/product-helpers';

import { IndividualProductHeaderContainer, KnownMoreButton } from './styles';

type IndividualProductHeaderProps = {
  productId: number;
  productName: string;
  knowMoreButton: boolean;
};

function IndividualProductHeader({
  productId,
  productName,
  knowMoreButton,
}: IndividualProductHeaderProps) {
  const location = useLocation();
  const productStaticData = getProductStaticData(productId);

  return (
    <IndividualProductHeaderContainer
      hasLightBackground={productHasLightBackground(productId)}
    >
      <ProductImage productId={productId} />
      <div className="individual-product-header-container__title-container">
        <h2>
          <ProductNameText id={productId} showAsterisk />
        </h2>
        {knowMoreButton && (
          <KnownMoreButton
            id={`btn-saiba-mais-${removeSpecialCaracters(productName)}`}
            to={{
              pathname: `${productStaticData.pagePath}`,
              search: location.search,
            }}
            state={{ from: location.pathname }}
          >
            Saiba mais
          </KnownMoreButton>
        )}
      </div>
    </IndividualProductHeaderContainer>
  );
}

export default IndividualProductHeader;
