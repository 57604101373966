import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { isComboFlux } from '@helpers/flux-helpers';
import {
  clearPathname,
  contractFlux,
  handleProductPageFlux,
  handleUserFlux,
} from '@helpers/route-helpers';

import { useSelector } from '@hooks/use-selector';

import { OfferFacade } from '@src/facade/offer-facade';

export interface RoutesContextData {
  nextStep: () => void;
  prevStep: () => void;
  handleNavigation: () => number;
}

export const RoutesContext = createContext<RoutesContextData>(
  {} as RoutesContextData,
);

interface RoutesContextProviderProps {
  children: React.ReactNode;
}

export function RoutesContextProvider({
  children,
  ...props
}: RoutesContextProviderProps) {
  const { selectedOffer } = OfferFacade();
  const [fluxStack, setFluxStack] = useState<string[]>([]);
  const { pathname, search, state } = useLocation();
  const navigate = useNavigate();
  const { cartModality } = useSelector(({ cart }) => cart);
  const initialPage = useSelector(({ application }) => application.initialPage);

  const stackRouteFlux = useCallback(() => {
    if (state) {
      setFluxStack(stack => {
        return [...stack, state.from];
      });
    }
  }, [state]);

  const handleNavigation = useCallback((): number => {
    handleUserFlux(pathname);

    if (pathname.includes('/produtos/')) {
      handleProductPageFlux(pathname);
      return contractFlux.productPage?.findIndex(
        paths => paths === clearPathname(pathname),
      );
    }

    if (isComboFlux(selectedOffer)) {
      return contractFlux.combo?.findIndex(
        paths => paths === clearPathname(pathname),
      );
    }

    return contractFlux[cartModality?.id ?? 0]?.findIndex(
      paths => paths === clearPathname(pathname),
    );
  }, [pathname, selectedOffer, cartModality?.id]);

  const nextStep = useCallback(() => {
    if (pathname.includes('/produtos/')) {
      navigate({
        pathname: contractFlux.productPage[handleNavigation() + 1],
        search,
      });
    } else if (isComboFlux(selectedOffer)) {
      navigate({
        pathname: contractFlux.combo[handleNavigation() + 1],
        search,
      });
    } else {
      navigate(
        {
          pathname: contractFlux[cartModality?.id ?? 0][handleNavigation() + 1],
          search,
        },
        {
          state: { from: pathname },
        },
      );
    }
  }, [
    pathname,
    selectedOffer,
    navigate,
    handleNavigation,
    search,
    cartModality?.id,
  ]);

  const prevStep = useCallback(() => {
    navigate({
      pathname: fluxStack.pop() ?? initialPage,
      search,
    });
  }, [fluxStack, initialPage, search, navigate]);

  useEffect(() => {
    handleNavigation();
    stackRouteFlux();
  }, [cartModality, handleNavigation, initialPage, stackRouteFlux]);

  const memorizeReturn = useMemo(
    () => ({
      nextStep,
      prevStep,
      handleNavigation,
    }),
    [nextStep, prevStep, handleNavigation],
  );

  return (
    <RoutesContext.Provider value={memorizeReturn} {...props}>
      {children}
    </RoutesContext.Provider>
  );
}
