export enum ProductsId {
  ALIMENTACAO = 27,
  AUTO = 28,
  REFEICAO = 31,
  BOASFESTAS = 202,
  MULTI = 207,
  VRVA = 209,
  CAIXA_REFEICAO = 270,
  CAIXA_ALIMENTACAO = 290,
  VALE_TRANSPORTE = 99998,
  TRANSPORTE = 99999,
  MULTI_REFEICAO_AUXILIO = 245,
  MULTI_REFEICAO_PAT = 244,
  MULTI_ALIMENTACAO_AUXILIO = 211,
  MULTI_ALIMENTACAO_PAT = 212,
  MULTI_VRVA = 213,
  MULTI_HOME_OFFICE = 58,
  MULTI_MOBILIDADE = 262,
  MULTI_PREMIACAO = 59,
  MULTI_BOAS_FESTAS = 217,
  MULTI_CAIXA_REFEICAO_AUXILIO = 273,
  MULTI_CAIXA_REFEICAO_PAT = 272,
  MULTI_CAIXA_ALIMENTACAO_AUXILIO = 292,
  MULTI_CAIXA_ALIMENTACAO_PAT = 293,
  MULTI_CAIXA_CA_CR = 274,
  AUXILIO_ALIMENTACAO = 204,
  AUXILIO_REFEICAO = 243,
  MULTI_JVCEF = 200,
  DESCONTO_FARMACIA = 601,
  SEGURO_ALIMENTACAO = 602,
  APOIO_TRABALHADOR = 604,
  TOTALPASS = 605,
  NUTRICAO = 680,
  HCM_CORPORATIVO = 20154,
  HCM_PROFISSIONAL = 20153,
  HCM_GERENCIAL = 20152,
  HCM_ESSENCIAL = 20151,
}
