import { getModalityData } from '@helpers/modality-helpers';
import { getProductData } from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

export function ProductNameText({
  id,
  showAsterisk,
}: {
  id: number;
  showAsterisk?: boolean;
}) {
  const { productList, modalityList } = useSelector(({ products }) => products);
  const productData = getProductData(id, productList);
  const modalityData = getModalityData(productData?.modalityId, modalityList);

  if (!productData) {
    return null;
  }

  if (modalityData.isWalletGroupCard) {
    return (
      <span>
        {productData.name}
        {productData.showAsteriskPatAux && showAsterisk ? '*' : ''}
      </span>
    );
  }

  return (
    <ProductNameTextElement
      css={{
        $$productColor: `$colors$${productData.colorPrefixToken}ColorName`,
      }}
    >
      {productData.name}
      {productData.showAsteriskPatAux && showAsterisk ? '*' : ''}
    </ProductNameTextElement>
  );
}

const ProductNameTextElement = styled('span', {
  color: '$$productColor',
});
