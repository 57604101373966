import { createContext, useMemo, useState } from 'react';

import ContactModal from '@compositions/contact-modal';

export interface ContactModalContextData {
  contactModalState: boolean;
  handleContactModal: () => void;
}

export const ContactModalContext = createContext<ContactModalContextData>(
  {} as ContactModalContextData,
);

interface ContactModalContextProviderProps {
  children: React.ReactNode;
}

export function ContactModalContextProvider({
  children,
  ...props
}: ContactModalContextProviderProps) {
  const [contactModalState, setContactModalState] = useState(false);

  const handleContactModal = () => {
    setContactModalState(state => !state);
  };

  const memorizeReturn = useMemo(
    () => ({
      contactModalState,
      handleContactModal,
    }),
    [contactModalState],
  );

  return (
    <ContactModalContext.Provider value={memorizeReturn} {...props}>
      {children}
      <ContactModal />
    </ContactModalContext.Provider>
  );
}
