import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';

import {
  FREE_LABEL,
  STATIC_PRODUCT_TAX,
  VARIABLE_TAX_LABEL,
  VARIABLE_TAX_VALUE,
} from '@config/pricing-config';
import {
  PricingDetailProductType,
  PricingResumeType,
  PricingType,
} from '@extra-types/pricing-types';

import { ServiceGroupId } from './enum/service-group-id';
import { TaxEnum } from './enum/tax-enum';
import { formatValueToPercentage } from './string-helpers';

function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

function calcValueByTaxType(
  taxValue: number,
  taxType: TaxEnum,
  baseValue: number,
) {
  switch (taxType) {
    case TaxEnum.FIXED_VALUE: {
      return taxValue;
    }
    case TaxEnum.PERCENTAGE: {
      return baseValue * taxValue;
    }
    default: {
      return 0;
    }
  }
}

function getTaxes(productList: PricingDetailProductType[]) {
  return productList.reduce(
    (totalValue, product) =>
      totalValue +
      product.lancamentos.reduce(
        (totalEntryValue, entry) =>
          totalEntryValue +
          (entry.valorUnitario === VARIABLE_TAX_VALUE ? 0 : entry.valorTotal),
        0,
      ),
    0,
  );
}

function getServiceResumeData(
  serviceId: string,
  productList: PricingDetailProductType[],
) {
  const productListByService = productList.filter(
    product => product.tipoProduto === serviceId,
  );
  const hasProductWithStaticTax = productListByService.find(
    product => !!STATIC_PRODUCT_TAX[product.codigoProduto],
  );

  if (productListByService.length === 0 || !hasProductWithStaticTax) {
    return undefined;
  }

  const entryList = productListByService.flatMap(
    product => product.lancamentos,
  );

  const entryIdList = [
    ...new Set(entryList.map(entry => entry.identificacaoCondicaoComercial)),
  ];

  const newEntryList = entryIdList.map(entryId => {
    const entries = entryList.filter(
      entry => entry.identificacaoCondicaoComercial === entryId,
    );

    return {
      descricao: entries[0].descricao,
      identificacaoCondicaoComercial: entryId,
      nome: entries[0].nome,
      tipo: entries[0].descricao,
      tipoValorTaxa: entries[0].tipoValorTaxa,
      valorTotal: entries.reduce(
        (totalValue, entry) => totalValue + entry.valorTotal,
        0,
      ),
      valorUnitario: entries[0].valorUnitario,
    };
  });

  const newServiceTotalValue = productListByService.reduce(
    (totalValue, product) => totalValue + product.valorTotal,
    0,
  );

  const newServiceBenefityTotalValue = productListByService.reduce(
    (totalValue, product) => totalValue + product.valorProduto,
    0,
  );
  const newServiceTaxTotalValue = getTaxes(productListByService);

  return {
    [serviceId]: {
      valorTotal: newServiceTotalValue,
      valorTotalBeneficios: newServiceBenefityTotalValue,
      valorTotalTaxas: newServiceTaxTotalValue,
      lancamentos: newEntryList,
    },
  };
}

export function addFixedTaxOnPricingResponse(data: PricingType): PricingType {
  const hasProductWithStaticTax = data.detalhe.produtos.find(
    product => !!STATIC_PRODUCT_TAX[product.codigoProduto],
  );

  if (!hasProductWithStaticTax) {
    return data;
  }

  const newPricingProductDetailResponse = data.detalhe.produtos.map(product => {
    const fixedProductTaxList = STATIC_PRODUCT_TAX[product.codigoProduto];

    if (!fixedProductTaxList) {
      return product;
    }

    const newTotalValue = fixedProductTaxList.reduce((totalValue, tax) => {
      const taxTotalValue = calcValueByTaxType(
        tax.value,
        tax.type,
        product.valorProduto,
      );
      return totalValue + taxTotalValue;
    }, product.valorTotal);

    const newEntryList = fixedProductTaxList.map(fixedTax => ({
      nome: fixedTax.name,
      descricao: fixedTax.description,
      identificacaoCondicaoComercial: fixedTax.commercialConditionID,
      tipo: 1,
      tipoValorTaxa: fixedTax.type,
      valorTotal:
        fixedTax.type !== TaxEnum.VARIABLE
          ? calcValueByTaxType(
              fixedTax.value,
              fixedTax.type,
              product.valorProduto,
            )
          : VARIABLE_TAX_VALUE,
      valorUnitario:
        fixedTax.type !== TaxEnum.VARIABLE
          ? fixedTax.value
          : VARIABLE_TAX_VALUE,
    }));

    return {
      ...product,
      valorTotal: newTotalValue,
      lancamentos: [...product.lancamentos, ...newEntryList],
    };
  });

  const resumeTotalBenefityValue = newPricingProductDetailResponse.reduce(
    (totalValue, product) => totalValue + product.valorProduto,
    0,
  );
  const resumeTotalValue = newPricingProductDetailResponse.reduce(
    (totalValue, product) => totalValue + product.valorTotal,
    0,
  );
  const resumeTotalTaxValue = getTaxes(newPricingProductDetailResponse);

  const servicesList = [
    ...new Set(data.detalhe.produtos.map(product => product.tipoProduto)),
  ];

  const newResumeServiceEntryList = servicesList.reduce(
    (totalValue, serviceId) => {
      const serviceResumeData = getServiceResumeData(
        serviceId,
        newPricingProductDetailResponse,
      );
      if (!notEmpty(serviceResumeData)) {
        return totalValue;
      }
      return {
        ...totalValue,
        ...serviceResumeData,
      };
    },
    {},
  );

  return {
    ...data,
    detalhe: {
      produtos: newPricingProductDetailResponse,
    },
    resumo: {
      ...data.resumo,
      servicos: {
        ...data.resumo.servicos,
        ...newResumeServiceEntryList,
      },
      valorTotalBeneficios: resumeTotalBenefityValue,
      valorTotal: resumeTotalValue,
      valorTotalTaxas: resumeTotalTaxValue,
    },
  };
}

export function getEntryValueLabel(value: number) {
  if (value === VARIABLE_TAX_VALUE) {
    return VARIABLE_TAX_LABEL;
  }

  if (value === 0) {
    return FREE_LABEL;
  }

  return formatValueToCurrency(value);
}

export function getEntryBaseValueLabel(value: number, taxType: number) {
  if (taxType === TaxEnum.FIXED_VALUE) {
    return formatValueToCurrency(value);
  }

  if (taxType === TaxEnum.VARIABLE) {
    return VARIABLE_TAX_LABEL;
  }

  if (taxType === TaxEnum.PERCENTAGE) {
    return formatValueToPercentage(value);
  }

  // TaxEnum.DEFAULT
  return `${value}`;
}

export const getPricingResumeData = (
  productPricedDataList: PricingType | undefined,
  serviceGroupId: ServiceGroupId,
): PricingResumeType | undefined => {
  if (productPricedDataList) {
    const resumeData = productPricedDataList.resumo.servicos[serviceGroupId];

    const productData = productPricedDataList.detalhe.produtos
      .filter(p => p.tipoProduto === serviceGroupId)
      .flatMap(res => res.lancamentos);

    return {
      ...resumeData,
      lancamentos: [...resumeData.lancamentos, ...productData],
    };
  }
  return undefined;
};
