/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance } from 'axios';
import Cookies from 'js-cookie';

import ErrorUtils from '@helpers/error-helpers';

import { ResponseTypeData } from '@api/models/request/request-data';
import InfraError from '@errors/types/infra-error';

import {
  servicesControlAccessToken,
  sessionKeys,
} from './authentication/service-control-access-token';

export default abstract class HttpService {
  public static async doGetOnThirdPartyService<T>(
    url: string,
    headers?: Record<string, string>,
    responseType?: ResponseTypeData,
  ): Promise<T> {
    return axios
      .get(url, {
        headers,
        responseType: responseType || 'text',
      })
      .then(res => {
        return res.data;
      })
      .catch((error: AxiosError) => {
        this.intercept(error);
      });
  }

  public static intercept(error: AxiosError, instance?: AxiosInstance): void {
    if (
      instance &&
      error.response &&
      [401, 403].includes(error.response.status)
    ) {
      (async () => {
        Cookies.remove(sessionKeys.X_AUTHORIZATION);
        await servicesControlAccessToken.refreshAccessToken();
      })();
      return;
    }

    if (!error.response) {
      throw new InfraError();
    }

    throw ErrorUtils.throwError(
      (error.response.data?.codigoDeRetorno &&
        error.response.data.codigoDeRetorno.codigo) ||
        error.response.data?.code ||
        error.response.status,
      error.response.data?.codigoDeRetorno?.descricao,
    );
  }
}
