import { useSelector } from './use-selector';

function useDefaultHeader() {
  const { config } = useSelector(({ application }) => application);

  const defaultHeader = {
    siglaEmissor: config.emissor.initials,
    siglaCanal: config.siglaCanal,
    siglaSubCanal: config.siglaSubCanal,
  };

  return {
    defaultHeader,
  };
}

export default useDefaultHeader;
