import { ModalityType } from '@extra-types/modality-type';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { OrderSteps } from '@helpers/enum/order-steps';
import { ServiceGroupId } from '@helpers/enum/service-group-id';
import { StringToReplaceList } from '@helpers/enum/string-to-replace-list';

export const MODALITY_ID_NOT_FOUND = -1;
export const MODALITY_NEW_TEXT = 'Novidade!';
export const MODALITY_PREFIX = 'modalidade-';
export const MODALITY_ERROR_MESSAGE = 'Modalidade não cadastrada.';
export const MODALITY_IMAGE_CARDS_MESSAGE_ERROR =
  'É necessário enviar uma lista de produtos caso o grupo não seja uma carteira.';

export const MODALITIES: {
  [key: number]: ModalityType;
} = {
  [ModalitiesId.DEFAULT]: {
    id: ModalitiesId.DEFAULT,
    pageRoute: '/modalidade/avulso',
    keyId: `${MODALITY_PREFIX}avulso`,
    shelfInformation: {
      title: `Soluções ${StringToReplaceList.EMISSOR_NAME}`,
      description:
        'Benefícios em cartões separados, com saldo único: Refeição, Alimentação e muito mais.',
      illustration: '/shelf-product-avulso.png',
    },
    colorPrefixToken: 'secondary',
    salesTitle: 'Cartões Avulsos',
    title: 'Avulsos',
    subtitle: 'Benefícios únicos em cartões separados para seus colaboradores',
    description: 'Saldos únicos em cartões separados.',
    salesText: 'Você escolhe os benefícios que deseja oferecer em cada cartão.',
    isWalletGroupCard: false,
    isFeatured: false,
    showLegalFlag: false,
    alternativeText: 'Cartões avulsos',
    displayOrder: 1,
    stepOrderLimit: OrderSteps.ORDER,
    singleModalityProductsShelfView: true,
    serviceGroupId: ServiceGroupId.BENEFIT,
    canBeOrderedSolo: true,
  },
  [ModalitiesId.MULTI]: {
    id: ModalitiesId.MULTI,
    pageRoute: '/modalidade/multisaldo',
    keyId: `${MODALITY_PREFIX}multi`,
    shelfInformation: {
      title: 'Multi',
      description:
        'Ter multipossibilidades é que é benefício. Saldos separados, para um ou mais usos, e com segurança jurídica total.',
      illustration: '/shelf-product-multi.png',
    },
    colorPrefixToken: 'multi',
    salesTitle: 'Multi - Cartão Único',
    title: 'Multi',
    subtitle:
      'Tudo em um só lugar! Com o Multi, sua empresa consegue oferecer diversos benefícios em um único cartão.',
    description:
      'Multi benefícios com saldos individuais em um só cartão para seus trabalhadores',
    salesText: 'Você escolhe os produtos que deseja oferecer no Multi.',
    isWalletGroupCard: true,
    isFeatured: false,
    showLegalFlag: true,
    alternativeText: 'Cartão Multi',
    displayOrder: 0,
    stepOrderLimit: OrderSteps.ORDER,
    singleModalityProductsShelfView: false,
    serviceGroupId: ServiceGroupId.BENEFIT,
    canBeOrderedSolo: true,
  },
  [ModalitiesId.MULTI_JVCEF]: {
    id: ModalitiesId.MULTI_JVCEF,
    pageRoute: '/modalidade/multisaldo',
    keyId: `${MODALITY_PREFIX}multi`,
    shelfInformation: {
      title: 'Multi',
      description:
        'Ter multipossibilidades é que é benefício. Saldos separados, para um ou mais usos, e com segurança jurídica total.',
      illustration: '/shelf-product-multi.png',
    },
    colorPrefixToken: 'multi',
    salesTitle: 'Multi - Cartão Único',
    title: 'Multi',
    subtitle:
      'Tudo em um só lugar! Com o Multi, sua empresa consegue oferecer diversos benefícios em um único cartão.',
    description:
      'Multi benefícios com saldos individuais em um só cartão para seus trabalhadores',
    salesText: 'Você escolhe os produtos que deseja oferecer no Multi.',
    isWalletGroupCard: true,
    isFeatured: false,
    showLegalFlag: false,
    alternativeText: 'Cartão Multi',
    displayOrder: 0,
    stepOrderLimit: OrderSteps.ORDER,
    singleModalityProductsShelfView: false,
    serviceGroupId: ServiceGroupId.BENEFIT,
    canBeOrderedSolo: true,
  },
  [ModalitiesId.MOBILIDADE]: {
    id: ModalitiesId.MOBILIDADE,
    pageRoute: '/mobilidade/vale-transporte',
    keyId: `${MODALITY_PREFIX}mobilidade`,
    shelfInformation: {
      title: 'Vale-Transporte',
      description:
        'Gestão inteligente é usar tecnologia para gerar economia. A plataforma que descomplica o gerenciamento do VT.',
      illustration: '/shelf-product-mobilidade.png',
    },
    colorPrefixToken: 'mobilidade',
    salesTitle: 'Mobilidade - Serviços de Transporte',
    title: 'Mobilidade',
    subtitle: 'Pensou em mobilidade, pensou em VR Mobilidade!',
    description: 'A plataforma que descomplica o gerenciamento do VT',
    salesText: 'Você escolhe os produtos que deseja oferecer no Multi.',
    isWalletGroupCard: false,
    isFeatured: true,
    showLegalFlag: true,
    alternativeText: 'Vale-Transporte',
    displayOrder: 2,
    stepOrderLimit: OrderSteps.CONTRACT,
    singleModalityProductsShelfView: false,
    serviceGroupId: ServiceGroupId.MOBILITY,
    canBeOrderedSolo: true,
  },
  [ModalitiesId.HCM]: {
    id: ModalitiesId.HCM,
    pageRoute: '/modalidade/pontomais',
    keyId: `${MODALITY_PREFIX}hcm`,
    shelfInformation: {
      title: 'Pontomais',
      description:
        'Controle de ponto online. Chega de perder tempo. Economize e automatize a sua gestão de jornada.',
      illustration: '/shelf-product-pontomais.png',
    },
    colorPrefixToken: 'tertiary',
    salesTitle: 'Pontomais',
    title: 'Pontomais',
    subtitle:
      'Controle de ponto online. Chega de perder tempo. Economize e automatize a sua gestão de jornada.',
    description:
      'Controle de ponto online. Chega de perder tempo. Economize e automatize a sua gestão de jornada.',
    salesText:
      'Controle de ponto online. Chega de perder tempo. Economize e automatize a sua gestão de jornada.',
    isWalletGroupCard: false,
    isFeatured: true,
    showLegalFlag: true,
    alternativeText:
      'Sua rotina de trabalho mais produtiva, do controle de ponto à distribuição de holerites',
    displayOrder: 3,
    stepOrderLimit: OrderSteps.CONTRACT,
    singleModalityProductsShelfView: false,
    serviceGroupId: ServiceGroupId.HCM,
    canBeOrderedSolo: false,
  },
};
