import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class PublicAgencyError extends ApplicationError {
  constructor(message?: string) {
    super(
      SpecificsApiErrors.PUBLIC_AGENCY_ERROR,
      message || ApiErrorsMessages.PUBLIC_AGENCY_ERROR,
    );
  }
}
