import { Navigate, useLocation } from 'react-router-dom';

import Modal, { ModalVariants } from '@components/modal';
import { PageTitle } from '@components/page-title';
import { ProductAdvantages } from '@compositions/product-page-compositions/product-advantages';
import { ProductBenefits } from '@compositions/product-page-compositions/product-benefits';
import { FaqPreviewSection } from '@compositions/sections/faq-preview-section';
import { BalanceManagementPlatformSection } from '@compositions/transportation-voucher-page-sections/balance-management-platform-section';
import { BalanceManagementSavingSection } from '@compositions/transportation-voucher-page-sections/balance-management-saving-section';
import { BenefitComparinsonTableSection } from '@compositions/transportation-voucher-page-sections/benefit-comparasion-table-section';
import { CommentsSection } from '@compositions/transportation-voucher-page-sections/comments-section';
import { EmployeesCompanyBenefitsSection } from '@compositions/transportation-voucher-page-sections/employees-company-benefits-section';
import { ProductHeroSection } from '@compositions/transportation-voucher-page-sections/product-hero-section';
import { SmallCallToActionSection } from '@compositions/transportation-voucher-page-sections/small-call-to-action-section';
import { SmartRoutingSection } from '@compositions/transportation-voucher-page-sections/smart-routing-section';
import { PageContainer } from '@elements/page-container';

import { ProductsId } from '@helpers/enum/products-id';
import { getProductData } from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';
import { VideoModalContextProvider } from '@store/context/video-modal-context';

export function TransportationVoucherPage() {
  const location = useLocation();
  const { initialPage } = useSelector(({ application }) => application);

  const productId = ProductsId.VALE_TRANSPORTE;
  const { productList, isLoading } = useSelector(({ products }) => products);
  const productApplicationData = getProductData(productId, productList);

  if (isLoading) {
    return (
      <PageContainer>
        <ProductBenefits.Loading />
        <ProductAdvantages.Loading />
      </PageContainer>
    );
  }

  if (productApplicationData === undefined) {
    return (
      <Navigate
        to={{
          pathname: initialPage,
          search: location.search,
        }}
      />
    );
  }

  return (
    <VideoModalContextProvider>
      <PageTitle title={productApplicationData.name} />
      <PageContainer>
        <ProductHeroSection productData={productApplicationData} />
        <BalanceManagementPlatformSection
          productData={productApplicationData}
        />
        <SmallCallToActionSection />
        <EmployeesCompanyBenefitsSection />
        <BalanceManagementSavingSection />
        <SmartRoutingSection />
        <BenefitComparinsonTableSection />
        <CommentsSection />
        <FaqPreviewSection
          id="secao-vt-faq"
          name="LOJA_VT_SECTION_FAQ_PREVIEW"
          title="Perguntas frequentes"
          subtitle="Listamos algumas perguntas e respostas sobre a Loja VR para tirar suas dúvidas. Se não encontrar aqui, fale com a gente!"
          tagFAQ="vt-icon"
        />
      </PageContainer>
      <Modal>
        <ModalVariants.Confirmation />
      </Modal>
    </VideoModalContextProvider>
  );
}
