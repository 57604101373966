import { env } from '@config/env';

import { ApiRequestAuthData } from '@api/models/request/auth-data';
import AddressResponse from '@api/models/response/address-response';

import getBaseURLApi from './authentication/get-base-url-api';

class AddressService {
  private readonly baseUrl;

  constructor() {
    this.baseUrl = 'enderecos';
  }

  public async getAddress(
    { siglaEmissor, siglaCanal, siglaSubCanal }: ApiRequestAuthData,
    cep: string,
  ): Promise<AddressResponse> {
    const url = `/${this.baseUrl}/${cep}`;

    const { data } = await getBaseURLApi(url, {
      baseURL: env.URL_API,
      headers: {
        'sigla-emissor': siglaEmissor,
        'sigla-canal': siglaCanal,
        'id-subCanal': siglaSubCanal ?? '',
      },
    });

    return data;
  }
}

export default AddressService;
