import { CSS } from '@stitches/react';

import {
  defaultTokenVariantList,
  productsTokenVariantsList,
} from '@config/styles-config';
import {
  DefaultTokenVariantsType,
  ProductsTokenVariantsType,
} from '@extra-types/token-variants-type';
import {
  convertPixelToRem,
  getStyleTokenByProduct,
  productsVariantsEmptyObjectList,
} from '@helpers/style-helpers';

import { config, styled } from '@src/themes';

type BarColorCompoundVariant = {
  barColor: ProductsTokenVariantsType;
  barColorVariant: DefaultTokenVariantsType;
  css: CSS<typeof config>;
};

function createProductCompoundVariant() {
  const productCompoundVariants: BarColorCompoundVariant[] = [];

  productsTokenVariantsList.forEach(productTokenVariant => {
    defaultTokenVariantList.forEach(defaultVariant => {
      productCompoundVariants.push({
        barColor: productTokenVariant,
        barColorVariant: defaultVariant,
        css: {
          'h1, h2, h3': {
            span: {
              background: getStyleTokenByProduct(
                productTokenVariant,
                defaultVariant,
                'pure',
              ),
            },
          },
          '&::before': {
            background: getStyleTokenByProduct(
              productTokenVariant,
              defaultVariant,
              'pure',
            ),
          },
        },
      });
    });
  });

  return productCompoundVariants;
}

export const TitleRootContainer = styled('hgroup', {
  position: 'relative',
  display: 'inline-flex',
  flexDirection: 'column',
  gap: convertPixelToRem(8),

  '&::before': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,

    background: '$primaryPure',
  },
  variants: {
    multi: {
      true: {},
    },
    hasLightBackground: {
      true: {},
    },
    color: {
      primary: {
        color: '$fontColorPrimary',
      },
      secondary: {
        color: '$fontColorSecondary',
      },
      walletFontColor: {
        color: '$walletFontColor',
      },
    },
    size: {
      md: {
        paddingLeft: '2.625rem',

        '@deviceSm': {
          paddingLeft: '2rem',
        },

        '&:before': {
          width: '1.25rem',
          height: '100%',
          minHeight: convertPixelToRem(40),
          maxHeight: convertPixelToRem(100),

          '@deviceSm': {
            width: '1rem',
          },
        },
      },
      sm: {
        paddingLeft: '2rem',

        '@deviceSm': {
          paddingLeft: '2rem',
        },

        '&:before': {
          width: '1rem',
          height: '100%',
        },
      },
    },
    barColor: {
      primary: {
        'h1, h2': {
          span: {
            background: getStyleTokenByProduct('primary', 'primary', 'pure'),
          },
        },
        '&:before': {
          background: getStyleTokenByProduct('primary', 'primary', 'pure'),
        },
      },

      secondary: {
        'h1, h2': {
          span: {
            background: getStyleTokenByProduct(
              'secondary',
              'secondary',
              'pure',
            ),
          },
        },
        '&:before': {
          background: getStyleTokenByProduct('secondary', 'secondary', 'pure'),
        },
      },
      ...productsVariantsEmptyObjectList,
    },
    barColorVariant: {
      primary: {},
      secondary: {},
    },
  },
  compoundVariants: [
    ...createProductCompoundVariant(),
    {
      color: 'primary',
      multi: true,
      css: {
        color: '$multiFontColor',
      },
    },
    {
      color: 'secondary',
      hasLightBackground: true,
      css: {
        color: '$fontColorPrimary',
      },
    },
  ],
  defaultVariants: {
    size: 'md',
    color: 'primary',
    barColor: 'primary',
    barColorVariant: 'primary',
  },
});

export const TitleDefaultContainer = styled('h1', {
  display: 'flex',
  alignItems: 'center',

  marginRight: convertPixelToRem(20),

  variants: {
    size: {
      lg: {
        fontSize: convertPixelToRem(52),
        fontWeight: '$bold',
        lineHeight: '1.4',

        '@deviceMd': {
          fontSize: convertPixelToRem(44),
          lineHeight: '1.2',
        },

        '@deviceSm': {
          fontSize: convertPixelToRem(40),
        },
      },
      md: {
        fontSize: convertPixelToRem(26),
        fontWeight: '$bold',
        lineHeight: '1.4',

        '@deviceSm': {
          fontSize: convertPixelToRem(20),
          lineHeight: '1.2',
          fontWeight: '$bold',
        },
      },
      sm: {
        fontSize: '1rem',
        fontWeight: '$semibold',
        lineHeight: '1.25',
      },
    },
  },

  defaultVariants: {
    size: 'md',
  },
});

export const TitleSubtitleContainer = styled('p', {
  marginRight: convertPixelToRem(20),

  fontSize: '1rem',
  lineHeight: '1.5',
  fontWeight: '$medium',

  '@deviceSm': {
    fontSize: convertPixelToRem(14),
  },
});

export const TitleFlagContainer = styled('span', {
  marginLeft: '1rem',
  padding: '0.25rem 0.5rem',
  borderRadius: convertPixelToRem(5),

  color: '$fontColorPrimary',
  fontSize: convertPixelToRem(14),
  lineHeight: '1.3',

  '@deviceSm': {
    fontSize: convertPixelToRem(12),
  },
});
