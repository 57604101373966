import { useContactModalContext } from '@hooks/use-contact-modal-context';

import { styled } from '@src/themes';

const EmptyCartLabel = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',

  height: '100%',
  padding: '1.5rem 0',

  i: {
    fontSize: '2rem',
  },

  p: {
    textAlign: 'center',
    lineHeight: '1.5',

    span: {
      color: '$primaryPure',
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
});

interface CartModalEmptyContentProps {
  error?: boolean;
}

export function CartModalEmptyContent({ error }: CartModalEmptyContentProps) {
  const { handleContactModal } = useContactModalContext();

  if (error) {
    return (
      <EmptyCartLabel>
        <i className="fa-solid fa-cart-circle-xmark" />
        <p>
          Não foi encontrada uma modalidade compatível com o carrinho, entre em
          contato com o&nbsp;
          <span
            role="button"
            onClick={() => handleContactModal()}
            tabIndex={0}
            onKeyDown={event => event.key === 'Enter' && handleContactModal()}
          >
            nosso suporte
          </span>
          .
        </p>
      </EmptyCartLabel>
    );
  }

  return (
    <EmptyCartLabel>
      <i className="fa-regular fa-cart-circle-exclamation" />
      <p>
        O seu carrinho está vazio, adicione ao menos um produto para prosseguir
        com a compra.
      </p>
    </EmptyCartLabel>
  );
}
