import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useToastMessageContext } from '@hooks/use-toast-message-context';

import { styled } from '@src/themes';

export type ToastMessageVariantType = 'success' | 'error' | 'info' | 'cart';

const ANIMATION_TIME = 200;

const iconsVariants: { [key in ToastMessageVariantType]: JSX.Element } = {
  success: <i className="fa-regular fa-circle-check" />,
  error: <i className="fa-regular fa-circle-xmark" />,
  info: <i className="fa-regular fa-circle-info" />,
  cart: <i className="fa-regular fa-cart-shopping" />,
};

export function ToastMessage() {
  const {
    toastContent,
    toastMessageState,
    toastBottomPosition,
    toastZIndexValue,
  } = useToastMessageContext();
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={toastMessageState}
      timeout={ANIMATION_TIME}
      classNames="toast-message"
      unmountOnExit
    >
      <ToastMessageContainer
        ref={nodeRef}
        variant={toastContent?.variant}
        css={{
          $$bottomPosition: `${toastBottomPosition}px`,
          $$zIndex: toastZIndexValue,
        }}
      >
        {toastContent && iconsVariants[toastContent.variant]}
        <span>{toastContent?.message}</span>
      </ToastMessageContainer>
    </CSSTransition>
  );
}

const ToastMessageContainer = styled('div', {
  zIndex: '$$zIndex',
  position: 'fixed',
  bottom: '$$bottomPosition',
  left: '50%',
  transform: 'translateX(calc(-50% - 1rem))',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',

  width: 'max-content',
  maxWidth: 'calc(100% - 2rem)',
  padding: '1.25rem',
  margin: '0 1rem 0',
  borderRadius: '0.5rem',
  boxShadow: '0px 4px 8px #1B212626',

  span: {
    color: '$fontColorDark',
    fontSize: '0.75rem',
    fontWeight: '$regular',
    lineHeight: 1.2,
  },

  p: {
    'strong.green': {
      color: '$primaryPure',
    },
  },

  i: {
    fontSize: '1.5rem',
    lineHeight: 1,
  },

  '&.toast-message-enter': {
    opacity: 0,
    transform: 'translateY(10px) translateX(calc(-50% - 1rem))',
  },

  '&.toast-message-enter-active': {
    opacity: 1,
    transition: `opacity ${ANIMATION_TIME}ms ease, transform ${ANIMATION_TIME}ms`,
    transform: 'translateY(0) translateX(calc(-50% - 1rem))',
  },

  '&.toast-message-exit': {
    opacity: 1,
    transform: 'translateY(0) translateX(calc(-50% - 1rem))',
  },

  '&.toast-message-exit-active': {
    opacity: 0,
    transition: `opacity ${ANIMATION_TIME}ms ease, transform ${ANIMATION_TIME}ms`,
    transform: 'translateY(10px) translateX(calc(-50% - 1rem))',
  },

  '@deviceSm': {
    padding: '1rem',
  },

  variants: {
    variant: {
      success: {
        backgroundColor: '#D0F6C5',
        i: {
          color: '#00C600',
        },
      },
      error: {
        backgroundColor: '#FFCBCD',

        i: {
          color: '#F93A1A',
        },
      },
      info: {
        backgroundColor: '#E5F1F7',

        i: {
          color: '#347392',
        },
      },
      cart: {
        backgroundColor: '#E5F1F7',

        i: {
          color: '#347392',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'info',
  },
});
