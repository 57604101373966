import { shadowBoxStyle } from '@elements/shadow-box-style';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const OrderPreviewPageHolder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '.order-preview__delivery-section': {
    marginTop: '1rem',
  },

  '.order-preview-page-holder__action-area': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '3rem',

    width: '100%',
    margin: '3rem 0',

    '@deviceSm': {
      ...shadowBoxStyle,
      gap: '2rem',
    },
  },

  '.action-area__contract-checkarea': {
    display: 'flex',

    '@deviceSm': {
      '> label': {
        flexDirection: 'column',
        alignItems: 'center',

        textAlign: 'center',

        p: {
          marginLeft: 0,
          marginTop: convertPixelToRem(14),
        },
      },
    },
  },

  '.action-area__button-area': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.25rem',

    width: '100%',

    'button, a': {
      width: convertPixelToRem(240),
    },

    '@deviceSm': {
      flexDirection: 'column-reverse',

      'button, a': {
        width: '100%',
      },
    },
  },
});

export const TableScrollContainer = styled('div', {
  width: '100%',

  '::-webkit-scrollbar': {
    height: '8px',
  },

  '::-webkit-scrollbar-track': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '0 0 5px 5px',
  },

  '::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    backgroundColor: '$secondaryPure',
  },

  '@deviceXs': {
    overflowY: 'hidden',
    overflowX: 'auto',

    '.title-table-container': {
      width: '100%',
    },
  },

  '@deviceMd': {
    width: '100%',
  },
});
