import { Skeleton } from '@components/skeletons';
import IndividualProductForm from '@compositions/individual-product-form';
import { Button } from '@elements/button';
import { Title } from '@elements/title';

import {
  PRODUCTS_INDIVIDUAL_PAGE_INFORMATION,
  productHasLightBackground,
} from '@config/product-page-config';
import { ProductType } from '@extra-types/product-type';
import { removeSpecialCaracters } from '@helpers/function-helpers';
import { getStyleTokenByProduct } from '@helpers/style-helpers';

import { useRoutes } from '@hooks/use-routes';

import {
  ProductBenefitItem,
  ProductBenefitsContainer,
  ProductBenefitsHolder,
  ProductBenefitsLoadingHolder,
} from './styles';

function ProductBenefitsLoading() {
  return (
    <ProductBenefitsLoadingHolder>
      <div className="product-information-loading-holder__left-side">
        <Skeleton.Title />
        <div className="left-side__phrase-group">
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
          <Skeleton.Line />
        </div>
        <Button.Default loading size="small">
          Loading
        </Button.Default>
      </div>
      <div className="product-information-loading-holder__right-side">
        <Skeleton.ProductForm />
      </div>
    </ProductBenefitsLoadingHolder>
  );
}

function ProductBenefitsDefault({ productData }: { productData: ProductType }) {
  const { prevStep } = useRoutes();

  return (
    <ProductBenefitsContainer
      id={`hero-${removeSpecialCaracters(productData.name)}`}
      css={{
        $$productColor: `$colors${getStyleTokenByProduct(
          productData.colorPrefixToken,
          'primary',
          'pure',
        )}`,
      }}
    >
      <ProductBenefitsHolder
        hasLightBackground={productHasLightBackground(productData.id)}
      >
        <div className="product-information-holder__title">
          <Title.Root
            fontColorVariant="secondary"
            barColor={productData.colorPrefixToken}
            barColorVariant="secondary"
            hasLightBackground={productHasLightBackground(productData.id)}
          >
            <Title.Default tag="h1" size="lg">
              {productData.name}
            </Title.Default>
          </Title.Root>
          <p>
            {
              PRODUCTS_INDIVIDUAL_PAGE_INFORMATION[productData.id].benefitsData
                .title
            }
          </p>
        </div>
        <div className="product-information-holder__form-area">
          <IndividualProductForm
            modalityId={productData.modalityId}
            productName={productData.name}
            productId={productData.id}
          />
        </div>
        <ul className="product-information-holder__offer-elements">
          {PRODUCTS_INDIVIDUAL_PAGE_INFORMATION[
            productData.id
          ].benefitsData.list.map(information => {
            return (
              <ProductBenefitItem
                key={information.id}
                css={{
                  $$productColor: `$colors${getStyleTokenByProduct(
                    productData.colorPrefixToken,
                    'primary',
                    'dark',
                  )}`,
                }}
              >
                <i className={information.icon} />
                <p>
                  <strong>{information.title}</strong> {information.description}
                </p>
              </ProductBenefitItem>
            );
          })}
        </ul>
        <div className="product-information-holder__action-area">
          <Button.Default
            id="btn-voltar-pagina-inicial"
            variant="primary"
            negative
            size="small"
            onClick={() => {
              prevStep();
            }}
          >
            <i className="fa-solid fa-arrow-left" />
            Voltar à página inicial
          </Button.Default>
        </div>
      </ProductBenefitsHolder>
    </ProductBenefitsContainer>
  );
}

export const ProductBenefits = {
  Default: ProductBenefitsDefault,
  Loading: ProductBenefitsLoading,
};
