import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { PRODUCTS } from '@config/products-config';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { IndividualProductFormType } from '@extra-types/product-form-types';
import { getModalityData } from '@helpers/modality-helpers';
import { getProductData, getProductType } from '@helpers/product-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addProductToCart,
  setCartModality,
  clearCart,
  removeProductFromCart,
} from '@store/slices/cart-slice';
import { closeModal, handleModalContent } from '@store/slices/modal-slice';

import AlreadyContractedProduct from './already-contracted-product';
import IndividualInputs from './individual-inputs';
import IndividualProductHeader from './individual-product-header';
import ProductInCartView from './product-in-cart-view';
import { IndividualProductFormContainer } from './styles';
import UnservedRegionProduct from './unserved-region-product';

type IndividualProductFormProps = {
  productName: string;
  productId: number;
  modalityId: number;
  knowMoreButton?: boolean;
};

function IndividualProductForm({
  productName,
  productId,
  modalityId,
  knowMoreButton = false,
}: IndividualProductFormProps) {
  const dispatch = useDispatch();
  const [isOnCart, setIsOnCart] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const { cartModality, productCartList, contractedProducts } = useSelector(
    ({ cart }) => cart,
  );
  const { unservedRegionProducts } = useSelector(({ customer }) => customer);
  const { modalityList, productList } = useSelector(({ products }) => products);
  const productCartData = useMemo(
    () => productCartList.find(cartProduct => cartProduct.id === productId),
    [productCartList, productId],
  );
  const productData = getProductData(productId, productList);
  const modalityData = getModalityData(modalityId, modalityList);

  const methods = useForm<IndividualProductFormType>({
    mode: 'onChange',
    defaultValues:
      productCartData !== undefined
        ? {
            amount: productCartData.amount || undefined,
            value: productCartData.value.toFixed(2) || undefined,
          }
        : undefined,
  });

  const handleAddProductToCart = (data: IndividualProductFormType) => {
    dispatch(setCartModality(modalityData));
    dispatch(
      addProductToCart({
        id: productId,
        type: getProductType(productData),
        amount: data.amount,
        value: Number(data.value),
        planType: PlanTypeEnum.MENSAL,
      }),
    );
    setIsOnCart(true);
    setEnableEdit(false);
  };

  const handleRemoveProduct = () => {
    if (productCartData) {
      methods.reset({
        value: undefined,
        amount: undefined,
      });
      dispatch(removeProductFromCart({ productId: productCartData.id }));
    }
  };

  const verifyIfThisProductIsAlreadyContracted = () => {
    return contractedProducts.some(
      contractedProduct => contractedProduct.id === productId,
    );
  };

  const verifyIfThisProductIsUnservedRegion = () => {
    return unservedRegionProducts.some(
      contractedProduct => contractedProduct === productId,
    );
  };

  const onSubmit: SubmitHandler<IndividualProductFormType> = data => {
    if (cartModality && cartModality.id !== modalityId) {
      dispatch(
        handleModalContent({
          title: 'Troca de modalidade de cartão',
          content:
            'O cartão/benefício escolhido não pode ser contratado junto com os benefícios que já estão no seu carrinho. Como você deseja continuar?',
          state: true,
          confirmationButton: () => {
            dispatch(closeModal());
          },
          denyButton: () => {
            dispatch(clearCart());
            handleAddProductToCart(data);
            dispatch(closeModal());
          },
        }),
      );
    } else {
      handleAddProductToCart(data);
    }
  };

  useEffect(() => {
    if (productCartData !== undefined) {
      setIsOnCart(true);
    } else {
      setIsOnCart(false);
      setEnableEdit(false);
    }
  }, [productCartData]);

  if (verifyIfThisProductIsUnservedRegion()) {
    return (
      <IndividualProductFormContainer
        css={{
          h3: {
            strong: {
              color: `$${PRODUCTS[productId].colorPrefixToken}ColorName`,
            },
          },
        }}
      >
        <IndividualProductHeader
          productId={productId}
          productName={productName}
          knowMoreButton={knowMoreButton}
        />
        <UnservedRegionProduct productId={productId} />
      </IndividualProductFormContainer>
    );
  }

  if (verifyIfThisProductIsAlreadyContracted()) {
    return (
      <IndividualProductFormContainer
        css={{
          h3: {
            strong: {
              color: `$${PRODUCTS[productId].colorPrefixToken}ColorName`,
            },
          },
        }}
      >
        <IndividualProductHeader
          productId={productId}
          productName={productName}
          knowMoreButton={knowMoreButton}
        />
        <AlreadyContractedProduct productId={productId} />
      </IndividualProductFormContainer>
    );
  }

  if (isOnCart && enableEdit === false) {
    return (
      <IndividualProductFormContainer isOnCart>
        <IndividualProductHeader
          productId={productId}
          productName={productName}
          knowMoreButton={knowMoreButton}
        />
        <ProductInCartView
          productId={productId}
          setEnableEdit={setEnableEdit}
          handleRemoveProduct={handleRemoveProduct}
        />
      </IndividualProductFormContainer>
    );
  }

  return (
    <IndividualProductFormContainer onSubmit={methods.handleSubmit(onSubmit)}>
      <IndividualProductHeader
        productId={productId}
        productName={productName}
        knowMoreButton={knowMoreButton}
      />
      <FormProvider {...methods}>
        <IndividualInputs productName={productName} isOnCart={isOnCart} />
      </FormProvider>
    </IndividualProductFormContainer>
  );
}

export default IndividualProductForm;
