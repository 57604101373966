import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import formatCurrencyToValue from '@vr/devkit/money/formatCurrencyToValue';
import formatValueToCurrency from '@vr/devkit/money/formatValueToCurrency';
import debounce from 'lodash.debounce';

import { Input } from '@components/input';
import { ProductImage } from '@elements/product-image';
import { ProductNameText } from '@elements/product-name-text';
import { Switch } from '@elements/switch';

import { DIGIT_WAIT_TIME_TO_PRICE } from '@config/application-config';
import {
  FONT_SIZE_INPUT_PRODUCT_PAGE,
  MAX_LENGTH_CURRENCY_VALUE,
  MAX_LENGTH_QUANTITY_CARDS,
} from '@config/input-config';
import { PRODUCT_OFFER_PHRASE } from '@config/products-config';
import { PlanTypeEnum } from '@extra-types/product-cart-type';
import { getProductFromCart } from '@helpers/cart-helpers';
import { FormErrors } from '@helpers/enum/form-errors';
import { removeSpecialCaracters } from '@helpers/function-helpers';
import { getProductData, getProductType } from '@helpers/product-helpers';
import { ProductPromotionalPhrase } from '@helpers/style-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { useSelector } from '@hooks/use-selector';
import {
  addProductToCart,
  removeProductFromCart,
} from '@store/slices/cart-slice';
import {
  closeWarningModal,
  handleWarningModalContent,
} from '@store/slices/warning-modal-slice';
import { fetchCartPricing } from '@store/thunks/cart-thunk';

import { OfferFacade } from '@src/facade/offer-facade';

import { ProductItemContainer } from './styles';

interface CartPageIndividualFormProductItemProps {
  productId: number;
  isMobility?: boolean;
  contracted?: boolean;
}

interface IndividualProductItemType {
  amount: number;
  value: string;
}

export function CartPageIndividualFormProductItem({
  productId,
  isMobility = false,
  contracted = false,
}: CartPageIndividualFormProductItemProps) {
  const { selectedOffer } = OfferFacade();
  const dispatch = useDispatch();
  const { config, isLoading } = useSelector(({ application }) => application);
  const isCustomerLoading = useSelector(({ customer }) => customer.isLoading);
  const { productCartList, isCartLoading } = useSelector(({ cart }) => cart);
  const productList = useSelector(({ products }) => products.productList);
  const [isActive, setActive] = useState(
    productCartList.some(product => product.id === productId),
  );

  const offerPhrase = PRODUCT_OFFER_PHRASE[productId] || (
    <div>Produto não encontrado</div>
  );

  const productCartData = getProductFromCart({ productId, productCartList });

  const productData = useMemo(
    () => getProductData(productId, productList),
    [productId, productList],
  );

  const { register, watch, handleSubmit, formState, trigger, resetField } =
    useForm<IndividualProductItemType>({
      mode: 'onChange',
      defaultValues: {
        amount: productCartData?.amount ?? undefined,
        value: productCartData?.value
          ? productCartData.value.toFixed(2)
          : undefined,
      },
    });

  const productAmount = watch('amount');
  const productValue = watch('value');

  const totalValue = productAmount * Number(productValue) || 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce(async () => {
      const isFormValid = await trigger(['amount', 'value']);
      handleSubmit((form: IndividualProductItemType) => {
        if (
          isFormValid &&
          !!form.amount &&
          !!form.value &&
          (form.amount !== productCartData?.amount ||
            Number(form.value) !== productCartData?.value)
        ) {
          dispatch(
            addProductToCart({
              id: productId,
              type: getProductType(productData),
              amount: form.amount,
              value: Number(form.value),
              planType: PlanTypeEnum.MENSAL,
            }),
          );
          dispatch(fetchCartPricing({ offerData: selectedOffer }));
        }
      })();
    }, DIGIT_WAIT_TIME_TO_PRICE),
    [handleSubmit, dispatch, productId, productCartData],
  );

  const handleActive = useCallback(() => {
    if (isActive) {
      const removeProduct = () => {
        dispatch(removeProductFromCart({ productId }));
        dispatch(closeWarningModal());
      };
      if (
        productCartList.length === 1 &&
        productCartList.some(cartProduct => cartProduct.id === productId)
      ) {
        dispatch(
          handleWarningModalContent({
            isVisible: true,
            title: 'Remover Produto',
            content:
              'Este é o seu último produto no carrinho, ao remove-lo você será redirecionado para a página inicial. Deseja mesmo continuar?',
            buttonAction: {
              label: 'Sim, remover produto',
              action: removeProduct,
            },
          }),
        );
      } else {
        dispatch(removeProductFromCart({ productId }));
        if (productValue) {
          dispatch(fetchCartPricing({ offerData: selectedOffer }));
        }
        setActive(false);
      }
    } else {
      setActive(true);
    }
  }, [
    isActive,
    productCartList,
    dispatch,
    productId,
    productValue,
    selectedOffer,
  ]);

  if (productData === undefined) {
    return null;
  }

  if (contracted) {
    return (
      <ProductItemContainer disabled className="offer-container">
        <div className="product-item__switch product-item__switch--unselected" />
        <div className="product-item__offer-text">
          <ProductImage productId={productId} />
          <div className="offer-text__product-information">
            <p className="product-information__product-name">
              <ProductNameText id={productId} />
            </p>
            <div className="product-information__text">
              <ProductPromotionalPhrase>
                <p>Uhul! Você já possui esse benefício!</p>
              </ProductPromotionalPhrase>
            </div>
          </div>
        </div>
      </ProductItemContainer>
    );
  }

  if (!isActive) {
    return (
      <ProductItemContainer className="offer-container">
        <div className="product-item__switch product-item__switch--unselected">
          <Switch
            disabled={isCartLoading || isCustomerLoading}
            isToggled={isActive}
            onToggle={handleActive}
          />
        </div>
        <div className="product-item__offer-text">
          <ProductImage productId={productId} />
          <div className="offer-text__product-information">
            <p className="product-information__product-name">
              <ProductNameText id={productId} />
            </p>
            <div className="product-information__text">{offerPhrase}</div>
          </div>
        </div>
      </ProductItemContainer>
    );
  }

  return (
    <ProductItemContainer
      isMobility={isMobility}
      css={{
        $$productColor: `$colors$${productData.colorPrefixToken}ColorName`,
      }}
    >
      {!isMobility && (
        <div className="product-item__switch">
          <Switch
            isToggled={isActive}
            onToggle={handleActive}
            disabled={isCartLoading || isCustomerLoading}
          />
        </div>
      )}
      <div className="product-item__title">
        <ProductImage productId={productId} />
        {productData.name}
      </div>
      <div className="product-item__product-amount">
        <Input.Default
          id={`product-summary-${removeSpecialCaracters(
            productData.name,
          )}-amount`}
          width="100%"
          customMask="999"
          maxLength={MAX_LENGTH_QUANTITY_CARDS}
          placeholder="0"
          fontSize={FONT_SIZE_INPUT_PRODUCT_PAGE}
          label="Qnt. Cartões"
          disabled={isCartLoading || isLoading || isCustomerLoading}
          errorMessage={formState.errors?.amount?.message}
          inputMode="decimal"
          {...register(`amount` as const, {
            onChange: () => debouncedSave(),
            valueAsNumber: true,
            shouldUnregister: true,
            required: 'É obrigatório',
            min: {
              value: 1,
              message: `${FormErrors.MIN_CARD_AMOUNT} 1`,
            },
            max: {
              value: config.maxBeneficiaryAmount,
              message: `${FormErrors.MAX_CARD_AMOUNT} ${config.maxBeneficiaryAmount}`,
            },
          })}
        />
      </div>
      <div className="product-item__product-value">
        <Input.Default
          id={`product-summary-${removeSpecialCaracters(
            productData.name,
          )}-value`}
          width="100%"
          maskMoney
          placeholder="R$ 0,00"
          maxLength={MAX_LENGTH_CURRENCY_VALUE}
          fontSize={FONT_SIZE_INPUT_PRODUCT_PAGE}
          label="Valor mensal"
          disabled={isCartLoading || isLoading || isCustomerLoading}
          errorMessage={formState.errors?.value?.message}
          inputMode="numeric"
          {...register(`value` as const, {
            deps: 'amount',
            shouldUnregister: true,
            onChange: event => {
              if (event.target.value === 'R$ 0,00') {
                resetField('value', {
                  defaultValue: event.target.value.replace('R$ 0,00', ''),
                });
              }
              debouncedSave();
            },
            setValueAs: value =>
              value && formatCurrencyToValue(value).toFixed(2),
            required: 'É obrigatório',
            validate: {
              min: value => {
                return value !== ''
                  ? Number(value) >= config.minCardValue ||
                      `${FormErrors.MIN_CARD_VALUE} ${formatValueToCurrency(
                        config.minCardValue,
                      )}`
                  : true;
              },
              max: value =>
                value !== ''
                  ? Number(value) <= config.maxCardValue ||
                    `${FormErrors.MAX_CARD_VALUE} ${formatValueToCurrency(
                      config.maxCardValue,
                    )}`
                  : true,
            },
          })}
        />
      </div>
      <div className="product-item__total-value" data-testid="total-value">
        {formatValueToCurrency(totalValue)}
      </div>
    </ProductItemContainer>
  );
}
