import React from 'react';

import { styled } from '@src/themes';

export function FormWarning() {
  return (
    <FormWarningContainer>
      <i className="fas fa-info-circle" />
      <div className="notes">
        <strong>* Importante:</strong>
        <ul>
          <li>
            Não são emitidas cobranças nesta etapa da contratação. Após a
            geração do contrato e conclusão do fluxo online um representante
            entrará em contato com você para dar prosseguimento à contratação do
            seu Vale-Transporte.
          </li>
          <li>
            Somente após o pagamento do boleto seu SVA estará disponível para
            uso.
          </li>
        </ul>
      </div>
    </FormWarningContainer>
  );
}

const FormWarningContainer = styled('div', {
  display: 'flex',
  gap: '1.5rem',
  alignItems: 'center',
  backgroundColor: '$informationLight',
  borderRadius: '1rem',
  padding: '1.5rem',

  '.notes': {
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'column',
    fontSize: '0.875rem',
    fontWeight: 500,

    strong: {
      fontSize: '1rem',
    },
  },

  i: {
    color: '$informationDark',
    fontSize: '1.5rem',
  },

  'ul li': {
    listStyleType: 'initial',
    listStylePosition: 'inside',
    lineHeight: 1.5,

    '&::marker': {
      color: '$informationDark',
    },
  },

  '@deviceSm': {
    flexDirection: 'column',
    gap: '1rem',
  },
});
