import { AnchorHTMLAttributes, ButtonHTMLAttributes, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { CSS, VariantProps } from '@stitches/react';

import Loader from '@elements/loader';

import { config } from '@src/themes';

import { ButtonStyled } from './styles';

interface ButtonLinkRouterProps
  extends LinkProps,
    VariantProps<typeof ButtonStyled> {
  children: React.ReactNode;
  loading?: boolean;
  css?: CSS<typeof config>;
  disabled?: boolean;
}

function ButtonLinkRouter({
  children,
  to,
  disabled = false,
  loading,
  onClick,
  css,
  ...props
}: ButtonLinkRouterProps) {
  const disableStatus = useMemo(() => disabled || loading, [disabled, loading]);
  return (
    <ButtonStyled
      as={Link}
      aria-disabled={disableStatus}
      isDisabled={disableStatus}
      to={disableStatus ? '/' : to}
      onClick={disableStatus ? e => e.preventDefault() : onClick}
      css={css}
      {...props}
    >
      {loading ? <Loader size="sm" /> : children}
    </ButtonStyled>
  );
}

interface ButtonAnchorProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof ButtonStyled> {
  children: React.ReactNode;
  loading?: boolean;
  css?: CSS<typeof config>;
  disabled?: boolean;
}

function ButtonAnchor({
  children,
  disabled = false,
  loading,
  onClick,
  href,
  css,
  ...props
}: ButtonAnchorProps) {
  const disableStatus = useMemo(() => disabled || loading, [disabled, loading]);

  return (
    <ButtonStyled
      as="a"
      aria-disabled={disableStatus}
      onClick={disableStatus ? e => e.preventDefault() : onClick}
      isDisabled={disableStatus}
      href={disableStatus ? '#' : href}
      css={css}
      {...props}
    >
      {loading ? <Loader size="sm" /> : children}
    </ButtonStyled>
  );
}

interface ButtonDefaultProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof ButtonStyled> {
  children: React.ReactNode;
  css?: CSS<typeof config>;
  loading?: boolean;
}

function ButtonDefault({
  children,
  disabled = false,
  onClick,
  css,
  loading,
  type = 'button',
  ...props
}: ButtonDefaultProps) {
  const disableStatus = useMemo(() => disabled || loading, [disabled, loading]);

  return (
    <ButtonStyled
      disabled={disabled}
      aria-disabled={disableStatus}
      isDisabled={disableStatus}
      onClick={disableStatus ? e => e.preventDefault() : onClick}
      type={type}
      css={css}
      {...props}
    >
      {loading ? <Loader size="sm" /> : children}
    </ButtonStyled>
  );
}

export const Button = {
  Default: ButtonDefault,
  Anchor: ButtonAnchor,
  LinkRouter: ButtonLinkRouter,
};
