import {
  BIRTHDATE_LENGTH,
  BIRTHDATE_MIN_LENGTH,
  CPF_MAX_LENGTH,
  FIND_ALPHABETICS,
  MATCH_ONLY_NUMBER,
  NAME_MIN_LENGTH,
  ONE_OR_MORE_OCCURRENCIES_OF_COMMA,
  ONE_OR_MORE_OCCURRENCIES_OF_SPACE,
} from '@config/input-config';
import { LinkType } from '@extra-types/link-type';

export function mergeRefs<T = unknown>(refs: unknown[]): React.RefCallback<T> {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        // eslint-disable-next-line no-param-reassign
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export const removeSpecialCaracters = (text: string): string => {
  const a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(p, (c: string) => b.charAt(a.indexOf(c)))
    .replace(/\(|\)/g, '')
    .replace(/&/g, '-e-')
    .replace(/\+/g, '-mais-')
    .replace(/[\s\W-]+/g, '-');
};

export function validSameValue(
  valueToBeCompared: string,
  listOfValues: string[],
): boolean {
  const filteredList = listOfValues.filter(
    value => value === valueToBeCompared,
  );
  return filteredList.length > 1;
}

export function formatFileOrder(beneficiaries: string): string {
  if (!beneficiaries.match(MATCH_ONLY_NUMBER)) {
    return beneficiaries;
  }

  return beneficiaries
    .replaceAll(ONE_OR_MORE_OCCURRENCIES_OF_SPACE, ',')
    .replaceAll(ONE_OR_MORE_OCCURRENCIES_OF_COMMA, ',');
}

export function ordenateBeneficiaryArray(beneficiaryData: string[]): string[] {
  const nameArray: string[] = [];
  let cpf = '';
  let birthDate = '';
  const orderedBeneficiaryArray: string[] = [];

  beneficiaryData.forEach(data => {
    if (data.match(FIND_ALPHABETICS) && data.length > NAME_MIN_LENGTH) {
      nameArray.push(data);
    } else if (
      data.trim().length <= BIRTHDATE_LENGTH &&
      data.trim().length < CPF_MAX_LENGTH &&
      data.trim().length > BIRTHDATE_MIN_LENGTH
    ) {
      birthDate = data;
    } else {
      cpf = data;
    }
  });

  orderedBeneficiaryArray.push(nameArray.toString().replaceAll(',', ' '));
  orderedBeneficiaryArray.push(cpf);
  orderedBeneficiaryArray.push(birthDate);

  return orderedBeneficiaryArray;
}

export const getLinkDestinationType = (destination: string): LinkType => {
  if (destination.includes('http')) return 'externalLink';
  if (destination[0] === '#') return 'internalSectionLink';
  return 'internalLink';
};

export function ensureNotUndefined<T>(
  argument: T | undefined | null,
  message = 'This value was promised to be there.',
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

export function toISOFormat(date: string) {
  const [DD, MM, YYYY] = date.split('/');

  return `${YYYY}-${MM}-${DD}`;
}

export function scrollToElement(
  id: string,
  {
    behavior = 'smooth',
    delayTime = 100,
  }: {
    behavior?: ScrollBehavior;
    delayTime?: number;
  } = {},
) {
  setTimeout(() => {
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({ behavior });
  }, delayTime);
}

export function stripHtmlTags(str: string) {
  if (str === null || str === '') {
    return '';
  }

  return str.toString().replace(/<[^>]*>/g, '');
}
