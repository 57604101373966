import { ASSETS_ICONS_PATH } from '@config/assets-config';
import { env } from '@config/env';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

export function ResumeSVAProductList({
  isCollapsed,
}: {
  isCollapsed: boolean;
}) {
  const { svaCartList } = useSelector(({ cart }) => cart);

  if (svaCartList.length === 0) {
    return null;
  }

  return (
    <ResumeSVAProductListContainer isCollapsed={isCollapsed}>
      <div className="resume-sva-balance-list-container__title">
        <span>Serviços adicionais</span>
      </div>
      <ul>
        {svaCartList.map(product => (
          <li key={product.id}>
            <img
              src={`${env.URL_ASSETS_CHANNEL + ASSETS_ICONS_PATH}/icon-${
                product.id
              }.svg`}
              alt={`Logotipo do produto ${product.id}`}
            />
          </li>
        ))}
      </ul>
    </ResumeSVAProductListContainer>
  );
}

const ResumeSVAProductListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',

  margin: '0.5rem 1rem',
  opacity: 1,
  transition: 'opacity 0.4s ease',
  transitionDelay: '0.05s',

  '@deviceSm': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.5rem',

    background: '$silverLight',
    borderRadius: '0.5rem',
    padding: '0.5rem 0.5rem 0',
    marginBottom: 0,
  },

  '.resume-sva-balance-list-container__title': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',

    span: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      color: '$primaryPure',
      lineHeight: 1.3,
    },

    '@deviceSm': {
      padding: '0 0.5rem',

      '.title__product-reference': {
        display: 'none',
      },

      span: {
        fontSize: '0.75rem',
      },
    },
  },

  ul: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    overflowX: 'auto',

    li: {
      display: 'flex',
    },

    img: {
      width: '1.5rem',
      objectFit: 'contain',
    },

    '@deviceSm': {
      gap: '0.625rem',

      width: '100%',
      padding: '0 0.5rem 0.75rem',
    },
  },
  variants: {
    isCollapsed: {
      false: {
        height: 0,
        marginBottom: 0,
        padding: 0,
        opacity: 0,
        visibility: 'hidden',
      },
    },
  },
});
