export enum ApiErrorsMessages {
  DEFAULT = 'Erro interno. Por favor, tente novamente mais tarde.',
  MAINTENANCE = 'Estamos trabalhando em melhorias para te oferecer a melhor experiência. Por favor, volte mais tarde.',
  INFRASTRUCTURE = 'Servidor indisponível. Por favor, tente novamente mais tarde.',
  NOT_CATALOGED = 'Erro interno. Por favor, tente novamente mais tarde.',
  INVALID_FIELD = 'Campo inválido ou obrigatório. Por favor, verifique os campos e reenvie a requisição novamente.',
  CHANNEL_NOT_FOUND = 'Não conseguimos direcionar você corretamente por esse link. Vamos te direcionar para a página inicial.',
  CHANNEL_RESTRICT = 'Verificamos que o CNPJ informado apresenta irregularidade junto à Receita Federal. Após a regularização, esperamos ter você aqui novamente.',
  AUTHENTICATION = 'Houve um problema na autenticação do sistema. Por favor, tente novamente mais tarde.',
  COMPANY_SERVED_BY_ANOTHER_CHANNEL = 'Empresa atendida por outro canal.',
  CEP_ERROR = 'Não foi possível consultar o CEP, tente novamente ou digite manualmente para continuar.',
  CONTRACT_ERROR = 'Não foi possível baixar o contrato, tente novamente.',
  BILLET_ERROR = 'Não foi possível gerar o boleto de pagamento, tente novamente.',
  MINIMUM_VALUE_NOT_REACHED = 'Há produtos que não atingiram o valor mínimo.',
  NO_CONDITIONS_FOR_CONTRACT = 'Não foi possível finalizar a contratação por favor, entre em contato com nossa central de atendimento.<br /><strong>Capital e Regiões Metropolitanas:</strong> (11) 4004-4938<br /><strong>E-mail:</strong> relacionamento@vr.com.br',
  CONTRACT_ERROR_NOT_CATALOGED = 'Não foi possível finalizar a contratação, por favor tente mais tarde.',
  CONTRACT_DOWNLOAD_ERROR = 'Não foi possível realizar o download do seu contrato, por favor tente novamente.',
  MODALITY_NOT_FOUND = 'Não foram encontradas modalidades de cartões, por favor tente novamente mais tarde.',
  FAQ_CATEGORIES_ERROR = 'Lamentamos, mas não foi possível carregar o conteúdo da FAQ no momento. Por favor, tente novamente mais tarde.',
  PUBLIC_AGENCY_ERROR = 'Não foi possível prosseguir com a contratação. Por favor, acesse nosso chat para mais informações.',
  CAN_NOT_APPLY_OFFER_ERROR = 'Não foi possível aplicar a oferta solicitada. Por favor, verificar as condições novamente.',
  BLOCK_LIST_ERROR = 'Contratos criados anteriormente não permitem a renegociação',
  INVALID_EMAIL_PERMISSION = 'Identificamos que o e-mail de acesso utilizado não possui autorização para criar novos contratos vinculados ao CNPJ informado. Por favor, utilize um e-mail que tenha autorização de compra ou entre em contato com o administrador do sistema para solicitar as permissões necessárias.',
}

export enum ApiErrorsModalTitle {
  DEFAULT = 'Desculpe, algo inesperado aconteceu :(',
  MAINTENANCE = 'Página em manutenção',
  CAUTION = 'Atenção!',
}
