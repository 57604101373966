import { Button } from '@elements/button';
import { MobilitySectionTitle } from '@elements/mobility-section-title';
import { PageSection } from '@elements/page-section';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

import { contractSectionID } from './product-hero-section';

export function SmallCallToActionSection() {
  return (
    <PageSection>
      <SectionHolder>
        <MobilitySectionTitle>
          Dê adeus à compra de VT em diversas operadoras agora mesmo e otimize o
          tempo do seu RH
        </MobilitySectionTitle>
        <div className="small-cta-section__action-area">
          <Button.Anchor
            id="mobilidade_sessao-pequena_cta"
            href={`#${contractSectionID}`}
          >
            Contratar Vale-Transporte
          </Button.Anchor>
        </div>
      </SectionHolder>
    </PageSection>
  );
}

const SectionHolder = styled('div', {
  display: 'flex',
  gap: convertPixelToRem(84),
  alignItems: 'center',
  justifyContent: 'space-between',

  paddingTop: '3rem',
  paddingBottom: '3rem',

  '.small-cta-section__action-area': {
    a: {
      width: '332px',
      color: '$black',
    },

    '@deviceLg': {
      width: '100%',

      a: {
        maxWidth: '332px',
        width: '100%',
        margin: '0 auto',
      },
    },
  },

  '@deviceLg': {
    gap: '2rem',
    flexDirection: 'column',
  },
});
