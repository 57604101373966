import { RegisterSectionContainer, RegisterSectionContent } from './styles';

interface RegisterSectionProps {
  title: string;
  inputNumber: number;
  children: React.ReactNode;
}

export function RegisterSection({
  children,
  title,
  inputNumber,
}: RegisterSectionProps) {
  return (
    <RegisterSectionContainer>
      <div className="section__title">
        <div className="title__number">{inputNumber}</div>
        {title}
      </div>
      <RegisterSectionContent>{children}</RegisterSectionContent>
    </RegisterSectionContainer>
  );
}
