import { useLocation, useNavigate } from 'react-router-dom';

import { env } from '@config/env';
import { ProductType } from '@extra-types/product-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

interface ModalityProductGroupProps {
  modalityGroup: {
    id: number;
    title: string;
    pageRoute: string;
    productList: ProductType[];
  };
  extraActionOnClick?: () => void;
}

export function ModalityProductGroup({
  modalityGroup,
  extraActionOnClick,
}: ModalityProductGroupProps) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ModalityProductGroupContainer
      onClick={() => {
        navigate({
          pathname: modalityGroup.pageRoute,
          search: location.search,
        });
        if (extraActionOnClick !== undefined) {
          extraActionOnClick();
        }
      }}
    >
      <p className="title">{modalityGroup.title}</p>
      <div className="product-list">
        {modalityGroup.productList.map(product => (
          <img
            key={product.id}
            src={`${env.URL_ASSETS_CHANNEL}${product.logoPath}`}
            alt={`Logotipo ${product.name}`}
          />
        ))}
      </div>
      <div className="action-label">
        Escolher modalidade {modalityGroup.title}
      </div>
    </ModalityProductGroupContainer>
  );
}

export const ModalityProductGroupContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  width: '100%',
  cursor: 'pointer',

  'p.title': {
    margin: '0.25rem 0',
    borderRadius: '6px 6px 0 0',

    fontSize: '0.75rem',
    fontWeight: '$semibold',
  },

  '.product-list': {
    display: 'flex',
    gap: '1rem',
    justifyContent: 'center',
    flexWrap: 'wrap',

    backgroundColor: '$silverLight',
    width: '100%',
    padding: '1rem 0.5rem',
    borderRadius: '6px 6px 0 0',
    transition: '0.4s ease',

    img: {
      height: convertPixelToRem(46),
    },
  },

  '.action-label': {
    display: 'flex',
    justifyContent: 'center',

    background: '$primaryLight',
    width: '100%',
    padding: '0.25rem 0.75rem',
    borderRadius: '0 0 6px 6px',
    transition: '0.4s ease',

    color: '$white',
    fontSize: '0.75rem',
    fontWeight: '$medium',
  },

  '&:hover': {
    '.product-list': {
      backgroundColor: '$silverLight',
    },
    '.action-label': {
      background: '$primaryDark',
    },
  },
});
