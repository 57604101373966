import { getChatKeyByEnvironment } from '@helpers/chat-helpers';
import { Emissor } from '@helpers/enum/emissor';

export interface EmissorData {
  initials: Emissor;
  title: string;
  description: string;
  name: string;
  shortName: string;
  portalName: string;
  email: string;
  tagGTMList: string[];
  tagGA3: string;
  tagGA4?: string;
  isSurveyActive: boolean;
  isContactEmailActive: boolean;
  website: string;
  storeWebsite: string;
  twitterWebsite: string;
  logoHeaderFileName: string;
  socialMediaList: Array<{
    id: string;
    name: string;
    icon: string;
    url: string;
  }>;
  privacyPolicy: {
    article: string;
    title: string;
    url: string;
  };
  copyright: {
    title: string;
    cnpj: string;
  };
  deliveryTimeList: Array<{
    id: string;
    local: string;
    deadline: string;
  }>;
  chat: {
    url: string;
    key: string;
    availableChannels: 'all' | string[];
    isActive: boolean;
  };
}

export const EmissorStaticInformation: { [key in Emissor]: EmissorData } = {
  VRPAT: {
    initials: Emissor.VRPAT,
    title: 'Loja VR',
    name: 'VR',
    shortName: 'VR',
    portalName: 'Portal VR Empresas',
    description:
      'Na Loja VR você compra online produtos: Vale-Alimentação, Vale-Refeição, VR + VA, VR Multi.',
    website: 'https://vr.com.br',
    storeWebsite: 'https://loja.vr.com.br',
    twitterWebsite: 'https://twitter.com/vrbeneficios',
    email: 'relacionamento@vr.com.br',
    tagGTMList: ['GTM-ML5G7V6', 'GTM-PQSKTRV', 'GTM-NHNZ9QF'],
    tagGA3: 'UA-188409239-1',
    tagGA4: 'G-BELVF2KJR4',
    isSurveyActive: true,
    logoHeaderFileName: 'logo-default.svg',
    isContactEmailActive: true,
    socialMediaList: [
      {
        id: 'footer-facebook',
        icon: 'fab fa-facebook-f',
        name: 'Facebook',
        url: 'https://www.facebook.com/VRBeneficios/',
      },
      {
        id: 'footer-instagram',
        icon: 'fab fa-instagram',
        name: 'Instagram',
        url: 'https://www.instagram.com/issoevr',
      },
      {
        id: 'footer-linkedin',
        icon: 'fab fa-linkedin-in',
        name: 'Linkedin',
        url: 'https://www.linkedin.com/company/vrbeneficios/?originalSubdomain=pt',
      },
      {
        id: 'footer-youtube',
        icon: 'fab fa-youtube',
        name: 'Youtube',
        url: 'https://www.youtube.com/channel/UCmNW3Md8IHDoM6Z4D_68Pdw',
      },
    ],
    privacyPolicy: {
      article: 'a',
      title: 'Política de Privacidade',
      url: 'https://assets-lojavr.vr.com.br/vrpat/assets/images/docs/politica_de_privacidade.pdf',
    },
    copyright: {
      title: 'VR',
      cnpj: '02.535.864/0001-33',
    },
    deliveryTimeList: [
      {
        id: 'delivery-time-1',
        local:
          'Capitais de São Paulo, Curitiba, Belo Horizonte, Porto Alegre e Rio de Janeiro:',
        deadline: 'Até 4 dias úteis',
      },
      {
        id: 'delivery-time-2',
        local: 'Outras capitais e interior de São Paulo:',
        deadline: 'Até 5 dias úteis',
      },
      {
        id: 'delivery-time-3',
        local: 'Outras regiões do país:',
        deadline: 'Até 7 dias úteis',
      },
    ],
    chat: {
      url: 'https://app.boteria.com.br',
      key: getChatKeyByEnvironment(),
      availableChannels: ['default', 'WEB', 'PME'],
      isActive: true,
    },
  },
  JVCEF: {
    initials: Emissor.JVCEF,
    title: 'Loja CAIXA Pré-Pagos',
    name: 'CAIXA Cartões Pré-Pagos',
    shortName: 'CAIXA Pré-Pagos',
    portalName: 'Portal CAIXA Pré-Pagos Empresas',
    description:
      'Produtos CAIXA Pré-Pagos: Liberdade de escolha. Simule e Compre totalmente on-line, os benefícios da CAIXA para sua empresa.',
    website: 'https://caixaprepagos.com.br',
    storeWebsite: 'https://loja.caixaprepagos.com.br',
    twitterWebsite: 'https://twitter.com/caixa',
    email: '',
    tagGTMList: ['GTM-PL7KVS8'],
    tagGA3: 'UA-216729055-1',
    tagGA4: undefined,
    socialMediaList: [],
    isSurveyActive: false,
    logoHeaderFileName: 'logo-header.png',
    isContactEmailActive: false,
    copyright: {
      title: 'CAIXA Cartões Pré-Pagos S.A.',
      cnpj: '39.459.331/0001-20',
    },
    privacyPolicy: {
      article: 'o',
      title: 'Aviso de Privacidade',
      url: 'https://www.caixaprepagos.com.br/aviso-de-privacidade.html',
    },
    deliveryTimeList: [
      {
        id: 'delivery-time-1',
        local:
          'São Paulo, Rio de Janeiro, Porto Alegre, Curitiba e Belo Horizonte (capitais):',
        deadline: 'Até 5 dias úteis',
      },
      {
        id: 'delivery-time-2',
        local: 'Interior SP e demais capitais:',
        deadline: 'Até 7 dias úteis',
      },
      {
        id: 'delivery-time-3',
        local: 'Outras localidades:',
        deadline: 'Até 10 dias úteis',
      },
    ],
    chat: {
      url: '',
      key: '',
      availableChannels: [],
      isActive: false,
    },
  },
};

interface LinkGroupData {
  id: string;
  title: string;
  linkList: Array<{
    id: string;
    label: string;
    url: string;
  }>;
}

export const footerPageLinkContent: { [key in Emissor]: Array<LinkGroupData> } =
  {
    VRPAT: [
      {
        id: 'footer-institucional-vr',
        title: 'Institucional VR',
        linkList: [
          {
            id: 'footer-historia-vr',
            label: 'História da VR',
            url: 'https://www.vr.com.br/institucional-vr/historia-da-vr.htm',
          },
          {
            id: 'footer-trabalhe-conosco',
            label: 'Trabalhe Conosco',
            url: 'https://vr.gupy.io/',
          },
          {
            id: 'footer-imprensa',
            label: 'Imprensa',
            url: 'https://blog.vr.com.br/imprensa/',
          },
          {
            id: 'footer-blog',
            label: 'Blog',
            url: 'https://blog.vr.com.br/',
          },
          {
            id: 'footer-programa-integridade',
            label: 'Programa de Integridade',
            url: 'https://www.vr.com.br/data/files/F7/C3/4C/52/22D82810650F64E7272DCCA8/Programa%20de%20Integridade_VR_2022.pdf',
          },
          {
            id: 'footer-canal-denuncias',
            label: 'Canal de Denúncias',
            url: 'https://www.vr.com.br/canal-de-denuncias.htm',
          },
        ],
      },
      {
        id: 'footer-saiba-mais-sobre',
        title: 'Saiba mais sobre',
        linkList: [
          {
            id: 'footer-vr-refeicao',
            label: 'Refeição',
            url: 'https://www.vr.com.br/pra-sua-empresa/vale-refeicao.htm',
          },
          {
            id: 'footer-vr-alimentacao',
            label: 'Alimentação',
            url: 'https://www.vr.com.br/pra-sua-empresa/vale-alimentacao.htm',
          },
          {
            id: 'footer-vr-conta-digital',
            label: 'VR Conta Digital',
            url: 'https://www.vr.com.br/estabelecimento/vr-conta-digital-empresas.htm',
          },
          {
            id: 'footer-onde-usar-meu-cartao-vr',
            label: 'Onde usar meu VR',
            url: 'https://www.vr.com.br/#rede-credenciada',
          },
          {
            id: 'footer-credencie-seu-estabelecimento',
            label: 'Credencie seu estabelecimento',
            url: 'https://portal.vr.com.br/portal/portal-vr/area-publica/credenciamento-contato/?utm_source=Site&utm_medium=Institucional&utm_campaign=vr.com.br&_ga=2.49128686.958179485.1668029760-1789440030.1660681973',
          },
          {
            id: 'footer-melhores-ofertas',
            label: 'As Melhores Ofertas',
            url: 'https://www.vr.com.br/saiba-mais-sobre/marketplace.htm',
          },
          {
            id: 'footer-melhores-praticas',
            label: 'Melhores práticas',
            url: 'https://www.vr.com.br/#:~:text=As%20Melhores%20Ofertas-,Melhores%20pr%C3%A1ticas,-BAIXE%20O%20APP',
          },
        ],
      },
    ],
    JVCEF: [],
  };
