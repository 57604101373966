import { useState } from 'react';

import { SummaryActions } from '@components/cart-summary-components/summary-actions';
import { SummaryOfferValidation } from '@components/cart-summary-components/summary-offer-validation';
import { CartSummary } from '@compositions/cart-summary';

import { HEADER_HEIGHT, HEADER_MOBILE_HEIGHT } from '@config/styles-config';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

export function CartSummaryActionsView() {
  const isLoading = useSelector(({ cart }) => cart.isCartLoading);
  const [isCollapsed, setCollapseState] = useState(true);

  const handleCollapseState = (state?: boolean) => {
    return state === undefined
      ? setCollapseState(oldState => !oldState)
      : setCollapseState(state);
  };

  return (
    <CartSummaryViewContainer>
      {!isLoading && (
        <CollapseButton type="button" onClick={() => handleCollapseState()}>
          <div className="green-bar" />
          {isCollapsed && <p>Clique para mais detalhes</p>}
          {!isCollapsed && <p>Clique para resumir</p>}
        </CollapseButton>
      )}
      <CartSummary isCollapsed={isCollapsed} />
      <SummaryOfferValidation enableMargin />
      <SummaryActions />
    </CartSummaryViewContainer>
  );
}

const CartSummaryViewContainer = styled('aside', {
  position: 'sticky',
  top: `calc(${convertPixelToRem(HEADER_HEIGHT)} + 3rem)`,

  display: 'flex',
  flexDirection: 'column',

  transition: 'transform 0.4s ease',

  '@media only screen and (max-width: 1114px)': {
    position: 'fixed',
    top: 'unset',
    bottom: 0,
    left: 0,
    right: 0,

    background: '$white',
    maxHeight: `calc(100vh - ${HEADER_HEIGHT}px - 1rem)`,
    borderRadius: `${convertPixelToRem(8)} ${convertPixelToRem(8)} 0 0`,
    boxShadow: '0px 0px 8px #00000080',
  },

  '@deviceSm': {
    maxHeight: `calc(100vh - ${HEADER_MOBILE_HEIGHT}px - 1rem)`,
  },
});

const CollapseButton = styled('button', {
  display: 'none',
  pointerEvents: 'none',

  background: 'transparent',
  padding: 0,
  width: 0,

  '.green-bar': {
    background: '$primaryDark',
    width: convertPixelToRem(46),
    height: '0.25rem',
    margin: '0 auto',
    borderRadius: '$pill',
    transition: 'background-color 0.4s ease',
  },

  p: {
    marginTop: '0.5rem',
    transition: 'color 0.4s ease',

    color: '$primaryPure',
    fontSize: convertPixelToRem(12),
    lineHeight: 1,
  },

  '&:hover': {
    '.green-bar': {
      background: '$secondaryPure',
    },

    p: {
      color: '$secondaryPure',
    },
  },

  '@media only screen and (max-width: 1114px)': {
    display: 'block',
    pointerEvents: 'all',

    width: '100%',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
  },
});
