import { Skeleton } from '@components/skeletons';
import CustomerModal from '@compositions/customer-modal';
import { HCMBenefitsSection } from '@compositions/hcm-sections/hcm-benefits-section';
import { HCMCarouselSection } from '@compositions/hcm-sections/hcm-carousel-section';
import { HCMFaqSection } from '@compositions/hcm-sections/hcm-faq-section';
import { HCMPlansSection } from '@compositions/hcm-sections/hcm-plans-section';
import { PageSection } from '@elements/page-section';

import { getModalityData } from '@helpers/modality-helpers';

import { useSelector } from '@hooks/use-selector';

import { styled } from '@src/themes';

type HCMProductsPageProps = {
  modalityId: number;
};

export function HCMProductsPage({ modalityId }: HCMProductsPageProps) {
  const { isLoading: isProductsLoading, modalityList } = useSelector(
    ({ products }) => products,
  );
  const isApplicationLoading = useSelector(
    ({ application }) => application.isLoading,
  );
  const modalityData = getModalityData(modalityId, modalityList);

  if (isApplicationLoading || isProductsLoading) {
    return (
      <PageSection>
        <Skeleton.SectionContainer>
          <Skeleton.BigBox />
          <Skeleton.Title />
          <Skeleton.BigBox />
        </Skeleton.SectionContainer>
      </PageSection>
    );
  }

  return (
    <HCMProductsPageContainer>
      <PageSection
        id={`${modalityData.keyId}-page`}
        variant="secondary"
        css={{
          backgroundColor: '$faqBackgroundColor',
        }}
      >
        <div className="banner-holder">
          <p>PLANOS E VALORES</p>
          <h2>
            Solução completa e digital para sua gestão de{' '}
            <strong>controle de ponto</strong>.
          </h2>
          <p className="description">
            Essa é a melhor oportunidade do ano para automatizar as tarefas
            operacionais de RH e ganhar mais segurança e agilidade na rotina dos
            negócios.
          </p>
        </div>
      </PageSection>
      <HCMPlansSection modalityId={modalityId} />
      <HCMBenefitsSection />
      <HCMCarouselSection />
      <HCMFaqSection />
      <CustomerModal />
    </HCMProductsPageContainer>
  );
}

const HCMProductsPageContainer = styled('div', {
  '.plans-holder': {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    padding: '1rem 0',
    overflow: 'auto',

    '@media screen and (max-width: 1024px)': {
      padding: '1rem',
      justifyContent: 'flex-start',
    },
  },

  '.banner-holder': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    minHeight: '282px',

    padding: '3rem 0 0 0',
    backgroundColor: '$featuredFlagBackground',

    color: '$white',

    p: {
      fontSize: '0.875rem',
      maxWidth: '50%',
    },

    h2: {
      fontSize: '1.675rem',
      fontWeight: 'bold',
      maxWidth: '50%',

      strong: {
        color: '#A6F89D',
      },
    },

    '@deviceMd': {
      fontSize: '1.25rem',

      'p, h2': {
        maxWidth: '100%',
      },
    },

    '@deviceSm': {
      minHeight: '210px',
      paddingTop: '2rem',
      gap: '0.5rem',

      '.description': {
        fontSize: '0.875rem',
        lineHeight: '1.3',
      },

      p: {
        fontSize: '0.75rem',
      },

      h2: {
        fontSize: '1.125rem',
        lineHeight: '1.5',
      },
    },
  },
});
