export const SICRE = {
  colors: {
    primaryPure: '#3FA110',
    primaryLight: '#3FA110',
    primaryDark: '#146E38',

    secondaryPure: '#FFCC00',
    secondaryLight: '#FFCC00',
    secondaryDark: '#FFA929',

    tertiaryPure: '#4A5969',
    tertiaryLight: '#9CAEC1',
    tertiaryDark: '#2E3E4E',

    headerBackgroundColor: '$white',
    footerBackgroundColor: '$primaryPure',
    sectionBackgroundSecondaryColor: '$primaryPure',

    multiPrimaryPure: '#146E38',
    multiPrimaryDark: '#146E38',
    multiPrimaryLight: '#C7FE9A',
    multiSecondaryPure: '#DEFF33',
    multiSecondaryDark: '#7A9108',
    multiSecondaryLight: '#EFFF99',
    multiFontColor: '#4A5969',
    alimentacaoPrimaryPure: '#25894C',
    alimentacaoPrimaryDark: '#146E38',
    alimentacaoSecondaryPure: '#FFA929',
    refeicaoPrimaryPure: '#5A645A',
    refeicaoPrimaryDark: '#323C32',
    refeicaoSecondaryPure: '#FFA929',
    autoPrimaryPure: '#D7E6C8',
    autoPrimaryDark: '#BFD5A8',
    autoSecondaryPure: '#FFA929',
    autoColorName: '#4F5B5D',
    boasFestasPrimaryPure: '#CB9D3E',
    boasFestasPrimaryDark: '#A37E30',

    switchBackgroundInitial: '#A9B6C1',
    switchInnerBackgroundInitial: '#5B6F80',
    switchBackgroundEnd: '$primaryPure',
    switchInnerBackgroundEnd: '#FFFFFF',

    featuredFlagBackground: '$primaryDark',
    walletBackground: '#FFFFFF',
    walletFontColor: '#4A5969',
    faqBackgroundColor: '$primaryDark',

    // Buttons - Primary Tokens
    buttonPrimaryBackgroundColor: '$primaryPure',
    buttonPrimaryBorderColor: '$primaryPure',
    buttonPrimaryTextColor: '$white',
    buttonPrimaryBackgroundColorHover: '$primaryDark',
    buttonPrimaryBorderColorHover: '$primaryDark',
    buttonPrimaryTextColorHover: '$white',

    buttonPrimaryOutlineBackgroundColor: 'transparent',
    buttonPrimaryOutlineBorderColor: '$primaryPure',
    buttonPrimaryOutlineTextColor: '$primaryPure',
    buttonPrimaryOutlineBackgroundColorHover: '$primaryDark',
    buttonPrimaryOutlineBorderColorHover: '$primaryDark',
    buttonPrimaryOutlineTextColorHover: '$white',

    buttonPrimaryNegativeBackgroundColor: '$white',
    buttonPrimaryNegativeBorderColor: '$white',
    buttonPrimaryNegativeTextColor: '$primaryPure',
    buttonPrimaryNegativeBackgroundColorHover: '$primaryDark',
    buttonPrimaryNegativeBorderColorHover: '$primaryDark',
    buttonPrimaryNegativeTextColorHover: '$white',

    buttonPrimaryDisabledBackgroundColor: '$disabledPure',
    buttonPrimaryDisabledBorderColor: '$disabledPure',
    buttonPrimaryDisabledTextColor: '$disabledFontPure',
    buttonPrimaryDisabledBackgroundColorHover: '$disabledPure',
    buttonPrimaryDisabledBorderColorHover: '$disabledPure',
    buttonPrimaryDisabledTextColorHover: '$disabledFontPure',

    // Buttons - Secondary Tokens
    buttonSecondaryBackgroundColor: '$secondaryPure',
    buttonSecondaryBorderColor: '$secondaryPure',
    buttonSecondaryTextColor: '$primaryDark',
    buttonSecondaryBackgroundColorHover: '$secondaryDark',
    buttonSecondaryBorderColorHover: '$secondaryDark',
    buttonSecondaryTextColorHover: '$primaryDark',

    buttonSecondaryOutlineBackgroundColor: 'transparent',
    buttonSecondaryOutlineBorderColor: '$secondaryPure',
    buttonSecondaryOutlineTextColor: '$secondaryPure',
    buttonSecondaryOutlineBackgroundColorHover: '$secondaryDark',
    buttonSecondaryOutlineBorderColorHover: '$secondaryDark',
    buttonSecondaryOutlineTextColorHover: '$primaryDark',

    buttonSecondaryNegativeBackgroundColor: '$white',
    buttonSecondaryNegativeBorderColor: '$white',
    buttonSecondaryNegativeTextColor: '$secondaryPure',
    buttonSecondaryNegativeBackgroundColorHover: '$secondaryDark',
    buttonSecondaryNegativeBorderColorHover: '$secondaryDark',
    buttonSecondaryNegativeTextColorHover: '$white',

    buttonSecondaryDisabledBackgroundColor: '$disabledPure',
    buttonSecondaryDisabledBorderColor: '$disabledPure',
    buttonSecondaryDisabledTextColor: '$disabledFontPure',
    buttonSecondaryDisabledBackgroundColorHover: '$disabledPure',
    buttonSecondaryDisabledBorderColorHover: '$disabledPure',
    buttonSecondaryDisabledTextColorHover: '$disabledFontPure',

    alimentacaoColorName: '$alimentacaoPrimaryPure',
    refeicaoColorName: '$refeicaoPrimaryPure',
    transporteColorName: '$transportePrimaryPure',
    culturaColorName: '$culturaPrimaryPure',
    homeOfficeColorName: '$homeOfficePrimaryPure',
    vrvaColorName: '$vrvaPrimaryPure',
    cestaColorName: '$cestaPrimaryPure',
    boasFestasColorName: '$boasFestasPrimaryPure',
    comprasColorName: '$comprasPrimaryPure',
    mobilidadeColorName: '$mobilidadePrimaryPure',
    multiColorName: '$multiPrimaryDark',
    multibeneficiosColorName: '$multibeneficiosPrimaryPure',
    marketplaceColorName: '$marketplacePrimaryPure',
  },
  fonts: {
    default: "'Exo 2', sans-serif",
  },
};
