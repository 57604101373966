import { styled } from '@src/themes';

const SummaryTitleContainer = styled('div', {
  color: '$primaryDark',
  fontSize: '1rem',
  overflow: 'hidden',

  variants: {
    hidden: {
      true: {
        '@deviceLg': {
          height: 0,
          paddingBottom: 0,
        },
      },
    },
  },
  defaultVariants: {
    hidden: false,
  },
});

interface SummaryTitleProps {
  hidden?: boolean;
}

export function SummaryTitle({ hidden }: SummaryTitleProps) {
  return (
    <SummaryTitleContainer hidden={hidden}>
      <h2>Resumo da Compra</h2>
    </SummaryTitleContainer>
  );
}
