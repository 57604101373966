import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

export const TableContainer = styled('table', {
  backgroundColor: '$white',
  width: '100%',
  margin: '0 auto',
  borderRadius: '10px',
  boxShadow: '0px 0px 8px #00000022',

  thead: {
    tr: {
      th: {
        backgroundColor: '$silverPure',
        padding: '1.25rem 0 1.25rem 1.25rem',
        borderRadius: '10px 0 0 0',

        '&.table-view__head-left-content': {
          '> div': {
            display: 'flex',
            alignItems: 'center',

            textAlign: 'left',

            '> img': {
              height: convertPixelToRem(54),
              marginRight: '1rem',
              filter: 'drop-shadow(0px 0px 2px #00000040)',
            },

            '> strong': {
              backgroundColor: '$$color',
              borderRadius: '0.25rem',

              color: '$fontColorPrimary',
              fontWeight: '800',
              whiteSpace: 'nowrap',
            },

            '@deviceSm': {
              fontSize: convertPixelToRem(14),

              '> img': {
                height: convertPixelToRem(40),
              },
            },
          },
        },

        '&.table-view__head-right-content': {
          padding: '1.25rem 1.5rem 1.25rem 0.75rem',
          borderRadius: '0 10px 0 0',

          '@deviceSm': {
            padding: '1.25rem 1.25rem 1.25rem 0.75rem',
          },

          '> div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',

            fontSize: '1rem',
            fontWeight: '700',
            lineHeight: '1',
            whiteSpace: 'nowrap',
            textAlign: 'end',

            span: {
              marginRight: '1.5rem',

              fontSize: convertPixelToRem(14),
              fontWeight: '500',
            },

            '@deviceSm': {
              flexDirection: 'column',
              alignItems: 'flex-end',

              lineHeight: 1,
              whiteSpace: 'normal',

              fontSize: '0.75rem',

              span: {
                marginRight: 0,
                marginBottom: '0.5rem',

                fontSize: '0.75rem',
                lineHeight: 1.25,
                fontWeight: 500,
              },
            },
          },
        },
      },
    },
  },

  tfoot: {
    borderTop: '2px solid $secondaryPure',

    tr: {
      padding: '2rem 1.25rem',

      td: {
        position: 'relative',

        backgroundColor: '$primaryPure',
        padding: '2rem',

        color: '$white',
        fontSize: '1rem',
        fontWeight: 800,

        '&:first-child': {
          padding: '2rem 0 2rem 1.25rem',
          borderRadius: '0 0 0 10px',
        },

        '&:last-child': {
          padding: '2rem 1.25rem 2rem 0',
          borderRadius: '0 0 10px 0',

          textAlign: 'right',
        },

        '> i': {
          display: 'none',
        },

        '@deviceSm': {
          fontSize: convertPixelToRem(14),

          '> i': {
            display: 'block',
            position: 'absolute',
            right: '23px',
            top: '0px',

            backgroundColor: '$secondaryPure',
            padding: '3px',
            borderRadius: '50px',
            transform: 'translateY(-50%)',

            color: '$primaryDark',
            fontSize: '10px',
            textAlign: 'center',
          },
        },
      },
    },
  },
});
