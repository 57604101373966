import { HTMLAttributes, useState } from 'react';

import { RateArticleType } from '@extra-types/rate-article-type';
import { convertPixelToRem } from '@helpers/style-helpers';

import { useContactModalContext } from '@hooks/use-contact-modal-context';
import useDefaultHeader from '@hooks/use-default-header';

import faqService from '@api/services/faq-service';

import { styled } from '@src/themes';

interface FAQRateArticleProps extends HTMLAttributes<HTMLDivElement> {
  articleId: string;
}

export function FAQRateArticle({ articleId, ...props }: FAQRateArticleProps) {
  const localArticleId = `article-${articleId}`;
  const { handleContactModal } = useContactModalContext();
  const [articleRate, setArticleRate] = useState<string | undefined>(
    localStorage.getItem(localArticleId) || undefined,
  );
  const { defaultHeader } = useDefaultHeader();

  const handleFAQRateArticle = (rate: RateArticleType) => {
    localStorage.setItem(localArticleId, rate);
    faqService.rateArticle(articleId, rate, defaultHeader);
    setArticleRate(rate);
  };

  if (articleRate === 'like') {
    return (
      <FAQRateArticleResponseContainer
        data-testid="like-button"
        variant="like"
        {...props}
      >
        <i className="fa-regular fa-check" />
        <span>Agradecemos seu feedback!</span>
      </FAQRateArticleResponseContainer>
    );
  }

  if (articleRate === 'deslike') {
    return (
      <FAQRateArticleResponseContainer
        data-testid="dislike-button"
        variant="deslike"
        {...props}
      >
        <p>
          <span>=(</span> Que pena... você pode acessar nosso chat{' '}
          <SuportButton type="button" onClick={handleContactModal}>
            clicando aqui
          </SuportButton>{' '}
          para tirar sua dúvida.
        </p>
      </FAQRateArticleResponseContainer>
    );
  }

  return (
    <FAQRateArticleContainer data-testid="faq-rate-article" {...props}>
      <span>Essa resposta lhe foi útil?</span>
      <RateButton
        type="button"
        variant="like"
        onClick={() => handleFAQRateArticle('like')}
      >
        <i className="fa-regular fa-thumbs-up" />
      </RateButton>
      <RateButton
        type="button"
        variant="deslike"
        onClick={() => handleFAQRateArticle('deslike')}
      >
        <i className="fa-regular fa-thumbs-down" />
      </RateButton>
    </FAQRateArticleContainer>
  );
}

const FAQRateArticleContainer = styled('div', {
  display: 'flex',
  gap: '1.5rem',
  alignItems: 'center',

  span: {
    color: '$rateColor',
    fontSize: convertPixelToRem(14),
    fontWeight: '$semibold',
  },
});

const FAQRateArticleResponseContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',

  variants: {
    variant: {
      like: {
        span: {
          color: '$primaryPure',
          fontSize: convertPixelToRem(14),
          fontWeight: '$semibold',
        },

        i: {
          color: '$primaryPure',
          fontSize: '1.5rem',
        },
      },
      deslike: {
        p: {
          span: {
            marginRight: '0.5rem',

            fontWeight: '$semibold',
          },
        },
      },
    },
  },
});

const RateButton = styled('button', {
  i: {
    color: '$rateColor',
    fontSize: convertPixelToRem(18),
    transition: 'color 0.4s',
  },

  variants: {
    variant: {
      like: {
        '&:hover': {
          i: {
            color: '$likeColor',
          },
        },
      },
      deslike: {
        '&:hover': {
          i: {
            color: '$deslikeColor',
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'like',
  },
});

const SuportButton = styled('button', {
  padding: 0,
  margin: 0,

  color: '$primaryPure',
  fontSize: convertPixelToRem(14),
  fontWeight: '$semibold',
  textDecoration: 'underline',
});
