/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

import { DtrumApi } from '@dynatrace/dtrum-api-types';
import Cookies from 'js-cookie';

import { COOKIES_CLIENT_ID_LABEL } from '@config/application-config';
import { GTM_QA } from '@config/gtm-config';
import { initializeAnalytics } from '@helpers/analytics-helpers';
import { Environment } from '@helpers/enum/environment';
import { getEnvironment } from '@helpers/environment-helpers';
import { hotjarWorkaround } from '@helpers/style-helpers';

import { useDispatch } from '@hooks/use-dispatch';
import { fetchApplicationConfig } from '@store/thunks/application-thunk';

import Routes from './routes';
import { globalStyles } from './styles/global';
import 'swiper/css';
import 'swiper/css/grid';

declare global {
  interface Window {
    renderBotWidget: (id: string) => void;
    toggleChat: () => void;
    chatOpen: boolean;
  }
}

declare global {
  interface Window {
    dtrum?: DtrumApi;
  }
}

function App() {
  globalStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApplicationConfig());
    initializeAnalytics();
    hotjarWorkaround();

    const clientID = Cookies.get(COOKIES_CLIENT_ID_LABEL);

    if (window.dtrum && clientID) {
      window.dtrum.sendSessionProperties(
        undefined,
        undefined,
        { lojavr_google_client_id: clientID },
        undefined,
      );
    }

    if (getEnvironment() === Environment.QA) {
      const bodyElement = document.querySelector('body');
      const gtmNoScript = document.createElement('noscript');

      const gtmIframe = document.createElement('iframe');

      gtmIframe.title = GTM_QA;
      gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_QA}`;
      gtmIframe.height = '0';
      gtmIframe.width = '0';
      gtmIframe.style.display = 'none';
      gtmIframe.style.visibility = 'hidden';

      gtmNoScript.appendChild(gtmIframe);
      bodyElement?.appendChild(gtmNoScript);
    }

    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      if (window.location.pathname !== '/') {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
        if (window.dtrum) {
          window.dtrum.endSession();
        }
      }

      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);

    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);

  return <Routes />;
}

export default App;
