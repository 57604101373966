import { styled } from '@src/themes';

export const ProductListContainer = styled('div', {
  width: '100%',
  borderRadius: '6px',
  boxShadow: '0px 0px 8px #00000021',
  display: 'flex',
  flexDirection: 'column',
  gap: '1px',

  fontSize: '0.75rem',
});

export const WalletFormContainer = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});
