import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class ProductNotComercializedRegionInformedError extends ApplicationError {
  constructor(message?: string) {
    super(
      SpecificsApiErrors.PRODUCTS_NOT_COMMERCIALIZED_IN_REGION_INFORMED,
      message || ApiErrorsMessages.DEFAULT,
    );
  }
}
