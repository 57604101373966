/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable no-plusplus */
/* eslint-disable no-extend-native */
import { QUERY_PARAM_INITIAL_PAGE_BLACK_LIST } from '@config/application-config';
import { StackItemType } from '@extra-types/stack-item-type';

import { getEmissor } from './emissor-helpers';
import { Emissor } from './enum/emissor';
import { ModalitiesId } from './enum/modalities-id';
import { removeSpecificQueryParamFromSearch } from './query-string-helpers';

export const contractFlux: Record<ModalitiesId | string, string[]> =
  {} as Record<ModalitiesId | string, string[]>;

const getWalletByEmissor = () => {
  return getEmissor().initials === Emissor.JVCEF
    ? ModalitiesId.MULTI_JVCEF
    : ModalitiesId.MULTI;
};

const defaultFluxRoutes = [
  '/pedido/carrinho',
  '/pedido/dados-empresa',
  '/pedido/revisar-pedido',
  '/pedido/checkout',
];

const comboFluxRoutes = [
  '/combo/carrinho',
  '/combo/empresa',
  '/combo/pedido',
  '/combo/checkout',
];

const mobilityFluxRoutes = [
  '/mobilidade/contrato',
  '/compra/mobilidade/pagamento',
];

export const modalityFluxMapping: { [key: string]: ModalitiesId } = {
  '/mobilidade/vale-transporte': ModalitiesId.MOBILIDADE,
  '/modalidade/avulso': ModalitiesId.DEFAULT,
  '/modalidade/multisaldo': getWalletByEmissor(),
  '/modalidade/pontomais': ModalitiesId.HCM,
};

export function handleProductPageFlux(currentPath: string) {
  contractFlux.productPage = [currentPath];
  defaultFluxRoutes.forEach(route => {
    contractFlux.productPage.push(route);
  });
}

function verifyCurrentPath(currentPath: string) {
  return defaultFluxRoutes.some(path => path === currentPath);
}

export function handleUserFlux(currentPath: string) {
  const currentModality = modalityFluxMapping[currentPath] || 0;

  contractFlux.combo = ['/'];
  comboFluxRoutes.forEach(route => {
    contractFlux.combo.push(route);
  });

  if (currentModality === ModalitiesId.MOBILIDADE) {
    contractFlux[currentModality] = [currentPath];
    mobilityFluxRoutes.forEach(route => {
      contractFlux[currentModality].push(route);
    });
  } else if (currentModality !== undefined && !verifyCurrentPath(currentPath)) {
    contractFlux[currentModality] = [currentPath];

    defaultFluxRoutes.forEach(route => {
      contractFlux[currentModality].push(route);
    });
  } else {
    contractFlux[ModalitiesId.DEFAULT] = [];
    defaultFluxRoutes.forEach(route => {
      contractFlux[currentModality].push(route);
    });
  }
}

export const verifyFluxOrder = (currentPath: string, previousPath: string) => {
  const previousIndex = defaultFluxRoutes.findIndex(
    path => path === previousPath,
  );
  const currentIndex = defaultFluxRoutes.findIndex(
    path => path === currentPath,
  );

  if (previousIndex > currentIndex) {
    return { blockedStep: true, path: defaultFluxRoutes[currentIndex - 1] };
  }
  return { blockedStep: false, path: defaultFluxRoutes[previousIndex] };
};

export function removeDuplicatesOf(arr: StackItemType[]) {
  return [...new Set(arr)];
}

export function removeBlockQueryParamFromInitialPage(search: string) {
  let newSearch = '';

  if (search !== '') {
    QUERY_PARAM_INITIAL_PAGE_BLACK_LIST.forEach(queryParam => {
      newSearch = removeSpecificQueryParamFromSearch({
        search,
        queryString: queryParam,
      });
    });
  }

  return newSearch;
}

export function clearPathname(pathname: string) {
  return pathname[pathname.length - 1] === '/'
    ? pathname.substring(0, pathname.length - 1)
    : pathname;
}
