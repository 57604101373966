import { BonusItemDetailProduct } from '@elements/bonus-item-detail-product';

import { EntryBonusRuleType } from '@extra-types/bonus-type';
import { convertPixelToRem, hexToRGB } from '@helpers/style-helpers';

import { styled, theme } from '@src/themes';

const MarketplaceDescriptionPurchaseContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',

  '> p': {
    color: '$fontColorPrimary',
    fontSize: '1rem',
    lineHeight: '1.5',

    '&.observation': {
      fontSize: '0.875rem',
      fontWeight: '400',
    },

    '@deviceSm': {
      fontSize: '0.875rem',

      '&.observation': {
        fontSize: '0.75rem',
        fontWeight: '400',
      },
    },
  },

  ul: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',

    background: hexToRGB(theme.colors.primaryPure.value, 0.08),
    padding: '1rem',

    li: {
      fontSize: convertPixelToRem(14),
    },

    '@deviceSm': {
      li: {
        fontSize: '0.75rem',
      },
    },
  },
});

export interface MarketplaceDescriptionPurchaseProps {
  productBonusDataList: EntryBonusRuleType[];
}

export function MarketplaceDescriptionPurchase({
  productBonusDataList,
}: MarketplaceDescriptionPurchaseProps) {
  return (
    <MarketplaceDescriptionPurchaseContainer>
      <p>
        Olá! Você acaba de pontuar no VR Marketplace, veja abaixo como ficou o
        seu acumulo de pontos:
      </p>
      <ul className="bonus-item__rule">
        {[...productBonusDataList]
          .sort((a, b) => {
            return b.unitaryValue - a.unitaryValue;
          })
          .map(productBonusData => (
            <BonusItemDetailProduct
              key={productBonusData.unitaryValue}
              data={productBonusData}
            />
          ))}
      </ul>
      <p className="observation">
        *Os pontos serão computados até 3 dias depois do pagamento e faturamento
        do boleto.
      </p>
    </MarketplaceDescriptionPurchaseContainer>
  );
}

export default MarketplaceDescriptionPurchase;
