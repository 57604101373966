import { Navigate, useLocation } from 'react-router-dom';

import { PageTitle } from '@components/page-title';
import { Skeleton } from '@components/skeletons';
import { PageContainer } from '@elements/page-container';
import { Title } from '@elements/title';
import { ComboCartSummarySection } from '@pages/offer-page/custom-offer-pages/compositions/combo-cart-summary-section';
import { ComboProductServicesFormList } from '@pages/offer-page/custom-offer-pages/compositions/combo-product-services-form-list';

import { stripHtmlTags } from '@helpers/function-helpers';

import { useSelector } from '@hooks/use-selector';
import { ComboCartPageContextProvider } from '@store/context/combo-cart-page-context';

import { OfferFacade } from '@src/facade/offer-facade';

import { ComboCartPageHolder } from './styles';

const SUBTITLE_TEXT = 'Combine serviços e tenha vantagens exclusivas!';

export function ComboCartPage() {
  const location = useLocation();
  const { selectedOffer } = OfferFacade();
  const { initialPage } = useSelector(({ application }) => application);
  const { isLoading: isProductLoading } = useSelector(
    ({ products }) => products,
  );
  const { companyData } = useSelector(({ customer }) => customer);
  const { isLoading: isApplicationLoading, config } = useSelector(
    ({ application }) => application,
  );

  if (isApplicationLoading || isProductLoading) {
    return (
      <PageContainer horizontalHolder headerPadding={false}>
        <ComboCartPageHolder>
          <Skeleton.GroupContainer>
            <Skeleton.Title />
            <Skeleton.BigBox />
            <Skeleton.BigBox />
          </Skeleton.GroupContainer>
          <Skeleton.GroupContainer>
            <Skeleton.Line height={20} />
            <Skeleton.Line height={46} />
            <Skeleton.Line height={46} />
            <Skeleton.Line height={46} />
          </Skeleton.GroupContainer>
        </ComboCartPageHolder>
      </PageContainer>
    );
  }

  if (!companyData.lead) {
    return (
      <Navigate
        to={{
          pathname: selectedOffer ? '/oferta' : initialPage,
          search: location.search,
        }}
        state={{ from: location.pathname }}
      />
    );
  }

  return (
    <ComboCartPageContextProvider>
      <PageTitle title="Monte seu combo" />
      <PageContainer headerPadding={false} horizontalHolder>
        <ComboCartPageHolder>
          <div>
            <Title.Root size="md" barColor="secondary">
              <Title.Default tag="h2">
                Selecione as soluções {config.emissor.shortName}
              </Title.Default>
              <Title.Subtitle>
                {selectedOffer
                  ? stripHtmlTags(selectedOffer.descricaoOferta)
                  : SUBTITLE_TEXT}
              </Title.Subtitle>
            </Title.Root>
            <ComboProductServicesFormList />
          </div>
          <ComboCartSummarySection />
        </ComboCartPageHolder>
      </PageContainer>
    </ComboCartPageContextProvider>
  );
}
