import { createContext, useContext, useMemo, useState } from 'react';

interface ContractContextData {
  contractArrayBuffer: string | Uint8Array;
  setContractArrayBuffer: React.Dispatch<
    React.SetStateAction<string | Uint8Array>
  >;
}

const ContractContext = createContext<ContractContextData>(
  {} as ContractContextData,
);

interface ContractContextProviderProps {
  children: React.ReactNode;
}

export function ContractContextProvider({
  children,
  ...props
}: ContractContextProviderProps) {
  const [contractArrayBuffer, setContractArrayBuffer] = useState<
    string | Uint8Array
  >('');

  const memorizeReturn = useMemo(
    () => ({
      contractArrayBuffer,
      setContractArrayBuffer,
    }),
    [contractArrayBuffer, setContractArrayBuffer],
  );

  return (
    <ContractContext.Provider value={memorizeReturn} {...props}>
      {children}
    </ContractContext.Provider>
  );
}

export function useContractArrayBuffer(): ContractContextData {
  const context = useContext(ContractContext);

  if (!context) {
    throw new Error(
      'useContractArrayBuffer must be used within a ContractContext.',
    );
  }

  return context;
}
