import { ModalityLegalFlag } from '@elements/legal-flag';
import { ProductImage } from '@elements/product-image';

import { ModalityType } from '@extra-types/modality-type';
import { removeModalityPrefix } from '@helpers/modality-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

type ModalityInformationProps = {
  modalityData: ModalityType;
  size?: 'lg' | 'md';
  responsiveColumn?: boolean;
};

export function ModalityInformation({
  modalityData,
  size = 'lg',
  responsiveColumn = false,
}: ModalityInformationProps) {
  return (
    <ModalityInformationContainer
      responsiveColumn={responsiveColumn}
      size={size}
      css={{
        $$modalityColor: `$colors$${modalityData.colorPrefixToken}PrimaryDark`,
      }}
    >
      <div className="modality-information__product-image">
        <ProductImage productId={modalityData.id} />
        {modalityData.showLegalFlag && (
          <ModalityLegalFlag
            modalityId={removeModalityPrefix(modalityData.keyId)}
            className="legal-flag-image"
          />
        )}
      </div>
      <p>{modalityData.shelfInformation.title}</p>
    </ModalityInformationContainer>
  );
}

const ModalityInformationContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',

  '.modality-information__product-image': {
    position: 'relative',

    'img:not(.legal-flag-image)': {
      maxWidth: '104px',
    },

    '.legal-flag-image': {
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'translate(-30%, -30%)',

      width: convertPixelToRem(50),
      height: convertPixelToRem(50),
    },
  },

  p: {
    color: '$$modalityColor',
    fontSize: '1.5rem',
    fontWeight: '$bold',
    whiteSpace: 'nowrap',
  },

  '> div': {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },

  variants: {
    size: {
      lg: {
        '.modality-information__product-image': {
          'img:not(.legal-flag-image)': {
            maxWidth: '104px',
          },
          '.legal-flag-image': {
            width: convertPixelToRem(50),
            height: convertPixelToRem(50),
          },
        },
        p: {
          fontSize: '1.5rem',
        },
      },
      md: {
        '.modality-information__product-image': {
          'img:not(.legal-flag-image)': {
            maxWidth: '80px',
          },
          '.legal-flag-image': {
            width: convertPixelToRem(46),
            height: convertPixelToRem(46),
          },
        },
        p: {
          fontSize: '1.5rem',
        },

        '@deviceMd': {
          '.modality-information__product-image': {
            'img:not(.legal-flag-image)': {
              maxWidth: '72px',
            },
            '.legal-flag-image': {
              width: convertPixelToRem(42),
              height: convertPixelToRem(42),
            },
          },
          p: {
            fontSize: '1rem',
          },
        },

        '@deviceSm': {
          '.modality-information__product-image': {
            'img:not(.legal-flag-image)': {
              maxWidth: '64px',
            },
            '.legal-flag-image': {
              width: convertPixelToRem(40),
              height: convertPixelToRem(40),
            },
          },
          p: {
            fontSize: '0.75rem',
          },
        },
      },
    },
    responsiveColumn: {
      true: {
        '.modality-information__product-image': {
          filter: 'drop-shadow(0px 0px 2px #00000020)',
        },
        '@deviceMd': {
          flexDirection: 'column',
          marginTop: '-3.5rem',
          gap: '1rem',
        },

        '@deviceSm': {
          marginTop: '-3.25rem',
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    size: 'lg',
  },
});
