import { getSingularOrPlural } from '@helpers/string-helpers';
import { convertPixelToRem } from '@helpers/style-helpers';

import { OfferFacade } from '@src/facade/offer-facade';
import { styled } from '@src/themes';

export function SummaryOfferValidation({
  enableMargin = false,
}: {
  enableMargin?: boolean;
}) {
  const { selectedOffer } = OfferFacade();

  if (!selectedOffer) {
    return null;
  }

  return (
    <SummaryOfferValidationContainer enableMargin={enableMargin}>
      <i className="fa-regular fa-circle-xmark" />
      <p>
        Faltam:{' '}
        <span>
          {selectedOffer.quantidadeColaboradoresMinima}{' '}
          {getSingularOrPlural(
            selectedOffer.quantidadeColaboradoresMinima || 0,
            'cartão',
            'cartões',
          )}
        </span>{' '}
        / <span>R${selectedOffer.valorMinimo || 0} cada</span>
      </p>
    </SummaryOfferValidationContainer>
  );
}

const SummaryOfferValidationContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',

  backgroundColor: '#FFCBCD',
  width: '100%',
  padding: convertPixelToRem(6),
  borderRadius: '0.5rem',
  boxShadow: '0px 4px 8px #1B212626',

  color: '$fontColorDark',
  fontSize: '0.75rem',

  i: {
    color: '#F93A1A',
    fontSize: '1.5rem',
    lineHeight: 1,
  },
  variants: {
    enableMargin: {
      true: {
        marginBottom: '1rem',

        '@media only screen and (max-width: 1114px)': {
          margin: '0 1rem 1rem',
        },
      },
    },
  },
});
