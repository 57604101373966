export enum CustomerType {
  NEW = 'new',
  CUSTOMER = 'customer',
  ANOTHER_CHANNEL = 'anotherChannel',
  ALL_PRODUCTS_CONTRACTED = 'allProductsContracted',
  OFFER_RESTRICTION = 'offerRestriction',
  UNSERVED_REGION = 'unservedRegion',
  HAS_AVAILABLE_PRODUCTS = 'hasAvailableProducts',
  BLOCK_LIST = 'blockList',
  DOWNGRADE = 'downgrade',
}
