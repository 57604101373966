import { PageSectionVariantType } from '@elements/page-section';

import { convertPixelToRem } from '@helpers/style-helpers';

import { styled } from '@src/themes';

const rowProperties = (condition: boolean) => {
  return condition
    ? {
        class: 'green',
        icon: 'check-square',
      }
    : {
        class: 'red',
        icon: 'window-close',
      };
};

interface ComparinsonTableProps {
  columnTitles: {
    left: string;
    right: string;
  };
  itemRowList: {
    id: string;
    title: JSX.Element;
    left: boolean;
    right: boolean;
  }[];
  columnGap?: number;
  variant?: PageSectionVariantType;
}

export function ComparinsonTable({
  columnTitles,
  itemRowList,
  columnGap,
  variant = 'primary',
}: ComparinsonTableProps) {
  return (
    <ComparinsonTableContainer
      variant={variant}
      css={
        columnGap
          ? {
              borderSpacing: `${columnGap}px 0`,
              '@deviceMd': { borderSpacing: '2rem 0' },
              '@deviceSm': { borderSpacing: '12px 0' },
            }
          : {}
      }
    >
      <thead>
        <tr>
          <th scope="col">
            <div />
          </th>
          <th scope="col">
            <div className="comparasion-table__column-title">
              {columnTitles.left}
            </div>
          </th>
          <th scope="col">
            <div className="comparasion-table__column-title">
              {columnTitles.right}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {itemRowList.map(itemRow => {
          return (
            <tr key={itemRow.id}>
              <td>
                <div className="comparasion-table__row-title">
                  {itemRow.title}
                </div>
              </td>
              <td>
                <div
                  data-testid={`row-icon-${itemRow.id}-left`}
                  className={`comparasion-table__row-icon comparasion-table__row-icon--${
                    rowProperties(itemRow.left).class
                  }`}
                >
                  <i className={`far fa-${rowProperties(itemRow.left).icon}`} />
                </div>
              </td>
              <td>
                <div
                  data-testid={`row-icon-${itemRow.id}-right`}
                  className={`comparasion-table__row-icon comparasion-table__row-icon--${
                    rowProperties(itemRow.right).class
                  }`}
                >
                  <i
                    className={`far fa-${rowProperties(itemRow.right).icon}`}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </ComparinsonTableContainer>
  );
}

const ComparinsonTableContainer = styled('table', {
  alignSelf: 'center',

  '.comparasion-table__column-title': {
    maxWidth: convertPixelToRem(182),
    padding: '0.75rem 0.5rem',

    color: '$black',
    fontSize: '0.75rem',
    fontWeight: '$bold',
  },

  '.comparasion-table__row-title': {
    padding: '0.75rem 0.5rem',

    color: '$black',
    fontSize: '0.75rem',
    lineHeight: '1.5',
  },

  '.comparasion-table__row-icon': {
    display: 'flex',
    justifyContent: 'center',

    fontSize: '1.25rem',

    '&--green': {
      color: '$likeColor',
    },

    '&--red': {
      color: '$deslikeColor',
    },
  },
  variants: {
    variant: {
      primary: {
        '.comparasion-table__column-title': {
          color: '$black',
        },

        '.comparasion-table__row-title': {
          color: '$black',
        },
      },
      secondary: {
        '.comparasion-table__column-title': {
          color: '$white',
        },

        '.comparasion-table__row-title': {
          color: '$white',
        },
      },
      tertiary: {
        '.comparasion-table__column-title': {
          color: '$white',
        },

        '.comparasion-table__row-title': {
          color: '$white',
        },
      },
    },
  },
});
