import { Navigate, useLocation, useParams } from 'react-router-dom';

import Modal, { ModalVariants } from '@components/modal';
import { PageTitle } from '@components/page-title';
import { ProductAdvantages } from '@compositions/product-page-compositions/product-advantages';
import { ProductBenefits } from '@compositions/product-page-compositions/product-benefits';
import { PATAuxComparasionSection } from '@compositions/sections/pat-aux-comparasion-section';
import { PageContainer } from '@elements/page-container';

import { INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX } from '@config/products-config';
import { removeSpecialCaracters } from '@helpers/function-helpers';
import { getProductByPagePath } from '@helpers/product-helpers';

import { useAnchorSmooth } from '@hooks/use-anchor-smooth';
import { useSelector } from '@hooks/use-selector';

export function ProductPage() {
  const { productName } = useParams();
  const location = useLocation();
  const {
    config: { emissor },
    initialPage,
  } = useSelector(({ application }) => application);
  const { productList, isLoading } = useSelector(({ products }) => products);
  useAnchorSmooth({ watchVariable: isLoading });

  const productData = productName
    ? getProductByPagePath(
        `${INDIVIDUAL_PRODUCT_PAGE_PATH_PREFIX}${productName}`,
        emissor.initials,
        productList,
      )
    : undefined;

  if (isLoading) {
    return (
      <PageContainer>
        <ProductBenefits.Loading />
        <ProductAdvantages.Loading />
      </PageContainer>
    );
  }

  if (productName === undefined || productData === undefined) {
    return (
      <Navigate
        to={{
          pathname: initialPage,
          search: location.search,
        }}
        state={{ from: location.pathname }}
      />
    );
  }

  return (
    <>
      <PageTitle title={productData.name} />
      <PageContainer headerPadding={false}>
        <ProductBenefits.Default productData={productData} />
        <ProductAdvantages.Default productData={productData} />
        {productData.showAsteriskPatAux && (
          <PATAuxComparasionSection
            contractIDSection={`hero-${removeSpecialCaracters(
              productData.name,
            )}`}
          />
        )}
      </PageContainer>

      <Modal>
        <ModalVariants.Confirmation />
      </Modal>
    </>
  );
}
