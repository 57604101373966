import { MODALITIES } from '@config/modality-config';
import { PricingDetailProductType } from '@extra-types/pricing-types';
import {
  getProductListByModality,
  getProductPricedFullDataList,
} from '@helpers/product-helpers';

import { useSelector } from '@hooks/use-selector';

import { OrderRevisionTableBenefitServiceSingle } from './benefit-service-modalities/order-revision-table-benefit-service-single';
import { OrderRevisionTableBenefitServiceWallet } from './benefit-service-modalities/order-revision-table-benefit-service-wallet';

interface OrderRevisionServiceTableContentProps {
  productPricedList: PricingDetailProductType[];
  isCollapsed: boolean;
}

export function OrderRevisionServiceTableContent({
  productPricedList,
  isCollapsed,
}: OrderRevisionServiceTableContentProps) {
  const { productList: apiProductList } = useSelector(
    ({ products }) => products,
  );

  const productPricedFullInformationList = getProductPricedFullDataList(
    productPricedList,
    apiProductList,
  );

  const modalityDataList = [
    ...new Set(
      productPricedFullInformationList.map(
        apiProductPriced => apiProductPriced.modalityId,
      ),
    ),
  ].map(modalityId => MODALITIES[modalityId]);

  return (
    <>
      {modalityDataList.map(modalityData => {
        const apiProductListByModality = getProductListByModality(
          modalityData.id,
          productPricedFullInformationList,
        );
        return modalityData.isWalletGroupCard ? (
          <OrderRevisionTableBenefitServiceWallet
            key={modalityData.id}
            modalityData={modalityData}
            productPricedFullDataList={apiProductListByModality}
            isCollapsed={isCollapsed}
          />
        ) : (
          <OrderRevisionTableBenefitServiceSingle
            key={modalityData.id}
            modalityData={modalityData}
            productPricedFullDataList={apiProductListByModality}
            isCollapsed={isCollapsed}
          />
        );
      })}
    </>
  );
}
