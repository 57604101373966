import { env } from '@config/env';
import { ProductAddonType } from '@extra-types/product-addon-type';

import { styled } from '@src/themes';

import { ProductReferenceIcon } from './product-reference-icon';

export function OrderRevisionServiceTableAddonItem({
  data,
}: {
  data: ProductAddonType;
}) {
  return (
    <OrderRevisionServiceTableAddonItemContainer>
      <div className="order-revision-table-benefit-service-addon-item__information-holder">
        <ProductReferenceIcon className="information-holder__reference-product-icon" />
        <img
          src={env.URL_ASSETS + data.logoPath}
          alt={`Logotipo do adicional ${data.name}`}
        />
        <div className="information-holder__text-group">
          <p className="title">{data.name}</p>
          <p>{data.description}</p>
        </div>
      </div>
      <div className="order-revision-table-benefit-service-addon-item__value-holder">
        {data.value && data.label && (
          <span className="value-holder__award-value">{`${data.value} ${data.label}`}</span>
        )}
      </div>
    </OrderRevisionServiceTableAddonItemContainer>
  );
}

const OrderRevisionServiceTableAddonItemContainer = styled('li', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',

  width: '100%',
  padding: '0.5rem 0',

  '& + li:not(li.awards__title-line)': {
    borderTop: '1px solid #E4E8ED',
  },

  '.order-revision-table-benefit-service-addon-item__information-holder': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    img: {
      flexShrink: 1,

      width: '36px',
      objectFit: 'contain',
    },

    '.information-holder__text-group': {
      p: {
        fontSize: '0.75rem',
        fontWeight: '$medium',
        lineHeight: 1.4,

        '&.title': {
          fontWeight: '$bold',
          marginBottom: '0.25rem',
        },
      },
    },
  },

  '.order-revision-table-benefit-service-addon-item__value-holder': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',

    span: {
      color: '$primaryPure',
      fontSize: '1rem',
      fontWeight: '$bold',
      whiteSpace: 'nowrap',
    },

    '.value-holder__award-value': {
      background: '$silverPure',
      padding: '0.5rem 0.75rem',
      borderRadius: '0.5rem',

      color: '$fontColorPrimary',
      fontSize: '0.75rem',
      fontWeight: '$semibold',
      lineHeight: 1.8,
    },
    '@deviceSm': {
      justifyContent: 'space-between',
      width: '100%',

      '.value-holder__award-value': {
        marginLeft: '52px',
      },
    },
  },

  '@deviceSm': {
    flexDirection: 'column',
    alignItems: 'start',
    gap: '0.5rem',

    '.information-holder__reference-product-icon': {
      display: 'none',
    },

    '.order-revision-table-benefit-service-addon-item__information-holder': {
      alignItems: 'start',
    },
  },
});
