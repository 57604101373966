import { ApiErrorsMessages } from '@helpers/enum/api-error-messages';

import SpecificsApiErrors from '@errors/specifics-api-errors';

import ApplicationError from './application-error';

export default class InvalidFieldError extends ApplicationError {
  constructor(message?: string) {
    super(
      SpecificsApiErrors.INVALID_FIELD,
      message || ApiErrorsMessages.INVALID_FIELD,
    );
  }
}
