/* eslint-disable sonarjs/cognitive-complexity */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { MIN_LIFES_ESSENTIAL_PLAN } from '@config/hcm-config';
import { EXCLUDE_REASONS } from '@config/products-config';
import { ProductCartType } from '@extra-types/product-cart-type';
import { ProductPricing } from '@extra-types/product-pricing';
import { convertProductCartListToProductPricingList } from '@helpers/cart-helpers';
import { CustomerType } from '@helpers/enum/customer-type';
import { ModalitiesId } from '@helpers/enum/modalities-id';
import { ProductsId } from '@helpers/enum/products-id';
import { isComboFlux } from '@helpers/flux-helpers';
import {
  convertProductListToRemovedProductList,
  convertRemovedProductCartListToProductList,
  formatProductContractedResponseToProductContracted,
} from '@helpers/product-helpers';
import { customerValidation } from '@helpers/validators/customer-validation';

import { RootState } from '@hooks/use-selector';
import {
  toggleCartLoadingState,
  removeProductFromCart,
  removeProductFromComboCartList,
  addProductListToComboCartPricingRemovedProductsList,
  removeProductListFromComboCartList,
} from '@store/slices/cart-slice';
import {
  changeCustomerType,
  handleAddUnservedRegionProduct,
} from '@store/slices/customer-slice';

import { OfferType } from '@api/models/response/offer-response';
import PricingResponse from '@api/models/response/pricing-response';
import clienteRhService from '@api/services/cliente-rh-service';
import ApplicationError from '@errors/types/application-error';
import DowngradeError from '@errors/types/downgrade-error';
import InvalidSimulationIdentificationError from '@errors/types/invalid-simulation-identification';
import NotCatalogedError from '@errors/types/not-cataloged-error';
import ProductNotComercializedRegionInformedError from '@errors/types/product-not-comercialized-in-region-error';
import SamePlanError from '@errors/types/same-plan-error';

export const fetchCartPricing = createAsyncThunk<
  PricingResponse,
  { offerData?: OfferType },
  { state: RootState }
>('cart/fetchCartPricingStatus', async ({ offerData }, thunkAPI) => {
  const { companyData } = thunkAPI.getState().customer;
  const { config } = thunkAPI.getState().application;
  const { productCartList, productComboCartList, extraAdditionalProductList } =
    thunkAPI.getState().cart;
  const { productList } = thunkAPI.getState().products;

  const productCartListToPrice = isComboFlux(offerData)
    ? Object.values(productComboCartList).flat()
    : productCartList;

  let response = {} as PricingResponse;

  const extensionsId = extraAdditionalProductList.map(({ id }) => id);

  try {
    const productPricingList = convertProductCartListToProductPricingList(
      productCartListToPrice,
      extensionsId,
    );

    if (companyData.lead === '') {
      throw new InvalidSimulationIdentificationError();
    }

    response = await clienteRhService.pricing(
      {
        siglaCanal: config.siglaCanal,
        siglaEmissor: config.emissor.initials,
        siglaSubCanal: config.siglaSubCanal,
      },
      companyData.lead,
      productPricingList,
      offerData?.codigoOferta,
    );

    const newProductList = convertRemovedProductCartListToProductList(
      productCartListToPrice,
      productList,
    );

    response.produtosRemovidos.forEach(product => {
      if (
        product.idMotivo ===
        EXCLUDE_REASONS.UNSERVED_REGION_PRODUCT_EXCLUDE_REASON
      ) {
        thunkAPI.dispatch(handleAddUnservedRegionProduct(product.codigo));
        if (isComboFlux(offerData)) {
          thunkAPI.dispatch(
            removeProductFromComboCartList({
              productId: product.codigo,
              modalityId:
                newProductList.find(p => p.id === product.codigo)?.modalityId ||
                0,
            }),
          );
        }
        thunkAPI.dispatch(removeProductFromCart({ productId: product.codigo }));
      }
    });

    if (thunkAPI.getState().cart.cartModality?.id !== ModalitiesId.HCM) {
      thunkAPI.dispatch(
        changeCustomerType(
          customerValidation({
            productList,
            customerType: thunkAPI.getState().customer.customerType,
            contractedProducts:
              formatProductContractedResponseToProductContracted(
                response.produtosContratados,
              ),
            productCartList: productCartListToPrice,
          }).find(scenarios => scenarios.validation())?.customerType,
        ),
      );
    }
  } catch (error) {
    if (error instanceof ProductNotComercializedRegionInformedError) {
      thunkAPI.dispatch(changeCustomerType(CustomerType.UNSERVED_REGION));
      if (isComboFlux(offerData)) {
        const newProductList = convertRemovedProductCartListToProductList(
          productCartListToPrice,
          productList,
        );
        const removedProductList =
          convertProductListToRemovedProductList(newProductList);
        thunkAPI.dispatch(
          addProductListToComboCartPricingRemovedProductsList(
            removedProductList,
          ),
        );
        thunkAPI.dispatch(
          removeProductListFromComboCartList(
            newProductList.map(product => ({
              productId: product.id,
              modalityId: product.modalityId,
            })),
          ),
        );
      } else {
        productCartListToPrice.forEach(product => {
          thunkAPI.dispatch(handleAddUnservedRegionProduct(product.id));
        });
        productCartListToPrice.forEach(product => {
          thunkAPI.dispatch(removeProductFromCart({ productId: product.id }));
        });
      }
      thunkAPI.dispatch(toggleCartLoadingState(false));
    } else if (
      error instanceof DowngradeError ||
      error instanceof SamePlanError
    ) {
      thunkAPI.dispatch(changeCustomerType(CustomerType.DOWNGRADE));
      thunkAPI.dispatch(toggleCartLoadingState(false));
    } else if (error instanceof ApplicationError) {
      return thunkAPI.rejectWithValue(error);
    } else {
      return thunkAPI.rejectWithValue(new NotCatalogedError());
    }
  }
  return response;
});

export const fetchPricingWithoutLead = createAsyncThunk<
  PricingResponse,
  ProductCartType[] | undefined,
  { state: RootState }
>('cart/fetchPricingWithoutLeadStatus', async (plans, thunkAPI) => {
  const { config } = thunkAPI.getState().application;
  const { productCartList, requiredAdditionalProductList } =
    thunkAPI.getState().cart;
  const filteredPlans = plans?.some(
    plan => plan.amount > MIN_LIFES_ESSENTIAL_PLAN,
  )
    ? plans.filter(p => p.id !== ProductsId.HCM_ESSENCIAL)
    : plans;

  const extensionsId = requiredAdditionalProductList?.unrequired.map(
    ({ id }) => id,
  );

  let productPricingList = [] as ProductPricing[];

  if (filteredPlans) {
    productPricingList = convertProductCartListToProductPricingList(
      filteredPlans,
      [],
    );
  } else {
    productPricingList = convertProductCartListToProductPricingList(
      productCartList,
      extensionsId,
    );
  }

  try {
    return await clienteRhService.pricingWithoutLead(
      {
        siglaCanal: config.siglaCanal,
        siglaEmissor: config.emissor.initials,
        siglaSubCanal: config.siglaSubCanal,
      },
      productPricingList,
      '',
    );
  } catch (error) {
    if (error instanceof ApplicationError) {
      return thunkAPI.rejectWithValue(error);
    }
    return thunkAPI.rejectWithValue(new NotCatalogedError());
  }
});
